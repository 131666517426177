import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { LABELS, TITLES } from "common/lables";
import { CRM_REPORTS_APIs } from "apihandlers/crm/reports";
import { CRM_LEAD_STATUS, FORMATS } from "common/config";
import { DateFunctions } from "common/datefunctions";

const EXCEL_GRID_ID = "ApprovalList";

const ApprovalList = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        fromDate: DateFunctions.getDate("thismonth", "from", FORMATS.SQL_DATE),
        toDate: DateFunctions.getDate("thismonth", "to", FORMATS.SQL_DATE),
        // fromDate: "2023-06-01",
        // toDate: "2023-06-30",
      };
      const res: any = await CRM_REPORTS_APIs.getApprovalList(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;

        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;

          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          if (list[i]["verifiedStatus"]) {
            list[i]["verifiedStatus"] = "Yes";
          } else {
            list[i]["verifiedStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getApprovalList:", error);
    }
  };

  const COLUMNS = [
    {
      key: "crmLeadName",
      name: LABELS.LEAD_NAME,
      width: "20%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "leadSource",
      name: LABELS.LEAD_SOURCE,
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "approvalLevel",
      name: LABELS.APPROVAL_LEVEL,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "approvedBy",
      name: LABELS.APPROVED_BY,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "approvedOn",
      name: LABELS.APPROVED_ON,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "areaName",
      name: LABELS.AREA_NAME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "region",
      name: LABELS.REGION,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "regionZone",
      name: LABELS.REGION_ZONE,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "city",
      name: LABELS.CITY,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "centralZone",
      name: LABELS.CENTRAL_ZONE,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "country",
      name: LABELS.COUNTRY,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "fssiNumber",
      name: LABELS.FSSI_NO,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "fssiDate",
      name: LABELS.FSSAI_EXPIRY_DATE,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "comments",
      name: "Comments",
      width: "20%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "customerType",
      name: LABELS.CUSTOMER_TYPE,
      width: "10%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "Customer",
          name: LABELS.CUSTOMER,
        },
        {
          id: "Dealer",
          name: LABELS.DEALER,
        },
      ],
    },
    {
      key: "leadStatus",
      name: LABELS.LEAD_STATUS,
      width: "10%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: CRM_LEAD_STATUS.NEW_LEAD,
          name: CRM_LEAD_STATUS.NEW_LEAD,
        },
        {
          id: CRM_LEAD_STATUS.VERIFIED,
          name: CRM_LEAD_STATUS.VERIFIED,
        },
        {
          id: CRM_LEAD_STATUS.ASSIGNED,
          name: CRM_LEAD_STATUS.ASSIGNED,
        },
        {
          id: CRM_LEAD_STATUS.FIELD_VISIT,
          name: CRM_LEAD_STATUS.FIELD_VISIT,
        },
        {
          id: CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
          name: CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
        },
        {
          id: CRM_LEAD_STATUS.APPROVALS,
          name: CRM_LEAD_STATUS.APPROVALS,
        },
        {
          id: CRM_LEAD_STATUS.QUALIFIED,
          name: CRM_LEAD_STATUS.QUALIFIED,
        },
        {
          id: CRM_LEAD_STATUS.CONVERTED,
          name: CRM_LEAD_STATUS.CONVERTED,
        },
        {
          id: CRM_LEAD_STATUS.CUSTOMER,
          name: CRM_LEAD_STATUS.CUSTOMER,
        },
      ],
    },
    {
      key: "verifiedStatus",
      name: LABELS.VERIFIED_STATUS,
      width: "5%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,

      filters: [
        {
          id: "true",
          name: LABELS.TRUE,
        },
        {
          id: "false",
          name: LABELS.FALSE,
        },
      ],
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        { id: "yes", name: "Yes" },
        { id: "no", name: "No" },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
  ];

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  console.log("selectedRow", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{TITLES.CRM_REPORTS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.APPROVAL_LIST}</li>
        </ul>
        <h4>{TITLES.APPROVAL_LIST}</h4>
        {/* <p>{SUB_TITLES.APPROVAL_LIST}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={EXCEL_GRID_ID}
            height={window.innerHeight * 0.85}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            hideUnhide={true}
            freezeColumn={true}
            exportName={TITLES.APPROVAL_LIST}
            onReload={reloadGrid}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(ApprovalList);
