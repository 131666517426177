/*
 * Page Titles
 */
export const TITLES = {
  SALESMAN: "Salesman",
  DRIVERS: "Drivers",
  MASTERS_BUTTON_VEHICLES: "Vehicles",
  CUSTOMERS_ON_MAP: "Customers on Map",
  TYPE_CATEGORY: "Type Category",
  ORGANIZATION_PROFILE: "Organization Profile",
  LEAD_DOCUMENTS: "Lead Documents",
  LEAD_APPROVALS: "Lead Approvals",
  SURVEY: "Survey",
  FEEDBACK: "Feedback",
  ORDERS_LIFECYCLE: "Orders Lifecycle",
  CRM_LIFECYCLE: "CRM Lifecycle",
  SCHEME: "Scheme",
  SKU: "SKU",
  MY_MAP: "My Map",
  CUSTOMERS: "Customers",
  GPT_BASE: "GPT Base",
  ALLOTMENT: "Allotment",
  TRAINING_FILES: "Training Files",
  TRAINING_ASSIGNMENTS: "Training Assignments",
  TRAINING_STATUS: "Training Status",
  APPROVAL_LIST: "Approval List",
  DOC_LIST: "Document List",
  LEAD_VISITS: "Lead Visits",
  LEAD_ASSIGNMENTS: "Lead Assignments",
  GET_ALL_LEADS: "Get All Leads",
  TRAINING_ASSIGN: "Training Assign",
  TRAINING_VIEW: "View Training",
  TRAINING: "Training",
  PRODUCT: "Product",
  CATEGORY: "Category",
  TAX: "Tax",
  ADD_SALES_INVOICE: "Add Sales Invoice",
  SALES_RETURN: "Sales Return",
  E_INVOICE: "E-Invoice",
  ADD_PURCHASE_BILL: "Add Purchase Bill",
  SALES: "Sales",
  PURCHASE_BILL: "Purchase Bill",
  PURCHASE_RETURN: "Purchase Return",
  SALES_ORDER: "Sales Order",
  SALES_INVOICE: "Sales Invoice",
  PHYSICAL_VOUCHER: "Physical Voucher",
  RETAIL_POS_SALE_REPORT: "Retail POS Sales Report",
  DISTRICT: "District",
  FARE_CHART: "Fare Chart",
  TEAM: "Team",
  DLR_PRE_APPOINTMENT_FORM: "DLR Pre Appointment Form",
  REGION: "Region",
  CURRENCY: "Currency",
  CITY: "Area",
  AREA: "Route",
  USER: "User",
  CRM_DASHBOARD: "CRM Dashboard",
  LEAD_PROFILE: "Lead Profile",
  COUNTRY: "Country",
  STATE: "State",
  BEAT: "Beat",
  VEHICLE_DRIVER: "Vehicle Driver",
  BEAT_PJP: "Beat/PJP",
  GRAMS: "Grams",
  BOX: "Box",
  PJP: "PJP",
  EXPENSES: "Expenses",
  LEDGER: "Ledger",
  REGIONAL_ZONES: "Regional Zones",
  LEAVE_APPLICATION: "Leave Application",
  ATTENDENCE: "Attendence",
  TRACK: "Track",
  STOCK_LOAD: "Stock Load",
  ORDER: "Order",
  CHALLAN: "Challan",
  INVOICE: "Invoice",
  RETURN: "Return",
  NEW_CATEGORY: "New Category",
  LOAD_RETURN: "Load Return",
  PRIMARY_ORDER: "Primary Order",
  SUB_CATEGORY: "Sub Category",
  ROI: "ROI",
  ROI_CALCULATER: "ROI Calculator",
  ROI_DASHBOARD: "ROI Dashboard",
  GRAPH_DASHBOARD: "Graph Dashboard",
  CRM_REPORTS: "CRM Reports",
  TRAINING_REPORTS: "Training Reports",
  PRICE: "Price",
  BRANCH: "Branch",
  HOLIDAYS: "Holidays",
  CENTRAL_ZONES: "Central Zones",
  LEAVE_TYPE: "Leave Type",
  TARGETS: "Targets",
  CUSTOMER_TYPE: "Customer Type ",
  MAIN_CATEGORY: "Main Category",
  VEHICLE: "Vehicle",
  DRIVER: "Driver",
  USERS: "Users",
  CUSTOMER: "Customer",
  INSTRUCTIONS: "Instructions",
  CONFIRM_VERIFICATION: "Confirming the Lead Qualification",
  LEAVE_APPROVAL: "Leave Approval",
  CONFIRM_LEAD_CONVERT: "Confirm Lead Convert",
  BUSINESS_CATEGORY: "Business Category",
  SALES_WISE_CATEGORY: "Sales Wise Category",
  ATTENDANCE_REPORT: "Attendance Report",
  POS_REPORT: "POS Report",
  BILL_ITEM_REPORT: "Bill Item Report ",
  BILL_REGISTER: "Bill Register",
  DAYWISE_MONTHLY_COLLECTION: "DayWise Monthly Collection",
  IBT_REGISTER: "IBT Register",
  IBT_OUTWARD_REGISTER: "IBT Outward Register",
  STOCK_REPORT: "Stock Report ",
  PRESENT_STOCK_REPORT: "Present Stock Report ",
  STOCK_ADJUSTMENT_REPORT: "Stock Adjustment Report ",
  PURCHASE_REPORT: "Purchase Report",
  CONSOLIDATED_PURCHASE_REPORT: "Consolidated Purchase Report",
  ITEMS_LIST_REPORT: "Items List Report",
  DMS_REPORT: "DMS Report",
  SALES_ORDER_REPORT: "Sales Order Report ",
  DELETED_ORDER_REPORT: "Deleted Order Report",
  SALES_BILL_REPORT: "Sales Bill Report ",
  DELETED_BILL_REPORT: "Deleted Bill Report ",
  PARTY_STATUS: "Party Status",
  SALES_BILL_REGISTER: "Sales Bill Register",
  SALES_RETURN_REPORT: "Sales Return Report",
  SALES_ORDER_VS_BILL_QTY_REPORT: "Sales Order VS Bill Qty Report",
  SALES_BILL_PAYMENT_DETAILS: "Sales Bill Payment Details",
  PURCHASE_BILL_SUMMARY: "Purchase Bill Summary",
  TOTAL_SUMMARY_REPORT: "Total Summary Report ",
  SALES_SUMMARY_REPORT: "Sales Summary Report",
  CURRENT_STOCK_WITH_DETAILS: "Current Stock With Details",
  REPORTS: "Reports",
  LEAVES_REPORT: "Leaves Report",
  CUSTOMER_LIST: "Customer List",
  ASSIGN_BEAT: "Assign Beat",
  LOCATION_UPDATE: "Location Update",
};

/*
 * Description for the page (Title)
 */
export const SUB_TITLES = {
  GRAPH_DASHBOARD: "Graph Dashboard",
  ROI_DASHBOARD: "ROI Dashboard",
  SALESMAN: "Salesman",
  DRIVERS: "Drivers",
  CUSTOMERS_ON_MAP: "Customers on Map",
  MASTERS_BUTTON_VEHICLES: "Vehicles",
  TYPE_CATEGORY: "Type Category",
  ORGANIZATION_PROFILE: "Organization Profile",
  LEAD_DOCUMENTS: "Lead Documents",
  LEAD_APPROVALS: "Lead Approvals",
  SURVEY: "Survey",
  RETAIL_POS_SALE_REPORT: "Retail POS Sales Report",
  FEEDBACK: "Feedback",
  SCHEME: "Scheme",
  ALLOTMENT: "Allotment",
  TRAINING_REPORTS: "Training Reports",
  TRAINING_FILES: "Training Files",
  TRAINING_ASSIGNMENTS: "Training Assignments",
  TRAINING_STATUS: "Training Status",
  APPROVAL_LIST: "Approval List",
  DOC_LIST: "Document List",
  LEAD_VISITS: "Lead Visits",
  LEAD_ASSIGNMENTS: "Lead Assignments",
  GET_ALL_LEADS: "Get All Leads",
  TRAINING_ASSIGN: "Training Assign",
  TRAINING_VIEW: "View Training",
  TRAINING: "Training",
  PRODUCT: "Product",
  CATEGORY: "Category",
  TAX: "Tax",
  FARE_CHART: "Fare Chart",
  TEAM: "Team",
  SALES_INVOICE: "Sales Invoice",
  ADD_SALES_INVOICE: "Add Sales Invoice",
  SALES_RETURN: "Sales Return",
  E_INVOICE: "E-Invoice",
  ADD_PURCHASE_BILL: "Add Purchase Bill",
  PURCHASE_BILL: "Purchase Bill",
  PURCHASE_RETURN: "Purchase Return",
  PHYSICAL_VOUCHER: "Physical Voucher",
  //keerthan
  ORDERS_LIFECYCLE: "Orders Lifecycle",
  CRM_LIFECYCLE: "CRM Lifecycle",
  NEW_CATEGORY: "New Category",
  DRIVER: "Driver",
  MAIN_CATEGORY: "Main Category",
  VEHICLE: "Vehicle",
  USERS: "Users",
  CUSTOMER: "Customer",
  INSTRUCTIONS: "Instructions",
  CONFIRM_VERIFICATION: "Confirm Verification",
  LEAVE_APPROVAL: "Leave Approval",
  PJP: "PJP",
  LEDGER: "Ledger",
  LEAVE_APPLICATION: "Leave Appication",
  ATTENDENCE: "Attendence",
  TRACK: "Track",
  STOCK_LOAD: "Stock Load",
  ORDER: "Order",
  CHALLAN: "Challan",
  INVOICE: "Invoice",
  RETURN: "Return",
  AREA: "Route",
  EXPENSES: "Expenses",

  DLR_PRE_APPOINTMENT_FORM: "DLR Pre Appointment Form",
  REGION: "Region",
  CURRENCY: "Currency",
  CITY: "Area",
  USER: "User",
  MANAGE_USERS: "Manage Users",
  CRM_DASHBOARD: "CRM Dashboard",
  COUNTRY: "Country",
  STATE: "State",
  BEAT: "Beat",
  BEAT_PJP: "Beat/PJP",
  GRAMS: "Grams",
  BOX: "Box",
  SUB_CATEGORY: "Sub Category",
  LEAD_PROFILE: "Lead Profile",
  ROI: "ROI",
  ROI_CALCULATER: "ROI Calculator",
  CRM_REPORTS: "CRM Reports",
  PRICE: "Price",
  BRANCH: "Branch",
  HOLIDAYS: "Holidays",
  LEAVE_TYPE: "Leave Type",
  TARGETS: "Targets",
  CUSTOMER_TYPE: "Customer Type ",
  BUSINESS_CATEGORY: "Business Category",
  SALES_WISE_CATEGORY: "Sales Wise Category",
  ATTENDANCE_REPORT: "Attendance Report",
  POS_REPORT: "POS Report",
  BILL_ITEM_REPORT: "Bill Item Report ",
  BILL_REGISTER: "Bill Register",
  DAYWISE_MONTHLY_COLLECTION: "DayWise Monthly Collection",
  IBT_REGISTER: "IBT Register",
  IBT_OUTWARD_REGISTER: "IBT Outward Register",
  STOCK_REPORT: "Stock Report ",
  PRESENT_STOCK_REPORT: "Present Stock Report ",
  STOCK_ADJUSTMENT_REPORT: "Stock Adjustment Report ",
  PURCHASE_REPORT: "Purchase Report",
  CONSOLIDATED_PURCHASE_REPORT: "Consolidated Purchase Report",
  ITEMS_LIST_REPORT: "Items List Report",
  DMS_REPORT: "DMS Report",
  SALES_ORDER_REPORT: "Sales Order Report ",
  DELETED_ORDER_REPORT: "Deleted Order Report",
  SALES_BILL_REPORT: "Sales Bill Report ",
  DELETED_BILL_REPORT: "Deleted Bill Report ",
  PARTY_STATUS: "Party Status",
  SALES_BILL_REGISTER: "Sales Bill Register",
  SALES_RETURN_REPORT: "Sales Return Report",
  SALES_ORDER_VS_BILL_QTY_REPORT: "Sales Order VS Bill Qty Report",
  SALES_BILL_PAYMENT_DETAILS: "Sales Bill Payment Details",
  PURCHASE_BILL_SUMMARY: "Purchase Bill Summary",
  TOTAL_SUMMARY_REPORT: "Total Summary Report ",

  SALESMAN_SUMMARY_REPORT: "Salesman Summary Report",
  CURRENT_STOCK_WITH_DETAILS: "Current Stock With Details",
  REPORTS: "Reports",
  LEAVES_REPORT: "Leaves Report",
  CUSTOMER_LIST: "Customer List",
  ASSIGN_BEAT: "Assign Beat",
  LOCATION_UPDATE: "Location Update",
  CENTRAL_ZONES: "Central Zones",
  CUSTOMERS: "Customers",
  DESIGNATION_MASTER: "Designation Master",
  DISTRICT: "District",
  GPT_MASTER: "GPT Master",
  GPT_BASE: "GPT Base",
  LEAVES: "Leaves",
  REGIONAL_ZONES: "Regional Zones",
  SKU: "SKU",
  VEHICLE_DRIVER: "Vehicle Driver",
};

/*
 * Form Labels, Headings, Grid Column names, Text values etc..
 */
export const LABELS = {
  PHYSICAL_VOUCHER_APPROVAL: "Physical Voucher Approval",
  STRIP: "Strip",
  PACKET: "Packet",
  CREATED_BY: "Created By",
  RECEIPT_NUMBER: "Receipt Number",
  CURRENT_STOCK: "Current Stock",
  WEIGHT: "Weight",
  BOX_QUANTITY: "Box Quantity",
  STRIP_PRICE: "Strip Price",
  MUST_SELL: "Must Sell",
  DEPARTMENT: "Department",
  EMPLOYEE_CODE: "Employee Code",
  DATE_OF_JOINING: "Date Of Joining",
  CADRE: "Cadre",
  BAND: "Band",
  DMS_MASTER: "DMS Masters",
  VEHICLE_NO: "Vehicle No",
  DRIVERS_NAME: "Drivers Name",
  FITNESS_FROM: "Fitness From",
  CHASSIS_NO: "Chasis No",
  EMMISSION_VALIDITY: "Emmission Validity",
  INSURENCE: "Insurance",
  INSURENCE_UPTO: "Insurance Upto",
  INSURENCE_FROM: "Insurance From",
  INSURANCE_NUMBER: "Insurance Number",
  INSURANCE_COMP_NAME: "Insurance Company Name",
  ATTACHED: "Is Attached ? ",
  FUEL_TYPE: "Fuel Type ",
  HYPOTHICATE: " Hypothicated ? ",
  PER_KM: " Per KM Expense  ",
  PERMIT_NO: "Permit Number",
  PERMIT_FROM: "Permit From",
  REGISTRATION_VALIDITY: "Registration Validity",
  ROADTAX_FROM: "RoadTax From",
  ROADTAX_UPTO: "RoadTax Upto",
  SPEED_LIMIT_UPTO: "Speed Limiter Upto",
  FAST_TAG_NO: "Fastag  Number",
  FASTTAG_PROVIDER: "Fastag Provider",
  MILEAGE: "Mileage ",
  AVG_SPEED: "Average Speed ",
  AXLE_NAME: "Axle Name",
  BODY_TYPE: "Body Type",
  CRATE_CAPACITY: "Crates/Box Capacity",
  MIN_CRATE_CAPACITY: "Minimum Crate Capacity",
  CURRENT_LOCATION: "Current Location",
  DAILY_TRAVEL: "Daily Travel (KM)",
  EMI_AMOUNT: "EMI Amount",
  EMI_DAYS: "EMI Days",
  EMISSION_NORMS: "Emission Norms",
  ENGINE_NO: "Engine number",
  FUEL_CARD_NO: "Fuel Card number",
  FUEL_PROVIDER: "Fuel Provider",
  LOAN_ACCOUNT_NUMBER: "Loan Account Number",
  LOAN_BANK_NAME: "Loan Bank Name ",
  MANUFACTURING_YEAR: "Manufacturing Year",
  MAXIMUM_PASSING_TON: "Maximum Passing Ton",
  MINIMUM_PASSING_TON: "Minimum Passing Ton",
  NEXT_NP_DUE_DATE: "Next NP Due Date",
  NP_5YR_VALIDITY: "NP 5 Year Validity  ",
  NP_10YR_VALIDITY: "NP 10 Year Validity  ",
  PREV_NP_DATE: "Previous NP Date",
  PHOTOS: "Photos",
  PREV_ROADTAX_DATE: "Previous Road Tax Date",
  RC_STATUS: "RC Status ",
  REEFER_FUEL_PER_HR: "Reefer Fuel Per Hour",
  IS_ATTACHED: "Is Attached",
  IS_HYPOTHICATED: "Is Hypothicated",
  IS_VAHAN_VERIFIED: "Is Vahan Verified",
  VEHICAL_LIST: "Vehicles List",
  VEHICLE_TYPE: "Vehicle Type",
  VEHICLE_DRIVER_INFO: "Vehicle and Driver Info ",
  VEHCILE_SPECIFICATION: "Vehicle Specification",
  AXLE_TYPE: "Axle Type",
  PASSING_TON: "Passing Ton",
  VEHICLE_IDENTIFICATION: "Vehicle Identification ",
  EMMISION_NORM: "Emission Norms",
  PURCHASE_DAATE: "Purchase Date",
  DIESEL: "DIESEL",
  PETROL: "PETROL",
  CNG: "CNG",
  ELECTRIC: "ELECTRIC",
  ELECTRICAL: "ELECTRICAL",
  HELPER: "HELPER",
  CLEANER: "CLEANER",
  NON_TRIP: "Non Trip",
  OTHERS: "OTHERS",
  VEHICLE_PAYEMENT_AND_NAV_DETAILS: "Vehicle Payment and Navigation Details",
  VEHICLE_DOCUMENTATION_DETAILS: "Vehicle Documentation Details",
  FUEL_DETAILS: "Fuel Details",
  LOAN_DETAILS: "Loan Details",
  OTHER_DETAILS: "Other Details",
  ACCIDENT: "ACCIDENT",
  FIT: "FIT",
  BREAKDOWN: "BREAKDOWN",
  UNDER_REPAIR: "UNDER REPAIR",
  UNDER_MAINTENANCE: "UNDER MAINTENANCE",
  FOR_SALE: "FOR SALE",
  PAPERS_PENDING: "PAPERS PENDING",
  CURRENT_STATUS: "Current Status",
  DDL_NUMBER: "DDL Number",
  MOBILE_NO: "Mobile Number",
  CUG: "CUG",
  DL_ISSUING_AUTHORITY: "DL Issuing Authority",
  MOBILE_ALTERNATIVE: "Mobile(alternative)",
  MODIFIED_ON: "Modified On",
  IFSC_CODE: "IFSC Code",
  BENEFICIARY_NAME: "Beneficiary Name",
  AADHAR_NUMBER: "Adhaar Number ",
  UAN_NUMBER: "UAN",
  TR_DUE_DATE: "TR Due Date",
  NT_DUE_DATE: "NT Due Date",
  TEMPERORY_ADDRESS: "Temporary Address",
  DRIVER_TYPE: "Driver  Type",
  VEHICLE_TYPES: "Vehicle Types",
  DRIVERS: "Drivers",
  DRIVER_DETAILS: "Driver Details",
  DRIVER_VEHICLE_DETAILS: "Driver Vehicle Details",
  EMPLOYEE_DETAILS: "Employee Details",
  MOBILE_CUG: "Mobile CUG",
  JOINING_DATE: " Joining Date",
  RELATIVE: "Relative",
  BANK_DETAILS: "Bank Details",
  REDEEMED_KEY: "Redeemed Key",
  BALANCE: "Balance",
  SCHEME_DETAILS: "Scheme Details",
  SCHEME_NAME: "Scheme Name",
  TARGET_STATUS_PRODUCT: "Target Status - Product",
  TARGET_STATUS_TOTAL: "Target Status - Total",

  COMPANY_NAME_REPORT: "DESAI BEVERAGES PRIVATE LIMITED",
  FREEZE_COLS: "Freeze Columns",
  TOTAL_VALUES: "Total Values",
  CASH_IN_BANK: "Cash in Bank",
  ACTIVE_OUTLETS: "Active Outlets",
  NON_SERVICES_OUTLETS: "Non Services Outlets",
  OUTSTANDING: "Outstanding",
  OPEN_CRM_MAP_VIEW: "Open CRM Map View",
  TOP_PRODUCTS: "Top Products",
  TARGET: "Target",
  MAP_TYPES: "Map Types",
  HIDE_UNHIDE: "Hide Unhide",
  INTERVIEW_PROCESS: "Interview Process",
  PRINT_QUALIFY: "Print Dealer Registration Form",
  PRINT_LEAD: "Print Dealer Pre-Appointment Form",
  SALES_TYPE: "Sales Type",
  ORG_NAME: "Name",
  PERMIT: "Permit",
  APPROVED_USER: "Approved User",
  ORG_ADDRESS1: "Address 1",
  ORG_ADDRESS2: "Address 2",
  ORG_PIN: "PIN Code",
  GST_IN: "GST",
  SALES: "Sales",
  FILTER: "Filter",
  PARTY: "Party",
  ADD_NEW_PARTY: "Add New Party",
  ORDER_NO: "Order No",
  BASIC: "Basic",
  ORDER_ITEM: "Order Item",
  SOLD: "Sold",
  SOLD_SKU: "Sold Sku",
  ORDER_ITEM_BASICS: "Order Item Basics",
  ORDER_ITEM_TAX_AMOUNT: "Order Item Tax Amount",
  ORDER_ITEM_TOTAL: "Order Item Total",
  OFFERS: "Offers",
  STOCK_LOAD: "Stock Load",
  TAX_AMOUNT: "Tax Amount",
  TIME: "Time",
  VIEW_START_LOCATION: "View Start Location",
  ROUTE_NAME: "Route Name",
  SHOP_NAME: "Shop Name",
  SALESMAN: "Salesman",
  ITEMS: "Items",
  RESET: "Reset",
  SALES_ORDER: "Sales Order",
  SALES_INVOICE: "Sales Invoice",
  SELECT_DATE_RANGE: "Select Date Range",
  VIEW_SUMMARY: "VIEW SUMMARY",
  ORDER_FROM: "ORDER FROM",
  BILL: "Bill",
  RETURN: "Return",
  E_INVOICE: "E-Invoice",
  PRINT_SUMMARY: "PRINT SUMMARY",
  PRINT_BILL: "PRINT BILL",
  ADD_SALES_INVOICE: "Add Sales Invoice",
  ADD_PURCHASE_BILL: "Add Purchase Bill",
  PURCHASE_BILL: "Purchase Bill",
  ITEM_AMOUNT: "Item Amount",
  BILL_STATUS: "Bill Status",
  BILL_RETURN: "Bill Return",
  PURCHASE_RETURN: "Purchase Return",
  ORIG_BILL_NO: "ORIG Bill No",
  ORIG_DATE: "ORIG Date",
  PURCHASE_RETURN_NO: "Purchase Return No.",
  PURCHASE_RETURN_DATE: "Purchase Return Date",
  NO_OF_ITEMS: "No Of Items",
  PRINT_PURCHASE_RETURN: "Print Purchase Return",
  PHYSICAL_VOUCHER: "Physical Voucher",
  DATE_TIME: "Date Time",
  ISSUE_RECEIPT: "Issue / Receipt",
  OLD_BAG_CARTON: "Old Bag / Carton",
  OLD_STRIP_PACKET: "Old Strip / Packet",
  NEW_BAG_CARTON: "New Bag / Carton",
  NEW_STRIP_PACKET: "New Strip / Packet",
  DIFF_BAG_CARTON: "Diff. Bag / Carton",
  DIFF_STRIP_PACKET: "Diff. Strip / Packet",

  RATE: "Rate",
  SALES_RETURN: "Sales Return",
  IMPORT_RESPONSE: "Import Response",
  PARTY_NAME: "Party Name",
  VOUCHER_NO: "Voucher Number",
  VOUCHER_DATE: "Voucher Date",
  IRN_NO: "IRN NO.",
  E_WAY_BILL_NO: "E-Way Bill NO",
  VALID_FROM: "Valid From",
  ACK_NO: "ACK NO.",
  ACK_DATE: "ACK Date",
  VOUCHER_TYPE: "Voucher Type",
  GST_IN_NO: "GST IN No.",
  VOUCHER_AMOUNT: "Voucher Amount",
  GENERATE_BILL: "GENERATE BILL",
  REMOVE_NEGATIVE_ORDER: "REMOVE NEGATIVE ORDER",
  PRINT_SALES_RETURN: "PRINT SALES RETURN",
  SELECT_ORDERS: "Select Orders",
  SALES_RETURN_NO: "Sales Return Number",
  SALES_RETURN_DATE: "Sales Return Date",
  REMARKS: "Remarks",
  CLOSED_ON: "Closed On",
  AMOUNT_DEPOSITED: "Amount Deposited",
  APPROVED_DATE: "Approved Date",
  GRAMS_QTY: "Grams Qty",
  TERRITORY_DETAILS: "Territory Details",
  ORG_MOBILE: "Mobile",
  MAIN_PRODUCT: "Main Product",
  ORG_EMAIL: "Email",
  URL: "URL",
  OPENED_ON: "Opened On",
  LAST_ORDERED_ON: "Last Ordered On",
  INSERT_ABOVE_CUSTOMER: "Inset Above Customer",
  CREDIT_DAYS: "Credit Days",
  RURAL: "Rural",
  SWD_OF: "SWD",
  SIGNATURE_PATH: "Signature Path",
  LAND_LINE: "Land Line",
  FIRST_ORDER_ON: "First Order On",
  ERP_CODE: "ERP Code",
  CREDIT_LIMIT: "Credit Limit",
  CUSTOMER_TYPE_DETAILS: "Customer Details",
  FILE_PATH: "File Path",
  QTY_BASED: "Qty Based",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ORG_GST: " GST",
  CUT_OFF_TIME: "Cutoff Time",
  MIME_TYPE: "Mime Type",
  ORG_PAN: " PAN",
  FILE_NAME: "File Name",
  CRM_DOCUMENT_NAME: "Crm Document Name",
  CRM_LEAD_TYPE: "Crm Lead Type",
  APPROVAL_LEVEL: "Approval Level",
  APPROVAL_MANDATORY: "Is Approval Mandatory",
  OBSERVATION_COMMENTS: "Observations / Comments",
  SURVEY: "Survey",
  TRAINING_STATUS: "Training Status",
  TRAINING_ASSIGNMENTS: "Training Assignments",
  TRAINING_FILES: "Training Files",
  FEEDBACK_BY: "Feedback given by",
  FEEDBACK: "Feedback",
  AVG_CALL_TIME: "Avg Call Time",
  ALLOTMENT: "Allotment",
  TRAINING_ASSIGN: "Training Assign",
  TRAINING_VIEW: "View Training",
  UPLOAD_TYPE: "Upload Type",
  TRAINING_CAT: "Training Category",
  TRAINING_COMMENT: "Training Comment",
  DURATION: "Duration",
  TRAING_MANDATORY: "Is Training Mandatory?",
  BACK: "Back",
  ASSIGNED_DATE: "Assigned Date",
  ASSIGNED_BY: "Assigned By",
  ASSIGNED_TO: "Assigned To",
  ASSIGN: "Assign",
  QUALIFY: "Qualify",
  CRM: "CRM",
  TRAINING: "Training",
  MIN_PRISE: "Min. Prise",
  PRISE: "Prise",

  FARE_CHART: "Fare Chart",
  FARE_NAME: "Fare Name",
  EXP_TYPE: "Expense Type",
  EXP_MODE: "Expense Mode",
  EXP_UNIT: "Expense Unit",
  UNIT_RATE: "Unit Rate",
  MIN: "Min",
  MAX: "Max",
  MIN_CALL: "Min. Call",
  MIN_PRODUCTIVE_CALL: "Min.Productive Call",
  EDITABLE: "Editable",
  UNIT_EDITABLE: "Unit Editable",
  TEAM: "Team",
  MARKETING_PERSON: "Marketing Person",
  REPORTING_TO: "Reporting To",
  HIERARCHY_LEVEL: "Hierarchy Level",

  MRP: "MRP",
  SUB_CATEG: "Sub Categ.",
  MAIN_CATEG: "Main Categ.",
  PRODUCT_NAME: "Product Name",
  PRODUCT: "Product",
  ALIAS: "Alias",
  CATEGORY: "Category",
  CGST: "CGST %",
  SGST: "SGST %",
  IGST: "IGST %",
  TAXES_NAME: "Taxes Name",
  ACCESS_VERIFICATION: "Access Verification",
  OLD: "Old",
  PACK_BODY: "Pack Body",
  NO_OF_VEHICLE: "No. Of Vehicle",
  LOGISTICS_VERIFICATION: "Logistics Verification",
  OWN: "Own",
  RENTED: "Rented",
  YES: "Yes",
  NO: "No",
  SIZE_OF_GODOWN: "Size Of Godown (In Sq.ft)",
  PHY_GODOWN_VERIFICATION: "Physical Godown Verification",
  CONTACT_PERSON: "Contact Person",
  FIRM_NAME: "Firm Name",
  AGENCY_DETAILS: "Agency Details",
  NEW_DLR_PRE_APPOINTMENT_FORM: " New DLR Pre Appointment Form",
  DLR_PRE_APPOINTMENT_FORM: "DLR Pre Appointment Form",
  CURRENCY_CODE: "Currency Code",
  COUNTRY: "Country",
  CURRENCY_SYMBOL: "Currency Symbol",
  CURRENCY: "Currency",
  POPULATION: "Population",
  TOTAL_SHOPS: "Total Shops",
  REGION: "Region",
  ACTIVE_STATUS: "Active Status",
  USER: "User",
  MAIN_CATEGORY: "Main Category",
  VEHICLE: "Vehicle",
  MAIN_CATEGORY_NAME: "Main Category Name",
  MASTERS: " Masters",
  GEOGRAPHY: "Geography",
  CUSTOMERS: "Customers",
  PRODUCTS: "Products",
  PARTER_DETAIL: "Partner Detail",
  EDIT_CRM_DASHBOARD: "Edit Crm Dashboard",
  NEW_CRM_DASHBOARD: " New Crm Dashboard",
  WORKING_CAPITAL: "Working Capital / Amount",
  INVESTMENT_CAPACITY: "Investment Capacity",
  CAPITAL_DETAILS: "Capital Details",
  VEHICAL_DETAILS: "Vehicle Detail",
  LOGISTIC_DETAILS: "Logistics Detail",
  GODOWN_SIZE: "Godown Size",
  STORAGE_FACILITY: "Storage Facility",
  TRANSPORT_FACILITY: "Transport Facility",
  STORAGE_DETAILS: "Storage Details",
  EXP_DETAILS: "Experience Details",
  OTHER_EXP: "Other Experience",
  PARTNER_PHONE: "Partner Phone",
  PARTNER_AGE: "Partner Age",
  PARTNER_MOB_2: "Partner Mobile Number 2",
  PARTNER_MOB_1: "Partner Mobile Number 1",
  PARTNER_PIN: "Partner PIN",
  PARTNER_CURRENT_ADDRESS: "Partner Current Address",
  PARTNER_STATE: "Partner State",
  PARTNER_TALUKA: "Partner Taluka",
  PARTNER_DIST: "Partner District",
  PARTNER_DOB: "Partner Date Of Birth",
  PARTNER_LAST_NAME: "Partner Last Name",
  PARTNER_MIDDLE_NAME: "Partner Middle Name",
  PARTNER_FIRST_NAME: "Partner First Name",
  PARTNER_DETAILS: "Partner Details",
  EDUCATIONAL_POST_GRADUATION_CIRTIFICATE:
    "Educational Post Graduation Certificate",
  EDUCATIONAL_GRADUATION_CIRTIFICATE: "Educational Graduation Certificate",
  EDUCATIONAL_HSC: "Educational HSC",
  EDUCATIONAL_SSC: "Educational SSC",
  EDUCATIONAL_DETAILS: "Educational Details",
  COMPANY_PIN: "Company PIN",
  FIRM_TYPE: "Firm Type",
  COMPANY_STATE: "Company State",
  COMPANY_TALUKA: "Company Taluka",
  COMPANY_DIST: "Company District",
  COMPANY_ADDRESS: "Company Address",
  COMPANY_EMAIL: "Company Email",
  COMPANY_NAME: "Company Name",
  COMPANY_DETAIL: "Company Details",
  AGE: "Age",
  MOB_2: "Mobile Number 2",
  MOB_1: "Mobile Number 1",
  PIN: "PIN",
  PERSONAL_STATE: "State",
  PERSONAL_TALUKA: "Taluka",
  PERSONAL_DIST: "District",
  PERSONAL_DETAILS: "Personal Details",
  CURRENT_ADDRESS: "Current Address",
  DOB: "Date Of Birth",
  APPLICATION_DT: "Application Date",
  DIST: "District",
  MIDDLE_NAME: "Middle Name",
  CURRENT_DETAILS: "Current Details",
  NEW_LEAD_PROFILE: "New Lead Profile",
  RELOAD: "Reload",
  ADD: "Add",
  ADD_NEW: "Add New",
  ADD_NEW_ROW: "Add New Row",
  LEAD_PROFILE: "Lead Profile",
  ROUTE_LIST_REPORT: "Route List Report",
  PRICE_LIST_REPORT: "Price List Report",
  PRODUCT_LIST_REPORT: "Product List Report",
  LEADS_REPORT: "Leads Report",
  FEEDBACK_REPORT: "Feedback Report",
  PARENT_ID: "Parent ID",
  INSTRUCTION_REPORT: "Instruction Report",
  SURVEY_REPORT: "Survey Report",
  CUSTOMER_LIST_REPORT: "Customer List Report",
  CUSTOMER_INSPECTION_REPORT: "Customer Inspection Report",
  ROUTE_REPORT: "Route Report",
  AREA_REPORT: "Area Report",
  REGION_REPORT: "Region Report",
  TOTAL_CUSTOMERS: "Total Customers",
  TOTAL_AREAS: "Total Areas",
  TOTAL_ROUTES: "Total Routes",
  LEAVES_REPORT: "Leaves Report",
  ATTENDANCE_REPORT: "Attendance Report",
  CITIES_LIST_REPORT: "Cities List Report",
  TEAM_DETAIL_REPORT: "Team Detail Report",
  BEAT_LIST_REPORT: "Beat List Report",
  BEAT_LOG_REPORT: "Beat Log Report",
  ORDER_DETAIL_REPORT: "Order Detail Report",
  SCHEMES_DETAIL_REPORT: "Scheme Detail Report",
  TARGET_DETAIL_REPORT: "Target Detail Report",
  SCHEME_UTILIZATION_REPORT: "Scheme Utilization Report",
  TARGET_ACHIEVED_REPORT: "Target Status - Product",
  TARGET_ACHIEVED_TOTAL_REPORT: "Target Status - Total",
  ALL_LOGS_REPORT: "All Logs Report",
  CUSTOMER_ORDERS: "Customer Orders",
  CATEGORY_WISE_SALES: "Category Wise Sales",
  MAIN_CATEGORY_WISE_SALES: "Main Category Wise Sales",
  SUB_CATEGORY_WISE_SALES: "Sub Category Wise Sales",
  PRODUCT_WISE_SALES: "Product Wise Sales",
  STATE_WISE_SALES: "State Wise Sales",
  REGION_WISE_SALES: "Region Wise Sales",
  CITY_WISE_SALES: "City Wise Sales",
  AREA_WISE_SALES: "Area Wise Sales",
  CUSTOMER_WISE_SALES: "Customer Wise Sales",
  CUSTOMER_TYPE_WISE_SALES: "Customer Type Wise Sales",
  SCHEME: "Scheme",
  PRICE: "Price",
  QUESTION: "Question",
  GPT_MASTER: "GPT Master",
  MINUTES_OF_MEETING: "Minutes of Meeting",
  WORK_PACKAGE: "Work Package",
  WORK_PACKAGES: "Work Packages",
  PL: "Paid Leaves",
  SL: "Sick Leaves",
  LEAVE_PLANNER_APPROVE: "Leave Approval",
  ADMIN_COMMENTS: "Admin Comments",
  ORG_CHART: "Organization Chart",
  TIME_SHEET_APPROVE: "Timesheet Approve",
  NOTES: "Notes",
  TERMS: "Terms",
  DUE_DATE: "Due Date",
  PRINT: "Print",
  INVOICES: "Invoices",
  INVOICE_PRINT: "Invoice Print",
  SELECT_TAX: "Select Tax",
  DATE_OF_INVOICE: "Invoice Date",
  PRINT_PAYSLIP: "Print Payslip",
  COMMENTS: "Comments",
  PRESENT_DAYS: "Present Days",
  ABSENT_DAYS: "Absent Days",
  TOTAL_WORKING_DAYS: "Total Working Days",
  PAY_ROLL: "Pay Roll",
  NET_PAY: "Net Pay",
  TDS: "TDS",

  /// KEERTHAN
  DRIVER: "Driver",
  DL_NUMBER: "DL Number",
  ISSUING_AUTHORITY: "Issuing Authority",
  DL_ISSUED_ON: "DL Issued On",
  DL_VALID_UPTO: "DL Valid Upto",
  EMERGENCY_NUMBER: "Emergency Number",
  ORDERS: "Orders",
  APPROVAL: "Approval",
  TOTAL_MONTHLY_LEAVES: "Total Monthly Leaves",
  TOTAL_YEARLY_LEAVES: "Total Yearly Leaves",
  EXPENSE_DIAL_CODE: "Expense Dial Code",
  EXPENSES: "Expenses",
  MODE_OF_EXPENSE: "Mode of Expense",
  UNIT_OF_MEASUREMENT: "Unit of Measurement",
  LEAVES: "Leaves",
  LEAVE_TYPE_ID: "Leave Type",
  REASON: "Reason",
  REGIONAL_ZONES: "Regional Zones",
  CENTRAL_ZONES: "Central Zones",
  ORGANIZATION_PROFILE: "Organization Profile",
  BUSINESS_CATEGORY: "Business Category",
  SALES_WISE_CATEGORY: "Sales Wise Category",
  STOCK_LEVEL: "Stock Level",
  TYPE_CATEGORY: "Type Category",
  SKU: "SKU",
  EXPENSE_CODE: "Expense Code",
  NEW_CATEGORY_NAME: "New Category Name",
  GPT_BASE: "GPT Base",
  VEHICLE_DRIVER: "Vehicle Driver",
  PJP: "PJP",
  EXPENSE_NAME: "Expense Name",
  CENTRAL_ZONE_CODE: "Central Zone Code",
  COUNTRY_ID: "Country ID",
  LEAD_DOCUMENTS: "Lead Documents",
  LEAD_APPROVALS: "Lead Approvals",
  LEAVE_APPROVAL: "Leave Approval",
  CENTRAL_ZONE_NAME: "Central Zone Name",
  REGIONAL_ZONE: "Regional Zone",
  DISTRICT_NAME: "District Name",
  // DAY_OF_WEEK: "Day of Week",
  DAY_OF_WEEK: "Day",
  AREA_TYPE: "Area Type",

  PROFESSIONAL_TAX: "Professional Tax",
  TOTAL_SALARY: "Total Salary",
  MONTH_END_DATE: "Month End Date",
  MONTH_START_DATE: "Month Start Date",
  PHOTO: "Photo",
  LEAVE_PLANNER: "Leave Planner",
  PRINT_CV: "Print CV",
  SELECTED_PROF_HISTORY: "Selected Profile History",
  RECENT_PROF_HISTORY: "Recent Profile History",
  KEY_EXPERIENCE: "Key Experience",
  COUNTRIES_WORKED_IN: "Countries Worked In",
  KEY_SKILLS: "Key Skills",
  COURSE: "Course",
  QUALIFICATION: "Qualification",
  PROFESSION: "Profession",
  ADD_CV: "Add/Edit CV",
  PROJECT_COUNTY: "Project County",
  CLIENT_COUNTY: "Client County",
  CONTACT_COUNTY: "Contact County",
  PROJECT_VALUE: "Project Value",
  BUDGET_FOR_EXECUTION: "Budget For Execution",
  PROJECT_AWARD_DATE: "Project Award Date",
  CONTACT_EMAIL_ID: "Contact Email ID",
  CONTACT_EIR_CODE: "Contact EIR Code",
  CONTACT_COUNTRY: "Contact Country",
  CONTACT_ADDRESS_1: "Contact Address 1",
  CONTACT_ADDRESS_2: "Contact Address 2",
  CONTACT_ADDRESS_3: "Contact Address 3",
  CONTACT_NAME: "Contact Name",
  CLIENT_EIR_CODE: "Client EIR Code",
  CLIENT_COUNTRY: "Client Country",
  CLIENT_ADDRESS_1: "Client Address 1",
  CLIENT_ADDRESS_2: "Client Address 2",
  CLIENT_ADDRESS_3: "Client Address 3",
  PROJECT_EIR_CODE: "Project EIR Code",
  PROJECT_COUNTRY: "Project Country",
  PROJECT_ADDRESS_1: "Project Address 1",
  PROJECT_ADDRESS_2: "Project Address 2",
  PROJECT_ADDRESS_3: "Project Address 3",
  PROJECT_CLOSE_DATE: "Project Close Date",
  PROJECT_START: "Project Start",
  PROJECT_FINISH: "Project Finish",
  CLIENT_NAME: "Client Name",
  BLOOD_GROUP: "Blood Group",
  PERMANENT_ADDRESS: "Permanent Address",
  PRESENT_ADDRESS: "Present Address",
  PERSONAL_EMAIL: "Personal Email",
  OFFICE_EMAIL: "Office Email",
  E_CONTACT_NUMBER: "Emergency Contact No.",
  RESOURCE_PLANNING: "Resource Planning",
  TIME_SHEET: "Time Sheet Booking",
  TIME_SHEET_APPROVAL: "Approve Time Sheet",
  PAY_SLIP: "Pay Slip",
  INVOICE: "Invoice",
  ESTIMATION: "Estimation",
  WANT_TO_PRINT_SALES_BILL_WITH: "Want to Print Sales Bill with",
  DATE: "Date",
  OUTLET: "Outlet",
  FROM_DATE: "From",
  TO_DATE: "To",
  EMPLOYEE: "Employee",
  NORMAL_HRS: "Normal Hours",
  DESIGNATION_MASTER: "Designation Master",
  DESIGNATION: "Designation",
  RESPONSIBILITY_MASTER: "Responsibility Master",
  RESPONSIBILITY: "Responsibility",
  LEVEL: "Level",
  PROJECT_TYPE_MASTER: "Project Type Master",
  PROJECT_TYPE: "Project Type",
  TASK_MASTER: "Task Master",
  TASK: "Task",
  HOLIDAY_MASTER: "Holiday Master",
  HOLIDAY: "Holiday",
  HOLIDAY_DATE: "Holiday Date",
  HOLIDAY_NAME: "Holiday Name",
  EMPLOYEE_MASTER: "Employees List",
  EMPLOYEE_ID: "Employee ID",
  EMPLOYEE_NAME: "Employee Name",

  STATUS: "Status",
  SCHEDULE_UPDATE_FREQUENCY: "Schedule Update Frequency",
  RISKS_REGISTER: "Risks Register",
  NEW_PASSWORD: "New Password",
  CURRENT_PASSWORD: "Current Password",
  SETTINGS: "Settings",
  CONSTRAINTS: "Constraints",
  SPENT_HRS: "Spent Hours",
  TASK_OWNER: "Task Owner",
  EMP_ID: "Employee ID",
  SELECT_DATE: "Status/Data Date",
  GEO_REPORT: "Geography Report",
  RESOURCE_PLANNING_REPORT: "Resource Planning Report",
  PROJECTS_REPORT: "Projects Report",
  TIME_SHEET_REPORT: "Time Sheet Report",
  INVOICE_REPORT: "Project Invoice Report",
  INVOICE_REGISTER: "Invoice Register",
  COST_REPORT: "Cost Report",
  APPROVAL_LOG_REPORT: "Approval Log Report",
  USER_LOG_REPORT: "User Log Report",
  EMPLOYEE_LEAVES_REPORT: "Employee Leaves Report",
  VIEW: "View",
  APPROVE: "Approve",
  REJECT: "Reject",
  APPROVE_FILE: "Approve File",
  COST: "Cost",
  UNIT: "Unit",
  QTY: "Qty.",
  EXPORT_TO_PDF: "Export To PDF",
  SIX_WEEKS_VIEW: "6 Weeks View",
  ONE_MONTH_VIEW: "1 Month View",
  SHOW_ALL: "Show All",
  TASK_NAME: "Discipline",
  REPORTS: "Reports",
  REPORT1: "Drill Down",
  PROJECT_MANAGER: "Project Manager",
  PROJECT_CHART: "Project Chart",
  PROJECT_CRITICAL_PATH: "Project Critical Path",
  WEEK_LOOK_AHEAD_SCHEDULE: "Week Look Ahead Schedule",
  PROJECT_REMAINING_DURATION: "Project Remaining Duration",
  EXPAND_ALL: "Expand All",
  PERCENTAGE: "Percentage",
  CRITICAL_PATH: "Critical Path",
  NEAR_TO_CRITICAL_PATH: "Near To Critical Path",
  PROJECT_CASH_FLOW: "Project Cash Flow",
  PROGRESS_CURVE: "Progress S Curve",
  MAN_HOURS_DISTRIBUTION: "Man Hours Distribution",
  RESOURCE_TABLE: "Resource Table",
  FINISH_DATE: "Finish Date",
  START_DATE: "Start Date",
  EDIT_CHART: "Edit Chart",
  PROJECT_OVERVIEW: "Project Overview",
  PROJECT_PROGRESS_STATUS: "Project Progress Status vs Earned",
  MAN_HOURS_PLAN: "Man Hours Distribution",
  RESOURCE_HISTOGRAM: "Resource Histogram",
  VIEW_PROJECT_CHARTER: "View Project Charter",
  PROJECT_CHARTER: "Project Charter",
  CONFIRM: "Confirm",
  LOAD: "Load",
  CONTACT_NUMBER: "Phone",
  INCHARGE: "Contact Person",
  ADDRESS_1: "Address 1",
  ADDRESS_2: "Address 2",
  ZIPCODE: "Zipcode",
  UPLOAD: "Upload",
  SELECT_FILE: "Choose File",
  FILE_UPLOAD_TYPE: "File Upload Type",
  SUPERVISOR: "Supervisor",
  PROJECT_NAME: "Project Name",
  PROJECT_CODE: "Project Code",
  SELECT_PROJECT: "Select Project",
  DELETE: "Delete",
  EDIT: "Edit",
  SAVE: "Save",
  CANCEL: "Cancel",
  HOME: "Home",
  PROJECTS: "Projects List",
  DASHBOARD: "Dashboard",
  GANTT_CHART: "Gantt Chart",
  USERS_LIST: "Users List",
  ORGANIZATION: "Organization",
  ORGANIZATIONS: "Organizations",
  LOGOUT: "Logout",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  USER_NAME: "Username",
  PASSWORD: "Password",
  LOGIN: "Login",
  REMEMBER_ME: "Remember Me",
  MENU: "Menu",
  SEARCH: "Search",
  EMAIL_ID: "Email ID",
  PHONE: "Phone Number",
  USER_TYPE: "User Type",
  ACTIVE: "Active",
  INACTIVE: "Cancel/Hide",
  CHARGEABLE: "Chargeable",
  DIRECTOR: "Director",
  PROJECT_STATUS: "Project Status",
  NEW: "New",
  STORE_VISIT: "Field Visit",
  RETAILER_DOCUMENT_VERIFICATION: "Retailer Document Verification ",
  QUALIFIED: "Qualified",
  CONVERTED: "Converted",
  COMPANY_DEATAIL: "Company Detail",
  AREA_DEATAIL: "Area Detail",
  PERSONAL_DEATIL: "Personal Detail",
  PARTNER_DETAIL: "Partner Detail",
  STORAGE_DETAIL: "Storage Detail",
  WAREHOUSE_DETAIL: "Warehouse Details",
  BANK_DEATIL: "Bank & A/c. Detail",
  REG_ADDRESS: "Reg.Address",
  PRINT_DATE: "Print Date",
  MONTH: "Month",
  AREA_LABLE: "Area",
  AREA: "Route",
  CITY: "Area",
  TALUKA: "Taluka",
  DISTRICT: "Default",
  ZONE: "Zone",
  STATE: "State",
  PIN_NO: "Pin No",
  FIRM_PAN: "Firm PAN",
  RELIGION: "Religion ",
  FIRM_GST: "Firm GST",
  FSSI_NO: "FSSAI No.",
  BUS_START_DATE: "FSSAI Expiry Date",
  PROPRIETOR: "Proprietor",
  PARTNERSHIP: "Partnership",
  PVT_LTD: "Pvt.Ltd",
  LTD: "Ltd",
  CONTACT_PERSON_1_NAME: "Contact Person (1) Name ",
  CONTACT_PERSON_1_MOBILE: "Contact Person (1) Mobile Number ",
  CONTACT_PERSON_2_NAME: "Contact Person (2) Name ",
  CONTACT_PERSON_2_MOBILE: "Contact Person (2) Mobile Number ",
  AREA_VILLAGE: "Area/Village",
  NAME: "Name of Shop",
  MOBILE_NUMBER: "Mobile Number",
  HOME_ADDRESS: "Home Address",
  DATE_OF_BIRTH: "Date of Birth",
  DRIVING_LICENSE: "Driving License",
  PAN_NUMBER: "PAN Number",
  AADHAR_UID_NUMBER: "Aadhar-UID Number",
  SL_NO: "SN",
  PARTNER: "Partner",
  SQ_FT: "Sq.Ft",
  BANK_DETAIL: "Bank Detail",
  ACCOUNT_NUMBER: "Account Number",
  ACCOUNT_NAME: "Account Name ",
  BANK_NAME: "Bank Name",
  BRANCH: "Branch",
  TYPE_OF_ACCOUNT: "Type of Account",
  IFSC_NO: "IFSC No",
  APPOINTED_BY: "Appointed By",
  ADDRESS: "Address",

  ///
  ACTIVITY_BOARD: "Activity Board",

  //
  REFERENCE: "Reference",
  DEPOSIT_DETAIL: "Security Deposit Detail",
  AMOUNT: "Amount",
  DEPOSIT_DATE: "Deposit Date",
  FAMILY_DEATIL: "Family Detail",
  OTHER_FAMILY: "Other Family References",
  CONTACT: "Contact",
  RELATION: "Relation",
  NOMINEE_DETAILS: "Nominee Details",
  FULL_NAME: "Full Name",
  ENCLOSED_DOCUMENT: "Enclosed Below Documents",
  REMARK: "Remarks",
  REMARK_PRINT: "           Remarks           ",
  WEEK_1: "   1   ",
  WEEK_2: "   2   ",
  WEEK_3: "   3   ",
  WEEK_4: "   4   ",
  WEEK_5: "   5   ",
  DOCUMENT_DETAILS: "Document Details",
  //

  FILE_UPLOAD: "File Upload",

  //
  ENTER_NAME: "Enter the name ",
  COUNTRY_NAME: "Country Name",
  COUNTRY_CODE: "Country Code",
  OPTIONS: "Options",
  STATE_NAME: "State Name ",
  BEAT: "Beat",
  BEAT_PJP: "Beat/PJP",
  BEAT_CODE: "Beat Code ",
  DAY: "Day",
  VEHICLE_NUMBER: "Vehicle Number",
  DRIVER_NAME: "Driver Name ",
  OWNER_NAME: "Owner Name",
  MAKE: "Make",
  MODEL: "Model",
  ENGINE_NUMBER: "Engine Number",
  CHASSIS_NUMBER: "Chassis Number",
  PERMIT_NUMBER: "Permit Number",
  PERMIT_UPTO: "Permit Upto",
  FITNESS_UPTO: "Fitness Upto",
  INSURANCE_UPTO: "Insurance Upto",
  PUC_UPTO: "PUC Upto",
  GPS_IMEI: "GPS IMEI",
  EXECUTIVE: "Executive",
  IS_ATTCHED: "Is Attched?",
  BRANCH_ID: "Branch ID",
  PURCHASE_DATE: "Purchase Date",
  FASTAG_NUMBER: "Fastag Number",
  PIN_CODE: "Pin Code",
  DIESEL_CARD_NUMBER: "Fuel Card Number",
  AVERAGE_CALL_TIME: "Average Call Time",
  SORTING_ORDER: "Sorting Order",
  //
  ROI: "ROI",
  ROI_CALCULATER: "ROI Calculater",

  //
  GRAMS: "Grams",
  QUANTITY: "Quantity",
  IS_QUANTITATIVE: "Is Quantitative ?",
  ALIAS_NAME: "Alias Name",
  BOX: "Box",
  PRODUCT_INSIDE: "Product Inside",
  SKU_NAME: "SKU Name",
  TAX: "Tax",
  HSN_CODE: "HSN Code",
  MIN_PRICE: "Min. Price",
  SUB_CATEGORY: "Sub Category",
  SUB_CATEGORY_NAME: "Sub Category Name",
  UPLOAD_PHOTO: "Upload Photo",

  //

  LOADING_STATUS: "Loading Status",
  GEO_FENCE_ID: "Geo Fence Id",
  DECIMAL_DELIMETER: "Decimal Delimeter",
  THOUSAND_DELIMETER: "Thousand Delimeter",
  COUNTRY_DIAL_CODE: "Country Dial Code ",
  STATE_CODE: "State Code ",
  CENTRAL_ZONE: "Central Zone",
  //
  NEW_CATEGORY: "New Category",
  INSERT_ABOVE: "Insert Above",
  //
  PIECE_PRICE: "Piece Price",
  BOX_PRICE: "Box Price",
  HOLIDAYS: "Holidays",
  USER_ROLE: "User Role",

  //
  LEAVE_TYPE: "Leave Type",
  MAX_DAYS: "Max Days",
  IS_MONTHLY: "Is Monthly ?",
  IS_LOP: "Is Lop ?",
  TARGETS: "Targets",
  TARGET_NAME: "Target Name ",
  IS_WEEKLY: "Is Weekly ?",
  IS_QTY_BASED: "Is Qty Based ?",
  ORDER_AMOUNT: "Order Amount",
  FROM: "From",
  TO: "To",
  CUSTOMER: "Customer",
  TRUE: "true",
  FALSE: "false",
  CUSTOMER_TYPE: "Customer Type ",
  MARGIN_PERCENTAGE: "Margin Percentage",
  USERS: "Users",
  LEAVE_NAME: "Leave Name",
  //
  CUSTOMER_NAME: "Customer Name",
  ROUTE: "Route",
  INSERT_BELOW: "Insert Below",
  LATITUDE: "Latitude",
  LONGITUDE: "Longitude",
  INSTRUCTIONS: "Instructions",
  TITLE: "Title",
  CREATED_USER: "Created User",
  CREATED_ON: "Created On",
  //
  CONFIRM_VERIFICATION_MSG:
    "Do you want to convert this lead for further processing?",
  DOCUMENT_VERIFIED: "Document Verified",

  VERIFIED_BY: "Verified By",
  VERIFIED_ON: "Verified On",

  ASSIGNED_ON: "Assigned On",
  APPROVED: "Approved",
  CONFIRM_LEAD_CONVERT_MSG: "Do You Want To Confirm Lead Convert?",

  //
  BUSINESS_TYPE: "Business Type",
  //
  ACCESS_RESON: "Access Reason",
  ACCESS_STATUS: "Access Status",
  AGEING: "Ageing",
  AGENCY_NAME: "Agency Name",
  BUSINESS_DESIGNATION: "Business Designation",
  BUSINESS_STATUS: "Business Status",
  FAMILY_MEMBERS: "Family Members",
  FINANCE_STATUS: "Finance Status",
  FIRM_PAN_NUMBER: "Firm Pan Number",
  FIRST_ORDER_NAME: "First Order Name",
  FIRST_ORDER_DATE: "First Order Date",
  FIRST_ORDER_DESIGNATION: "First Order Designation",
  GODOWN_STATUS: "Godown Status",
  CAPACITY: "Capacity ",
  MONTHLY_INCOME: "Monthly Income Source",
  YEARLY_INCOME: "Yearly Income Source",
  ONLINE_DATE: "Online Date",
  ONLINE_STATUS: "Online Status",
  OWNER_STATUS: "Owner Status",
  STORAGE_SIZE: "Storage Size",
  TECH_STATUS: "Tech Status",
  VEHICLE_STATUS: "Vehicle Status",
  VEHICLE_TYPE_STATUS: "Vehicle Type Status",
  VERIFIED_STATUS: "Verified Status",
  ORDER_SCHEDULE: "Order Schedule",
  WORK_CONDITION: "Work Condition",
  WORK_MODE_STATUS: "Work Mode Status",
  FSSAI_EXPIRY_DATE: "FSSAI Expiry Date",
  MANDATORY: "Mandatory",
  START_PERCENTAGE: "Start Percentage",
  REGION_ZONE: "Region Zone",
  //
  LEAD_SOURCE: "Lead Source",
  LEAD_NAME: "Lead Name",
  AREA_NAME: "Area Name",
  LEAD_STATUS: "Lead Status",
  DOCUMENT_NAME: "Document Name ",
  UPLOADED_ON: "Uploaded On",
  UPLOADED_BY: "Uploaded By",
  DOCUMENT_STATUS: "Document Status",
  UPDATED_BY: "Updated By",
  APPROVED_ON: "Approved On",
  DEALER: "Dealer",
  APPROVED_BY: "Approved By",
  BI_WEEKLY: "Bi-Weekly",
  POS_REPORT: "POS Report",
  RETAIL_POS_SALE_REPORT: "Retail POS Sales Report",
  SELECT_OUTLET: "Select Outlet",
  BILL_DATE: "Bill Date",
  BILL_NO: "Bill No.",
  MATERIAL_CODE: "Material Code",
  GST_SLAB: "GST Slab",
  BASIC_RATE: "Basic Rate",
  SGST_AMOUNT: "SGST Amount",
  CGST_AMOUNT: "CGST Amount",
  ROUND_OFF: "Round Off",
  NET_AMOUNT: "Net Amount",
  BILL_AMOUNT: "Bill Amount",
  BILL_TAX_TOTAL: "Bill Tax Total",
  GROUP_NAME: "Group Name",
  BILL_ITEM_REPORT: "Bill Item Report ",
  BILL_NUMBER: "Bill Number",
  CODE: "Code",
  BILL_REGISTER: "Bill Register",
  TOTAL: "Total",
  DAYWISE_MONTHLY_COLLECTION: "DayWise Monthly Collection",
  IBT_REGISTER: "IBT Register",
  IBT_OUTWARD_REGISTER: "IBT Outward Register",
  STOCK_REPORT: "Stock Report ",
  ITEM: "Item",
  OPENING_STOCK: "Opening Stock",
  PURCHASE: "Purchase",
  ADJUSTMENT: "Adjustment",
  CLOSING_STOCK: "Closing Stock",
  PRESENT_STOCK_REPORT: "Present Stock Report ",
  STOCK_ADJUSTMENT_REPORT: "Stock Adjustment Report ",
  ARRIVAL: "Arrival",
  PURCHASE_REPORT: "Purchase Report",
  BUYING_PRICE: "Buying Price",
  CONSOLIDATED_PURCHASE_REPORT: "Consolidated Purchase Report",
  ITEMS_LIST_REPORT: "Items List Report",
  SELLING_PRICE: "Selling Price",
  TAX_PERCENTAGE: "Tax Percentage",
  BUSINESS_DETAILS: "Business Details",
  JOB: "Job",
  WHOLESELLER: "Wholeseller",
  DISTRIBUTOR: "Distributor",
  OTHER: "Other",
  YEAR_OF_EXPERIENCE: "Year Of Experience",
  MONTHLY_TURNOVER: "Monthly Turnover",
  CURRENT_WORK_DETAILS: "Current Work Details",
  GST_AVAILABLE: "GST Available",
  FOOD_LICENCE: "Food Licence",
  INFRASTRUCTURE: "Infrastructure",
  DEPOSIT_REMARKS: "Deposit Remarks",
  CURRENT_WORK_STATUS: "Current Work Status",
  WAREHOUSE: "Warehouse",
  SALES_ORDER_REPORT: "Sales Order Report ",
  OFFLINE_ORDER_TIME: "Offline Order Time",
  SALES_PERSON: "Sales Person",
  SHOP_PARTY_NAME: "Shop Party Name",
  NUMBER_OF_ITEMS: "Number Of Items",
  ORDER_NUMBER: "Order Number",
  //
  DELETED_ORDER_REPORT: "Deleted Order Report",
  SALES_BILL_REPORT: "Sales Bill Report ",
  TIN_NUMBER: "Tin Number",
  DELETED_BILL_REPORT: "Deleted Bill Report ",
  PARTY_STATUS: "Party Status",
  VISIT_DATE_TIME: "Visit Date Time",
  SHOP_PART_NAME: "Shop Party Name",
  SHOP_PARTY_NUMBER: "Shop Party Number",
  SALES_BILL_REGISTER: "Sales Bill Register",
  BILL_TIME: "Bill Time",
  PARTY_GST_NUMBER: "Party GST Number",
  CITY_NAME: "City  Name",
  INVOICE_TYPE: "Invoice Type",
  PARTY_AMOUNT: "Party Amount",
  IGST_AMOUNT: "IGST Amount",
  SALES_RETURN_REPORT: "Sales Return Report",
  VOUCHER_TIME: "Voucher Time",
  SALESMAN_NAME: "Salesman Name",
  NUMBER_OF_QUANTITY: "Number Of Quantity",
  SALES_ORDER_VS_BILL_QTY_REPORT: "Sales Order VS Bill Qty Report",
  ORDER_DATE: "Order Date",
  ORDER_TIME: "Order Time",
  ORDER_QTY: "Order Qty",
  DELIVERY_QTY: "Delivery Qty",
  PENDING_QTY: "Pending Qty",
  PRODUCT_RATE: "Product Rate",
  SALES_BILL_PAYMENT_DETAILS: "Sales Bill Payment Details",
  CREDIT_DEBIT: "Credit / Debit",
  GSTIN_NUMBER: "GSTIN Number ",
  CASH: "CASH",
  UPI: "UPI",
  CARD: "CARD",
  DEBIT: "DEBIT",
  PURCHASE_BILL_SUMMARY: "Purchase Bill Summary",
  PO_NUMBER: "PO Number",
  TOTAL_SUMMARY_REPORT: "Total Summary Report ",
  PURCHASE_OREDER_NUMBER: "Purchase Order Number",
  PURCHASE_ORDER_TOTAL_AMOUNT: "Purchase Order Total Amount",
  SALES_ORDER_NUMBER: "Sales Order Number",
  SALES_ORDER_TOTAL_AMOUNT: "Sales Order Total Amount",
  SALES_BILL_NUMBER: "Sales Bill Number",
  SALES_BILL_TOTAL_AMOUNT: "Sales Bill Total Amount",
  SALE_RETURNS_NUMBER: "Sale Returns Number",
  SALE_RETURNS_TOTAL_AMOUNT: "Sale Returns Total Amount",
  SALESMAN_SUMMARY_REPORT: "Salesman Summary Report",
  LOCK: "Lock",
  IN_STOCK: "In Stock",
  NOT_SELLING: "Not Selling",
  CURRENT_STOCK_WITH_DETAILS: "Current Stock With Details",
  OPENING_CARTON_BAG: "Opening Carton Bag",
  OPENING_STRIP_PACKETS: "Opening Strip Packets",
  OPENING_AMOUNT: "Opening Amount",
  RECEIVED_CARTON_BAG: "Received Carton Bag",
  RECEIVED_STRIP_PACKETS: "Received Strip Packets",
  RECEIVED_AMOUNT: "Received Amount",
  ISSUE_CARTON_BAG: "Issue Carton Bag",
  ISSUE_STRIP_PACKETS: "Issue Strip Packets",
  ISSUE_AMOUNT: "Issue Amount",
  CLOSING_CARTON_BAG: "Closing Carton Bag",
  CLOSING_STRIP_PACKETS: "Closing Strip Packets",
  CLOSING_AMOUNT: "Closing Amount",
  DEALER_NAME: "Dealer Name",
  START_PHOTO: "Start Photo",
  END_PHOTO: "End Photo",
  START_TIME: "Start Time",
  END_TIME: "End Time",
  START_LOCATION: "Start Location",
  END_LOCATION: "End Location",
  REPORTING_MANAGER: "Reporting Manager",
  APPLIED_ON: "Applied On",
  LEAVE_FROM: "Leave From",
  LEAVE_TO: "Leave To",
  HIGHEST_QUALIFICATION: "Highest Qualification",
  INVESTMENT_AMOUNT: "Investment Amount",
  DEMO_IMAGES: "Demo Images",
  PHYSICAL_GODOWN_VERIFICATION: "Physical Godown Verification",
  LOGISTICS_VERIFICATIO: "Logistics Verification",
  SIGNATURE: "Signature",
  PROFILE: "Profile",
  CUSTOMER_LIST: "Customer List",
  LOCATION: "Location",
  VIEW_LOCATION: "View Location",
  ASSIGN_BEAT: "Assign Beat",
  LOCATION_UPDATE: "Location Update",
  NOT_DONE: "Not Done",
  DONE: "Done",
  ID: "Id",
};

export const CONFIRMS = {
  SURE_TO_LOGOUT: "Are you sure want to logout..?",
  DELETE_USER: "Are you sure want to delete this user..?",
  SURE_TO_APPROVE: "Are you sure want to approve this..?",
  SURE_TO_SAVE: "Are you sure want to save this..?",
};

export const SUCCESS = {
  RECORD_SUCCESS: "Record Saved Successfully..!",

  PASSWORD_CHANGED: "Password Changed Successfully..!",
  LOGIN_SUCCESS: "Login Successful, Redirecting to home page..!",
  LOGOUT_SUCCESS: "Logout successful, Redirecting to login page..!",
  SIGNUP_SUCCESS: "Signup successful..!",
  PROPERTY_SAVED: "Property Saved Successfully..!",
  FILE_UPLOADED: "File Uploaded Successfully..!",
  PROJECT_SUCCESS: "Project Saved Successfully..!",
  ORGANIZATION_SUCCESS: "Organization Saved Successfully..!",
  USER_SUCCESS: "User Saved Successfully..!",
  CHART_INFO_UPDATED: "Chart Info Updated Successfully..!",
  FILE_APPROVED: "File Approved Successfully..!",
};

export const WARNINGS = {
  PLEASE_SELECT_DEALER: "Please select dealer..!",
  PLEASE_SELECT_INTERVIEW_PROCESS: "Please select interview process..!",
  MANDATORY_COMMENTS_REQUIRED: "Few approvals are pending..!",
  ARE_YOU_SURE: "Are you sure..?",
  MANDATORY: "Please fill mandatory fields..!",
  PLEASE_FILL_MANDATORY_FIELDS: "Please fill all the mandatory fields..!",
  PLEASE_SELECT_ASSIGNED_BY: "Please select assigned by..!",
  QUESTION_EMPTY: "Please enter the question..!",
  WORK_PACKAGE_EMPTY: "Work Package is Mandatory..!",

  PROJECT_TYPE_EMPTY: "Project Type Name is Mandatory..!",
  DESIGNATION_NAME_EMPTY: "Designation Name is Mandatory..!",
  RESPONSIBILITY_NAME_EMPTY: "Responsibility Name is Mandatory..!",
  EMP_IS_EMPTY: "Employee is mandatory..!",

  BOTH_PASSWORDS_SAME: "Current & New Password should not be same..!",
  NPWD_LENGTH_SMALL:
    "New Password Length should be greater than 6 characters..!",
  CPWD_EMPTY: "Current Password is empty..!",
  NPWD_EMPTY: "New Password is empty..!",
  DATE_EMPTY: "Please select date..!",
  TASK_EMPTY: "Please select Task..!",
  FILE_NOT_FOUND: "File Not Found..!",
  INVALID_EMAIL: "Invalid Email ID..!",
  USER_TYPE_EMPTY: "Please Select User Type..!",
  USER_NAME_IS_EMPTY: "Please Enter User Name..!",
  FIRST_NAME_IS_EMPTY: "Please Enter First Name..!",
  CONTACT_NO_IS_EMPTY: "Please Enter Incharge Contact Number..!",
  INCHARGE_IS_EMPTY: "Please Enter Incharge Name..!",
  ORG_NAME_IS_EMPTY: "Please Enter Organization Name..!",
  ZIPCODE_IS_EMPTY: "Please Enter Zipcode..!",
  ADDRESS_IS_EMPTY: "Please Enter Address..!",
  SUPERVISOR_EMPTY: "Please select Supervisor..!",
  ORG_EMPTY: "Please select Organization..!",
  PROJECT_NAME_EMPTY: "Please enter the Project Name..!",
  PROJECT_EMPTY: "Please select project..!",
  FILE_EMPTY: "Please select file..!",
  USERNAME_IS_EMPTY: "Username is mandatory..!",
  PASSWORD_IS_EMPTY: "Password is mandatory..!",
  PROPERTY_TYPE_EMPTY: "Please select property type..!",
  STATE_EMPTY: "Please select state..!",
  CITY_EMPTY: "Please select city..!",
  AREA_EMPTY: "Please select Area..!",
  ADDRESS_EMPTY: "Please enter address..!",
  PROPERTY_NAME_EMPTY: "Please enter address..!",
  PROPERTY_DESC_EMPTY: "Please enter property description..!",
  PROPERTY_OWNER_EMPTY: "Please enter property owner name..!",
  DATA_IS_EMPTY: "Data cannot be empty..!",
  //
  FILE_SIZE_EXCEEDED: "File size exceeded..!",
  //
  IS_A_REQUIRED_FIELD: "is a required field",
  PLEASE_CHECK_FORM_ERRORS: "Please check form errors..!",
  PLEASE_ACCEPT_ALL_TERMS: "Please accept all terms ",
  SHOULD_BE_GREATER_THAN_ZERO: "Should be greater than zero",

  MIN_CHAR_REQUIRED_FIELD: "requires minimum 4 letters..!",
};

export const ERRORS = {
  RECORD_FAILED: "Record Not Saved..!",
  LOGIN_FAILED: "Login Failed: Invalid Username or Password..!",
  LOGOUT_FAILED: "Logout failed..!",
  SCORE_FAILED: "Score Calculations Failed..!",
  FILE_NOT_UPLOADED: "File Not Uploaded Successfully..!",
  PROJECT_FAILED: "Project Not Saved..!",
  ORGANIZATION_FAILED: "Organization Not Saved..!",
  USER_FAILED: "User Not Saved..!",
  CHART_INFO_NOT_UPDATED: "Chart Info Not Updated Successfully..!",
  FILE_APPROVE_FAILED: "File Not Approved Successfully..!",
  PRINT_ERROR: "Print failed, please try again..!",
};

export const INFO = {
  CLICK_HERE_STREETVIEW: "Click for streetview",
  CONGRATULATION_LEAD_CONVERTED: "Congratulations, Lead is converted !",
  UPLOAD_LEGAL_DOC: "Please upload the signed legal document here",
};
