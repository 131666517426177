/*
 * Google Street View
 */
import React, { useEffect, useRef, useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  StreetViewPanorama,
  Marker,
  InfoWindow,
  MapContext,
  Circle,
} from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LatLngExpression } from "leaflet";

import "./styles/index.css";

import StreetView from "./StreetView";
import axios from "axios";
import TxtBox from "components/Custom/TxtBox";
import { GEO_APIs } from "apihandlers/geography";
import { API_CONFIG, CONFIG } from "common/config";
import { COLORS } from "common/colors";
import Btn from "components/Custom/Btn";
import { CUSTOMER_APIs } from "apihandlers/customer";
import LeadProfile from "components/Crm/LeadProfile";

const GOOGLE_MAPS_API_KEY = "AIzaSyBvuPV_NBMRG1OLDntdJvarJsWFhzgPjbs"; // neyaai2179@gmail.com
const GMAP_KEY_2 = "AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg";

const shopImg = require("./images/shop.png");
const shopSmallImg = require("./images/shop_32x32.png");
const personImg = require("./images/person.png");
const locationImg = require("./images/location_32x32.png");

interface defaultProps {}
const StreetViewMap = (props: defaultProps) => {
  let _timer = null;
  // States
  const [leads, setLeads] = useState<any>([]);
  const [address, setAddress] = React.useState("");
  const [map, setMap] = React.useState(null);
  const [center, setCenter] = useState<any>({
    lat: 15.2993,
    lng: 74.124,
  });
  const [marker, setMarker] = useState<any>({
    position: center,
    icon: personImg,
    title: "",
  });
  const [shops, setShops] = useState<any>([]);
  const [filter, SetFilter] = useState("store");
  const [circleRadius, setCircleRadius] = useState(10);
  const [addressPos, setAddressPos] = useState<LatLngExpression>([
    center.lat,
    center.lng,
  ]);

  useEffect(() => {
    const args = {};
    CUSTOMER_APIs.getCrmLeadProfiles(args).then((res: any) => {
      if (res.status === 200) {
        setLeads(res.response.records);
      }
    });
    setShops([]);
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    let panorama: google.maps.StreetViewPanorama;
    panorama = new google.maps.StreetViewPanorama(
      document.getElementById("gMap") as HTMLElement,
      {
        position: center,
        pov: {
          heading: 34,
          pitch: 10,
        },
        zoom: 10,
      }
    );
    map.setStreetView(panorama);
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPositionChanged = (lat, lng) => {
    setCenter({ lat, lng });
    setMarker({
      position: { lat, lng },
      icon: personImg,
      title: "",
    });
    setAddressPos([lat, lng]);
  };

  const onSearch = (e, f) => {
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      const qry = e.target.value;
      const apiUrl =
        "https://maps.googleapis.com/maps/api/place/autocomplete/json";
      axios
        .get(apiUrl, {
          params: {
            address: qry, // Your parameters here
            key: GOOGLE_MAPS_API_KEY,
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 250);
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        (document.getElementById("placeSearch") as HTMLInputElement).value =
          results[0].formatted_address;
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        onPositionChanged(latLng.lat, latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };

  const onFilterChange = () => {
    const val = (
      document.getElementById("filterSearch") as HTMLInputElement
    ).value.trim();
    SetFilter(val);
    if (addressPos) {
      if (addressPos[1]) {
        getStoresList(val, addressPos[0], addressPos[1]);
      }
    }
  };

  const getStoresList = async (keyword, lat, lng) => {
    // setFilterLoading(true);
    const args = {
      lat,
      lng,
      radius: circleRadius,
      keyword,
    };
    try {
      const res: any = await GEO_APIs.getGoogleResult(args);
      console.log("getStoresList:", res);
      // setFilterLoading(false);
      setShops(
        res.response.map((r: any, i) => {
          let photo = "";
          console.log(r.photos);
          if (r.photos) {
            photo = r.photos[0].photo_reference;
          }
          let addrs = r.vicinity.split(", ");
          addrs.shift();
          return {
            name: r.name,
            position: {
              lat: r.geometry.location.lat,
              lng: r.geometry.location.lng,
            },
            photo: photo,
            address: addrs.toString(),
          };
        })
      );
    } catch (error) {
      console.log("getStoresList:", error);
    }
  };

  const mapHeight = window.innerHeight * 0.4;
  const streetViewRef: any = useRef(null);

  console.log("shops: ", shops);

  return (
    <div className="streetView">
      <div style={{ width: "75%", backgroundColor: "#fff" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: mapHeight,
          }}
        >
          <div style={{ width: "50%" }}>
            {window.google && (
              <GoogleMap
                mapContainerStyle={{
                  height: mapHeight,
                }}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={(e) => {
                  console.log("pos:", e.latLng.lat() + "," + e.latLng.lng());
                }}
              >
                {/* Radius */}
                {addressPos && (
                  <Circle
                    center={{
                      lat: addressPos[0],
                      lng: addressPos[1],
                    }}
                    radius={circleRadius}
                    options={{
                      fillColor: COLORS.SECONDARY,
                      strokeColor: COLORS.SECONDARY,
                    }}
                  />
                )}

                {/* Leads Markers */}
                {leads.map((lead, i) => {
                  return (
                    <Marker
                      key={"lead-" + i}
                      position={{
                        lat: Number(lead.latitude),
                        lng: Number(lead.longitude),
                      }}
                      title={lead.name}
                      label={lead.name}
                      icon={shopSmallImg}
                    />
                  );
                })}

                {/* Shops Markers */}
                {shops.map((shop, i) => {
                  return (
                    <Marker
                      key={"shopmarker-" + i}
                      position={shop.position}
                      title={shop.name}
                      label={shop.name}
                      icon={locationImg}
                    />
                  );
                })}

                <Marker
                  position={marker.position}
                  icon={marker.icon}
                  // draggable={true}
                  // onDragStart={() => {
                  //   streetViewRef.updateStreetPosition(null);
                  // }}
                  // onDragEnd={(e) => {
                  //   const lat = e.latLng.lat();
                  //   const lng = e.latLng.lng();
                  //   setCenter({
                  //     lat,
                  //     lng,
                  //   });
                  //   streetViewRef.updateStreetPosition({ lat, lng });
                  // }}
                >
                  {/* <InfoWindow position={marker.position}>
                    <div
                      style={{
                        backgroundColor: "white",
                        opacity: 0.8,
                      }}
                    >
                      <h2>
                        {marker.position.lat.toFixed(6) +
                          "," +
                          marker.position.lng.toFixed(6)}
                      </h2>
                    </div>
                  </InfoWindow> */}
                </Marker>
              </GoogleMap>
            )}
          </div>
          <div style={{ width: "50%" }}>
            <StreetView
              childRef={streetViewRef}
              height={mapHeight}
              position={center}
              onPositionChanged={(e) => {
                const lat = e.lat();
                const lng = e.lng();
                onPositionChanged(lat, lng);
              }}
            />
          </div>
        </div>
        <div className="mapFilters">
          <div
            style={{
              width: "48%",
              paddingLeft: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: -8,
            }}
          >
            {/* <TxtBox id={"placeSearch"} onKeyPress={onSearch} /> */}

            {/* {window.google && (
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div style={{ width: "100%" }}>
                    <TxtBox
                      id="placeSearch"
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )} */}
          </div>

          <div
            className="flexRowJustify width50"
            style={{
              background: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "30%",
                paddingLeft: "0.5rem",
                paddingTop: "0.2rem",
              }}
            >
              <label style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                {"Filter Radius: ( " + circleRadius + " Mtrs )"}
              </label>
              <input
                type={"range"}
                id={"radiusRange"}
                max={100000}
                min={500}
                value={circleRadius}
                // disabled={filterLoading}
                style={{ width: "90%" }}
                onChange={(e: any) => {
                  setCircleRadius(Number(e.target.value));
                }}
              />
            </div>
            <div className="shopCategoryfilters">
              <TxtBox id="filterSearch" defaultValue={"paan"} />
              <div style={{ paddingTop: 8, display: "flex" }}>
                <Btn
                  text={"Search"}
                  marginRight={10}
                  onClick={onFilterChange}
                />
                <Btn
                  text={"Clear"}
                  onClick={() => {
                    setShops([]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Lead Grid */}
        <div className="mapLeadGrid">
          <LeadProfile />
        </div>
      </div>

      {/* Shops List */}
      <div style={{ width: "25%" }} className="mapShops">
        <h4
          style={{
            textTransform: "uppercase",
            borderBottom: "1px solid #ccc",
          }}
        >
          {"Shops List"}
        </h4>
        {shops.map((shop: any, i: number) => {
          return (
            <div className="shop" key={"shop-" + i}>
              <div>
                <img src={shopImg} width={"100%"} />
              </div>
              <div
                onClick={() =>
                  onPositionChanged(shop.position.lat, shop.position.lng)
                }
              >
                <h4 className="shopName">{shop.name}</h4>
                <p className="details">{shop.address}</p>
              </div>
            </div>
          );
        })}
        <Btn text={"Add New Lead"} paddingLeft={10} paddingRight={10} />
      </div>
    </div>
  );
};

export default StreetViewMap;
