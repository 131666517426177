import React from "react";

import HGraph from "components/Custom/HGraph";

import { GRAPH_TYPES } from "common/config";

const BarGraph = (props: any) => {
  const { height } = props;
  console.log("BarGraph: ", height);
  return (
    <React.Fragment>
      <div className="graphs">
        <HGraph
          width={"100%"}
          height={height}
          type={GRAPH_TYPES.BAR}
          float={"right"}
          xAxis={["Converted", "Rejected", "In-Progress", "Visited"]}
          yAxis={[90, 80, 85, 70]}
          title={"Progress"}
          exportTitle={"Bar Graph Record "}
          xAxisTitle=""
          yAxisTitle=""
        />
      </div>
    </React.Fragment>
  );
};

export default BarGraph;
