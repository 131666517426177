/*
 * Google Street View
 */
import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  Map,
  ZoomControl,
  ScaleControl,
  TileLayer,
  FeatureGroup,
  withLeaflet,
} from "react-leaflet";
//Leaflet
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Tooltip, Marker, Polygon, Polyline, Popup } from "react-leaflet";
import { withSnackbar } from "notistack";
// import { EditControl } from "react-leaflet-draw";

import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

import LayersIcon from "@mui/icons-material/Layers";
import IconButton from "@mui/material/IconButton";
import { Slider } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Loader from "components/Custom/Loader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import Popover from "@mui/material/Popover";

import "./styles/index.css";
import "./styles/fleetmap.css";

import Btn from "components/Custom/Btn";
import SkeletonBox from "components/Custom/SkeletonBox";
import AutoComplete from "components/Custom/AutoComplete";

import { CONFIG, DEFAULTS, NOTIFY, LOCAL_STORAGE } from "common/config";
import { LABELS, WARNINGS } from "common/lables";
import { COLORS } from "common/colors";
import { COMMON_JS } from "common/scripts";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { MASTER_APIs } from "apihandlers/master";
import { GEO_APIs } from "apihandlers/geography";

const GOOGLE_MAPS_API_KEY = "AIzaSyBvuPV_NBMRG1OLDntdJvarJsWFhzgPjbs"; // neyaai2179@gmail.com
const GMAP_KEY_2 = "AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg";

const otherImg = require("./images/marker-other.png");
const gopalImg = require("./images/marker-gopal.png");
// const markerRed = require("./images/location_32x32.png");
// const chipsIcon = require("./images/chips-gopal.png");

// const boundaryData = require("./Boundaries.json");
// const rangeData = require("./RangeKm.json");
// const polyData = require("./PolygonData.json");

interface defaultProps {}

const CustomersOnMap = (props: any) => {
  const { setPageMenu } = props;
  let _timer = null;
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [customers, setCustomers] = useState<any>([]);
  const [dealers, setDealers] = useState<any>([]);

  const [leads, setLeads] = useState<any>([]);
  const [address, setAddress] = React.useState("");
  const [map, setMap] = React.useState(null);
  const [center, setCenter] = useState<any>({
    lat: 15.2993,
    lng: 74.124,
  });

  const [sales, setSales] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mapTypes, setMapTypes] = useState<boolean>(false);

  let mType = localStorage.getItem(LOCAL_STORAGE.MAP_TYPE);
  const [selectedMapType, setSelectedMapType] = useState<any>(
    mType ? Number(mType) : 0
  );

  useEffect(() => {
    if (loading) {
      getDealers();
    }
  }, [loading]);

  const getDealers = async () => {
    try {
      const args = {};
      const res: any = await MASTER_APIs.getOrgProfiles(args);
      if (res.status === 200) {
        setDealers(
          res.response.records.map((rec: any) => {
            return {
              id: rec.id,
              label: rec.organizationName,
            };
          })
        );
        setLoading(false);
      }
    } catch (error) {
      console.log("getDealers: ", error);
    }
  };

  const getCustomers = async () => {
    try {
      // const orgId = dealersRef.current.selectedValue.id;
      // if (!orgId) {
      //   COMMON_JS.showNotify(
      //     props,
      //     NOTIFY.WARNING,
      //     WARNINGS.PLEASE_SELECT_DEALER
      //   );
      //   return;
      // }
      const args = {
        // orgId,
      };
      setRefresh(true);
      const res: any = await CUSTOMER_APIs.getAllCustomersOnMap(args);
      if (res.status === 200) {
        setCustomers(res.response.records);
        setRefresh(false);
      }
    } catch (error) {
      console.log("getCustomers: ", error);
    }
  };

  // const getMonthSales = async (e, newValue: any) => {
  //   // setSales([]);
  //   try {
  //     const args = {
  //       month: newValue / 8,
  //     };
  //     const res = await GEO_APIs.getSalesAnalysis(args);
  //     const { response, status } = res;
  //     if (status === 200) {
  //       console.log("months", res.response);
  //       setSales(res.response);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching sales data:", error);
  //   }
  // };

  const createClusterCustomIcon = (cluster) => {
    const count = cluster.getChildCount();
    let size = "LargeXL";
    if (count < 10) {
      size = "Small";
    } else if (count > 9 && count < 100) {
      size = "Medium";
    } else if (count > 99 && count < 1000) {
      size = "Large";
    }
    const options = {
      cluster: `markerCluster${size}`,
    };
    return L.divIcon({
      html: `<div>
          <span class="markerClusterLabel">${count}</span>
        </div>`,
      className: `${options.cluster}`,
    });
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPositionChanged = (lat, lng) => {
    setCenter({ lat, lng });
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        (document.getElementById("placeSearch") as HTMLInputElement).value =
          results[0].formatted_address;
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        onPositionChanged(latLng.lat, latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };

  const getTileUrl = (mapType = 0) => {
    localStorage.setItem(LOCAL_STORAGE.MAP_TYPE, mapType.toString());
    let url = CONFIG.OSM_TILE_URL;
    switch (mapType) {
      case 0:
        url = CONFIG.OSM_TILE_URL;
        break;
      case 1:
        url = CONFIG.GOOGLE_STREETS_MAP_URL;
        break;
      case 2:
        url = CONFIG.GOOGLE_SATELLITE_MAP_URL;
        break;
      case 3:
        url = CONFIG.GOOGLE_HYBRID_MAP_URL;
        break;
      default:
        url = CONFIG.OSM_TILE_URL;
        break;
    }
    return url;
  };

  const getMapMarker = (orgId) => {
    let iconUrl = gopalImg;
    if (orgId != 2) {
      // If not gopal
      iconUrl = otherImg;
    }
    //else {
    //   iconUrl = markerRed;
    // }
    // if (orgId === 2) {
    //   iconUrl = chipsIcon;
    // }
    let customMarker = null;
    let width = 26;
    let angle = 0;

    const html =
      "<div class='movingMarker' id='movingmarker-" +
      orgId +
      "' style='transform: rotate(" +
      angle.toString() +
      "deg);'><img src='" +
      iconUrl +
      "' alt='' width='" +
      width +
      "px'/></div>";

    customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(16, 16),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "mapMarker",
    });

    return customMarker;
  };

  const mapRef: any = useRef(null);
  const dealersRef: any = useRef(null);

  const mPos = {
    lat: 15.2993,
    lng: 74.124,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMapTypes(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMapTypes(false);
  };

  // const months = [
  //   {
  //     value: 8,
  //     label: "Jan",
  //   },
  //   {
  //     value: 16,
  //     label: "Feb",
  //   },
  //   {
  //     value: 24,
  //     label: "Mar",
  //   },
  //   {
  //     value: 32,
  //     label: "Apr",
  //   },
  //   {
  //     value: 40,
  //     label: "May",
  //   },
  //   {
  //     value: 48,
  //     label: "Jun",
  //   },
  //   {
  //     value: 56,
  //     label: "Jul",
  //   },
  //   {
  //     value: 64,
  //     label: "Aug",
  //   },
  //   {
  //     value: 72,
  //     label: "Sep",
  //   },
  //   {
  //     value: 80,
  //     label: "Oct",
  //   },
  //   {
  //     value: 88,
  //     label: "Nov",
  //   },
  //   {
  //     value: 96,
  //     label: "Dec",
  //   },
  // ];

  // const boundData = boundaryData.all_coordinates;
  // const areaData = polyData.boundary_coordinates;
  // const range = rangeData.all_ranges;
  // const nearbyPlace = rangeData.nearPlace;
  // const routeCordinates = rangeData.route_cordinates;
  // const cordinates = routeCordinates.map((route, i) => {
  //   return [route.lat, route.lng];
  // });

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        {/* <div className="formGroup width30">
          <label>{LABELS.DEALER}</label>
          <AutoComplete
            list={dealers}
            childRef={dealersRef}
            defaultValue={null}
            placeholder={"Select Dealer"}
            onClick={() => setLoading(true)}
            // onChange={() => getCustomers()}
          />
        </div> */}
        <Btn
          text={LABELS.LOAD}
          bgColor={COLORS.RELOAD_EXPORT}
          color={COLORS.RELOAD_EXPORT_TEXT}
          onClick={() => getCustomers()}
        />
      </div>
      {loading || refresh ? (
        <div>
          <SkeletonBox height={window.innerHeight * 0.82} width={"98%"} />
        </div>
      ) : (
        <div id="mapContainer" className={"mapContainer"}>
          <Map
            center={mPos}
            zoom={10}
            maxZoom={DEFAULTS.MAX_ZOOM}
            minZoom={DEFAULTS.MIN_ZOOM}
            viewport={null}
            // onClick={this.mapClick}
            className="fleetMap"
            zoomControl={true}
            // onMoveend={this.onDragMap}
            // onzoomend={this.onZoomEnd}
            // ref={mapRef}
            style={{
              width: window.innerWidth * 0.92,
              height: window.innerHeight * 0.82,
            }}
          >
            <TileLayer url={getTileUrl(selectedMapType)} />
            {/* <div style={{ margin: "20px" }}> */}
            <ZoomControl position="topright" />
            {/* /     </div> */}
            <MarkerClusterGroup
              iconCreateFunction={createClusterCustomIcon}
              showCoverageOnHover={true}
            >
              {customers.map((customer, i) => {
                return (
                  <Marker
                    position={{
                      lat: customer.lat,
                      lng: customer.lng,
                    }}
                    icon={getMapMarker(customer.orgId)}
                    key={"mapMarker-" + i}
                    className="mapMarker"
                  >
                    <Tooltip
                      direction="right"
                      offset={[10, -2]}
                      permanent
                      className={"makerLabel"}
                    >
                      <span>{customer.name}</span>
                    </Tooltip>
                  </Marker>
                );
              })}

              {/*  */}
              {/* <MapPopup
              vehicle={vehicle}
              data={data}
              showTrack={showTrack}
              shareVehicle={shareVehicle}
             /> */}
            </MarkerClusterGroup>

            {/* {sales &&
              sales.map((sold, i) => {
                return (
                  <Marker
                    position={{ lat: sold.latitude, lng: sold.longitude }}
                    icon={getMapMarker(2)}
                    key={"mapMarker-" + i}
                    className="mapMarker"
                    draggable={false}
                  >
                    <Tooltip
                      direction="right"
                      offset={[10, -2]}
                      className={"makerLabel"}
                    >
                      <span>{sold.product}</span>
                    </Tooltip>
                  </Marker>
                );
              })}

            <Polyline positions={range} color="blue" /> */}

            {/* <Polygon
              positions={areaData}
              color={"red"}
              fillColor={"orange"}
              //className="zone"
            /> */}
            {/* {boundData.map((boundary, i) => {
              return (
                <Marker
                  position={boundary}
                  icon={getMapMarker(2)}
                  key={"mapMarker-" + i}
                  className="mapMarker"
                ></Marker>
              );
            })} */}

            {/* {areaData.map((area, i) => {
              return (
                <Marker
                  position={area}
                  icon={getMapMarker(area.orgId)}
                  key={"mapMarker-" + i}
                  className="mapMarker"
                  draggable={true}
                ></Marker>
              );
            })}

            {nearbyPlace.map((place, i) => {
              return (
                <Marker
                  position={place.location}
                  icon={getMapMarker(4)}
                  key={"mapMarker-" + i}
                  className="mapMarker"
                  draggable={true}
                >
                  <Tooltip
                    direction="right"
                    offset={[10, -2]}
                    className={"makerLabel"}
                  >
                    <span>{place.name}</span>
                  </Tooltip>
                  <Popup>
                    <div>
                      <label>{place.name}</label>
                      <hr></hr>
                      <label>{place.location}</label>
                    </div>
                  </Popup>
                </Marker>
              );
            })} */}

            {/* <Polyline positions={cordinates} color="red" weight={5} /> */}
          </Map>

          <IconButton
            aria-label="Map Types"
            size="large"
            style={{
              color: "black",
              position: "fixed",
              bottom: "120px",
              right: "100px",
              width: "48px",
              height: "48px",
              background: "white",
              borderRadius: "4px",
            }}
            onClick={handleClick}
          >
            <LayersIcon />
          </IconButton>
          <Popover
            style={{ margin: "-12px" }}
            open={mapTypes}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <List dense className="mapTypes uiPopOver">
              {DEFAULTS.MAP_TYPES.map((m, i) => {
                return (
                  <ListItem
                    key={"mtype-" + i}
                    disablePadding
                    onClick={() => {
                      setSelectedMapType(i);
                      setMapTypes(false);
                    }}
                    className={i === selectedMapType ? "active" : ""}
                  >
                    <ListItemButton>
                      <ListItemText primary={m} className="listText" />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popover>

          {/* <div
            style={{
              position: "absolute",
              right: "35%",
              marginTop: "10px",
              backgroundColor: "white",
              borderRadius: "5px",
              opacity: "0.9",
              padding: "10px 30px",
              width: "500px",
              height: "50px",
              zIndex: "999999999",
            }}
          >
          

            <Slider
              step={null}
              track={false}
              defaultValue={[0]}
              marks={months}
              onChangeCommitted={(event, newValue) => {
                getMonthSales(event, newValue);
              }}
            />
          </div> */}
          {/* {loading && (
            <>
              <div className="progressBar">
                <LinearProgress />
              </div>
              <div className="mapOverLay">
                <Loader size="40px"></Loader>
                <p className="bold">Loading...</p>
              </div>
            </>
          )} */}
        </div>
      )}
    </div>
  );
};

export default withSnackbar(CustomersOnMap);
