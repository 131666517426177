import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import RadioBtn from "components/Custom/RadioBtn";
import TxtRow from "components/Custom/TxtRow";
import UiDialog from "components/Custom/UiDialog";
import AutoComplete from "components/Custom/AutoComplete";
import { COMMON_JS } from "common/scripts";
import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/lables";

import { MASTER_APIs } from "apihandlers/master";
import { GEO_APIs } from "apihandlers/geography";

const OrganizationProfile = (props: any) => {
  const { setDashMenu } = props;

  const areaRef: any = useRef(null);

  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [areas, setAreas] = useState<any>(null);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "organizationName",
      name: LABELS.ORG_NAME,
      width: "13%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "organizationAddress1",
      name: LABELS.ORG_ADDRESS1,
      width: "12%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "organizationAddress2",
      name: LABELS.ORG_ADDRESS2,
      width: "11%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "organizationEmail",
      name: LABELS.ORG_EMAIL,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "organizationPinCode",
      name: LABELS.ORG_PIN,
      width: "6%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "organizationMobile",
      name: LABELS.ORG_MOBILE,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "organizationGst",
      name: LABELS.ORG_GST,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "organizationPan",
      name: LABELS.ORG_PAN,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "areaName",
      name: LABELS.AREA,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      width: "9%",
      editable: false,
      sortable: true,
      align: "center",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: true,
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getArea();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getOrgProfiles(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getOrgProfiles:", error);
    }
  };

  const getArea = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setAreas(list);
        getRows();
      }
    });
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const manageOrgProfile = () => {
    const organizationName = (
      document.getElementById("organizationName") as HTMLInputElement
    ).value.trim();
    const organizationAddress1 = (
      document.getElementById("organizationAddress1") as HTMLInputElement
    ).value.trim();
    const organizationAddress2 = (
      document.getElementById("organizationAddress2") as HTMLInputElement
    ).value.trim();
    const organizationPinCode = (
      document.getElementById("organizationPinCode") as HTMLInputElement
    ).value.trim();
    const organizationMobile = (
      document.getElementById("organizationMobile") as HTMLInputElement
    ).value.trim();
    const organizationEmail = (
      document.getElementById("organizationEmail") as HTMLInputElement
    ).value.trim();
    const organizationGst = (
      document.getElementById("organizationGst") as HTMLInputElement
    ).value.trim();
    const organizationPan = (
      document.getElementById("organizationPan") as HTMLInputElement
    ).value.trim();
    const areaId = areaRef.current.selectedValue.id;
    let ele = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = ele ? ele.value : "";

    // Validations
    let errors = [];
    if (organizationName === "") {
      errors.push({
        id: "organizationName",
        msg:
          "<span class='bold'>" +
          LABELS.ORG_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (organizationAddress1 === "") {
      errors.push({
        id: "organizationAddress1",
        msg:
          "<span class='bold'>" +
          LABELS.ORG_ADDRESS1 +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (organizationAddress2 === "") {
      errors.push({
        id: "organizationAddress2",
        msg:
          "<span class='bold'>" +
          LABELS.ORG_ADDRESS2 +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (organizationPinCode === "") {
      errors.push({
        id: "organizationPinCode",
        msg:
          "<span class='bold'>" +
          LABELS.ORG_PIN +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (organizationMobile === "") {
      errors.push({
        id: "organizationMobile",
        msg:
          "<span class='bold'>" +
          LABELS.ORG_MOBILE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!areaId) {
      errors.push({
        id: "areaId",
        msg:
          "<span class='bold'>" +
          LABELS.AREA +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      organizationName,
      organizationAddress1,
      organizationAddress2,
      organizationPinCode,
      organizationMobile,
      organizationEmail,
      organizationGst,
      organizationPan,
      areaId,
      activeStatus: activeStatus.toString(),
    };

    setIsSubmit(true);
    MASTER_APIs.manageOrgProfile(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("Page: OrganizationProfile:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.ORGANIZATION_PROFILE}</li>
        </ul>
        <h4>{TITLES.ORGANIZATION_PROFILE}</h4>
        <p>{SUB_TITLES.ORGANIZATION_PROFILE}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"orgProfile"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.ORGANIZATION_PROFILE}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                TITLES.ORGANIZATION_PROFILE +
                ": " +
                selectedRow.organizationName
              : "Add " + TITLES.ORGANIZATION_PROFILE
          }
          open={true}
          size={"md"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexColumn">
            <div className="flexRowJustify">
              <div className="formGroup red width45">
                <TxtRow
                  id={"organizationName"}
                  label={LABELS.ORG_NAME}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationName : ""
                  }
                  type={"text"}
                  required={true}
                  disabled={isSubmit}
                />
              </div>
              <div className="formGroup red width45">
                <TxtRow
                  id={"organizationAddress1"}
                  label={LABELS.ORG_ADDRESS1}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationAddress1 : ""
                  }
                  type={"text"}
                  required={true}
                  disabled={isSubmit}
                />
              </div>
            </div>
            <div className="flexRowJustify">
              <div className="formGroup red width45">
                <TxtRow
                  id={"organizationAddress2"}
                  label={LABELS.ORG_ADDRESS2}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationAddress2 : ""
                  }
                  type={"text"}
                  required={true}
                  disabled={isSubmit}
                />
              </div>
              <div className="formGroup red width45">
                <TxtRow
                  id={"organizationPinCode"}
                  type={"number"}
                  required={true}
                  label={LABELS.ORG_PIN}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationPinCode : ""
                  }
                  disabled={isSubmit}
                />
              </div>
            </div>
            <div className="flexRowJustify">
              <div className="formGroup red width45">
                <TxtRow
                  id={"organizationMobile"}
                  label={LABELS.ORG_MOBILE}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationMobile : ""
                  }
                  type={"text"}
                  required={true}
                  disabled={isSubmit}
                />
              </div>
              <div className="formGroup red width45">
                <label>
                  {LABELS.AREA}
                  {<span className="red">*</span>}
                </label>
                <AutoComplete
                  list={areas}
                  childRef={areaRef}
                  defaultValue={
                    selectedRow.areaId
                      ? {
                          id: selectedRow.areaId,
                          label: selectedRow.areaName,
                        }
                      : null
                  }
                  onChange={(e, v) => {}}
                  disabled={isSubmit}
                />
              </div>
            </div>
            <div className="flexRowJustify">
              <div className="formGroup  width45">
                <TxtRow
                  id={"organizationEmail"}
                  label={LABELS.ORG_EMAIL}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationEmail : ""
                  }
                  type={"text"}
                  disabled={isSubmit}
                />
              </div>
              <div className="formGroup width45">
                <TxtRow
                  id={"organizationGst"}
                  label={LABELS.ORG_GST}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationGst : ""
                  }
                  type={"text"}
                  disabled={isSubmit}
                />
              </div>
            </div>
            <div className="flexRowJustify">
              <div className="formGroup  width45">
                <TxtRow
                  id={"organizationPan"}
                  label={LABELS.ORG_PAN}
                  defaultValue={
                    selectedRow.id ? selectedRow.organizationPan : ""
                  }
                  type={"text"}
                  disabled={isSubmit}
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.ACTIVE_STATUS}</label>
                <RadioBtn
                  list={[
                    {
                      id: "true",
                      value: "true",
                      label: LABELS.ACTIVE,
                      checked: selectedRow.id
                        ? selectedRow.activeStatus === "Yes"
                        : true,
                      disabled: false,
                    },
                    {
                      id: "false",
                      value: "false",
                      label: LABELS.INACTIVE,
                      checked: selectedRow.id
                        ? selectedRow.activeStatus === "No"
                        : false,
                      disabled: false,
                    },
                  ]}
                  row={true}
                  name={"activeStatus"}
                  disabled={isSubmit}
                />
              </div>
            </div>
            {/* mandatoryResults */}
            {mandatoryResults.length > 0 && (
              <div className="mandatoryBox">
                <ul>
                  {mandatoryResults.map((m) => {
                    return (
                      <li>
                        <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="formGroup submitBtnWrapper">
              <div className="flexbtnjustify">
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => {
                    setManageDialog(false);
                    setMandatoryResults([]);
                  }}
                  loading={isSubmit}
                />
                <Btn
                  text={LABELS.SAVE}
                  onClick={manageOrgProfile}
                  loading={isSubmit}
                  bgColor={COLORS.SAVE_GREEN}
                />
              </div>
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};
export default withSnackbar(OrganizationProfile);
