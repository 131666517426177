import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import { CONFIG, NOTIFY } from "common/config";
import { LABELS, WARNINGS, TITLES, SUB_TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { MASTER_APIs } from "apihandlers/master";
import { REPORTS_APIs } from "apihandlers/reports";
import { COLORS } from "common/colors";

const CurrentStockWithDetails = (props: any) => {
  const { setDashMenu, userInfo } = props;

  const outletRef: any = useRef();

  const [loading, setLoading] = useState(true);
  const [masters, setMasters] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "10%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "productName",
      name: LABELS.PRODUCT_NAME,
      width: "40%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "stock",
      name: LABELS.CURRENT_STOCK,
      width: "50%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
  ];

  useEffect(() => {
    if (loading) {
      loadChart();
    }
  }, [loading]);

  const getOrgProfile = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    MASTER_APIs.getOrgProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list: any = [{ id: -1, label: "All" }];
        let recs: any = response.records;
        for (let i = 0; i < recs.length; i++) {
          list.push({
            id: recs[i].id,
            label: recs[i].organizationName,
          });
        }
        setMasters(list);
        // setLoading(false);
      }
    });
  };

  const loadChart = () => {
    const args = {
      reportType: "excel",
    };
    let errors = [];
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    setRows(null);
    // setLoading(true);
    REPORTS_APIs.getDmsCurrentStock(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        for (let i = 0; i < response.rows.length; i++) {
          response.rows[i]["slNo"] = i + 1;
        }
        setRows(response.rows);
        setLoading(false);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    loadChart();
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          {/* <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {TITLES.DMS_REPORT}
          </li>
          <li className="dim">{" / "}</li> */}
          <li className="active">{TITLES.CURRENT_STOCK_WITH_DETAILS}</li>
        </ul>
        <h4>{TITLES.CURRENT_STOCK_WITH_DETAILS}</h4>
        <p>{SUB_TITLES.CURRENT_STOCK_WITH_DETAILS}</p>
      </div>

      <div className="hChart">
        {/* <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 0,
              marginBottom: 20,
            }}
          >
             <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.FROM_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="fromDt"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.TO_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="toDt"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div> 

            <div
              style={{
                display: "flex",
                padding: 10,
                marginTop: "0.3rem",
              }}
            >
              <Btn
                text={LABELS.LOAD}
                onClick={loadChart}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </div> */}
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="grid excelGridWrapper">
          {!loading &&
            (rows ? (
              <ExcelGrid
                id={"currentStockWithDetails"}
                height={window.innerHeight * 0.8}
                cols={columns}
                rows={rows}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.CURRENT_STOCK_WITH_DETAILS}
                hideUnhide={true}
                onReload={reloadGrid}
              />
            ) : (
              <div className="flexCenter" style={{ minHeight: 450 }}>
                <Loader />
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(CurrentStockWithDetails);
