import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "components/Custom/UiDialog";
import AddNewPhysicalVoucher from "./AddNewPhysicalApproval";

import { LABELS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";
import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { TRANS_APIs } from "apihandlers/transaction";

const PhysicalVoucherApproval = (props: any) => {
  const { setDashMenu, privileges } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [voucherItemRows, setVoucherItemRows] = useState(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [mandatoryLoadResults, setMandatoryLoadResults] = useState<any[]>([]);

  const [reset, setReset] = useState<boolean>(true);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "receiptNumber",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.RECEIPT_NUMBER,
      width: "20%",
    },
    {
      key: "createdOn",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.CREATED_ON,
      width: "15%",
    },
    {
      key: "createdBy",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.CREATED_BY,
      width: "20%",
    },
    {
      key: "approvedOn",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.APPROVED_ON,
      width: "15%",
    },
    {
      key: "approvedBy",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.APPROVED_BY,
      width: "20%",
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      width: "5%",
      renderCell: (params) =>
        !params.row.approvedOn && (
          <div className="options">
            <Tooltip title={LABELS.EDIT} placement="bottom">
              <IconButton
                className="iconBtn"
                onClick={() => onEditRow(params.row)}
              >
                <IconEdit />
              </IconButton>
            </Tooltip>
          </div>
        ),
    },
  ];

  useEffect(() => {
    if (loading) {
      loadGrid();
    }
  }, [loading]);

  // const getRows = async () => {
  //   try {
  //     const fromDate = (
  //       document.getElementById("fromDate") as HTMLInputElement
  //     ).value.trim();
  //     const toDate = (
  //       document.getElementById("toDate") as HTMLInputElement
  //     ).value.trim();
  //     const args = {
  //       pageNo: 1,
  //       pageLength: DEFAULTS.PAGE_LENGTH,
  //       fromDate,
  //       toDate,
  //     };
  //     setRows(null);
  //     const res: any = await ORDER_APIs.getOrders(args);
  //     const { status, response } = res;
  //     if (status === 200) {
  //       let list = response.order;
  //       // for (let i = 0; i < response.order.length; i++) {
  //       //   list[i]["slNo"] = i + 1;
  //       // }
  //       setRows([]);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("getOrders:", error);
  //   }
  // };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    if (row) {
      const args = {
        stockId: row.id,
      };
      setVoucherItemRows(null);
      TRANS_APIs.getPhysicalVoucherItems(args)
        .then((res: any) => {
          const response: any = res.response;
          const { status } = res;
          if (status === 200) {
            let voucherList = response.records.map((r, i) => {
              r["slNo"] = i + 1;
              return r;
            });
            setVoucherItemRows(voucherList);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching voucher items:", error);
          setLoading(false);
        });
    }
  };

  const loadGrid = () => {
    // const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
    //   .value;
    // const toDt = (document.getElementById("toDt") as HTMLInputElement).value;

    let errors = [];

    // if (fromDt === "") {
    //   errors.push({
    //     id: "fromDt",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.FROM_DATE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (toDt === "") {
    //   errors.push({
    //     id: "toDt",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.TO_DATE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    setMandatoryLoadResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    setLoading(false);

    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      // fromDt,
      // toDt,
    };

    TRANS_APIs.getPhysicalVoucher(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    loadGrid();
  };

  console.log("voucherItemRows", voucherItemRows);

  return (
    <React.Fragment>
      {
        <div className="pageInfo">
          <ul className="breadcrumbs">
            <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
            <li className="dim">{" / "}</li>
            <li className="active">{LABELS.PHYSICAL_VOUCHER_APPROVAL}</li>
          </ul>
          <h4>{LABELS.PHYSICAL_VOUCHER_APPROVAL}</h4>
        </div>
      }
      {/* <div className="filterBorder" style={{ width: "96%" }}>
        <UiAccordion id={"filter"} title={LABELS.FILTER}>
          <div className="flexRowAround" style={{ width: "90%" }}>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.FROM_DATE}
                type={"date"}
                id="fromDt"
                defaultValue={
                  reset
                    ? DateFunctions.getDate("today", "from", "YYYY-MM-DD")
                    : null
                }
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.TO_DATE}
                type={"date"}
                id="toDt"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <div
                style={{
                  marginTop: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                }}
              >
                <Btn
                  size={""}
                  text={LABELS.RESET}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => {
                    console.log("Check");
                    setReset(false);
                  }}
                  //loading={isSubmit}
                />
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadGrid}
                  bgColor={COLORS.GRID_ADD_NEW}

                  // loading={isSubmit}
                />
              </div>
            </div>
          </div>
          {mandatoryLoadResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryLoadResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </UiAccordion>
      </div> */}

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.55}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.PHYSICAL_VOUCHER_APPROVAL}
            onReload={reloadGrid}
            userPrivilege={"0"}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
        {manageDialog && (
          <UiDialog
            title={"Add New " + LABELS.PHYSICAL_VOUCHER_APPROVAL + ": "}
            open={true}
            size={"md"}
            onClose={() => {
              setManageDialog(false);
              setVoucherItemRows(null);
            }}
          >
            <AddNewPhysicalVoucher
              hideHeader={true}
              setManageDialog={setManageDialog}
              voucherItemRows={voucherItemRows}
              selectedRow={selectedRow}
            />
          </UiDialog>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(PhysicalVoucherApproval);
