import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import VehicleForm from "./VehicleForm";

import { NOTIFY, DEFAULTS } from "common/config";
import { ERRORS, LABELS, SUB_TITLES, SUCCESS, TITLES } from "common/lables";

import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";
import { MASTER_APIs } from "apihandlers/master";
import { GEO_APIs } from "apihandlers/geography";
import { log } from "util";

const Vehicles = (props: any) => {
  const { setDashMenu, privileges, dmsMasters = "" } = props;

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [vehicleTypes, setVehicleTypes] = useState<any>(null);
  const [drivers, setDrivers] = useState<any>([]);
  const [axles, setAxles] = useState<any>(null);
  const [bodyTypes, setBodyTypes] = useState<any>(null);
  const [passingTons, setPassingTons] = useState<any>(null);
  const [areas, setAreas] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any>({});
  const [uploading, setUploading] = useState<boolean>(true);
  const [vehicleFiles, setVehicleFiles] = useState<any>([]);

  const dmsColumns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: true,
      align: "center",
    },

    {
      key: "vehicleNumber",
      name: LABELS.VEHICLE_NO,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: true,
      align: "left",
    },

    {
      key: "driverName",
      name: LABELS.DRIVERS_NAME,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "chassisNumber",
      name: LABELS.CHASSIS_NO,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fitnessStartDate",
      name: LABELS.FITNESS_FROM,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fcValidity",
      name: LABELS.FITNESS_UPTO,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "emissionValidity",
      name: LABELS.EMMISSION_VALIDITY,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceStartDate",
      name: LABELS.INSURENCE_FROM,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceValidity",
      name: LABELS.INSURENCE_UPTO,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceCompName",
      name: LABELS.INSURANCE_COMP_NAME,
      type: "text",
      width: "14%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceNum",
      name: LABELS.INSURANCE_NUMBER,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "perKmExpenses",
      name: LABELS.PER_KM,
      type: "number",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "registrationValidity",
      name: LABELS.REGISTRATION_VALIDITY,
      type: "date",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "vehicleModel",
      name: LABELS.MODEL,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "vehicleMake",
      name: LABELS.MAKE,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "averageSpeed",
      name: LABELS.AVG_SPEED,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "engineNumber",
      name: LABELS.ENGINE_NO,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "dieselCardNumber",
      name: LABELS.FUEL_CARD_NO,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "locationName",
      name: LABELS.LOCATION,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "loanAcNum",
      name: LABELS.LOAN_ACCOUNT_NUMBER,
      type: "number",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "ownerName",
      name: LABELS.OWNER_NAME,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "prevNpDt",
      name: LABELS.PREV_NP_DATE,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "prevRoadTaxDt",
      name: LABELS.PREV_ROADTAX_DATE,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "purchaseDate",
      name: LABELS.PURCHASE_DATE,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "rcStatus",
      name: LABELS.RC_STATUS,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "reeferFuelPerHr",
      name: LABELS.REEFER_FUEL_PER_HR,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "isAttached",
      name: LABELS.IS_ATTACHED,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "8%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "isHypothicated",
      name: LABELS.IS_HYPOTHICATED,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "isVahanVerified",
      name: LABELS.IS_VAHAN_VERIFIED,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "vehicleStatus",
      name: LABELS.VEHICLE_STATUS,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "5%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
  ];

  const gridCols = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: true,
      align: "center",
    },

    {
      key: "vehicleNumber",
      name: LABELS.VEHICLE_NO,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: true,
      align: "left",
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      key: "driverName",
      name: LABELS.DRIVERS_NAME,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "chassisNumber",
      name: LABELS.CHASSIS_NO,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fitnessStartDate",
      name: LABELS.FITNESS_FROM,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fcValidity",
      name: LABELS.FITNESS_UPTO,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "emissionValidity",
      name: LABELS.EMMISSION_VALIDITY,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceStartDate",
      name: LABELS.INSURENCE_FROM,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceValidity",
      name: LABELS.INSURENCE_UPTO,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceCompName",
      name: LABELS.INSURANCE_COMP_NAME,
      type: "text",
      width: "14%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "insuranceNum",
      name: LABELS.INSURANCE_NUMBER,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "perKmExpenses",
      name: LABELS.PER_KM,
      type: "number",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "permitNumber",
      name: LABELS.PERMIT_NO,
      type: "number",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "permitStartDate",
      name: LABELS.PERMIT_FROM,
      type: "date",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "permitValidity",
      name: LABELS.PERMIT_UPTO,
      type: "date",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "registrationValidity",
      name: LABELS.REGISTRATION_VALIDITY,
      type: "date",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "roadTaxStartDate",
      name: LABELS.ROADTAX_FROM,
      type: "date",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "roadTaxEndDate",
      name: LABELS.ROADTAX_UPTO,
      type: "date",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "speedLimiterEndDate",
      name: LABELS.SPEED_LIMIT_UPTO,
      type: "date",
      width: "11%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "vehicleModel",
      name: LABELS.MODEL,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "vehicleMake",
      name: LABELS.MAKE,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fastagNumber",
      name: LABELS.FAST_TAG_NO,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fastagProvider",
      name: LABELS.FASTTAG_PROVIDER,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "averageMileage",
      name: LABELS.MILEAGE,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "averageSpeed",
      name: LABELS.AVG_SPEED,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "axleName",
      name: LABELS.AXLE_NAME,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "bodyTypeName",
      name: LABELS.BODY_TYPE,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "crateCapacity",
      name: LABELS.CRATE_CAPACITY,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "minCratesCapacity",
      name: LABELS.MIN_CRATE_CAPACITY,
      type: "number",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "currentLocation",
      name: LABELS.CURRENT_LOCATION,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "dailyTravel",
      name: LABELS.DAILY_TRAVEL,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "emiAmount",
      name: LABELS.EMI_AMOUNT,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "emiDays",
      name: LABELS.EMI_DAYS,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "emissionNorms",
      name: LABELS.EMISSION_NORMS,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "engineNumber",
      name: LABELS.ENGINE_NO,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "dieselCardNumber",
      name: LABELS.FUEL_CARD_NO,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fuelProvider",
      name: LABELS.FUEL_PROVIDER,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "fuelTypes",
      name: LABELS.FUEL_TYPE,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "gpsImei",
      name: LABELS.GPS_IMEI,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "latitude",
      name: LABELS.LATITUDE,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "longitude",
      name: LABELS.LONGITUDE,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "locationName",
      name: LABELS.LOCATION,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "loanAcNum",
      name: LABELS.LOAN_ACCOUNT_NUMBER,
      type: "number",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "loanBankName",
      name: LABELS.LOAN_BANK_NAME,
      type: "text",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "manufacturingYear",
      name: LABELS.MANUFACTURING_YEAR,
      type: "number",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "maxPassingTon",
      name: LABELS.MAXIMUM_PASSING_TON,
      type: "number",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },

    {
      key: "minPassingTon",
      name: LABELS.MINIMUM_PASSING_TON,
      type: "number",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "nextNpDueDt",
      name: LABELS.NEXT_NP_DUE_DATE,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "np5YrValidity",
      name: LABELS.NP_5YR_VALIDITY,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "np10YrValidity",
      name: LABELS.NP_10YR_VALIDITY,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "ownerName",
      name: LABELS.OWNER_NAME,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "prevNpDt",
      name: LABELS.PREV_NP_DATE,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "prevRoadTaxDt",
      name: LABELS.PREV_ROADTAX_DATE,
      type: "date",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "purchaseDate",
      name: LABELS.PURCHASE_DATE,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "rcStatus",
      name: LABELS.RC_STATUS,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "reeferFuelPerHr",
      name: LABELS.REEFER_FUEL_PER_HR,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "isAttached",
      name: LABELS.IS_ATTACHED,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "8%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "isHypothicated",
      name: LABELS.IS_HYPOTHICATED,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "isVahanVerified",
      name: LABELS.IS_VAHAN_VERIFIED,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "vehicleStatus",
      name: LABELS.VEHICLE_STATUS,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      width: "5%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "left",
    },
  ];
  const [cols, setCols] = useState<any[]>(gridCols);

  useEffect(() => {
    if (loading) {
      getDrivers();
      getRows();
    }
  }, [loading]);

  const getDrivers = () => {
    const args = {
      activeStatus: true,
      shortMode: true,
    };
    MASTER_APIs.getDrivers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((d) => {
          return {
            id: d.id,
            label: d.fullName,
          };
        });
        setDrivers(list);
        getVehicleTypes();
      }
    });
  };

  const getVehicleTypes = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    MASTER_APIs.getVehicleTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setVehicleTypes(list);
        getAxles();
      }
    });
  };

  const getAxles = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    MASTER_APIs.getAxles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setAxles(list);
        getBodyTypes();
      }
    });
  };

  const getBodyTypes = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    MASTER_APIs.getBodyTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setBodyTypes(list);
        getPassingTon();
      }
    });
  };

  const getPassingTon = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    MASTER_APIs.getPassingtons(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.maximumWeight + " - " + r.minimumWeight,
          };
        });
        setPassingTons(list);
        getAreas();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getVehicles(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["isAttached"]) {
            list[i]["isAttached"] = "Yes";
          } else {
            list[i]["isAttached"] = "No";
          }
          if (list[i]["isHypothicated"]) {
            list[i]["isHypothicated"] = "Yes";
          } else {
            list[i]["isHypothicated"] = "No";
          }
          if (list[i]["isVahanVerified"]) {
            list[i]["isVahanVerified"] = "Yes";
          } else {
            list[i]["isVahanVerified"] = "No";
          }
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getVehicles:", error);
    }
  };

  const getAreas = () => {
    const args = {
      activeStatus: true,
      loadingOnlyStatus: true,
      minResponse: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setAreas(list);
        getRows();
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    getUploadedFiles(row.id);
  };
  const manageRecord = (args: any) => {
    console.log(args);
    setIsSubmit(true);
    MASTER_APIs.manageVehicle(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        console.error(res.error);
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
    setVehicleFiles([]);
  };

  const onCloseForm = () => {
    setManageDialog(false);
    setMandatoryResults([]);
  };
  const getUploadedFiles = (vehicleId) => {
    const args = {
      vehicleId,
    };
    setUploading(true);
    setVehicleFiles([]);
    MASTER_APIs.getVehicleFileInfo(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        setVehicleFiles(list);
        setUploading(false);
      }
    });
  };

  console.log("vehicleTypes:", vehicleTypes);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          {dmsMasters !== LABELS.DMS_MASTER && (
            <>
              <li onClick={() => setDashMenu(LABELS.HOME)}>
                {LABELS.MASTERS}{" "}
              </li>
              <li className="dim">{" / "}</li>{" "}
            </>
          )}
          <li className="active">{TITLES.MASTERS_BUTTON_VEHICLES}</li>
        </ul>
        <h4>{TITLES.MASTERS_BUTTON_VEHICLES}</h4>
        <p className="SubTitles">{SUB_TITLES.MASTERS_BUTTON_VEHICLES}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={LABELS.VEHICAL_LIST}
            cols={dmsMasters === "" ? cols : dmsColumns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.VEHICAL_LIST}
            onReload={reloadGrid}
            onAdd={onAdd}
            height={window.innerHeight * 0.8}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <VehicleForm
          selectedRow={selectedRow}
          onClose={onCloseForm}
          manageRecord={manageRecord}
          vehicleTypes={vehicleTypes}
          axlesTypes={axles}
          bodyTypes={bodyTypes}
          passingTonType={passingTons}
          driverNames={drivers}
          areas={areas}
          isSubmit={isSubmit}
          onEditRow={onEditRow}
          setVehicleFiles={setVehicleFiles}
          vehicleFiles={vehicleFiles}
        />
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Vehicles);
