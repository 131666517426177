import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";

import { API_CONFIG, FORMATS, NOTIFY } from "common/config";
import { LABELS, WARNINGS, TITLES, INFO, SUB_TITLES } from "common/lables";

import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { MASTER_APIs } from "apihandlers/master";
import { REPORTS_APIs } from "apihandlers/reports";

const LocationUpdate = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(false);
  const [masters, setMasters] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   key: "id",
    //   name: LABELS.ID,
    //   width: "20%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      key: "name",
      name: LABELS.NAME,
      width: "35%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "doneSum",
      name: LABELS.DONE,
      width: "30%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "notDoneSum",
      name: LABELS.NOT_DONE,
      width: "30%",
      type: "text",
      editable: true,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
  ];

  useEffect(() => {
    locationUpdate();
  }, []);

  // const getOrgProfile = () => {
  //   const args = {};
  //   MASTER_APIs.getOrgProfiles(args).then((res: any) => {
  //     const response: any = res.response;
  //     const { status } = res;
  //     if (status === 200) {
  //       let list: any = [{ id: -1, label: "All" }];
  //       let recs: any = response.records;
  //       for (let i = 0; i < recs.length; i++) {
  //         list.push({
  //           id: recs[i].id,
  //           label: recs[i].organizationName,
  //         });
  //       }
  //       setMasters(list);
  //       setLoading(false);
  //     }
  //   });
  // };

  const locationUpdate = () => {
    const args = {
      reportType: "excel",
    };
    // let errors = [];
    // if (fromDt == "") {
    //   errors.push({
    //     id: "fromDt",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.FROM_DATE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (toDt == "") {
    //   errors.push({
    //     id: "toDt",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.TO_DATE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // setMandatoryResults(errors);
    // if (errors.length > 0) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_CHECK_FORM_ERRORS
    //   );
    //   return;
    // }
    setRows([]);
    setLoading(true);

    REPORTS_APIs.getLocationUpdate(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        for (let i = 0; i < response.rows.length; i++) {
          response.rows[i]["slNo"] = i + 1;
        }
        setRows(response.rows);
        setLoading(false);
      }
    });
  };
  const reloadGrid = () => {
    setRows(null);
    locationUpdate();
  };

  console.log("Page: LocationUpdate", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {TITLES.REPORTS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.LOCATION_UPDATE}</li>
        </ul>
        <h4>{TITLES.LOCATION_UPDATE}</h4>
        <p>{SUB_TITLES.LOCATION_UPDATE}</p>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          {/* <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 0,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.FROM_DATE}
                </label>
                <TxtBox
                  type={"datetime"}
                  id="fromDt"
                  defaultValue={DateFunctions.getDate(
                    "now",
                    "from",
                    FORMATS.SQL_DATE
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.TO_DATE}
                </label>
                <TxtBox
                  type={"datetime"}
                  id="toDt"
                  defaultValue={DateFunctions.getDate(
                    "thismonth",
                    "to",
                    FORMATS.SQL_DATE
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn text={LABELS.LOAD} onClick={loadAttendenceReport} />
              </div>
            </div>
          </div> */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {loading ? (
            <div className="flexRowCenter" style={{ minHeight: 450 }}>
              <Loader />
            </div>
          ) : (
            rows && (
              <ExcelGrid
                id={LABELS.LOCATION_UPDATE}
                height={window.innerHeight * 0.8}
                cols={columns}
                rows={rows}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.LOCATION_UPDATE}
                onReload={reloadGrid}
                // freezeOption={true}
              />
            )
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(LocationUpdate);
