import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import UiDialog from "components/Custom/UiDialog";
import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { COMMON_JS } from "common/scripts";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/lables";

import { MASTER_APIs } from "apihandlers/master";
import { GEO_APIs } from "apihandlers/geography";
import { USER_APIs } from "apihandlers/user";

const AssignBeat = (props: any) => {
  const { setDashMenu } = props;

  const dealerRef: any = useRef(null);
  const userRef: any = useRef(null);
  const areaRef: any = useRef(null);

  const [dealerName, setDealerName] = useState([]);
  const [user, setUser] = useState([]);
  const [area, setArea] = useState([]);
  const [orgId, setOrgId] = useState();
  const [loading, setLoading] = useState(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [mandatoryResult, setMandatoryResult] = useState<any[]>([]);
  const [rows, setRows] = useState(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "areaName",
      name: LABELS.AREA,
      width: "45%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "executive",
      name: LABELS.EXECUTIVE,
      width: "45%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getOrgProfile();
  }, []);

  const getOrgProfile = () => {
    const args = {
      activeStatus: true,
    };
    MASTER_APIs.getOrgProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.organizationName,
          };
        });
        setDealerName(list);
      }
    });
  };

  const getUser = async (orgId) => {
    let user = [];
    try {
      const args = {
        orgId,
        activeStatus: "true",
      };
      const res: any = await USER_APIs.getOrgWiseUsers(args);
      user = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.fullName,
        };
      });
    } catch (error) {}
    return user;
  };
  const getArea = async (orgId) => {
    let area = [];
    try {
      const args = {
        orgId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getOrgWiseAreas(args);
      area = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.areaName,
        };
      });
    } catch (error) {}
    return area;
  };
  const onDealerChange = async (e, f) => {
    if (f.id) {
      const res = await getUser(f.id);
      setUser(res);
      const area = await getArea(f.id);
      setArea(area);
    }
  };
  const getRows = async (orgId) => {
    try {
      const args = {
        orgId,
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      setRows(null);
      setLoading(true);
      const res: any = await GEO_APIs.getOrgWiseAreas(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getCities:", error);
    }
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows(orgId);
  };

  const loadAssignBeat = () => {
    setRows(null);
    const orgId = dealerRef.current.selectedValue.id;
    let errors = [];
    if (!orgId) {
      errors.push({
        id: "orgId",
        msg:
          "<span class='bold'>" +
          LABELS.DEALER_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    } else {
      setOrgId(orgId);
      getRows(orgId);
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }
  };

  const manageAssignBeat = () => {
    const id = areaRef.current.selectedValue.id;
    let errors = [];
    if (!id) {
      errors.push({
        id: "id",
        msg:
          "<span class='bold'>" +
          LABELS.AREA +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    setMandatoryResult(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id,
      areaName: areaRef.current.selectedValue.label,
      orgId: orgId,
      executiveId: userRef.current.selectedValue.id,
      cityId: selectedRow.cityId,
      activeStatus: selectedRow.activeStatus,
      weekDay: "",
      vehicleId: selectedRow.vehicleId,
      driverId: selectedRow.driverId,
      sortingOrder: selectedRow.sortingOrder,
      pinCode: selectedRow.pinCode,
      avgCallTime: selectedRow.avgCallTime,
      totalPopulation: selectedRow.totalPopulation,
      totalShops: selectedRow.totalShops,
      beatCode: selectedRow.beatCode,
      areaType: selectedRow.areaType,
      regionZoneId: selectedRow.regionZoneId,
    };
    if (!id) {
      errors.push({
        id: "orgId",
        msg:
          "<span class='bold'>" +
          LABELS.DEALER_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    GEO_APIs.manageOrgWiseArea(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
      setManageDialog(false);
    });
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.ASSIGN_BEAT}</li>
        </ul>
        <h4>{TITLES.ASSIGN_BEAT}</h4>
        <p>{SUB_TITLES.ASSIGN_BEAT}</p>
      </div>

      <div style={{ width: "30%" }}>
        <div className="formGroup  ">
          <label>{LABELS.DEALER_NAME}</label>
          <AutoComplete
            list={dealerName}
            childRef={dealerRef}
            onChange={onDealerChange}
          />
        </div>

        <div style={{ paddingLeft: ".5rem" }}>
          <Btn
            text={LABELS.LOAD}
            onClick={loadAssignBeat}
            bgColor={COLORS.SAVE_GREEN}
          />
        </div>
      </div>

      {mandatoryResults.length > 0 && (
        <div className="mandatoryBox" style={{ marginTop: "1rem" }}>
          <ul>
            {mandatoryResults.map((m) => {
              return (
                <li>
                  <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <div
        className="grid "
        style={{
          width: "98%",
          display: "flex",
          marginLeft: "-12px",
          justifyContent: "center",
          marginTop: "0",
        }}
      >
        {loading ? (
          <div className="flexRowCenter" style={{ minHeight: 450 }}>
            <Loader />
          </div>
        ) : (
          rows && (
            <ExcelGrid
              id={LABELS.ASSIGN_BEAT}
              height={window.innerHeight * 0.8}
              cols={columns}
              rows={rows}
              exportOption={true}
              newRowOption={false}
              exportName={LABELS.ASSIGN_BEAT}
              onReload={reloadGrid}
            />
          )
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.ASSIGN_BEAT + ": " + selectedRow.areaName
              : "Add New " + LABELS.ASSIGN_BEAT
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
            setMandatoryResult([]);
          }}
        >
          <div style={{ height: "30%" }}>
            <div className="formGroup required ">
              <label>
                {LABELS.AREA} {" *"}
              </label>
              <AutoComplete
                list={area}
                childRef={areaRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.id,
                        label: selectedRow.areaName,
                      }
                    : null
                }
                disabled={true}
              />
            </div>
            <div className="formGroup required">
              <label>
                {LABELS.USER}
                {" *"}
              </label>
              <AutoComplete
                list={user}
                childRef={userRef}
                defaultValue={
                  selectedRow.executiveId
                    ? {
                        id: selectedRow.executiveId,
                        label: selectedRow.executive,
                      }
                    : null
                }
              />
            </div>
          </div>

          <div
            className="formGroup submitBtnWrapper"
            style={{ paddingTop: "7rem" }}
          >
            <Btn
              text={LABELS.SAVE}
              onClick={manageAssignBeat}
              bgColor={COLORS.SAVE_GREEN}
            />
          </div>

          {mandatoryResult.length > 0 && (
            <div className="mandatoryBox" style={{ marginTop: "1rem" }}>
              <ul>
                {mandatoryResult.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </UiDialog>
      )}
    </React.Fragment>
  );
};
export default withSnackbar(AssignBeat);
