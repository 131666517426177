// * Note: Please collect location info & logtime of each and every API Requests

// import axios from 'axios';

import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7001";

export const AUTH_APIs = {
  // User Login
  userLogin: async (args = {}) => {
    let apiUrl = "/au/v1/306E7421V77191BL";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Check Login
  checklogin: async (args = {}) => {
    let apiUrl = "/au/v1/911V6888F35172KR";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Change Password => POST
  changePassword: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/au/v1/281V7032G95892VS",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // User Session End
  logout: async (args = {}) => {
    let apiUrl = "/au/v1/227Z6190M33745TH";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      {},
      false,
      local
    );
    return response;
  },

  // updateFcmToken
  updateFcmToken: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/au/v1/357Q3245A12214LS",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  ///
  fileUpload: async (args = {}) => {
    let apiUrl = "/au/v1/352G4430K46192JU";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getFile: async (args = {}) => {
    let apiUrl = "/au/v1/149Z7769V62163UT";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },
};
