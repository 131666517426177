import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import { NOTIFY, DEFAULTS } from "common/config";
import { COLORS } from "common/colors";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { PRODUCT_APIs } from "apihandlers/product";
import { MASTER_APIs } from "apihandlers/master";

const SKU = (props: any) => {
  const { setDashMenu, privileges } = props;

  const insertAboveRef: any = useRef(null);
  const productRef: any = useRef(null);
  const branchRef: any = useRef(null);
  const gramsRef: any = useRef(null);
  const taxRef: any = useRef(null);
  const typeCategoryRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [products, setProducts] = useState<any>(null);
  const [grams, setGrams] = useState<any>(null);
  const [taxes, setTaxes] = useState<any>(null);
  const [branch, setBranch] = useState<any>(null);
  const [typeCategory, setTypeCategory] = useState<any>(null);
  const [insertAbove, setInsertAbove] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getProducts();
    }
  }, [loading]);

  const getProducts = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getProducts(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setProducts(list);
        getGrams();
      }
    });
  };
  const getGrams = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getGramsMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setGrams(list);
        getTypeCategory();
      }
    });
  };

  const getTypeCategory = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getTypeCategoryId(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setTypeCategory(list);
        getTax();
      }
    });
  };

  const getTax = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getTaxMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.taxName,
          };
        });
        setTaxes(list);
        getBranch();
      }
    });
  };

  const getBranch = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    MASTER_APIs.getBranchProfile(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setBranch(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await PRODUCT_APIs.getProductsSku(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);

        console.log("Rows List :", list);
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setInsertAbove(list);
      }
    } catch (error) {
      console.error("getCurrencies:", error);
    }
  };

  const onEditRow = (row: any) => {
    let insertAboveName = "";
    if (row.insertAbove) {
      const selectedInsertAbove = insertAbove.find((i) => {
        return i.id === row.insertAbove;
      });
      if (selectedInsertAbove) {
        insertAboveName = selectedInsertAbove.label;
      }
    }
    row.insertAboveName = insertAboveName;
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "productName",
      name: LABELS.SKU_NAME,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "mainProduct",
      name: LABELS.MAIN_PRODUCT,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "aliasName",
      name: LABELS.ALIAS_NAME,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "defaultBranch",
      name: LABELS.BRANCH,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "hsnCode",
      name: LABELS.HSN_CODE,
      width: "8%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "grams",
      name: LABELS.GRAMS,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "productTypeCategory",
      name: LABELS.TYPE_CATEGORY,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "taxStructure",
      name: LABELS.TAX,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "minPrice",
      name: LABELS.MIN_PRICE,
      width: "8%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "mrp",
      name: LABELS.MRP,
      width: "8%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "price",
      name: LABELS.PRICE,
      width: "8%",
      editable: false,
      sortable: true,
      align: "center",
      type: "number",
      searchable: true,
    },
    {
      key: "insertAbove",
      name: LABELS.INSERT_ABOVE,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "number",
      searchable: "true",
    },
    {
      key: "sortingOrder",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "number",
      searchable: "true",
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",

      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageBox = () => {
    const productId = productRef.current.selectedValue.id;
    const branchId = branchRef.current.selectedValue.id;
    const productName = (
      document.getElementById("skuName") as HTMLInputElement
    ).value.trim();
    const aliasName = (
      document.getElementById("alias") as HTMLInputElement
    ).value.trim();
    const gramsId = gramsRef.current.selectedValue.id;
    const typeCategoryId = typeCategoryRef.current.selectedValue.id;
    const taxStructureId = taxRef.current.selectedValue.id;
    const hsnCode = (
      document.getElementById("hsnCode") as HTMLInputElement
    ).value.trim();
    const mrp = (
      document.getElementById("mrp") as HTMLInputElement
    ).value.trim();
    const price = (
      document.getElementById("price") as HTMLInputElement
    ).value.trim();
    const minPrice = (
      document.getElementById("minPrice") as HTMLInputElement
    ).value.trim();
    const sortingOrder = (
      document.getElementById("sortingOrder") as HTMLInputElement
    ).value.trim();
    const insertAbove = insertAboveRef.current.selectedValue.id;
    let op = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = op ? op.value : "";

    // Validations
    let errors = [];
    if (!productId) {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.PRODUCT_INSIDE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (productName === "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.SKU_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (aliasName === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.ALIAS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!branchId) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.BRANCH +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!gramsId) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.GRAMS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!typeCategoryId) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.TYPE_CATEGORY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!taxStructureId) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.TAX +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!price) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.PRICE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!mrp) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.MRP +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (sortingOrder === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.SORTING_ORDER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      productName,
      aliasName,
      branchId,
      gramsId,
      productId,
      typeCategoryId,
      taxStructureId,
      price,
      mrp: mrp,
      minPrice,
      sortingOrder,
      hsnCode,
      insertAbove,
      activeStatus,
    };

    setIsSubmit(true);
    console.log("args", args);
    PRODUCT_APIs.manageProductSku(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };
  // console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.SKU}</li>
        </ul>
        <h4>{TITLES.SKU}</h4>
        <p>{SUB_TITLES.SKU}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"sku"}
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.SKU}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.SKU + ": " + selectedRow.productName
              : "Add New " + LABELS.SKU
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup required">
            <label>
              {LABELS.PRODUCT_NAME}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={products}
              childRef={productRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.mainProductId,
                      label: selectedRow.mainProduct,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup required">
            <TxtRow
              id={"skuName"}
              type={"text"}
              label={LABELS.SKU_NAME}
              required={true}
              defaultValue={selectedRow.productName}
              disabled={isSubmit}
            />
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width45 required">
              <TxtRow
                id={"alias"}
                type={"text"}
                label={LABELS.ALIAS}
                required={true}
                defaultValue={selectedRow.aliasName}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width45 required">
              <label>
                {LABELS.BRANCH}
                {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={branch}
                childRef={branchRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.branchId,
                        label: selectedRow.defaultBranch,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width45 required ">
              <label>
                {LABELS.GRAMS}
                {<span>*</span>}
              </label>
              <AutoComplete
                list={grams}
                childRef={gramsRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.gramId,
                        label: selectedRow.grams,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width45 required ">
              <label>
                {LABELS.TYPE_CATEGORY}
                {<span>*</span>}
              </label>
              <AutoComplete
                list={typeCategory}
                childRef={typeCategoryRef}
                defaultValue={
                  selectedRow.typeCategoryId
                    ? {
                        id: selectedRow.typeCategoryId,
                        label: selectedRow.productTypeCategory,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width45 required">
              <label>
                {LABELS.TAX}
                {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={taxes}
                childRef={taxRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.taxId,
                        label: selectedRow.taxStructure,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width45 ">
              <TxtRow
                id={"hsnCode"}
                type={"text"}
                label={LABELS.HSN_CODE}
                defaultValue={selectedRow.hsnCode}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width45 required">
              <TxtRow
                id={"mrp"}
                type={"number"}
                label={LABELS.MRP}
                required={true}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.mrp).toString()
                    : selectedRow.mrp
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width45 required">
              <TxtRow
                id={"price"}
                type={"number"}
                label={LABELS.PRICE}
                required={true}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.price).toString()
                    : selectedRow.price
                }
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width45 ">
              <TxtRow
                id={"minPrice"}
                type={"number"}
                label={LABELS.MIN_PRICE}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.minPrice).toString()
                    : selectedRow.minPrice
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width45 required">
              <TxtRow
                id={"sortingOrder"}
                type={"number"}
                label={LABELS.SORTING_ORDER}
                required={true}
                defaultValue={
                  selectedRow
                    ? Number(selectedRow.sortingOrder).toString()
                    : selectedRow.sortOrder
                }
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="formGroup width45">
            <label>{LABELS.INSERT_ABOVE}</label>
            <AutoComplete
              list={insertAbove}
              childRef={insertAboveRef}
              defaultValue={
                selectedRow.insertAbove
                  ? {
                      id: selectedRow.insertAbove,
                      label: selectedRow.insertAboveName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup required">
            <label>{LABELS.ACTIVE_STATUS}</label>
            <span className="red">*</span>

            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                // loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                // loading={isSubmit}
                bgColor={COLORS.SAVE_GREEN}
                onClick={manageBox}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(SKU);
