import React, { useEffect, useState, useRef } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit, Close as IconClose } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtBox from "components/Custom/TxtBox";
import SkeletonBox from "components/Custom/SkeletonBox";
import AutoComplete from "components/Custom/AutoComplete";
import TxtRow from "components/Custom/TxtRow";

import { API_CONFIG, CONFIG, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { PRODUCT_APIs } from "apihandlers/product";
import { AUTH_APIs } from "apihandlers/auth";

const thumbnailImg = require("assets/images/thumbnail.jpg");

const ProductTypeCategory = (props: any) => {
  const { setDashMenu, privileges } = props;

  const insertAboveRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [files, setFiles] = useState<any>(null);
  const [insertAbove, setInsertAbove] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setFiles(null);
      uploadFile(file, base64).then((res) => {
        setFiles([res]);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "productcategory",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
      }
    } catch (error) {}
    return fileInfo;
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await PRODUCT_APIs.getProductTypeCategory(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          list[i].imagePath = list[i].image;
          let fileUrl = API_CONFIG.STATIC_URL + list[i].image;
          list[i].image = (
            <div>
              <img
                src={fileUrl}
                alt=""
                width={"auto"}
                height={50}
                onClick={() => {
                  window.open(fileUrl, "_blank");
                }}
              />
            </div>
          );
        }
        setRows(list);
        setLoading(false);
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });

        setInsertAbove(list);
      }
    } catch (error) {
      console.error("getProductTypeCategory:", error);
    }
  };

  const onEditRow = (row: any) => {
    let insertAboveName = "";
    if (row.insertAbove) {
      const selectedInsertAbove = insertAbove.find((i) => {
        return i.id === row.insertAbove;
      });
      if (selectedInsertAbove) {
        insertAboveName = selectedInsertAbove.label;
      }
    }
    row.insertAboveName = insertAboveName;
    setSelectedRow(row);
    setManageDialog(true);
    setFiles([
      {
        id: row.imageId,
        path: row.imagePath,
      },
    ]);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "name",
      name: LABELS.NAME,
      width: "20%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "aliasName",
      name: LABELS.ALIAS,
      width: "15%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "image",
      name: LABELS.FILE_UPLOAD,
      type: "html",
      width: "15%",
      editable: false,
    },
    {
      key: "imagePath",
      name: LABELS.FILE_PATH,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "insertAbove",
      name: LABELS.INSERT_ABOVE,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "number",
      searchable: "true",
    },
    {
      key: "sortOrder",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      type: "number",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageProductTypeCategory = () => {
    const name = (
      document.getElementById("name") as HTMLInputElement
    ).value.trim();
    const aliasName = (
      document.getElementById("aliasName") as HTMLInputElement
    ).value.trim();
    const sortOrder = (
      document.getElementById("sortOrder") as HTMLInputElement
    ).value.trim();
    const insertAbove = insertAboveRef.current.selectedValue.id;
    let active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";
    // Validations
    let errors = [];
    if (name === "") {
      errors.push({
        id: "name",
        msg:
          "<span class='bold'>" +
          LABELS.NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    //ToDo
    if (files.length === 0) {
      errors.push({
        id: "imageId",
        msg:
          "<span class='bold'>" +
          LABELS.FILE_UPLOAD +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      name: name,
      aliasName: aliasName,
      sortOrder: Number(sortOrder).toString(),
      orgId: 1,
      activeStatus: selectBtnValue.toString(),
      imageId: files.length > 0 ? files[0].id : "",
      insertAbove,
      //
    };

    console.log("args", args);
    setIsSubmit(true);
    PRODUCT_APIs.manageProductTypeCategory(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
    setFiles([]);
  };

  console.log("selectedRow", selectedRow, files);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TYPE_CATEGORY}</li>
        </ul>
        <h4>{TITLES.TYPE_CATEGORY}</h4>
        <p>{SUB_TITLES.TYPE_CATEGORY}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"productTypeCategory"}
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.TYPE_CATEGORY}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.TYPE_CATEGORY + ": " + selectedRow.name
              : "Add New " + LABELS.TYPE_CATEGORY
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setFiles([]);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup red">
            <TxtRow
              id={"name"}
              type={"text"}
              label={LABELS.NAME}
              required={true}
              defaultValue={selectedRow.name}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"aliasName"}
              type={"text"}
              label={LABELS.ALIAS}
              //required={true}
              defaultValue={selectedRow.aliasName}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"sortOrder"}
              type={"number"}
              label={LABELS.SORTING_ORDER}
              // required={true}
              defaultValue={selectedRow.sortOrder}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>{LABELS.INSERT_ABOVE}</label>
            <AutoComplete
              list={insertAbove}
              childRef={insertAboveRef}
              defaultValue={
                selectedRow.insertAbove
                  ? {
                      id: selectedRow.insertAbove,
                      label: selectedRow.insertAboveName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <label>
              {LABELS.FILE_UPLOAD}
              <small>
                {" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}
              </small>
              <span>*</span>
            </label>
            <TxtBox
              type={"file"}
              id="uploadFile"
              // defaultValue={selectedRow.id ? selectedRow.files : null}
              // className="uploadFile"
              accept="image/*,.pdf"
              onChange={onUploadFile}
            />
            <div>
              {!files ? (
                <div className="thumbnailBox width20">
                  <SkeletonBox height={300} />
                </div>
              ) : (
                files.map((u, i) => {
                  return (
                    u.path && (
                      <div className="thumbnailBox width20">
                        <IconButton
                          className="thumbnailBoxIcon"
                          onClick={() => {
                            const list = files.filter((a, j) => j !== i);
                            setFiles(list);
                          }}
                        >
                          <IconClose />
                        </IconButton>
                        <img
                          onClick={() =>
                            window.open(
                              API_CONFIG.STATIC_URL + u.path,
                              "_blank"
                            )
                          }
                          src={
                            u.mimeType === "application/pdf"
                              ? thumbnailImg
                              : API_CONFIG.STATIC_URL + u.path
                          }
                          className="responsive cursor"
                        />
                      </div>
                    )
                  );
                })
              )}
            </div>
          </div>

          <div className="formGroup required">
            <label>{LABELS.ACTIVE_STATUS}</label>
            <span className="red">*</span>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className=" flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                  setFiles([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageProductTypeCategory}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ProductTypeCategory);
