import React, { useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

const ChkBox = (props: any) => {
  const { id, disabled, text, checked, controlled, onChange } = props;
  const [checkedValue, setCheckedValue] = useState(checked || false);
  const onChangeValue = () => {
    setCheckedValue(!checkedValue);
    if (onChange) {
      onChange(!checkedValue, id);
    }
  };

  return controlled ? (
    <FormControlLabel
      className="uiChkBox"
      disabled={disabled}
      control={<Checkbox id={id} checked={checked} />}
      label={text}
      onChange={onChangeValue}
    />
  ) : (
    <FormControlLabel
      className="uiChkBox"
      checked={checkedValue}
      disabled={disabled}
      control={<Checkbox id={id} />}
      label={text}
      onChange={onChangeValue}
    />
  );
};

export default ChkBox;
