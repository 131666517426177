/*
 * Lead Profile Creation
 */

import React from "react";

import NewDealer from "./NewDealer";

interface defaultProps {}

const DealerPreAppointment = (props: any) => {
  const { header, selectedRow, onClose, isButtonDisabled } = props;
  return (
    <>
      <NewDealer
        selectedRow={selectedRow}
        onClose={onClose}
        isButtonDisabled={isButtonDisabled}
      />
    </>
  );
};

export default DealerPreAppointment;
