import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { FORMATS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { GEO_APIs } from "apihandlers/geography";
import { MASTER_APIs } from "apihandlers/master";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { DateFunctions } from "common/datefunctions";
import { PRODUCT_APIs } from "apihandlers/product";

const Targets = (props: any) => {
  const { setDashMenu } = props;

  const regionRef: any = useRef(null);
  const cityRef: any = useRef(null);
  const beatPjpRef: any = useRef(null);
  const customerRef: any = useRef(null);
  const productRef: any = useRef(null);
  const boxRef: any = useRef(null);
  const mainCategoryRef: any = useRef(null);
  const subCategoryRef: any = useRef(null);
  const categoryRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [states, setStates] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [regions, setRegions] = useState<any>(null);
  const [countries, setCountries] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [beatPjp, setBeatPjp] = useState<any[]>([]);

  const [cities, setCities] = useState<any[]>([]);

  const [customers, setCustomers] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);

  const [box, setBox] = useState<any[]>([]);
  const [mainCategory, setMainCategory] = useState<any[]>([]);
  const [subCategory, setSubCategory] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getAreas();
    }
  }, [loading]);

  const getAreas = () => {
    const args = {
      activeStatus: true,
    };

    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setBeatPjp(list);

        getCities();
      }
    });
  };

  const getCities = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getCities(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.cityName,
          };
        });
        setCities(list);
        getRegion();
      }
    });
  };

  const getRegion = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getRegions(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.regionName,
          };
        });
        setRegions(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getTarget(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          if (list[i]["isMonthly"]) {
            list[i]["isMonthly"] = "Yes";
          } else {
            list[i]["isMonthly"] = "No";
          }
          if (list[i]["isQtyBased"]) {
            list[i]["isQtyBased"] = "Yes";
          } else {
            list[i]["isQtyBased"] = "No";
          }
          if (list[i]["isWeekly"]) {
            list[i]["isWeekly"] = "Yes";
          } else {
            list[i]["isWeekly"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
        getProducts();
      }
    } catch (error) {
      console.error("getRegions:", error);
    }
  };

  const getProducts = () => {
    const args = {
      activeStatus: true,
    };
    PRODUCT_APIs.getProducts(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setProducts(list);
        getProductsSku();
      }
    });
  };

  const getProductsSku = () => {
    const args = { activeStatus: true };
    PRODUCT_APIs.getProductsSku(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setBox(list);
        getProductMainCategory();
      }
    });
  };

  const getProductMainCategory = () => {
    const args = { activeStatus: true };
    PRODUCT_APIs.getProductMainCategory(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setMainCategory(list);
        getProductSubCategory();
      }
    });
  };

  const getProductSubCategory = () => {
    const args = { activeStatus: true };
    PRODUCT_APIs.getProductSubCategory(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setSubCategory(list);
        getProductTypeCategory();
      }
    });
  };

  const getProductTypeCategory = () => {
    const args = { activeStatus: true };
    PRODUCT_APIs.getProductTypeCategory(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setCategory(list);
        getCustomerProfiles();
      }
    });
  };

  const getCustomerProfiles = () => {
    const args = { activeStatus: true };
    CUSTOMER_APIs.getCustomerProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setCustomers(list);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };
  const reloadGrid = () => {
    setRows(null);
    getRows();
  };
  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "targetName",
      name: LABELS.TARGET_NAME,
      width: "13%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "customerName",
      name: LABELS.CUSTOMER_NAME,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "productName",
      name: LABELS.PRODUCT_NAME,
      width: "12%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "productskuName",
      name: LABELS.SKU_NAME,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "typecategName",
      name: LABELS.CATEGORY,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "maincategName",
      name: LABELS.MAIN_CATEG,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "subcategName",
      name: LABELS.SUB_CATEG,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "targetQty",
      name: LABELS.QUANTITY,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "targetAmount",
      name: LABELS.AMOUNT,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "areaName",
      name: LABELS.AREA,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "cityName",
      name: LABELS.CITY,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "regionName",
      name: LABELS.REGION,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "fromDate",
      name: LABELS.FROM,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "toDate",
      name: LABELS.TO,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "isQtyBased",
      name: LABELS.QTY_BASED,
      width: "6%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "isWeekly",
      name: LABELS.WEEKLY,
      width: "6%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "isMonthly",
      name: LABELS.MONTHLY,
      width: "6%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "10%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageHolidays = () => {
    const targetName = (
      document.getElementById("targetName") as HTMLInputElement
    ).value.trim();

    const fromDate = (
      document.getElementById("fromDate") as HTMLInputElement
    ).value.trim();

    const toDate = (
      document.getElementById("toDate") as HTMLInputElement
    ).value.trim();

    const areaId = beatPjpRef.current.selectedValue.id;

    const cityId = cityRef.current.selectedValue.id;

    const regionId = regionRef.current.selectedValue.id;

    const customerId = customerRef.current.selectedValue.id;

    const productId = productRef.current.selectedValue.id;

    const productskuId = boxRef.current.selectedValue.id;

    const maincategId = mainCategoryRef.current.selectedValue.id;

    const subcategId = subCategoryRef.current.selectedValue.id;

    const typecategId = categoryRef.current.selectedValue.id;

    const quantity = (
      document.getElementById("quantity") as HTMLInputElement
    ).value.trim();

    const amount = (
      document.getElementById("orderAmount") as HTMLInputElement
    ).value.trim();

    let opt = document.querySelector(
      'input[name="isWeekly"]:checked'
    ) as HTMLInputElement;
    const isWeekly = opt ? opt.value : "";
    console.log("Weekly", isWeekly);

    opt = document.querySelector(
      'input[name="isMonthly"]:checked'
    ) as HTMLInputElement;
    const isMonthly = opt ? opt.value : "";
    opt = document.querySelector(
      'input[name="isQtyBased"]:checked'
    ) as HTMLInputElement;
    const isQtyBased = opt ? opt.value : "";

    opt = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = opt ? opt.value : "";

    let errors = [];
    if (!targetName) {
      errors.push({
        id: "targetName",
        msg:
          "<span class='bold'>" +
          LABELS.TARGET_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (!quantity) {
      errors.push({
        id: "quantity",
        msg:
          "<span class='bold'>" +
          LABELS.QUANTITY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (!amount) {
      errors.push({
        id: "orderAmount",
        msg:
          "<span class='bold'>" +
          LABELS.ORDER_AMOUNT +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      targetName,
      fromDate,
      toDate,
      isWeekly,
      isMonthly,
      isQtyBased,
      activeStatus,
      areaId,
      cityId,
      regionId,
      customerId,
      productId,
      productskuId,
      maincategId,
      subcategId,
      typecategId,
      quantity,
      amount,
    };
    console.log("args", args);
    setIsSubmit(true);
    MASTER_APIs.manageTarget(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TARGETS}</li>
        </ul>
        <h4>{TITLES.TARGETS}</h4>
        <p>{SUB_TITLES.TARGETS}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.TARGETS}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.TARGETS + ": " + selectedRow.targetName
              : "Add New " + LABELS.TARGETS
          }
          open={true}
          size={"md"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexRowJustify">
            <div className="formGroup width30  red">
              <TxtRow
                id={"targetName"}
                type={"text"}
                label={LABELS.TARGET_NAME}
                defaultValue={selectedRow.targetName}
                disabled={isSubmit}
                required={true}
              />
            </div>
            <div className="formGroup width30 ">
              <TxtRow
                id={"fromDate"}
                type={"date"}
                label={LABELS.FROM}
                defaultValue={
                  selectedRow.fromDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.fromDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30 ">
              <TxtRow
                id={"toDate"}
                type={"date"}
                label={LABELS.TO}
                defaultValue={
                  selectedRow.toDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.toDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.BEAT_PJP}</label>

              <AutoComplete
                list={beatPjp}
                childRef={beatPjpRef}
                defaultValue={
                  selectedRow.areaId
                    ? {
                        id: selectedRow.areaId,
                        label: selectedRow.areaName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.CITY}</label>

              <AutoComplete
                list={cities}
                childRef={cityRef}
                defaultValue={
                  selectedRow.cityId
                    ? {
                        id: selectedRow.cityId,
                        label: selectedRow.cityName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.REGION}</label>

              <AutoComplete
                list={regions}
                childRef={regionRef}
                defaultValue={
                  selectedRow.regionId
                    ? {
                        id: selectedRow.regionId,
                        label: selectedRow.regionName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.CUSTOMER}</label>

              <AutoComplete
                list={customers}
                childRef={customerRef}
                defaultValue={
                  selectedRow.customerId
                    ? {
                        id: selectedRow.customerId,
                        label: selectedRow.customerName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.PRODUCT}</label>

              <AutoComplete
                list={products}
                childRef={productRef}
                defaultValue={
                  selectedRow.productId
                    ? {
                        id: selectedRow.productId,
                        label: selectedRow.productName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.BOX}</label>

              <AutoComplete
                list={box}
                childRef={boxRef}
                defaultValue={
                  selectedRow.productskuId
                    ? {
                        id: selectedRow.productskuId,
                        label: selectedRow.productskuName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.MAIN_CATEG}</label>

              <AutoComplete
                list={mainCategory}
                childRef={mainCategoryRef}
                defaultValue={
                  selectedRow.maincategId
                    ? {
                        id: selectedRow.maincategId,
                        label: selectedRow.maincategName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.SUB_CATEG}</label>

              <AutoComplete
                list={subCategory}
                childRef={subCategoryRef}
                defaultValue={
                  selectedRow.subcategId
                    ? {
                        id: selectedRow.subcategId,
                        label: selectedRow.subcategName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.CATEGORY}</label>

              <AutoComplete
                list={category}
                childRef={categoryRef}
                defaultValue={
                  selectedRow.typecategId
                    ? {
                        id: selectedRow.typecategId,
                        label: selectedRow.typecategName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <TxtRow
                id={"quantity"}
                type={"number"}
                label={LABELS.QUANTITY}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.targetQty).toString()
                    : selectedRow.targetQty
                }
                disabled={isSubmit}
                required={true}
              />
            </div>
            <div className="formGroup width30 red ">
              <TxtRow
                id={"orderAmount"}
                type={"number"}
                label={LABELS.ORDER_AMOUNT}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.targetAmount).toString()
                    : selectedRow.targetAmount
                }
                disabled={isSubmit}
                required={true}
              />
            </div>

            <div className="formGroup width30 "></div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30 ">
              <label>
                {LABELS.IS_WEEKLY}
                {/* {<span className="red">*</span>} */}
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: LABELS.TRUE,
                    label: LABELS.YES,
                    checked: selectedRow.id
                      ? selectedRow.isWeekly === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: LABELS.FALSE,
                    label: LABELS.NO,
                    checked: selectedRow.id
                      ? selectedRow.isWeekly === false
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"isWeekly"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30 ">
              <label>
                {LABELS.IS_MONTHLY}
                {/* {<span className="red">*</span>} */}
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: LABELS.TRUE,
                    label: LABELS.YES,
                    checked: selectedRow.id
                      ? selectedRow.isMonthly === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: LABELS.FALSE,
                    label: LABELS.NO,
                    checked: selectedRow.id
                      ? selectedRow.isMonthly === false
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"isMonthly"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30 ">
              <label>
                {LABELS.IS_QTY_BASED}
                {/* {<span className="red">*</span>} */}
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: LABELS.TRUE,
                    label: LABELS.YES,
                    checked: selectedRow.id
                      ? selectedRow.isQtyBased === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: LABELS.FALSE,
                    label: LABELS.NO,
                    checked: selectedRow.id
                      ? selectedRow.isQtyBased === false
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"isQtyBased"}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="formGroup required ">
            <label>
              {LABELS.ACTIVE_STATUS} {<span>*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>

          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageHolidays}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Targets);
