import React from "react";
import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";

import App from "./App";

import store from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// todo: Use babel plugin for this
if (process.env.REACT_APP_PRODUCTION === "true") {
  console.log(
    "%cYou're on live!",
    "color:red;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold"
  );
  // console.log = () => {};
  // console.warn = () => {};
  // console.info = () => {};
}

root.render(
  <SnackbarProvider maxSnack={3}>
    <Provider store={store}>
      <App />
    </Provider>
  </SnackbarProvider>
);
