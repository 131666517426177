import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "../../Custom/UiDialog";

import { ERRORS, LABELS, SUCCESS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { TRANS_APIs } from "apihandlers/transaction";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { PRODUCT_APIs } from "apihandlers/product";

const AddSalesInvoice = (props: any) => {
  const { hideHeader, setPageMenu } = props;

  const areasRef: any = useRef(null);
  const partyRef: any = useRef(null);
  const addSalesRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [rows, setRows] = useState<any>(null);
  const [filter, setFilter] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [areas, setAreas] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const [parties, setParties] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "productName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.PRODUCT,
      width: "30%",
    },
    {
      key: "closingStock",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.CLOSING_STOCK,
      width: "20%",
    },
    {
      key: "quantity",
      type: "text",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.QUANTITY,
      width: "15%",
    },
    {
      key: "mrp",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.RATE,
      width: "15%",
    },
    {
      key: "price",
      type: "text",
      formula: "quantity * mrp",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.AMOUNT,
      width: "15%",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRoute();
    }
  }, [loading]);

  const getRoute = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setAreas(list);
        getParty();
      }
    });
  };

  const getParty = () => {
    const args = {
      activeStatus: true,
    };
    CUSTOMER_APIs.getCustomerProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setParties(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const comments = (
        document.getElementById("comments") as HTMLInputElement
      ).value.trim();
      const routeId = areasRef.current.selectedValue.id;
      const partyId = partyRef.current.selectedValue.id;
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        routeId,
        partyId,
        comments,
        orderId: 2711,
      };
      setFilter(args);
      setRows(null);
      const res: any = await PRODUCT_APIs.getProductsSku(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getProductsSku:", error);
    }
  };

  const mangeSalesInvoice = () => {
    // const orderNumber = (
    //   document.getElementById("orderNumber") as HTMLInputElement
    // ).value.trim();
    // const orderDate = (
    //   document.getElementById("orderDate") as HTMLInputElement
    // ).value.trim();
    // const areaId = areasRef.current.selectedValue.id;
    // const customerId = partyRef.current.selectedValue.id;
    // const vehicleId = vehicleRef.current.selectedValue.id;
    // const branchId = branchRef.current.selectedValue.id;
    // const stockLoadId = stockRef.current.selectedValue.id;
    // const productSkuId = productRef.current.selectedValue.id;
    // const schemeId = schemeRef.current.selectedValue.id;
    // const taxAmount = (
    //   document.getElementById("taxAmount") as HTMLInputElement
    // ).value.trim();
    // const total = (
    //   document.getElementById("total") as HTMLInputElement
    // ).value.trim();
    // const basic = (
    //   document.getElementById("basic") as HTMLInputElement
    // ).value.trim();
    // const remarks = (
    //   document.getElementById("remarks") as HTMLInputElement
    // ).value.trim();
    // const sold = (
    //   document.getElementById("sold") as HTMLInputElement
    // ).value.trim();
    // const soldSku = (
    //   document.getElementById("soldSku") as HTMLInputElement
    // ).value.trim();
    // const price = (
    //   document.getElementById("price") as HTMLInputElement
    // ).value.trim();
    // const orderItemBasic = (
    //   document.getElementById("orderItemBasic") as HTMLInputElement
    // ).value.trim();
    // const orderItemTaxAmount = (
    //   document.getElementById("orderItemTaxAmount") as HTMLInputElement
    // ).value.trim();
    // const orderItemTotal = (
    //   document.getElementById("orderItemTotal") as HTMLInputElement
    // ).value.trim();
    // const offers = (
    //   document.getElementById("offers") as HTMLInputElement
    // ).value.trim();
    // let ele = document.querySelector(
    //   'input[name="activeStatus"]:checked'
    // ) as HTMLInputElement;
    // const status = ele ? ele.value : "";

    // Validations;
    let errors = [];
    // if (countryName == "") {
    //   errors.push({
    //     id: "symbol",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.COUNTRY_NAME +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (countryCode == "") {
    //   errors.push({
    //     id: "symbol",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.COUNTRY_CODE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (countryDialCode == "") {
    //   errors.push({
    //     id: "code",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.COUNTRY_DIAL_CODE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // setMandatoryResults(errors);
    // if (errors.length > 0) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_CHECK_FORM_ERRORS
    //   );
    //   return;
    // }
    const rows = addSalesRef.getRowData();

    setLoading(true);
    const args = {
      orderId: -1,
      // orderNumber: "123567",
      customerId: 79,
      orderDate: "2023-09-30 10:15:05",
      // areaId: 6307,
      taxAmount: "60",
      total: "100",
      basic: "50",
      status: true,
      remarks: "nothing",
      //vehicleId: 33,
      branchId: 15,
      // stockLoadId: 2,
      // productSkuId: rows.id,
      // sold,
      // soldSku,
      // price,
      // orderItemBasic,
      // orderItemTaxAmount,
      // orderItemTotal,
      items: rows,
      // photos: [],
      //schemeId: 5,
      // offers,
    };

    // setIsSubmit(true);
    console.log("args:", args);
    TRANS_APIs.manageOrder(args).then((res) => {
      // setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        return;
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };
  const onReset = (e) => {
    console.log(e);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  return (
    <React.Fragment>
      <div className="userDashboard">
        {!hideHeader && (
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li onClick={() => setPageMenu(LABELS.HOME)}>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li onClick={() => props.setPageMenu(LABELS.HOME)}>
                {LABELS.SALES}
              </li>
              <li className="dim">{" / "}</li>
              <li className="active">{TITLES.ADD_SALES_INVOICE}</li>
            </ul>
            <h4>{TITLES.ADD_SALES_INVOICE}</h4>
          </div>
        )}
        <div>
          <div className="width50 filterBorder">
            <UiAccordion id={"filter"} title={LABELS.FILTER}>
              <div className="flexRowAround">
                <div className="formGroup width30">
                  <label>{LABELS.ROUTE}</label>
                  <AutoComplete
                    list={areas}
                    childRef={areasRef}
                    defaultValue={
                      selectedRow.id
                        ? { id: selectedRow.id, label: selectedRow.label }
                        : { id: -1, label: "Select Route" }
                    }
                  />
                </div>
                <div className="formGroup width30">
                  <label>{LABELS.PARTY}</label>
                  <AutoComplete
                    list={parties}
                    childRef={partyRef}
                    defaultValue={
                      selectedRow.id
                        ? { id: selectedRow.id, label: selectedRow.label }
                        : { id: -1, label: "Select Party" }
                    }
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"comments"}
                    label={LABELS.COMMENTS}
                    defaultValue={selectedRow.comment}
                  />
                </div>
              </div>
            </UiAccordion>
          </div>

          <div className="grid excelGridWrapper">
            {rows ? (
              <>
                <ExcelGrid
                  height={window.innerHeight * 0.6}
                  childRef={addSalesRef}
                  cols={columns}
                  rows={rows}
                  exportOption={true}
                  newRowOption={false}
                  exportName={LABELS.ADD_SALES_INVOICE}
                  onReload={reloadGrid}
                />
              </>
            ) : (
              <div className="flexRowCenter">
                <Loader />
              </div>
            )}
          </div>

          {/* {!hideHeader && ( */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              width: "96.5%",
            }}
          >
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Btn
                text={LABELS.SAVE}
                onClick={mangeSalesInvoice}
                marginRight={15}
                bgColor={COLORS.GRID_ADD_NEW}
                //  loading={isSubmit}
              />
              {/* <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => setManageDialog(false)}
                // loading={isSubmit}
              /> */}
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(AddSalesInvoice);
