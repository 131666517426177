import React from "react";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { LABELS } from "common/lables";

const steps = [
  {
    time: "07:23 (11th Aug 2023)",
    description: "Nithin approved Ashok Agencies",
    other: " scheduled Activity",
  },
  {
    time: "08:50 (11th Aug 2023)",
    description: "Prajwal uploaded FSSAI date and document",
    other: " scheduled Activity",
  },
  {
    time: "03:40 (11th Aug 2023)",
    description: "Abhishek visited Jnanesh Agency for site vist",
    other: " scheduled Activity ",
  },
  {
    time: "03:40 (11th Aug 2023)",
    description: "Swapna assigned Subheesh to visit Jai Ambe Agency for review",
    other: " scheduled Activity ",
  },
];

const ActivityBoard = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",

        borderRadius: 4,

        paddingLeft: 12,

        marginBottom: 20,
      }}
    >
      <h4 style={{ textAlign: "center" }}>{LABELS.ACTIVITY_BOARD}</h4>

      <div style={{ width: "100%", overflow: "auto", height: 250 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.time} active={true} style={{ marginBottom: -10 }}>
              <StepLabel>{step.time}</StepLabel>

              <StepContent style={{ marginTop: "-0.5rem" }}>
                <Typography style={{ fontWeight: "500" }}>
                  {step.description}
                </Typography>
              </StepContent>

              {/* <StepContent>

              <div style={{ fontWeight: "100", paddingTop: "0.5rem" }}>

                {step.other}

              </div>

            </StepContent> */}
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};

export default ActivityBoard;
