import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { ArrowBack as IconBack } from "@mui/icons-material";

import { LEAVE_STATUS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { CONFIRMS, LABELS, SUCCESS, ERRORS, WARNINGS } from "common/lables";

// import { ApiMasters } from "apihandlers/masters";

// import { APIs } from "apihandlers/reports";
import { COMMON_JS } from "common/scripts";

import { REPORTS_APIs } from "apihandlers/reports";

let _timer = null;

const Report1 = (props: any) => {
  const [loading, setLoading] = useState(true);

  const [history, setHistory] = useState<any>([]);

  const [drillDownVal, setDrillDownVal] = useState([]);

  const [rows, setRows] = useState<any>([
    {
      id: "1001",
      slNo: "1",
      empName: "001 - Level 1",
      leaveFrom: "2015-01-01",
      leaveTo: "2020-01-01",
      status: "true",
      extraData: [
        {
          id: "1003",
          slNo: "1",
          empName: "0011 - Level 2 - Level 1",
          leaveFrom: "2015-01-01",
          leaveTo: "2020-01-01",
          status: "true",
          extraData: [
            {
              id: "1004",
              slNo: "1",
              empName: "00111 - Level 3 - Level 2 - Level 1",
              leaveFrom: "2015-01-01",
              leaveTo: "2020-01-01",
              status: "true",
              extraData: [],
            },
            {
              id: "1005",
              slNo: "2",
              empName: "00112 - Level 3 - Level 2 - Level 1",
              leaveFrom: "2015-01-01",
              leaveTo: "2020-01-01",
              status: "true",
              extraData: [
                {
                  id: "1006",
                  slNo: "1",
                  empName: "001121 - Level 4 - Level 3 - Level 2 - Level 1",
                  leaveFrom: "2015-01-01",
                  leaveTo: "2020-01-01",
                  status: "true",
                  extraData: [],
                },
                {
                  id: "1007",
                  slNo: "2",
                  empName: "001122 - Level 4 - Level 3 - Level 2 - Level 1",
                  leaveFrom: "2015-01-01",
                  leaveTo: "2020-01-01",
                  status: "true",
                  extraData: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "1002",
      slNo: "2",
      empName: "002 - Level 1",
      leaveFrom: "2015-01-01",
      leaveTo: "2020-01-01",
      status: "true",
      extraData: [],
    },
    {
      id: "1101",
      slNo: "3",
      empName: "003 - Level 1",
      leaveFrom: "2015-01-01",
      leaveTo: "2020-01-01",
      status: "true",
      extraData: [
        {
          id: "1102",
          slNo: "1",
          empName: "0031 - Level 2 - Level 1",
          leaveFrom: "2015-01-01",
          leaveTo: "2020-01-01",
          status: "true",
          extraData: [],
        },
        {
          id: "1101",
          slNo: "2",
          empName: "0032 - Level 2 - Level 1",
          leaveFrom: "2015-01-01",
          leaveTo: "2020-01-01",
          status: "true",
          extraData: [],
        },
      ],
    },
  ]);

  const [filteredRows, setFilteredRows] = useState<any>(null);

  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [empList, setEmpList] = useState([]);
  const [fileId, setFileId] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getEmpList();
      // setRows();
    }
  }, [loading]);

  const getEmpList = () => {
    const args = {};
    // REPORT_APIs.getEmployeeMaster(args).then((res: any) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list = response.records.map((r) => {
    //       return {
    //         id: r.id,
    //         label: r.empName,
    //       };
    //     });
    //     setEmpList(list);
    //     getRows();
    //   }
    // });
  };

  const getRows = () => {
    const args = {};
    // API_HANDLER_REPORTS.getReport1(args).then((res: any) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list = response.records;
    //     console.log("getReport1: ", list);
    //     for (let i = 0; i < response.records.length; i++) {
    //       // list[i]["slNo"] = i + 1;
    //       // if (list[i]["activeStatus"]) {
    //       //   list[i]["activeStatusText"] = "Yes";
    //       // } else {
    //       //   list[i]["activeStatusText"] = "No";
    //       // }
    //     }
    //     setRows(list);
    //     setFilteredRows([]);
    //     setLoading(false);
    //   }
    // });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const onApprove = (row: any = {}, status = LEAVE_STATUS.APPLIED) => {
    const args = {
      id: row.id,
      empId: row.empId,
      leaveFrom: row.leaveFrom,
      leaveTo: row.leaveTo,
      type: LEAVE_STATUS.APPROVE,
      status,
    };
    // API_HANDLER_REPORTS.manageLeavePlanner(args).then((res) => {
    //   if (res.status === 200) {
    //     COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
    //     setSelectedRow({});
    //     setManageDialog(false);
    //     reloadGrid();
    //   } else {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
    //   }
    // });
  };

  const manageRecord = () => {
    const empId = empListRef.current.selectedValue.id;
    const leaveFrom = (
      document.getElementById("leaveFrom") as HTMLInputElement
    ).value.trim();
    const leaveTo = (
      document.getElementById("leaveTo") as HTMLInputElement
    ).value.trim();
    // Validations
    if (!empId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.EMP_IS_EMPTY);
      return;
    }
    if (!leaveFrom) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Leave From is mandatory..!");
      return;
    }
    if (!leaveTo) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Leave To is mandatory..!");
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      empId,
      leaveFrom,
      leaveTo,
      type: "request",
    };
    // API_HANDLER_REPORTS.manageLeavePlanner(args).then((res) => {
    //   if (res.status === 200) {
    //     COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
    //     setSelectedRow({});
    //     setManageDialog(false);
    //     reloadGrid();
    //   } else {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
    //   }
    // });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.empName.toUpperCase().indexOf(qry) > -1;
      });
      // console.log("flist: ", rows, flist, qry);
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const empListRef: any = useRef();

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "empName",
      headerName: "Employee Name",
      width: 300,
      renderCell: (params: any) => {
        return (
          <div
            className={params.row.extraData.length > 0 ? "linkColumn" : ""}
            onClick={
              params.row.extraData.length > 0
                ? () => expandRow(params.id)
                : () => {
                    return;
                  }
            }
          >
            {params.value}
          </div>
        );
      },
    },
    { field: "leaveFrom", headerName: "Leave From", width: 200 },
    { field: "leaveTo", headerName: "Leave To", width: 200 },
    { field: "status", headerName: "Status", width: 200 },
  ];

  const expandRow = (id = "") => {
    let list: any = drillDownVal;
    let tmpHistory = history;
    if (selectedRows.length > 0) {
      let fList = [];
      let tmpList = [];
      for (let i in selectedRows) {
        let cellId = selectedRows[i];
        console.log("calculating: ", cellId);
        tmpList.push(cellId);
        let recs = rows.find((f: any) => f.id === cellId);
        if (recs) {
          for (let j in recs.extraData) {
            fList.push(recs.extraData[j]);
          }
        }
      }
      list.push(tmpList);
      setDrillDownVal(list);
      setFilteredRows(fList);
      setSelectedRows([]);
      tmpHistory.push(fList);
      setHistory(tmpHistory);
    } else {
      list.push([id]);
      setDrillDownVal(list);
      let fList: any = [];
      let tmpRows = filteredRows ? filteredRows : rows;
      fList = tmpRows.find((f: any) => f.id === id);
      if (fList) {
        fList = fList.extraData;
      }
      setFilteredRows(fList);
      tmpHistory.push(fList);
      setHistory(tmpHistory);
    }
  };

  console.log("filteredRows: history:", history);

  const finalRows = filteredRows ? filteredRows : rows;

  return (
    <React.Fragment>
      <div className="pageInfo" style={{ backgroundColor: "#fff" }}>
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.REPORT1}</li>
        </ul>
        <h4>{LABELS.REPORT1}</h4>
      </div>
      <div>
        <div
          className="grid"
          style={{
            width: "98%",
            height: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          {filteredRows && filteredRows.length > 0 && (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "20%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  let tmpHistory = history;
                  tmpHistory.pop();
                  console.log("tmpHistory: ", tmpHistory);
                  if (tmpHistory.length === 0) {
                    setFilteredRows(null);
                  } else {
                    setFilteredRows(tmpHistory[tmpHistory.length - 1]);
                  }
                }}
              >
                <IconBack />
              </IconButton>
              <h4>{"Back"}</h4>
            </div>
          )}
          <DataGrid
            rows={finalRows}
            columns={columns}
            rowHeight={50}
            disableColumnMenu={true}
            disableRowSelectionOnClick={true}
            isRowSelectable={(params: any) => {
              return params.row.extraData.length > 0;
            }}
            checkboxSelection={true}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedRows(newRowSelectionModel);
            }}
            // onCellClick={(params) => {
            //   console.log("onCellClick");
            //   if (params.field !== "__check__") {
            //     if (selectedRows.length > 0) {
            //       expandRow("");
            //     }
            //   }
            // }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(Report1);
