import React from "react";

import HGraph from "components/Custom/HGraph";

import { GRAPH_TYPES } from "common/config";

const StackedBar = (props: any) => {
  const { height } = props;
  const series = [
    {
      name: "",
      data: [30, 53],
    },
    {
      name: "cancel",
      data: [20, 25],
    },
    {
      name: "converted",
      data: [10, 11],
    },
  ];

  console.log("StackedBar", series);
  return (
    <React.Fragment>
      <div className="graphs">
        <HGraph
          width={"100%"}
          height={height}
          type={GRAPH_TYPES.STACKED_BAR}
          float={"right"}
          xAxis={[]}
          yAxis={[]}
          title={" "}
          series={series}
          exportTitle={" "}
          xAxisTitle="Months"
          yAxisTitle="Leads"
        />
      </div>
    </React.Fragment>
  );
};

export default StackedBar;
