import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";
import { GetApp as IconExport } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { CONFIRMS, LABELS, SUCCESS, ERRORS, WARNINGS } from "common/lables";

import { REPORTS_APIs } from "apihandlers/reports";
import { COMMON_JS } from "common/scripts";
import { IconButton, Tooltip } from "@mui/material";

const MainCategorySaleReport = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    // API_HANDLER_HOME.getProjects(args).then((res) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     setProjects(response.records);
    //   }
    //   setLoading(false);
    // });
    setLoading(false);
  };

  const loadChart = () => {
    // const projectId = projectRef.current.selectedValue.id;
    // if (!projectId) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
    //   return;
    // }
    const args = {
      fromDt: "2023-04-01 00:00:00",
      toDt: "2024-04-01 00:00:00",
    };
    setChartData(null);
    REPORTS_APIs.getMainCategorySalesReport(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setTimeout(() => {
          setChartData(response);
        }, 2000);
      } else {
        setChartData({});
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const exportHighchart = () => {
    // let projectName = "";
    // if (projectRef.current) {
    //   projectName = projectRef.current.selectedValue.label;
    // }
    let title = LABELS.MAIN_CATEGORY_WISE_SALES;
    let header = "<html>";
    header +=
      "<head><title>" +
      title +
      "</title><style>@page{margin-top: 5;size: A3 landscape;}</style></head>";
    let body = "<body><h2 style='text-align: center;'>" + title + "</h2>";
    body += chartRef.current.container.current.innerHTML;
    body += "</html>";
    const html = header + body;
    const printWin = window.open("", "", "toolbar=0,scrollbars=0,status=0");
    printWin.document.write(html);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
  };

  // const orgRef: any = useRef();
  const projectRef: any = useRef();
  let chartRef: any = useRef();
  const taskRef: any = useRef();
  let projectName = "Main Category";
  // if (projectRef.current) {
  //   projectName = projectRef.current.selectedValue.label;
  // }

  const chartTitle1 = projectName;

  const options1 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      // width: window.innerWidth * 0.9,
      // height: 550,
      marginTop: 40,
      marginLeft: 85,
      height: 1000 / 1.75,
      width: 1410 / 2,
    },
    credits: false,
    title: {
      text: chartTitle1,
    },
    exporting: {
      filename: chartTitle1,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        colors: [
          "#50B432",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: true,
        size: "75%",
      },
    },
    series: [
      {
        name: "Resource",
        colorByPoint: true,
        data: chartData ? chartData.series1 : [],
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.MAIN_CATEGORY_WISE_SALES}</li>
        </ul>
        <h4>{LABELS.MAIN_CATEGORY_WISE_SALES}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div
          className="hChart"
          style={{
            paddingBottom: 50,
          }}
        >
          <div id="chartForm">
            {/* <div className="formGroup">
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
                disabled={!chartData}
              />
            </div> */}

            <div
              className="formGroup"
              style={{
                display: "flex",
                marginTop: 15,
                marginBottom: 25,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                  marginRight={15}
                />
                {chartData && chartData.series1 && (
                  <Tooltip title={LABELS.EXPORT_TO_PDF}>
                    <IconButton
                      disabled={chartData === null}
                      onClick={exportHighchart}
                    >
                      <IconExport />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          {chartData ? (
            <div
              style={{
                width: "98%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "98%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "49%" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options1}
                    ref={chartRef}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(MainCategorySaleReport);
