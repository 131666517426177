/*
 * Project Configs Here
 */

export const PRODUCTION = process.env.REACT_APP_PRODUCTION;
export const UAT = process.env.REACT_APP_UAT;
const LOCAL_URL = process.env.REACT_APP_LOCAL_URL;
const UAT_URL = process.env.REACT_APP_UAT_URL;
const PRODUCTION_URL = process.env.REACT_APP_PRODUCTION_URL;
export const SUPER_POWER = true;

export const CONFIG = {
  SITE_NAME: process.env.REACT_APP_WEBSITE_NAME,
  VERSION: "v" + process.env.REACT_APP_VERSION,
  // DON'T CHANGE USER ROLES VALUES
  USER_ROLES: {
    Admin: 1,
    Customer: 2,
    Supplier: 3,
    Consignor: 4,
    Consignee: 5,
    Transporter: 6,
    Distributor: 7,
    Retailer: 8,
    Driver: 9,
    SalesTeam: 14,
    MarketingTeam: 15,
    Accounts: 16,
    Warehouse: 17,
    Tracking: 18,
    Support: 19,
    Placement: 20,
  },
  MAX_FILE_SIZE: 5,
  MAP_BOX_TILE_URL_STREETS:
    "https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  MAP_BOX_TILE_URL_SATELLITE:
    "https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  OSM_TILE_URL: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  GOOGLE_STREETS_MAP_URL:
    "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_STREETS_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=m,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_URL:
    "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=s,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_URL:
    "https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=t,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_URL:
    "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=y,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
};

export const API_CONFIG = {
  API_URL:
    PRODUCTION === "true"
      ? PRODUCTION_URL
      : UAT === "true"
      ? UAT_URL
      : LOCAL_URL,
  STATIC_URL:
    PRODUCTION === "true"
      ? PRODUCTION_URL
      : UAT === "true"
      ? UAT_URL
      : "http://192.168.0.115:7001/",
  METHODS: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  TIMEOUT: 35 * 1000,
};

export const FORMATS = {
  SQL_DATE: "YYYY-MM-DD HH:mm:ss",
  SQL_DATE_ONLY: "YYYY-MM-DD",
  DATE: "DD-MM-YYYY HH:mm:ss",
  DATE_ONLY: "DD-MM-YYYY",
  DATE_PICKER_FORMAT: "dd-MM-yyyy",
  REPORT_TITLE_DATE: "MMM DD, YYYY",
  REPORT_DATE_WITH_TIME: "MMM DD, YYYY HH:mm:ss",
  MONTH_ONLY: "MMMM",
  MONTH_AND_YEAR_ONLY: "MMMM - YYYY",
  GRAPH_TIME: "HH:mm",
  PRINT_DATE: "DD/MM/YYYY",
};

export const RESOURCE_SLOTS = {
  SLOT_1: "08:00",
  SLOT_2: "09:00",
  SLOT_3: "10:00",
  SLOT_4: "11:00",
  SLOT_5: "12:00",
  SLOT_6: "13:00",
  SLOT_7: "14:00",
  SLOT_8: "15:00",
  SLOT_9: "16:00",
  SLOT_10: "17:00",
  SLOT_11: "18:00",
  SLOT_12: "19:00",
  SLOT_13: "20:00",
};

export const DEFAULTS = {
  BTN_TYPE: "outlined",
  DIALOG_SIZE: "sm",
  MAP_TYPE: 1,
  PAGE_LENGTH: 10000,
  MAX_ZOOM: 18,
  MIN_ZOOM: 4,
  SUB_GRID_ROW_HEIGHT: 400,
  GRID_ROW_HEIGHT: 30,
  MAP_TYPES: ["OSM Map", "Google Streets", "Google Satellite"],
};

export const LOCAL_STORAGE = {
  USER_INFO: "nUserInfo",
  ACCESS_TOKEN: "nToken",
  REMEMBER_ME: "nRemember",
  MAP_TYPE: "mapType",
};

export const NOTIFY = {
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  AUTO_HIDE_DURATION: 3000,
};

export const BTN_VARIENTS = {
  TEXT: "text",
  OUTLINED: "outlined",
  CONTAINED: "contained",
};

export const UNITS = {
  PRICE: " €",
};

export const LEAVE_STATUS = {
  APPROVE: "Approve",
  APPLIED: "Applied",
  REJECT: "Reject",
};

export const GRAPH_TYPES = {
  STACKED_BAR: "stakedbar",
  BAR: "bar",
  COLUMN: "column",
  PIE: "pie",
  SOLIDGAUGE: "solidgauge",
  LINE: "line",
  AREA: "area",
  COLUMN_RANGE: "columnrange",
};

export const CRM_LEAD_STATUS = {
  NEW_LEAD: "New Lead",
  VERIFIED: "Verified",
  PRE_VERIFICATION: "Pre Verification",
  ASSIGNED: "Assigned",
  FIELD_VISIT: "Field Visit",
  DOCUMENT_VERIFICATION: "Document Verification",
  INTERVIEW_PROCESS: "Interview Process",
  APPROVALS: "Approvals",
  QUALIFIED: "Qualified",
  LEGAL_DOCUMENTS: "Legal Documents",
  CONVERTED: "Converted",
  HOLD: "Hold",
  REJECT: "Reject",
  CUSTOMER: "Customer",
};

export const DOC_UPLOAD_STATUS = {
  UPLOADED: "Uploaded",
  VERIFIED: "Verified",
  REJECTED: "Rejected",
};

export const INVESTMENT_AMOUNTS = [
  {
    id: "0-5 lakhs",
    label: "0-5 lakhs",
  },
  {
    id: "5-10 lakhs",
    label: "5-10 lakhs",
  },
  {
    id: "10-15 lakhs",
    label: "10-15 lakhs",
  },
  {
    id: "15-20 lakhs",
    label: "15-20 lakhs",
  },
  {
    id: "20 lakhs and above",
    label: "20 lakhs and above",
  },
];
