import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit, Close as IconClose } from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS, CONFIG, API_CONFIG } from "common/config";
import { LABELS, WARNINGS, TITLES, SUCCESS, ERRORS } from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { GEO_APIs } from "../../../apihandlers/geography";
import { MASTER_APIs } from "apihandlers/master";
import { AUTH_APIs } from "apihandlers/auth";
import SkeletonBox from "components/Custom/SkeletonBox";
import "./styles/users.css";

const thumbnailImg = require("assets/images/pdf.png");
const video = require("assets/images/video.png");
const TrainingView = (props: any) => {
  const { setDashMenu } = props;

  const trainingRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [trainings, setTrainings] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [trainingFiles, setTrainingFiles] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getTtraining();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getTrainingAssigns(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          list[i]["isTrainingCompletedText"] = list[i]["isCompleted"]
            ? "Completed"
            : "Not Completed";
          let fileUrl = API_CONFIG.STATIC_URL + list[i].path;
          if (list[i].mimeType === "video/mp4") {
            fileUrl = video;
          }
          if (list[i].mimeType === "application/pdf") {
            fileUrl = thumbnailImg;
          }
          list[i].files = (
            <div>
              <img
                src={fileUrl}
                alt=""
                width={"auto"}
                height={50}
                onClick={() => {
                  window.open(API_CONFIG.STATIC_URL + list[i].path, "_blank");
                }}
              />
            </div>
          );
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getCities:", error);
    }
  };

  const getTtraining = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };
    MASTER_APIs.getTrainings(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.trainingCategory,
          };
        });
        setTrainings(list);
        getRows();
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    { key: "category", name: LABELS.TRAINING_CAT, width: "15%" },
    {
      key: "files",
      name: "View Training",
      type: "html",
      width: "20%",
      editable: false,
    },
    {
      key: "mimeType",
      type: "text",
      name: LABELS.UPLOAD_TYPE,
      width: "15%",
      editable: false,
    },
    {
      key: "startTime",
      type: "text",
      name: "Start Date",
      width: "10%",
      editable: false,
    },
    {
      key: "endTime",
      type: "text",
      name: "Completion Date",
      width: "10%",
      editable: false,
    },
    {
      key: "isTrainingCompletedText",
      type: "text",
      name: "Training Status",
      width: "15%",
      editable: false,
    },
    {
      key: "options",
      name: "Options",
      width: "10%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageTraining = () => {
    const trainingId = trainingRef.current.selectedValue.id;
    const startDate = (
      document.getElementById("startDate") as HTMLInputElement
    ).value.trim();
    // const regionId = regionRef.current.selectedValue.id;
    const startPercentage = (
      document.getElementById("startPercentage") as HTMLInputElement
    ).value.trim();
    const endPercentage = (
      document.getElementById("endPercentage") as HTMLInputElement
    ).value.trim();
    const endDate = (
      document.getElementById("endDate") as HTMLInputElement
    ).value.trim();
    const viewedDevice = (
      document.getElementById("viewedDevice") as HTMLInputElement
    ).value.trim();
    const training = document.querySelector(
      'input[name="isTrainingCompleted"]:checked'
    ) as HTMLInputElement;
    const isTrainingCompleted = training ? training.value : "false";
    // const active = document.querySelector(
    //   'input[name="activeStatus"]:checked'
    // ) as HTMLInputElement;
    // const selectBtnValue = active ? active.value : "";
    // Validations
    // if (!cityName) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!regionId) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!shopCount) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!totalPopulation) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!selectBtnValue) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }

    const args = {
      id: selectedRow.activityId || -1,
      trainingId: trainingId,
      startDate: startDate,
      startPercentage: startPercentage,
      endPercentage: endPercentage,
      endDate: endDate,
      viewedDevice: viewedDevice,
      isTrainingCompleted: isTrainingCompleted.toString(),
    };
    setIsSubmit(true);
    MASTER_APIs.manageTrainingActivity(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  // const onUploadFile = (e: any, f: any) => {
  //   console.log("onUploadFile: ", e, f);
  //   const file: any = e.target.files[0];
  //   if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
  //     COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
  //     return;
  //   }
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     let base64: any = reader.result;
  //     setTrainingFiles(null);
  //     uploadFile(file, base64).then((res) => {
  //       setTrainingFiles([res]);
  //     });
  //   };
  //   reader.onerror = (error) => {
  //     console.log("uploadFile: Error:", error);
  //   };
  // };

  // const uploadFile = async (file: any, base64 = "") => {
  //   const args = {
  //     recordRefId: 1,
  //     file: {
  //       name: file.name,
  //       data: base64.replace("data:", "").replace(/^.+,/, ""),
  //       type: file.type,
  //       mimeType: file.name.split(".")[1],
  //       size: file.size,
  //     },
  //     category: "training",
  //   };
  //   let fileInfo = null;
  //   try {
  //     const res = await AUTH_APIs.fileUpload(args);
  //     console.log("uploadFile: ", args, res);
  //     const response: any = res.response;
  //     if (res.status === 200) {
  //       fileInfo = response;
  //     }
  //   } catch (error) {}
  //   return fileInfo;
  // };

  // console.log("trainingFilesFiles: ", trainingFiles);

  console.log("selectedRow:", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>
            {LABELS.TRAINING_VIEW}{" "}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TRAINING_VIEW}</li>
        </ul>
        <h4>{TITLES.TRAINING_VIEW}</h4>
        {/* <p>{SUB_TITLES.TRAINING_VIEW}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.TRAINING_VIEW}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.TRAINING_VIEW + ": " + selectedRow.category
              : "Add New " + LABELS.TRAINING_VIEW
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label>
              {"Training"}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={trainings}
              childRef={trainingRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.trainingId,
                      label: selectedRow.category,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"startDate"}
              type={"date"}
              label={"Start Date"}
              required={true}
              defaultValue={selectedRow.startTime}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"startPercentage"}
              type={"text"}
              label={"Start Percentage"}
              required={true}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.startPercent).toString()
                  : ""
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"endPercentage"}
              type={"text"}
              label={"endPercentage"}
              defaultValue={
                selectedRow.id ? Number(selectedRow.endPercent).toString() : ""
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"endDate"}
              type={"date"}
              label={"End Date"}
              // required={true}
              defaultValue={selectedRow.endTime}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"viewedDevice"}
              type={"text"}
              label={"Viewed Device"}
              // required={true}
              defaultValue={selectedRow.viewedOn}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {"Training Status"} {<span className="red">*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: "Completed",
                  checked: selectedRow.isCompleted,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: "Not Completed",
                  checked: !selectedRow.isCompleted,
                  disabled: false,
                },
              ]}
              row={true}
              name={"isTrainingCompleted"}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup submitBtnWrapper">
            <div className="width45 flexRowJustify">
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageTraining}
              />
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(TrainingView);
