import React from "react";

import "./styles.css";

import { LABELS, TITLES } from "common/lables";

const Roi = (props: any) => {
  const { setDashMenu } = props;

  const handleStoedDataChange = (event) => {
    const deposite = event.target.value;
    const interestDeposite = (
      document.getElementById("iod1") as HTMLInputElement
    ).value;
    totalInteresOnDeposite(interestDeposite, deposite);
  };
  const handleNumOfVehicleChange = (event) => {
    const noOfVehicle = event.target.value;
    const rateVal = (document.getElementById("rate1") as HTMLInputElement)
      .value;
    totalCostOfVan(noOfVehicle, rateVal);
  };
  const handleRateChange = (event) => {
    const rateVal = event.target.value;
    const noOfVehicle = (
      document.getElementById("noOfVehicle1") as HTMLInputElement
    ).value;
    totalCostOfVan(noOfVehicle, rateVal);
  };
  const totalCostOfVan = (numOfVehicle, rateVal) => {
    const costOfVan = numOfVehicle * rateVal;
    (document.getElementById("costofvan2") as HTMLInputElement).value =
      costOfVan.toString();
  };
  ///////Monthly cost///////
  const handleWeareHouseRental = (event) => {
    const rental = event.target.value;
    const otherCost = (
      document.getElementById("othercost2") as HTMLInputElement
    ).value;
    const staffCost = (document.getElementById("cost2") as HTMLInputElement)
      .value;
    totalMonthCost(rental, staffCost, otherCost);
  };
  const handleNoStaff = (event) => {
    const noStaff = event.target.value;
    const staffSalary = (document.getElementById("pss1") as HTMLInputElement)
      .value;
    totalCost(noStaff, staffSalary);
  };
  const handleStaffSalary = (event) => {
    const staffSalary = event.target.value;
    const noStaff = (document.getElementById("noofstaff1") as HTMLInputElement)
      .value;
    totalCost(noStaff, staffSalary);
  };
  const totalCost = (noStaff, staffSalary) => {
    const finalCost = noStaff * staffSalary;
    (document.getElementById("cost2") as HTMLInputElement).value =
      finalCost.toString();
    const otherCost = (
      document.getElementById("othercost2") as HTMLInputElement
    ).value;
    const wareHouseRent = (
      document.getElementById("warehouserental2") as HTMLInputElement
    ).value;
    totalMonthCost(wareHouseRent, finalCost, otherCost);
  };
  /////////////////////////////////
  const handleOtherCost = (event) => {
    let otherCostVal = event.target.value;
    const staffCost = (document.getElementById("cost2") as HTMLInputElement)
      .value;
    const wareHouseRent = (
      document.getElementById("warehouserental2") as HTMLInputElement
    ).value;
    totalMonthCost(wareHouseRent, staffCost, otherCostVal);
  };

  ////////monthly operating cost/////
  const totalMonthCost = (rent, cost, other) => {
    const rentVal = rent;
    const costVal = cost;
    const otherVal = other;
    const totalA = Number(rentVal) + Number(costVal) + Number(otherVal);
    (document.getElementById("monthcost") as HTMLInputElement).value =
      totalA.toString();
    const totalB = (
      document.getElementById("monthlyOperatingIncome") as HTMLInputElement
    ).value;
    totalmonthlyProfit(totalB, totalA);
  };

  ////////Income/////////
  const handleEstimatedRevenue = (event) => {
    const estimatedRevenue = event.target.value;
    const commissionInput1Val = (
      document.getElementById("commission1") as HTMLInputElement
    ).value;
    totalCommission(commissionInput1Val, estimatedRevenue);
  };
  const handleCommission = (event) => {
    const commission = event.target.value;
    const estimatedRevenue = (
      document.getElementById("estimatedRevenue1") as HTMLInputElement
    ).value;
    totalCommission(commission, estimatedRevenue);
  };
  const totalCommission = (commission, estimatedRevenue) => {
    const totalCommissionOutput = (commission * estimatedRevenue) / 100;
    (document.getElementById("commission2") as HTMLInputElement).value =
      totalCommissionOutput.toString();
    const interestOnDepositeValue1 = (
      document.getElementById("iod2") as HTMLInputElement
    ).value;
    const schemesBenefit = (document.getElementById("sb2") as HTMLInputElement)
      .value;
    MonthlyOperatingIncomeSum(
      totalCommissionOutput,
      interestOnDepositeValue1,
      schemesBenefit
    );
  };
  ///////interest on deposite////////
  const handleInterestOnDeposite = (event) => {
    const interestDepositeVal = event.target.value;
    const storeData = (
      document.getElementById("securityDepositeAmount2") as HTMLInputElement
    ).value;
    totalInteresOnDeposite(interestDepositeVal, storeData);
  };
  const totalInteresOnDeposite = (data1, data2) => {
    const totalInterestValue = (data1 * data2) / 12 / 10;
    (document.getElementById("iod2") as HTMLInputElement).value =
      totalInterestValue.toString();
    const commissionInput1Val = (
      document.getElementById("commission2") as HTMLInputElement
    ).value;
    const schemesBenefit = (document.getElementById("sb2") as HTMLInputElement)
      .value;
    MonthlyOperatingIncomeSum(
      commissionInput1Val,
      totalInterestValue,
      schemesBenefit
    );
  };
  /////////////////////Schemes Benefit //////////////////////////////////////////////////
  const handleSchemesBenefit = (event) => {
    const schemesBenefitValue = event.target.value;
    const interestOnDepositeValue1 = (
      document.getElementById("iod2") as HTMLInputElement
    ).value;
    const commissionInput1Val = (
      document.getElementById("commission2") as HTMLInputElement
    ).value;
    MonthlyOperatingIncomeSum(
      commissionInput1Val,
      interestOnDepositeValue1,
      schemesBenefitValue
    );
  };
  const MonthlyOperatingIncomeSum = (
    commissionVal,
    interestVal,
    schemeBenefitVal
  ) => {
    const totalB =
      Number(commissionVal) + Number(interestVal) + Number(schemeBenefitVal);
    (
      document.getElementById("monthlyOperatingIncome") as HTMLInputElement
    ).value = totalB.toString();
    const totalA = (document.getElementById("monthcost") as HTMLInputElement)
      .value;
    totalmonthlyProfit(totalB, totalA);
  };
  ///////////monthly profit///////////
  const totalmonthlyProfit = (monthCost, monthIncome) => {
    const profit = Number(monthIncome) - Number(monthCost);
    (document.getElementById("mp2") as HTMLInputElement).value =
      profit.toString();
    const annualProfit1 = (document.getElementById("ap1") as HTMLInputElement)
      .value;
    anualProfitTotal(annualProfit1, profit);
  };
  //////ANUUAL PROFIT//////
  const handleAnnualProfit = (event) => {
    const annualProfitInput = event.target.value;
    const monthProfit = (document.getElementById("mp2") as HTMLInputElement)
      .value;
    anualProfitTotal(annualProfitInput, monthProfit);
  };
  const anualProfitTotal = (annualProfitVal, monthlyProfitVal) => {
    const annualTotal = annualProfitVal * monthlyProfitVal;
    (document.getElementById("ap2") as HTMLInputElement).value =
      annualTotal.toString();
    const costOfVan = (
      document.getElementById("costofvan2") as HTMLInputElement
    ).value;
    const roi = (Number(annualTotal) / Number(costOfVan)) * 100;
    (document.getElementById("Roidata") as HTMLInputElement).value =
      roi.toString();
  };

  const defaultValues = {
    secureDepoDefault: 500000,
    vehicleNo: 2,
    rates: 500000,
    wareHouse: 20000,
    staffno: 2,
    staffSalaries: 12000,
    otherCosts: 5000,
    esRev: 1000000,
    comm: 7,
    depInt: 15,
    scheme: 5000,
    Annual: 12,
  };
  const cov = defaultValues.vehicleNo * defaultValues.rates;
  const costValue = defaultValues.staffSalaries * defaultValues.staffno;
  const commissions = (defaultValues.esRev * defaultValues.comm) / 100;
  const intdeposite =
    (defaultValues.depInt * defaultValues.secureDepoDefault) / 12 / 100;
  const B = commissions + intdeposite + defaultValues.scheme;
  const A = defaultValues.wareHouse + costValue + defaultValues.otherCosts;
  const totProfit = B - A;
  const annualProf =
    commissions +
    intdeposite +
    defaultValues.scheme -
    (defaultValues.wareHouse + costValue + defaultValues.otherCosts);
  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li
            onClick={() => {
              setDashMenu(LABELS.HOME);
            }}
          >
            {LABELS.HOME}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.ROI}</li>
        </ul>
        <h4>{TITLES.ROI_CALCULATER}</h4>
      </div>

      <div id="roi" className="justify">
        <div className="table-div">
          <table className="roiTable">
            <thead>
              <tr>
                <th className="mainHead">Particulars</th>
                <th className="mainHead">UoM</th>
                <th className="mainHead">Amount</th>
                <th className="mainHead">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="subHead tdata">Investment</th>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <td className="heading tdata">Security Deposit </td>
                <td className="tdata">Rs</td>
                <td className="tdata">
                  <input id="securityDepositeAmount1" type="text" />
                </td>
                <td className="tdata roiEntry">
                  <input
                    id="securityDepositeAmount2"
                    type="text"
                    defaultValue={defaultValues.secureDepoDefault}
                    onInput={handleStoedDataChange}
                    className="roiEntry"
                  />
                </td>
              </tr>
              <tr className="empty">
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <th className="subHead">One time cost</th>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <td className="heading tdata">No of Vehicle</td>
                <td className="tdata">Nos</td>
                <td className="tdata roiEntry">
                  <input
                    id="noOfVehicle1"
                    type="text"
                    name="Amount"
                    onInput={handleNumOfVehicleChange}
                    className="roiEntry"
                    defaultValue={defaultValues.vehicleNo}
                  />
                </td>
                <td className="tdata">
                  <input id="noOfVehicle2" type="text" name="Amount" />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Rate</td>
                <td className="tdata">{"Per vehicle"}</td>
                <td className="tdata roiEntry">
                  <input
                    id="rate1"
                    type="text"
                    name="Amount"
                    onInput={handleRateChange}
                    className="roiEntry"
                    defaultValue={defaultValues.rates}
                  />
                </td>
                <td className="tdata">
                  <input id="rate2" type="text" name="Amount" />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Cost of Van</td>
                <td className="tdata">Rs</td>
                <td className="tdata">
                  <input id="costofvan1" type="text" name="Amount" />
                </td>
                <td className="tdata roiCalculations">
                  <input
                    id="costofvan2"
                    type="text"
                    name="Amount"
                    disabled={true}
                    className="roiCalculations"
                    defaultValue={cov}
                  />
                </td>
              </tr>
              <tr className="empty">
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>

              <tr>
                <th className="subHead tdata">Monthly Cost</th>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <td className="heading tdata">Warehouse Rental</td>
                <td className="tdata"></td>
                <td className="tdata">
                  <input id="warehouserental1" type="text" name="Amount" />
                </td>
                <td className="tdata roiEntry">
                  <input
                    id="warehouserental2"
                    type="text"
                    name="Amount"
                    onInput={handleWeareHouseRental}
                    className="roiEntry"
                    defaultValue={defaultValues.wareHouse}
                  />
                </td>
              </tr>
              <tr>
                <td className="heading">
                  <b>Sales Representative</b>
                </td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <td className="heading tdata">No of staff</td>
                <td className="tdata">Nos</td>
                <td className="tdata roiEntry">
                  <input
                    className=" roiEntry"
                    id="noofstaff1"
                    type="text"
                    name="Amount"
                    onInput={handleNoStaff}
                    defaultValue={defaultValues.staffno}
                  />
                </td>
                <td className="tdata">
                  <input id="noofstaff2" type="text" name="Amount" />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Per Staff salary</td>
                <td className="tdata">Rs</td>
                <td className="tdata roiEntry">
                  <input
                    className="roiEntry"
                    id="pss1"
                    type="text"
                    name="Amount"
                    onInput={handleStaffSalary}
                    defaultValue={defaultValues.staffSalaries}
                  />
                </td>
                <td className="tdata">
                  <input id="pss2" type="text" name="Amount" />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Cost</td>
                <td className="tdata">Rs</td>
                <td className="tdata">
                  <input id="cost1" type="text" name="Amount" />
                </td>
                <td className="tdata roiCalculations">
                  <input
                    id="cost2"
                    type="text"
                    name="Amount"
                    disabled={true}
                    className="roiCalculations"
                    defaultValue={costValue}
                  />
                </td>
              </tr>
              <tr className="empty">
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <td className="heading tdata">Other cost</td>
                <td className="tdata"></td>
                <td className="tdata">
                  <input id="othercost1" type="text" name="Amount" />
                </td>
                <td className="tdata roiEntry">
                  <input
                    id="othercost2"
                    type="text"
                    name="Amount"
                    onInput={handleOtherCost}
                    className="roiEntry"
                    defaultValue={defaultValues.otherCosts}
                  />
                </td>
              </tr>

              <tr style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                <td className="heading monthOperate" colSpan={2}>
                  Monthly Operating cost
                </td>
                <td></td>
                <td className="roiCalculations">
                  <input
                    id="monthcost"
                    disabled={true}
                    className="roiCalculations"
                    defaultValue={A}
                  />
                </td>
              </tr>
              <tr className="empty">
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <th className="subHead">Income</th>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <td className="heading tdata">Estimated Revenue</td>
                <td className="tdata  "></td>
                <td className="tdata roiEntry">
                  <input
                    id="estimatedRevenue1"
                    type="text"
                    name="Amount"
                    onInput={handleEstimatedRevenue}
                    className="roiEntry"
                    defaultValue={defaultValues.esRev}
                  />
                </td>
                <td className="tdata ">
                  <input id="er2" type="text" name="Amount" />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Commission</td>
                <td className="tdata">%</td>
                <td className="tdata roiEntry">
                  <input
                    className="roiEntry"
                    id="commission1"
                    type="percentageInput"
                    name="Amount"
                    onInput={handleCommission}
                    defaultValue={defaultValues.comm}
                  />
                  %
                </td>
                <td className="tdata roiCalculations">
                  <input
                    className="roiCalculations"
                    id="commission2"
                    type="percentageInput"
                    name="Amount"
                    defaultValue={commissions}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Interest on Deposit</td>
                <td className="tdata">%</td>
                <td className="tdata roiEntry">
                  <input
                    className="roiEntry"
                    id="iod1"
                    type="percentageInput1"
                    name="Amount"
                    onInput={handleInterestOnDeposite}
                    defaultValue={defaultValues.depInt}
                  />
                  %
                </td>
                <td className="tdata roiCalculations">
                  <input
                    id="iod2"
                    type="percentageInput2"
                    name="Amount"
                    className="roiCalculations"
                    defaultValue={intdeposite}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Schemes benefit</td>
                <td className="tdata"></td>
                <td className="tdata">
                  <input id="sb1" type="text" name="Amount" />
                </td>
                <td className="tdata roiEntry">
                  <input
                    id="sb2"
                    type="text"
                    name="Amount"
                    onInput={handleSchemesBenefit}
                    className="roiEntry"
                    defaultValue={defaultValues.scheme}
                  />
                </td>
              </tr>
              <tr style={{ background: "rgb(238, 238, 238)" }}>
                <td className="heading monthOperate" colSpan={2}>
                  Monthly Operating Income
                </td>
                <td></td>
                <td className="roiCalculations">
                  <input
                    id="monthlyOperatingIncome"
                    disabled
                    type="text"
                    name="Amount"
                    className="roiCalculations"
                    defaultValue={B}
                  />
                </td>
              </tr>
              <tr className="empty">
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <td className="heading tdata">Monthly Profit/(loss)</td>
                <td className="tdata">Rs</td>
                {/* <!-- <td className="tdata"><input type="text" name="Amount"></td> --> */}
                <td className="tdata"></td>
                <td className="tdata roiInvestment">
                  <input
                    id="mp2"
                    type="text"
                    name="Amount"
                    disabled={true}
                    className="roiInvestment"
                    defaultValue={totProfit}
                  />
                </td>
              </tr>
              <tr>
                <td className="heading tdata">Annual Profit</td>
                <td className="tdata">%</td>
                <td className="tdata roiEntry">
                  <input
                    id="ap1"
                    type="text"
                    name="Amount"
                    onInput={handleAnnualProfit}
                    className="roiEntry"
                    defaultValue={defaultValues.Annual}
                  />
                </td>
                <td className="tdata roiInvestment">
                  <input
                    id="ap2"
                    type="text"
                    name="Amount"
                    className="roiInvestment"
                    disabled={true}
                    defaultValue={(defaultValues.Annual * annualProf)}
                  />
                </td>
              </tr>
              <tr className="empty">
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
              <tr>
                <th className="heading tdata">ROI on Investment</th>
                <td className="tdata">%</td>
                <td className="tdata"></td>
                <td className="tdata roiInvestment">
                  <input
                    id="Roidata"
                    type="text"
                    name="Amount"
                    className="roiInvestment"
                    disabled={true}
                    defaultValue={
                      ((defaultValues.Annual * annualProf) / cov) * 100
                    }
                  />
                  %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="image-div">
          <img
            className="logo-one"
            src="https://www.gopalnamkeen.com/front/assets/images/logo.png"
          ></img>

          <img
            className="logo-two"
            src="https://cdn-scripbox-wordpress.scripbox.com/wp-content/uploads/2020/03/return-on-investment-vector.png"
          ></img>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Roi;
