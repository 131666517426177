/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";

// import { GRAPH_TYPES } from "common/config";
// import { GRAPHS } from "common/graphs";
// import { COLORS } from "common/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts3d(Highcharts);

interface cProps {
  width: string;
  type: "column";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any[];
  stacked?: boolean;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const StackedCol = (props: any) => {
  const {
    width,
    type,
    stacked,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  let options = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 35,
        depth: 40,
      },
    },

    title: {
      text: "Olympic Games all-time medal table, grouped by continent",
      align: "left",
    },

    xAxis: {
      categories: ["Gold", "Silver", "Bronze"],
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "Count medals",
      },
    },

    tooltip: {
      format:
        "<b>{key}</b><br/>{series.name}: {y}<br/>" +
        "Total: {point.stackTotal}",
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
    },

    series: [
      {
        name: "Norway",
        data: [148, 133, 124],
        stack: "Europe",
      },
      {
        name: "Germany",
        data: [102, 98, 65],
        stack: "Europe",
      },
      {
        name: "United States",
        data: [113, 122, 95],
        stack: "North America",
      },
      {
        name: "Canada",
        data: [77, 72, 80],
        stack: "North America",
      },
    ],
  };

  console.log("options: ", options);

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default StackedCol;
