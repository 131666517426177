import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import RadioBtn from "components/Custom/RadioBtn";
import TxtRow from "components/Custom/TxtRow";
import UiDialog from "components/Custom/UiDialog";
import { COMMON_JS } from "common/scripts";
import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/lables";

import { GEO_APIs } from "apihandlers/geography";

const Country = (props: any) => {
  const { setDashMenu } = props;

  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "countryName",
      name: LABELS.COUNTRY_NAME,
      width: "30%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "countryCode",
      name: LABELS.COUNTRY_CODE,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "countryDialCode",
      name: LABELS.COUNTRY_DIAL_CODE,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "sortOrder",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: true,
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    GEO_APIs.getCountries(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const mangeCountry = () => {
    const countryName = (
      document.getElementById("name") as HTMLInputElement
    ).value.trim();
    const countryCode = (
      document.getElementById("countryCode") as HTMLInputElement
    ).value.trim();
    const countryDialCode = (
      document.getElementById("countryDialCode") as HTMLInputElement
    ).value.trim();
    const sortOrder = (
      document.getElementById("sortOrder") as HTMLInputElement
    ).value.trim();
    let ele = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = ele ? ele.value : "";

    // Validations;
    let errors = [];
    if (countryName === "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.COUNTRY_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (countryCode === "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.COUNTRY_CODE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (countryDialCode === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.COUNTRY_DIAL_CODE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      countryName,
      countryCode,
      countryDialCode,
      sortOrder: Number(sortOrder).toString(),
      activeStatus,
    };

    setIsSubmit(true);
    GEO_APIs.manageCountry(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("Page: Country:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.COUNTRY}</li>
        </ul>
        <h4>{TITLES.COUNTRY}</h4>
        <p>{SUB_TITLES.COUNTRY}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"country"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.COUNTRY}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + TITLES.COUNTRY + ": " + selectedRow.countryName
              : "Add " + TITLES.COUNTRY
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexColumn">
            <div className="formGroup red">
              <TxtRow
                id={"name"}
                label={LABELS.NAME}
                defaultValue={selectedRow.id ? selectedRow.countryName : ""}
                type={"text"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup red">
              <TxtRow
                id={"countryCode"}
                label={LABELS.COUNTRY_CODE}
                defaultValue={selectedRow.id ? selectedRow.countryCode : ""}
                type={"text"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup red">
              <TxtRow
                id={"countryDialCode"}
                label={LABELS.COUNTRY_DIAL_CODE}
                defaultValue={selectedRow.id ? selectedRow.countryDialCode : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup">
              <TxtRow
                id={"sortOrder"}
                type={"number"}
                label={LABELS.SORTING_ORDER}
                defaultValue={selectedRow.sortOrder}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup required">
              <label>
                {LABELS.ACTIVE_STATUS}
                <span>*</span>
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Yes"
                      : true,
                    disabled: isSubmit,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "No"
                      : false,
                    disabled: isSubmit,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            {/* mandatoryResults */}
            {mandatoryResults.length > 0 && (
              <div className="mandatoryBox">
                <ul>
                  {mandatoryResults.map((m) => {
                    return (
                      <li>
                        <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="formGroup submitBtnWrapper">
              <div className="flexbtnjustify">
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => {
                    setManageDialog(false);
                    setMandatoryResults([]);
                  }}
                  loading={isSubmit}
                />
                <Btn
                  text={LABELS.SAVE}
                  onClick={mangeCountry}
                  loading={isSubmit}
                  bgColor={COLORS.SAVE_GREEN}
                />
              </div>
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};
export default withSnackbar(Country);
