import React, { useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Close as IconClose } from "@mui/icons-material";

import TxtRow from "components/Custom/TxtRow";
import Btn from "components/Custom/Btn";
import SkeletonBox from "components/Custom/SkeletonBox";
import TxtBox from "components/Custom/TxtBox";

import { INFO, LABELS, SUCCESS, WARNINGS } from "common/lables";
import { COLORS } from "common/colors";
import { API_CONFIG, CONFIG, CRM_LEAD_STATUS, NOTIFY } from "common/config";
import { COMMON_JS } from "common/scripts";
import { CRM_APIs } from "apihandlers/crm";
import { AUTH_APIs } from "apihandlers/auth";

const pdfThumbnailImg = require("assets/images/pdf.png");
const docThumbnailImg = require("assets/images/doc.png");

const LegalDocuments = (props: any) => {
  const { areas, onClose, selectedRow, isButtonDisabled } = props;
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const legalDocs = selectedRow.legalDocs
    ? JSON.parse(selectedRow.legalDocs)
    : {};
  const [legalDocumentsFiles, setLegalDocumentsFiles] = useState<any>(
    legalDocs.files || {}
  );
  const [uploading, setUploading] = useState<boolean>(false);

  const saveRecord = async () => {
    try {
      const remarks = (
        document.getElementById("remarks") as HTMLInputElement
      ).value.trim();
      const args = {
        crmLeadId: selectedRow.id,
        crmLeadStatus: CRM_LEAD_STATUS.LEGAL_DOCUMENTS,
        crmLeadName: selectedRow.crmLeadName,
        activeStatus: selectedRow.activeStatus,
        legalDocs: {
          files: legalDocumentsFiles,
          remarks,
        },
      };
      setIsSubmit(true);
      const res: any = await CRM_APIs.manageCrmLeadProfile(args);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        onClose(true);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
      setIsSubmit(false);
      console.log("saveRecord: ", args, res);
    } catch (error) {
      setIsSubmit(false);
      console.log("saveRecord: error:", error);
    }
  };
  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setLegalDocumentsFiles({});
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        setLegalDocumentsFiles(res);
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "crm",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        fileInfo["mimeType"] = args.file.mimeType;
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };

  return (
    <div>
      <div className="formNote">
        <ul>
          <li>{INFO.UPLOAD_LEGAL_DOC}</li>
        </ul>
      </div>
      <div
        className="formGroup"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {legalDocumentsFiles.path && (
          <div className="fileUpload width10">
            <img
              onClick={() =>
                window.open(
                  API_CONFIG.STATIC_URL + legalDocumentsFiles.path,
                  "_blank"
                )
              }
              src={
                legalDocumentsFiles.mimeType === "pdf"
                  ? pdfThumbnailImg
                  : legalDocumentsFiles.mimeType.indexOf("doc") > -1
                  ? docThumbnailImg
                  : API_CONFIG.STATIC_URL + legalDocumentsFiles.path
              }
              className="responsive cursor"
            />
          </div>
        )}
      </div>
      <div className="formGroup width30">
        <label>
          {LABELS.FILE_UPLOAD}
          <small>{" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}</small>
        </label>
        <TxtBox
          type={"file"}
          id="uploadFile"
          // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
          accept=".doc,.docx"
          onChange={onUploadFile}
        />
      </div>

      <div className="formGroup width60">
        <TxtRow
          id={"remarks"}
          type={"text"}
          label={LABELS.REMARKS}
          // required={true}
          defaultValue={legalDocs.remarks || ""}
          //disabled={isSubmit}
          rows={4}
        />
      </div>

      <div className="formGroup submitBtnWrapper">
        <div className=" flexRowAround">
          <Btn
            text={LABELS.SAVE}
            loading={isSubmit}
            onClick={saveRecord}
            bgColor={COLORS.SAVE_GREEN}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(LegalDocuments);
