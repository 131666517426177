import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "components/Custom/UiDialog";
import SkeletonBox from "components/Custom/SkeletonBox";

import { ERRORS, LABELS, SUCCESS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { TRANS_APIs } from "apihandlers/transaction";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { PRODUCT_APIs } from "apihandlers/product";
import ChkBox from "components/Custom/ChkBox";

const AddNewPhysicalVoucher = (props: any) => {
  const {
    hideHeader,
    setPageMenu,
    setManageDialog,
    voucherItemRows,
    selectedRow,
  } = props;

  const addPhysicalVoucherRef: any = useRef(null);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [products, setProducts] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "productName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.PRODUCT,
      width: "30%",
    },
    {
      key: "strip",
      type: "number",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.STRIP,
      width: "33%",
    },
    {
      key: "packet",
      type: "number",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.PACKET,
      width: "33%",
    },
  ];

  useEffect(() => {
    if (loading) {
    }
  }, [loading]);

  const mangePhysicalVoucher = () => {
    const approved = (document.getElementById("approve") as HTMLInputElement)
      .checked;
    const rows = addPhysicalVoucherRef.getRowData();
    console.log("summary", rows);
    setLoading(true);
    let items = [];
    for (let i = 0; i < rows.length; i++) {
      items.push({
        itemId: rows[i].id || -1,
        productSkuId: rows[i].productSkuId,
        strip: rows[i].strip,
        packet: rows[i].packet,
      });
    }
    const args = {
      stockId: selectedRow.id || -1,
      // date: fromDate,
      items: items,
      approved: approved.toString(),
    };

    // let errors = [];
    // if (args.items.length === 0) {
    //   errors.push({
    //     id: "items",
    //     msg: WARNINGS.ITEM_IS_EMPTY,
    //   });
    // }
    // setMandatoryResults(errors);
    // if (errors.length > 0) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_CHECK_FORM_ERRORS
    //   );
    //   return;
    // }

    // setIsSubmit(true);

    console.log("args:", args);
    TRANS_APIs.managePhysicalVoucher(args).then((res) => {
      // setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
  };

  let gridRowsData = voucherItemRows;

  return (
    <React.Fragment>
      <div className="userDashboard">
        <div>
          {/* <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width40"></div>
            <div className="formGroup width40"></div>
          </div> */}
          {!loading && gridRowsData ? (
            <>
              <div className="grid excelGridWrapper">
                <ExcelGrid
                  id={"addPhysicalVoucher"}
                  height={window.innerHeight * 0.5}
                  childRef={addPhysicalVoucherRef}
                  cols={columns}
                  rows={gridRowsData}
                  newRowOption={false}
                  exportName={LABELS.ADD_SALES_INVOICE}
                  needAllRows={true}
                  // onReload={reloadGrid}
                />
              </div>
              {selectedRow.id && (
                <ChkBox
                  id={"approve"}
                  text={"Approve"}
                  checked={voucherItemRows ? true : false}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  width: "97%",
                }}
              >
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Btn
                    text={LABELS.SAVE}
                    onClick={mangePhysicalVoucher}
                    marginRight={15}
                    bgColor={COLORS.GRID_ADD_NEW}
                    //  loading={isSubmit}
                  />
                </div>
              </div>
            </>
          ) : (
            <SkeletonBox width="100%" height={200} />
          )}
        </div>
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(AddNewPhysicalVoucher);
