import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { LABELS, TITLES } from "common/lables";
import { CRM_REPORTS_APIs } from "apihandlers/crm/reports";
import { API_CONFIG } from "common/config";

const TrainingFiles = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        // fromDate: DateFunctions.getDate("thismonth", "from", FORMATS.SQL_DATE),
        // toDate: DateFunctions.getDate("thismonth", "from", FORMATS.SQL_DATE),
        fromDate: "2023-06-01",
        toDate: "2023-06-30",
      };
      const res: any = await CRM_REPORTS_APIs.getTrainingFiles(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;

          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          if (list[i]["isTrainingMandatory"]) {
            list[i]["isTrainingMandatory"] = "Yes";
          } else {
            list[i]["isTrainingMandatory"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getTrainingFiles:", error);
    }
  };

  const COLUMNS = [
    {
      key: "trainingCategory",
      name: "Training Category",
      width: "30%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "mimeType",
      name: "Mime Type",
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "durationInMinutes",
      name: "Duration In Minutes",
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "path",
      name: "File",
      width: "10%",
      type: "text",
      sortable: false,
      searchable: false,
      editable: false,
      renderCell: ({ row }: any) => {
        return (
          <div className="options">
            <img
              onClick={() => {
                window.open(API_CONFIG.STATIC_URL + row.path, "_blank");
              }}
              src={API_CONFIG.STATIC_URL + row.path}
              height={45}
              width={"100%"}
            />
          </div>
        );
      },
    },
    {
      key: "trainingComments",
      name: "Comments",
      width: "30%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "isTrainingMandatory",
      name: LABELS.MANDATORY,
      width: "5%",
      type: "dropdown",
      filters: [
        { id: "yes", name: "Yes" },
        { id: "no", name: "No" },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        { id: "yes", name: "Yes" },
        { id: "no", name: "No" },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
  ];

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  console.log("selectedRow", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>
            {TITLES.TRAINING_REPORTS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TRAINING_FILES}</li>
        </ul>
        <h4>{TITLES.TRAINING_FILES}</h4>
        {/* <p>{SUB_TITLES.TRAINING_FILES}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.85}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.TRAINING_FILES}
            onReload={reloadGrid}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(TrainingFiles);
