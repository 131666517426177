/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import VariablePie from "highcharts/modules/variable-pie";

import { GRAPH_TYPES } from "common/config";
import { GRAPHS } from "common/graphs";
import { COLORS } from "common/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts3d(Highcharts);
VariablePie(Highcharts);

interface cProps {
  width: string;
  type:
    | "column"
    | "pie"
    | "bar"
    | "solidgauge"
    | "stakedbar"
    | "line"
    | "area"
    | "columnrange";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any[];
  stacked?: boolean;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const HGraph = (props: any) => {
  const {
    width,
    type,
    stacked,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  const getOptions = () => {
    switch (type) {
      case GRAPH_TYPES.COLUMN:
        return GRAPHS.getColumnGraph(
          title,
          xAxis,
          yAxis,
          xAxisTitle,
          yAxisTitle,
          exportTitle,
          height,
          stacked,
          series
        );
      case GRAPH_TYPES.PIE:
        return GRAPHS.getPieGraph(title, series, exportTitle, height);
      case GRAPH_TYPES.STACKED_BAR:
        return GRAPHS.getStackedBar(
          title,
          xAxis,
          yAxis,
          series,
          xAxisTitle,
          yAxisTitle,
          exportTitle,
          height
        );
      case GRAPH_TYPES.BAR:
        return GRAPHS.getBarGraph(
          title,
          xAxis,
          yAxis,
          xAxisTitle,
          yAxisTitle,
          exportTitle,
          height
        );
      case GRAPH_TYPES.SOLIDGAUGE:
        return GRAPHS.getSolidGauge(title, series, title, height);
      case GRAPH_TYPES.LINE:
        return GRAPHS.getLineGraph(
          title,
          xAxis,
          series,
          exportTitle,
          xAxisTitle,
          yAxisTitle,
          height
        );
      case GRAPH_TYPES.AREA:
        return GRAPHS.getAreaGraph(
          title,
          xAxis,
          series,
          xAxisTitle,
          yAxisTitle,
          height
        );
      case GRAPH_TYPES.COLUMN_RANGE:
        return GRAPHS.getColumnRangeGraph(
          title,
          xAxis,
          series,
          xAxisTitle,
          yAxisTitle,
          exportTitle,
          height
        );
      default:
        break;
    }
  };

  let options: any = getOptions();

  if (colors) {
    options.colors = colors;
  }

  // options = {
  //   chart: {
  //     type: "column",
  //   },

  //   title: {
  //     text: "Olympic Games all-time medal table, grouped by continent",
  //     align: "left",
  //   },

  //   xAxis: {
  //     categories: ["Gold", "Silver", "Bronze"],
  //   },

  //   yAxis: {
  //     allowDecimals: false,
  //     min: 0,
  //     title: {
  //       text: "Count medals",
  //     },
  //   },

  //   tooltip: {
  //     format:
  //       "<b>{key}</b><br/>{series.name}: {y}<br/>" +
  //       "Total: {point.stackTotal}",
  //   },

  //   plotOptions: {
  //     column: {
  //       stacking: "normal",
  //     },
  //   },

  //   series: [
  //     {
  //       name: "Norway",
  //       data: [148, 133, 124],
  //       stack: "Europe",
  //     },
  //     {
  //       name: "Germany",
  //       data: [102, 98, 65],
  //       stack: "Europe",
  //     },
  //     {
  //       name: "United States",
  //       data: [113, 122, 95],
  //       stack: "North America",
  //     },
  //     {
  //       name: "Canada",
  //       data: [77, 72, 80],
  //       stack: "North America",
  //     },
  //   ],
  // };

  //   chart: {
  //     type: 'columnrange',
  //     inverted: true
  // },

  // accessibility: {
  //     description: 'Image description: A column range chart compares the monthly temperature variations throughout 2017 in Vik I Sogn, Norway. The chart is interactive and displays the temperature range for each month when hovering over the data. The temperature is measured in degrees Celsius on the X-axis and the months are plotted on the Y-axis. The lowest temperature is recorded in March at minus 10.2 Celsius. The lowest range of temperatures is found in December ranging from a low of minus 9 to a high of 8.6 Celsius. The highest temperature is found in July at 26.2 Celsius. July also has the highest range of temperatures from 6 to 26.2 Celsius. The broadest range of temperatures is found in May ranging from a low of minus 0.6 to a high of 23.1 Celsius.'
  // },

  // title: {
  //     text: 'Temperature variation by month'
  // },

  // subtitle: {
  //     text: 'Observed in Vik i Sogn, Norway, 2021 | ' +
  //         'Source: <a href="https://www.vikjavev.no/ver/" target="_blank">Vikjavev</a>'
  // },

  // xAxis: {
  //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //         'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  // },

  // yAxis: {
  //     title: {
  //         text: 'Temperature ( °C )'
  //     }
  // },

  // tooltip: {
  //     valueSuffix: '°C'
  // },

  // plotOptions: {
  //     columnrange: {
  //         borderRadius: '50%',
  //         dataLabels: {
  //             enabled: true,
  //             format: '{y}°C'
  //         }
  //     }
  // },

  // legend: {
  //     enabled: false
  // },

  // series: [{
  //     name: 'Temperatures',
  //     data: [
  //         [-13.9, 5.2],
  //         [-16.7, 10.6],
  //         [-4.7, 11.6],
  //         [-4.4, 16.8],
  //         [-2.1, 27.2],
  //         [5.9, 29.4],
  //         [6.5, 29.1],
  //         [4.7, 25.4],
  //         [4.3, 21.6],
  //         [-3.5, 15.1],
  //         [-9.8, 12.5],
  //         [-11.5, 8.4]
  //     ]
  // }]

  console.log("options: ", options);

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default HGraph;
