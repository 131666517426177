import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "../../Custom/UiDialog";

import { LABELS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { TRANS_APIs } from "apihandlers/transaction";
import { PRODUCT_APIs } from "apihandlers/product";

const AddPurchaseBill = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [rows, setRows] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "productName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.PRODUCT,
      width: "40%",
    },
    {
      key: "quantity",
      type: "number",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.QUANTITY,
      width: "20%",
    },
    {
      key: "mrp",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.RATE,
      width: "15%",
    },
    {
      key: "price",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.AMOUNT,
      width: "20%",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const voucherDate = (
        document.getElementById("voucherDate") as HTMLInputElement
      ).value.trim();
      const billNo = (
        document.getElementById("billNo") as HTMLInputElement
      ).value.trim();
      const billDate = (
        document.getElementById("billDate") as HTMLInputElement
      ).value.trim();
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        voucherDate,
        billDate,
        billNo,
      };

      setRows(null);
      const res: any = await PRODUCT_APIs.getProductsSku(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getOrders:", error);
    }
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  return (
    <React.Fragment>
      <div>
        <div className="pageInfo">
          <ul className="breadcrumbs">
            <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
            <li className="dim">{" / "}</li>
            <li onClick={() => props.setDashMenu(LABELS.HOME)}>
              {LABELS.PURCHASE}
            </li>
            <li className="dim">{" / "}</li>
            <li className="active">{TITLES.ADD_PURCHASE_BILL}</li>
          </ul>
          <h4>{TITLES.ADD_PURCHASE_BILL}</h4>
        </div>
        <div>
          <div className="width50 filterBorder">
            <UiAccordion id={"filter"} title={LABELS.FILTER}>
              <div className="flexRowAround">
                <div className="formGroup width30">
                  <TxtRow
                    label={LABELS.VOUCHER_DATE}
                    type={"date"}
                    id={"voucherDate"}
                    defaultValue={DateFunctions.getDate(
                      "today",
                      "from",
                      "YYYY-MM-DD"
                    )}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"billNo"}
                    label={LABELS.BILL_NO}
                    defaultValue={selectedRow.billNo}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    label={LABELS.BILL_DATE}
                    type={"date"}
                    id={"billDate"}
                    defaultValue={DateFunctions.getDate(
                      "today",
                      "from",
                      "YYYY-MM-DD"
                    )}
                  />
                </div>
              </div>
            </UiAccordion>
          </div>

          <div className="grid excelGridWrapper">
            {rows ? (
              <>
                <ExcelGrid
                  height={window.innerHeight * 0.6}
                  cols={columns}
                  rows={rows}
                  exportOption={true}
                  newRowOption={false}
                  exportName={LABELS.ADD_PURCHASE_BILL}
                  onReload={reloadGrid}
                />
              </>
            ) : (
              <div className="flexRowCenter">
                <Loader />
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              width: "96.5%",
            }}
          >
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Btn
                text={LABELS.SAVE}
                onClick={() => {}}
                marginRight={15}
                bgColor={COLORS.GRID_ADD_NEW}
                //  loading={isSubmit}
              />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => setManageDialog(false)}
                // loading={isSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(AddPurchaseBill);
