import React, { useEffect, useState, useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import RadioBtn from "components/Custom/RadioBtn";
import TxtRow from "components/Custom/TxtRow";
import UiDialog from "components/Custom/UiDialog";
import { COMMON_JS } from "common/scripts";
import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/lables";

import { GEO_APIs } from "apihandlers/geography";

const CentralZone = (props: any) => {
  const { setDashMenu } = props;

  const countryIdRef: any = useRef(null);

  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [countryId, setCountryId] = useState<any>({});
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      align: "center",
      headerAlign: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "centralZoneName",
      name: LABELS.CENTRAL_ZONE_NAME,
      width: "30%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "centralZoneCode",
      name: LABELS.CENTRAL_ZONE_CODE,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "countryName",
      name: LABELS.COUNTRY,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "sortOrder",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: "true",
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    GEO_APIs.getCentralZones(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    });
    getCountries();
  };

  const getCountries = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getCountries(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.countryName,
          };
        });
        setCountryId(list);
      }
    });
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const manageCentralZone = () => {
    const centralZoneName = (
      document.getElementById("centralZoneName") as HTMLInputElement
    ).value.trim();
    const centralZoneCode = (
      document.getElementById("centralZoneCode") as HTMLInputElement
    ).value.trim();
    const countryId = countryIdRef.current.selectedValue.id;
    const sortOrder = (
      document.getElementById("sortOrder") as HTMLInputElement
    ).value.trim();
    let ele = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = ele ? ele.value : "";

    // Validations
    let errors = [];
    if (centralZoneName === "") {
      errors.push({
        id: "centralZoneName",
        msg:
          "<span class='bold'>" +
          LABELS.CENTRAL_ZONE_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (!countryId) {
      errors.push({
        id: "countryId",
        msg:
          "<span class='bold'>" +
          LABELS.COUNTRY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      centralZoneName,
      centralZoneCode,
      countryId,
      sortOrder: Number(sortOrder).toString(),
      activeStatus,
    };

    setIsSubmit(true);
    GEO_APIs.manageCentralZone(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("Page: CentralZone:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.CENTRAL_ZONES}</li>
        </ul>
        <h4>{TITLES.CENTRAL_ZONES}</h4>
        <p>{SUB_TITLES.CENTRAL_ZONES}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"centralZone"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.CENTRAL_ZONES}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                TITLES.CENTRAL_ZONES +
                ": " +
                selectedRow.centralZoneName
              : "Add " + TITLES.CENTRAL_ZONES
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexColumn">
            <div className="formGroup required">
              <TxtRow
                id={"centralZoneName"}
                label={LABELS.CENTRAL_ZONE_NAME}
                defaultValue={selectedRow.id ? selectedRow.centralZoneName : ""}
                type={"text"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup">
              <TxtRow
                id={"centralZoneCode"}
                label={LABELS.CENTRAL_ZONE_CODE}
                defaultValue={selectedRow.centralZoneCode}
                type={"text"}
                required={false}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup required">
              <label>
                {LABELS.COUNTRY}
                {<span>*</span>}
              </label>
              <AutoComplete
                list={countryId}
                childRef={countryIdRef}
                defaultValue={
                  selectedRow.countryId
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.countryName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup">
              <TxtRow
                id={"sortOrder"}
                type={"number"}
                label={LABELS.SORTING_ORDER}
                defaultValue={selectedRow.sortOrder}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup">
              <label>{LABELS.ACTIVE_STATUS}</label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Yes"
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "No"
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                disabled={isSubmit}
              />
            </div>

            {/* mandatoryResults */}
            {mandatoryResults.length > 0 && (
              <div className="mandatoryBox">
                <ul>
                  {mandatoryResults.map((m) => {
                    return (
                      <li>
                        <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="formGroup submitBtnWrapper">
              <div className="flexbtnjustify">
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => {
                    setManageDialog(false);
                    setMandatoryResults([]);
                  }}
                  loading={isSubmit}
                />
                <Btn
                  text={LABELS.SAVE}
                  onClick={manageCentralZone}
                  loading={isSubmit}
                  bgColor={COLORS.SAVE_GREEN}
                />
              </div>
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};
export default withSnackbar(CentralZone);
