import React, { useState } from "react";

import HGraph from "components/Custom/HGraph";

import { GRAPH_TYPES } from "common/config";

const PieChart = (props: any) => {
  const { height } = props;
  const [pieChart, setPieChart] = useState<any>({});
  const series = [
    {
      name: "Browsers",
      colorByPoint: true,
      data: [
        {
          name: "Call",
          y: 61.04,
          drilldown: "Call",
        },
        {
          name: "Website",
          y: 9.47,
          drilldown: "Website",
        },
        {
          name: "Social Media",
          y: 9.32,
          drilldown: "Social Media",
        },
        {
          name: "Team",
          y: 8.15,
          drilldown: "Team",
        },
        {
          name: "Other",
          y: 11.02,
          drilldown: null,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <div className="graphs">
        <HGraph
          width={"100%"}
          height={height}
          type={GRAPH_TYPES.PIE}
          float={"right"}
          series={series}
          title={"Lead Source"}
          exportTitle={" Pie Chart Record"}
        />
      </div>
    </React.Fragment>
  );
};

export default PieChart;
