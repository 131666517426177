//Combination of column , line and pie:

/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";

// import { GRAPH_TYPES } from "common/config";
// import { GRAPHS } from "common/graphs";
// import { COLORS } from "common/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts3d(Highcharts);

interface cProps {
  width: string;
  type: "column";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any[];
  stacked?: boolean;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const ComboChart = (props: any) => {
  const {
    width,
    type,
    stacked,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  let options = {
    title: {
      text: "Sales of petroleum products March, Norway",
      align: "left",
    },
    xAxis: {
      categories: [
        "Jet fuel",
        "Duty-free diesel",
        "Petrol",
        "Diesel",
        "Gas oil",
      ],
    },
    yAxis: {
      title: {
        text: "Million liters",
      },
    },
    tooltip: {
      valueSuffix: " million liters",
    },
    plotOptions: {
      series: {
        borderRadius: "0%",
      },
    },
    series: [
      {
        type: "column",
        name: "2020",
        data: [59, 83, 65, 228, 184],
      },
      {
        type: "column",
        name: "2021",
        data: [24, 79, 72, 240, 167],
      },
      {
        type: "column",
        name: "2022",
        data: [58, 88, 75, 250, 176],
      },
      {
        type: "spline",
        name: "Average",
        data: [47, 83.33, 70.66, 239.33, 175.66],
        marker: {
          lineWidth: 2,
          lineColor: "#AA0000",
          fillColor: "white",
        },
      },
      {
        type: "pie",
        name: "Total",
        data: [
          {
            name: "2020",
            y: 619,
            color: "#FF0000", // 2020 color
            dataLabels: {
              enabled: true,
              distance: -50,
              format: "{point.total} M",
              style: {
                fontSize: "15px",
              },
            },
          },
          {
            name: "2021",
            y: 586,
            color: "#GG0000", // 2021 color
          },
          {
            name: "2022",
            y: 647,
            color: "#HH0000", // 2022 color
          },
        ],
        center: [75, 65],
        size: 100,
        innerSize: "70%",
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  console.log("options: ", options);

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default ComboChart;
