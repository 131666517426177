import React, { useState } from "react";
import { MenuList } from "@mui/material";

import { CONFIG } from "common/config";
import { LABELS } from "common/lables";

import "./dashboard.css";

import "devextreme/dist/css/dx.light.css";

const Orders = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo, setPageMenu } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li onClick={() => setPageMenu(LABELS.HOME)}>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.ORDERS}</li>
            </ul>
            <h4>{LABELS.ORDERS}</h4>
          </div>

          <MenuList dense className="dashboardMenuWrap"></MenuList>
        </div>
      )}

      {/* {dashMenu == LABELS.SUB_CATEGORY && (
        <Price
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )} */}
      {/* {dashMenu == LABELS.PRICE && (
        <Price
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )} */}
    </React.Fragment>
  );
};

export default Orders;
