import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid as DGrid,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  PictureAsPdf as IconPdf,
  Article as IconFile,
} from "@mui/icons-material";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  GroupPanel,
  SearchPanel,
  Grouping,
  Toolbar,
  Item,
  Button,
  Lookup,
} from "devextreme-react/data-grid";
import Guid from "devextreme/core/guid";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";
import ChkBox from "components/Custom/ChkBox";

import { MASTER_APIs } from "apihandlers/master";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { PRODUCT_APIs } from "apihandlers/product";
import { NOTIFY, DEFAULTS } from "common/config";
import { COLORS } from "common/colors";
import {
  CONFIRMS,
  LABELS,
  SUCCESS,
  ERRORS,
  WARNINGS,
  SUB_TITLES,
} from "common/lables";

import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";

let _timer = null;

const Scheme = (props: any) => {
  const { setDashMenu } = props;
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [schemeRows, setSchemeRows] = useState<any>([]);

  const [fileId, setFileId] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [customerTypes, setCustomerTypes] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [skuFreeBase, setSkuFreeBase] = useState<any>([]);

  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, setEditRowKey] = React.useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [newRowPosition, setNewRowPosition] = React.useState("viewportTop");

  useEffect(() => {
    if (loading) {
      getStates();
    }
  }, [loading]);

  const getStates = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    GEO_APIs.getStates(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.stateName,
          };
        });
        getCustomerTypes(list);
      }
    });
  };

  const getCustomerTypes = (statesList = []) => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    CUSTOMER_APIs.getCustomerTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        getskuFreeBase(statesList, list);
      }
    });
  };

  const getskuFreeBase = (statesList = [], cTypes = []) => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    PRODUCT_APIs.getProductsSku(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            name: r.productName,
          };
        });
        setStates(statesList);
        setCustomerTypes(cTypes);
        setSkuFreeBase(list);
        getRows();
      }
    });
  };

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    MASTER_APIs.getScheme(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        let recJson = {};
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          if (!recJson[list[i]["id"]]) {
            recJson[list[i]["id"]] = [];
          }
          recJson[list[i]["id"]].push(list[i]);
        }
        let tmpList = [];
        for (let id in recJson) {
          let tmpRec = recJson[id][0];
          tmpRec["rows"] = recJson[id];
          tmpList.push(tmpRec);
        }
        setRows(tmpList);
        setFilteredRows([]);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const onEditRow = (row: any) => {
    setSchemeRows(row.rows);
    setSelectedRow(row);
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: "Sl No.",
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { key: "schemeNo", name: "Scheme No.", width: "20%" },
    { key: "skuOfferName", name: "SKU Offer Name", width: "30%" },
    { key: "stateName", name: "State Name", width: "20%", align: "left" },
    { key: "fromDate", name: "From Date", width: "10%" },
    { key: "toDate", name: "To Date", width: "10%" },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRecord = () => {
    // const schemeNo = (
    //   document.getElementById("schemeNo") as HTMLInputElement
    // ).value.trim();
    // const schemeName = (
    //   document.getElementById("schemeName") as HTMLInputElement
    // ).value.trim();
    const schemeDetail = (
      document.getElementById("schemeDetail") as HTMLInputElement
    ).value.trim();
    const fromDate = (
      document.getElementById("fromDate") as HTMLInputElement
    ).value.trim();
    const toDate = (
      document.getElementById("toDate") as HTMLInputElement
    ).value.trim();
    const monday = (document.getElementById("monday") as HTMLInputElement)
      .checked;
    const tuesday = (document.getElementById("tuesday") as HTMLInputElement)
      .checked;
    const wednesday = (document.getElementById("wednesday") as HTMLInputElement)
      .checked;
    const thursday = (document.getElementById("thursday") as HTMLInputElement)
      .checked;
    const friday = (document.getElementById("friday") as HTMLInputElement)
      .checked;
    const saturday = (document.getElementById("saturday") as HTMLInputElement)
      .checked;
    const sunday = (document.getElementById("sunday") as HTMLInputElement)
      .checked;
    const active = (document.getElementById("active") as HTMLInputElement)
      .checked;
    const customerTypeId = customerTypesRef.current.selectedValue.id;
    const stateId = statesRef.current.selectedValue.id;

    // Validations
    if (fromDate === "") {
      COMMON_JS.showNotify(props, NOTIFY.ERROR, "From date is mandatory..!");
      return;
    }
    if (toDate === "") {
      COMMON_JS.showNotify(props, NOTIFY.ERROR, "To date is mandatory..!");
      return;
    }
    const args = {
      schemeId: selectedRow.id || -1,
      // schemeName,
      schemeDetail,
      fromDate,
      toDate,
      isMon: monday.toString(),
      isTue: tuesday.toString(),
      isWed: wednesday.toString(),
      isThu: thursday.toString(),
      isFri: friday.toString(),
      isSat: saturday.toString(),
      isSun: sunday.toString(),
      active: active.toString(),
      stateId,
      customerTypeId,
      items: schemeRows.filter((s) => {
        return s.skuOfferId;
      }),
    };
    console.log("manageScheme: ", args, schemeRows);
    MASTER_APIs.manageScheme(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        // setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.name.toUpperCase().indexOf(qry) > -1;
      });
      // console.log("flist: ", rows, flist, qry);
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const onAddButtonClick = React.useCallback((e: any) => {
    const key = new Guid().toString();
    setChanges([
      {
        key,
        type: "insert",
        insertAfterKey: e.row.key,
      },
    ]);
    setEditRowKey(key);
  }, []);

  const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  const onRowInserted = React.useCallback((e: any) => {
    e.component.navigateToRow(e.key);
  }, []);

  const onSaving = React.useCallback(
    (e: any) => {
      // console.log("onSaving: ", changes);
      e.cancel = true;

      let rowId = changes[0].key;
      let saveType = changes[0].type;
      let chng = changes;
      if (saveType === "update") {
        let changesRow = schemeRows.filter((r: any) => {
          return r.schemeDetailId === rowId;
        });
        // console.log("changesRow: ", changesRow, chng);
        if (changesRow.length > 0) {
          for (let key in changesRow[0]) {
            if (key !== "schemeDetailId") {
              if (!(key in chng[0]["data"])) {
                chng[0]["data"][key] = changesRow[0][key];
              }
            }
          }
        }
      }

      let tmpRows = schemeRows;
      let data = changes[0].data;
      if (saveType === "insert") {
        data.schemeDetailId = rowId;
        tmpRows.push(data);
      }
      if (rowId.indexOf("schemeDetailId-") === -1) {
        // New Record
        let parentRowId = changes[0].insertAfterKey;
        if (!parentRowId) {
          parentRowId = changes[0].key;
        }
        // ReGenerate Rows
        let tRows = [];
        for (let indx in schemeRows) {
          tRows.push(schemeRows[indx]);
          if (tRows[indx]["schemeDetailId"] === parentRowId) {
            if (saveType === "update") {
              for (let key in data) {
                tRows[indx][key] = data[key];
              }
            } else {
              // let empArr = employees.filter((e) => {
              //   return e.name === tRows[indx]["resource"];
              // });
              let row = {
                schemeDetailId: rowId,
                // resource: empArr.length > 0 ? empArr[0].name : "",
              };
              for (let key in data) {
                row[key] = data[key];
              }
              tRows.push(row);
            }
          }
        }
        setSchemeRows(tRows);
      } else {
        // Existing Record Update
        for (let indx in schemeRows) {
          if (tmpRows[indx]["schemeDetailId"] === rowId) {
            for (let key in data) {
              tmpRows[indx][key] = data[key];
            }
            break;
          }
        }
        setSchemeRows(tmpRows);
      }
      // console.log("final: ", rows, tmpRows, rowId);
      setChanges([]);
      setEditRowKey(null);
      setHasChanges(true);
      // e.promise = saveChange(dispatch, e.changes[0]);
    },
    [changes, rows]
  );

  const customerTypesRef: any = useRef();
  const statesRef: any = useRef();
  const frequencyRef: any = useRef();
  const gridRef: any = useRef(null);

  console.log("Scheme: ", schemeRows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.SCHEME}</li>
        </ul>
        <h4>{LABELS.SCHEME}</h4>
        <p>{SUB_TITLES.SCHEME}</p>
      </div>

      {/* {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
              let schemas = [];
              for (let i = 0; i < 10; i++) {
                schemas.push({
                  schemeDetailId: "scm-" + i,
                  valueMark: "",
                });
              }
              setSchemeRows(schemas);
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )} */}

      {/* <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
      {rows ? (
        // <DGrid
        //   rows={
        //     !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
        //   }
        //   columns={columns}
        //   rowHeight={50}
        //   // pageSize={5}
        //   // rowsPerPageOptions={[5]}
        //   disableColumnMenu={true}
        // />
        <ExcelGrid
          height={window.innerHeight * 0.8}
          cols={COLUMNS}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.SCHEME}
          onReload={reloadGrid}
          onAdd={onAdd}
        />
      ) : (
        <div className="flexRowCenter">
          <Loader />
        </div>
      )}
      {/* </div> */}

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Scheme: " + selectedRow.schemeDetail
              : "Add New Scheme"
          }
          open={true}
          size={"lg"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          {/* <div className="formGroup">
            <label>{"Scheme No."}</label>
            <TxtBox
              id={"schemeNo"}
              placeholder={""}
              defaultValue={selectedRow.name}
            />
          </div> */}
          <div style={{ width: "50%" }}>
            {/* <div className="formGroup">
              <label>{"Scheme Name"}</label>
              <TxtBox
                id={"schemeName"}
                placeholder={""}
                defaultValue={selectedRow.name}
                rows={3}
              />
            </div> */}
            <div className="formGroup">
              <label>{"Scheme Detail"}</label>
              <TxtBox
                id={"schemeDetail"}
                className={"multiLine"}
                multiline={true}
                placeholder={""}
                defaultValue={selectedRow.schemeDetail}
                rows={3}
              />
            </div>
            <div className="formGroup">
              <label>{"Customer Type"}</label>
              <AutoComplete
                id={"customerTypes"}
                list={customerTypes}
                childRef={customerTypesRef}
                defaultValue={
                  selectedRow.id
                    ? customerTypes.find(
                        (c: any) => c.id === selectedRow.customerType
                      )
                    : null
                }
                disabled={false}
              />
            </div>
            <div className="formGroup">
              <label>{"Select State"}</label>
              <AutoComplete
                id={"states"}
                list={states}
                childRef={statesRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.stateId,
                        label: selectedRow.stateName,
                      }
                    : null
                }
                disabled={false}
              />
            </div>
            <div className="formGroup">
              <label>{"From Date"}</label>
              <TxtBox
                id={"fromDate"}
                placeholder={"From Date"}
                defaultValue={selectedRow.fromDate}
                type={"date"}
              />
            </div>
            <div className="formGroup">
              <label>{"To Date"}</label>
              <TxtBox
                id={"toDate"}
                placeholder={"To Date"}
                defaultValue={selectedRow.toDate}
                type={"date"}
              />
            </div>
            <div className="formGroup">
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <ChkBox
                    text={"Monday"}
                    id={"monday"}
                    checked={selectedRow.isMon ? true : false}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <ChkBox
                    text={"Tuesday"}
                    id={"tuesday"}
                    checked={selectedRow.isTue ? true : false}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <ChkBox
                    text={"Wednesday"}
                    id={"wednesday"}
                    checked={selectedRow.isWed ? true : false}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <ChkBox
                    text={"Thursday"}
                    id={"thursday"}
                    checked={selectedRow.isThu ? true : false}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <ChkBox
                    text={"Friday"}
                    id={"friday"}
                    checked={selectedRow.isFri ? true : false}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <ChkBox
                    text={"Saturday"}
                    id={"saturday"}
                    checked={selectedRow.isSat ? true : false}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <ChkBox
                    text={"Sunday"}
                    id={"sunday"}
                    checked={selectedRow.isSun ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="formGroup">
              <SwitchBtn
                text={LABELS.ACTIVE}
                id={"active"}
                checked={selectedRow.id ? selectedRow.activeStatus : true}
              />
            </div>
          </div>

          <div
            className="grid"
            style={{
              width: "98%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DataGrid
              id="gridContainer"
              className="resourcePlanning"
              style={{
                overflow: "auto",
              }}
              ref={gridRef}
              dataSource={schemeRows}
              keyExpr="schemeDetailId"
              showBorders={true}
              allowColumnResizing={true}
              columnMinWidth={150}
              columnAutoWidth={true}
              onRowInserted={onRowInserted}
              onSaving={onSaving}
            >
              <Scrolling columnRenderingMode="virtual" />
              <Paging enabled={false} />
              <Editing
                mode="row"
                allowAdding={true}
                allowDeleting={true}
                allowUpdating={true}
                confirmDelete={true}
                useIcons={true}
                newRowPosition={newRowPosition}
                changes={changes}
                onChangesChange={setChanges}
                editRowKey={editRowKey}
                onEditRowKeyChange={setEditRowKey}
              />
              <Column
                type="buttons"
                caption="Options"
                minWidth={80}
                width={80}
                alignment={"center"}
              >
                {/* <Button
                  icon="add"
                  onClick={onAddButtonClick}
                  visible={isAddButtonVisible}
                /> */}
                <Button name="edit" />
                {/* <Button name="delete" /> */}
                <Button name="save" />
                <Button name="cancel" />
              </Column>
              <Column
                dataField="valueMark"
                caption={"Value Mark"}
                width={100}
              />
              <Column
                dataField="discountPercentage"
                caption={"Discount Percentage"}
                width={200}
              />
              <Column
                dataField="quantityMark"
                caption={"Quantity Mark"}
                width={100}
              />
              <Column
                dataField="qtyDiscountPercentage"
                caption={"Qty. Discount Percent"}
                width={200}
              />
              <Column
                dataField="skuFreeBaseId"
                caption="SKU Free Base"
                width={250}
              >
                <Lookup
                  dataSource={skuFreeBase}
                  displayExpr="name"
                  valueExpr="id"
                />
              </Column>
              <Column
                dataField="skuBaseMark"
                caption={"SKU Base Mark"}
                width={100}
              />
              <Column dataField="skuOfferId" caption="SKU Offer" width={250}>
                <Lookup
                  dataSource={skuFreeBase}
                  displayExpr="name"
                  valueExpr="id"
                />
              </Column>
              <Column
                dataField="skuOfferQunatity"
                caption={"SKU Offer Qty."}
                width={100}
              />
              <Column
                dataField="skuOfferPercentage"
                caption={"SKU Offer Percent"}
                width={200}
              />
              <GroupPanel visible={true} />
              <SearchPanel visible={true} />
              <Grouping autoExpandAll={true} />
            </DataGrid>
          </div>

          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn
                text={LABELS.SAVE}
                onClick={manageRecord}
                bgColor={COLORS.SAVE_GREEN}
              />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Scheme);
