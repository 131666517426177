/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";

// import { GRAPH_TYPES } from "common/config";
// import { GRAPHS } from "common/graphs";
// import { COLORS } from "common/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts3d(Highcharts);

interface cProps {
  width: string;
  type: "column";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any[];
  stacked?: boolean;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const ColumnNeg = (props: any) => {
  const {
    width,
    type,
    stacked,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  let options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Column chart with negative values",
    },
    xAxis: {
      categories: ["Apples", "Oranges", "Pears", "Grapes", "Bananas"],
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: "0%",
      },
    },
    series: [
      {
        name: "John",
        data: [5, 3, 4, 7, 2],
      },
      {
        name: "Jane",
        data: [2, -2, -3, 2, 1],
      },
      {
        name: "Joe",
        data: [3, 4, 4, -2, 5],
      },
    ],
  };

  console.log("options: ", options);

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default ColumnNeg;
