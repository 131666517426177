import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  PictureAsPdf as IconPdf,
  Article as IconFile,
} from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";
// import {ApiMasters} from '@apihandlers/masters/masters';
import { NOTIFY, DEFAULTS } from "common/config";
import { COLORS } from "common/colors";
import {
  CONFIRMS,
  LABELS,
  SUCCESS,
  ERRORS,
  WARNINGS,
  SUB_TITLES,
} from "common/lables";

import { COMMON_JS } from "common/scripts";

let _timer = null;

const Designations = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);

  const [fileId, setFileId] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    // ApiMastersHandler.getVehicleTypes(args).then((res: any) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list = response.records;
    //     for (let i = 0; i < response.records.length; i++) {
    //       list[i]["slNo"] = i + 1;
    //       if (list[i]["activeStatus"]) {
    //         list[i]["activeStatusText"] = "Yes";
    //       } else {
    //         list[i]["activeStatusText"] = "No";
    //       }
    //     }
    //     setRows(list);
    //     setFilteredRows([]);
    //     setLoading(false);
    //   }
    // });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "name", headerName: "Designation Name", width: 300 },
    { field: "activeStatusText", headerName: "Active", width: 200 },
    {
      field: "options",
      headerName: "Options",
      width: 150,
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRecord = () => {
    const name = (
      document.getElementById("name") as HTMLInputElement
    ).value.trim();
    const activeStatus = (
      document.getElementById("isActive") as HTMLInputElement
    ).checked;

    // Validations
    if (!name) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.DESIGNATION_NAME_EMPTY
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      name,
      activeStatus: activeStatus.toString(),
    };
    // ApiMastersHandler.manageVehicleType(args).then((res) => {
    //   if (res.status === 200) {
    //     COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
    //     setSelectedRow({});
    //     setManageDialog(false);
    //     reloadGrid();
    //   } else {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
    //   }
    // });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.name.toUpperCase().indexOf(qry) > -1;
      });
      // console.log("flist: ", rows, flist, qry);
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const orgRef: any = useRef();
  const userRef: any = useRef();
  const frequencyRef: any = useRef();

  // console.log("Users: ", loading, selectedRow.active);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.DESIGNATION_MASTER}</li>
        </ul>
        <h4>{LABELS.DESIGNATION_MASTER}</h4>
        <p>{SUB_TITLES.DESIGNATION_MASTER}</p>
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={
              !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
            }
            columns={columns}
            rowHeight={50}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Designation: " + selectedRow.name
              : "Add New Designation"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{LABELS.DESIGNATION}</label>
            <TxtBox
              id={"name"}
              placeholder={LABELS.DESIGNATION}
              defaultValue={selectedRow.name}
            />
          </div>
          <div className="formGroup">
            <SwitchBtn
              text={LABELS.ACTIVE}
              id={"isActive"}
              checked={selectedRow.id ? selectedRow.activeStatus : true}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn
                text={LABELS.SAVE}
                bgColor={COLORS.SAVE_GREEN}
                onClick={manageRecord}
              />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Designations);
