import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "components/Custom/UiDialog";
import SkeletonBox from "components/Custom/SkeletonBox";

import { ERRORS, LABELS, SUCCESS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { TRANS_APIs } from "apihandlers/transaction";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { PRODUCT_APIs } from "apihandlers/product";
import ChkBox from "components/Custom/ChkBox";

const AddNewPhysicalVoucher = (props: any) => {
  const {
    hideHeader,
    setPageMenu,
    setManageDialog,
    voucherItemRows,
    selectedRow,
    onClose,
  } = props;

  const addPhysicalVoucherRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [products, setProducts] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "productName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.PRODUCT,
      width: "30%",
    },
    {
      key: "strip",
      type: "number",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.STRIP,
      width: "33%",
    },
    {
      key: "packet",
      type: "number",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.PACKET,
      width: "33%",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      setRows(null);
      const res: any = await PRODUCT_APIs.getProductsByPrice(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getProductsByPrice:", error);
    }
  };

  const mangePhysicalVoucher = () => {
    const gridRows = addPhysicalVoucherRef.getRowData();
    setLoading(true);
    let items = [];
    for (let i = 0; i < gridRows.length; i++) {
      items.push({
        itemId: -1,
        productSkuId: gridRows[i].productSkuId,
        strip: gridRows[i].strip,
        packet: gridRows[i].packet,
      });
    }
    const args = {
      stockId: selectedRow.id || -1,
      items: items,
      approved: "false",
    };
    console.log("args:", args);
    TRANS_APIs.managePhysicalVoucher(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setManageDialog(false);
        onClose();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  console.log("voucherItemRows: ", voucherItemRows);

  let gridRowsData = selectedRow.id ? voucherItemRows : rows;

  return (
    <React.Fragment>
      <div className="userDashboard">
        <div>
          {/* <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width40"></div>
            <div className="formGroup width40"></div>
          </div> */}
          {!loading && gridRowsData ? (
            <>
              <div className="grid excelGridWrapper">
                <ExcelGrid
                  id={"addPhysicalVoucher"}
                  height={window.innerHeight * 0.5}
                  childRef={addPhysicalVoucherRef}
                  cols={columns}
                  rows={gridRowsData}
                  newRowOption={false}
                  exportName={LABELS.ADD_SALES_INVOICE}
                  // onReload={reloadGrid}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  width: "97%",
                }}
              >
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Btn
                    text={LABELS.SAVE}
                    onClick={mangePhysicalVoucher}
                    marginRight={15}
                    bgColor={COLORS.GRID_ADD_NEW}
                    //  loading={isSubmit}
                  />
                </div>
              </div>
            </>
          ) : (
            <SkeletonBox width="100%" height={200} />
          )}
        </div>
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(AddNewPhysicalVoucher);
