import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "../../Custom/UiDialog";
import AddSalesInvoice from "../AddSalesInvoice";

import { LABELS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { PRODUCT_APIs } from "apihandlers/product";
import { USER_APIs } from "apihandlers/user";
import { TRANS_APIs } from "apihandlers/transaction";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";

const EInvoice = (props: any) => {
  const { setDashMenu } = props;

  const productRef: any = useRef(null);
  const userRef: any = useRef(null);
  const areasRef: any = useRef(null);
  const partyRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [rows, setRows] = useState<any>(null);
  const [filter, setFilter] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [products, setProducts] = useState<any>({});
  const [areas, setAreas] = useState<any>({});
  const [resetArgs, setResetArgs] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);

  const [parties, setParties] = useState<any>({});
  const [users, setUsers] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "partyName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.PARTY_NAME,
      width: "10%",
    },
    {
      key: "voucherNumber",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.VOUCHER_NO,
      width: "15%",
    },
    {
      key: "voucherDate",
      type: "date",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.VOUCHER_DATE,
      width: "10%",
    },
    {
      key: "irnNo",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.IRN_NO,
      width: "20%",
    },
    {
      key: "eWayBillNO",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.E_WAY_BILL_NO,
      width: "20%",
    },
    {
      key: "validFrom",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.VALID_FROM,
      width: "10%",
    },
    {
      key: "validTo",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.DL_VALID_UPTO,
      width: "10%",
    },
    {
      key: "total",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ACK_NO,
      width: "10%",
    },
    {
      key: "ackDate",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ACK_DATE,
      width: "10%",
    },
    {
      key: "status",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.STATUS,
      width: "10%",
    },
    {
      key: "uploadType",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.UPLOAD_TYPE,
      width: "10%",
    },

    {
      key: "voucherType",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.VOUCHER_TYPE,
      width: "10%",
    },
    {
      key: "gstIn",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.GST_IN_NO,
      width: "10%",
    },
    {
      key: "voucherAmt",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.VOUCHER_AMOUNT,
      width: "10%",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const fromDate = (
        document.getElementById("fromDate") as HTMLInputElement
      ).value.trim();
      const toDate = (
        document.getElementById("toDate") as HTMLInputElement
      ).value.trim();
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        fromDate,
        toDate,
      };
      setFilter(args);
      setRows(null);
      const res: any = await TRANS_APIs.getOrders(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.order;
        // for (let i = 0; i < response.order.length; i++) {
        //   list[i]["slNo"] = i + 1;
        // }
        setRows([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("getOrders:", error);
    }
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };
  const importResponse = (e) => {
    setManageDialog(true);
  };
  const printEInvoice = (e) => {
    console.log(e);
  };

  return (
    <React.Fragment>
      {
        <div className="pageInfo">
          <ul className="breadcrumbs">
            <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
            <li className="dim">{" / "}</li>
            <li onClick={() => props.setDashMenu(LABELS.HOME)}>
              {LABELS.SALES}
            </li>
            <li className="dim">{" / "}</li>
            <li className="active">{TITLES.E_INVOICE}</li>
          </ul>
          <h4>{TITLES.E_INVOICE}</h4>
        </div>
      }
      <div className="width50 filterBorder">
        <UiAccordion id={"filter"} title={LABELS.FILTER}>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.FROM_DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.TO_DATE}
                type={"date"}
                id="toDate"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <div style={{ marginTop: "1.5rem" }}>
                <Btn
                  text={LABELS.LOAD}
                  onClick={getRows}
                  bgColor={COLORS.GRID_ADD_NEW}
                  // loading={isSubmit}
                />
              </div>
            </div>
          </div>
        </UiAccordion>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.55}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.E_INVOICE}
            onReload={reloadGrid}
            leftSideWidth={"50%"}
            rightSideWidth={"50%"}
            leftSideBtns={
              <>
                <Btn
                  className={"excelBtnWrapper"}
                  text={LABELS.IMPORT_RESPONSE}
                  onClick={importResponse}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                />
                <Btn
                  className={"excelBtnWrapper"}
                  text={LABELS.PRINT}
                  onClick={printEInvoice}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                />
              </>
            }
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(EInvoice);
