import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "../../Custom/UiDialog";

import { LABELS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { PRODUCT_APIs } from "apihandlers/product";
import { USER_APIs } from "apihandlers/user";
import { TRANS_APIs } from "apihandlers/transaction";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { GetApp } from "@mui/icons-material";

const SalesInvoice = (props: any) => {
  const { setDashMenu } = props;

  const productRef: any = useRef(null);
  const userRef: any = useRef(null);
  const areasRef: any = useRef(null);
  const partyRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [rows, setRows] = useState<any>(null);
  const [filter, setFilter] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [products, setProducts] = useState<any>({});
  const [areas, setAreas] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);

  const [parties, setParties] = useState<any>({});
  const [users, setUsers] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "",
      selectColumn: true,
    },
    {
      key: "expanded",
    },
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "billDate",
      type: "datetime",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.DATE,
      width: "10%",
    },
    {
      key: "billNumber",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_NO,
      width: "10%",
    },
    {
      key: "shopName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.SHOP_NAME,
      width: "15%",
    },
    {
      key: "routeName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ROUTE_NAME,
      width: "15%",
    },
    {
      key: "address",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ADDRESS,
      width: "10%",
    },
    {
      key: "items",
      name: LABELS.ITEMS,
      editable: true,
      sortable: true,
      searchable: true,
      type: "dropdown",
      width: "10%",
      list: [
        {
          id: "1",
          label: "Masala Bites 100 grams",
        },
        {
          id: "2",
          label: "Masala Bites 200 grams",
        },
        {
          id: "3",
          label: "Masala Bites 150 grams",
        },
        {
          id: "4",
          label: "Masala Bites 2000 grams",
        },
      ],
      filters: [
        {
          id: "1",
          label: "Masala Bites 100 grams",
        },
        {
          id: "2",
          label: "Masala Bites 200 grams",
        },
        {
          id: "3",
          label: "Masala Bites 150 grams",
        },
        {
          id: "4",
          label: "Masala Bites 2000 grams",
        },
      ],
    },
    {
      key: "amount",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.AMOUNT,
      width: "10%",
      renderSum: true,
    },
    {
      key: "salesMan",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.SALESMAN,
      width: "15%",
    },
    {
      key: "bill",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL,
      width: "10%",
    },
    {
      key: "return",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.RETURN,
      width: "10%",
    },
  ];
  const formulaRenderer = (row) => {
    console.log("formulaRenderer: ", row);
    let qty = Number(row["qty"] || 0);
    let price = Number(row["price"] || 0);
    let amount = 0;
    if (!isNaN(qty) && !isNaN(price)) {
      amount = price * qty;
    }
    row["amount"] = amount;
    return row;
  };

  const columns2 = [
    {
      key: "",
      selectColumn: true,
    },
    {
      key: "slNo",
      type: "text",
      editable: false,
      sortable: false,
      searchable: true,
      name: LABELS.SL_NO,
      width: "5%",
    },
    {
      key: "productName",
      type: "text",
      editable: false,
      sortable: false,
      searchable: true,
      name: LABELS.PRODUCT_NAME,
      width: "35%",
    },
    {
      key: "qty",
      type: "text",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.QTY,
      width: "20%",
      renderSum: true,

      formula: formulaRenderer,
      isDisabled: (row: any) => {
        return row.price <= 10;
      },
    },
    {
      key: "price",
      type: "text",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.RATE,
      width: "25%",
      renderSum: true,
      formula: formulaRenderer,
    },
    {
      key: "amount",
      type: "text",
      editable: false,
      sortable: false,
      searchable: false,
      name: LABELS.AMOUNT,
      width: "20%",
      renderSum: true,
      formula: formulaRenderer,
    },
  ];

  useEffect(() => {
    if (loading) {
      getRoute();
    }
  }, [loading]);

  const getRoute = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setAreas(list);
        getParty();
      }
    });
  };

  const getParty = () => {
    const args = {
      activeStatus: true,
    };
    CUSTOMER_APIs.getCustomerProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setParties(list);
        getProduct();
      }
    });
  };

  const getProduct = () => {
    const args = {
      activeStatus: true,
    };
    PRODUCT_APIs.getProductsSku(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setProducts(list);
        getUser();
      }
    });
  };

  const getUser = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setUsers(list);
        getRows();
      }
    });
  };

  const subGrid = (subRows = []) => {
    return (
      <>
        <ExcelGrid
          id={"salesSubGrid"}
          cols={columns2}
          rows={subRows}
          isSubGrid={true}
          noTotalRows={true}
          onAdd={() => {}}
        />
      </>
    );
  };

  const getRows = async () => {
    try {
      const fromDate = (
        document.getElementById("fromDate") as HTMLInputElement
      ).value.trim();
      const toDate = (
        document.getElementById("toDate") as HTMLInputElement
      ).value.trim();
      const routeId = areasRef.current.selectedValue.id;
      const partyId = partyRef.current.selectedValue.id;
      const productId = productRef.current.selectedValue.id;
      const userId = userRef.current.selectedValue.id;
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        orderId: "",
        fromDate,
        toDate,
        routeId,
        partyId,
        productId,
        userId,
      };
      setFilter(args);
      setRows(null);
      const res: any = await TRANS_APIs.getSalesInvoice(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          list[i]["_type"] = "maingrid";
          list[i]["_expanded"] = false;
          let subList = list[i].sales;
          for (let j = 0; j < subList.length; j++) {
            subList[j]["slNo"] = j + 1;
          }
          list[i]["_subGrid"] = () => subGrid(subList);
          if (list[i].billNumber == 200) {
            list[i]["_className"] = "bgHighlight";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getSalesInvoice:", error);
    }
  };

  const onReset = (e) => {
    console.log(e);
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const viewSummary = (e) => {
    console.log(e);
  };

  const ordedrForm = (e) => {
    console.log(e);
  };

  const viewInvoice = (e) => {
    console.log(e);
  };

  const printSummary = (e) => {
    console.log(e);
  };
  const printBill = (e) => {
    console.log(e);
  };

  let exportName = [LABELS.SALES_INVOICE];

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.SALES}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.SALES_INVOICE}</li>
        </ul>
        <h4>{TITLES.SALES_INVOICE}</h4>
      </div>
      <div className="width50 filterBorder">
        <UiAccordion id={"filter"} title={LABELS.FILTER}>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.FROM_DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.TO_DATE}
                type={"date"}
                id="toDate"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.ROUTE}</label>

              <AutoComplete
                list={areas}
                childRef={areasRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Route" }
                }
              />
            </div>
          </div>

          <div className="flexRowAround" style={{ margin: 0, padding: 0 }}>
            <div className="formGroup width30">
              <label>{LABELS.PARTY}</label>

              <AutoComplete
                list={parties}
                childRef={partyRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Party" }
                }
              />
            </div>

            <div className="formGroup width30">
              <label>{LABELS.PRODUCT}</label>

              <AutoComplete
                list={products}
                childRef={productRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Product" }
                }
                onChange={(e, v) => {}}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.USER}</label>

              <AutoComplete
                list={users}
                childRef={userRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select User" }
                }
              />
            </div>
          </div>
          <div className="flexRowAround" style={{ margin: 0, padding: 0 }}>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
            <div
              className="formGroup width30"
              style={{ margin: 0, padding: 0 }}
            >
              <div className="flexbtnLoad " style={{ margin: 0, padding: 0 }}>
                <Btn
                  text={LABELS.RESET}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={onReset}
                  //loading={isSubmit}
                />
                <Btn
                  text={LABELS.LOAD}
                  onClick={getRows}
                  bgColor={COLORS.GRID_ADD_NEW}
                  // loading={isSubmit}
                />
              </div>
            </div>
          </div>
        </UiAccordion>
      </div>
      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"salesInvoice"}
            height={window.innerHeight * 0.55}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={exportName.join("-")}
            hideUnhide={true}
            freezeOption={true}
            onReload={reloadGrid}
            onAdd={onAdd}
            leftSideWidth={"80%"}
            rightSideWidth={"20%"}
            leftSideBtns={
              <>
                <div className="excelBtnWrapper">
                  <Btn
                    text={LABELS.E_INVOICE}
                    onClick={viewInvoice}
                    bgColor={COLORS.RELOAD_EXPORT}
                    color={COLORS.RELOAD_EXPORT_TEXT}
                  />
                </div>
                <div className="excelBtnWrapper">
                  <Btn
                    text={LABELS.VIEW_SUMMARY}
                    onClick={viewSummary}
                    bgColor={COLORS.RELOAD_EXPORT}
                    color={COLORS.RELOAD_EXPORT_TEXT}
                  />
                </div>
                <div className="excelBtnWrapper">
                  <Btn
                    text={LABELS.ORDER_FROM}
                    onClick={ordedrForm}
                    bgColor={COLORS.RELOAD_EXPORT}
                    color={COLORS.RELOAD_EXPORT_TEXT}
                  />
                </div>

                <div className="excelBtnWrapper">
                  <Btn
                    text={LABELS.PRINT_SUMMARY}
                    size={"medium"}
                    onClick={printSummary}
                    bgColor={COLORS.RELOAD_EXPORT}
                    color={COLORS.RELOAD_EXPORT_TEXT}
                  />
                </div>

                <div className="excelBtnWrapper">
                  <Btn
                    text={LABELS.PRINT_BILL}
                    onClick={printBill}
                    bgColor={COLORS.RELOAD_EXPORT}
                    color={COLORS.RELOAD_EXPORT_TEXT}
                  />
                </div>
              </>
            }
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
        {manageDialog && (
          <UiDialog
            title={
              selectedRow.id
                ? "Edit " + TITLES.SALES_INVOICE + ": " + selectedRow.id
                : "Add " + TITLES.SALES_INVOICE
            }
            open={true}
            size={"md"}
            onClose={() => {
              setManageDialog(false);
            }}
          ></UiDialog>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(SalesInvoice);
