import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  LocalPrintshop as IconPrint,
  Article as IconCv,
} from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { CONFIRMS, LABELS, SUCCESS, ERRORS, WARNINGS } from "common/lables";

import { COMMON_JS } from "common/scripts";

let _timer = null;

const thumbnail = require("assets/images/employee.png");

const Employees = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedCvRow, setSelectedCvRow] = useState<any>(null);
  const [designations, setDesignations] = useState([]);
  const [cvDialog, setCvDialog] = useState(false);

  const [profession, setProfession] = useState("");
  const [qualification, setQualification] = useState("");
  const [course, setCourse] = useState("");
  const [keySkills, setKeySkills] = useState("");
  const [countries, setCountries] = useState("");
  const [experience, setExperience] = useState("");
  const [recentProfHistory, setRecentProfHistory] = useState("");
  const [selectedProfHistory, setSelectedProfHistory] = useState("");

  useEffect(() => {
    if (loading) {
      getDesignations();
    }
  }, [loading]);

  const getDesignations = () => {
    const args = {};
    // API_HANDLER_MASTERS.getDesignationMaster(args).then((res: any) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list = response.records.map((r) => {
    //       return {
    //         id: r.id,
    //         label: r.designationName,
    //       };
    //     });
    //     setDesignations(list);
    //     getRows();
    //   }
    // });
  };

  const getRows = () => {
    const args = {};
    // API_HANDLER_MASTERS.getEmployeeMaster(args).then((res: any) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list = response.records;
    //     for (let i = 0; i < response.records.length; i++) {
    //       list[i]["slNo"] = i + 1;
    //       if (list[i]["activeStatus"]) {
    //         list[i]["activeStatusText"] = "Yes";
    //       } else {
    //         list[i]["activeStatusText"] = "No";
    //       }
    //     }
    //     setRows(list);
    //     setFilteredRows([]);
    //     setLoading(false);
    //   }
    // });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    // Get Image
    if (row.fileId) {
      const args = { fileId: row.fileId };
      // API_HANDLER_HOME.getFile(args).then((res: any) => {
      //   let base64 = "data:" + row.mimeType + ";base64, " + res;
      //   fetch(base64).then((r) => {
      //     r.blob().then((data) => {
      //       let objUrl = window.URL.createObjectURL(data);
      //       // console.log("objUrl: ", objUrl);
      //       (document.getElementById("profilePic") as HTMLElement).setAttribute(
      //         "src",
      //         objUrl
      //       );
      //     });
      //   });
      // });
    }
  };

  const onAddCv = (row: any) => {
    setCvDialog(true);
    setSelectedCvRow(null);
    // Get CV Info
    let args = { empId: row.id };
    // API_HANDLER_MASTERS.getCv(args).then((res) => {
    //   if (res.status === 200) {
    //     let emp: any = res.response;
    //     if (!emp.empId) {
    //       emp = {
    //         empId: row.id,
    //         empName: row.empName,
    //       };
    //     }
    //     setSelectedCvRow(emp);
    //     setProfession(emp.profession);
    //     setQualification(emp.qualification);
    //     setCourse(emp.course);
    //     setKeySkills(emp.skill);
    //     setCountries(emp.workedCountries);
    //     setExperience(emp.experience);
    //     setRecentProfHistory(emp.history);
    //     setSelectedProfHistory(emp.selectedHistory);
    //   } else {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
    //   }
    // });
  };

  const onPrintCv = (row: any) => {
    const args = {
      empId: row.id,
    };
    // API_HANDLER_PRINT.printCv(args)
    //   .then((res) => {
    //     if (res) {
    //       let base64 = "data:application/pdf;base64, " + res;
    //       fetch(base64).then((r) => {
    //         r.blob().then((data) => {
    //           let objUrl = window.URL.createObjectURL(data);
    //           window.open(objUrl, "_blank");
    //           window.URL.revokeObjectURL(objUrl);
    //         });
    //       });
    //     } else {
    //       COMMON_JS.showNotify(props, NOTIFY.ERROR, "CV Not Found..!");
    //     }
    //   })
    //   .catch((err) => {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, "CV Not Found..!");
    //   });
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 80,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "empName", headerName: "Employee Name", width: 200 },
    { field: "empCode", headerName: "Employee ID", width: 150 },
    { field: "designationName", headerName: "Designation", width: 180 },
    { field: "contactNumber", headerName: "Contact Number", width: 180 },
    // { field: "emergencyNumber", headerName: "Emergency Number", width: 150 },
    { field: "officeEmail", headerName: "Official Email", width: 200 },
    // { field: "personalEmail", headerName: "Personal Email", width: 200 },
    { field: "preseAddress", headerName: "Present Address", width: 300 },
    // { field: "permAddress", headerName: "Permanent Address", width: 300 },
    // { field: "startDate", headerName: "Start Date", width: 120 },
    // { field: "finishDate", headerName: "Finish Date", width: 120 },
    // { field: "bloodGroup", headerName: "Blood Group", width: 100 },
    { field: "activeStatusText", headerName: "Active", width: 100 },
    {
      field: "options",
      headerName: "Options",
      width: 150,
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.ADD_CV} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onAddCv(params.row)}>
              <IconCv />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.PRINT_CV} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onPrintCv(params.row)}
            >
              <IconPrint />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRecord = () => {
    const designationId = designationsRef.current.selectedValue.id;

    const empName = (
      document.getElementById("empName") as HTMLInputElement
    ).value.trim();
    const empCode = (
      document.getElementById("empCode") as HTMLInputElement
    ).value.trim();
    const contactNumber = (
      document.getElementById("contactNumber") as HTMLInputElement
    ).value.trim();
    const emergencyNumber = (
      document.getElementById("emergencyNumber") as HTMLInputElement
    ).value.trim();
    const personalEmail = (
      document.getElementById("personalEmail") as HTMLInputElement
    ).value.trim();
    const officeEmail = (
      document.getElementById("officeEmail") as HTMLInputElement
    ).value.trim();
    const permAddress = (
      document.getElementById("permAddress") as HTMLInputElement
    ).value.trim();
    const preseAddress = (
      document.getElementById("preseAddress") as HTMLInputElement
    ).value.trim();
    const startDate = (
      document.getElementById("startDate") as HTMLInputElement
    ).value.trim();
    const finishDate = (
      document.getElementById("finishDate") as HTMLInputElement
    ).value.trim();
    const bloodGroup = (
      document.getElementById("bloodGroup") as HTMLInputElement
    ).value.trim();

    const activeStatus = (
      document.getElementById("isActive") as HTMLInputElement
    ).checked;

    // Validations
    if (!designationId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Select Designation..!"
      );
      return;
    }
    if (!empName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Employee Name..!"
      );
      return;
    }
    if (!contactNumber) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Contact Number..!"
      );
      return;
    }
    if (!emergencyNumber) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Emergency Number..!"
      );
      return;
    }
    if (!personalEmail) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Personal Email..!"
      );
      return;
    }
    if (!officeEmail) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Office Email..!"
      );
      return;
    }
    if (!permAddress) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Permanent Address..!"
      );
      return;
    }
    if (!preseAddress) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Present Address..!"
      );
      return;
    }
    if (!startDate) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please Enter Start Date..!");
      return;
    }
    if (!bloodGroup) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please Enter Blood Group..!"
      );
      return;
    }
    const args = {
      id: selectedRow.id || -1,
      empCode,
      designationId,
      empName,
      contactNumber,
      emergencyNumber,
      personalEmail,
      officeEmail,
      permAddress,
      preseAddress,
      startDate,
      bloodGroup,
      activeStatus,
      finishDate,
    };
    let file: any = document.getElementById("empPhoto") as HTMLInputElement;
    // console.log("file:", file.files);
    if (file.files) {
      if (file.files.length > 0) {
        file = file.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let base64: any = reader.result;
          args["file"] = {
            name: file.name,
            data: base64.replace("data:", "").replace(/^.+,/, ""),
            type: file.name.split(".")[1],
            size: file.size,
          };
          saveRecord(args);
        };
      } else {
        saveRecord(args);
      }
    } else {
      saveRecord(args);
    }
  };

  const saveRecord = (args = {}) => {
    // API_HANDLER_MASTERS.manageEmployee(args).then((res) => {
    //   if (res.status === 200) {
    //     COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
    //     setSelectedRow({});
    //     setManageDialog(false);
    //     reloadGrid();
    //   } else {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
    //   }
    // });
  };

  const saveCV = () => {
    // Validations
    if (!profession) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please Enter Profession..!");
      return;
    }

    const args = {
      id: selectedCvRow.id || -1,
      empId: selectedCvRow.empId,
      profession,
      qualification,
      course,
      skill: keySkills,
      workedCountries: countries,
      experience,
      selectedHistory: selectedProfHistory,
      history: recentProfHistory,
    };
    // API_HANDLER_MASTERS.manageCv(args).then((res) => {
    //   if (res.status === 200) {
    //     COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
    //     setSelectedCvRow({});
    //     setCvDialog(false);
    //     reloadGrid();
    //   } else {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
    //   }
    // });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.empName.toUpperCase().indexOf(qry) > -1;
      });
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f: any, i: number) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const uploadImg = (e: any, f: any) => {
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > 2) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "File size should not exceed more than 2MB..!"
      );
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      (document.getElementById("profilePic") as HTMLInputElement).setAttribute(
        "src",
        base64
      );
    };
    reader.onerror = (error) => {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "File upload failed..!");
      // console.log("uploadFile: Error:", error);
    };
  };

  const designationsRef: any = useRef(null);

  // console.log("getCv: profession:", profession);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.EMPLOYEE_MASTER}</li>
        </ul>
        <h4>{LABELS.EMPLOYEE_MASTER}</h4>
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={
              !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
            }
            columns={columns}
            rowHeight={50}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Employee: " + selectedRow.empName
              : "Add New Employee"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>{LABELS.PHOTO}</label>
            <img id="profilePic" src={thumbnail} style={{ width: "30%" }} />
            <TxtBox
              id={"empPhoto"}
              type={"file"}
              accept="image/*"
              onChange={uploadImg}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.EMPLOYEE_ID}</label>
            <TxtBox
              id={"empCode"}
              placeholder={LABELS.EMPLOYEE_ID}
              defaultValue={selectedRow.empCode || "Auto"}
              disabled={selectedRow.empCode ? false : true}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.EMPLOYEE_NAME}</label>
            <TxtBox
              id={"empName"}
              placeholder={LABELS.EMPLOYEE_NAME}
              defaultValue={selectedRow.empName}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.DESIGNATION}</label>
            <AutoComplete
              id={"designations"}
              list={designations}
              childRef={designationsRef}
              defaultValue={
                selectedRow.designationId
                  ? {
                      id: selectedRow.designationId,
                      label: selectedRow.designationName,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_NUMBER}</label>
            <TxtBox
              id={"contactNumber"}
              placeholder={LABELS.CONTACT_NUMBER}
              defaultValue={selectedRow.contactNumber}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.E_CONTACT_NUMBER}</label>
            <TxtBox
              id={"emergencyNumber"}
              placeholder={LABELS.E_CONTACT_NUMBER}
              defaultValue={selectedRow.emergencyNumber}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PERSONAL_EMAIL}</label>
            <TxtBox
              id={"personalEmail"}
              placeholder={LABELS.PERSONAL_EMAIL}
              defaultValue={selectedRow.personalEmail}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.OFFICE_EMAIL}</label>
            <TxtBox
              id={"officeEmail"}
              placeholder={LABELS.OFFICE_EMAIL}
              defaultValue={selectedRow.officeEmail}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PERMANENT_ADDRESS}</label>
            <TxtBox
              id={"permAddress"}
              placeholder={LABELS.PERMANENT_ADDRESS}
              defaultValue={selectedRow.permAddress}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PRESENT_ADDRESS}</label>
            <TxtBox
              id={"preseAddress"}
              placeholder={LABELS.PRESENT_ADDRESS}
              defaultValue={selectedRow.preseAddress}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.START_DATE}</label>
            <TxtBox
              id={"startDate"}
              placeholder={LABELS.START_DATE}
              defaultValue={selectedRow.startDate}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.FINISH_DATE}</label>
            <TxtBox
              id={"finishDate"}
              placeholder={LABELS.FINISH_DATE}
              defaultValue={selectedRow.finishDate}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.BLOOD_GROUP}</label>
            <TxtBox
              id={"bloodGroup"}
              placeholder={LABELS.BLOOD_GROUP}
              defaultValue={selectedRow.bloodGroup}
              type={"text"}
            />
          </div>
          <div className="formGroup">
            <SwitchBtn
              text={LABELS.ACTIVE}
              id={"isActive"}
              checked={selectedRow.id ? selectedRow.activeStatus : true}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageRecord} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}

      {cvDialog && (
        <UiDialog
          title={
            selectedCvRow
              ? selectedCvRow.id
                ? "Edit CV: " + selectedCvRow.empName
                : "Add New CV"
              : "Add New CV"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setCvDialog(false);
          }}
        >
          {!selectedCvRow ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 400,
              }}
            >
              <Loader />
            </div>
          ) : (
            <React.Fragment>
              <div className="formGroup">
                <label>{LABELS.PROFESSION}</label>
                <ReactQuill
                  theme="snow"
                  value={profession}
                  onChange={setProfession}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"profession"}
                  placeholder={LABELS.PROFESSION}
                  defaultValue={selectedCvRow.profession}
                /> */}
              </div>
              <div className="formGroup">
                <label>{LABELS.QUALIFICATION}</label>
                <ReactQuill
                  theme="snow"
                  value={qualification}
                  onChange={setQualification}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"qualification"}
                  placeholder={LABELS.QUALIFICATION}
                  defaultValue={selectedCvRow.qualification}
                /> */}
              </div>
              <div className="formGroup">
                <label>{LABELS.COURSE}</label>
                <ReactQuill
                  theme="snow"
                  value={course}
                  onChange={setCourse}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"course"}
                  placeholder={LABELS.COURSE}
                  defaultValue={selectedCvRow.course}
                /> */}
              </div>
              <div className="formGroup">
                <label>{LABELS.KEY_SKILLS}</label>
                <ReactQuill
                  theme="snow"
                  value={keySkills}
                  onChange={setKeySkills}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"keyskill"}
                  placeholder={LABELS.KEY_SKILLS}
                  defaultValue={selectedCvRow.skill}
                /> */}
              </div>
              <div className="formGroup">
                <label>{LABELS.COUNTRIES_WORKED_IN}</label>
                <ReactQuill
                  theme="snow"
                  value={countries}
                  onChange={setCountries}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"countriesworkedin"}
                  placeholder={LABELS.COUNTRIES_WORKED_IN}
                  defaultValue={selectedCvRow.workedCountries}
                /> */}
              </div>
              <div className="formGroup">
                <label>{LABELS.KEY_EXPERIENCE}</label>
                <ReactQuill
                  theme="snow"
                  value={experience}
                  onChange={setExperience}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"keyexperience"}
                  placeholder={LABELS.KEY_EXPERIENCE}
                  defaultValue={selectedCvRow.experience}
                /> */}
              </div>
              <div className="formGroup">
                <label>{LABELS.RECENT_PROF_HISTORY}</label>
                <ReactQuill
                  theme="snow"
                  value={recentProfHistory}
                  onChange={setRecentProfHistory}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"recentprofhistory"}
                  placeholder={LABELS.RECENT_PROF_HISTORY}
                  defaultValue={selectedCvRow.history}
                /> */}
              </div>
              <div className="formGroup">
                <label>{LABELS.SELECTED_PROF_HISTORY}</label>
                <ReactQuill
                  theme="snow"
                  value={selectedProfHistory}
                  onChange={setSelectedProfHistory}
                  style={{
                    marginTop: 2,
                  }}
                />
                {/* <TxtBox
                  id={"selectedprofhistory"}
                  placeholder={LABELS.SELECTED_PROF_HISTORY}
                  defaultValue={selectedCvRow.selectedHistory}
                /> */}
              </div>
              <div
                className="formGroup"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Btn text={LABELS.SAVE} onClick={saveCV} />
                  <Btn
                    text={LABELS.CANCEL}
                    bgColor={COLORS.DIM_DARK_GRAY}
                    onClick={() => setCvDialog(false)}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Employees);
