import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import { NOTIFY } from "common/config";
import { LABELS, TITLES, WARNINGS, SUB_TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { MASTER_APIs } from "apihandlers/master";
import { REPORTS_APIs } from "apihandlers/reports";

const RetailPOSSalesReport = (props: any) => {
  const { setDashMenu } = props;

  const outletRef: any = useRef();

  const [loading, setLoading] = useState(true);
  const [masters, setMasters] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "6%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "billDate",
      name: LABELS.BILL_DATE,
      width: "12%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "billNo",
      name: LABELS.BILL_NO,
      width: "12%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "hsnCode",
      name: LABELS.HSN_CODE,
      width: "12%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "materialCode",
      name: LABELS.MATERIAL_CODE,
      width: "12%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "productName",
      name: LABELS.PRODUCT_NAME,
      width: "12%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "quantity",
      name: LABELS.QUANTITY,
      width: "12%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "gstSlab",
      name: LABELS.GST_SLAB,
      width: "12%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "basicRate",
      name: LABELS.BASIC_RATE,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "amount",
      name: LABELS.AMOUNT,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "sgstAmount",
      name: LABELS.SGST_AMOUNT,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "cgstAmount",
      name: LABELS.CGST_AMOUNT,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "roundOff",
      name: LABELS.ROUND_OFF,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "netAmount",
      name: LABELS.NET_AMOUNT,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "billAmount",
      name: LABELS.BILL_AMOUNT,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "billTaxTotal",
      name: LABELS.BILL_TAX_TOTAL,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "groupName",
      name: LABELS.GROUP_NAME,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
  ];

  useEffect(() => {
    if (loading) {
      getOrgProfile();
    }
  }, [loading]);

  const getOrgProfile = () => {
    const args = {};
    MASTER_APIs.getPosCenters(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list: any = [];
        let recs: any = response.records;
        for (let i = 0; i < recs.length; i++) {
          list.push({
            id: recs[i].idcenters,
            label: recs[i].name,
          });
        }
        setMasters(list);
        setLoading(false);
      }
    });
  };

  const loadChart = () => {
    const outlet = outletRef.current.selectedValue.id;
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const toDt = (document.getElementById("toDt") as HTMLInputElement).value;
    const args = {
      fromDt,
      toDt,
      outlet,
      reportType: "excel",
    };
    let errors = [];
    if (!outlet) {
      errors.push({
        id: "outlet",
        msg:
          "<span class='bold'>" +
          LABELS.OUTLET +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (fromDt === "") {
      errors.push({
        id: "fromDt",
        msg:
          "<span class='bold'>" +
          LABELS.FROM_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (toDt === "") {
      errors.push({
        id: "toDt",
        msg:
          "<span class='bold'>" +
          LABELS.TO_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }
    setRows(null);
    REPORTS_APIs.getPosRetailSale(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        for (let i = 0; i < response.rows.length; i++) {
          response.rows[i]["slNo"] = i + 1;
        }
        setRows(response.rows);
        setLoading(false);
      }
    });
  };

  console.log("Page: RetailPOSSalesReport:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.POS_REPORT}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.RETAIL_POS_SALE_REPORT}</li>
        </ul>
        <h4>{TITLES.RETAIL_POS_SALE_REPORT}</h4>
        <p>{SUB_TITLES.RETAIL_POS_SALE_REPORT}</p>
      </div>
      <div className="hChart">
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
              marginBottom: 10,
              width: "40%",
            }}
          >
            <label>{LABELS.SELECT_OUTLET + " :"}</label>
            <AutoComplete
              id={"masters"}
              list={masters}
              childRef={outletRef}
              // defaultValue={}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 0,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.FROM_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="fromDt"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.TO_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="toDt"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <Btn text={LABELS.LOAD} onClick={loadChart} />
            </div>
          </div>
        </div>
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="grid excelGridWrapper">
          {loading ? (
            <div className="flexRowCenter" style={{ minHeight: 450 }}>
              <Loader />
            </div>
          ) : (
            rows && (
              <ExcelGrid
                id={LABELS.RETAIL_POS_SALE_REPORT}
                height={window.innerHeight * 0.8}
                cols={columns}
                rows={rows}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.RETAIL_POS_SALE_REPORT}
                hideUnhide={true}
              />
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(RetailPOSSalesReport);
