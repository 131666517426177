import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit, Close as IconClose } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import TxtBox from "components/Custom/TxtBox";
import SkeletonBox from "components/Custom/SkeletonBox";

import { API_CONFIG, CONFIG, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { PRODUCT_APIs } from "apihandlers/product";
import { AUTH_APIs } from "apihandlers/auth";
import { MASTER_APIs } from "apihandlers/master";

const thumbnailImg = require("assets/images/pdf.png");

const Product = (props: any) => {
  const { setDashMenu, privileges } = props;

  const mainCategRef: any = useRef(null);
  const subCategRef: any = useRef(null);
  const categoryRef: any = useRef(null);
  const gramsRef: any = useRef(null);
  const taxRef: any = useRef(null);
  const branchRef: any = useRef(null);
  const productNewCategoriesRef: any = useRef(null);
  const insertAboveRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [mainCategs, setMainCategs] = useState<any>(null);
  const [subCategs, setSubCategs] = useState<any>(null);
  const [categories, setCategories] = useState<any>(null);
  const [taxes, setTaxes] = useState<any>(null);
  const [grams, setGrams] = useState<any>(null);
  const [branches, setBranches] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [productNewCategories, setProductNewCategories] = useState<any>({});
  const [insertAbove, setInsertAbove] = useState<any>({});
  const [files, setFiles] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getProductTypeCategory();
    }
  }, [loading]);

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setFiles(null);
      uploadFile(file, base64).then((res) => {
        setFiles([res]);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "productcategory",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
      }
    } catch (error) {}
    return fileInfo;
  };

  const getProductTypeCategory = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getProductTypeCategory(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setCategories(list);
        getProductSubCategory();
      }
    });
  };

  const getProductSubCategory = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getProductSubCategory(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setSubCategs(list);
        getProductMainCategory();
      }
    });
  };
  const getProductMainCategory = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getProductMainCategory(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setMainCategs(list);
        getProductNewCategories();
        getGramsMaster();
      }
    });
  };

  const getProductNewCategories = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getProductNewCategories(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setProductNewCategories(list);
        getGramsMaster();
      }
    });
  };

  const getGramsMaster = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getGramsMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setGrams(list);
        getTaxMaster();
      }
    });
  };

  const getTaxMaster = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getTaxMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.taxName,
          };
        });
        setTaxes(list);
        getBranch();
      }
    });
  };

  const getBranch = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    MASTER_APIs.getBranchProfile(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setBranches(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await PRODUCT_APIs.getProducts(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          list[i].imagePath = list[i].image;
          let fileUrl = API_CONFIG.STATIC_URL + list[i].image;
          list[i].image = (
            <div>
              <img
                src={fileUrl}
                alt=""
                width={"auto"}
                height={50}
                onClick={() => {
                  window.open(fileUrl, "_blank");
                }}
              />
            </div>
          );
        }
        setRows(list);
        setLoading(false);
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setInsertAbove(list);
      }
    } catch (error) {
      console.error("getProducts:", error);
    }
  };

  const onEditRow = (row: any) => {
    let insertAboveName = "";
    if (row.insertAbove) {
      const selectedInsertAbove = insertAbove.find((i) => {
        return i.id === row.insertAbove;
      });
      if (selectedInsertAbove) {
        insertAboveName = selectedInsertAbove.label;
      }
    }
    row.insertAboveName = insertAboveName;
    setSelectedRow(row);
    setManageDialog(true);
    setFiles([
      {
        id: row.imageId,
        path: row.imagePath,
      },
    ]);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "productName",
      name: LABELS.PRODUCT_NAME,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "aliasName",
      name: LABELS.ALIAS_NAME,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "typeCategory",
      name: LABELS.CATEGORY,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "mainCategory",
      name: LABELS.MAIN_CATEGORY,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "subCategory",
      name: LABELS.SUB_CATEGORY,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "newCategory",
      name: LABELS.NEW_CATEGORY,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "defaultBranch",
      name: LABELS.BRANCH,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "hsnCode",
      name: LABELS.HSN_CODE,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "gramsQty",
      name: LABELS.GRAMS_QTY,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "grams",
      name: LABELS.GRAMS,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "taxStructure",
      name: LABELS.TAX,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "minPrice",
      name: LABELS.MIN_PRICE,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "mrp",
      name: LABELS.MRP,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "price",
      name: LABELS.PRICE,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "image",
      name: LABELS.FILE_UPLOAD,
      type: "html",
      width: "10%",
      editable: false,
    },
    {
      key: "mime_type",
      name: LABELS.MIME_TYPE,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "insertAbove",
      name: LABELS.INSERT_ABOVE,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "number",
      searchable: "true",
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRegion = () => {
    const productName = (
      document.getElementById("productName") as HTMLInputElement
    ).value.trim();
    const alias = (
      document.getElementById("aliasName") as HTMLInputElement
    ).value.trim();
    const branchId = branchRef.current.selectedValue.id;
    const mainCategoryId = mainCategRef.current.selectedValue.id;
    const subCategoryId = subCategRef.current.selectedValue.id;
    const typeCategoryId = categoryRef.current.selectedValue.id;
    const gramsId = gramsRef.current.selectedValue.id;
    const taxId = taxRef.current.selectedValue.id;
    const price = (
      document.getElementById("price") as HTMLInputElement
    ).value.trim();
    const mrp = (
      document.getElementById("mrp") as HTMLInputElement
    ).value.trim();
    const minPrice = (
      document.getElementById("minPrice") as HTMLInputElement
    ).value.trim();
    const sortingOrder = (
      document.getElementById("sortingOrder") as HTMLInputElement
    ).value.trim();
    const hsnCode = (
      document.getElementById("hsnCode") as HTMLInputElement
    ).value.trim();
    const insertAbove = insertAboveRef.current.selectedValue.id;
    const newCategoryId = productNewCategoriesRef.current.selectedValue.id;
    let active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";
    // Validations
    let errors = [];
    if (productName === "") {
      errors.push({
        id: "productName",
        msg:
          "<span class='bold'>" +
          LABELS.PRODUCT_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (alias === "") {
      errors.push({
        id: "alias",
        msg:
          "<span class='bold'>" +
          LABELS.ALIAS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!branchId) {
      errors.push({
        id: "branch",
        msg:
          "<span class='bold'>" +
          LABELS.BRANCH +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!mainCategoryId) {
      errors.push({
        id: "maincateg",
        msg:
          "<span class='bold'>" +
          LABELS.MAIN_CATEGORY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!subCategoryId) {
      errors.push({
        id: "maincateg",
        msg:
          "<span class='bold'>" +
          LABELS.SUB_CATEGORY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!typeCategoryId) {
      errors.push({
        id: "maincateg",
        msg:
          "<span class='bold'>" +
          LABELS.CATEGORY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!gramsId) {
      errors.push({
        id: "maincateg",
        msg:
          "<span class='bold'>" +
          LABELS.GRAMS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!taxId) {
      errors.push({
        id: "maincateg",
        msg:
          "<span class='bold'>" +
          LABELS.TAX +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (price === "") {
      errors.push({
        id: "maincateg",
        msg:
          "<span class='bold'>" +
          LABELS.PRICE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (mrp === "") {
      errors.push({
        id: "maincateg",
        msg:
          "<span class='bold'>" +
          LABELS.MRP +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (files.length < 1) {
      errors.push({
        id: "files",
        msg:
          "<span class='bold'>" +
          LABELS.FILE_UPLOAD +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      productName: productName,
      aliasName: alias,
      branchId,
      gramsId: gramsId,
      mainCategoryId: mainCategoryId,
      subCategoryId: subCategoryId,
      typeCategoryId: typeCategoryId,
      taxId: taxId,
      price: price,
      mrp: mrp,
      minPrice: minPrice,
      sortingOrder: sortingOrder,
      hsnCode: hsnCode,
      activeStatus: selectBtnValue.toString(),
      imageId: files.length > 0 ? files[0].id : "",
      insertAbove,
      newCategoryId: newCategoryId,
    };
    console.log("args", args);
    setIsSubmit(true);
    PRODUCT_APIs.manageProduct(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
    setMandatoryResults([]);
    setFiles([]);
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.PRODUCT}</li>
        </ul>
        <h4>{TITLES.PRODUCT}</h4>
        <p>{SUB_TITLES.PRODUCT}</p>
      </div>
      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.PRODUCT}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.PRODUCT + ": " + selectedRow.productName
              : "Add New " + LABELS.PRODUCT
          }
          open={true}
          size={"lg"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
            setFiles([]);
          }}
        >
          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <TxtRow
                id={"productName"}
                type={"text"}
                label={LABELS.PRODUCT_NAME}
                required={true}
                defaultValue={selectedRow.productName}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <TxtRow
                id={"aliasName"}
                type={"text"}
                label={LABELS.ALIAS}
                required={true}
                defaultValue={selectedRow.aliasName}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <label>
                {LABELS.BRANCH}
                {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={branches}
                childRef={branchRef}
                defaultValue={
                  selectedRow.defaultBranch
                    ? {
                        id: selectedRow.branchId,
                        label: selectedRow.defaultBranch,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup  width30 red">
              <label>
                {LABELS.MAIN_CATEGORY} {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={mainCategs}
                childRef={mainCategRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.mainCategoryId,
                        label: selectedRow.mainCategory,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <label>
                {LABELS.SUB_CATEGORY} {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={subCategs}
                childRef={subCategRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.subCategoryId,
                        label: selectedRow.subCategory,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <label>
                {LABELS.CATEGORY} {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={categories}
                childRef={categoryRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.typeCategoryId,
                        label: selectedRow.typeCategory,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.NEW_CATEGORY}</label>
              <AutoComplete
                list={productNewCategories}
                childRef={productNewCategoriesRef}
                defaultValue={
                  selectedRow.newCategoryId
                    ? {
                        id: selectedRow.newCategoryId,
                        label: selectedRow.newCategory,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <label>
                {LABELS.GRAMS} {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={grams}
                childRef={gramsRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.gramId,
                        label: selectedRow.grams,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <label>
                {LABELS.TAX} {<span className="red">*</span>}
              </label>
              <AutoComplete
                list={taxes}
                childRef={taxRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.taxId,
                        label: selectedRow.taxStructure,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <TxtRow
                id={"mrp"}
                type={"number"}
                label={LABELS.MRP}
                required={true}
                defaultValue={selectedRow.mrp}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <TxtRow
                id={"price"}
                type={"number"}
                label={LABELS.PRICE}
                required={true}
                defaultValue={selectedRow.price}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 ">
              <TxtRow
                id={"minPrice"}
                type={"number"}
                label={LABELS.MIN_PRICE}
                defaultValue={selectedRow.minPrice}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30 ">
              <TxtRow
                id={"hsnCode"}
                type={"text"}
                label={LABELS.HSN_CODE}
                defaultValue={selectedRow.hsnCode}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"sortingOrder"}
                type={"number"}
                label={LABELS.SORTING_ORDER}
                defaultValue={selectedRow.sortingOrder}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.INSERT_ABOVE}</label>
              <AutoComplete
                list={insertAbove}
                childRef={insertAboveRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.insertAbove,
                        label: selectedRow.insertAboveName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup red width30">
              <label>
                {LABELS.FILE_UPLOAD}
                <small>
                  {" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}
                </small>
              </label>
              <TxtBox
                type={"file"}
                id="uploadFile"
                accept="image/*,.pdf"
                onChange={onUploadFile}
              />
              <div>
                {!files ? (
                  <div className="thumbnailBox width20">
                    <SkeletonBox height={300} />
                  </div>
                ) : (
                  files.map((u, i) => {
                    return (
                      u.path && (
                        <div className="thumbnailBox width20">
                          <IconButton
                            className="thumbnailBoxIcon"
                            onClick={() => {
                              const list = files.filter((a, j) => j !== i);
                              setFiles(list);
                            }}
                          >
                            <IconClose />
                          </IconButton>
                          <img
                            onClick={() =>
                              window.open(
                                API_CONFIG.STATIC_URL + u.path,
                                "_blank"
                              )
                            }
                            src={
                              u.mimeType === "application/pdf"
                                ? thumbnailImg
                                : API_CONFIG.STATIC_URL + u.path
                            }
                            className="responsive cursor"
                          />
                        </div>
                      )
                    );
                  })
                )}
              </div>
            </div>
            <div className="formGroup width30 required">
              <label>
                {LABELS.ACTIVE_STATUS} <span className="red">*</span>
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === false
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
          </div>

          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className=" flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageRegion}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Product);
