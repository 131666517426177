import React from "react";

import HGraph from "components/Custom/HGraph";

import { GRAPH_TYPES } from "common/config";

const ColumnGraph = (props: any) => {
  const { height } = props;
  return (
    <React.Fragment>
      <div className="graphs">
        <HGraph
          width={"100%"}
          height={height}
          type={GRAPH_TYPES.COLUMN}
          float={"right"}
          xAxis={[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]}
          yAxis={[20, 25, 32, 40, 65, 68, 55, 43, 0, 0, 0, 0]}
          title={" "}
          exportTitle={" Column Graph Record "}
          xAxisTitle=""
          yAxisTitle="Leads"
        />
      </div>
    </React.Fragment>
  );
};

export default ColumnGraph;
