import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "../../Custom/UiDialog";

import { LABELS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";

import { TRANS_APIs } from "apihandlers/transaction";

const PurchaseBill = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "expanded",
    },
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "billNO",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_NO,
      width: "10%",
    },
    {
      key: "approveDate",
      type: "datetime",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.APPROVED_DATE,
      width: "10%",
    },
    {
      key: "billDate",
      type: "date",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_DATE,
      width: "10%",
    },
    {
      key: "items",
      type: "dropdown",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.ITEMS,
      width: "15%",
      list: [
        {
          id: "1",
          label: "Masala Bites 100 grams",
        },
        {
          id: "2",
          label: "Masala Bites 200 grams",
        },
        {
          id: "3",
          label: "Masala Bites 1500 grams",
        },
        {
          id: "4",
          label: "Masala Bites 2000 grams",
        },
      ],
      filters: [
        {
          id: "1",
          label: "Masala Bites 100 grams",
        },
        {
          id: "2",
          label: "Masala Bites 200 grams",
        },
        {
          id: "3",
          label: "Masala Bites 1500 grams",
        },
        {
          id: "4",
          label: "Masala Bites 2000 grams",
        },
      ],
    },
    {
      key: "itemAmount",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ITEM_AMOUNT,
      width: "15%",
      renderSum: true,
    },
    {
      key: "billAmount",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_AMOUNT,
      width: "15%",
      renderSum: true,
    },
    {
      key: "billStatus",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_STATUS,
      width: "10%",
    },
    {
      key: "billReturn",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_RETURN,
      width: "10%",
    },
  ];

  const formulaRenderer = (row) => {
    console.log("Formula row ", row);
    let qt = Number(row["qty"] || 0);
    let price = Number(row["price"] || 0);
    let amount = Number(row["amount"] || 0);
    if (!isNaN(qt) && !isNaN(price)) {
      amount = price * qt;
    }
    row["amount"] = amount;
    return row;
  };
  const columns2 = [
    {
      key: "",
      selectColumn: "true",
    },
    {
      key: "slNo",
      type: "text",
      editable: false,
      sortable: false,
      searchable: false,
      name: LABELS.SL_NO,
      width: "10%",
    },
    {
      key: "productName",
      type: "text",
      editable: false,
      sortable: false,
      searchable: false,
      name: LABELS.PRODUCT_NAME,
      width: "35%",
    },
    {
      key: "qty",
      type: "number",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.QTY,
      width: "20%",
      isDisabled: (row: any) => {
        return row.amount <= 50;
      },
      formula: formulaRenderer,
    },
    {
      key: "price",
      type: "number",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.RATE,
      formula: formulaRenderer,

      width: "25%",
    },
    {
      key: "amount",
      type: "number",
      // formula: "qty * price",
      editable: false,
      sortable: false,
      searchable: false,
      name: LABELS.AMOUNT,
      formula: formulaRenderer,

      width: "20%",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const fromDate = (
        document.getElementById("fromDate") as HTMLInputElement
      ).value.trim();
      const toDate = (
        document.getElementById("toDate") as HTMLInputElement
      ).value.trim();
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        fromDate,
        toDate,
      };
      setRows(null);
      const res: any = await TRANS_APIs.getPurchaseBill(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          list[i]["_type"] = "maingrid";
          list[i]["_expanded"] = false;
          if (list[i].billStatus === "Yes") {
            list[i]["_className"] = "bgHighlight";
          }
          let subList = list[i].purchase;
          for (let j = 0; j < subList.length; j++) {
            subList[j]["slNo"] = j + 1;
          }
          list[i]["_subGrid"] = () => subGrid(subList);
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getOrders:", error);
    }
  };

  const subGrid = (subRows = []) => {
    return (
      <>
        <ExcelGrid
          id={"purchaseBillSubGrid"}
          cols={columns2}
          rows={subRows}
          isSubGrid={true}
          noTotalRows={true}
        />
      </>
    );
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };
  const importResponse = (e) => {
    setManageDialog(true);
  };
  const printEInvoice = (e) => {
    console.log(e);
  };

  return (
    <React.Fragment>
      {
        <div className="pageInfo">
          <ul className="breadcrumbs">
            <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
            <li className="dim">{" / "}</li>
            <li onClick={() => props.setDashMenu(LABELS.HOME)}>
              {LABELS.PURCHASE}
            </li>
            <li className="dim">{" / "}</li>
            <li className="active">{TITLES.PURCHASE_BILL}</li>
          </ul>
          <h4>{TITLES.PURCHASE_BILL}</h4>
        </div>
      }
      <div className="width50 filterBorder">
        <UiAccordion id={"filter"} title={LABELS.FILTER}>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.FROM_DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.TO_DATE}
                type={"date"}
                id="toDate"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <div style={{ marginTop: "1.5rem" }}>
                <Btn
                  text={LABELS.LOAD}
                  onClick={getRows}
                  bgColor={COLORS.GRID_ADD_NEW}
                  // loading={isSubmit}
                />
              </div>
            </div>
          </div>
        </UiAccordion>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={LABELS.PURCHASE_BILL}
            height={window.innerHeight * 0.55}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.PURCHASE_BILL}
            onReload={reloadGrid}
            hideUnhide={true}
            freezeOption={true}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(PurchaseBill);
