import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { FORMATS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { GEO_APIs } from "apihandlers/geography";
import { PRODUCT_APIs } from "apihandlers/product";
import { DateFunctions } from "common/datefunctions";
import { CUSTOMER_APIs } from "apihandlers/customer";
import RadioBtn from "components/Custom/RadioBtn";

const Price = (props: any) => {
  const { setDashMenu, privileges } = props;

  const CustomerRef: any = useRef(null);
  const stateRef: any = useRef(null);
  const districtRef: any = useRef(null);
  const regionRef: any = useRef(null);
  const cityRef: any = useRef(null);
  const boxRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [states, setStates] = useState<any>(null);
  const [districts, setDistricts] = useState<any>(null);
  const [region, setRegion] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [box, setBox] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "skuName",
      name: LABELS.BOX,
      width: "20%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "stateName",
      name: LABELS.STATE,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "districtName",
      name: LABELS.DISTRICT,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "regionName",
      name: LABELS.REGION,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "cityName",
      name: LABELS.CITY,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "orgName",
      name: LABELS.ORGANIZATION,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "fromDt",
      name: LABELS.FROM_DATE,
      width: "10%",
      type: "date",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "toDt",
      name: LABELS.TO_DATE,
      width: "10%",
      type: "date",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "piecePrice",
      name: LABELS.PIECE_PRICE,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "stripPrice",
      name: LABELS.STRIP_PRICE,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "boxPrice",
      name: LABELS.BOX_PRICE,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "mustSell",
      name: LABELS.MUST_SELL,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      searchable: true,
      type: "dropdown",
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getStates();
    }
  }, [loading]);

  const getStates = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    GEO_APIs.getStates(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.stateName,
          };
        });
        setStates(list);
        getCustomer();
      }
    });
  };

  const getCustomer = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    CUSTOMER_APIs.getCustomerProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setCustomer(list);
        getProductsSku();
      }
    });
  };

  const getDistricts = async (stateId) => {
    let districts = [];
    try {
      const args = {
        stateId,
        activeStatus: true,
        minResponse: true,
      };
      const res: any = await GEO_APIs.getDistricts(args);
      districts = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.districtName,
        };
      });
    } catch (error) {}
    return districts;
  };

  const getRegions = async (districtId) => {
    let regions = [];
    try {
      const args = {
        districtId,
        activeStatus: true,
        minResponse: true,
      };
      const res: any = await GEO_APIs.getRegions(args);
      regions = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.regionName,
        };
      });
    } catch (error) {}
    return regions;
  };

  const getCity = async (regionId) => {
    let cities = [];
    try {
      const args = {
        regionId,
        activeStatus: true,
        minResponse: true,
      };
      const res: any = await GEO_APIs.getCities(args);
      cities = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.cityName,
        };
      });
    } catch (error) {}
    return cities;
  };

  const onStateChange = async (e, f) => {
    if (f.id) {
      const res = await getDistricts(f.id);
      setDistricts(res);
    }
  };

  const onDistrictChange = async (e, f) => {
    if (f.id) {
      const res = await getRegions(f.id);
      setRegion(res);
    } else {
      setRegion([]);
    }
  };
  const onRegionChange = async (e, f) => {
    if (f.id) {
      const res = await getCity(f.id);
      setCity(res);
    }
  };

  const getProductsSku = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    PRODUCT_APIs.getProductsSku(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.mainProduct,
          };
        });
        setBox(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await PRODUCT_APIs.getPrices(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
          if (list[i]["mustSell"]) {
            list[i]["mustSell"] = "Yes";
          } else {
            list[i]["mustSell"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getPrices:", error);
    }
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const managePrice = () => {
    const customerId = CustomerRef.current.selectedValue.id;
    const skuId = boxRef.current.selectedValue.id;
    const stateId = stateRef.current.selectedValue.id;
    const districtId = districtRef.current.selectedValue.id;
    const regionId = regionRef.current.selectedValue.id;
    const cityId = cityRef.current.selectedValue.id;
    const fromDt = (
      document.getElementById("from") as HTMLInputElement
    ).value.trim();
    const toDt = (
      document.getElementById("to") as HTMLInputElement
    ).value.trim();
    const piecePrice = (
      document.getElementById("piecePrice") as HTMLInputElement
    ).value.trim();
    const boxPrice = (
      document.getElementById("boxPrice") as HTMLInputElement
    ).value.trim();
    const stripPrice = (
      document.getElementById("stripPrice") as HTMLInputElement
    ).value.trim();
    let ele = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = ele ? ele.value : "";

    let op = document.querySelector(
      'input[name="mustSell"]:checked'
    ) as HTMLInputElement;
    const mustSell = ele ? ele.value : "";

    // Validations
    let errors = [];
    // if (!customerId) {
    //   errors.push({
    //     id: "customerID",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.CUSTOMER +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (!stateId) {
    //   errors.push({
    //     id: "stateId",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.STATE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (!districtId) {
    //   errors.push({
    //     id: "districtId",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.DISTRICT +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (!regionId) {
    //   errors.push({
    //     id: "regionId",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.REGION +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (!cityId) {
    //   errors.push({
    //     id: "cityId",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.CITY +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    if (!skuId) {
      errors.push({
        id: "skuId",
        msg:
          "<span class='bold'>" +
          LABELS.BOX +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (fromDt === "") {
      errors.push({
        id: "fromDt",
        msg:
          "<span class='bold'>" +
          LABELS.FROM +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (toDt === "") {
      errors.push({
        id: "toDt",
        msg:
          "<span class='bold'>" +
          LABELS.TO +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!piecePrice) {
      errors.push({
        id: "piecePrice",
        msg:
          "<span class='bold'>" +
          LABELS.PIECE_PRICE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!stripPrice) {
      errors.push({
        id: "stripPrice",
        msg:
          "<span class='bold'>" +
          LABELS.STRIP_PRICE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!boxPrice) {
      errors.push({
        id: "boxPrice",
        msg:
          "<span class='bold'>" +
          LABELS.BOX_PRICE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      customerId,
      skuId,
      stateId,
      districtId,
      regionId,
      cityId,
      fromDt,
      toDt,
      piecePrice,
      boxPrice,
      stripPrice,
      mustSell,
      activeStatus,
    };
    console.log("args", args);
    setIsSubmit(true);
    PRODUCT_APIs.managePrice(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.PRICE}</li>
        </ul>
        <h4>{TITLES.PRICE}</h4>
        <p>{SUB_TITLES.PRICE}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"price"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.PRICE}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.PRICE + ": " + selectedRow.skuName
              : "Add New " + LABELS.PRICE
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup ">
            <label>{LABELS.CUSTOMER}</label>
            <AutoComplete
              list={customer}
              childRef={CustomerRef}
              defaultValue={
                selectedRow.customerId
                  ? {
                      id: selectedRow.customerId,
                      label: selectedRow.customerName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup ">
            <label>{LABELS.STATE}</label>
            <AutoComplete
              list={states}
              childRef={stateRef}
              defaultValue={
                selectedRow.stateId
                  ? {
                      id: selectedRow.stateId,
                      label: selectedRow.stateName,
                    }
                  : null
              }
              onChange={onStateChange}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>{LABELS.DISTRICT}</label>
            <AutoComplete
              list={districts}
              childRef={districtRef}
              defaultValue={
                selectedRow.districtId
                  ? {
                      id: selectedRow.districtId,
                      label: selectedRow.districtName,
                    }
                  : null
              }
              onChange={onDistrictChange}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>{LABELS.REGION}</label>
            <AutoComplete
              list={region}
              childRef={regionRef}
              defaultValue={
                selectedRow.regionId
                  ? {
                      id: selectedRow.regionId,
                      label: selectedRow.regionName,
                    }
                  : null
              }
              onChange={onRegionChange}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>{LABELS.CITY}</label>
            <AutoComplete
              list={city}
              childRef={cityRef}
              defaultValue={
                selectedRow.cityId
                  ? {
                      id: selectedRow.cityId,
                      label: selectedRow.cityName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <label>
              {LABELS.BOX}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={box}
              childRef={boxRef}
              defaultValue={
                selectedRow.skuId
                  ? {
                      id: selectedRow.skuId,
                      label: selectedRow.skuName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup required">
            <TxtRow
              id={"from"}
              type={"date"}
              label={LABELS.FROM_DATE}
              defaultValue={
                selectedRow.ldDate
                  ? DateFunctions.getFormatedDate(
                      selectedRow.ldDate,
                      FORMATS.SQL_DATE,
                      FORMATS.SQL_DATE_ONLY
                    )
                  : DateFunctions.getDate(
                      "today",
                      "from",
                      FORMATS.SQL_DATE_ONLY
                    )
              }
              disabled={isSubmit}
              required={true}
            />
          </div>

          <div className="formGroup required">
            <TxtRow
              id={"to"}
              type={"date"}
              label={LABELS.TO_DATE}
              defaultValue={
                selectedRow.ldDate
                  ? DateFunctions.getFormatedDate(
                      selectedRow.ldDate,
                      FORMATS.SQL_DATE,
                      FORMATS.SQL_DATE_ONLY
                    )
                  : DateFunctions.getDate(
                      "today",
                      "from",
                      FORMATS.SQL_DATE_ONLY
                    )
              }
              disabled={isSubmit}
              required={true}
            />
          </div>

          <div className="formGroup red">
            <TxtRow
              id={"piecePrice"}
              type={"number"}
              label={LABELS.PIECE_PRICE}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.piecePrice).toString()
                  : selectedRow.piecePrice
              }
              disabled={isSubmit}
              required={true}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"stripPrice"}
              type={"number"}
              label={LABELS.STRIP_PRICE}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.stripPrice).toString()
                  : selectedRow.stripPrice
              }
              disabled={isSubmit}
              required={true}
            />
          </div>

          <div className="formGroup red">
            <TxtRow
              id={"boxPrice"}
              type={"number"}
              label={LABELS.BOX_PRICE}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.boxPrice).toString()
                  : selectedRow.boxPrice
              }
              disabled={isSubmit}
              required={true}
            />
          </div>
          <div className="formGroup required">
            <label>{LABELS.MUST_SELL}</label>
            <span className="red">*</span>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.YES,
                  checked: selectedRow.id
                    ? selectedRow.mustSell === "Yes"
                    : true,
                  disabled: isSubmit,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.NO,
                  checked: selectedRow.id
                    ? selectedRow.mustSell === "No"
                    : false,
                  disabled: isSubmit,
                },
              ]}
              row={true}
              name={"mustSell"}
              disabled={isSubmit}
              required={true}
            />
          </div>
          <div className="formGroup required">
            <label>
              {LABELS.ACTIVE_STATUS}
              <span className="red">*</span>
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === "Yes"
                    : true,
                  disabled: isSubmit,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === "No"
                    : false,
                  disabled: isSubmit,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
              required={true}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className=" flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={managePrice}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Price);
