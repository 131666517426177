import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "../../Custom/ExcelGrid";

import { FORMATS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";

const EXCEL_GRID_ID = "Customer";

const Customer = (props: any) => {
  const { setDashMenu } = props;

  const customerTypeRef: any = useRef(null);

  const routeRef: any = useRef(null);
  const insertBelowRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [customerType, setCustomerType] = useState<any>([]);
  const [route, setRoute] = useState<any>([]);
  const [insertBelow, setInsertBelow] = useState<any>([]);

  useEffect(() => {
    if (loading) {
      getCustomerTypes();
    }
  }, [loading]);

  const getCustomerTypes = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };

    CUSTOMER_APIs.getCustomerTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setCustomerType(list);
        getAreas();
      }
    });
  };

  const getAreas = () => {
    const args = {};
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setRoute(list);

        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await CUSTOMER_APIs.getCustomerProfiles(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);

        getCustomerProfiles();
      }
    } catch (error) {
      console.error("getRegions:", error);
    }
  };

  const getCustomerProfiles = () => {
    const args = {};
    CUSTOMER_APIs.getCustomerProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setInsertBelow(list);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };
  const reloadGrid = () => {
    setRows(null);
    getRows();
  };
  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "name",
      name: LABELS.NAME,
      width: "15%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "mobileNumber",
      name: LABELS.MOBILE_NUMBER,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "incharge",
      name: LABELS.CONTACT_PERSON,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "address1",
      name: LABELS.ADDRESS_1,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "address2",
      name: LABELS.ADDRESS_2,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "amountDeposited",
      name: LABELS.AMOUNT_DEPOSITED,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "approvedDate",
      name: LABELS.APPROVED_DATE,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "approvedUser",
      name: LABELS.APPROVED_USER,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "areaName",
      name: LABELS.AREA,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "businessType",
      name: LABELS.BUSINESS_TYPE,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "createdDate",
      name: LABELS.CREATED_ON,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "createdUser",
      name: LABELS.CREATED_USER,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "openedOn",
      name: LABELS.OPENED_ON,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "closedOn",
      name: LABELS.CLOSED_ON,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },

    {
      key: "creditDays",
      name: LABELS.CREDIT_DAYS,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "creditLimit",
      name: LABELS.CREDIT_LIMIT,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "erpCode",
      name: LABELS.ERP_CODE,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "firstOrderOn",
      name: LABELS.FIRST_ORDER_ON,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "googleUrl",
      name: LABELS.URL,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "gstin",
      name: LABELS.GST_IN,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "incharge",
      name: LABELS.INCHARGE,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "insertAbove",
      name: LABELS.INSERT_ABOVE,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "insertAboveCustomer",
      name: LABELS.INSERT_ABOVE_CUSTOMER,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "lastOrderOn",
      name: LABELS.LAST_ORDERED_ON,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "latitude",
      name: LABELS.LATITUDE,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "longitude",
      name: LABELS.LONGITUDE,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "landline",
      name: LABELS.LAND_LINE,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "pan",
      name: LABELS.PAN_NUMBER,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "pincode",
      name: LABELS.PIN_CODE,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "remarksBusiness",
      name: LABELS.REMARKS,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "salesType",
      name: LABELS.SALES_TYPE,
      width: "7%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "signaturePath",
      name: LABELS.SIGNATURE_PATH,
      width: "10%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "sortingOrder",
      name: LABELS.SORTING_ORDER,
      width: "6%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: "false",
      sortable: "true",
      searchable: "true",
    },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageCustomer = () => {
    const customerTypeId = customerTypeRef.current.selectedValue.id;
    const name = (
      document.getElementById("customerName") as HTMLInputElement
    ).value.trim();

    const address1 = (
      document.getElementById("address") as HTMLInputElement
    ).value.trim();

    const areaId = routeRef.current.selectedValue.id;

    const pincode = (
      document.getElementById("pinCode") as HTMLInputElement
    ).value.trim();
    const incharge = (
      document.getElementById("contactPerson") as HTMLInputElement
    ).value.trim();

    const mobileNumber = (
      document.getElementById("mobileNumber") as HTMLInputElement
    ).value.trim();

    // const insertAbove = (
    //   document.getElementById("insertBelow") as HTMLInputElement
    // ).value.trim();

    const latitude = (
      document.getElementById("latitude") as HTMLInputElement
    ).value.trim();
    const longitude = (
      document.getElementById("longitude") as HTMLInputElement
    ).value.trim();

    const active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = active ? active.value : "";

    let errors = [];
    if (!customerTypeId) {
      errors.push({
        id: "customerTypeId",
        msg:
          "<span class='bold'>" +
          LABELS.CUSTOMER_TYPE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!name) {
      errors.push({
        id: "customerName",
        msg:
          "<span class='bold'>" +
          LABELS.CUSTOMER_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!address1) {
      errors.push({
        id: "address",
        msg:
          "<span class='bold'>" +
          LABELS.ADDRESS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!areaId) {
      errors.push({
        id: "areaId",
        msg:
          "<span class='bold'>" +
          LABELS.ROUTE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!pincode) {
      errors.push({
        id: "pinCode",
        msg:
          "<span class='bold'>" +
          LABELS.PIN_CODE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!incharge) {
      errors.push({
        id: "contactPerson",
        msg:
          "<span class='bold'>" +
          LABELS.CONTACT_PERSON +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (!mobileNumber) {
      errors.push({
        id: "mobileNumber",
        msg:
          "<span class='bold'>" +
          LABELS.MOBILE_NUMBER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      customerTypeId,
      name,
      address1,
      areaId,
      pincode,
      incharge,
      mobileNumber,
      insertAbove: 4,
      latitude,
      longitude,
      sortingOrder: 3,
    };
    console.log("args", args);
    setIsSubmit(true);
    CUSTOMER_APIs.manageCustomerProfile(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.CUSTOMERS}</li>
        </ul>
        <h4>{TITLES.CUSTOMERS}</h4>
        <p>{SUB_TITLES.CUSTOMERS}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={EXCEL_GRID_ID}
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            hideUnhide={true}
            freezeColumn={true}
            exportName={LABELS.CUSTOMER}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.CUSTOMER + ": " + selectedRow.nameOfHoliday
              : "Add New " + LABELS.CUSTOMER
          }
          open={true}
          size={"lg"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexRowJustify ">
            <div className="formGroup width30 ">
              <label className="red">
                {LABELS.CUSTOMER_TYPE}
                {" *"}
              </label>

              <AutoComplete
                list={customerType}
                childRef={customerTypeRef}
                defaultValue={
                  selectedRow.customerTypeId
                    ? {
                        id: selectedRow.customerTypeId,
                        label: selectedRow.country,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30 red">
              <TxtRow
                id={"customerName"}
                type={"text"}
                label={LABELS.CUSTOMER_NAME}
                defaultValue={selectedRow.name}
                disabled={isSubmit}
                required={true}
              />
            </div>
            <div className="formGroup width30 red">
              <TxtRow
                id={"address"}
                type={"text"}
                label={LABELS.ADDRESS}
                defaultValue={selectedRow.address1}
                disabled={isSubmit}
                required={true}
              />
            </div>
          </div>
          <div className="flexRowJustify ">
            <div className="formGroup width30 ">
              <label className="red">
                {LABELS.ROUTE}
                {" *"}
              </label>

              <AutoComplete
                list={route}
                childRef={routeRef}
                defaultValue={
                  selectedRow.countryId
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.country,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <TxtRow
                id={"pinCode"}
                type={"number"}
                label={LABELS.PIN_CODE}
                defaultValue={
                  selectedRow.pincode
                    ? Number(selectedRow.pincode).toString()
                    : selectedRow.pincode
                }
                disabled={isSubmit}
                required={true}
              />
            </div>
            <div className="formGroup width30 red">
              <TxtRow
                id={"contactPerson"}
                type={"text"}
                label={LABELS.CONTACT_PERSON}
                defaultValue={selectedRow.incharge}
                disabled={isSubmit}
                required={true}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <TxtRow
                id={"mobileNumber"}
                type={"number"}
                label={LABELS.MOBILE_NUMBER}
                defaultValue={
                  selectedRow.mobileNumber
                    ? Number(selectedRow.mobileNumber).toString()
                    : selectedRow.mobileNumber
                }
                required={true}
              />
            </div>

            <div className="formGroup width30 ">
              <label>{LABELS.INSERT_BELOW}</label>

              <AutoComplete
                list={insertBelow}
                childRef={insertBelowRef}
                defaultValue={
                  selectedRow.countryId
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.country,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30 ">
              <TxtRow
                id={"latitude"}
                type={"number"}
                label={LABELS.LATITUDE}
                disabled={isSubmit}
                defaultValue={
                  selectedRow.latitude
                    ? Number(selectedRow.latitude).toString()
                    : selectedRow.latitude
                }
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30 ">
              <TxtRow
                id={"longitude"}
                type={"number"}
                label={LABELS.LONGITUDE}
                defaultValue={
                  selectedRow.longitude
                    ? Number(selectedRow.longitude).toString()
                    : selectedRow.longitude
                }
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="formGroup required ">
            <label>
              {LABELS.STATUS}
              {/* {<span className="red">*</span>} */}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>

          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                bgColor={COLORS.SAVE_GREEN}
                onClick={manageCustomer}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Customer);
