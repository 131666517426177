import { REPORTS_APIs } from "apihandlers/reports";
import React, { useEffect, useState } from "react";
import HGraph from "components/Custom/HGraph";
import StackedCol from "./StackedCol";
import ColumnNeg from "./ColumnNeg";
import PieSemicircle from "./PieSemiCircle";
import VariablePieChart from "./VariablePieChart";
import ComboChart from "./ComboChart";
import Pie3dChart from "./Pie3dChart";
import CustomLabelChart from "./CustomLabelChart";
// import LineSeriesChart from "./LineSeriesChart";
import ColumnDrilldown from "./ColumnDrilldown";
import NetworkGraph from "./NetworkGraph";
import TreeGraphChart from "./TreeGraphChart";

import { GRAPH_TYPES } from "common/config";

const Karthik = () => {
  const [chartData, setChartData] = useState([]);
  const [piechartdata, setPieChartdata] = useState([]);

  const chart = [
    {
      name: "Browsers",
      colorByPoint: true,
      data: piechartdata,
    },
  ];

  const barStackedChart = [
    {
      name: "Cristiano Ronaldo",
      data: [4, 4, 6, 15, 12],
      color: "#a7b600",
    },
    {
      name: "Lionel Messi",
      data: [5, 3, 12, 6, 11],
      color: "#XX0000",
    },
    {
      name: "Robert Lewandowski",
      data: [5, 15, 8, 5, 8],
      color: "#a7b662",
    },
  ];

  const linechart = [
    {
      name: "Reggane",
      data: [
        16.0, 18.2, 23.1, 27.9, 32.2, 36.4, 39.8, 38.4, 35.5, 29.2, 22.0, 17.8,
      ],
      color: "#FF0000",
    },
    {
      name: "Tallinn",
      data: [
        -2.9, -3.6, -0.6, 4.8, 10.2, 14.5, 17.6, 16.5, 12.0, 6.5, 2.0, -0.9,
      ],
      color: "#808011",
    },
  ];

  const areaChart = [
    {
      name: "USA",
      data: [
        null,
        null,
        null,
        null,
        null,
        2,
        9,
        13,
        50,
        170,
        299,
        438,
        841,
        1169,
        1703,
        2422,
        3692,
        5543,
        7345,
        12298,
        18638,
        22229,
        25540,
        28133,
        29463,
        31139,
        31175,
        31255,
        29561,
        27552,
        26008,
        25830,
        26516,
        27835,
        28537,
        27519,
        25914,
        25542,
        24418,
        24138,
        24104,
        23208,
        22886,
        23305,
        23459,
        23368,
        23317,
        23575,
        23205,
        22217,
        21392,
        19008,
        13708,
        11511,
        10979,
        10904,
        11011,
        10903,
        10732,
        10685,
        10577,
        10526,
        10457,
        10027,
        8570,
        8360,
        7853,
        5709,
        5273,
        5113,
        5066,
        4897,
        4881,
        4804,
        4717,
        4571,
        4018,
        3822,
        3785,
        3805,
        3750,
        3708,
        3708,
      ],
    },
    {
      name: "USSR/Russia",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        1,
        5,
        25,
        50,
        120,
        150,
        200,
        426,
        660,
        863,
        1048,
        1627,
        2492,
        3346,
        4259,
        5242,
        6144,
        7091,
        8400,
        9490,
        10671,
        11736,
        13279,
        14600,
        15878,
        17286,
        19235,
        22165,
        24281,
        26169,
        28258,
        30665,
        32146,
        33486,
        35130,
        36825,
        38582,
        40159,
        38107,
        36538,
        35078,
        32980,
        29154,
        26734,
        24403,
        21339,
        18179,
        15942,
        15442,
        14368,
        13188,
        12188,
        11152,
        10114,
        9076,
        8038,
        7000,
        6643,
        6286,
        5929,
        5527,
        5215,
        4858,
        4750,
        4650,
        4600,
        4500,
        4490,
        4300,
        4350,
        4330,
        4310,
        4495,
        4477,
      ],
    },
  ];

  const columRangechart = [
    {
      name: "Temperatures",
      data: [
        [-13.9, 5.2],
        [-16.7, 10.6],
        [-4.7, 11.6],
        [-4.4, 16.8],
        [-2.1, 27.2],
        [5.9, 29.4],
        [6.5, 29.1],
        [4.7, 25.4],
        [4.3, 21.6],
        [-3.5, 15.1],
        [-9.8, 12.5],
        [-11.5, 8.4],
      ],
    },
  ];

  useEffect(() => {
    getGraphData();
  }, []);

  const getGraphData = async () => {
    try {
      const args = {};
      const res: any = await REPORTS_APIs.getGraphData(args);
      const { status, response } = res;
      if (status === 200) {
        // console.log(response.columnGraph);
        setChartData(response.columnGraph);
        setPieChartdata(response.pieGraph);

        // console.log(response.columnGraph.xAxis);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  console.log("chartData", chartData);

  return (
    <>
      <div
        className="graphsWrapperContainer"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <HGraph
          width={"50%"}
          height={"40%"}
          type={GRAPH_TYPES.COLUMN}
          float={"right"}
          // stacked={false}
          xAxis={[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]}
          yAxis={[20, 25, 32, 40, 65, 68, 55, 43, 0, 0, 0, 0]}
          // xAxis={chartData.xAxis}
          // yAxis={chartData.yAxis}
          title={"Column Graph"}
          exportTitle={"Column Graph Record"}
          xAxisTitle=""
          yAxisTitle="Leads"
        />

        <HGraph
          width={"50%"}
          height={"50%"}
          type={GRAPH_TYPES.PIE}
          float={"left"}
          series={chart}
          title={"Piechart"}
          exportTitle={" Pie Chart Record"}
        />

        <HGraph
          title={"line Chart"}
          width={"50%"}
          height={"50%"}
          type={GRAPH_TYPES.LINE}
          float={"right"}
          xAxis={[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]}
          // exportTitle={"line Chart Record"}
          series={linechart}
          xAxisTitle={"line"}
          yAxisTitle={"chart"}
        />

        <HGraph
          title={"area Chart"}
          width={"50%"}
          height={"50%"}
          type={GRAPH_TYPES.AREA}
          // float={"right"}
          xAxis={["1940", "1960", "1980", "2000", "2020"]}
          series={areaChart}
          xAxisTitle={"Nuclear Weopon"}
          yAxisTitle={"chart"}
        />

        {/* <HGraph
        title={"Column Stacked and Grouped"}
        width={"50%"}
        height={"50%"}
        type={GRAPH_TYPES.COLUMN}
        float={"right"}
        yAxisTitle={"Count Medals"}
        stacked={true}
        xAxis={["Gold", "Silver", "Bronze"]}
        series={columnStackedChart}
        // xAxisTitle={""}
      /> */}

        <HGraph
          title={"Column Range chart"}
          width={"50%"}
          height={"50%"}
          type={GRAPH_TYPES.COLUMN_RANGE}
          yAxisTitle={"Temperature"}
          xAxis={[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]}
          series={columRangechart}
        />

        <StackedCol width={"50%"} height={"50%"} />

        <ColumnNeg width={"50%"} height={"50%"} />

        <PieSemicircle width={"50%"} height={"50%"} />

        <VariablePieChart width={"50%"} height={"50%"} />

        <ComboChart width={"50%"} height={"50%"} />

        <Pie3dChart width={"50%"} height={"50%"} />

        <HGraph
          width={"50%"}
          height={"50%"}
          type={GRAPH_TYPES.STACKED_BAR}
          xAxis={["2020/21", "2019/20", "2018/19", "2017/18", "2016/17"]}
          series={barStackedChart}
        />

        <CustomLabelChart height={"50%"} />

        {/* <LineSeriesChart height={"50%"} /> */}

        <ColumnDrilldown width={"80%"} height={"50%"} />

        <TreeGraphChart width={"100%"} height={"50%"} />
      </div>
    </>
  );
};

export default Karthik;
