import React from "react";
import { useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Edit as IconEdit, Close as IconClose } from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import SkeletonBox from "components/Custom/SkeletonBox";

import { NOTIFY, CONFIG, API_CONFIG } from "common/config";
import { LABELS, WARNINGS } from "common/lables";
import { COMMON_JS } from "common/scripts";
import "./styles/users.css";
import { AUTH_APIs } from "apihandlers/auth";

const thumbnailImg = require("assets/images/pdf.png");

const VehicleDocs = (props: any) => {
  const {
    vehicleTypes,
    selectedRow,
    onClose,
    axlesTypes,
    bodyTypes,
    passingTonType,
    driverNames,
    manageRecord,
    areas,
    isSubmit,
    manageFiles,
    vehicleFiles,
    setVehicleFiles,
  } = props;

  const [uploading, setUploading] = useState<boolean>(false);

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "fuel",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        setVehicleFiles([...vehicleFiles, fileInfo]);
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };
  const onSave = () => {
    const args = {
      vehicleId: selectedRow.id || -1,
      files: vehicleFiles,
    };
    manageFiles(args);
  };

  return (
    <>
      <div className="formGroup width45">
        <label>
          {LABELS.PHOTOS}
          <small>{" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}</small>
        </label>
        <TxtBox
          type={"file"}
          id="uploadFile"
          // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
          // className="uploadFile"
          accept="image/*"
          onChange={onUploadFile}
        />

        <div
          className="formGroup"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {vehicleFiles.map((u, i) => {
            return (
              <div className="thumbnailBox width20">
                <IconButton
                  className="thumbnailBoxIcon"
                  onClick={() => {
                    const list = vehicleFiles.filter((a, j) => j !== i);
                    setVehicleFiles(list);
                  }}
                >
                  <IconClose />
                </IconButton>
                <img
                  onClick={() =>
                    window.open(API_CONFIG.STATIC_URL + u.path, "_blank")
                  }
                  src={
                    u.mimeType === "pdf"
                      ? thumbnailImg
                      : API_CONFIG.STATIC_URL + u.path
                  }
                  className="responsive cursor"
                />
              </div>
            );
          })}
          {uploading && (
            <div className="thumbnailBox width20">
              <SkeletonBox height={300} />
            </div>
          )}
        </div>
      </div>
      {/* <div className="formGroup submitBtnWrapper">
        <div className=" flexbtnjustify">
          <Btn
            text={LABELS.CANCEL}
            bgColor={COLORS.RELOAD_EXPORT}
            color={COLORS.RELOAD_EXPORT_TEXT}
            onClick={onClose}
            // loading={isSubmit}
          />
          <Btn
            text={LABELS.SAVE}
            bgColor={COLORS.SAVE_GREEN}
            loading={isSubmit}
            onClick={onSave}
          />
        </div>
      </div> */}
    </>
  );
};

export default withSnackbar(VehicleDocs);
