export const COLORS = {
  PRIMARY: "#0034AD",
  SECONDARY: "#FFEE00",
  TERTIARY: "#CEC225",
  WHITE: "#fff",
  DIM_GRAY: "#e1e1e1",
  DIM_DARK_GRAY: "#6e6e6e",
  RED: "#a31818",
  GREEN: "#089e50",
  SAVE_GREEN: "#22b378",
  GRID_ADD_NEW: "#22b378",
  RELOAD_EXPORT: "#e9e9e9",
  RELOAD_EXPORT_TEXT: "#3f3f3f",
  GRID_HEADER: "#f7f7f7",
  GRID_HEADER_TEXT: "#5c6b65",
  ORANGE: "orange",
};
