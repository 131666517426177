/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import GanttModule from "highcharts/modules/gantt";
import AccessibilityModule from "highcharts/modules/accessibility";
import Drilldown from "highcharts/modules/drilldown";

// import { GRAPH_TYPES } from "common/config";
// import { GRAPHS } from "common/graphs";
// import { COLORS } from "common/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts3d(Highcharts);
GanttModule(Highcharts);
AccessibilityModule(Highcharts);
Drilldown(Highcharts);

interface cProps {
  width: string;
  type: "column";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any[];
  stacked?: boolean;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const CustomLabelsChart = (props: any) => {
  const {
    width,
    type,
    stacked,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  let options = {
    chart: {
      type: "gantt",
    },

    title: {
      text: "Highcharts Gantt Chart",
    },

    subtitle: {
      text: "With custom symbols in data labels",
    },

    xAxis: {
      minPadding: 0.05,
      maxPadding: 0.05,
    },

    yAxis: {
      categories: ["Prototyping", "Development", "Testing"],
    },

    tooltip: {
      outside: true,
    },

    accessibility: {
      point: {
        valueDescriptionFormat:
          "{point.yCategory}, assigned to {point.assignee} from {point.x:%Y-%m-%d} to {point.x2:%Y-%m-%d}.",
      },
    },

    lang: {
      accessibility: {
        axis: {
          xAxisDescriptionPlural:
            "The chart has a two-part X axis showing time in both week numbers and days.",
        },
      },
    },

    series: [
      {
        name: "Project 1",
        data: [
          {
            start: Date.UTC(2018, 11, 1),
            end: Date.UTC(2018, 11, 2),
            y: 0,
            assignee: "bre1470",
          },
          {
            start: Date.UTC(2018, 11, 2),
            end: Date.UTC(2018, 11, 5),
            y: 1,
            assignee: "oysteinmoseng",
            fontSymbol: "exclamation",
            accessibility: {
              description: "Exclamation symbol.",
            },
          },
          {
            start: Date.UTC(2018, 11, 8),
            end: Date.UTC(2018, 11, 9),
            y: 2,
            assignee: "TorsteinHonsi",
          },
          {
            start: Date.UTC(2018, 11, 9),
            end: Date.UTC(2018, 11, 19),
            y: 1,
            assignee: "bre1470",
          },
          {
            start: Date.UTC(2018, 11, 10),
            end: Date.UTC(2018, 11, 23),
            y: 2,
            assignee: "TorsteinHonsi",
            fontSymbol: "smile-o",
            accessibility: {
              description: "Smiling face symbol.",
            },
          },
        ],
        dataLabels: [
          {
            enabled: true,
            format:
              '<div style="width: 20px; height: 20px; overflow: hidden; border-radius: 50%; margin-left: -25px">' +
              '<img src="https://github.com/{point.assignee}.png" ' +
              'style="width: 30px; margin-left: -5px; margin-top: -2px"></div>',
            useHTML: true,
            align: "left",
          },
          {
            enabled: true,
            format:
              '<i class="fa fa-{point.fontSymbol}" style="font-size: 1.5em"></i>',
            useHTML: true,
            align: "right",
          },
        ],
      },
    ],
  };

  console.log("options: ", options);

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default CustomLabelsChart;
