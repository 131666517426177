import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Btn from "components/Custom/Btn";

import RetailPOSSalesReport from "./RetailPOSSalesReport";
import BillIemReport from "./BillIemReport";
import BillRegister from "./BillRegister";
import DayWiseMonthlyCollection from "./DayWiseMonthlyCollection";
import IBTRegister from "./IBTRegister";
import IBTOutwardRegister from "./IBTOutwardRegister ";
import StockReport from "./StockReport";
import PresentStockReport from "./PresentStockReport";
import StockAdjustmentReport from "./StockAdjustmentReport";
import PurchaseReport from "./PurchaseReport";
import ConsolidatedPurchaseReport from "./ConsolidatedPurchaseReport ";
import ItemsListReport from "./ItemsListReport";

import { CONFIG } from "common/config";
import { LABELS, TITLES } from "common/lables";

import "devextreme/dist/css/dx.light.css";

const employeeLeavesReport = require("assets/images/reports/employeeleavesreport.png");
const chart1 = require("assets/images/reports/chart1.png");
const chartResourceHistogram = require("assets/images/reports/Resource_Histogram.png");
const chartResourceTable = require("assets/images/reports/Resource_Table.png");
const chartManHoursPlan = require("assets/images/reports/Man_Hours_Distribution.png");
const chartProgressCurve = require("assets/images/reports/Progress_S_Curve.png");
const chartProjectCashFlow = require("assets/images/reports/Project_Cashflow.png");
const chartProgressStatus = require("assets/images/reports/Project_Progress_Pie.png");
const chartProjectOverview = require("assets/images/reports/Project_Overview.png");
const chartRisksRegister = require("assets/images/reports/Risk_Register.png");
const chartEarnedValue = require("assets/images/reports/Earned_Value_Report.png");
const chartQuantitiesReport = require("assets/images/reports/Quantities_Report.png");
const chartManhoursReport = require("assets/images/reports/Manhours_Report.png");
const chartCostReport = require("assets/images/reports/Cost_Report.png");
const chartConstraintLogReport = require("assets/images/reports/Constraint_Log_Report.png");
const chartTimeSheetLog = require("assets/images/reports/Time_Sheet_Log_Report.png");
const chartApprovalLog = require("assets/images/reports/Approval_Log_Report.png");
const userLogreport = require("assets/images/reports/User_Log_Report.png");
const invoicereport = require("assets/images/reports/invoicereport.png");
const analysisReport = require("assets/images/reports/projectsreport.png");

const POSReport = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo, setPageMenu } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{TITLES.POS_REPORT}</li>
            </ul>
            <h4>{TITLES.POS_REPORT}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.RETAIL_POS_SALE_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.RETAIL_POS_SALE_REPORT}</h4>
            </MenuItem>

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.BILL_ITEM_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.BILL_ITEM_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.BILL_REGISTER);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.BILL_REGISTER}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.DAYWISE_MONTHLY_COLLECTION);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.DAYWISE_MONTHLY_COLLECTION}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.IBT_REGISTER);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.IBT_REGISTER}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.IBT_OUTWARD_REGISTER);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.IBT_OUTWARD_REGISTER}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.STOCK_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.STOCK_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PRESENT_STOCK_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.PRESENT_STOCK_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.STOCK_ADJUSTMENT_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.STOCK_ADJUSTMENT_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PURCHASE_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.PURCHASE_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CONSOLIDATED_PURCHASE_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.CONSOLIDATED_PURCHASE_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.ITEMS_LIST_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.ITEMS_LIST_REPORT}</h4>
            </MenuItem> */}
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.RETAIL_POS_SALE_REPORT && (
        <RetailPOSSalesReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.BILL_ITEM_REPORT && (
        <BillIemReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.BILL_REGISTER && (
        <BillRegister
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.DAYWISE_MONTHLY_COLLECTION && (
        <DayWiseMonthlyCollection
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.IBT_REGISTER && (
        <IBTRegister
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.IBT_OUTWARD_REGISTER && (
        <IBTOutwardRegister
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.STOCK_REPORT && (
        <StockReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.PRESENT_STOCK_REPORT && (
        <PresentStockReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.STOCK_ADJUSTMENT_REPORT && (
        <StockAdjustmentReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.PURCHASE_REPORT && (
        <PurchaseReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.CONSOLIDATED_PURCHASE_REPORT && (
        <ConsolidatedPurchaseReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.ITEMS_LIST_REPORT && (
        <ItemsListReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default POSReport;
