import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "../../Custom/UiDialog";
import AddSalesInvoice from "../AddSalesInvoice";

import { LABELS, TITLES, WARNINGS } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { PRODUCT_APIs } from "apihandlers/product";
import { USER_APIs } from "apihandlers/user";
import { TRANS_APIs } from "apihandlers/transaction";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";

const SalesReturn = (props: any) => {
  const { setDashMenu } = props;

  const productRef: any = useRef(null);
  const userRef: any = useRef(null);
  const areasRef: any = useRef(null);
  const partyRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [rows, setRows] = useState<any>(null);
  const [subRows, setSubRows] = useState<any>([]);
  const [filter, setFilter] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [products, setProducts] = useState<any>({});
  const [areas, setAreas] = useState<any>({});
  const [resetArgs, setResetArgs] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);

  const [parties, setParties] = useState<any>({});
  const [users, setUsers] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "billNumber",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_NO,
      width: "10%",
    },
    {
      key: "salesReturnNumber",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.SALES_RETURN_NO,
      width: "15%",
    },
    {
      key: "salesReturnDate",
      type: "date",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.SALES_RETURN_DATE,
      width: "10%",
    },
    {
      key: "shopName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.SHOP_NAME,
      width: "20%",
    },
    {
      key: "routeName",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ROUTE_NAME,
      width: "20%",
    },
    {
      key: "items",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ITEMS,
      width: "10%",
    },
    {
      key: "amount",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.AMOUNT,
      width: "10%",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRoute();
    }
  }, [loading]);

  const getRoute = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setAreas(list);
        getUser();
      }
    });
  };

  const getUser = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setUsers(list);
        getRows();
      }
    });
  };

  // const subGrid = () => {
  //   return (
  //     <>
  //       <ExcelGrid
  //         id={"salesSubGrid"}
  //         cols={columns2}
  //         rows={subRows}
  //         isSubGrid={true}
  //         noTotalRows={true}
  //       />
  //     </>
  //   );
  // };

  const getRows = async () => {
    try {
      const fromDate = (
        document.getElementById("fromDate") as HTMLInputElement
      ).value.trim();
      const toDate = (
        document.getElementById("toDate") as HTMLInputElement
      ).value.trim();
      const routeId = areasRef.current.selectedValue.id;
      const userId = userRef.current.selectedValue.id;
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        fromDate,
        toDate,
        routeId,
        userId,
      };
      setFilter(args);
      setRows(null);
      const res: any = await TRANS_APIs.getSalesReturn(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          // list[i]["_type"] = "maingrid";
          // list[i]["_expanded"] = false;
          // list[i]["_subGrid"] = subGrid;

          // let subList = list[i].sales;
          // for (let j = 0; j < subList.length; j++) {
          //   subList[j]["slNo"] = j + 1;
          // }
          // setSubRows(subList);
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getSalesReturn:", error);
    }
  };

  const onReset = (e) => {
    console.log(e);
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };
  const printSummary = (e) => {
    console.log(e);
  };
  const printSalesReport = (e) => {
    console.log(e);
  };

  return (
    <React.Fragment>
      {
        <div className="pageInfo">
          <ul className="breadcrumbs">
            <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
            <li className="dim">{" / "}</li>
            <li onClick={() => props.setDashMenu(LABELS.HOME)}>
              {LABELS.SALES}
            </li>
            <li className="dim">{" / "}</li>
            <li className="active">{TITLES.SALES_RETURN}</li>
          </ul>
          <h4>{TITLES.SALES_RETURN}</h4>
        </div>
      }
      <div className="width50 filterBorder">
        <UiAccordion id={"filter"} title={LABELS.FILTER}>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.FROM_DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.TO_DATE}
                type={"date"}
                id="toDate"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.ROUTE}</label>
              <AutoComplete
                list={areas}
                childRef={areasRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Route" }
                }
              />
            </div>
          </div>
          <div className="flexRowAround" style={{ margin: 0, padding: 0 }}>
            <div className="formGroup width30">
              <label>{LABELS.USER}</label>
              <AutoComplete
                list={users}
                childRef={userRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select User" }
                }
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30">
              <div className="flexbtnLoad " style={{ marginTop: "1.5rem" }}>
                <Btn
                  text={LABELS.RESET}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={onReset}
                  //loading={isSubmit}
                />
                <Btn
                  text={LABELS.LOAD}
                  onClick={getRows}
                  bgColor={COLORS.GRID_ADD_NEW}
                  // loading={isSubmit}
                />
              </div>
            </div>
          </div>
        </UiAccordion>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.55}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.SALES_RETURN}
            onReload={reloadGrid}
            leftSideWidth={"50%"}
            rightSideWidth={"50%"}
            leftSideBtns={
              <>
                <div className="excelBtnWrapper">
                  <Btn
                    text={LABELS.PRINT_SUMMARY}
                    onClick={printSummary}
                    bgColor={COLORS.RELOAD_EXPORT}
                    color={COLORS.RELOAD_EXPORT_TEXT}
                  />
                </div>
                <div className="excelBtnWrapper">
                  <Btn
                    text={LABELS.PRINT_SALES_RETURN}
                    onClick={printSalesReport}
                    bgColor={COLORS.RELOAD_EXPORT}
                    color={COLORS.RELOAD_EXPORT_TEXT}
                  />
                </div>
              </>
            }
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(SalesReturn);
