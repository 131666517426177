// * Note: Please collect location info & logtime of each and every API Requests

// import axios from 'axios';

import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7004";

export const MASTER_APIs = {
  manageTraining: async (args = {}) => {
    let apiUrl = "/ma/v1/PLV1RSVLT1WGPDT1";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/PLV1RSVLT1WGPDT1";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },
  getTrainings: async (args = {}) => {
    let apiUrl = "/ma/v1/OT7FS81ZHT8IJ0LC";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/OT7FS81ZHT8IJ0LC";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageTrainingAssign: async (args = {}) => {
    let apiUrl = "/ma/v1/L2MRXPFNTQPRJXO1";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/L2MRXPFNTQPRJXO1";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },
  getTrainingAssigns: async (args = {}) => {
    let apiUrl = "/ma/v1/Z91GLXZF6RONH5JB";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/Z91GLXZF6RONH5JB";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageTrainingActivity: async (args = {}) => {
    let apiUrl = "/ma/v1/USV66G5ZBLM4Q7AL";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/USV66G5ZBLM4Q7AL";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getTrainingActivities: async (args = {}) => {
    let apiUrl = "/ma/v1/UJZ3QSR2EEIK0RQP";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/UJZ3QSR2EEIK0RQP";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageGptMaster: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/ma/v1/838I3515U68755JD",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  getGptMaster: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/ma/v1/107C5643D53898BI",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // Adding/Editing Vehicles
  manageVehicle: async (args = {}) => {
    let apiUrl = "/ma/v1/243G1336Q39721XF";
    if (local) {
      apiUrl = "http://192.168.0.115:7004//ma/v1/243G1336Q39721XF";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Vehicles details
  getVehicles: async (args = {}) => {
    let apiUrl = "/ma/v1/768X3479A21866MW";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/768X3479A21866MW";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing driver
  manageDriver: async (args = {}) => {
    let apiUrl = "/ma/v1/805A3556N28311BV";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/805A3556N28311BV";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get driver details
  getDrivers: async (args = {}) => {
    let apiUrl = "/ma/v1/497F1872Q59764PF";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/497F1872Q59764PF";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Add vehicle driver details
  manageVehicleDriver: async (args = {}) => {
    let apiUrl = "/ma/v1/506O5863Y93386XC";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/506O5863Y93386XC";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get vehicle driver details
  getVehicleDrivers: async (args = {}) => {
    let apiUrl = "/ma/v1/910A8352X90318CK";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/910A8352X90318CK";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update Branch
  manageBranchProfile: async (args = {}) => {
    let apiUrl = "/ma/v1/972C2154T55252HE";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/972C2154T55252HE";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get customer Branches
  getBranchProfile: async (args = {}) => {
    let apiUrl = "/ma/v1/549I9528I50289QW";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/549I9528I50289QW";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update
  manageFareChart: async (args = {}) => {
    let apiUrl = "/ma/v1/463V6381L97251VK";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/463V6381L97251VK";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getFareCharts: async (args = {}) => {
    let apiUrl = "/ma/v1/395M2086H67832OU";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/395M2086H67832OU";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update

  manageHolidays: async (args = {}) => {
    let apiUrl = "/ma/v1/546H9074T95520BK";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/546H9074T95520BK";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getHolidays: async (args = {}) => {
    let apiUrl = "/ma/v1/885O7965S48296AZ";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/885O7965S48296AZ";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update
  manageLeaveTypes: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/ma/v1/607D5599N60637VW",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get
  getLeaveTypes: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/ma/v1/859Q2704F49253VF",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // To create/update
  manageMktgTeam: async (args = {}) => {
    let apiUrl = "/ma/v1/676V6275F35069GK";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/676V6275F35069GK";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getMktgTeam: async (args = {}) => {
    let apiUrl = "/ma/v1/121Z9029X80381JW";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/121Z9029X80381JW";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update
  manageGptBase: async (args = {}) => {
    let apiUrl = "/ma/v1/838I3515U68755JD";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/838I3515U68755JD";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getGptBase: async (args = {}) => {
    let apiUrl = "/ma/v1/107C5643D53898BI";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/107C5643D53898BI";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update
  manageScheme: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/ma/v1/849R6113S73487MU",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get
  getScheme: async (args = {}) => {
    let apiUrl = "/ma/v1/544K1349L83793JC";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/544K1349L83793JC";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update
  manageTarget: async (args = {}) => {
    let apiUrl = "/ma/v1/116Y6852Y24601QB";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/116Y6852Y24601QB";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getTarget: async (args = {}) => {
    let apiUrl = "/ma/v1/580X8943L66542QT";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/580X8943L66542QT";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update
  manageOrgProfile: async (args = {}) => {
    let apiUrl = "/ma/v1/8Z4QAVD6OUUG9XKD";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/8Z4QAVD6OUUG9XKD";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getPosCenters: async (args = {}) => {
    let apiUrl = "/ma/v1/Z1LG4APYOYV41RB4";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getOrgProfiles: async (args = {}) => {
    let apiUrl = "/ma/v1/GBAVZ6X1B4NZGXXE";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update
  manageLeaveApplication: async (args = {}) => {
    let apiUrl = "/ma/v1/RBL81B0EESC6RLGF";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/RBL81B0EESC6RLGF";
    }
    const response = await COMMON_JS.callApi(
      "/ma/v1/0GZXN17DMQRC004P",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get
  getLeaveApplications: async (args = {}) => {
    let apiUrl = "/ma/v1/CKJ8B8OWR0WVHW0B";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/CKJ8B8OWR0WVHW0B";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  //Expenses
  manageExpense: async (args = {}) => {
    let apiUrl = "/ma/v1/3NFCPQKHM24C11MY";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/3NFCPQKHM24C11MY";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getExpenses: async (args = {}) => {
    let apiUrl = "/ma/v1/3G99DI8VF8ZE7N1Z";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/3G99DI8VF8ZE7N1Z";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageMktngLeave: async (args = {}) => {
    let apiUrl = "/ma/v1/UKRDRA4T0BHI4DEB";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/UKRDRA4T0BHI4DEB";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get
  getMktngLeaves: async (args = {}) => {
    let apiUrl = "ma/v1/6FFSZLJQI22AYQ9Q";
    if (local) {
      apiUrl = "http://192.168.0.115:7004/ma/v1/6FFSZLJQI22AYQ9Q";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getUserDepartments: async (args = {}) => {
    let apiUrl = "/ma/v1/T0NFNFE5JN3A78M3";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getUserDesignations: async (args = {}) => {
    let apiUrl = "/ma/v1/TUCQ1JO84XGL3KEL";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getVehicleTypes: async (args = {}) => {
    let apiUrl = "/ma/v1/JV10C550591NASKX";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getAxles: async (args = {}) => {
    let apiUrl = "/ma/v1/XHPXW9FM3EMS5R1C";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getBodyTypes: async (args = {}) => {
    let apiUrl = "/ma/v1/M8XHIZZKEBW20QSR";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPassingtons: async (args = {}) => {
    let apiUrl = "/ma/v1/V4FAWSD0AF6Q5P9U";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getVehicleFileInfo: async (args = {}) => {
    let apiUrl = "/ma/v1/9MWF05GQ6GQXYT7W";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  manageDriverFiles: async (args = {}) => {
    let apiUrl = "/ma/v1/6LNIPR7FZ1COYI74";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getDriverFileInfo: async (args = {}) => {
    let apiUrl = "/ma/v1/U0DC041KOUA0PA46";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
};
