/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import VariablePie from "highcharts/modules/variable-pie";

// import { GRAPH_TYPES } from "common/config";
// import { GRAPHS } from "common/graphs";
// import { COLORS } from "common/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts3d(Highcharts);
VariablePie(Highcharts);

interface cProps {
  width: string;
  type: "column";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any[];
  stacked?: boolean;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const VariablePieChart = (props: any) => {
  const {
    width,
    type,
    stacked,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  let options = {
    chart: {
      type: "variablepie",
    },
    title: {
      text: "Countries compared by population density and total area, 2022.",
      align: "left",
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        "Area (square km): <b>{point.y}</b><br/>" +
        "Population density (people per square km): <b>{point.z}</b><br/>",
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "20%",
        zMin: 0,
        name: "countries",
        borderRadius: 5,
        data: [
          {
            name: "Spain",
            y: 505992,
            z: 92,
          },
          {
            name: "France",
            y: 551695,
            z: 119,
          },
          {
            name: "Poland",
            y: 312679,
            z: 121,
          },
          {
            name: "Czech Republic",
            y: 78865,
            z: 136,
          },
          {
            name: "Italy",
            y: 301336,
            z: 200,
          },
          {
            name: "Switzerland",
            y: 41284,
            z: 213,
          },
          {
            name: "Germany",
            y: 357114,
            z: 235,
          },
        ],
        colors: [
          "#4caefe",
          "#3dc3e8",
          "#2dd9db",
          "#1feeaf",
          "#0ff3a0",
          "#00e887",
          "#23e274",
        ],
      },
    ],
  };

  console.log("options: ", options);

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default VariablePieChart;
