import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import { NOTIFY } from "common/config";
import { LABELS, WARNINGS, TITLES, SUB_TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { MASTER_APIs } from "apihandlers/master";
import { REPORTS_APIs } from "apihandlers/reports";

const StockReport = (props: any) => {
  const { setDashMenu } = props;

  const outletRef: any = useRef();

  const [loading, setLoading] = useState(true);
  const [masters, setMasters] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "item",
      name: LABELS.ITEM,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "openingStock",
      name: LABELS.OPENING_STOCK,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "purchase",
      name: LABELS.PURCHASE,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "sales",
      name: LABELS.SALES,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "adjustment",
      name: LABELS.ADJUSTMENT,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "closingStock",
      name: LABELS.CLOSING_STOCK,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "date",
      name: LABELS.DATE,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
  ];

  useEffect(() => {
    if (loading) {
      getOrgProfile();
    }
  }, [loading]);

  const getOrgProfile = () => {
    const args = {};
    MASTER_APIs.getOrgProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list: any = [{ id: -1, label: "All" }];
        let recs: any = response.records;
        for (let i = 0; i < recs.length; i++) {
          list.push({
            id: recs[i].id,
            label: recs[i].organizationName,
          });
        }
        setMasters(list);
        setLoading(false);
      }
    });
  };

  const loadChart = () => {
    const outlet = outletRef.current.selectedValue.id;
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const toDt = (document.getElementById("toDt") as HTMLInputElement).value;
    const args = {
      fromDt,
      toDt,
      outlet,
      reportType: "excel",
    };
    let errors = [];
    if (fromDt === "") {
      errors.push({
        id: "fromDt",
        msg:
          "<span class='bold'>" +
          LABELS.FROM_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (toDt === "") {
      errors.push({
        id: "toDt",
        msg:
          "<span class='bold'>" +
          LABELS.TO_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }
    setRows(null);
    setLoading(true);
    REPORTS_APIs.getPosStockReport(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        for (let i = 0; i < response.rows.length; i++) {
          response.rows[i]["slNo"] = i + 1;
        }
        setRows(response.rows);
        setLoading(false);
      }
    });
  };

  console.log("Page: StockReport:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {TITLES.POS_REPORT}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.STOCK_REPORT}</li>
        </ul>
        <h4>{TITLES.STOCK_REPORT}</h4>
        <p>{SUB_TITLES.STOCK_REPORT}</p>
      </div>

      <div className="hChart">
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
              marginBottom: 10,
              width: "40%",
            }}
          >
            <label>{LABELS.SELECT_OUTLET + " :"}</label>
            <AutoComplete
              id={"masters"}
              list={masters}
              childRef={outletRef}
              defaultValue={{ id: -1, label: "All" }}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 0,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.FROM_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="fromDt"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.TO_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="toDt"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <Btn text={LABELS.LOAD} onClick={loadChart} />
            </div>
          </div>
        </div>
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="grid excelGridWrapper">
          {loading ? (
            <div className="flexCenter" style={{ minHeight: 450 }}>
              <Loader />
            </div>
          ) : (
            rows && (
              <ExcelGrid
                id={LABELS.STOCK_REPORT}
                height={window.innerHeight * 0.8}
                cols={columns}
                rows={rows}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.STOCK_REPORT}
              />
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(StockReport);
