import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Btn from "components/Custom/Btn";

import SalesOrderReport from "./SalesOrderReport";
import DeletedOrderReport from "./DeletedOrderReport";
import SalesBillReport from "./SalesBillReport";
import DeletedBillReport from "./DeletedBillReport";
import PartyStatus from "./PartyStatus";
import SalesBillRegister from "./SalesBillRegister";
import SalesReturnReport from "./SalesReturnReport";
import SalesOrderVSBillQty from "./SalesOrderVSBillQty";
import SalesBillPaymentDetails from "./SalesBillPaymentDetails";
import PurchaseBillSummary from "./PurchaseBillSummary";
import TotalSummaryReport from "./TotalSummaryReport";
import SalesmanSummaryReport from "./SalesmanSummaryReport";
import CurrentStockWithDetails from "./CurrentStockWithDetails";

import { CONFIG } from "common/config";
import { LABELS, TITLES } from "common/lables";

import "devextreme/dist/css/dx.light.css";

const employeeLeavesReport = require("assets/images/reports/employeeleavesreport.png");
const chart1 = require("assets/images/reports/chart1.png");
const chartResourceHistogram = require("assets/images/reports/Resource_Histogram.png");
const chartResourceTable = require("assets/images/reports/Resource_Table.png");
const chartManHoursPlan = require("assets/images/reports/Man_Hours_Distribution.png");
const chartProgressCurve = require("assets/images/reports/Progress_S_Curve.png");
const chartProjectCashFlow = require("assets/images/reports/Project_Cashflow.png");
const chartProgressStatus = require("assets/images/reports/Project_Progress_Pie.png");
const chartProjectOverview = require("assets/images/reports/Project_Overview.png");
const chartRisksRegister = require("assets/images/reports/Risk_Register.png");
const chartEarnedValue = require("assets/images/reports/Earned_Value_Report.png");
const chartQuantitiesReport = require("assets/images/reports/Quantities_Report.png");
const chartManhoursReport = require("assets/images/reports/Manhours_Report.png");
const chartCostReport = require("assets/images/reports/Cost_Report.png");
const chartConstraintLogReport = require("assets/images/reports/Constraint_Log_Report.png");
const chartTimeSheetLog = require("assets/images/reports/Time_Sheet_Log_Report.png");
const chartApprovalLog = require("assets/images/reports/Approval_Log_Report.png");
const userLogreport = require("assets/images/reports/User_Log_Report.png");
const invoicereport = require("assets/images/reports/invoicereport.png");
const analysisReport = require("assets/images/reports/projectsreport.png");

const DMSReport = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo, setPageMenu } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{TITLES.DMS_REPORT}</li>
            </ul>
            <h4>{TITLES.DMS_REPORT}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SALES_ORDER_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.SALES_ORDER_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.DELETED_ORDER_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.DELETED_ORDER_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SALES_BILL_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.SALES_BILL_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.DELETED_BILL_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.DELETED_BILL_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PARTY_STATUS);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.PARTY_STATUS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SALES_BILL_REGISTER);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.SALES_BILL_REGISTER}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SALES_RETURN_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.SALES_RETURN_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SALES_ORDER_VS_BILL_QTY_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.SALES_ORDER_VS_BILL_QTY_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SALES_BILL_PAYMENT_DETAILS);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.SALES_BILL_PAYMENT_DETAILS}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PURCHASE_BILL_SUMMARY);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.PURCHASE_BILL_SUMMARY}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TOTAL_SUMMARY_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.TOTAL_SUMMARY_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SALESMAN_SUMMARY_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.SALESMAN_SUMMARY_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CURRENT_STOCK_WITH_DETAILS);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.CURRENT_STOCK_WITH_DETAILS}</h4>
            </MenuItem>
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.SALES_ORDER_REPORT && (
        <SalesOrderReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.DELETED_ORDER_REPORT && (
        <DeletedOrderReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.SALES_BILL_REPORT && (
        <SalesBillReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.DELETED_BILL_REPORT && (
        <DeletedBillReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.PARTY_STATUS && (
        <PartyStatus
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.SALES_BILL_REGISTER && (
        <SalesBillRegister
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.SALES_RETURN_REPORT && (
        <SalesReturnReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.SALES_ORDER_VS_BILL_QTY_REPORT && (
        <SalesOrderVSBillQty
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.SALES_BILL_PAYMENT_DETAILS && (
        <SalesBillPaymentDetails
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.PURCHASE_BILL_SUMMARY && (
        <PurchaseBillSummary
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.TOTAL_SUMMARY_REPORT && (
        <TotalSummaryReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}

      {dashMenu == LABELS.SALESMAN_SUMMARY_REPORT && (
        <SalesmanSummaryReport
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
      {dashMenu == LABELS.CURRENT_STOCK_WITH_DETAILS && (
        <CurrentStockWithDetails
          setDashMenu={(menu) => {
            if (menu === "") {
              setPageMenu(LABELS.HOME);
            } else {
              setDashMenu(menu);
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default DMSReport;
