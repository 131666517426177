import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDownward as IconArrowDown } from "@mui/icons-material";

const UiAccordion = (props: any) => {
  const { id, title, children, expanded, onChange } = props;
  return (
    <Accordion
      className="uiAccordion"
      expanded={expanded}
      onChange={onChange ? () => onChange(id) : undefined}
    >
      <AccordionSummary
        id={id}
        className="uiAccordionTitle"
        expandIcon={<IconArrowDown className="uiIcon" />}
      >
        <h4>{title}</h4>
      </AccordionSummary>
      <AccordionDetails className="uiAccordionContent">
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default UiAccordion;
