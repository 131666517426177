// * Note: Please collect location info & logtime of each and every API Requests

// import axios from 'axios';
import { API_CONFIG, CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const monthsData = require("./monthsData.json");

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7002";

export const GEO_APIs = {
  // Adding/Editing Country
  getGoogleResult: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/ge/v1/4NJ7QM05BSX8WYI8",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  manageCountry: async (args = {}) => {
    let apiUrl = "/ge/v1/647E9674G44439LY";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/647E9674G44439LY";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Country details
  getCountries: async (args = {}) => {
    let apiUrl = "/ge/v1/110T5189V47573RB";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/110T5189V47573RB";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Currency
  manageCurrency: async (args = {}) => {
    let apiUrl = "/ge/v1/134R9833Y31419LF";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/134R9833Y31419LF";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Currency details
  getCurrencies: async (args = {}) => {
    let apiUrl = "/ge/v1/930I2442Q95272NN";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/930I2442Q95272NN";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing state
  manageState: async (args = {}) => {
    let apiUrl = "/ge/v1/260S2228L48634XZ";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/260S2228L48634XZ";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get states details
  getStates: async (args = {}) => {
    let apiUrl = "/ge/v1/875E8139L74106VG";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/875E8139L74106VG";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing region
  manageRegion: async (args = {}) => {
    let apiUrl = "/ge/v1/560G8274M58845SD";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/560G8274M58845SD";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get regions details
  getRegions: async (args = {}) => {
    let apiUrl = "/ge/v1/299K9938V88156VM";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/299K9938V88156VM";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing area
  manageCity: async (args = {}) => {
    let apiUrl = "/ge/v1/481J5571G41479PT";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/481J5571G41479PT";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },
  //Get Districts
  getDistricts: async (args = {}) => {
    let apiUrl = "/ge/v1/5DAJE4YQYILYP0OF";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/5DAJE4YQYILYP0OF";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  //manage Districts
  manageDistrict: async (args = {}) => {
    let apiUrl = "/ge/v1/N4KWM0OVFHIM5Q2A";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/N4KWM0OVFHIM5Q2A";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get areas details
  getCities: async (args = {}) => {
    let apiUrl = "/ge/v1/775C4406C17657YN";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/775C4406C17657YN";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing area
  manageArea: async (args = {}) => {
    let apiUrl = "/ge/v1/812D2484K56185PV";
    if (local) {
      apiUrl = "http://192.168.0.115:7002//ge/v1/812D2484K56185PV";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get areas details
  getAreas: async (args = {}) => {
    let apiUrl = "/ge/v1/209E7002A15449QL";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing areabeat
  manageAreaBeat: async (args = {}) => {
    let apiUrl = "/v1/geography/areabeat";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/v1/geography/areabeat";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get areabeats details
  getAreaBeat: async (args = {}) => {
    let apiUrl = "/v1/geography/areabeats";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/v1/geography/areabeats";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  //Get Central zone

  getCentralZones: async (args = {}) => {
    let apiUrl = "/ge/v1/YJZSFMW0SPHVRVGM";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/YJZSFMW0SPHVRVGM";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageCentralZone: async (args = {}) => {
    let apiUrl = "/ge/v1/F49KEHYQFO1O9KA3";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/F49KEHYQFO1O9KA3";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getRegionZones: async (args = {}) => {
    let apiUrl = "/ge/v1/8NYK3HUIRKXKK1D3";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/8NYK3HUIRKXKK1D3";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getOrgWiseAreas: async (args = {}) => {
    let apiUrl = "/ge/v1/Z76NAUMT4AAS345L";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/Z76NAUMT4AAS345L";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageRegionZone: async (args = {}) => {
    let apiUrl = "/ge/v1/0C6L5GNQ4PJK8YCR";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/0C6L5GNQ4PJK8YCR";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },
  manageOrgWiseArea: async (args = {}) => {
    let apiUrl = "/ge/v1/WJPKYM8NEFH341AY";
    if (local) {
      apiUrl = "http://192.168.0.115:7002/ge/v1/WJPKYM8NEFH341AY";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getSalesAnalysis: async (args: any = {}) => {
    let resObj: any = {};
    let apiUrl = "/ge/v1/WJPKYM8NEFH341AY";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":7002" + apiUrl;
    }

    resObj = {
      status: 200,
      response: monthsData[args.month] || [],
    };
    return resObj;
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
};
