import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";

import AllLeads from "../CrmReports/AllLeads";
import LeadAssignments from "./LeadAssignments";
import LeadVisits from "./LeadVisits";
import DocList from "./DocList";
import ApprovalList from "./ApprovalList";

import TrainingFiles from "components/Training/Reports/TrainingFiles";
import TrainingAssignments from "components/Training/Reports/TrainingAssignments";
import TrainingStatus from "components/Training/Reports/TrainingStatus";

import { CONFIG } from "common/config";
import { LABELS, TITLES } from "common/lables";

import "./dashboard.css";
import "./styles/reports.css";
import "./styles/datatables.min.css";
import "devextreme/dist/css/dx.light.css";

const employeeLeavesReport = require("assets/images/reports/employeeleavesreport.png");

const CrmReports = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo, setPageMenu } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  if (childRef) {
    if (childRef.setMenu) {
      childRef.setMenu = setMenu;
    }
  }

  let menuUserLog = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li onClick={() => setPageMenu(LABELS.HOME)}>{LABELS.HOME}</li>{" "}
              <li className="dim">{" / "}</li>
              <li className="active">{TITLES.CRM_REPORTS}</li>
            </ul>
            <h4>{TITLES.CRM_REPORTS}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.GET_ALL_LEADS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.GET_ALL_LEADS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.LEAD_ASSIGNMENTS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.LEAD_ASSIGNMENTS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.LEAD_VISITS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.LEAD_VISITS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.DOC_LIST);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.DOC_LIST}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.APPROVAL_LIST);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.APPROVAL_LIST}</h4>
            </MenuItem>
            {/*
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.GET_ALL_LEADS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.GET_ALL_LEADS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.GET_ALL_LEADS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.GET_ALL_LEADS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.GET_ALL_LEADS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.GET_ALL_LEADS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(TITLES.GET_ALL_LEADS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{TITLES.GET_ALL_LEADS}</h4>
            </MenuItem> */}
          </MenuList>
        </div>
      )}

      {dashMenu == TITLES.GET_ALL_LEADS && (
        <AllLeads setDashMenu={setDashMenu} />
      )}

      {dashMenu == TITLES.LEAD_ASSIGNMENTS && (
        <LeadAssignments setDashMenu={setDashMenu} />
      )}

      {dashMenu == TITLES.LEAD_VISITS && (
        <LeadVisits setDashMenu={setDashMenu} />
      )}

      {dashMenu == TITLES.DOC_LIST && <DocList setDashMenu={setDashMenu} />}

      {dashMenu == TITLES.APPROVAL_LIST && (
        <ApprovalList setDashMenu={setDashMenu} />
      )}

      {/* Training */}
      {dashMenu == TITLES.TRAINING_FILES && (
        <TrainingFiles setDashMenu={setDashMenu} />
      )}
      {dashMenu == TITLES.TRAINING_ASSIGNMENTS && (
        <TrainingAssignments setDashMenu={setDashMenu} />
      )}
      {dashMenu == TITLES.TRAINING_STATUS && (
        <TrainingStatus setDashMenu={setDashMenu} />
      )}
    </React.Fragment>
  );
};

export default CrmReports;
