/**
 * Lead Profile Creation
 */
import React from "react";

import ActivityBoard from "./ActivityBoard";
import PieChart from "./PieChart";
import ColumnGraph from "./ColumnGraph";
import StackedBar from "./StackedBar";
import SolidGauge from "./SolidGauge";
import BarGraph from "./BarGraph";
import LeadProfile from "../LeadProfile";
import Btn from "components/Custom/Btn";

import { INFO, LABELS, TITLES } from "common/lables";

import "./style/index.css";
import { COLORS } from "common/colors";

const map = require("assets/images/map.png");

interface defaultProps {}

const CrmDashboard = (props: any) => {
  const { setDashMenu } = props;

  return (
    <React.Fragment>
      <div className="userDashboard">
        <div className="pageInfo">
          <ul className="breadcrumbs">
            <li
              onClick={() => {
                setDashMenu(LABELS.HOME);
              }}
            >
              {LABELS.HOME}
            </li>
            <li className="dim">{" / "}</li>
            <li className="active">{TITLES.CRM_DASHBOARD}</li>
          </ul>
          <h4>{TITLES.CRM_DASHBOARD}</h4>
        </div>
        <div
          className="crmDashboard"
          style={{
            width: "98%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              justifyContent: "space-between",
              height: window.innerHeight * 0.3,
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <div className="width23" style={{ paddingLeft: 2 }}>
              <ColumnGraph height={window.innerHeight * 0.3} />
            </div>
            <div className="width23">
              <PieChart height={window.innerHeight * 0.3} />
            </div>
            <div className="width23">
              <BarGraph height={window.innerHeight * 0.3} />
            </div>
            <div
              className="width23"
              style={{
                display: "flex",
                flexDirection: "column",
                height: window.innerHeight * 0.3,
                position: "relative",
              }}
            >
              <img
                src={map}
                height={"100%"}
                style={{
                  border: "1px solid #ccc",
                  padding: 2,
                  borderRadius: 5,
                  width: "fit-content",
                  margin: "auto",
                }}
              />
              <a
                className="textShadow"
                href="/streetview"
                target="_new"
                style={{
                  position: "absolute",
                  bottom: 4,
                  left: "33%",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                {INFO.CLICK_HERE_STREETVIEW}
              </a>
            </div>
          </div>
          <div>
            <Btn
              text={"Show Roi"}
              onClick={() => {
                setDashMenu(TITLES.ROI);
              }}
              bgColor={COLORS.SAVE_GREEN}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "75%" }}>
              <LeadProfile header={false} />
            </div>
            <div
              style={{
                width: "23%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginBottom: 0,
                }}
              >
                <StackedBar height={window.innerHeight * 0.2} />
                <SolidGauge height={window.innerHeight * 0.2} />
              </div>
              <div
                style={{
                  height: "40%",
                }}
              >
                <ActivityBoard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CrmDashboard;
