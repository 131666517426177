import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { PersonAddAlt as AddCustomerIcon } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import UiAccordion from "components/Custom/UiAccordion";
import UiDialog from "components/Custom/UiDialog";
import AddSalesInvoice from "components/Sales/AddSalesInvoice";
import RadioBtn from "components/Custom/RadioBtn";

import { ERRORS, LABELS, SUCCESS, TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";
import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { PRODUCT_APIs } from "apihandlers/product";
import { USER_APIs } from "apihandlers/user";
import { TRANS_APIs } from "apihandlers/transaction";
import { GEO_APIs } from "apihandlers/geography";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { MASTER_APIs } from "apihandlers/master";

const SalesOrder = (props: any) => {
  const { setDashMenu } = props;

  const productRef: any = useRef(null);
  const userRef: any = useRef(null);
  const areasRef: any = useRef(null);
  const partyRef: any = useRef(null);
  const salesOrderRef: any = useRef(null);
  const salesOrderChildRef: any = useRef(null);
  const vehicleRef: any = useRef(null);
  const branchRef: any = useRef(null);
  const stockRef: any = useRef(null);
  const schemeRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [rows, setRows] = useState<any>(null);
  const [filter, setFilter] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [areas, setAreas] = useState<any>([]);
  const [vehicles, setVehicles] = useState<any>([]);
  const [parties, setParties] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [stockLoad, setStockLoad] = useState<any>([]);
  const [orderItems, setOrderItems] = useState<any>([]);
  const [schemes, setSchemes] = useState<any>([]);
  const [resetArgs, setResetArgs] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [salesInvoice, SetsalesInvoice] = useState<boolean>(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [partyId, setPartyId] = useState();
  const [manageProductDialog, setManageProductDialog] =
    useState<boolean>(false);

  const columns = [
    {
      key: "expanded",
    },
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "12.5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "orderNumber",
      type: "dropdown",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.ORDER_NO,
      width: "25%",
      list: [
        {
          id: "1",
          label: "List 1",
        },
        {
          id: "2",
          label: "List 2",
        },
      ],
      filters: [
        {
          id: "1",
          label: "List 1",
        },
        {
          id: "2",
          label: "List 2",
        },
      ],
    },
    {
      key: "orderDate",
      type: "datetime",
      editable: true,
      sortable: true,
      searchable: true,
      name: LABELS.DATE,
      width: "20%",
    },
    {
      key: "totalQty",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ITEMS,
      width: "20%",
      renderSum: true, // renders the total summary at the bottom
    },
    {
      key: "total",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.AMOUNT,
      width: "20%",
      renderSum: true,
    },
  ];

  const formulaRenderer = (row) => {
    console.log("formulaRenderer: ", row);
    let orderItemBasic = Number(row["orderItemBasic"] || 0);
    let price = Number(row["price"] || 0);
    let orderItemTotal = 0;
    if (!isNaN(orderItemBasic) && !isNaN(price)) {
      orderItemTotal = price * orderItemBasic;
    }
    row["orderItemTotal"] = orderItemTotal;
    return row;
  };

  const columns2 = [
    {
      key: "",
      selectColumn: true,
    },
    {
      key: "slNo",
      type: "text",
      editable: false,
      sortable: false,
      searchable: false,
      name: LABELS.SL_NO,
      width: "5%",
    },
    {
      key: "productId",
      type: "text",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.PRODUCT_NAME,
      width: "35%",
    },
    {
      key: "price",
      type: "text",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.PRICE,
      width: "20%",
      formula: formulaRenderer,
      renderSum: true,
    },
    {
      key: "orderItemBasic",
      type: "number",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.QTY,
      width: "20%",
      formula: formulaRenderer,
      isDisabled: (row: any) => {
        return row.slNo === 1;
      },
    },
    {
      key: "orderItemTotal",
      type: "text",
      editable: false,
      sortable: false,
      searchable: false,
      name: LABELS.AMOUNT,
      width: "20%",
      formula: formulaRenderer,
      renderSum: true,
    },
  ];

  useEffect(() => {
    getRows();
    getRoute();
  }, []);

  const getRoute = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setAreas(list);
        getParty();
      }
    });
  };

  const getParty = () => {
    const args = {
      activeStatus: true,
    };
    CUSTOMER_APIs.getCustomerProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setParties(list);
        getProduct();
      }
    });
  };

  const getProduct = () => {
    const args = {
      activeStatus: true,
    };
    PRODUCT_APIs.getProductsSku(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.productName,
          };
        });
        setProducts(list);
        getUser();
      }
    });
  };

  const getUser = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setUsers(list);
        getVehicle();
      }
    });
  };

  const getVehicle = () => {
    const args = {
      activeStatus: true,
    };
    MASTER_APIs.getVehicles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.vehicleNumber,
          };
        });
        setVehicles(list);
        getBranch();
      }
    });
  };

  const getBranch = () => {
    const args = {
      activeStatus: true,
    };
    MASTER_APIs.getBranchProfile(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setBranches(list);
        getScheme();
      }
    });
  };

  const getScheme = () => {
    const args = {
      activeStatus: true,
    };
    MASTER_APIs.getScheme(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setSchemes(list);
        getStockLoad();
      }
    });
  };

  const getStockLoad = () => {
    const args = {
      activeStatus: true,
    };
    TRANS_APIs.getStockLoad(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        // let list = response.stockLoad.map((r) => {
        //   return {
        //     id: r.id,
        //     label: r.name,
        //   };
        // });
        setStockLoad([]);
        getRows();
      }
    });
  };

  // const getOrderItems = () => {
  //   const args = {
  //     activeStatus: true,
  //   };
  //   TRANS_APIs.getOrders(args).then((res: any) => {
  //     const response: any = res.response;
  //     const { status } = res;
  //     if (status === 200) {
  //       let list = response.records.map((r) => {
  //         return {
  //           id: r.id,
  //           label: r.fullName,
  //         };
  //       });
  //       setStockLoad(list);
  //       getRows();
  //     }
  //   });
  // };

  const getRows = async () => {
    try {
      // const fromDate = (
      //   document.getElementById("fromDate") as HTMLInputElement
      // ).value.trim();
      // const toDate = (
      //   document.getElementById("toDate") as HTMLInputElement
      // ).value.trim();
      // const routeId = areasRef.current.selectedValue.id;
      const partyId = partyRef.current.selectedValue.id;
      setPartyId(partyId);
      // const productId = productRef.current.selectedValue.id;
      // const userId = userRef.current.selectedValue.id;
      const args = {
        // orderId: 2693,
        // pageNo: 1,
        // pageLength: DEFAULTS.PAGE_LENGTH,
        // fromDate,
        // toDate,
        // routeId,
        // partyId,
        // productId,
        // userId,
      };
      setFilter(args);
      setLoading(true);
      const res: any = await TRANS_APIs.getOrders(args);
      const { status, response } = res;
      if (status === 200) {
        // let list = response.records;
        // let tmpRows = [];
        // setRows([
        //   {
        //     id: "1",
        //     slNo: "1",
        //     orderNumber: "orderNumber",
        //     orderDate: "2023-09-29 10:15:05",
        //     items: "30",
        //     total: "10000",
        //     _className: "bgHighlight",
        //     _type: "maingrid",
        //     _expanded: false,
        //     _subGrid: () => {
        //       return (
        //         <ExcelGrid
        //           id={"salesOrderSubgrid"} //orderitemid
        //           cols={columns2}
        //           rows={[
        //             {
        //               type: "subgrid",
        //               id: "11",
        //               productName: "productName 1",
        //               price: "10",
        //               qty: "5",
        //               amount: "50",
        //             },
        //             {
        //               type: "subgrid",
        //               id: "12",
        //               productName: "productName 2",
        //               price: "20",
        //               qty: "10",
        //               amount: "200",
        //             },
        //           ]}
        //           isSubGrid={true}
        //         />
        //       );
        //     },
        //   },
        //   {
        //     id: "2",
        //     slNo: "2",
        //     orderNumber: "orderNumber 2",
        //     orderDate: "orderDate",
        //     items: "30",
        //     total: "20000",
        //     _type: "maingrid",
        //     _expanded: false,
        //     _subGrid: () => {
        //       return (
        //         <ExcelGrid
        //           id={"salesOrderSubgrid2"}
        //           cols={columns2}
        //           rows={[
        //             {
        //               id: "21",
        //               _type: "subgrid", // add this extra from backend
        //               productName: "productName 21",
        //               price: "10",
        //               qty: "5",
        //               amount: "50",
        //             },
        //             {
        //               id: "22",
        //               _type: "subgrid",
        //               productName: "productName 22",
        //               price: "20",
        //               qty: "10",
        //               amount: "200",
        //             },
        //           ]}
        //           isSubGrid={true}
        //         />
        //       );
        //     },
        //   },
        // ]);
        let list = response.records;
        // let tmpRows = [];
        // setRows([
        //   {
        //     id: "1",
        //     slNo: "1",
        //     orderNumber: "orderNumber",
        //     orderDate: "2023-09-29 10:15:05",
        //     items: "30",
        //     total: "10000",
        //     _className: "bgHighlight",
        //     _type: "maingrid",
        //     _expanded: false,
        //     _subGrid: () => {
        //       return (
        //         <ExcelGrid
        //           id={"salesOrderSubgrid"} //orderitemid
        //           cols={columns2}
        //           rows={[
        //             {
        //               type: "subgrid",
        //               id: "11",
        //               productName: "productName 1",
        //               price: "10",
        //               qty: "5",
        //               amount: "50",
        //             },
        //             {
        //               type: "subgrid",
        //               id: "12",
        //               productName: "productName 2",
        //               price: "20",
        //               qty: "10",
        //               amount: "200",
        //             },
        //           ]}
        //           isSubGrid={true}
        //         />
        //       );
        //     },
        //   },
        //   {
        //     id: "2",
        //     slNo: "2",
        //     orderNumber: "orderNumber 2",
        //     orderDate: "orderDate",
        //     items: "30",
        //     total: "20000",
        //     _type: "maingrid",
        //     _expanded: false,
        //     _subGrid: () => {
        //       return (
        //         <ExcelGrid
        //           id={"salesOrderSubgrid2"}
        //           cols={columns2}
        //           rows={[
        //             {
        //               id: "21",
        //               _type: "subgrid", // add this extra from backend
        //               productName: "productName 21",
        //               price: "10",
        //               qty: "5",
        //               amount: "50",
        //             },
        //             {
        //               id: "22",
        //               _type: "subgrid",
        //               productName: "productName 22",
        //               price: "20",
        //               qty: "10",
        //               amount: "200",
        //             },
        //           ]}
        //           isSubGrid={true}
        //         />
        //       );
        //     },
        //   },
        // ]);
        // let list2 = response.order;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          list[i]["_type"] = "maingrid";
          list[i]["_expanded"] = false;
          let subList = list[i].items;
          for (let j = 0; j < subList.length; j++) {
            subList[j]["slNo"] = j + 1;
          }
          list[i]["_subGrid"] = () => subGrid(subList);
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getOrders:", error);
    }
  };

  const onReset = () => {
    console.log("onReset:", salesOrderRef.getRowData());
  };

  const mangeSalesOrder = () => {
    // const orderNumber = (
    //   document.getElementById("orderNumber") as HTMLInputElement
    // ).value.trim();
    // const orderDate = (
    //   document.getElementById("orderDate") as HTMLInputElement
    // ).value.trim();
    // const areaId = areasRef.current.selectedValue.id;
    // const customerId = partyRef.current.selectedValue.id;
    // const vehicleId = vehicleRef.current.selectedValue.id;
    // const branchId = branchRef.current.selectedValue.id;
    // const stockLoadId = stockRef.current.selectedValue.id;
    // const productSkuId = productRef.current.selectedValue.id;
    // const schemeId = schemeRef.current.selectedValue.id;
    // const taxAmount = (
    //   document.getElementById("taxAmount") as HTMLInputElement
    // ).value.trim();
    // const total = (
    //   document.getElementById("total") as HTMLInputElement
    // ).value.trim();
    // const basic = (
    //   document.getElementById("basic") as HTMLInputElement
    // ).value.trim();
    // const remarks = (
    //   document.getElementById("remarks") as HTMLInputElement
    // ).value.trim();
    // const sold = (
    //   document.getElementById("sold") as HTMLInputElement
    // ).value.trim();
    // const soldSku = (
    //   document.getElementById("soldSku") as HTMLInputElement
    // ).value.trim();
    // const price = (
    //   document.getElementById("price") as HTMLInputElement
    // ).value.trim();
    // const orderItemBasic = (
    //   document.getElementById("orderItemBasic") as HTMLInputElement
    // ).value.trim();
    // const orderItemTaxAmount = (
    //   document.getElementById("orderItemTaxAmount") as HTMLInputElement
    // ).value.trim();
    // const orderItemTotal = (
    //   document.getElementById("orderItemTotal") as HTMLInputElement
    // ).value.trim();
    // const offers = (
    //   document.getElementById("offers") as HTMLInputElement
    // ).value.trim();
    // let ele = document.querySelector(
    //   'input[name="activeStatus"]:checked'
    // ) as HTMLInputElement;
    // const status = ele ? ele.value : "";

    // Validations;
    let errors = [];
    // if (countryName == "") {
    //   errors.push({
    //     id: "symbol",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.COUNTRY_NAME +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (countryCode == "") {
    //   errors.push({
    //     id: "symbol",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.COUNTRY_CODE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (countryDialCode == "") {
    //   errors.push({
    //     id: "code",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.COUNTRY_DIAL_CODE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // setMandatoryResults(errors);
    // if (errors.length > 0) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_CHECK_FORM_ERRORS
    //   );
    //   return;
    // }
    // const args = {
    //   orderId: selectedRow.id || -1,
    //   // orderNumber,
    //   customerId: 79,
    //   orderDate: "2023-09-29 10:15:05",
    //   areaId: 6306,
    //   taxAmount: "39",
    //   total: "100",
    //   basic: "50",
    //   status: true,
    //   remarks: "nothing",
    //   // vehicleId,
    //   branchId: 15,
    //   // stockLoadId,
    //   // orderItemId: selectedRow.id || -1,
    //   // productSkuId,
    //   // sold,
    //   // soldSku,
    //   // price,
    //   // orderItemBasic,
    //   // orderItemTaxAmount,
    //   // orderItemTotal,
    //   // items: [
    //   //   {
    //   //     productName: rows2[i].productName,
    //   //     quantity: rows2[i].quantity,
    //   //     price: rows2[i].price,
    //   //     mrp: rows2[i].mrp,
    //   //   },
    //   // ],
    //   // photos: [],
    //   // schemeId,
    //   // offers,
    // };

    // setIsSubmit(true);
    let gridRow = salesOrderChildRef.getRowData();
    // gridRow.push({ customerId: partyId });
    const args = gridRow[0];
    console.log("args", args);

    TRANS_APIs.manageOrder(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const viewSummary = (e) => {
    console.log(e);
  };

  const ordedrForm = (e) => {
    console.log(e);
  };

  const removeNegativeOrder = (e) => {
    console.log(e);
  };

  const generateBill = (e) => {
    console.log(e);
  };

  const subGrid = (subRows = []) => {
    console.log("subList:", subRows);
    return (
      <>
        <ExcelGrid
          id={"salesSubGrid"}
          childRef={salesOrderChildRef}
          cols={columns2}
          rows={subRows}
          isSubGrid={subRows.length !== 0 ? true : true}
          noTotalRows={true}
          onAdd={addProduct}
        />
      </>
    );
  };

  const addProduct = () => {
    setManageProductDialog(true);
  };

  const manageProduct = () => {};
  console.log("rows:", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.SALES}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.SALES_ORDER}</li>
        </ul>
        <h4>{TITLES.SALES_ORDER}</h4>
      </div>
      <div className="width50 filterBorder">
        <UiAccordion id={"filter"} title={LABELS.FILTER}>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.FROM_DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.TO_DATE}
                type={"date"}
                id="toDate"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.ROUTE}</label>
              <AutoComplete
                list={areas}
                childRef={areasRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Route" }
                }
              />
            </div>
          </div>

          <div className="flexRowAround" style={{ margin: 0, padding: 0 }}>
            <div className="formGroup width25">
              <label>{LABELS.PARTY}</label>
              <AutoComplete
                list={parties}
                childRef={partyRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Party" }
                }
              />
            </div>
            <div className=" width5" style={{ marginTop: 18 }}>
              <Tooltip title={LABELS.ADD_NEW_PARTY} placement="bottom">
                <IconButton
                  className="iconBtn"
                  onClick={(e) => {
                    console.log(e);
                  }}
                >
                  <AddCustomerIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="formGroup width30">
              <label>{LABELS.PRODUCT}</label>
              <AutoComplete
                list={products}
                childRef={productRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Product" }
                }
                onChange={(e, v) => {}}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.USER}</label>

              <AutoComplete
                list={users}
                childRef={userRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select User" }
                }
              />
            </div>
          </div>
          <div className="flexRowAround" style={{ margin: 0, padding: 0 }}>
            <div className="formGroup width60">
              <label>{LABELS.WANT_TO_PRINT_SALES_BILL_WITH}</label>
              <RadioBtn
                list={[
                  {
                    id: "invoice",
                    value: "Invoice",
                    label: LABELS.INVOICE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Invoice"
                      : true,
                    disabled: false,
                  },
                  {
                    id: "estimation",
                    value: "Estimation",
                    label: LABELS.ESTIMATION,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Estimation"
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                //disabled={isSubmit}
              />
            </div>
            <div
              className="formGroup width30"
              style={{ margin: 0, padding: 0 }}
            >
              <div className="flexbtnLoad" style={{ margin: 0, padding: 0 }}>
                <Btn
                  text={LABELS.RESET}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={onReset}
                  //loading={isSubmit}
                />
                <Btn
                  text={LABELS.LOAD}
                  onClick={getRows}
                  bgColor={COLORS.GRID_ADD_NEW}
                  // loading={isSubmit}
                />
              </div>
            </div>
          </div>
        </UiAccordion>
      </div>
      <div>
        {loading ? (
          <div className="grid excelGridWrapper">
            <Loader />
          </div>
        ) : (
          rows && (
            <>
              <div>
                <ExcelGrid
                  id={"salesOrder"}
                  childRef={salesOrderRef}
                  height={window.innerHeight * 0.7}
                  cols={columns}
                  rows={rows}
                  exportOption={true}
                  newRowOption={false}
                  hideUnhide={true}
                  // freezeOption={true}
                  exportName={LABELS.SALES_ORDER}
                  onReload={reloadGrid}
                  onAdd={onAdd}
                  leftSideWidth={"80%"}
                  rightSideWidth={"20%"}
                  leftSideBtns={
                    <>
                      <div className="excelBtnWrapper">
                        <Btn
                          text={LABELS.VIEW_SUMMARY}
                          onClick={viewSummary}
                          bgColor={COLORS.RELOAD_EXPORT}
                          color={COLORS.RELOAD_EXPORT_TEXT}
                        />
                      </div>
                      <div className="excelBtnWrapper">
                        <Btn
                          text={LABELS.ORDER_FROM}
                          onClick={ordedrForm}
                          bgColor={COLORS.RELOAD_EXPORT}
                          color={COLORS.RELOAD_EXPORT_TEXT}
                        />
                      </div>
                      <div className="excelBtnWrapper">
                        <Btn
                          text={LABELS.REMOVE_NEGATIVE_ORDER}
                          size={"medium"}
                          onClick={removeNegativeOrder}
                          bgColor={COLORS.RELOAD_EXPORT}
                          color={COLORS.RELOAD_EXPORT_TEXT}
                        />
                      </div>
                      <div className="excelBtnWrapper">
                        <Btn
                          text={LABELS.GENERATE_BILL}
                          onClick={generateBill}
                          bgColor={COLORS.RELOAD_EXPORT}
                          color={COLORS.RELOAD_EXPORT_TEXT}
                        />
                      </div>
                    </>
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  width: "96.5%",
                }}
              >
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Btn
                    text={LABELS.SAVE}
                    onClick={mangeSalesOrder}
                    marginRight={15}
                    bgColor={COLORS.GRID_ADD_NEW}
                    //  loading={isSubmit}
                  />
                </div>
              </div>
            </>
          )
        )}

        {manageDialog && (
          <UiDialog
            title={
              selectedRow.id
                ? "Edit " + TITLES.SALES_ORDER + ": " + selectedRow.id
                : "Add " + TITLES.SALES_ORDER
            }
            open={true}
            size={"xl"}
            onClose={() => {
              setManageDialog(false);
            }}
          >
            <AddSalesInvoice hideHeader={true} />
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                width: "96.5%",
              }}
            >
              <div
                style={{
                  width: "35%",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Btn
                  text={LABELS.SAVE}
                  onClick={mangeSalesOrder}
                  marginRight={15}
                  bgColor={COLORS.GRID_ADD_NEW}
                  //  loading={isSubmit}
                />
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => setManageDialog(false)}
                  // loading={isSubmit}
                />
              </div>
            </div> */}
          </UiDialog>
        )}

        {manageProductDialog && (
          <UiDialog
            title={
              selectedRow.id
                ? "Edit " + TITLES.PRODUCT + ": " + selectedRow.id
                : "Add " + TITLES.PRODUCT
            }
            open={true}
            size={"sm"}
            onClose={() => {
              setManageProductDialog(false);
            }}
          >
            <div className="formGroup ">
              <label>{LABELS.PRODUCT}</label>
              <AutoComplete
                list={products}
                childRef={productRef}
                defaultValue={
                  selectedRow.id
                    ? { id: selectedRow.id, label: selectedRow.label }
                    : { id: -1, label: "Select Product" }
                }
              />
            </div>
            <div className="formGroup ">
              <TxtRow id="quantity" label={LABELS.QUANTITY} type={"number"} />
            </div>

            <div className="formGroup submitBtnWrapper">
              <div className="flexbtnjustify">
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => {
                    setManageProductDialog(false);
                    setMandatoryResults([]);
                  }}
                />
                <Btn
                  text={LABELS.SAVE}
                  onClick={manageProduct}
                  bgColor={COLORS.SAVE_GREEN}
                />
              </div>
            </div>
          </UiDialog>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(SalesOrder);
