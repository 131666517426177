import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { MASTER_APIs } from "apihandlers/master";

const VehicleDriver = (props: any) => {
  const { setDashMenu } = props;

  const driverRef: any = useRef(null);
  const vehicleRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [vehicles, setVehicles] = useState<any>(null);
  const [drivers, setDrivers] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getVehicles();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getVehicleDrivers(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getRegions:", error);
    }
  };

  const getVehicles = () => {
    const args = { activeStatus: true };
    MASTER_APIs.getVehicles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.vehicleNumber,
          };
        });
        setVehicles(list);
        getDrivers();
      }
    });
  };

  const getDrivers = () => {
    const args = { activeStatus: true };
    MASTER_APIs.getDrivers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.driverName,
          };
        });
        setDrivers(list);
        getRows();
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "vehicleNumber",
      name: LABELS.VEHICLE_NUMBER,
      width: "40%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "driverName",
      name: LABELS.DRIVER_NAME,
      width: "30%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "createdOn",
      name: LABELS.CREATED_ON,
      width: "15%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: "false",
      sortable: "true",
      searchable: "true",
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRegion = () => {
    const driverId = driverRef.current.selectedValue.id;
    const vehicleId = vehicleRef.current.selectedValue.id;
    let active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";

    // Validations
    let errors = [];
    if (!driverId) {
      errors.push({
        id: "name",
        msg:
          "<span class='bold'>" +
          LABELS.DRIVER_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!vehicleId) {
      errors.push({
        id: "sortOrder",
        msg:
          "<span class='bold'>" +
          LABELS.VEHICLE_NUMBER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      branchId: 7,
      createdUserId: 16,
      vehicleId: vehicleId,
      driverId: driverId,
      activeStatus: selectBtnValue,
    };
    setIsSubmit(true);
    MASTER_APIs.manageVehicleDriver(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.VEHICLE_DRIVER}</li>
        </ul>
        <h4>{TITLES.VEHICLE_DRIVER}</h4>
        <p>{SUB_TITLES.VEHICLE_DRIVER}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.VEHICLE_DRIVER}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                LABELS.VEHICLE_DRIVER +
                ": " +
                selectedRow.vehicleNumber
              : "Add New " + LABELS.VEHICLE_DRIVER
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup  required">
            <label>
              {LABELS.VEHICLE_NUMBER}
              {<span>*</span>}
            </label>
            <AutoComplete
              list={vehicles}
              childRef={vehicleRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.vehicleId,
                      label: selectedRow.vehicleNumber,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
              required={true}
            />
          </div>
          <div className="formGroup required ">
            <label>
              {LABELS.DRIVER_NAME}
              {<span>*</span>}
            </label>
            <AutoComplete
              list={drivers}
              childRef={driverRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.driverId,
                      label: selectedRow.driverName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup required">
            <label>
              {LABELS.ACTIVE_STATUS} {<span>*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className=" flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageRegion}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(VehicleDriver);
