import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";

import { FORMATS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import { LABELS, TITLES, SUCCESS, ERRORS } from "common/lables";
import { COMMON_JS } from "common/scripts";

import { GEO_APIs } from "apihandlers/geography";
import { DateFunctions } from "common/datefunctions";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { USER_APIs } from "apihandlers/user";

const EXCEL_GRID_ID = "Survey";

const Instructions = (props: any) => {
  const { setDashMenu } = props;

  const stateRef: any = useRef(null);
  const regionRef: any = useRef(null);
  const countriesRef: any = useRef(null);
  const cityRef: any = useRef(null);
  const areaRef: any = useRef(null);
  const userRef: any = useRef(null);

  const customerRef: any = useRef(null);

  const customerTypeRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [states, setStates] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [regions, setRegions] = useState<any>(null);
  const [countries, setCountries] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [area, setArea] = useState<any>([]);
  const [user, setUser] = useState<any>([]);
  const [customer, setCustomer] = useState<any>([]);
  const [customerType, setCustomerType] = useState<any>([]);

  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getCountries();
    }
  }, [loading]);

  const getCountries = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };

    GEO_APIs.getCountries(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.countryName,
          };
        });
        setCountries(list);

        getRegion();
      }
    });
  };
  const getRegion = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getRegions(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.regionName,
          };
        });
        setRegions(list);
        getStates();
      }
    });
  };
  const getStates = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getStates(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.stateName,
          };
        });
        setStates(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await CUSTOMER_APIs.getInstructions(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
        getCities();
      }
    } catch (error) {
      console.error("getRegions:", error);
    }
  };

  const getCities = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getCities(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.cityName,
          };
        });
        setCity(list);
        getAreas();
      }
    });
  };
  const getAreas = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setArea(list);
        getUsers();
      }
    });
  };
  const getUsers = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setUser(list);
        getCustomerProfiles();
      }
    });
  };
  const getCustomerProfiles = () => {
    const args = {
      activeStatus: true,
    };
    CUSTOMER_APIs.getCustomerProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setCustomer(list);
        getCustomerTypes();
      }
    });
  };
  const getCustomerTypes = () => {
    const args = {
      activeStatus: true,
    };
    CUSTOMER_APIs.getCustomerTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setCustomerType(list);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };
  const reloadGrid = () => {
    setRows(null);
    getRows();
  };
  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "title",
      name: LABELS.TITLE,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "createdOn",
      name: LABELS.DATE,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "createdBy",
      name: LABELS.CREATED_USER,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "createdOn",
      name: LABELS.CREATED_ON,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "customerName",
      name: LABELS.CUSTOMER,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "customerType",
      name: LABELS.CUSTOMER_TYPE,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageInstructions = () => {
    const title = (
      document.getElementById("title") as HTMLInputElement
    ).value.trim();

    const createdOn = (
      document.getElementById("date") as HTMLInputElement
    ).value.trim();
    const instructions = (
      document.getElementById("instructions") as HTMLInputElement
    ).value.trim();

    const countryId = countriesRef.current.selectedValue.id;
    const regionId = regionRef.current.selectedValue.id;

    const stateId = stateRef.current.selectedValue.id;
    const cityId = cityRef.current.selectedValue.id;
    const areaId = areaRef.current.selectedValue.id;

    const createdUserId = userRef.current.selectedValue.id;

    // const customerId = customerRef.current.selectedValue.id;

    const customerTypeId = customerTypeRef.current.selectedValue.id;

    // let errors = [];
    // if (!nameOfHoliday) {
    //   errors.push({
    //     id: "name",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.NAME +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }

    // setMandatoryResults(errors);
    // if (errors.length > 0) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_CHECK_FORM_ERRORS
    //   );
    //   return;
    // }

    const args = {
      id: selectedRow.id || -1,
      title,
      createdOn,
      instructions,
      countryId,
      regionId,
      stateId,
      cityId,
      areaId,
      createdUserId,
      customerId: 63,
      customerTypeId,
    };
    console.log("args", args);
    setIsSubmit(true);
    CUSTOMER_APIs.manageInstruction(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          {/* <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li> */}
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.INSTRUCTIONS}</li>
        </ul>
        <h4>{TITLES.INSTRUCTIONS}</h4>
        {/* <p>{SUB_TITLES.REGION}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            id={EXCEL_GRID_ID}
            hideUnhide={true}
            freezeColumn={true}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.INSTRUCTIONS}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.INSTRUCTIONS + ": " + selectedRow.title
              : "Add New " + LABELS.INSTRUCTIONS
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup ">
            <TxtRow
              id={"title"}
              type={"text"}
              label={LABELS.TITLE}
              defaultValue={selectedRow.title}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup ">
            <TxtRow
              id={"date"}
              type={"date"}
              label={LABELS.DATE}
              defaultValue={
                selectedRow.createdOn
                  ? DateFunctions.getFormatedDate(
                      selectedRow.createdOn,
                      FORMATS.SQL_DATE,
                      FORMATS.SQL_DATE_ONLY
                    )
                  : DateFunctions.getDate(
                      "today",
                      "from",
                      FORMATS.SQL_DATE_ONLY
                    )
              }
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup ">
            <TxtRow
              id={"instructions"}
              type={"text"}
              label={LABELS.INSTRUCTIONS}
              defaultValue={selectedRow.instructions}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.COUNTRY}</label>
            <AutoComplete
              list={countries}
              childRef={countriesRef}
              defaultValue={
                selectedRow.countryId
                  ? {
                      id: selectedRow.countryId,
                      label: selectedRow.country,
                    }
                  : null
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>{LABELS.REGION}</label>

            <AutoComplete
              list={regions}
              childRef={regionRef}
              defaultValue={
                selectedRow.regionId
                  ? {
                      id: selectedRow.regionId,
                      label: selectedRow.regionName,
                    }
                  : null
              }
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup ">
            <label>{LABELS.STATE}</label>
            <AutoComplete
              list={states}
              childRef={stateRef}
              defaultValue={
                selectedRow.stateId
                  ? {
                      id: selectedRow.stateId,
                      label: selectedRow.state,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup ">
            <label>{LABELS.CITY}</label>
            <AutoComplete
              list={city}
              childRef={cityRef}
              defaultValue={
                selectedRow.cityId
                  ? {
                      id: selectedRow.cityId,
                      label: selectedRow.city,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup ">
            <label>{LABELS.AREA}</label>
            <AutoComplete
              list={area}
              childRef={areaRef}
              defaultValue={
                selectedRow.areaId
                  ? {
                      id: selectedRow.areaId,
                      label: selectedRow.area,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup ">
            <label>{LABELS.USER}</label>
            <AutoComplete
              list={user}
              childRef={userRef}
              defaultValue={
                selectedRow.createdUserId
                  ? {
                      id: selectedRow.createdUserId,
                      label: selectedRow.createdBy,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>{LABELS.CUSTOMER}</label>
            <AutoComplete
              list={customer}
              childRef={customerRef}
              defaultValue={
                selectedRow.customerId
                  ? {
                      id: selectedRow.customerId,
                      label: selectedRow.customerName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>{LABELS.CUSTOMER_TYPE}</label>
            <AutoComplete
              list={customerType}
              childRef={customerTypeRef}
              defaultValue={
                selectedRow.customerTypeId
                  ? {
                      id: selectedRow.customerTypeId,
                      label: selectedRow.customerType,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>

          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className="width45 flexRowJustify">
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageInstructions}
              />
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Instructions);
