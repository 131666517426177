/**
 * Lead Profile Creation
 */
import React, { useEffect, useState } from "react";
import { IconButton, Tooltip, Button, Dialog } from "@mui/material";
import {
  ArrowBackSharp as IconBack,
  Edit as IconEdit,
  CheckCircle as IconVerify,
  Person as IconAssign,
  Store as IconStoreVisit,
  Assignment as IconDocVerify,
  Forum as IconApprove,
  Verified as IconQualified,
  HowToReg as IconConverted,
  LocalPrintshop as IconPrint,
  AccessTimeFilled as IconPreVerify,
  Gavel as IconLegalDoc,
  Headset as IconInterview,
} from "@mui/icons-material";
import { withSnackbar } from "notistack";

import EditLeadProfile from "./EditLeadProfile";
import NewLeadProfile from "./NewLeadProfile";
import UiDialog from "components/Custom/UiDialog";
import HGraph from "components/Custom/HGraph";
import ExcelGrid from "components/Custom/ExcelGrid";
import Btn from "components/Custom/Btn";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import SkeletonBox from "components/Custom/SkeletonBox";

import { CRM_LEAD_STATUS, DEFAULTS, NOTIFY } from "common/config";
import { ERRORS, LABELS, SUCCESS, TITLES } from "common/lables";
import { CUSTOMER_APIs } from "apihandlers/customer";
import { CRM_APIs } from "apihandlers/crm";
import { COMMON_JS } from "common/scripts";
import { COLORS } from "common/colors";
import { GEO_APIs } from "apihandlers/geography";
import { PRINT_APIs } from "apihandlers/print";

interface defaultProps {}

const LeadProfile = (props: any) => {
  const { header, setDashMenu, setPageMenu } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [manageNewDialog, setManageNewDialog] = useState<boolean>(false);
  const [manageEditDialog, setManageEditDialog] = useState<boolean>(false);
  const [manageAssignDialog, setManageAssignDialog] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [columnChart, setColumnChart] = useState<any>({});
  const [rows, setRows] = useState<any[]>(null);
  const [filteredRows, setFilteredRows] = useState<any[]>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [areas, setAreas] = useState([]);
  const [manageVerifiedDialog, setManageVerifiedDialog] =
    useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const onAdd = () => {
    setManageNewDialog(!manageDialog);
    setSelectedRow({});
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageEditDialog(true);
  };

  const onVerify = async () => {
    const args = {
      crmLeadStatus: CRM_LEAD_STATUS.VERIFIED,
      activeStatus: "true",
      crmLeadId: selectedRow.id,
      crmLeadName: selectedRow.crmLeadName,
      isLeadVerified: "true",
    };
    const res = await CRM_APIs.manageCrmLeadProfile(args);
    if (res.status === 200) {
      COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
      getRows();
    } else {
      COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
    }
  };

  const onAssign = (row: any) => {
    setManageAssignDialog(row);
  };

  const onStoreVisit = (row: any) => {
    setSelectedRow(row);
    setManageEditDialog(true);
  };

  const onDocVerification = (row: any) => {
    setSelectedRow(row);
    setManageEditDialog(true);
  };

  const onApprovals = (row: any) => {
    setSelectedRow(row);
    setManageEditDialog(true);
  };

  const onConvert = (row: any) => {
    setSelectedRow(row);
    setManageEditDialog(true);
  };

  const onPrint = (type: string, row: any) => {
    switch (type) {
      case LABELS.PRINT_LEAD:
        leadPrint(row);
        break;
      case LABELS.PRINT_QUALIFY:
        leadQualifyPrint(row);
        break;
      default:
        break;
    }
  };

  const leadPrint = async (rowData: any) => {
    try {
      const args = {
        id: rowData.id,
      };
      const res = await PRINT_APIs.printLead(args);
      if (res) {
        if (res.status) {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
          return;
        }
        let base64 = "data:application/pdf;base64, " + res;
        fetch(base64).then((r) => {
          r.blob().then((data) => {
            let objUrl = window.URL.createObjectURL(data);
            window.open(objUrl, "_blank");
            window.URL.revokeObjectURL(objUrl);
          });
        });
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.PRINT_ERROR);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const leadQualifyPrint = async (rowData: any) => {
    try {
      const args = {
        id: rowData.id,
      };
      const res = await PRINT_APIs.printLeadQualify(args);
      if (res) {
        if (res.status) {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
          return;
        }
        let base64 = "data:application/pdf;base64, " + res;
        fetch(base64).then((r) => {
          r.blob().then((data) => {
            let objUrl = window.URL.createObjectURL(data);
            window.open(objUrl, "_blank");
            window.URL.revokeObjectURL(objUrl);
          });
        });
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.PRINT_ERROR);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [cols, setCols] = useState<any[]>([
    // {
    //   key: "select",
    //   name: "Select",
    //   width: "5%",
    //   selectColumn: true,
    // },
    {
      key: "crmLeadName",
      name: "Lead Name",
      type: "number",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "customerType",
      name: "Customer Type",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "state",
      name: LABELS.STATE,
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "district",
      name: LABELS.DISTRICT,
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdOn",
      name: "Created On",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "crmLeadStatus",
      name: "Lead Status",
      type: "dropdown",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
      filters: [
        {
          id: CRM_LEAD_STATUS.NEW_LEAD,
          name: CRM_LEAD_STATUS.NEW_LEAD,
        },
        {
          id: CRM_LEAD_STATUS.PRE_VERIFICATION,
          name: CRM_LEAD_STATUS.PRE_VERIFICATION,
        },
        {
          id: CRM_LEAD_STATUS.VERIFIED,
          name: CRM_LEAD_STATUS.VERIFIED,
        },
        {
          id: CRM_LEAD_STATUS.ASSIGNED,
          name: CRM_LEAD_STATUS.ASSIGNED,
        },
        {
          id: CRM_LEAD_STATUS.FIELD_VISIT,
          name: CRM_LEAD_STATUS.FIELD_VISIT,
        },
        {
          id: CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
          name: CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
        },
        {
          id: CRM_LEAD_STATUS.APPROVALS,
          name: CRM_LEAD_STATUS.APPROVALS,
        },
        {
          id: CRM_LEAD_STATUS.QUALIFIED,
          name: CRM_LEAD_STATUS.QUALIFIED,
        },
        {
          id: CRM_LEAD_STATUS.LEGAL_DOCUMENTS,
          name: CRM_LEAD_STATUS.LEGAL_DOCUMENTS,
        },
        {
          id: CRM_LEAD_STATUS.CONVERTED,
          name: CRM_LEAD_STATUS.CONVERTED,
        },
        {
          id: CRM_LEAD_STATUS.CUSTOMER,
          name: CRM_LEAD_STATUS.CUSTOMER,
        },
      ],
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "40%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => {
        let editBtn = true;
        let preLeadPrintBtn = null;
        let preVerificationBtn = null;
        let verifyBtn = null;
        let assignBtn = null;
        let fieldVisitBtn = null;
        let docBtn = null;
        let interviewBtn = null;
        let approvalBtn = null;
        let qualifiedBtn = null;
        let legalDocumentsBtn = null;
        let convertBtn = null;
        let qualifyLeadPrintBtn = null;
        switch (row.crmLeadStatus) {
          case CRM_LEAD_STATUS.NEW_LEAD:
            preLeadPrintBtn = true;
            preVerificationBtn = true;
            break;
          case CRM_LEAD_STATUS.PRE_VERIFICATION:
            preLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = true;
            break;
          case CRM_LEAD_STATUS.VERIFIED:
            preLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = true;
            break;
          case CRM_LEAD_STATUS.ASSIGNED:
            preLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = true;
            break;
          case CRM_LEAD_STATUS.FIELD_VISIT:
            preLeadPrintBtn = true;
            preVerificationBtn = false;
            qualifyLeadPrintBtn = true;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = false;
            docBtn = true;
            break;
          case CRM_LEAD_STATUS.DOCUMENT_VERIFICATION:
            preLeadPrintBtn = true;
            qualifyLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = false;
            docBtn = false;
            interviewBtn = true;
            break;
          case CRM_LEAD_STATUS.INTERVIEW_PROCESS:
            preLeadPrintBtn = true;
            qualifyLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = false;
            docBtn = false;
            interviewBtn = false;
            approvalBtn = true;
            break;
          case CRM_LEAD_STATUS.APPROVALS:
            preLeadPrintBtn = true;
            qualifyLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = false;
            docBtn = false;
            interviewBtn = false;
            approvalBtn = false;
            qualifiedBtn = true;
            break;
          case CRM_LEAD_STATUS.QUALIFIED:
            preLeadPrintBtn = true;
            qualifyLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = false;
            docBtn = false;
            approvalBtn = false;
            qualifiedBtn = false;
            legalDocumentsBtn = true;
            break;
          case CRM_LEAD_STATUS.LEGAL_DOCUMENTS:
            preLeadPrintBtn = true;
            qualifyLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = false;
            docBtn = false;
            approvalBtn = false;
            qualifiedBtn = false;
            convertBtn = false;
            legalDocumentsBtn = false;
            convertBtn = true;
            break;
          case CRM_LEAD_STATUS.CONVERTED:
            preLeadPrintBtn = true;
            qualifyLeadPrintBtn = true;
            preVerificationBtn = false;
            verifyBtn = false;
            assignBtn = false;
            fieldVisitBtn = false;
            docBtn = false;
            approvalBtn = false;
            qualifiedBtn = false;
            legalDocumentsBtn = false;
            convertBtn = false;
            break;
          default:
            break;
        }
        return (
          <div className="options">
            {/* Edit => All */}
            {editBtn && (
              <Tooltip title={LABELS.EDIT} placement="bottom">
                <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
                  <IconEdit />
                </IconButton>
              </Tooltip>
            )}

            {/* Pre-Verification => If "New Lead" */}
            {preVerificationBtn !== null ? (
              preVerificationBtn ? (
                <Tooltip
                  title={CRM_LEAD_STATUS.PRE_VERIFICATION}
                  placement="bottom"
                >
                  <IconButton
                    className="iconBtn"
                    onClick={() => {
                      setSelectedRow(row);
                      setManageEditDialog(true);
                    }}
                  >
                    <IconPreVerify />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={CRM_LEAD_STATUS.PRE_VERIFICATION}
                  placement="bottom"
                >
                  <IconButton className="iconBtn green" disableRipple>
                    <IconPreVerify />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {/* Verify => If "New Lead" */}
            {verifyBtn !== null ? (
              verifyBtn ? (
                <Tooltip title={CRM_LEAD_STATUS.VERIFIED} placement="bottom">
                  <IconButton
                    className="iconBtn"
                    onClick={() => {
                      setSelectedRow(row);
                      setManageVerifiedDialog(true);
                    }}
                  >
                    <IconVerify />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={CRM_LEAD_STATUS.VERIFIED} placement="bottom">
                  <IconButton className="iconBtn green" disableRipple>
                    <IconVerify />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {/* Assign => If "Verified" || "Assign" */}
            {assignBtn !== null ? (
              assignBtn ? (
                <Tooltip title={LABELS.ASSIGN} placement="bottom">
                  <IconButton
                    className="iconBtn"
                    onClick={() => {
                      setSelectedRow(row);
                      setManageEditDialog(true);
                    }}
                  >
                    <IconAssign />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={LABELS.ASSIGN} placement="bottom">
                  <IconButton className="iconBtn green" disableRipple>
                    <IconAssign />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {fieldVisitBtn !== null ? (
              fieldVisitBtn ? (
                <Tooltip title={LABELS.STORE_VISIT} placement="bottom">
                  <IconButton
                    className="iconBtn"
                    onClick={() => onStoreVisit(row)}
                  >
                    <IconStoreVisit />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={LABELS.STORE_VISIT} placement="bottom">
                  <IconButton className="iconBtn green" disableRipple>
                    <IconStoreVisit />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {docBtn !== null ? (
              docBtn ? (
                <Tooltip
                  title={CRM_LEAD_STATUS.DOCUMENT_VERIFICATION}
                  placement="bottom"
                >
                  <IconButton
                    className="iconBtn"
                    onClick={() => onDocVerification(row)}
                  >
                    <IconDocVerify />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={CRM_LEAD_STATUS.DOCUMENT_VERIFICATION}
                  placement="bottom"
                >
                  <IconButton className="iconBtn green" disableRipple>
                    <IconDocVerify />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {interviewBtn !== null ? (
              interviewBtn ? (
                <Tooltip
                  title={CRM_LEAD_STATUS.INTERVIEW_PROCESS}
                  placement="bottom"
                >
                  <IconButton
                    className="iconBtn"
                    onClick={() => {
                      setSelectedRow(row);
                      setManageEditDialog(true);
                    }}
                  >
                    <IconInterview />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={CRM_LEAD_STATUS.INTERVIEW_PROCESS}
                  placement="bottom"
                >
                  <IconButton className="iconBtn green" disableRipple>
                    <IconInterview />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {approvalBtn !== null ? (
              approvalBtn ? (
                <Tooltip title={CRM_LEAD_STATUS.APPROVALS} placement="bottom">
                  <IconButton
                    className="iconBtn"
                    onClick={() => onApprovals(row)}
                  >
                    <IconApprove />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={CRM_LEAD_STATUS.APPROVALS} placement="bottom">
                  <IconButton className="iconBtn green" disableRipple>
                    <IconApprove />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {qualifiedBtn !== null ? (
              qualifiedBtn ? (
                <Tooltip title={CRM_LEAD_STATUS.QUALIFIED} placement="bottom">
                  <IconButton
                    className="iconBtn"
                    onClick={() => onConvert(row)}
                  >
                    <IconQualified />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={CRM_LEAD_STATUS.QUALIFIED} placement="bottom">
                  <IconButton className="iconBtn green" disableRipple>
                    <IconQualified />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {legalDocumentsBtn !== null ? (
              legalDocumentsBtn ? (
                <Tooltip
                  title={CRM_LEAD_STATUS.LEGAL_DOCUMENTS}
                  placement="bottom"
                >
                  <IconButton
                    className="iconBtn"
                    onClick={() => {
                      setSelectedRow(row);
                      setManageEditDialog(true);
                    }}
                  >
                    <IconLegalDoc />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={CRM_LEAD_STATUS.LEGAL_DOCUMENTS}
                  placement="bottom"
                >
                  <IconButton className="iconBtn green" disableRipple>
                    <IconLegalDoc />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {convertBtn !== null ? (
              convertBtn ? (
                <Tooltip title={CRM_LEAD_STATUS.CONVERTED} placement="bottom">
                  <IconButton
                    className="iconBtn"
                    onClick={() => onConvert(row)}
                  >
                    <IconConverted />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={CRM_LEAD_STATUS.CONVERTED} placement="bottom">
                  <IconButton className="iconBtn green" disableRipple>
                    <IconConverted />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {/* Print */}
            {preLeadPrintBtn && (
              <Tooltip title={LABELS.PRINT_LEAD} placement="bottom">
                <IconButton
                  className="iconBtn"
                  onClick={() => onPrint(LABELS.PRINT_LEAD, row)}
                >
                  <IconPrint />
                </IconButton>
              </Tooltip>
            )}
            {qualifyLeadPrintBtn && (
              <Tooltip title={LABELS.PRINT_QUALIFY} placement="bottom">
                <IconButton
                  className="iconBtn"
                  onClick={() => onPrint(LABELS.PRINT_QUALIFY, row)}
                >
                  <IconPrint />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    getInitData();
  }, []);

  const getInitData = async () => {
    try {
      const res: any = await GEO_APIs.getAreas({ activeStatus: "true" });
      const areaList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.areaName,
        };
      });
      setAreas(areaList);
      getRows();
    } catch (error) {}
  };

  const getRows = async () => {
    try {
      const args = {
        activeStatus: "true",
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      setRows(null);
      const res: any = await CUSTOMER_APIs.getCrmLeadProfiles(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        setRows(list);
      }
    } catch (error) {
      console.error("getUserTypes:", error);
    }
  };

  const gridHeight = window.innerHeight * 0.9;

  return (
    <React.Fragment>
      <div className="userDashboard">
        {header && (
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li onClick={() => setPageMenu(LABELS.HOME)}>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="" onClick={() => setPageMenu(LABELS.HOME)}>
                {LABELS.CRM}
              </li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.LEAD_PROFILE}</li>
            </ul>
            <h4>{LABELS.LEAD_PROFILE}</h4>
          </div>
        )}

        {pieChart.series && (
          <div className="graphs">
            <HGraph
              width={"50%"}
              type={"column"}
              xAxis={columnChart.xAxis}
              yAxis={columnChart.yAxis}
              title={" "}
              exportTitle={" "}
            />
            <HGraph
              width={"50%"}
              type={"pie"}
              float={"right"}
              series={pieChart.series}
              title={" "}
              exportTitle={" "}
            />
          </div>
        )}

        <div
          style={{
            position: "relative",
          }}
        >
          {manageEditDialog && (
            <div
              className="flexColumn"
              style={{
                width: "98%",
                position: "absolute",
                top: 0,
                zIndex: 999,
                background: "#fff",
              }}
            >
              <div style={{ marginTop: 15 }}>
                <Btn
                  startIcon={<IconBack />}
                  text={LABELS.BACK}
                  style={{ marginBottom: 15 }}
                  onClick={() => {
                    setManageEditDialog(false);
                  }}
                  bgColor={COLORS.SAVE_GREEN}
                />
              </div>
              <EditLeadProfile
                selectedRow={selectedRow}
                areas={areas}
                onClose={() => {
                  getRows();
                  setManageEditDialog(false);
                }}
              />
            </div>
          )}
          <div style={{ opacity: manageEditDialog ? 0 : 1 }}>
            {rows ? (
              <ExcelGrid
                id={"leadProfile"}
                height={gridHeight}
                cols={cols}
                rows={rows}
                exportOption={true}
                hideUnhide={true}
                freezeColumn={true}
                newRowOption={false}
                exportName={LABELS.LEAD_PROFILE}
                onAdd={onAdd}
                onReload={getRows}
              />
            ) : (
              <div
                className="flexColumn"
                style={{ height: gridHeight, marginTop: 10, width: "98%" }}
              >
                <div
                  className="flexRow"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div className="flexRow" style={{ width: "25%" }}>
                    <SkeletonBox
                      height={30}
                      width={"45%"}
                      style={{ marginBottom: 5, marginRight: 10 }}
                    />
                    <SkeletonBox
                      height={30}
                      width={"45%"}
                      style={{ marginBottom: 5 }}
                    />
                  </div>
                  <div
                    className="flexRow"
                    style={{
                      width: "25%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <SkeletonBox
                      height={30}
                      width={"45%"}
                      style={{ marginBottom: 5, marginRight: 10 }}
                    />
                    <SkeletonBox
                      height={30}
                      width={"45%"}
                      style={{ marginBottom: 5 }}
                    />
                  </div>
                </div>
                <SkeletonBox height={gridHeight - 50} width={"100%"} />
              </div>
            )}
          </div>
        </div>

        {manageNewDialog && (
          <UiDialog
            open={true}
            title={LABELS.NEW_LEAD_PROFILE}
            size={"xl"}
            onClose={() => setManageNewDialog(false)}
          >
            <NewLeadProfile
              selectedRow={selectedRow}
              areas={areas}
              onClose={() => {
                setManageNewDialog(false);
                getRows();
              }}
            />
          </UiDialog>
        )}

        {manageVerifiedDialog && (
          <ConfirmDialog
            title={TITLES.CONFIRM_VERIFICATION}
            open={true}
            onClose={() => {
              setManageVerifiedDialog(false);
            }}
            onOk={() => {
              onVerify();
              setManageVerifiedDialog(false);
            }}
            size={"md"}
          >
            <p style={{ fontSize: "1.5rem" }}>
              {LABELS.CONFIRM_VERIFICATION_MSG}
            </p>
          </ConfirmDialog>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(LeadProfile);
