import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Btn from "components/Custom/Btn";

// Reports
import Report1 from "../Report1";

import ReportUserLog from "./ReportUserLog";
import ReportEmployeeLeaves from "./ReportEmployeeLeaves";
import CustomerOrders from "./CustomerOrders";
import AllLogsReport from "./AllLogsReport";
import UserLogReport from "./UserLogReport";
import RouteList from "./RouteList";
import PriceList from "./PriceList";
import ProductList from "./ProductList";
import LeadsReport from "./LeadsReport";
import FeedbackReport from "./FeedbackReport";
import InstructionsReport from "./InstructionsReport";
import SurveyReport from "./SurveyReport";
import CustomerList from "./CustomerList";
import CustomerInspectionReport from "./CustomerInspectionReport";
import LeavesReport from "./LeavesReport";
import AttendanceReport from "./AttendanceReport";
import CitiesListReport from "./CitiesListReport";
import TeamDetailReport from "./TeamDetailReport";
import RouteReport from "./RouteReport";
import RouteReports from "./RouteReports";
import AreaReport from "./AreaReport";
import RegionReport from "./RegionReport";

import BeatOrderReport from "./BeatOrderReport";
import OrderDetailReport from "./OrderDetailReport";
import SchemeReport from "./SchemeReport";
import SchemeUtilizationReport from "./SchemeUtilizationReport";
import TargetReport from "./TargetReport";
import TargetStatusReport from "./TargetStatusReport";
import TargetTotalReport from "./TargetTotalReport";
import CategorySaleReport from "./CategorySaleReport";
import MainCategoryWiseSaleReport from "./MainCategoryWiseSaleReport";
import SubCategoryWiseSaleReport from "./SubCategoryWiseSaleReport";
import SkuWiseSaleReport from "./SkuWiseSaleReport";
import StateWiseSaleReport from "./StateWiseSaleReport";
import RegionWiseSaleReport from "./RegionWiseSaleReport";
import CityWiseSaleReport from "./CityWiseSaleReport";
import AreaWiseSalesReport from "./AreaWiseSalesReport";
import CustomerWiseSalesReport from "./CustomerWiseSalesReport";
import CustomerTypeWiseSalesReport from "./CustomerTypeWiseSalesReport";
import LocationUpdate from "./LocationUpdate";
import CurrentStockWithDetails from "../DMSReport/CurrentStockWithDetails";

import { CONFIG } from "common/config";
import { LABELS } from "common/lables";

import "./dashboard.css";
import "./styles/reports.css";
import "./styles/datatables.min.css";
import "devextreme/dist/css/dx.light.css";

const employeeLeavesReport = require("assets/images/reports/employeeleavesreport.png");
const chart1 = require("assets/images/reports/chart1.png");
const chartResourceHistogram = require("assets/images/reports/Resource_Histogram.png");
const chartResourceTable = require("assets/images/reports/Resource_Table.png");
const chartManHoursPlan = require("assets/images/reports/Man_Hours_Distribution.png");
const chartProgressCurve = require("assets/images/reports/Progress_S_Curve.png");
const chartProjectCashFlow = require("assets/images/reports/Project_Cashflow.png");
const chartProgressStatus = require("assets/images/reports/Project_Progress_Pie.png");
const chartProjectOverview = require("assets/images/reports/Project_Overview.png");
const chartRisksRegister = require("assets/images/reports/Risk_Register.png");
const chartEarnedValue = require("assets/images/reports/Earned_Value_Report.png");
const chartQuantitiesReport = require("assets/images/reports/Quantities_Report.png");
const chartManhoursReport = require("assets/images/reports/Manhours_Report.png");
const chartCostReport = require("assets/images/reports/Cost_Report.png");
const chartConstraintLogReport = require("assets/images/reports/Constraint_Log_Report.png");
const chartTimeSheetLog = require("assets/images/reports/Time_Sheet_Log_Report.png");
const chartApprovalLog = require("assets/images/reports/Approval_Log_Report.png");
const userLogreport = require("assets/images/reports/User_Log_Report.png");
const invoicereport = require("assets/images/reports/invoicereport.png");
const analysisReport = require("assets/images/reports/projectsreport.png");

const Reports = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.REPORTS}</li>
            </ul>
            <h4>{LABELS.REPORTS}</h4>
          </div>

          <MenuList dense className="dashboardMenuWrap">
            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.GEO_REPORT);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.GEO_REPORT}</h4>
            </MenuItem> */}

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.REPORT1);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.REPORT1}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CUSTOMER_ORDERS);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.CUSTOMER_ORDERS}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.ROUTE_LIST_REPORT);
              }}
            >
              <img src={chartConstraintLogReport} />
              <h4>{LABELS.ROUTE_LIST_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PRICE_LIST_REPORT);
              }}
            >
              <img src={chartQuantitiesReport} />
              <h4>{LABELS.PRICE_LIST_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PRODUCT_LIST_REPORT);
              }}
            >
              <img src={chartQuantitiesReport} />
              <h4>{LABELS.PRODUCT_LIST_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.LEADS_REPORT);
              }}
            >
              <img src={chartConstraintLogReport} />
              <h4>{LABELS.LEADS_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.FEEDBACK_REPORT);
              }}
            >
              <img src={chartRisksRegister} />
              <h4>{LABELS.FEEDBACK_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.INSTRUCTION_REPORT);
              }}
            >
              <img src={chartTimeSheetLog} />
              <h4>{LABELS.INSTRUCTION_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SURVEY_REPORT);
              }}
            >
              <img src={chartRisksRegister} />
              <h4>{LABELS.SURVEY_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CUSTOMER_LIST_REPORT);
              }}
            >
              <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60.000000pt"
                      height="100.000000pt"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      {" "}
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#0078b2"
                        stroke="none"
                      >
                        {" "}
                        <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113 -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37 -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166 -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19 -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14 58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630 295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229 3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298 816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57 62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168 29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31 c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0 -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62 -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74 792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608 3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21 -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6 1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130 -124 c72 -68 175 -165 228 -216z" />{" "}
                        <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108 0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z" />{" "}
                        <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3 1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104 -1 -1126 -18z" />{" "}
                        <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24 25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z" />{" "}
                        <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0 20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0 -27 -23z" />{" "}
                        <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29 34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z" />{" "}
                      </g>{" "}
                    </svg>
              <h4>{LABELS.CUSTOMER_LIST_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.LEAVES_REPORT);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.LEAVES_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.ATTENDANCE_REPORT);
              }}
            >
              <img src={chartTimeSheetLog} />
              <h4>{LABELS.ATTENDANCE_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CITIES_LIST_REPORT);
              }}
            >
              <img src={userLogreport} />
              <h4>{LABELS.CITIES_LIST_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TEAM_DETAIL_REPORT);
              }}
            >
              <img src={chartResourceHistogram} />
              <h4>{LABELS.TEAM_DETAIL_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.BEAT_LIST_REPORT);
              }}
            >
              <img src={chartConstraintLogReport} />
              <h4>{LABELS.BEAT_LIST_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.BEAT_LOG_REPORT);
              }}
            >
              <img src={chartConstraintLogReport} />
              <h4>{LABELS.BEAT_LOG_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.ORDER_DETAIL_REPORT);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.ORDER_DETAIL_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SCHEMES_DETAIL_REPORT);
              }}
            >
              <img src={chartEarnedValue} />
              <h4>{LABELS.SCHEMES_DETAIL_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TARGET_DETAIL_REPORT);
              }}
            >
              <img src={invoicereport} />
              <h4>{LABELS.TARGET_DETAIL_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SCHEME_UTILIZATION_REPORT);
              }}
            >
              <img src={chartProgressCurve} />
              <h4>{LABELS.SCHEME_UTILIZATION_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TARGET_ACHIEVED_REPORT);
              }}
            >
              <img src={chartCostReport} />
              <h4>{LABELS.TARGET_ACHIEVED_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TARGET_ACHIEVED_TOTAL_REPORT);
              }}
            >
              <img src={chartCostReport} />
              <h4>{LABELS.TARGET_ACHIEVED_TOTAL_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CATEGORY_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.CATEGORY_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.MAIN_CATEGORY_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.MAIN_CATEGORY_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SUB_CATEGORY_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.SUB_CATEGORY_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PRODUCT_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.PRODUCT_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.STATE_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.STATE_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.REGION_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.REGION_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CITY_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.CITY_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.AREA_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.AREA_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CUSTOMER_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.CUSTOMER_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CUSTOMER_TYPE_WISE_SALES);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.CUSTOMER_TYPE_WISE_SALES}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.USER_LOG_REPORT);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.USER_LOG_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.ALL_LOGS_REPORT);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.ALL_LOGS_REPORT}</h4>
            </MenuItem>
          <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.LOCATION_UPDATE);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.LOCATION_UPDATE}</h4>
            </MenuItem>*/}
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.REGION_REPORT);
              }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60.000000pt"
                height="100.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                {" "}
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#0078b2"
                  stroke="none"
                >
                  {" "}
                  <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113 -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37 -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166 -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19 -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14 58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630 295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229 3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298 816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57 62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168 29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31 c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0 -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62 -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74 792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608 3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21 -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6 1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130 -124 c72 -68 175 -165 228 -216z" />{" "}
                  <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108 0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z" />{" "}
                  <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3 1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104 -1 -1126 -18z" />{" "}
                  <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24 25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z" />{" "}
                  <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0 20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0 -27 -23z" />{" "}
                  <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29 34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z" />{" "}
                </g>{" "}
              </svg>
              <h4>{LABELS.REGION_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.AREA_REPORT);
              }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60.000000pt"
                height="100.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                {" "}
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#0078b2"
                  stroke="none"
                >
                  {" "}
                  <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113 -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37 -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166 -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19 -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14 58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630 295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229 3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298 816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57 62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168 29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31 c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0 -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62 -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74 792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608 3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21 -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6 1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130 -124 c72 -68 175 -165 228 -216z" />{" "}
                  <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108 0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z" />{" "}
                  <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3 1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104 -1 -1126 -18z" />{" "}
                  <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24 25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z" />{" "}
                  <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0 20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0 -27 -23z" />{" "}
                  <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29 34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z" />{" "}
                </g>{" "}
              </svg>
              <h4>{LABELS.AREA_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.ROUTE_REPORT);
              }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60.000000pt"
                height="100.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                {" "}
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#0078b2"
                  stroke="none"
                >
                  {" "}
                  <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113 -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37 -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166 -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19 -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14 58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630 295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229 3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298 816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57 62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168 29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31 c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0 -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62 -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74 792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608 3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21 -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6 1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130 -124 c72 -68 175 -165 228 -216z" />{" "}
                  <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108 0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z" />{" "}
                  <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3 1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104 -1 -1126 -18z" />{" "}
                  <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24 25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z" />{" "}
                  <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0 20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0 -27 -23z" />{" "}
                  <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29 34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z" />{" "}
                </g>{" "}
              </svg>
              <h4>{LABELS.ROUTE_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CUSTOMER_LIST_REPORT);
              }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60.000000pt"
                height="100.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                {" "}
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#0078b2"
                  stroke="none"
                >
                  {" "}
                  <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113 -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37 -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166 -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19 -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14 58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630 295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229 3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298 816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57 62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168 29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31 c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0 -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62 -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74 792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608 3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21 -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6 1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130 -124 c72 -68 175 -165 228 -216z" />{" "}
                  <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108 0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z" />{" "}
                  <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3 1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104 -1 -1126 -18z" />{" "}
                  <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24 25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z" />{" "}
                  <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0 20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0 -27 -23z" />{" "}
                  <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29 34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z" />{" "}
                </g>{" "}
              </svg>
              <h4>{LABELS.CUSTOMER_LIST_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CUSTOMER_INSPECTION_REPORT);
              }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60.000000pt"
                height="100.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                {" "}
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#0078b2"
                  stroke="none"
                >
                  {" "}
                  <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113 -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37 -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166 -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19 -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14 58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630 295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229 3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298 816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57 62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168 29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31 c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0 -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62 -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74 792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608 3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21 -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6 1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130 -124 c72 -68 175 -165 228 -216z" />{" "}
                  <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108 0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z" />{" "}
                  <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3 1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104 -1 -1126 -18z" />{" "}
                  <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24 25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z" />{" "}
                  <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0 20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0 -27 -23z" />{" "}
                  <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29 34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z" />{" "}
                </g>{" "}
              </svg>
              <h4>{LABELS.CUSTOMER_INSPECTION_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CURRENT_STOCK_WITH_DETAILS);
              }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60.000000pt"
                height="100.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                {" "}
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#0078b2"
                  stroke="none"
                >
                  {" "}
                  <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113 -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37 -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166 -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19 -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14 58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630 295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229 3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298 816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57 62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168 29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31 c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0 -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62 -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74 792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608 3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21 -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6 1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130 -124 c72 -68 175 -165 228 -216z" />{" "}
                  <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108 0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z" />{" "}
                  <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3 1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104 -1 -1126 -18z" />{" "}
                  <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24 25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z" />{" "}
                  <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0 20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0 -27 -23z" />{" "}
                  <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29 34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z" />{" "}
                </g>{" "}
              </svg>
              <h4>{LABELS.CURRENT_STOCK_WITH_DETAILS}</h4>
            </MenuItem>
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.GEO_REPORT && (
        <ReportEmployeeLeaves setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.USER_LOG_REPORT && (
        <UserLogReport setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.REPORT1 && <Report1 setDashMenu={setDashMenu} />}

      {dashMenu == LABELS.CUSTOMER_ORDERS && (
        <CustomerOrders setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.ALL_LOGS_REPORT && (
        <AllLogsReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.ROUTE_LIST_REPORT && (
        <RouteList setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.PRICE_LIST_REPORT && (
        <PriceList setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.PRODUCT_LIST_REPORT && (
        <ProductList setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.LEADS_REPORT && (
        <LeadsReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.FEEDBACK_REPORT && (
        <FeedbackReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.INSTRUCTION_REPORT && (
        <InstructionsReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.SURVEY_REPORT && (
        <SurveyReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CUSTOMER_LIST_REPORT && (
        <CustomerList setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CUSTOMER_INSPECTION_REPORT && (
        <CustomerInspectionReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.ROUTE_REPORT && (
        <AreaReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.AREA_REPORT && (
        <RouteReports setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.REGION_REPORT && (
        <RegionReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.LEAVES_REPORT && (
        <LeavesReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.ATTENDANCE_REPORT && (
        <AttendanceReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CITIES_LIST_REPORT && (
        <CitiesListReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TEAM_DETAIL_REPORT && (
        <TeamDetailReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.BEAT_LIST_REPORT && (
        <RouteReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.BEAT_LOG_REPORT && (
        <BeatOrderReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.ORDER_DETAIL_REPORT && (
        <OrderDetailReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.SCHEMES_DETAIL_REPORT && (
        <SchemeReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TARGET_DETAIL_REPORT && (
        <TargetReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.SCHEME_UTILIZATION_REPORT && (
        <SchemeUtilizationReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TARGET_ACHIEVED_REPORT && (
        <TargetStatusReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TARGET_ACHIEVED_TOTAL_REPORT && (
        <TargetTotalReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CATEGORY_WISE_SALES && (
        <CategorySaleReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.MAIN_CATEGORY_WISE_SALES && (
        <MainCategoryWiseSaleReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.SUB_CATEGORY_WISE_SALES && (
        <SubCategoryWiseSaleReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.PRODUCT_WISE_SALES && (
        <SkuWiseSaleReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.STATE_WISE_SALES && (
        <StateWiseSaleReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.REGION_WISE_SALES && (
        <RegionWiseSaleReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CITY_WISE_SALES && (
        <CityWiseSaleReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.AREA_WISE_SALES && (
        <AreaWiseSalesReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CUSTOMER_WISE_SALES && (
        <CustomerWiseSalesReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CUSTOMER_TYPE_WISE_SALES && (
        <CustomerTypeWiseSalesReport setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.LOCATION_UPDATE && (
        <LocationUpdate setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.CURRENT_STOCK_WITH_DETAILS && (
        <CurrentStockWithDetails
          setDashMenu={setDashMenu}
          userInfo={userInfo}
        />
      )}
    </React.Fragment>
  );
};

export default Reports;
