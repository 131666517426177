// * Note: Please collect location info & logtime of each and every API Requests

// import axios from 'axios';

import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7006";

export const PRODUCT_APIs = {
  getProductsByPrice: async (args = {}) => {
    let apiUrl = "/pr/v1/T8T38HI7KBFFC1R3";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Taxes
  manageTaxMaster: async (args = {}) => {
    let apiUrl = "/pr/v1/457Q8623Y97241XY";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/457Q8623Y97241XY";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Taxes
  getTaxMaster: async (args = {}) => {
    let apiUrl = "/pr/v1/4QSNH29YUE21K4FR";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/4QSNH29YUE21K4FR";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Grams
  manageGramsMaster: async (args = {}) => {
    let apiUrl = "/pr/v1/06I87M9Y6BVO1C5W";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/06I87M9Y6BVO1C5W";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get grams
  getGramsMaster: async (args = {}) => {
    let apiUrl = "/pr/v1/VT7O00HRCUCUAS6S";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/VT7O00HRCUCUAS6S";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Product Main Category
  manageProductMainCategory: async (args = {}) => {
    let apiUrl = "/pr/v1/Z4SREC40LMEM2AIJ";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/Z4SREC40LMEM2AIJ";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Product Main Categories
  getProductMainCategory: async (args = {}) => {
    let apiUrl = "/pr/v1/JJ2CJ8EWPCROIQ4G";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/JJ2CJ8EWPCROIQ4G";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Product Sub Category
  manageProductSubCategory: async (args = {}) => {
    let apiUrl = "/pr/v1/XLK1MUKBSPW5Z54H";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/XLK1MUKBSPW5Z54H";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Product Sub Categories
  getProductSubCategory: async (args = {}) => {
    let apiUrl = "/pr/v1/QLB97Y5PYSI2R3E4";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/QLB97Y5PYSI2R3E4";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Product Sub Category
  manageProductTypeCategory: async (args = {}) => {
    let apiUrl = "/pr/v1/SHVX2KQJ5MSIKPXM";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/SHVX2KQJ5MSIKPXM";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Product Sub Categories
  getProductTypeCategory: async (args = {}) => {
    let apiUrl = "/pr/v1/FPNEXYZWYW1WMB65";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/FPNEXYZWYW1WMB65";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Product
  manageProduct: async (args = {}) => {
    let apiUrl = "/pr/v1/GMT1IXZYIH1NO17B";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/GMT1IXZYIH1NO17B";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get products
  getProducts: async (args = {}) => {
    let apiUrl = "/pr/v1/F2FV0ISUJ62Z6II9";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/F2FV0ISUJ62Z6II9";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Adding/Editing Product SKU
  manageProductSku: async (args = {}) => {
    let apiUrl = "/pr/v1/Z1F41898PIAVBGH5";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/Z1F41898PIAVBGH5";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get products SKU
  getProductsSku: async (args = {}) => {
    let apiUrl = "/pr/v1/31XTUQVIBM99HA39";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  //Get New Category

  getProductNewCategories: async (args = {}) => {
    let apiUrl = "/pr/v1/6JV93SCE70UCQ4HJ";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/6JV93SCE70UCQ4HJ";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageProductNewCategory: async (args = {}) => {
    let apiUrl = "/pr/v1/8621U1VDDCC0E5Z1";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/8621U1VDDCC0E5Z1";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  managePrice: async (args = {}, accessToken = "") => {
    let apiUrl = "/pr/v1/ASAHHP0UGMYS2AER";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/ASAHHP0UGMYS2AER";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getPrices: async (args = {}) => {
    let apiUrl = "/pr/v1/2ZAQDFNKYUT3J4O4";
    if (local) {
      apiUrl = "http://192.168.0.115:7006/pr/v1/2ZAQDFNKYUT3J4O4";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getTypeCategoryId: async (args = {}) => {
    let apiUrl = "/pr/v1/FPNEXYZWYW1WMB65";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
};
