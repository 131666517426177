import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "../../../components/Custom/UiDialog";
import RadioBtn from "../../../components/Custom/RadioBtn";
import Btn from "../../../components/Custom/Btn";
import SwitchBtn from "../../../components/Custom/SwitchBtn";
import AutoComplete from "../../../components/Custom/AutoComplete";
import Loader from "../../../components/Custom/Loader";
import ExcelGrid from "../../Custom/ExcelGrid";

import { CONFIG, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "../../../components/Custom/TxtRow";

import { MASTER_APIs } from "apihandlers/master";
import { USER_APIs } from "apihandlers/user";

const Leaves = (props: any) => {
  const { setDashMenu } = props;

  const userNameRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const [fileId, setFileId] = useState<any>();
  const [Leaves, setLeaves] = useState<any>();

  const leaveTypeRef: any = useRef(null);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getMktngLeaves(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getMktngLeaves:", error);
    }
    getUsers();
  };
  const getUsers = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.userType,
          };
        });
        setUser(list);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };
  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "leaveName",
      name: LABELS.LEAVE_NAME,
      width: "30%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "userTypeName",
      name: LABELS.USER_TYPE,
      width: "25%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "totalMonthlyLeaves",
      name: LABELS.TOTAL_MONTHLY_LEAVES,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "totalYearlyleaves",
      name: LABELS.TOTAL_YEARLY_LEAVES,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },

    {
      key: "options",
      name: "Options",
      width: "10%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageMktngLeave = () => {
    const leaveName = (
      document.getElementById("leaveName") as HTMLInputElement
    ).value.trim();
    // const (!userTypeId) = (
    //   document.getElementById("userTypeId") as HTMLInputElement
    // ).value.trim();
    const userTypeId = userNameRef.current.selectedValue.id;
    const totalMonthlyleaves = (
      document.getElementById("totalMonthlyleaves") as HTMLInputElement
    ).value.trim();
    const totalYearlyleaves = (
      document.getElementById("totalYearlyleaves") as HTMLInputElement
    ).value.trim();

    // Validations

    const args = {
      id: selectedRow.id || -1,
      leaveName,
      userTypeId,
      totalMonthlyleaves,
      totalYearlyleaves,
      approvalRulesDetails: [{ days: " ", reason: " " }], //Todo JSON input Arguments
    };
    console.log("args", args);
    setIsSubmit(true);
    MASTER_APIs.manageMktngLeave(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.LEAVES}</li>
        </ul>
        <h4>{LABELS.LEAVES}</h4>
        <p>{SUB_TITLES.LEAVES}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.LEAVES}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.LEAVES + ": " + selectedRow.leaveName
              : "Add New " + LABELS.LEAVES
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup required">
            <TxtRow
              id={"leaveName"}
              type={"text"}
              label={LABELS.LEAVE_NAME}
              required={true}
              defaultValue={selectedRow.leaveName}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup required">
            <label>{LABELS.USER_TYPE}</label>
            <AutoComplete
              list={user}
              childRef={userNameRef}
              defaultValue={
                selectedRow.userTypeName
                  ? {
                      id: selectedRow.userTypeId,
                      label: selectedRow.userTypeName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
              required={true}
            />
          </div>

          <div className="formGroup required">
            <TxtRow
              id={"totalMonthlyleaves"}
              type={"number"}
              label={LABELS.TOTAL_MONTHLY_LEAVES}
              required={true}
              defaultValue={selectedRow.totalMonthlyleaves}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup required">
            <TxtRow
              id={"totalYearlyleaves"}
              type={"number"}
              label={LABELS.TOTAL_YEARLY_LEAVES}
              required={true}
              defaultValue={selectedRow.totalYearlyleaves}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup submitBtnWrapper">
            <div className=" flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => setManageDialog(false)}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageMktngLeave}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Leaves);
