/*
 * Google Street View
 * see https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
 */
import React, { useState } from "react";
import ReactStreetview from "react-streetview";

// see https://developers.google.com/maps/documentation/javascript
const GOOGLE_MAPS_API_KEY = "AIzaSyBvuPV_NBMRG1OLDntdJvarJsWFhzgPjbs"; // neyaai2179@gmail.com

interface defaultProps {
  childRef: any;
  height: number;
  position: any;
  onPositionChanged: Function;
}

const StreetView = (props: defaultProps) => {
  const { childRef, height, position, onPositionChanged } = props;
  const [streetPos, setStreetPos] = useState<any>(position);
  const updateStreetPosition = (pos: any) => {
    setStreetPos(pos);
  };

  if (childRef) {
    childRef.updateStreetPosition = updateStreetPosition;
  }

  console.log("center: position:", streetPos);

  return (
    <div style={{ height }}>
      {streetPos && (
        <ReactStreetview
          apiKey={GOOGLE_MAPS_API_KEY}
          streetViewPanoramaOptions={{
            position: streetPos,
            pov: { heading: 100, pitch: 0 },
            zoom: 1,
          }}
          onPositionChanged={onPositionChanged}
          // onPovChanged={(e, f) => {
          //   console.log("onPovChanged:", e);
          // }}
        >
          {/* Child Components */}
        </ReactStreetview>
      )}
    </div>
  );
};

export default StreetView;
