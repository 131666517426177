import React, { useState } from "react";
import { withSnackbar } from "notistack";

import TxtRow from "components/Custom/TxtRow";
import Btn from "components/Custom/Btn";
import RadioBtn from "components/Custom/RadioBtn";

import { LABELS, SUCCESS } from "common/lables";
import { COLORS } from "common/colors";
import { CRM_LEAD_STATUS, NOTIFY } from "common/config";
import { COMMON_JS } from "common/scripts";
import { CRM_APIs } from "apihandlers/crm";

const PreVerification = (props: any) => {
  const { onClose, selectedRow, isButtonDisabled } = props;

  const capitalDetails = selectedRow.capitalDetails
    ? JSON.parse(selectedRow.capitalDetails)
    : {};

  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const saveRecord = async () => {
    try {
      // Business Details
      let workStatus = document.querySelector(
        'input[name="currentWorkStatus"]:checked'
      ) as HTMLInputElement;
      const currentWorkStatus = workStatus ? workStatus.value : "";
      const yearOfExperince = (
        document.getElementById("yearOfExperince") as HTMLInputElement
      ).value.trim();
      const monthlyTurnover = (
        document.getElementById("monthlyTurnover") as HTMLInputElement
      ).value.trim();
      const currentWorkDetails = (
        document.getElementById("currentWorkDetails") as HTMLInputElement
      ).value.trim();

      // Infrastructure
      let op = document.querySelector(
        'input[name="warehouse"]:checked'
      ) as HTMLInputElement;
      const warehouse = op ? op.value : "";
      op = document.querySelector(
        'input[name="vehicle"]:checked'
      ) as HTMLInputElement;
      const vehicle = op ? op.value : "";
      const sqFt = (
        document.getElementById("sqFt") as HTMLInputElement
      ).value.trim();
      const noOfVehicle = (
        document.getElementById("noOfVehicle") as HTMLInputElement
      ).value.trim();

      // Other
      op = document.querySelector(
        'input[name="gstAvailable"]:checked'
      ) as HTMLInputElement;
      const gstAvailable = op ? op.value : "";
      op = document.querySelector(
        'input[name="foodLicence"]:checked'
      ) as HTMLInputElement;
      const foodLicence = op ? op.value : "";
      const depositRemarks = (
        document.getElementById("depositRemarks") as HTMLInputElement
      ).value.trim();
      const remarks = (
        document.getElementById("remarks") as HTMLInputElement
      ).value.trim();

      const workingCapital = (
        document.getElementById("workingCapital") as HTMLInputElement
      ).value.trim();

      const businessDetails = {
        currentWorkStatus,
        yearOfExperince,
        monthlyTurnover,
        currentWorkDetails,
      };

      const infrastructure = {
        warehouse,
        vehicle,
        sqFt,
        noOfVehicle,
      };

      const other = {
        gstAvailable,
        foodLicence,
        depositRemarks,
        remarks,
      };

      const args = {
        crmLeadId: selectedRow.id,
        crmLeadStatus: CRM_LEAD_STATUS.PRE_VERIFICATION,
        crmLeadName: selectedRow.crmLeadName,
        activeStatus: selectedRow.activeStatus,
        preVerificationDetails: { businessDetails, infrastructure, other },
        capitalDetails: {
          ...JSON.parse(selectedRow.capitalDetails),
          workingCapital,
        },
      };
      setIsSubmit(true);
      const res: any = await CRM_APIs.manageCrmLeadProfile(args);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        onClose(true);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
      setIsSubmit(false);
    } catch (error) {
      setIsSubmit(false);
      console.log("saveRecord: error:", error);
    }
  };

  const preVerification = selectedRow.preVerificationDetails
    ? JSON.parse(selectedRow.preVerificationDetails)
    : {};
  const businessDetails: any = preVerification.businessDetails || {};
  const infrastructure: any = preVerification.infrastructure || {};
  const other: any = preVerification.other || {};

  console.log("preVerification:", preVerification);

  return (
    <div>
      <fieldset className="formSection">
        <legend className="formSectionLegend">{LABELS.BUSINESS_DETAILS}</legend>
        <div className="flexRowAround">
          <div className="formGroup width30 ">
            <label>{LABELS.CURRENT_WORK_STATUS}</label>
            <RadioBtn
              list={[
                {
                  id: "Job",
                  value: "JOB",
                  label: LABELS.JOB,
                  checked: businessDetails.currentWorkStatus
                    ? businessDetails.currentWorkStatus === "Job"
                    : false,
                  disabled: false,
                },
                {
                  id: "Wholeseller",
                  value: "Wholeseller",
                  label: LABELS.WHOLESELLER,
                  checked: businessDetails.currentWorkStatus
                    ? businessDetails.currentWorkStatus === "Wholeseller"
                    : false,
                  disabled: false,
                },
                {
                  id: "Distributor",
                  value: "Distributor",
                  label: LABELS.DISTRIBUTOR,
                  checked: businessDetails.currentWorkStatus
                    ? businessDetails.currentWorkStatus === "Distributor"
                    : false,
                  disabled: false,
                },
                {
                  id: "Other",
                  value: "Other",
                  label: LABELS.OTHER,
                  checked: businessDetails.currentWorkStatus
                    ? businessDetails.currentWorkStatus === "Other"
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"currentWorkStatus"}
            />
          </div>
          <div className="formGroup width30">
            <TxtRow
              id={"yearOfExperince"}
              type={"number"}
              label={LABELS.YEAR_OF_EXPERIENCE}
              // required={true}
              defaultValue={businessDetails.yearOfExperince || ""}
              //disabled={isSubmit}
            />
          </div>
          <div className="formGroup width30">
            <TxtRow
              id={"monthlyTurnover"}
              type={"number"}
              label={LABELS.MONTHLY_TURNOVER}
              // required={true}
              defaultValue={businessDetails.monthlyTurnover || ""}
              //disabled={isSubmit}
            />
          </div>
        </div>
        <div className="flexRowAround">
          <div className="formGroup width30">
            <TxtRow
              id={"currentWorkDetails"}
              type={"text"}
              label={LABELS.CURRENT_WORK_DETAILS}
              // required={true}
              defaultValue={businessDetails.currentWorkDetails || ""}
              //disabled={isSubmit}
            />
          </div>
          <div className="formGroup width30"></div>
          <div className="formGroup width30"></div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">{LABELS.INFRASTRUCTURE}</legend>
        <div className="flexRowAround">
          <div className="formGroup width30 ">
            <label>{LABELS.WAREHOUSE}</label>
            <RadioBtn
              list={[
                {
                  id: "Rented",
                  value: "Rented",
                  label: LABELS.RENTED,
                  checked: infrastructure.warehouse
                    ? infrastructure.warehouse === "Rented"
                    : false,
                  disabled: false,
                },
                {
                  id: "Own",
                  value: "Own",
                  label: LABELS.OWN,
                  checked: infrastructure.warehouse
                    ? infrastructure.warehouse === "Own"
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"warehouse"}
            />
          </div>
          <div className="formGroup width30 ">
            <label>{LABELS.VEHICLE}</label>
            <RadioBtn
              list={[
                {
                  id: "Rented",
                  value: "Rented",
                  label: LABELS.RENTED,
                  checked: infrastructure.vehicle
                    ? infrastructure.vehicle === "Rented"
                    : false,
                  disabled: false,
                },
                {
                  id: "Own",
                  value: "Own",
                  label: LABELS.OWN,
                  checked: infrastructure.vehicle
                    ? infrastructure.vehicle === "Own"
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"vehicle"}
            />
          </div>
          <div className="formGroup width30">
            <TxtRow
              id={"sqFt"}
              type={"number"}
              label={LABELS.SQ_FT}
              // required={true}
              defaultValue={infrastructure.sqFt || ""}
              //disabled={isSubmit}
            />
          </div>
        </div>
        <div className="flexRowAround">
          <div className="formGroup width30">
            <TxtRow
              id={"noOfVehicle"}
              type={"number"}
              label={LABELS.NO_OF_VEHICLE}
              // required={true}
              defaultValue={infrastructure.noOfVehicle || ""}
              //disabled={isSubmit}
            />
          </div>
          <div className="formGroup width30"></div>
          <div className="formGroup width30"></div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">{LABELS.OTHER}</legend>
        <div className="flexRowAround">
          <div className="formGroup width30 ">
            <label>{LABELS.GST_AVAILABLE}</label>
            <RadioBtn
              list={[
                {
                  id: "Yes",
                  value: "Yes",
                  label: LABELS.YES,
                  checked: other.gstAvailable
                    ? other.gstAvailable === "Yes"
                    : false,
                  disabled: false,
                },
                {
                  id: "No",
                  value: "No",
                  label: LABELS.NO,
                  checked: other.gstAvailable
                    ? other.gstAvailable === "No"
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"gstAvailable"}
            />
          </div>

          <div className="formGroup width30 ">
            <label>{LABELS.FOOD_LICENCE}</label>
            <RadioBtn
              list={[
                {
                  id: "Yes",
                  value: "Yes",
                  label: LABELS.YES,
                  checked: other.foodLicence
                    ? other.foodLicence === "Yes"
                    : false,
                  disabled: false,
                },
                {
                  id: "No",
                  value: "No",
                  label: LABELS.NO,
                  checked: other.foodLicence
                    ? other.foodLicence === "No"
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"foodLicence"}
            />
          </div>

          <div className="formGroup width30">
            <TxtRow
              id={"depositRemarks"}
              type={"text"}
              label={LABELS.DEPOSIT_REMARKS}
              // required={true}
              defaultValue={other.depositRemarks || ""}
              //disabled={isSubmit}
            />
          </div>
        </div>
        <div className="flexRowAround">
          <div className="formGroup width30">
            <TxtRow
              id={"workingCapital"}
              type={"text"}
              label={LABELS.WORKING_CAPITAL}
              // required={true}
              defaultValue={capitalDetails.workingCapital}
              //disabled={isSubmit}
            />
          </div>
          <div className="formGroup width30"></div>
          <div className="formGroup width30"></div>
        </div>
      </fieldset>

      <div className="flexRow">
        <div className="formGroup width60">
          <TxtRow
            id={"remarks"}
            type={"text"}
            label={LABELS.REMARKS}
            // required={true}
            defaultValue={other.remarks || ""}
            //disabled={isSubmit}
            rows={4}
          />
        </div>
      </div>

      <div className="formGroup submitBtnWrapper">
        <div className=" flexRowAround">
          <Btn
            text={LABELS.SAVE}
            loading={isSubmit}
            onClick={saveRecord}
            bgColor={COLORS.SAVE_GREEN}
            disabled={isButtonDisabled}
            // isButtonDisabled
          />
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(PreVerification);
