import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit, Close as IconClose } from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS, CONFIG, API_CONFIG } from "common/config";
import { LABELS, WARNINGS, TITLES, SUCCESS, ERRORS } from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { GEO_APIs } from "../../../apihandlers/geography";
import { MASTER_APIs } from "apihandlers/master";
import { AUTH_APIs } from "apihandlers/auth";
import SkeletonBox from "components/Custom/SkeletonBox";
import "./styles/users.css";

const thumbnailImg = require("assets/images/pdf.png");
const video = require("assets/images/video.png");
const TrainingUpload = (props: any) => {
  const { setDashMenu } = props;

  const regionRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [regions, setRegions] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [trainingFiles, setTrainingFiles] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getTrainings(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          list[i]["isTrainingMandatoryText"] = list[i]["isTrainingMandatory"]
            ? "Yes"
            : "No";
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getCities:", error);
    }
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    setTrainingFiles([
      {
        id: row.fileId,
        path: row.path,
        mimeType: row.mimeType,
      },
    ]);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { key: "trainingCategory", name: LABELS.TRAINING_CAT, width: "25%" },
    { key: "trainingComments", name: LABELS.TRAINING_COMMENT, width: "25%" },
    {
      key: "mimeType",
      type: "text",
      name: LABELS.UPLOAD_TYPE,
      width: "15%",
    },
    {
      key: "isTrainingMandatoryText",
      type: "text",
      name: LABELS.TRAING_MANDATORY,
      width: "15%",
    },
    { key: "activeStatusText", name: LABELS.ACTIVE, width: "10%" },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageTraining = () => {
    const trainingCategory = (
      document.getElementById("trainingCategory") as HTMLInputElement
    ).value.trim();
    // const regionId = regionRef.current.selectedValue.id;
    const trainingComments = (
      document.getElementById("trainingComments") as HTMLInputElement
    ).value.trim();
    let durationInMinutes: any = (
      document.getElementById("durationInMinutes") as HTMLInputElement
    ).value.trim();
    const training = document.querySelector(
      'input[name="isTrainingMandatory"]:checked'
    ) as HTMLInputElement;
    const isTrainingMandatory = training ? training.value : "";
    const active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";
    // Validations
    // if (!cityName) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!regionId) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!shopCount) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!totalPopulation) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!selectBtnValue) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    if (durationInMinutes) {
      let dur = durationInMinutes.split(":");
      durationInMinutes = Number(dur[0]) + Number(dur[1]);
    }

    const args = {
      id: selectedRow.id || -1,
      trainingCategory,
      trainingComments,
      fileId: trainingFiles.length > 0 ? trainingFiles[0].id : -1,
      isTrainingMandatory: isTrainingMandatory.toString(),
      durationInMinutes,
      activeStatus: selectBtnValue.toString(),
    };
    setIsSubmit(true);
    MASTER_APIs.manageTraining(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
    setTrainingFiles([]);
  };

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setTrainingFiles(null);
      uploadFile(file, base64).then((res) => {
        setTrainingFiles([res]);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "training",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
      }
    } catch (error) {}
    return fileInfo;
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.TRAINING} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TRAINING}</li>
        </ul>
        <h4>{TITLES.TRAINING}</h4>
        {/* <p>{SUB_TITLES.TRAINING}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.TRAINING}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.TRAINING + ": " + selectedRow.trainingCategory
              : "Add New " + LABELS.TRAINING
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <TxtRow
              id={"trainingCategory"}
              type={"text"}
              label={"trainingCategory"}
              required={true}
              defaultValue={selectedRow.trainingCategory}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"trainingComments"}
              type={"text"}
              label={"trainingComments"}
              required={true}
              defaultValue={selectedRow.trainingComments}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"durationInMinutes"}
              type={"time"}
              label={"durationInMinutes"}
              required={true}
              defaultValue={selectedRow.durationInMinutes}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {"Is Training Mandatory"} {<span className="red">*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: "Yes",
                  checked: selectedRow.id
                    ? selectedRow.isTrainingMandatory === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: "No",
                  checked: selectedRow.id
                    ? selectedRow.isTrainingMandatory === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"isTrainingMandatory"}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {LABELS.FILE_UPLOAD}
              <small>
                {" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}
              </small>
            </label>
            <TxtBox
              type={"file"}
              id="uploadFile"
              //defaultValue={selectedRow.id}
              //className="uploadFile"
              accept="image/*,.pdf,.mp4"
              onChange={onUploadFile}
            />
            <div>
              {!trainingFiles ? (
                <div className="thumbnailBox width20">
                  <SkeletonBox height={300} />
                </div>
              ) : (
                trainingFiles.map((u, i) => {
                  return (
                    u.path && (
                      <div className="thumbnailBox width20">
                        <IconButton
                          className="thumbnailBoxIcon"
                          onClick={() => {
                            const list = trainingFiles.filter(
                              (a, j) => j !== i
                            );
                            setTrainingFiles(list);
                          }}
                        >
                          <IconClose />
                        </IconButton>
                        <img
                          onClick={() =>
                            window.open(
                              API_CONFIG.STATIC_URL + u.path,
                              "_blank"
                            )
                          }
                          src={
                            u.mimeType === "application/pdf"
                              ? thumbnailImg
                              : API_CONFIG.STATIC_URL + u.path
                          }
                          className="responsive cursor"
                        />
                        {/* <img
                        onClick={() =>
                          window.open(API_CONFIG.STATIC_URL + u.path, "_blank")
                        }
                        src={
                          u.mimeType === "video/mp4"
                            ? video
                            : API_CONFIG.STATIC_URL + u.path
                        }
                        // className="responsive cursor"
                      /> */}
                      </div>
                    )
                  );
                })
              )}
            </div>
          </div>
          <div className="formGroup">
            <label>
              {LABELS.STATUS} {<span className="red">*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup submitBtnWrapper">
            <div className="width45 flexRowJustify">
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageTraining}
              />
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(TrainingUpload);
