import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { GEO_APIs } from "apihandlers/geography";
import { MASTER_APIs } from "apihandlers/master";
import { USER_APIs } from "apihandlers/user";

const City = (props: any) => {
  const { setDashMenu } = props;

  const regionRef: any = useRef(null);
  const vehiclesRef: any = useRef(null);
  const driversRef: any = useRef(null);
  const executiveRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [regions, setRegions] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [vehicles, setVehicles] = useState<any>({});
  const [drivers, setDrivers] = useState<any>({});
  const [executive, setExecutive] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getRegion();
    }
  }, [loading]);

  const getRegion = () => {
    const args = {};
    GEO_APIs.getRegions(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.regionName,
          };
        });
        setRegions(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await GEO_APIs.getCities(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getCities:", error);
    }
    getVehicles();
  };

  const getVehicles = () => {
    const args = {
      activeStatus: true,
    };
    MASTER_APIs.getVehicles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.vehicleNumber,
          };
        });
        setVehicles(list);
        getDrivers();
      }
    });
  };

  const getDrivers = () => {
    const args = {};
    MASTER_APIs.getDrivers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.userName,
          };
        });
        setDrivers(list);
        getUsers();
      }
    });
  };

  const getUsers = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setExecutive(list);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "cityName",
      name: LABELS.CITY,
      width: "20%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "regionName",
      name: LABELS.REGION,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "totalShops",
      type: "number",
      name: LABELS.TOTAL_SHOPS,
      width: "8%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "population",
      type: "number",
      name: LABELS.POPULATION,
      width: "8%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "vehicle",
      name: LABELS.VEHICLE_NUMBER,
      width: "8%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "driverName",
      name: LABELS.DRIVER_NAME,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "executiveName",
      name: LABELS.EXECUTIVE,
      width: "7%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "sortOrder",
      name: LABELS.SORTING_ORDER,
      width: "7%",
      type: "number",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "10%",
      type: "dropdown",
      filters: [
        { id: "yes", name: "Yes" },
        { id: "no", name: "No" },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageCity = () => {
    const cityName = (
      document.getElementById("cityName") as HTMLInputElement
    ).value.trim();
    const regionId = regionRef.current.selectedValue.id;
    const shopCount = (
      document.getElementById("shopCount") as HTMLInputElement
    ).value.trim();
    const totalPopulation = (
      document.getElementById("totalPopulation") as HTMLInputElement
    ).value.trim();
    const vehicleId = vehiclesRef.current.selectedValue.id;
    const driverId = driversRef.current.selectedValue.id;
    const sortingOrder = (
      document.getElementById("sortingOrder") as HTMLInputElement
    ).value.trim();
    const executiveId = executiveRef.current.selectedValue.id;
    const active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";

    // Validations
    let errors = [];
    if (cityName === "") {
      errors.push({
        id: "cityName",
        msg:
          "<span class='bold'>" +
          LABELS.CITY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!regionId) {
      errors.push({
        id: "regionId",
        msg:
          "<span class='bold'>" +
          LABELS.REGION +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (shopCount === "") {
      errors.push({
        id: "shopCount",
        msg:
          "<span class='bold'>" +
          LABELS.TOTAL_SHOPS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (totalPopulation === "") {
      errors.push({
        id: "totalPopulation",
        msg:
          "<span class='bold'>" +
          LABELS.POPULATION +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (!selectBtnValue) {
      errors.push({
        id: "selectBtnValue",
        msg:
          "<span class='bold'>" +
          LABELS.ACTIVE_STATUS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      cityName: cityName,
      totalShops: shopCount,
      population: totalPopulation,
      regionId: regionId,
      vehicleId,
      driverId,
      executiveId,
      sortOrder: Number(sortingOrder).toString(),
      activeStatus: selectBtnValue.toString(),
    };
    setIsSubmit(true);
    GEO_APIs.manageCity(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("Page: City:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.CITY}</li>
        </ul>
        <h4>{TITLES.CITY}</h4>
        <p>{SUB_TITLES.CITY}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"city"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.CITY}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.CITY + ": " + selectedRow.cityName
              : "Add New " + LABELS.CITY
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup red">
            <TxtRow
              id={"cityName"}
              type={"text"}
              label={LABELS.CITY}
              required={true}
              defaultValue={selectedRow.cityName}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <label>
              {LABELS.REGION}
              {<span>*</span>}
            </label>
            <AutoComplete
              list={regions}
              childRef={regionRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.regionId,
                      label: selectedRow.regionName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"shopCount"}
              type={"number"}
              label={LABELS.TOTAL_SHOPS}
              required={true}
              defaultValue={selectedRow.totalShops}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"totalPopulation"}
              type={"number"}
              label={LABELS.POPULATION}
              required={true}
              defaultValue={selectedRow.population}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>
              {LABELS.VEHICLE_NUMBER}
              {/* {<span className="red">*</span>} */}
            </label>
            <AutoComplete
              list={vehicles}
              childRef={vehiclesRef}
              defaultValue={
                selectedRow.vehicle
                  ? {
                      id: selectedRow.vehicleId,
                      label: selectedRow.vehicle,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>
              {LABELS.DRIVER_NAME}
              {/* {<span className="red">*</span>} */}
            </label>
            <AutoComplete
              list={drivers}
              childRef={driversRef}
              defaultValue={
                selectedRow.driver
                  ? {
                      id: selectedRow.driverId,
                      label: selectedRow.driver,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"sortingOrder"}
              type={"number"}
              label={LABELS.SORTING_ORDER}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.sortOrder).toString()
                  : selectedRow.sortOrder
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup ">
            <label>
              {LABELS.EXECUTIVE}
              {/* {<span className="red">*</span>} */}
            </label>
            <AutoComplete
              list={executive}
              childRef={executiveRef}
              defaultValue={
                selectedRow.executive
                  ? {
                      id: selectedRow.executiveId,
                      label: selectedRow.executive,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label className="red">
              {LABELS.ACTIVE_STATUS} {<span>*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageCity}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(City);
