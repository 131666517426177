import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { PRODUCT_APIs } from "apihandlers/product";

const Tax = (props: any) => {
  const { setDashMenu, privileges } = props;

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await PRODUCT_APIs.getTaxMaster(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getTaxMaster:", error);
    }
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "taxName",
      name: LABELS.TAXES_NAME,
      width: "25%",
      type: "text",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "cgstPc",
      name: LABELS.CGST,
      width: "20%",
      type: "number",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "sgstPc",
      name: LABELS.SGST,
      width: "20%",
      type: "number",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "igstPc",
      name: LABELS.IGST,
      width: "20%",
      type: "number",
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      align: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageTaxMaster = () => {
    const taxName = (
      document.getElementById("taxName") as HTMLInputElement
    ).value.trim();
    const cgst = (
      document.getElementById("cgst") as HTMLInputElement
    ).value.trim();
    const sgst = (
      document.getElementById("sgst") as HTMLInputElement
    ).value.trim();
    const igst = (
      document.getElementById("igst") as HTMLInputElement
    ).value.trim();
    let active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";
    // Validations
    let errors = [];
    if (taxName === "") {
      errors.push({
        id: "taxName",
        msg:
          "<span class='bold'>" +
          LABELS.TAXES_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (cgst === "") {
      errors.push({
        id: "cgst",
        msg:
          "<span class='bold'>" +
          LABELS.CGST +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (sgst === "") {
      errors.push({
        id: "sgst",
        msg:
          "<span class='bold'>" +
          LABELS.SGST +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (igst === "") {
      errors.push({
        id: "igst",
        msg:
          "<span class='bold'>" +
          LABELS.IGST +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!selectBtnValue) {
      errors.push({
        id: "selectBtnValue",
        msg:
          "<span class='bold'>" +
          LABELS.ACTIVE_STATUS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      taxName: taxName,
      cgstPc: cgst,
      sgstPc: sgst,
      igstPc: igst,
      activeStatus: selectBtnValue.toString(),
    };
    console.log("args", args);
    setIsSubmit(true);
    PRODUCT_APIs.manageTaxMaster(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TAX}</li>
        </ul>
        <h4>{TITLES.TAX}</h4>
        <p>{SUB_TITLES.TAX}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"tax"}
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.TAX}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.TAX + ": " + selectedRow.taxName
              : "Add New " + LABELS.TAX
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup red">
            <TxtRow
              id={"taxName"}
              type={"text"}
              label={LABELS.TAXES_NAME}
              required={true}
              defaultValue={selectedRow.taxName}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"cgst"}
              type={"number"}
              label={LABELS.CGST}
              required={true}
              defaultValue={selectedRow.cgstPc}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"sgst"}
              type={"number"}
              label={LABELS.SGST}
              required={true}
              defaultValue={selectedRow.sgstPc}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"igst"}
              type={"number"}
              label={LABELS.IGST}
              required={true}
              defaultValue={selectedRow.igstPc}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup required">
            <label>{LABELS.ACTIVE_STATUS}</label>
            <span className="red">*</span>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className=" flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageTaxMaster}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Tax);
