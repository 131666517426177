import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import UiAccordion from "components/Custom/UiAccordion";
import { LABELS, TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { DEFAULTS } from "common/config";
import { COLORS } from "common/colors";

import { TRANS_APIs } from "apihandlers/transaction";

const PurchaseReturn = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const columns = [
    {
      key: "expanded",
    },
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "origBillNo",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ORIG_BILL_NO,
      width: "10%",
    },
    {
      key: "origDate",
      type: "date",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ORIG_DATE,
      width: "10%",
    },
    {
      key: "purchaseReturnNo",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.PURCHASE_RETURN_NO,
      width: "15%",
    },
    {
      key: "purchaseReturnDate",
      type: "date",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.PURCHASE_RETURN_DATE,
      width: "15%",
    },
    {
      key: "noOfItem",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.NO_OF_ITEMS,
      width: "15%",
    },
    {
      key: "itemAmt",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.ITEM_AMOUNT,
      width: "15%",
    },
    {
      key: "billAmt",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      name: LABELS.BILL_AMOUNT,
      width: "15%",
    },
  ];

  const columns2 = [
    {
      key: "productName",
      type: "text",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.PRODUCT_NAME,
      width: "35%",
    },
    {
      key: "qty",
      type: "number",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.QTY,
      width: "20%",
    },
    {
      key: "price",
      type: "number",
      editable: true,
      sortable: false,
      searchable: false,
      name: LABELS.RATE,
      width: "25%",
    },
    {
      key: "amount",
      type: "number",
      formula: "qty * price",
      editable: false,
      sortable: false,
      searchable: false,
      name: LABELS.AMOUNT,
      width: "20%",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const fromDate = (
        document.getElementById("fromDate") as HTMLInputElement
      ).value.trim();
      const toDate = (
        document.getElementById("toDate") as HTMLInputElement
      ).value.trim();
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        fromDate,
        toDate,
      };
      setRows(null);
      const res: any = await TRANS_APIs.getPurchaseReturn(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          list[i]["_type"] = "maingrid";
          list[i]["_expanded"] = false;
          let subList = list[i].purchaseReturn;
          for (let j = 0; j < subList.length; j++) {
            subList[j]["slNo"] = j + 1;
          }
          list[i]["_subGrid"] = () => subGrid(subList);
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getPurchaseReturn:", error);
    }
  };

  const subGrid = (subRows = []) => {
    return (
      <>
        <ExcelGrid
          id={"purchaseBillSubGrid"}
          cols={columns2}
          rows={subRows}
          isSubGrid={true}
          noTotalRows={true}
        />
      </>
    );
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };
  const printPurchaseReturn = (e) => {
    console.log(e);
  };

  return (
    <React.Fragment>
      {
        <div className="pageInfo">
          <ul className="breadcrumbs">
            <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
            <li className="dim">{" / "}</li>
            <li onClick={() => props.setDashMenu(LABELS.HOME)}>
              {LABELS.PURCHASE}
            </li>
            <li className="dim">{" / "}</li>
            <li className="active">{TITLES.PURCHASE_RETURN}</li>
          </ul>
          <h4>{TITLES.PURCHASE_RETURN}</h4>
        </div>
      }
      <div className="width50 filterBorder">
        <UiAccordion id={"filter"} title={LABELS.FILTER}>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.FROM_DATE}
                type={"date"}
                id="fromDate"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                label={LABELS.TO_DATE}
                type={"date"}
                id="toDate"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="formGroup width30">
              <div style={{ marginTop: "1.5rem" }}>
                <Btn
                  text={LABELS.LOAD}
                  onClick={getRows}
                  bgColor={COLORS.GRID_ADD_NEW}
                  // loading={isSubmit}
                />
              </div>
            </div>
          </div>
        </UiAccordion>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.55}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.PURCHASE_RETURN}
            onReload={reloadGrid}
            leftSideWidth={"50%"}
            rightSideWidth={"50%"}
            leftSideBtns={
              <div className="excelBtnWrapper">
                <Btn
                  text={LABELS.PRINT_PURCHASE_RETURN}
                  size={"medium"}
                  onClick={printPurchaseReturn}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                />
              </div>
            }
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(PurchaseReturn);
