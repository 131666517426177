import React from "react";
import { useState, useRef } from "react";
import { withSnackbar } from "notistack";

import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import RadioBtn from "components/Custom/RadioBtn";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";

import VehicleDocs from "./VehicleDocs";

import { DateFunctions } from "common/datefunctions";
import { NOTIFY, FORMATS, CONFIG } from "common/config";
import { COLORS } from "common/colors";
import { LABELS, WARNINGS } from "common/lables";
import { COMMON_JS } from "common/scripts";
import "./styles/users.css";
import { AUTH_APIs } from "apihandlers/auth";

const thumbnailImg = require("assets/images/pdf.png");

const VehicleForm = (props: any) => {
  const {
    vehicleTypes,
    selectedRow,
    onClose,
    axlesTypes,
    bodyTypes,
    passingTonType,
    driverNames,
    manageRecord,
    areas,
    manageFiles,
    isSubmit,
    savedFiles,
    vehicleFiles,
    setVehicleFiles,
    onEditRow,
  } = props;
  const vehicleTypeRef: any = useRef(null);
  const axlesTypeRef: any = useRef(null);
  const passingTonsRef: any = useRef(null);
  const bodyTypeRef: any = useRef(null);
  const locationTypeRef: any = useRef(null);
  const driverTypeRef: any = useRef(null);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  // const [vehicleFiles, setVehicleFiles] = useState<any>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const tabs = [
    {
      text: "Vehicle Details",
    },
    {
      text: "File Upload",
    },
  ];

  const onSave = () => {
    const vehicleNumber = (
      document.getElementById("vehicleNumber") as HTMLInputElement
    ).value.trim();
    const vehicleTypeId = vehicleTypeRef.current.selectedValue.id;
    const ownerName = (
      document.getElementById("ownerName") as HTMLInputElement
    ).value.trim();
    const driverId = driverTypeRef.current.selectedValue.id;
    const vehTypeAxleId = axlesTypeRef.current.selectedValue.id;
    const vehTypeBodyTypeId = bodyTypeRef.current.selectedValue.id;
    const vehTypePassingTonId = passingTonsRef.current.selectedValue.id;
    const vehicleMake = (
      document.getElementById("vehicleMake") as HTMLInputElement
    ).value.trim();
    const vehicleModel = (
      document.getElementById("vehicleModel") as HTMLInputElement
    ).value.trim();
    const engineNumber = (
      document.getElementById("engineNumber") as HTMLInputElement
    ).value.trim();
    const chassisNumber = (
      document.getElementById("chassisNumber") as HTMLInputElement
    ).value.trim();
    const permitNumber = (
      document.getElementById("permitNumber") as HTMLInputElement
    ).value.trim();
    const dieselCardNumber = (
      document.getElementById("dieselCardNumber") as HTMLInputElement
    ).value.trim();
    const fastagNumber = (
      document.getElementById("fastagNumber") as HTMLInputElement
    ).value.trim();
    const gpsImei = (
      document.getElementById("gpsImei") as HTMLInputElement
    ).value.trim();
    const fuelProvider = (
      document.getElementById("fuelProvider") as HTMLInputElement
    ).value.trim();
    const fastagProvider = (
      document.getElementById("fastagProvider") as HTMLInputElement
    ).value.trim();
    const rcStatus = (
      document.getElementById("rcStatus") as HTMLInputElement
    ).value.trim();
    const manufacturingYear = (
      document.getElementById("manufacturingYear") as HTMLInputElement
    ).value.trim();
    const emissionNorms = (
      document.getElementById("emissionNorms") as HTMLInputElement
    ).value.trim();
    const locationId = locationTypeRef.current.selectedValue.id;
    const purchaseDate = (
      document.getElementById("purchaseDate") as HTMLInputElement
    ).value.trim();
    const registrationValidity = (
      document.getElementById("registrationValidity") as HTMLInputElement
    ).value.trim();
    const roadTaxStartDate = (
      document.getElementById("roadTaxStartDate") as HTMLInputElement
    ).value.trim();
    const roadTaxEndDate = (
      document.getElementById("roadTaxEndDate") as HTMLInputElement
    ).value.trim();
    const permitStartDate = (
      document.getElementById("permitStartDate") as HTMLInputElement
    ).value.trim();
    const permitEndDate = (
      document.getElementById("permitEndDate") as HTMLInputElement
    ).value.trim();
    const insuranceStartDate = (
      document.getElementById("insuranceStartDate") as HTMLInputElement
    ).value.trim();
    const insuranceEndDate = (
      document.getElementById("insuranceEndDate") as HTMLInputElement
    ).value.trim();
    const fitnessStartDate = (
      document.getElementById("fitnessStartDate") as HTMLInputElement
    ).value.trim();
    const fitnessEndDate = (
      document.getElementById("fitnessEndDate") as HTMLInputElement
    ).value.trim();
    const speedLimiterEndDate = (
      document.getElementById("speedLimiterEndDate") as HTMLInputElement
    ).value.trim();
    const pucEndDate = (
      document.getElementById("pucEndDate") as HTMLInputElement
    ).value.trim();
    const crateCapacity = (
      document.getElementById("crateCapacity") as HTMLInputElement
    ).value.trim();
    const dailyTravel = (
      document.getElementById("dailyTravel") as HTMLInputElement
    ).value.trim();
    const averageSpeed = (
      document.getElementById("averageSpeed") as HTMLInputElement
    ).value.trim();
    const averageMileage = (
      document.getElementById("averageMileage") as HTMLInputElement
    ).value.trim();
    const perKmExpenses = (
      document.getElementById("perKmExpenses") as HTMLInputElement
    ).value.trim();
    let ele = document.querySelector(
      'input[name="fuelTypes"]:checked'
    ) as HTMLInputElement;
    let fuelTypes = ele ? ele.value : "";

    let attached = document.querySelector(
      'input[name="isAttached"]:checked'
    ) as HTMLInputElement;
    const isAttached = attached ? attached.value : "";

    let hypothicated = document.querySelector(
      'input[name="isHypothicated"]:checked'
    ) as HTMLInputElement;
    const isHypothicated = hypothicated ? hypothicated.value : "";

    let active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = active ? active.value : "";

    let vahan = document.querySelector(
      'input[name="isVahanVerified"]:checked'
    ) as HTMLInputElement;
    const isVahanVerified = vahan ? vahan.value : "";

    let vehicleState = document.querySelector(
      'input[name="vehicleStatus"]:checked'
    ) as HTMLInputElement;
    const vehicleStatus = vehicleState ? vehicleState.value : "";
    //new

    const reeferFuelPerHr = (
      document.getElementById("reeferFuelPerHr") as HTMLInputElement
    ).value.trim();
    const prevNPDATE = (
      document.getElementById("prevNPDATE") as HTMLInputElement
    ).value.trim();
    const nextNPDueDate = (
      document.getElementById("nextNPDueDate") as HTMLInputElement
    ).value.trim();
    const previousRoadTaxDate = (
      document.getElementById("nextNPDueDate") as HTMLInputElement
    ).value.trim();
    const np5yrValidity = (
      document.getElementById("np5yrValidity") as HTMLInputElement
    ).value.trim();
    const np10yrValidity = (
      document.getElementById("np10yrValidity") as HTMLInputElement
    ).value.trim();
    const loanAccNum = (
      document.getElementById("loanAccNum") as HTMLInputElement
    ).value.trim();
    const loanBankName = (
      document.getElementById("loanBankName") as HTMLInputElement
    ).value.trim();
    const insuranceNumber = (
      document.getElementById("insuranceNumber") as HTMLInputElement
    ).value.trim();
    const insuranceCompanyName = (
      document.getElementById("insuranceCompanyName") as HTMLInputElement
    ).value.trim();
    const emiAmount = (
      document.getElementById("emiAmount") as HTMLInputElement
    ).value.trim();
    const emiDays = (
      document.getElementById("emiDays") as HTMLInputElement
    ).value.trim();

    const currentLocation = (
      document.getElementById("currentLocation") as HTMLInputElement
    ).value.trim();

    const latitude = (
      document.getElementById("latitude") as HTMLInputElement
    ).value.trim();
    const longitude = (
      document.getElementById("longitude") as HTMLInputElement
    ).value.trim();

    const args = {
      activeStatus: activeStatus,
      averageMileage: averageMileage,
      averageSpeed: averageSpeed,
      chassisNumber: chassisNumber,
      crateCapacity: crateCapacity,
      currentLocation,
      dailyTravel: dailyTravel,
      dieselCardNumber: dieselCardNumber,
      driverId: driverId, // 124,
      emissionNorms: emissionNorms,
      emissionValidity: pucEndDate,
      engineNumber: engineNumber,
      fastagNumber: fastagNumber,
      fastagProvider: fastagProvider,
      fcValidity: fitnessEndDate, //"2023-06-01",
      fitnessStartDate: fitnessStartDate, //"",
      fuelProvider: fuelProvider,
      fuelTypes, //"Diesel",
      gpsImei: gpsImei,
      id: selectedRow.id || -1, // -1,
      insuranceStartDate: insuranceStartDate,
      insuranceValidity: insuranceEndDate, //"2023-05-01",
      isAttached, // "true",
      isHypothicated, // "true",
      isVahanVerified,
      latitude,
      longitude,
      manufacturingYear: manufacturingYear,
      ownerName: ownerName,
      perKmExpenses: perKmExpenses,
      permitNumber: permitNumber,
      permitStartDate: permitStartDate,
      permitValidity: permitEndDate,
      purchaseDate: purchaseDate,
      rcStatus: rcStatus,
      registrationValidity: registrationValidity,
      roadTaxEndDate: roadTaxEndDate,
      roadTaxStartDate: roadTaxStartDate,
      speedLimiterEndDate: speedLimiterEndDate,
      vehTypeAxleId,
      vehTypeBodyTypeId: vehTypeBodyTypeId,
      vehTypePassingTonId: vehTypePassingTonId,
      vehicleMake: vehicleMake,
      vehicleModel: vehicleModel,
      vehicleNumber: vehicleNumber,
      vehicleStatus,
      vehicleTypeId: vehicleTypeId,
      verifiedOn: "",
      locationId,
      //new args
      reeferFuelPerHr: reeferFuelPerHr,
      prevNpDt: prevNPDATE,
      nextNpDueDt: nextNPDueDate,

      prevRoadTaxDt: previousRoadTaxDate,
      np5YrValidity: np5yrValidity,
      np10YrValidity: np10yrValidity,
      loanAcNum: loanAccNum,
      loanBankName,
      insuranceNum: insuranceNumber,
      insuranceCompName: insuranceCompanyName,
      emiAmount,
      emiDays,
      files: vehicleFiles,
    };
    console.log("args: ", args);
    // const fileArgs = {
    //   vehicleId: selectedRow.id || -1,
    //   files: vehicleFiles,
    // };

    // Validations

    let errors = [];
    if (vehicleNumber === "") {
      errors.push({
        id: "vehicleNumber",
        msg:
          "<span class='bold'>" +
          LABELS.VEHICLE_NO +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (!isAttached) {
      errors.push({
        id: "isAttached",
        msg:
          "<span class='bold'>" +
          LABELS.ATTACHED +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!vehicleTypeId) {
      errors.push({
        id: "vehicleTypeId",
        msg:
          "<span class='bold'>" +
          LABELS.VEHICLE_TYPE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (insuranceEndDate === "") {
      errors.push({
        id: "insuranceEndDate",
        msg:
          "<span class='bold'>" +
          LABELS.INSURENCE_UPTO +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (!fitnessEndDate) {
      errors.push({
        id: "fitnessEndDate",
        msg:
          "<span class='bold'>" +
          LABELS.FITNESS_UPTO +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!isHypothicated) {
      errors.push({
        id: "isHypothicated",
        msg:
          "<span class='bold'>" +
          LABELS.HYPOTHICATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }
    console.log("args", args);
    manageRecord(args);

    console.log("isAttached :", isAttached);
    console.log("vehicle Status :", vehicleStatus);
  };

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      // setFuelFiles(null);
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        // setFuelFiles([res]);
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "fuel",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        setVehicleFiles([...vehicleFiles, fileInfo]);
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };

  console.log("selectedRow:", selectedRow, areas);

  return (
    <UiDialog
      title={
        selectedRow.id
          ? "Edit " + LABELS.VEHICLE + ": " + selectedRow.vehicleNumber
          : "Add New " + LABELS.VEHICLE
      }
      // subTitle={"Adding new vehicle"}
      open={true}
      size={"lg"}
      onClose={onClose}
    >
      {/* <UiTabs
        tabIndex={tabIndex}
        tabs={tabs}
        handleChange={(e, f) => {
          setTabIndex(f);
          console.log("F", f, tabIndex);
        }}
        aria-label="basic tabs example"
      />
      {tabIndex === 0 && ( */}
      <div className="flexColumn">
        <div className="expenseNote">
          <div className="formNote">
            <h4>{"Note:"}</h4>
            <p className="red">
              {<span className="red">*</span>}
              {" indicates mandatory fields."}
            </p>
          </div>
        </div>
        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.VEHICLE_DRIVER_INFO}
          </legend>
          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <TxtRow
                id={"vehicleNumber"}
                label={LABELS.VEHICLE_NO}
                required={true}
                defaultValue={selectedRow.vehicleNumber}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"ownerName"}
                label={LABELS.OWNER_NAME}
                defaultValue={selectedRow.ownerName}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <label>{LABELS.DRIVER_NAME}</label>
              <AutoComplete
                list={driverNames}
                childRef={driverTypeRef}
                defaultValue={
                  selectedRow.driverId
                    ? {
                        id: selectedRow.driverId,
                        label: selectedRow.driverName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.LOCATION}</label>
              <AutoComplete
                list={areas}
                childRef={locationTypeRef}
                defaultValue={
                  selectedRow.locationId
                    ? {
                        id: selectedRow.locationId,
                        label: selectedRow.locationName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30 red">
              <label>
                {LABELS.ATTACHED}
                {<span>{" *"}</span>}
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.YES,
                    checked: selectedRow.id
                      ? selectedRow.isAttached === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.NO,
                    checked: selectedRow.id
                      ? selectedRow.isAttached === false
                      : false,
                    disabled: false,
                  },
                ]}
                name={"isAttached"}
                row={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.VEHCILE_SPECIFICATION}
          </legend>
          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <label>
                {LABELS.VEHICLE_TYPE}
                {<span>{" *"}</span>}
              </label>
              <AutoComplete
                list={vehicleTypes}
                childRef={vehicleTypeRef}
                defaultValue={
                  selectedRow.typeId
                    ? {
                        id: selectedRow.typeId,
                        label: selectedRow.typeName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.AXLE_TYPE}</label>
              <AutoComplete
                list={axlesTypes}
                childRef={axlesTypeRef}
                defaultValue={
                  selectedRow.axleName
                    ? {
                        id: selectedRow.vehTypeAxleId,
                        label: selectedRow.axleName,
                      }
                    : null
                }
                onChange={(e, val) => {}}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <label>{LABELS.BODY_TYPE}</label>
              <AutoComplete
                list={bodyTypes}
                childRef={bodyTypeRef}
                defaultValue={
                  selectedRow.bodyTypeName
                    ? {
                        id: selectedRow.vehTypeBodyTypeId,
                        label: selectedRow.bodyTypeName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.PASSING_TON}</label>
              <AutoComplete
                list={passingTonType}
                childRef={passingTonsRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.vehTypePassingTonId,
                        label:
                          selectedRow.maxPassingTon +
                          " -" +
                          selectedRow.minPassingTon,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.VEHICLE_IDENTIFICATION}
          </legend>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"vehicleMake"}
                label={LABELS.MAKE}
                defaultValue={selectedRow.vehicleMake}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"vehicleModel"}
                label={LABELS.MODEL}
                defaultValue={selectedRow.vehicleModel}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"engineNumber"}
                label={LABELS.ENGINE_NO}
                defaultValue={selectedRow.engineNumber}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"chassisNumber"}
                label={LABELS.CHASSIS_NO}
                defaultValue={selectedRow.chassisNumber}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"permitNumber"}
                label={LABELS.PERMIT_NO}
                defaultValue={selectedRow.permitNumber}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"rcStatus"}
                label={LABELS.RC_STATUS}
                defaultValue={selectedRow.rcStatus}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"manufacturingYear"}
                label={LABELS.MANUFACTURING_YEAR}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.manufacturingYear).toString()
                    : selectedRow.manufacturingYear
                }
                disabled={isSubmit}
                type="number"
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"emissionNorms"}
                label={LABELS.EMMISION_NORM}
                defaultValue={selectedRow.emissionNorms}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"purchaseDate"}
                label={LABELS.PURCHASE_DAATE}
                defaultValue={
                  selectedRow.purchaseDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.purchaseDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"crateCapacity"}
                label={LABELS.CRATE_CAPACITY}
                defaultValue={selectedRow.crateCapacity}
                type={"number"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width65">
              <label>{LABELS.FUEL_TYPE}</label>
              <RadioBtn
                name={"fuelTypes"}
                row={true}
                list={[
                  {
                    id: "0",
                    label: LABELS.DIESEL,
                    value: LABELS.DIESEL,
                    checked: selectedRow.id
                      ? selectedRow.fuelTypes === "DIESEL"
                      : true,
                    disabled: false,
                  },
                  {
                    id: "1",
                    label: LABELS.PETROL,
                    value: LABELS.PETROL,
                    checked: selectedRow.id
                      ? selectedRow.fuelTypes === "PETROL"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "2",
                    label: LABELS.CNG,
                    value: LABELS.CNG,
                    checked: selectedRow.id
                      ? selectedRow.fuelTypes === "CNG"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "3",
                    label: LABELS.ELECTRIC,
                    value: LABELS.ELECTRIC,
                    checked: selectedRow.id
                      ? selectedRow.fuelTypes === "ELECTRIC"
                      : false,
                    disabled: false,
                  },
                ]}
                disabled={isSubmit}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.VEHICLE_PAYEMENT_AND_NAV_DETAILS}
          </legend>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"dieselCardNumber"}
                label={LABELS.FUEL_CARD_NO}
                defaultValue={selectedRow.dieselCardNumber}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"fastagNumber"}
                label={LABELS.FAST_TAG_NO}
                defaultValue={selectedRow.fastagNumber}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"gpsImei"}
                label={LABELS.GPS_IMEI}
                defaultValue={selectedRow.gpsImei}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"fuelProvider"}
                label={LABELS.FUEL_PROVIDER}
                defaultValue={selectedRow.fuelProvider}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"fastagProvider"}
                label={LABELS.FASTTAG_PROVIDER}
                defaultValue={selectedRow.fastagProvider}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.VEHICLE_DOCUMENTATION_DETAILS}
          </legend>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"registrationValidity"}
                label={LABELS.REGISTRATION_VALIDITY}
                defaultValue={
                  selectedRow.registrationValidity
                    ? DateFunctions.getFormatedDate(
                        selectedRow.registrationValidity,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"roadTaxStartDate"}
                label={LABELS.ROADTAX_FROM}
                defaultValue={
                  selectedRow.roadTaxStartDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.roadTaxStartDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"roadTaxEndDate"}
                label={LABELS.ROADTAX_UPTO}
                defaultValue={
                  selectedRow.roadTaxEndDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.roadTaxEndDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"permitStartDate"}
                label={LABELS.PERMIT_FROM}
                defaultValue={
                  selectedRow.permitStartDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.permitStartDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"permitEndDate"}
                label={LABELS.PERMIT_UPTO}
                defaultValue={
                  selectedRow.permitValidity
                    ? DateFunctions.getFormatedDate(
                        selectedRow.permitValidity,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"insuranceStartDate"}
                label={LABELS.INSURENCE_FROM}
                defaultValue={
                  selectedRow.insuranceStartDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.insuranceStartDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <TxtRow
                id={"insuranceEndDate"}
                label={LABELS.INSURENCE_UPTO}
                defaultValue={
                  selectedRow.insuranceValidity
                    ? DateFunctions.getFormatedDate(
                        selectedRow.insuranceValidity,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"fitnessStartDate"}
                label={LABELS.FITNESS_FROM}
                defaultValue={
                  selectedRow.fitnessStartDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.fitnessStartDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <TxtRow
                id={"fitnessEndDate"}
                label={LABELS.FITNESS_UPTO}
                defaultValue={
                  selectedRow.fcValidity
                    ? DateFunctions.getFormatedDate(
                        selectedRow.fcValidity,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                required={true}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"speedLimiterEndDate"}
                label={LABELS.SPEED_LIMIT_UPTO}
                defaultValue={
                  selectedRow.speedLimiterEndDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.speedLimiterEndDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"pucEndDate"}
                label={LABELS.PUC_UPTO}
                defaultValue={
                  selectedRow.emissionValidity
                    ? DateFunctions.getFormatedDate(
                        selectedRow.emissionValidity,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"prevNPDATE"}
                label={LABELS.PREV_NP_DATE}
                defaultValue={
                  selectedRow.prevNpDt
                    ? DateFunctions.getFormatedDate(
                        selectedRow.prevNpDt,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"nextNPDueDate"}
                label={LABELS.NEXT_NP_DUE_DATE}
                defaultValue={
                  selectedRow.nextNpDueDt
                    ? DateFunctions.getFormatedDate(
                        selectedRow.nextNpDueDt,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"previousRoadTaxDate"}
                label={LABELS.PREV_ROADTAX_DATE}
                defaultValue={
                  selectedRow.prevRoadTaxDt
                    ? DateFunctions.getFormatedDate(
                        selectedRow.prevRoadTaxDt,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"np5yrValidity"}
                label={LABELS.NP_5YR_VALIDITY}
                defaultValue={
                  selectedRow.np5YrValidity
                    ? DateFunctions.getFormatedDate(
                        selectedRow.np5YrValidity,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"np10yrValidity"}
                label={LABELS.NP_10YR_VALIDITY}
                defaultValue={
                  selectedRow.np10YrValidity
                    ? DateFunctions.getFormatedDate(
                        selectedRow.np10YrValidity,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">{LABELS.FUEL_DETAILS}</legend>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"dailyTravel"}
                label={LABELS.DAILY_TRAVEL}
                defaultValue={selectedRow.dailyTravel}
                type={"number"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"averageSpeed"}
                label={LABELS.AVG_SPEED}
                defaultValue={selectedRow.averageSpeed}
                type={"number"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"averageMileage"}
                label={LABELS.MILEAGE}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.averageMileage).toString()
                    : selectedRow.averageMileage
                }
                type={"number"}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"perKmExpenses"}
                label={LABELS.PER_KM}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.perKmExpenses).toString()
                    : selectedRow.perKmExpenses
                }
                type={"number"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"reeferFuelPerHr"}
                label={LABELS.REEFER_FUEL_PER_HR}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.reeferFuelPerHr).toString()
                    : selectedRow.reeferFuelPerHr
                }
                type={"number"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">{LABELS.LOAN_DETAILS}</legend>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"loanAccNum"}
                label={LABELS.LOAN_ACCOUNT_NUMBER}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.loanAcNum).toString()
                    : selectedRow.loanAcNum
                }
                type={"number"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"loanBankName"}
                label={LABELS.LOAN_BANK_NAME}
                defaultValue={selectedRow.loanBankName}
                type={"text"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"insuranceNumber"}
                label={LABELS.INSURANCE_NUMBER}
                defaultValue={
                  selectedRow.insuranceNum
                  // selectedRow.id
                  //   ? Number(selectedRow.insuranceNum).toString()
                  // : selectedRow.insuranceNum
                }
                type={"text"}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"insuranceCompanyName"}
                label={LABELS.INSURANCE_COMP_NAME}
                defaultValue={selectedRow.insuranceCompName}
                type={"text"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"emiAmount"}
                label={LABELS.EMI_AMOUNT}
                defaultValue={selectedRow.emiAmount}
                type={"number"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"emiDays"}
                label={LABELS.EMI_DAYS}
                defaultValue={selectedRow.emiDays}
                type={"number"}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <label>
                {LABELS.HYPOTHICATE}
                {<span className="red">{" *"}</span>}
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.YES,
                    checked: selectedRow.id
                      ? selectedRow.isHypothicated === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.NO,
                    checked: selectedRow.id
                      ? selectedRow.isHypothicated === false
                      : false,
                    disabled: false,
                  },
                ]}
                name={"isHypothicated"}
                row={true}
                disabled={isSubmit}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">{LABELS.OTHER_DETAILS}</legend>
          <div className="flexRowJustify">
            <div
              className="formGroup width30 "
              style={{ paddingLeft: ".5rem" }}
            >
              <TxtRow
                id={"latitude"}
                label={LABELS.LATITUDE}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.latitude).toString()
                    : selectedRow.latitude
                }
                type={"number"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"longitude"}
                label={LABELS.LONGITUDE}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.longitude).toString()
                    : selectedRow.longitude
                }
                type={"number"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"currentLocation"}
                label={LABELS.CURRENT_LOCATION}
                defaultValue={selectedRow.currentLocation}
                type={"text"}
                disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowJustify">
            <div className="formGroup width30" style={{ paddingLeft: ".5rem" }}>
              <TxtRow
                id={"verifiedOn"}
                label={LABELS.VERIFIED_ON}
                defaultValue={
                  selectedRow.verifiedOn
                    ? DateFunctions.getFormatedDate(
                        selectedRow.verifiedOn,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red">
              <label>
                {LABELS.IS_VAHAN_VERIFIED}
                {<span className="red">{" *"}</span>}
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.YES,
                    checked: selectedRow.id
                      ? selectedRow.isVahanVerified === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.NO,
                    checked: selectedRow.id
                      ? selectedRow.isVahanVerified === false
                      : false,
                    disabled: false,
                  },
                ]}
                name={"isVahanVerified"}
                row={true}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30"></div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width80" style={{ paddingLeft: ".5rem" }}>
              <label>{LABELS.VEHICLE_STATUS}</label>
              <RadioBtn
                name={"vehicleStatus"}
                row={true}
                list={[
                  {
                    id: "0",
                    label: LABELS.FIT,
                    value: LABELS.FIT,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "FIT"
                      : true,
                    disabled: false,
                  },
                  {
                    id: "1",
                    label: LABELS.BREAKDOWN,
                    value: LABELS.BREAKDOWN,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "BREAKDOWN"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "2",
                    label: LABELS.ACCIDENT,
                    value: LABELS.ACCIDENT,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "ACCIDENT"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "3",
                    label: LABELS.UNDER_REPAIR,
                    value: LABELS.UNDER_REPAIR,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "UNDER REPAIR"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "4",
                    label: LABELS.UNDER_MAINTENANCE,
                    value: LABELS.UNDER_MAINTENANCE,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "UNDER MAINTENANCE"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "5",
                    label: LABELS.FOR_SALE,
                    value: LABELS.FOR_SALE,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "FOR SALE"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "6",
                    label: LABELS.SOLD,
                    value: LABELS.SOLD,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "SOLD"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "7",
                    label: LABELS.PAPERS_PENDING,
                    value: LABELS.PAPERS_PENDING,
                    checked: selectedRow.id
                      ? selectedRow.vehicleStatus === "PAPERS PENDING"
                      : false,
                    disabled: false,
                  },
                ]}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30" style={{ paddingLeft: ".5rem" }}>
              <label>{LABELS.ACTIVE_STATUS}</label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === false
                      : false,
                    disabled: false,
                  },
                ]}
                name={"activeStatus"}
                row={true}
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div>
        </fieldset>

        {/* <div className="formGroup">
            <label>
              {LABELS.FILE_UPLOAD}
              <small>
                {" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}
              </small>
            </label>
            <TxtBox
              type={"file"}
              id="uploadFile"
              // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
              className="uploadFile"
              accept="image/*,.pdf"
              onChange={onUploadFile}
            />
            <div
              className="formGroup"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {vehicleFiles.map((u, i) => {
                return (
                  <div className="thumbnailBox width20">
                    <IconButton
                      className="thumbnailBoxIcon"
                      onClick={() => {
                        const list = vehicleFiles.filter((a, j) => j !== i);
                        setVehicleFiles(list);
                      }}
                    >
                      <IconClose />
                    </IconButton>
                    <img
                      onClick={() =>
                        window.open(API_CONFIG.API_URL + u.path, "_blank")
                      }
                      src={
                        u.mimeType === "pdf"
                          ? thumbnailImg
                          : API_CONFIG.STATIC_URL + u.path
                      }
                      className="responsive cursor"
                    />
                  </div>
                );
              })}
              {uploading && (
                <div className="thumbnailBox width20">
                  <SkeletonBox height={300} />
                </div>
              )}
            </div>
          </div> */}
        <fieldset className="formSection">
          <legend className="formSectionLegend">{LABELS.PHOTOS}</legend>
          <div>
            <VehicleDocs
              onClose={onClose}
              manageFiles={manageFiles}
              selectedRow={selectedRow}
              savedFiles={savedFiles}
              vehicleFiles={vehicleFiles}
              setVehicleFiles={setVehicleFiles}
            />
          </div>
        </fieldset>
        {/* mandatoryResults */}
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <div className="formGroup submitBtnWrapper">
          <div className=" flexbtnjustify">
            <Btn
              text={LABELS.CANCEL}
              bgColor={COLORS.RELOAD_EXPORT}
              color={COLORS.RELOAD_EXPORT_TEXT}
              onClick={onClose}
              // loading={isSubmit}
            />
            <Btn
              text={LABELS.SAVE}
              bgColor={COLORS.SAVE_GREEN}
              loading={isSubmit}
              onClick={onSave}
            />
          </div>
        </div>
      </div>
      {/* )}
       {tabIndex === 1 && (
        <div>
          <VehicleDocs
            onClose={onClose}
            manageFiles={manageFiles}
            selectedRow={selectedRow}
            savedFiles={savedFiles}
            vehicleFiles={vehicleFiles}
            setVehicleFiles={setVehicleFiles}
          />
        </div>
      )} */}
    </UiDialog>
  );
};

export default withSnackbar(VehicleForm);
