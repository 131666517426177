import React from "react";
import {
  Person as UserIcon,
  CalendarMonth as CalenderIcon,
} from "@mui/icons-material";
import "./style.css";
import { LABELS } from "common/lables";

const Verified = (props: any) => {
  const { selectedRow } = props;
  return (
    <React.Fragment>
      <fieldset className="formSection" style={{ padding: "2rem" }}>
        <div className="listIcon">
          <UserIcon fontSize="medium" />
          <p>
            <span className="semiBold">{LABELS.VERIFIED_BY}</span>
            <span>{":"}</span>
            <span className="pL10">{selectedRow.verifiedBy || ""}</span>
          </p>
        </div>
        <div className="listIcon">
          <CalenderIcon fontSize="medium" />
          <p>
            <span className="semiBold">{LABELS.VERIFIED_ON}</span>
            <span>{":"}</span>
            <span className="pL10">{selectedRow.verifiedOn || ""}</span>
          </p>
        </div>
      </fieldset>
    </React.Fragment>
  );
};

export default Verified;
