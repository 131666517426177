import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import { NOTIFY, CONFIG, FORMATS } from "common/config";
import { LABELS, WARNINGS, TITLES, SUB_TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { MASTER_APIs } from "apihandlers/master";
import { REPORTS_APIs } from "apihandlers/reports";
import { COLORS } from "common/colors";

const AreaReport = (props: any) => {
  const { setDashMenu, userInfo, dmsReport } = props;

  const outletRef: any = useRef();

  const [loading, setLoading] = useState(false);
  const [masters, setMasters] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "regionName",
      name: LABELS.REGION,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "totalAreas",
      name: LABELS.TOTAL_AREAS,
      width: "10%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
      renderSum: true,
    },
    {
      key: "areaName",
      name: LABELS.AREA_NAME,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "totalRoutes",
      name: LABELS.TOTAL_ROUTES,
      width: "10%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
      renderSum: true,
    },
    {
      key: "routeName",
      name: LABELS.ROUTE_NAME,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "totalCustomers",
      name: LABELS.TOTAL_CUSTOMERS,
      width: "10%",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
      renderSum: true,
    },
  ];

  const loadChart = () => {
    // const outlet = outletRef.current.selectedValue.id || userInfo.orgId;
    const args = {
      // outlet,
      reportType: "excel",
    };
    let errors = [];
    // if (!outlet) {
    //   errors.push({
    //     id: "outlet",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.OUTLET +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,

    const timestamp = new Date().getTime();

    // Create a new Date object using the timestamp
    const date = new Date(timestamp);

    // Define options for formatting
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    // Use toLocaleDateString to format the date
    const formattedDate: string = date.toLocaleDateString("en-GB", options);

    //   });
    // }

    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }
    setRows(null);
    setLoading(true);
    REPORTS_APIs.getRoutesReport(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        for (let i = 0; i < response.rows.length; i++) {
          response.rows[i]["slNo"] = i + 1;
        }
        setRows(response.rows);
        setLoading(false);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    loadChart();
  };

  console.log("Page: AreaReport:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {!dmsReport ? TITLES.REPORTS : TITLES.DMS_REPORT}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.ROUTE_REPORT}</li>
        </ul>
        <h4>{LABELS.ROUTE_REPORT}</h4>
        <p>{LABELS.ROUTE_REPORT}</p>
      </div>

      <div className="hChart">
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="grid excelGridWrapper">
          {!loading &&
            (rows ? (
              <ExcelGrid
                id={"AreaReport"}
                height={window.innerHeight * 0.8}
                cols={columns}
                rows={rows}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.ROUTE_REPORT}
                exportDetails={""}
                exportSplitColumn={
                  LABELS.ROUTE_REPORT +
                  "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                  LABELS.PRINT_DATE +
                  ": " +
                  DateFunctions.getFormatedDate(
                    new Date(),
                    FORMATS.SQL_DATE,
                    FORMATS.PRINT_DATE
                  )
                }
                exportSplitColumnPrefix={
                  LABELS.ROUTE_REPORT +
                  "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                  LABELS.PRINT_DATE +
                  ": " +
                  DateFunctions.getFormatedDate(
                    new Date(),
                    FORMATS.SQL_DATE,
                    FORMATS.PRINT_DATE
                  )
                }
                splitColumnValues={[""]} // Send only If you want to display two or more values
                onReload={reloadGrid}
                title={LABELS.ROUTE_REPORT}
              />
            ) : (
              <div className="flexCenter" style={{ minHeight: 450 }}>
                <Loader />
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(AreaReport);
