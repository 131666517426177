/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import AccessibilityModule from "highcharts/modules/accessibility";
import Drilldown from "highcharts/modules/drilldown";

// import { GRAPH_TYPES } from "common/config";
// import { GRAPHS } from "common/graphs";
// import { COLORS } from "common/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Highcharts3d(Highcharts);
AccessibilityModule(Highcharts);
Drilldown(Highcharts);

interface cProps {
  width: string;
  type: "column";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any[];
  stacked?: boolean;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const ColumnDrilldown = (props: any) => {
  const {
    width,
    type,
    stacked,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  let options = {
    chart: {
      type: "column",
    },
    title: {
      align: "left",
      text: "Browser market shares. January, 2022",
    },
    subtitle: {
      align: "left",
      text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>',
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Total percent market share",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}%",
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
    },

    series: [
      {
        name: "Category 1",
        colorByPoint: true,
        data: [
          { name: "Item 1", y: 100, drilldown: "Item1_Drilldown" },
          { name: "Item 2", y: 150, drilldown: "Item2_Drilldown" },
          { name: "Item 3", y: 80, drilldown: "Item3_Drilldown" },
          { name: "Item 4", y: 120, drilldown: "Item4_Drilldown" },
          { name: "Item 5", y: 200, drilldown: "Item5_Drilldown" },
          { name: "Item 6", y: 110, drilldown: "Item6_Drilldown" },
          { name: "Item 7", y: 90, drilldown: "Item7_Drilldown" },
          { name: "Item 8", y: 140, drilldown: "Item8_Drilldown" },
          { name: "Item 9", y: 75, drilldown: "Item9_Drilldown" },
          { name: "Item 10", y: 160, drilldown: "Item10_Drilldown" },
          { name: "Item 11", y: 135, drilldown: "Item11_Drilldown" },
          { name: "Item 12", y: 115, drilldown: "Item12_Drilldown" },
          { name: "Item 13", y: 70, drilldown: "Item13_Drilldown" },
          { name: "Item 14", y: 130, drilldown: "Item14_Drilldown" },
          { name: "Item 15", y: 180, drilldown: "Item15_Drilldown" },
          { name: "Item 16", y: 95, drilldown: "Item16_Drilldown" },
          { name: "Item 17", y: 110, drilldown: "Item17_Drilldown" },
          { name: "Item 18", y: 85, drilldown: "Item18_Drilldown" },
          { name: "Item 19", y: 120, drilldown: "Item19_Drilldown" },
          { name: "Item 20", y: 105, drilldown: "Item20_Drilldown" },
        ],
      },
    ],
    drilldown: {
      breadcrumbs: {
        position: {
          align: "right",
        },
      },
      series: [
        {
          name: "Item1_Drilldown",
          id: "Item1_Drilldown",
          data: [
            ["Subitem 1.1", 50],
            ["Subitem 1.2", 302],
            ["Subitem 1.3", 20],
            ["Subitem 1.4", 402],
            ["Subitem 1.5", 60],
            ["Subitem 1.6", 452],
            ["Subitem 1.7", 35],
            ["Subitem 1.8", 55],
            ["Subitem 1.9", 25],
            ["Subitem 1.10", 65],
            ["Subitem 1.11", 75],
            ["Subitem 1.12", 515],
            ["Subitem 1.13", 40],
            ["Subitem 1.14", 70],
            ["Subitem 1.15", 90],
            ["Subitem 1.16", 65],
            ["Subitem 1.17", 45],
            ["Subitem 1.18", 25],
            ["Subitem 1.19", 35],
            ["Subitem 1.20", 45],
          ],
        },
        {
          name: "Item2_Drilldown",
          id: "Item2_Drilldown",
          data: [
            ["Subitem 2.1", 150],
            ["Subitem 2.2", 230],
            ["Subitem 2.3", 20],
            ["Subitem 2.4", 340],
            ["Subitem 2.5", 640],
            ["Subitem 2.6", 45],
            ["Subitem 2.7", 354],
            ["Subitem 2.8", 55],
            ["Subitem 2.9", 25],
            ["Subitem 2.10", 645],
            ["Subitem 2.11", 745],
            ["Subitem 2.12", 55],
            ["Subitem 2.13", 40],
            ["Subitem 2.14", 70],
            ["Subitem 2.15", 90],
            ["Subitem 2.16", 655],
            ["Subitem 2.17", 454],
            ["Subitem 2.18", 25],
            ["Subitem 2.19", 35],
            ["Subitem 2.20", 45],
          ],
        },
        {
          name: "Item3_Drilldown",
          id: "Item3_Drilldown",
          data: [
            ["Subitem 3.1", 50],
            ["Subitem 3.2", 30],
            ["Subitem 3.3", 20],
            ["Subitem 3.4", 403],
            ["Subitem 3.5", 60],
            ["Subitem 3.6", 45],
            ["Subitem 3.7", 352],
            ["Subitem 3.8", 55],
            ["Subitem 3.9", 25],
            ["Subitem 3.10", 65],
            ["Subitem 3.11", 75],
            ["Subitem 3.12", 552],
            ["Subitem 3.13", 40],
            ["Subitem 3.14", 70],
            ["Subitem 3.15", 90],
            ["Subitem 3.16", 65],
            ["Subitem 3.17", 452],
            ["Subitem 3.18", 25],
            ["Subitem 3.19", 35],
            ["Subitem 3.20", 45],
          ],
        },
        {
          name: "Item4_Drilldown",
          id: "Item4_Drilldown",
          data: [
            ["Subitem 4.1", 50],
            ["Subitem 4.2", 301],
            ["Subitem 4.3", 20],
            ["Subitem 4.4", 401],
            ["Subitem 4.5", 60],
            ["Subitem 4.6", 45],
            ["Subitem 4.7", 35],
            ["Subitem 4.8", 551],
            ["Subitem 4.9", 25],
            ["Subitem 4.10", 65],
            ["Subitem 4.11", 75],
            ["Subitem 4.12", 155],
            ["Subitem 4.13", 40],
            ["Subitem 4.14", 70],
            ["Subitem 4.15", 90],
            ["Subitem 4.16", 165],
            ["Subitem 4.17", 45],
            ["Subitem 4.18", 25],
            ["Subitem 4.19", 35],
            ["Subitem 4.20", 45],
          ],
        },
        {
          name: "Item5_Drilldown",
          id: "Item5_Drilldown",
          data: [
            ["Subitem 5.1", 50],
            ["Subitem 5.2", 30],
            ["Subitem 5.3", 20],
            ["Subitem 5.4", 406],
            ["Subitem 5.5", 60],
            ["Subitem 5.6", 45],
            ["Subitem 5.7", 357],
            ["Subitem 5.8", 55],
            ["Subitem 5.9", 25],
            ["Subitem 5.10", 65],
            ["Subitem 5.11", 75],
            ["Subitem 5.12", 558],
            ["Subitem 5.13", 40],
            ["Subitem 5.14", 70],
            ["Subitem 5.15", 90],
            ["Subitem 5.16", 65],
            ["Subitem 5.17", 495],
            ["Subitem 5.18", 25],
            ["Subitem 5.19", 35],
            ["Subitem 5.20", 45],
          ],
        },
        {
          name: "Item6_Drilldown",
          id: "Item6_Drilldown",
          data: [
            ["Subitem 6.1", 509],
            ["Subitem 6.2", 30],
            ["Subitem 6.3", 20],
            ["Subitem 6.4", 40],
            ["Subitem 6.5", 609],
            ["Subitem 6.6", 45],
            ["Subitem 6.7", 35],
            ["Subitem 6.8", 559],
            ["Subitem 6.9", 259],
            ["Subitem 6.10", 65],
            ["Subitem 6.11", 75],
            ["Subitem 6.12", 55],
            ["Subitem 6.13", 40],
            ["Subitem 6.14", 970],
            ["Subitem 6.15", 90],
            ["Subitem 6.16", 65],
            ["Subitem 6.17", 45],
            ["Subitem 6.18", 295],
            ["Subitem 6.19", 35],
            ["Subitem 6.20", 45],
          ],
        },
        {
          name: "Item7_Drilldown",
          id: "Item7_Drilldown",
          data: [
            ["Subitem 7.1", 50],
            ["Subitem 7.2", 300],
            ["Subitem 7.3", 20],
            ["Subitem 7.4", 40],
            ["Subitem 7.5", 600],
            ["Subitem 7.6", 45],
            ["Subitem 7.7", 35],
            ["Subitem 7.8", 558],
            ["Subitem 7.9", 25],
            ["Subitem 7.10", 685],
            ["Subitem 7.11", 75],
            ["Subitem 7.12", 585],
            ["Subitem 7.13", 40],
            ["Subitem 7.14", 780],
            ["Subitem 7.15", 90],
            ["Subitem 7.16", 65],
            ["Subitem 7.17", 485],
            ["Subitem 7.18", 25],
            ["Subitem 7.19", 35],
            ["Subitem 7.20", 45],
          ],
        },
        {
          name: "Item8_Drilldown",
          id: "Item8_Drilldown",
          data: [
            ["Subitem 8.1", 580],
            ["Subitem 8.2", 30],
            ["Subitem 8.3", 260],
            ["Subitem 8.4", 405],
            ["Subitem 8.5", 60],
            ["Subitem 8.6", 45],
            ["Subitem 8.7", 355],
            ["Subitem 8.8", 55],
            ["Subitem 8.9", 255],
            ["Subitem 8.10", 655],
            ["Subitem 8.11", 75],
            ["Subitem 8.12", 555],
            ["Subitem 8.13", 40],
            ["Subitem 8.14", 70],
            ["Subitem 8.15", 90],
            ["Subitem 8.16", 565],
            ["Subitem 8.17", 45],
            ["Subitem 8.18", 25],
            ["Subitem 8.19", 35],
            ["Subitem 8.20", 45],
          ],
        },
        {
          name: "Item9_Drilldown",
          id: "Item9_Drilldown",
          data: [
            ["Subitem 9.1", 550],
            ["Subitem 9.2", 530],
            ["Subitem 9.3", 20],
            ["Subitem 9.4", 40],
            ["Subitem 9.5", 650],
            ["Subitem 9.6", 45],
            ["Subitem 9.7", 35],
            ["Subitem 9.8", 555],
            ["Subitem 9.9", 25],
            ["Subitem 9.10", 65],
            ["Subitem 9.11", 755],
            ["Subitem 9.12", 55],
            ["Subitem 9.13", 40],
            ["Subitem 9.14", 70],
            ["Subitem 9.15", 90],
            ["Subitem 9.16", 655],
            ["Subitem 9.17", 45],
            ["Subitem 9.18", 25],
            ["Subitem 9.19", 35],
            ["Subitem 9.20", 45],
          ],
        },
        {
          name: "Item10_Drilldown",
          id: "Item10_Drilldown",
          data: [
            ["Subitem 10.1", 50],
            ["Subitem 10.2", 30],
            ["Subitem 10.3", 20],
            ["Subitem 10.4", 40],
            ["Subitem 10.5", 650],
            ["Subitem 10.6", 45],
            ["Subitem 10.7", 35],
            ["Subitem 10.8", 55],
            ["Subitem 10.9", 25],
            ["Subitem 10.10", 65],
            ["Subitem 10.11", 75],
            ["Subitem 10.12", 555],
            ["Subitem 10.13", 40],
            ["Subitem 10.14", 70],
            ["Subitem 10.15", 950],
            ["Subitem 10.16", 65],
            ["Subitem 10.17", 455],
            ["Subitem 10.18", 25],
            ["Subitem 10.19", 35],
            ["Subitem 10.20", 45],
          ],
        },
        {
          name: "Item11_Drilldown",
          id: "Item11_Drilldown",
          data: [
            ["Subitem 11.1", 50],
            ["Subitem 11.2", 30],
            ["Subitem 11.3", 205],
            ["Subitem 11.4", 40],
            ["Subitem 11.5", 60],
            ["Subitem 11.6", 455],
            ["Subitem 11.7", 35],
            ["Subitem 11.8", 55],
            ["Subitem 11.9", 25],
            ["Subitem 11.10", 655],
            ["Subitem 11.11", 75],
            ["Subitem 11.12", 55],
            ["Subitem 11.13", 40],
            ["Subitem 11.14", 70],
            ["Subitem 11.15", 950],
            ["Subitem 11.16", 65],
            ["Subitem 11.17", 45],
            ["Subitem 11.18", 25],
            ["Subitem 11.19", 35],
            ["Subitem 11.20", 45],
          ],
        },
        {
          name: "Item12_Drilldown",
          id: "Item12_Drilldown",
          data: [
            ["Subitem 12.1", 50],
            ["Subitem 12.2", 350],
            ["Subitem 12.3", 20],
            ["Subitem 12.4", 40],
            ["Subitem 12.5", 60],
            ["Subitem 12.6", 45],
            ["Subitem 12.7", 355],
            ["Subitem 12.8", 55],
            ["Subitem 12.9", 25],
            ["Subitem 12.10", 65],
            ["Subitem 12.11", 755],
            ["Subitem 12.12", 55],
            ["Subitem 12.13", 40],
            ["Subitem 12.14", 70],
            ["Subitem 12.15", 90],
            ["Subitem 12.16", 65],
            ["Subitem 12.17", 455],
            ["Subitem 12.18", 25],
            ["Subitem 12.19", 35],
            ["Subitem 12.20", 45],
          ],
        },
        {
          name: "Item13_Drilldown",
          id: "Item13_Drilldown",
          data: [
            ["Subitem 13.1", 505],
            ["Subitem 13.2", 30],
            ["Subitem 13.3", 20],
            ["Subitem 13.4", 450],
            ["Subitem 13.5", 60],
            ["Subitem 13.6", 45],
            ["Subitem 13.7", 35],
            ["Subitem 13.8", 555],
            ["Subitem 13.9", 25],
            ["Subitem 13.10", 65],
            ["Subitem 13.11", 75],
            ["Subitem 13.12", 55],
            ["Subitem 13.13", 540],
            ["Subitem 13.14", 750],
            ["Subitem 13.15", 90],
            ["Subitem 13.16", 65],
            ["Subitem 13.17", 45],
            ["Subitem 13.18", 25],
            ["Subitem 13.19", 535],
            ["Subitem 13.20", 545],
          ],
        },
        {
          name: "Item14_Drilldown",
          id: "Item14_Drilldown",
          data: [
            ["Subitem 14.1", 50],
            ["Subitem 14.2", 30],
            ["Subitem 14.3", 205],
            ["Subitem 14.4", 40],
            ["Subitem 14.5", 60],
            ["Subitem 14.6", 455],
            ["Subitem 14.7", 35],
            ["Subitem 14.8", 55],
            ["Subitem 14.9", 25],
            ["Subitem 14.10", 65],
            ["Subitem 14.11", 755],
            ["Subitem 14.12", 55],
            ["Subitem 14.13", 40],
            ["Subitem 14.14", 70],
            ["Subitem 14.15", 950],
            ["Subitem 14.16", 65],
            ["Subitem 14.17", 45],
            ["Subitem 14.18", 25],
            ["Subitem 14.19", 35],
            ["Subitem 14.20", 45],
          ],
        },
        {
          name: "Item15_Drilldown",
          id: "Item15_Drilldown",
          data: [
            ["Subitem 15.1", 50],
            ["Subitem 15.2", 350],
            ["Subitem 15.3", 20],
            ["Subitem 15.4", 40],
            ["Subitem 15.5", 60],
            ["Subitem 15.6", 45],
            ["Subitem 15.7", 35],
            ["Subitem 15.8", 55],
            ["Subitem 15.9", 255],
            ["Subitem 15.10", 65],
            ["Subitem 15.11", 75],
            ["Subitem 15.12", 55],
            ["Subitem 15.13", 405],
            ["Subitem 15.14", 70],
            ["Subitem 15.15", 90],
            ["Subitem 15.16", 655],
            ["Subitem 15.17", 45],
            ["Subitem 15.18", 25],
            ["Subitem 15.19", 35],
            ["Subitem 15.20", 45],
          ],
        },
        {
          name: "Item16_Drilldown",
          id: "Item16_Drilldown",
          data: [
            ["Subitem 16.1", 50],
            ["Subitem 16.2", 30],
            ["Subitem 16.3", 205],
            ["Subitem 16.4", 40],
            ["Subitem 16.5", 60],
            ["Subitem 16.6", 45],
            ["Subitem 16.7", 355],
            ["Subitem 16.8", 55],
            ["Subitem 16.9", 25],
            ["Subitem 16.10", 65],
            ["Subitem 16.11", 75],
            ["Subitem 16.12", 55],
            ["Subitem 16.13", 450],
            ["Subitem 16.14", 70],
            ["Subitem 16.15", 90],
            ["Subitem 16.16", 655],
            ["Subitem 16.17", 45],
            ["Subitem 16.18", 25],
            ["Subitem 16.19", 35],
            ["Subitem 16.20", 45],
          ],
        },
        {
          name: "Item17_Drilldown",
          id: "Item17_Drilldown",
          data: [
            ["Subitem 17.1", 504],
            ["Subitem 17.2", 30],
            ["Subitem 17.3", 20],
            ["Subitem 17.4", 40],
            ["Subitem 17.5", 630],
            ["Subitem 17.6", 45],
            ["Subitem 17.7", 35],
            ["Subitem 17.8", 55],
            ["Subitem 17.9", 251],
            ["Subitem 17.10", 65],
            ["Subitem 17.11", 75],
            ["Subitem 17.12", 55],
            ["Subitem 17.13", 40],
            ["Subitem 17.14", 70],
            ["Subitem 17.15", 901],
            ["Subitem 17.16", 65],
            ["Subitem 17.17", 45],
            ["Subitem 17.18", 215],
            ["Subitem 17.19", 35],
            ["Subitem 17.20", 45],
          ],
        },
        {
          name: "Item18_Drilldown",
          id: "Item18_Drilldown",
          data: [
            ["Subitem 18.1", 50],
            ["Subitem 18.2", 30],
            ["Subitem 18.3", 20],
            ["Subitem 18.4", 401],
            ["Subitem 18.5", 60],
            ["Subitem 18.6", 45],
            ["Subitem 18.7", 352],
            ["Subitem 18.8", 55],
            ["Subitem 18.9", 25],
            ["Subitem 18.10", 625],
            ["Subitem 18.11", 75],
            ["Subitem 18.12", 55],
            ["Subitem 18.13", 420],
            ["Subitem 18.14", 70],
            ["Subitem 18.15", 90],
            ["Subitem 18.16", 65],
            ["Subitem 18.17", 245],
            ["Subitem 18.18", 25],
            ["Subitem 18.19", 35],
            ["Subitem 18.20", 45],
          ],
        },
        {
          name: "Item19_Drilldown",
          id: "Item19_Drilldown",
          data: [
            ["Subitem 19.1", 150],
            ["Subitem 19.2", 30],
            ["Subitem 19.3", 20],
            ["Subitem 19.4", 402],
            ["Subitem 19.5", 60],
            ["Subitem 19.6", 45],
            ["Subitem 19.7", 35],
            ["Subitem 19.8", 552],
            ["Subitem 19.9", 25],
            ["Subitem 19.10", 65],
            ["Subitem 19.11", 752],
            ["Subitem 19.12", 55],
            ["Subitem 19.13", 40],
            ["Subitem 19.14", 70],
            ["Subitem 19.15", 920],
            ["Subitem 19.16", 65],
            ["Subitem 19.17", 45],
            ["Subitem 19.18", 225],
            ["Subitem 19.19", 35],
            ["Subitem 19.20", 45],
          ],
        },
        {
          name: "Item20_Drilldown",
          id: "Item20_Drilldown",
          data: [
            ["Subitem 20.1", 50],
            ["Subitem 20.2", 30],
            ["Subitem 20.3", 220],
            ["Subitem 20.4", 40],
            ["Subitem 20.5", 60],
            ["Subitem 20.6", 45],
            ["Subitem 20.7", 352],
            ["Subitem 20.8", 55],
            ["Subitem 20.9", 25],
            ["Subitem 20.10", 65],
            ["Subitem 20.11", 752],
            ["Subitem 20.12", 552],
            ["Subitem 20.13", 402],
            ["Subitem 20.14", 70],
            ["Subitem 20.15", 920],
            ["Subitem 20.16", 65],
            ["Subitem 20.17", 425],
            ["Subitem 20.18", 225],
            ["Subitem 20.19", 135],
            ["Subitem 20.20", 45],
          ],
        },
      ],
    },
  };

  console.log("options: ", options);

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default ColumnDrilldown;

// [
//   {
//     name: "Category 1",
//     colorByPoint: true,
//     data: [
//       { name: "Item 1", y: 100, drilldown: "Item1_Drilldown" },
//       { name: "Item 2", y: 150, drilldown: "Item2_Drilldown" },
//       { name: "Item 3", y: 80, drilldown: "Item3_Drilldown" },
//       { name: "Item 4", y: 120, drilldown: "Item4_Drilldown" },
//       { name: "Item 5", y: 200, drilldown: "Item5_Drilldown" },
//       { name: "Item 6", y: 110, drilldown: "Item6_Drilldown" },
//       { name: "Item 7", y: 90, drilldown: "Item7_Drilldown" },
//       { name: "Item 8", y: 140, drilldown: "Item8_Drilldown" },
//       { name: "Item 9", y: 75, drilldown: "Item9_Drilldown" },
//       { name: "Item 10", y: 160, drilldown: "Item10_Drilldown" },
//       { name: "Item 11", y: 135, drilldown: "Item11_Drilldown" },
//       { name: "Item 12", y: 115, drilldown: "Item12_Drilldown" },
//       { name: "Item 13", y: 70, drilldown: "Item13_Drilldown" },
//       { name: "Item 14", y: 130, drilldown: "Item14_Drilldown" },
//       { name: "Item 15", y: 180, drilldown: "Item15_Drilldown" },
//       { name: "Item 16", y: 95, drilldown: "Item16_Drilldown" },
//       { name: "Item 17", y: 110, drilldown: "Item17_Drilldown" },
//       { name: "Item 18", y: 85, drilldown: "Item18_Drilldown" },
//       { name: "Item 19", y: 120, drilldown: "Item19_Drilldown" },
//       { name: "Item 20", y: 105, drilldown: "Item20_Drilldown" },
//     ],
//   },
//   {
//     name: "Item1_Drilldown",
//     id: "Item1_Drilldown",
//     data: [
//       ["Subitem 1.1", 50],
//       ["Subitem 1.2", 302],
//       ["Subitem 1.3", 20],
//       ["Subitem 1.4", 402],
//       ["Subitem 1.5", 60],
//       ["Subitem 1.6", 452],
//       ["Subitem 1.7", 35],
//       ["Subitem 1.8", 55],
//       ["Subitem 1.9", 25],
//       ["Subitem 1.10", 65],
//       ["Subitem 1.11", 75],
//       ["Subitem 1.12", 515],
//       ["Subitem 1.13", 40],
//       ["Subitem 1.14", 70],
//       ["Subitem 1.15", 90],
//       ["Subitem 1.16", 65],
//       ["Subitem 1.17", 45],
//       ["Subitem 1.18", 25],
//       ["Subitem 1.19", 35],
//       ["Subitem 1.20", 45],
//     ],
//   },
//   {
//     name: "Item2_Drilldown",
//     id: "Item2_Drilldown",
//     data: [
//       ["Subitem 2.1", 150],
//       ["Subitem 2.2", 230],
//       ["Subitem 2.3", 20],
//       ["Subitem 2.4", 340],
//       ["Subitem 2.5", 640],
//       ["Subitem 2.6", 45],
//       ["Subitem 2.7", 354],
//       ["Subitem 2.8", 55],
//       ["Subitem 2.9", 25],
//       ["Subitem 2.10", 645],
//       ["Subitem 2.11", 745],
//       ["Subitem 2.12", 55],
//       ["Subitem 2.13", 40],
//       ["Subitem 2.14", 70],
//       ["Subitem 2.15", 90],
//       ["Subitem 2.16", 655],
//       ["Subitem 2.17", 454],
//       ["Subitem 2.18", 25],
//       ["Subitem 2.19", 35],
//       ["Subitem 2.20", 45],
//     ],
//   },
//   {
//     name: "Item3_Drilldown",
//     id: "Item3_Drilldown",
//     data: [
//       ["Subitem 3.1", 50],
//       ["Subitem 3.2", 30],
//       ["Subitem 3.3", 20],
//       ["Subitem 3.4", 403],
//       ["Subitem 3.5", 60],
//       ["Subitem 3.6", 45],
//       ["Subitem 3.7", 352],
//       ["Subitem 3.8", 55],
//       ["Subitem 3.9", 25],
//       ["Subitem 3.10", 65],
//       ["Subitem 3.11", 75],
//       ["Subitem 3.12", 552],
//       ["Subitem 3.13", 40],
//       ["Subitem 3.14", 70],
//       ["Subitem 3.15", 90],
//       ["Subitem 3.16", 65],
//       ["Subitem 3.17", 452],
//       ["Subitem 3.18", 25],
//       ["Subitem 3.19", 35],
//       ["Subitem 3.20", 45],
//     ],
//   },
//   {
//     name: "Item4_Drilldown",
//     id: "Item4_Drilldown",
//     data: [
//       ["Subitem 4.1", 50],
//       ["Subitem 4.2", 301],
//       ["Subitem 4.3", 20],
//       ["Subitem 4.4", 401],
//       ["Subitem 4.5", 60],
//       ["Subitem 4.6", 45],
//       ["Subitem 4.7", 35],
//       ["Subitem 4.8", 551],
//       ["Subitem 4.9", 25],
//       ["Subitem 4.10", 65],
//       ["Subitem 4.11", 75],
//       ["Subitem 4.12", 155],
//       ["Subitem 4.13", 40],
//       ["Subitem 4.14", 70],
//       ["Subitem 4.15", 90],
//       ["Subitem 4.16", 165],
//       ["Subitem 4.17", 45],
//       ["Subitem 4.18", 25],
//       ["Subitem 4.19", 35],
//       ["Subitem 4.20", 45],
//     ],
//   },
//   {
//     name: "Item5_Drilldown",
//     id: "Item5_Drilldown",
//     data: [
//       ["Subitem 5.1", 50],
//       ["Subitem 5.2", 30],
//       ["Subitem 5.3", 20],
//       ["Subitem 5.4", 406],
//       ["Subitem 5.5", 60],
//       ["Subitem 5.6", 45],
//       ["Subitem 5.7", 357],
//       ["Subitem 5.8", 55],
//       ["Subitem 5.9", 25],
//       ["Subitem 5.10", 65],
//       ["Subitem 5.11", 75],
//       ["Subitem 5.12", 558],
//       ["Subitem 5.13", 40],
//       ["Subitem 5.14", 70],
//       ["Subitem 5.15", 90],
//       ["Subitem 5.16", 65],
//       ["Subitem 5.17", 495],
//       ["Subitem 5.18", 25],
//       ["Subitem 5.19", 35],
//       ["Subitem 5.20", 45],
//     ],
//   },
//   {
//     name: "Item6_Drilldown",
//     id: "Item6_Drilldown",
//     data: [
//       ["Subitem 6.1", 509],
//       ["Subitem 6.2", 30],
//       ["Subitem 6.3", 20],
//       ["Subitem 6.4", 40],
//       ["Subitem 6.5", 609],
//       ["Subitem 6.6", 45],
//       ["Subitem 6.7", 35],
//       ["Subitem 6.8", 559],
//       ["Subitem 6.9", 259],
//       ["Subitem 6.10", 65],
//       ["Subitem 6.11", 75],
//       ["Subitem 6.12", 55],
//       ["Subitem 6.13", 40],
//       ["Subitem 6.14", 970],
//       ["Subitem 6.15", 90],
//       ["Subitem 6.16", 65],
//       ["Subitem 6.17", 45],
//       ["Subitem 6.18", 295],
//       ["Subitem 6.19", 35],
//       ["Subitem 6.20", 45],
//     ],
//   },
//   {
//     name: "Item7_Drilldown",
//     id: "Item7_Drilldown",
//     data: [
//       ["Subitem 7.1", 50],
//       ["Subitem 7.2", 300],
//       ["Subitem 7.3", 20],
//       ["Subitem 7.4", 40],
//       ["Subitem 7.5", 600],
//       ["Subitem 7.6", 45],
//       ["Subitem 7.7", 35],
//       ["Subitem 7.8", 558],
//       ["Subitem 7.9", 25],
//       ["Subitem 7.10", 685],
//       ["Subitem 7.11", 75],
//       ["Subitem 7.12", 585],
//       ["Subitem 7.13", 40],
//       ["Subitem 7.14", 780],
//       ["Subitem 7.15", 90],
//       ["Subitem 7.16", 65],
//       ["Subitem 7.17", 485],
//       ["Subitem 7.18", 25],
//       ["Subitem 7.19", 35],
//       ["Subitem 7.20", 45],
//     ],
//   },
//   {
//     name: "Item8_Drilldown",
//     id: "Item8_Drilldown",
//     data: [
//       ["Subitem 8.1", 580],
//       ["Subitem 8.2", 30],
//       ["Subitem 8.3", 260],
//       ["Subitem 8.4", 405],
//       ["Subitem 8.5", 60],
//       ["Subitem 8.6", 45],
//       ["Subitem 8.7", 355],
//       ["Subitem 8.8", 55],
//       ["Subitem 8.9", 255],
//       ["Subitem 8.10", 655],
//       ["Subitem 8.11", 75],
//       ["Subitem 8.12", 555],
//       ["Subitem 8.13", 40],
//       ["Subitem 8.14", 70],
//       ["Subitem 8.15", 90],
//       ["Subitem 8.16", 565],
//       ["Subitem 8.17", 45],
//       ["Subitem 8.18", 25],
//       ["Subitem 8.19", 35],
//       ["Subitem 8.20", 45],
//     ],
//   },
//   {
//     name: "Item9_Drilldown",
//     id: "Item9_Drilldown",
//     data: [
//       ["Subitem 9.1", 550],
//       ["Subitem 9.2", 530],
//       ["Subitem 9.3", 20],
//       ["Subitem 9.4", 40],
//       ["Subitem 9.5", 650],
//       ["Subitem 9.6", 45],
//       ["Subitem 9.7", 35],
//       ["Subitem 9.8", 555],
//       ["Subitem 9.9", 25],
//       ["Subitem 9.10", 65],
//       ["Subitem 9.11", 755],
//       ["Subitem 9.12", 55],
//       ["Subitem 9.13", 40],
//       ["Subitem 9.14", 70],
//       ["Subitem 9.15", 90],
//       ["Subitem 9.16", 655],
//       ["Subitem 9.17", 45],
//       ["Subitem 9.18", 25],
//       ["Subitem 9.19", 35],
//       ["Subitem 9.20", 45],
//     ],
//   },
//   {
//     name: "Item10_Drilldown",
//     id: "Item10_Drilldown",
//     data: [
//       ["Subitem 10.1", 50],
//       ["Subitem 10.2", 30],
//       ["Subitem 10.3", 20],
//       ["Subitem 10.4", 40],
//       ["Subitem 10.5", 650],
//       ["Subitem 10.6", 45],
//       ["Subitem 10.7", 35],
//       ["Subitem 10.8", 55],
//       ["Subitem 10.9", 25],
//       ["Subitem 10.10", 65],
//       ["Subitem 10.11", 75],
//       ["Subitem 10.12", 555],
//       ["Subitem 10.13", 40],
//       ["Subitem 10.14", 70],
//       ["Subitem 10.15", 950],
//       ["Subitem 10.16", 65],
//       ["Subitem 10.17", 455],
//       ["Subitem 10.18", 25],
//       ["Subitem 10.19", 35],
//       ["Subitem 10.20", 45],
//     ],
//   },
//   {
//     name: "Item11_Drilldown",
//     id: "Item11_Drilldown",
//     data: [
//       ["Subitem 11.1", 50],
//       ["Subitem 11.2", 30],
//       ["Subitem 11.3", 205],
//       ["Subitem 11.4", 40],
//       ["Subitem 11.5", 60],
//       ["Subitem 11.6", 455],
//       ["Subitem 11.7", 35],
//       ["Subitem 11.8", 55],
//       ["Subitem 11.9", 25],
//       ["Subitem 11.10", 655],
//       ["Subitem 11.11", 75],
//       ["Subitem 11.12", 55],
//       ["Subitem 11.13", 40],
//       ["Subitem 11.14", 70],
//       ["Subitem 11.15", 950],
//       ["Subitem 11.16", 65],
//       ["Subitem 11.17", 45],
//       ["Subitem 11.18", 25],
//       ["Subitem 11.19", 35],
//       ["Subitem 11.20", 45],
//     ],
//   },
//   {
//     name: "Item12_Drilldown",
//     id: "Item12_Drilldown",
//     data: [
//       ["Subitem 12.1", 50],
//       ["Subitem 12.2", 350],
//       ["Subitem 12.3", 20],
//       ["Subitem 12.4", 40],
//       ["Subitem 12.5", 60],
//       ["Subitem 12.6", 45],
//       ["Subitem 12.7", 355],
//       ["Subitem 12.8", 55],
//       ["Subitem 12.9", 25],
//       ["Subitem 12.10", 65],
//       ["Subitem 12.11", 755],
//       ["Subitem 12.12", 55],
//       ["Subitem 12.13", 40],
//       ["Subitem 12.14", 70],
//       ["Subitem 12.15", 90],
//       ["Subitem 12.16", 65],
//       ["Subitem 12.17", 455],
//       ["Subitem 12.18", 25],
//       ["Subitem 12.19", 35],
//       ["Subitem 12.20", 45],
//     ],
//   },
//   {
//     name: "Item13_Drilldown",
//     id: "Item13_Drilldown",
//     data: [
//       ["Subitem 13.1", 505],
//       ["Subitem 13.2", 30],
//       ["Subitem 13.3", 20],
//       ["Subitem 13.4", 450],
//       ["Subitem 13.5", 60],
//       ["Subitem 13.6", 45],
//       ["Subitem 13.7", 35],
//       ["Subitem 13.8", 555],
//       ["Subitem 13.9", 25],
//       ["Subitem 13.10", 65],
//       ["Subitem 13.11", 75],
//       ["Subitem 13.12", 55],
//       ["Subitem 13.13", 540],
//       ["Subitem 13.14", 750],
//       ["Subitem 13.15", 90],
//       ["Subitem 13.16", 65],
//       ["Subitem 13.17", 45],
//       ["Subitem 13.18", 25],
//       ["Subitem 13.19", 535],
//       ["Subitem 13.20", 545],
//     ],
//   },
//   {
//     name: "Item14_Drilldown",
//     id: "Item14_Drilldown",
//     data: [
//       ["Subitem 14.1", 50],
//       ["Subitem 14.2", 30],
//       ["Subitem 14.3", 205],
//       ["Subitem 14.4", 40],
//       ["Subitem 14.5", 60],
//       ["Subitem 14.6", 455],
//       ["Subitem 14.7", 35],
//       ["Subitem 14.8", 55],
//       ["Subitem 14.9", 25],
//       ["Subitem 14.10", 65],
//       ["Subitem 14.11", 755],
//       ["Subitem 14.12", 55],
//       ["Subitem 14.13", 40],
//       ["Subitem 14.14", 70],
//       ["Subitem 14.15", 950],
//       ["Subitem 14.16", 65],
//       ["Subitem 14.17", 45],
//       ["Subitem 14.18", 25],
//       ["Subitem 14.19", 35],
//       ["Subitem 14.20", 45],
//     ],
//   },
//   {
//     name: "Item15_Drilldown",
//     id: "Item15_Drilldown",
//     data: [
//       ["Subitem 15.1", 50],
//       ["Subitem 15.2", 350],
//       ["Subitem 15.3", 20],
//       ["Subitem 15.4", 40],
//       ["Subitem 15.5", 60],
//       ["Subitem 15.6", 45],
//       ["Subitem 15.7", 35],
//       ["Subitem 15.8", 55],
//       ["Subitem 15.9", 255],
//       ["Subitem 15.10", 65],
//       ["Subitem 15.11", 75],
//       ["Subitem 15.12", 55],
//       ["Subitem 15.13", 405],
//       ["Subitem 15.14", 70],
//       ["Subitem 15.15", 90],
//       ["Subitem 15.16", 655],
//       ["Subitem 15.17", 45],
//       ["Subitem 15.18", 25],
//       ["Subitem 15.19", 35],
//       ["Subitem 15.20", 45],
//     ],
//   },
//   {
//     name: "Item16_Drilldown",
//     id: "Item16_Drilldown",
//     data: [
//       ["Subitem 16.1", 50],
//       ["Subitem 16.2", 30],
//       ["Subitem 16.3", 205],
//       ["Subitem 16.4", 40],
//       ["Subitem 16.5", 60],
//       ["Subitem 16.6", 45],
//       ["Subitem 16.7", 355],
//       ["Subitem 16.8", 55],
//       ["Subitem 16.9", 25],
//       ["Subitem 16.10", 65],
//       ["Subitem 16.11", 75],
//       ["Subitem 16.12", 55],
//       ["Subitem 16.13", 450],
//       ["Subitem 16.14", 70],
//       ["Subitem 16.15", 90],
//       ["Subitem 16.16", 655],
//       ["Subitem 16.17", 45],
//       ["Subitem 16.18", 25],
//       ["Subitem 16.19", 35],
//       ["Subitem 16.20", 45],
//     ],
//   },
//   {
//     name: "Item17_Drilldown",
//     id: "Item17_Drilldown",
//     data: [
//       ["Subitem 17.1", 504],
//       ["Subitem 17.2", 30],
//       ["Subitem 17.3", 20],
//       ["Subitem 17.4", 40],
//       ["Subitem 17.5", 630],
//       ["Subitem 17.6", 45],
//       ["Subitem 17.7", 35],
//       ["Subitem 17.8", 55],
//       ["Subitem 17.9", 251],
//       ["Subitem 17.10", 65],
//       ["Subitem 17.11", 75],
//       ["Subitem 17.12", 55],
//       ["Subitem 17.13", 40],
//       ["Subitem 17.14", 70],
//       ["Subitem 17.15", 901],
//       ["Subitem 17.16", 65],
//       ["Subitem 17.17", 45],
//       ["Subitem 17.18", 215],
//       ["Subitem 17.19", 35],
//       ["Subitem 17.20", 45],
//     ],
//   },
//   {
//     name: "Item18_Drilldown",
//     id: "Item18_Drilldown",
//     data: [
//       ["Subitem 18.1", 50],
//       ["Subitem 18.2", 30],
//       ["Subitem 18.3", 20],
//       ["Subitem 18.4", 401],
//       ["Subitem 18.5", 60],
//       ["Subitem 18.6", 45],
//       ["Subitem 18.7", 352],
//       ["Subitem 18.8", 55],
//       ["Subitem 18.9", 25],
//       ["Subitem 18.10", 625],
//       ["Subitem 18.11", 75],
//       ["Subitem 18.12", 55],
//       ["Subitem 18.13", 420],
//       ["Subitem 18.14", 70],
//       ["Subitem 18.15", 90],
//       ["Subitem 18.16", 65],
//       ["Subitem 18.17", 245],
//       ["Subitem 18.18", 25],
//       ["Subitem 18.19", 35],
//       ["Subitem 18.20", 45],
//     ],
//   },
//   {
//     name: "Item19_Drilldown",
//     id: "Item19_Drilldown",
//     data: [
//       ["Subitem 19.1", 150],
//       ["Subitem 19.2", 30],
//       ["Subitem 19.3", 20],
//       ["Subitem 19.4", 402],
//       ["Subitem 19.5", 60],
//       ["Subitem 19.6", 45],
//       ["Subitem 19.7", 35],
//       ["Subitem 19.8", 552],
//       ["Subitem 19.9", 25],
//       ["Subitem 19.10", 65],
//       ["Subitem 19.11", 752],
//       ["Subitem 19.12", 55],
//       ["Subitem 19.13", 40],
//       ["Subitem 19.14", 70],
//       ["Subitem 19.15", 920],
//       ["Subitem 19.16", 65],
//       ["Subitem 19.17", 45],
//       ["Subitem 19.18", 225],
//       ["Subitem 19.19", 35],
//       ["Subitem 19.20", 45],
//     ],
//   },
//   {
//     name: "Item20_Drilldown",
//     id: "Item20_Drilldown",
//     data: [
//       ["Subitem 20.1", 50],
//       ["Subitem 20.2", 30],
//       ["Subitem 20.3", 220],
//       ["Subitem 20.4", 40],
//       ["Subitem 20.5", 60],
//       ["Subitem 20.6", 45],
//       ["Subitem 20.7", 352],
//       ["Subitem 20.8", 55],
//       ["Subitem 20.9", 25],
//       ["Subitem 20.10", 65],
//       ["Subitem 20.11", 752],
//       ["Subitem 20.12", 552],
//       ["Subitem 20.13", 402],
//       ["Subitem 20.14", 70],
//       ["Subitem 20.15", 920],
//       ["Subitem 20.16", 65],
//       ["Subitem 20.17", 425],
//       ["Subitem 20.18", 225],
//       ["Subitem 20.19", 135],
//       ["Subitem 20.20", 45],
//     ],
//   },
// ];
