import React from "react";
import HGraph from "components/Custom/HGraph";

import { GRAPH_TYPES } from "common/config";
import { LABELS } from "common/lables";
import { COLORS } from "common/colors";

import "./styles.css";

const road = require("assets/images/road-image.jpg");
const car = require("assets/images/car-image.png");
const nature = require("assets/images/nature.jpg");

const series = [
  {
    name: "Browsers",
    colorByPoint: true,
    data: [
      {
        name: "Call",
        y: 61.04,
        drilldown: "Call",
      },
      {
        name: "Website",
        y: 9.47,
        drilldown: "Website",
      },
      {
        name: "Social Media",
        y: 9.32,
        drilldown: "Social Media",
      },
      {
        name: "Team",
        y: 8.15,
        drilldown: "Team",
      },
      {
        name: "Other",
        y: 11.02,
        drilldown: null,
      },
    ],
  },
];

const RoiDashboard = () => {
  console.log("Page: RoiDashboard");
  return (
    <>
      <div className="roiContainer">
        <div className="roiLeft">
          <img src={nature} width={"100%"} height={"100%"} alt="nature" />
        </div>

        <div className="roiRight">
          <div className="dashboardWrapper">
            <div className="dashboardCard">
              <h4 style={{ color: COLORS.PRIMARY, fontSize: "2rem" }}>
                Rs 2,82,000
              </h4>
              <h4>{LABELS.TOTAL_VALUES}</h4>
            </div>
            <div className="dashboardCard">
              <h4 style={{ color: COLORS.PRIMARY, fontSize: "2rem" }}>
                Rs 5,75,000
              </h4>
              <h4>{LABELS.CASH_IN_BANK}</h4>
            </div>
          </div>
          <div className="dashboardWrapper">
            <div className="dashboardCard">
              <span className="green">3200</span>
              <h4 style={{ color: COLORS.RED, fontSize: "2rem" }}>68%</h4>
              <h4>{LABELS.ACTIVE_OUTLETS}</h4>
            </div>
            <div className="dashboardCard">
              <h4 style={{ color: COLORS.PRIMARY, fontSize: "2rem" }}>281</h4>
              <h4>{LABELS.NON_SERVICES_OUTLETS}</h4>
            </div>
          </div>
          <div className="dashboardWrapper">
            <div className="dashboardCard">
              <span>29,00,000</span>
              <h4 style={{ color: COLORS.PRIMARY, fontSize: "2rem" }}>77%</h4>
              <h4>{LABELS.TARGET}</h4>
            </div>
            <div className="dashboardCard">
              <h4 style={{ color: COLORS.PRIMARY, fontSize: "2rem" }}>
                Rs 3,71,475
              </h4>
              <h4>{LABELS.OUTSTANDING}</h4>
            </div>
          </div>

          <div className="dashboardWrapper">
            <div className="dashboardCard">
              <h4 style={{ marginBottom: "1.8rem" }}>
                {LABELS.OPEN_CRM_MAP_VIEW}
              </h4>
            </div>
            <div className="dashboardCard">
              <h4>{LABELS.TOP_PRODUCTS}</h4>
              <li className="flexRow">
                <ul>
                  {"Godhiya "}
                  <span className="fontL" style={{ color: COLORS.PRIMARY }}>
                    47%
                  </span>
                </ul>
                <ul>
                  {"Chips "}
                  <span className="fontL" style={{ color: COLORS.PRIMARY }}>
                    34%
                  </span>
                </ul>
              </li>
            </div>
          </div>
          <div className="dashboardWrapper">
            <div className="dashboardCard">
              <HGraph
                width={"100%"}
                height={"50%"}
                type={GRAPH_TYPES.COLUMN}
                float={"right"}
                xAxis={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                yAxis={[20, 25, 32, 40, 65, 68, 55, 43, 0, 0, 0, 0]}
                title={" "}
                exportTitle={" Column Graph Record "}
                xAxisTitle=""
                yAxisTitle="Leads"
              />
            </div>
            <div className="dashboardCard">
              <HGraph
                width={"100%"}
                height={"52%"}
                type={GRAPH_TYPES.PIE}
                float={"right"}
                series={series}
                title={"Lead Source"}
                exportTitle={" Pie Chart Record"}
              />
            </div>
          </div>
        </div>

        <div className="imageWrapper">
          <img src={road} alt="road" />
          <div className="carImageWrapper">
            <img src={car} alt="trip"></img>
            <img src={car} alt="trip"></img>
            <img src={car} alt="trip"></img>
            <img src={car} alt="trip"></img>
            <img src={car} alt="trip"></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoiDashboard;
