import React from "react";
import { withSnackbar } from "notistack";

import { LABELS, TITLES } from "../../../common/lables";

const imgLogo = require("assets/images/crmLifeCycle.png");

const CRMLifecycle = (props: any) => {
  const { setDashMenu } = props;

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.CRM_LIFECYCLE}</li>
        </ul>
        <h4>{TITLES.CRM_LIFECYCLE}</h4>
        {/* <p>{SUB_TITLES.SURVEY}</p> */}
      </div>
      <div className="CRMLifecycle">
        <img src={imgLogo} />
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(CRMLifecycle);
