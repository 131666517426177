import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import Btn from "../../components/Custom/Btn";

// Reports
import Report1 from "../Report1";

import Product from "../Masters/Product";
import Category from "../Masters/Category";
import Tax from "../Masters/Tax";
import TrainingView from "../Training/TrainingView";
import Country from "../Masters/Country";
import State from "../Masters/State";
import Beat from "../Masters/Beat";
import TrainingUpload from "../Training/TrainingUpload";
import TrainingAssign from "../Training/TrainingAssign";
import Grams from "../Masters/Grams";
import SKU from "../Masters/SKU";
import SubCategory from "../Masters/SubCategory";

import ReportEmployeeLeaves from "../../components/Reports/ReportEmployeeLeaves";
import UserLogReport from "../../components/Reports/UserLogReport";

import { CONFIG } from "../../common/config";
import { LABELS } from "../../common/lables";

import "./dashboard.css";
import "./styles/reports.css";
import "./styles/datatables.min.css";
import "devextreme/dist/css/dx.light.css";
import TrainingFiles from "./Reports/TrainingFiles";
import TrainingAssignments from "./Reports/TrainingAssignments";
import TrainingStatus from "./Reports/TrainingStatus";
//../../assets/images/reports/employeeleavesreport.png
const employeeLeavesReport = require("assets/images/reports/employeeleavesreport.png");
const chart1 = require("assets/images/reports/chart1.png");
const chartResourceHistogram = require("assets/images/reports/Resource_Histogram.png");
const chartResourceTable = require("assets/images/reports/Resource_Table.png");
const chartManHoursPlan = require("assets/images/reports/Man_Hours_Distribution.png");
const chartProgressCurve = require("assets/images/reports/Progress_S_Curve.png");
const chartProjectCashFlow = require("assets/images/reports/Project_Cashflow.png");
const chartProgressStatus = require("assets/images/reports/Project_Progress_Pie.png");
const chartProjectOverview = require("assets/images/reports/Project_Overview.png");
const chartRisksRegister = require("assets/images/reports/Risk_Register.png");
const chartEarnedValue = require("assets/images/reports/Earned_Value_Report.png");
const chartQuantitiesReport = require("assets/images/reports/Quantities_Report.png");
const chartManhoursReport = require("assets/images/reports/Manhours_Report.png");
const chartCostReport = require("assets/images/reports/Cost_Report.png");
const chartConstraintLogReport = require("assets/images/reports/Constraint_Log_Report.png");
const chartTimeSheetLog = require("assets/images/reports/Time_Sheet_Log_Report.png");
const chartApprovalLog = require("assets/images/reports/Approval_Log_Report.png");
const userLogreport = require("assets/images/reports/User_Log_Report.png");
const invoicereport = require("assets/images/reports/invoicereport.png");
const analysisReport = require("assets/images/reports/projectsreport.png");

const Training = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo, setPageMenu } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li onClick={() => setPageMenu(LABELS.HOME)}>{LABELS.HOME}</li>

              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.TRAINING}</li>
            </ul>
            <h4>{LABELS.TRAINING}</h4>
          </div>

          <h4 className="subHeadings">{LABELS.TRAINING}</h4>
          <MenuList dense className="dashboardMenuWrap">
            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.GEO_REPORT);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.GEO_REPORT}</h4>
            </MenuItem> */}

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TRAINING);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.TRAINING}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TRAINING_ASSIGN);
              }}
            >
              <img src={chartCostReport} />
              <h4>{LABELS.TRAINING_ASSIGN}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TRAINING_VIEW);
              }}
            >
              <img src={chartCostReport} />
              <h4>{LABELS.TRAINING_VIEW}</h4>
            </MenuItem>

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TAX);
              }}
            >
              <img src={chartCostReport} />
              <h4>{LABELS.TAX}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CATEGORY);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.CATEGORY}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PRODUCT);
              }}
            >
              <img src={analysisReport} />
              <h4>{LABELS.PRODUCT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.REPORT1);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.REPORT1}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.COUNTRY);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.COUNTRY}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.STATE);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.STATE}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.BEAT);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.BEAT_PJP}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.GRAMS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.GRAMS}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.BOX);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.BOX}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SUB_CATEGORY);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.SUB_CATEGORY}</h4>
            </MenuItem> */}
          </MenuList>
          <h4 className="subHeadings">{LABELS.REPORTS}</h4>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TRAINING_FILES);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.TRAINING_FILES}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TRAINING_ASSIGNMENTS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.TRAINING_ASSIGNMENTS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TRAINING_STATUS);
              }}
            >
              <img src={employeeLeavesReport} />
              <h4>{LABELS.TRAINING_STATUS}</h4>
            </MenuItem>
          </MenuList>
        </div>
      )}
      {dashMenu == LABELS.TRAINING && (
        <TrainingUpload setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TRAINING_ASSIGN && (
        <TrainingAssign setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TRAINING_VIEW && (
        <TrainingView setDashMenu={setDashMenu} />
      )}
      {/* {dashMenu == LABELS.TAX && <Tax setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.CATEGORY && <Category setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.PRODUCT && <Product setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.REPORT1 && <Report1 setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.COUNTRY && <Country setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.STATE && <State setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.BEAT && <Beat setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.GRAMS && <Grams setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.BOX && <Box setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.SUB_CATEGORY && (
        <SubCategory setDashMenu={setDashMenu} />
      )} */}

      {dashMenu == LABELS.TRAINING_FILES && (
        <TrainingFiles setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TRAINING_ASSIGNMENTS && (
        <TrainingAssignments setDashMenu={setDashMenu} />
      )}
      {dashMenu == LABELS.TRAINING_STATUS && (
        <TrainingStatus setDashMenu={setDashMenu} />
      )}
    </React.Fragment>
  );
};

export default Training;
