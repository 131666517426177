import React, { Component, useState } from "react";
import {
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  ListItemButton,
  Collapse,
  List,
  IconButton,
} from "@mui/material";
import {
  Home as IconHome,
  Menu as IconMenu,
  PowerSettingsNew as IconLogout,
  Group as IconUsers,
  FolderCopy as IconProjects,
  CloudUpload as IconUpload,
  InsertChart as IconChart,
  Dashboard as IconDashboard,
  AccountBalance as IconOrg,
  CheckBoxOutlined as IconApprove,
  Settings as IconSettings,
  PermMedia as IconProjectStatus,
  Margin as IconResourcePlanning,
  PunchClock as IconTimeSheet,
  ExpandLess,
  ExpandMore,
  Dns as IconMaster,
  TextSnippet as IconReports,
  Groups as IconMeeting,
  GridView as IconCrmDashboard,
  ManageAccounts as IconCrm,
  PeopleOutline as IconLeadProfile,
  OndemandVideo as IconTraining,
  Functions as IconRoi,
  ContentCopy as IconCrmReports,
  Store as IconCustomers,
  Diversity3 as IconTeam,
  LocalGroceryStore as IconOrders,
  MonetizationOn as IconSales,
  ShoppingCart as IconPurchase,
  Receipt as IconPhysicalVoucher,
  FactCheck as IconPhysicalVoucherApproval,
} from "@mui/icons-material";
import { withSnackbar } from "notistack";

import ConfirmDialog from "components/Custom/ConfirmDialog";

import { DEFAULTS, NOTIFY, LOCAL_STORAGE, CONFIG } from "common/config";
import { CONFIRMS, LABELS, SUCCESS, ERRORS, TITLES } from "common/lables";
import { AUTH_APIs } from "apihandlers/auth";
import { COMMON_JS } from "common/scripts";

const LeftBar = (props: any) => {
  const { page, setPage, userInfo, childRef } = props;
  const [leftBar, setLeftBar] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [showMasterMenu, setShowMasterMenu] = useState(false);
  const [showCrmMenu, setShowCrmMenu] = useState(false);
  const [showSalesMenu, setShowSalesMenu] = useState(false);
  const [showPurchaseMenu, setShowPurchaseMenu] = useState(false);
  const [showCustomerMenu, setCustomerMenu] = useState(false);
  const [showTeamMenu, setTeamMenu] = useState(false);
  const [showOrderMenu, setOrderMenu] = useState(false);

  const onUserLogout = () => {
    AUTH_APIs.logout({}).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.LOGOUT_SUCCESS);
        localStorage.removeItem(LOCAL_STORAGE.USER_INFO);
        localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
        setLogoutPopup(false);
        setTimeout(() => {
          window.open("/login", "_self");
        }, 500);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.LOGOUT_FAILED);
      }
    });
  };

  const onSetLeftBar = (val: boolean) => {
    setLeftBar(val);
    if (val) {
      (document.querySelector(".content") as HTMLElement).classList.add(
        "active"
      );
    } else {
      (document.querySelector(".content") as HTMLElement).classList.remove(
        "active"
      );
      setShowMasterMenu(false);
    }
  };

  const roleId = userInfo.role.id;

  let mainMasterAccess = roleId === CONFIG.USER_ROLES.Admin;

  let tripEntryAccess =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Placement;

  let masterEntryLevel = false;
  let transactionEntryLevel = false;
  if (roleId === CONFIG.USER_ROLES.Admin) {
    masterEntryLevel = true;
    transactionEntryLevel = true;
  } else {
    masterEntryLevel = true;
    transactionEntryLevel = true;
  }

  let menuAdmin = roleId === CONFIG.USER_ROLES.Admin;

  let crmMenu = true;
  let salesMenu = true;
  let menuCrmDashboard = true;
  let menuSalesOrder = true;
  let menuSalesInvoice = true;
  let menuSalesReturn = true;
  let menuAddSalesInvoice = true;
  let menuEInvoice = true;
  let menuCrmLeadProfile = true;
  let purchaseMenu = true;
  let menuAddPurchaseBill = true;
  let menuPurchaseBill = true;
  let menuPurchaseReturn = true;
  let menuPhysicalVoucher = true;
  let menuCrmFeedback = true;
  let menuCrmSurvey = true;
  let menuRoi = true;
  let menuCrmReports = true;
  let customerMenu = true;
  let customer = true;
  let customerType = true;
  let teamMenu = true;
  let PJP = true;
  let Ledger = true;
  let LeaveApplication = true;
  let attendance = true;
  let track = true;
  let orderMenu = true;
  let stockLoad = true;
  let order = true;
  let Challan = true;
  let Invoice = true;
  let Return = true;
  let loadReturn = true;
  let leaveApproval = true;
  let primaryOrder = true;
  let ordersLifecycle = true;
  let CRMLifecycle = true;
  let menuCustomersOnMap = true;

  if (childRef) {
    childRef.hideCrmMenu = () => {
      setShowCrmMenu(false);
    };
  }

  console.log("showCrmMenu:", showCrmMenu);

  return (
    <div className={"leftBar " + (leftBar ? "active" : "")}>
      <MenuList className="leftMenuWrapper">
        <MenuItem className={"leftMenu"} onClick={() => onSetLeftBar(!leftBar)}>
          <ListItemIcon>
            <IconMenu fontSize="small" />
          </ListItemIcon>
          <div>
            <h4>
              {CONFIG.SITE_NAME} <small>{CONFIG.VERSION}</small>
            </h4>
            {/* <img src={logoSmall} className="responsive" /> */}
          </div>
        </MenuItem>

        <MenuItem
          className={
            "leftMenu " +
            (page === LABELS.HOME || page === LABELS.MENU || page === ""
              ? "active"
              : "")
          }
          onClick={() => setPage(LABELS.HOME)}
        >
          <Tooltip title={LABELS.HOME} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconHome fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.HOME}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        {menuCustomersOnMap && (
          <MenuItem
            className={
              "leftMenu " + (page === TITLES.CUSTOMERS_ON_MAP ? "active" : "")
            }
            onClick={() => setPage(TITLES.CUSTOMERS_ON_MAP)}
          >
            <Tooltip title={TITLES.CUSTOMERS_ON_MAP} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconCustomers fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {TITLES.CUSTOMERS_ON_MAP}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {/* <MenuItem
          className={"leftMenu " + (page === TITLES.MY_MAP ? "active" : "")}
          onClick={() => setPage(TITLES.MY_MAP)}
        >
          <Tooltip title={TITLES.MY_MAP} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconCustomers fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{TITLES.MY_MAP}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem> */}

        {/* <MenuItem
          className={"leftMenu " + (page === TITLES.POS_REPORT ? "active" : "")}
          onClick={() => setPage(TITLES.POS_REPORT)}
        >
          <Tooltip title={TITLES.POS_REPORT} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconReports fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {TITLES.POS_REPORT}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem> */}

        {/* <MenuItem
          className={"leftMenu " + (page === TITLES.DMS_REPORT ? "active" : "")}
          onClick={() => setPage(TITLES.DMS_REPORT)}
        >
          <Tooltip title={TITLES.DMS_REPORT} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconReports fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {TITLES.DMS_REPORT}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem> */}

        <MenuItem
          className={"leftMenu " + (page === LABELS.REPORTS ? "active" : "")}
          onClick={() => setPage(LABELS.REPORTS)}
        >
          <Tooltip title={LABELS.REPORTS} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconReports fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.REPORTS}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        <MenuItem
          className={"leftMenu " + (page === LABELS.MASTERS ? "active" : "")}
          onClick={() => setPage(LABELS.MASTERS)}
        >
          <Tooltip title={LABELS.MASTERS} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconMaster fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.MASTERS}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        {/* <MenuItem
          className={
            "leftMenu " + (page === TITLES.ROI_DASHBOARD ? "active" : "")
          }
          onClick={() => setPage(TITLES.ROI_DASHBOARD)}
        >
          <Tooltip title={TITLES.ROI_DASHBOARD} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconMaster fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {TITLES.ROI_DASHBOARD}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>
        <MenuItem
          className={
            "leftMenu " + (page === TITLES.GRAPH_DASHBOARD ? "active" : "")
          }
          onClick={() => setPage(TITLES.GRAPH_DASHBOARD)}
        >
          <Tooltip title={TITLES.GRAPH_DASHBOARD} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconMaster fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {TITLES.GRAPH_DASHBOARD}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        {/*
        <MenuItem
          className={"leftMenu " + (page === LABELS.CUSTOMERS ? "active" : "")}
          onClick={() => setPage(LABELS.CUSTOMERS)}
        >
          <Tooltip title={LABELS.CUSTOMERS} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconCustomers fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {LABELS.CUSTOMERS}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        {teamMenu && (
          <ListItemButton
            onClick={() => {
              setTeamMenu(!showTeamMenu);
            }}
            className="leftDropDownMenu"
          >
            <ListItemIcon className="leftDropDownMenuIcon">
              <IconTeam />
            </ListItemIcon>
            {leftBar && (
              <ListItemText
                className="menuText leftDropDownMenuText"
                primary={LABELS.TEAM}
              />
            )}
            {leftBar && (showTeamMenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )} */}
        {/* <Collapse in={showTeamMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {PJP && (
              <MenuItem
                className={
                  "leftMenu subMenu " + (page === TITLES.PJP ? "active" : "")
                }
                onClick={() => setPage(TITLES.PJP)}
              >
                <Tooltip title={TITLES.PJP} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.PJP}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {Ledger && (
              <MenuItem
                className={
                  "leftMenu subMenu " + (page === TITLES.LEDGER ? "active" : "")
                }
                onClick={() => setPage(TITLES.LEDGER)}
              >
                <Tooltip title={TITLES.LEDGER} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.LEDGER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {LeaveApplication && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.LEAVE_APPLICATION ? "active" : "")
                }
                onClick={() => setPage(TITLES.LEAVE_APPLICATION)}
              >
                <Tooltip
                  title={TITLES.LEAVE_APPLICATION}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.LEAVE_APPLICATION}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {leaveApproval && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.LEAVE_APPROVAL ? "active" : "")
                }
                onClick={() => setPage(TITLES.LEAVE_APPROVAL)}
              >
                <Tooltip
                  title={TITLES.LEAVE_APPROVAL}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.LEAVE_APPROVAL}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {attendance && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.ATTENDENCE ? "active" : "")
                }
                onClick={() => setPage(TITLES.ATTENDENCE)}
              >
                <Tooltip title={TITLES.ATTENDENCE} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.ATTENDENCE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {track && (
              <MenuItem
                className={
                  "leftMenu subMenu " + (page === TITLES.TRACK ? "active" : "")
                }
                onClick={() => setPage(TITLES.TRACK)}
              >
                <Tooltip title={TITLES.TRACK} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.TRACK}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
          </List>
        </Collapse> */}
        {/* {orderMenu && (
          <ListItemButton
            onClick={() => {
              setOrderMenu(!showOrderMenu);
            }}
            className="leftDropDownMenu"
          >
            <ListItemIcon className="leftDropDownMenuIcon">
              <IconOrders />
            </ListItemIcon>
            {leftBar && (
              <ListItemText
                className="menuText leftDropDownMenuText"
                primary={LABELS.ORDERS}
              />
            )}
            {leftBar && (showOrderMenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )} */}
        {/* <Collapse in={showOrderMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {stockLoad && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.STOCK_LOAD ? "active" : "")
                }
                onClick={() => setPage(TITLES.STOCK_LOAD)}
              >
                <Tooltip title={TITLES.STOCK_LOAD} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.STOCK_LOAD}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {order && (
              <MenuItem
                className={
                  "leftMenu subMenu " + (page === TITLES.ORDER ? "active" : "")
                }
                onClick={() => setPage(TITLES.ORDER)}
              >
                <Tooltip title={TITLES.ORDER} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.ORDER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {Challan && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.CHALLAN ? "active" : "")
                }
                onClick={() => setPage(TITLES.CHALLAN)}
              >
                <Tooltip title={TITLES.CHALLAN} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.CHALLAN}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {Invoice && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.INVOICE ? "active" : "")
                }
                onClick={() => setPage(TITLES.INVOICE)}
              >
                <Tooltip title={TITLES.INVOICE} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.INVOICE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {Return && (
              <MenuItem
                className={
                  "leftMenu subMenu " + (page === TITLES.RETURN ? "active" : "")
                }
                onClick={() => setPage(TITLES.RETURN)}
              >
                <Tooltip title={TITLES.RETURN} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.RETURN}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {loadReturn && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.LOAD_RETURN ? "active" : "")
                }
                onClick={() => setPage(TITLES.LOAD_RETURN)}
              >
                <Tooltip title={TITLES.LOAD_RETURN} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.LOAD_RETURN}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {primaryOrder && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.PRIMARY_ORDER ? "active" : "")
                }
                onClick={() => setPage(TITLES.PRIMARY_ORDER)}
              >
                <Tooltip title={TITLES.PRIMARY_ORDER} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.PRIMARY_ORDER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {ordersLifecycle && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.ORDERS_LIFECYCLE ? "active" : "")
                }
                onClick={() => setPage(TITLES.ORDERS_LIFECYCLE)}
              >
                <Tooltip
                  title={TITLES.ORDERS_LIFECYCLE}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.ORDERS_LIFECYCLE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
          </List>
        </Collapse>

        <MenuItem
          className={"leftMenu " + (page === LABELS.TRAINING ? "active" : "")}
          onClick={() => setPage(LABELS.TRAINING)}
        >
          <Tooltip title={LABELS.TRAINING} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconTraining fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {LABELS.TRAINING}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem> */}

        {/* {masterEntryLevel && (
          <ListItemButton
            onClick={() => {
              setShowMasterMenu(!showMasterMenu);
            }}
            style={{
              paddingLeft: 20,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: 26,
                position: "relative",
                left: leftBar ? 0 : -5,
              }}
            >
              <IconMaster style={{ fontSize: 18 }} />
            </ListItemIcon>
            {leftBar && (
              <ListItemText
                className="menuText"
                primary="Masters"
                style={{
                  textAlign: "left",
                  color: "#fff",
                  marginLeft: 18,
                }}
              />
            )}
            {leftBar && (showMasterMenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )}
        <Collapse in={showMasterMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.HOLIDAY_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.HOLIDAY_MASTER)}
              >
                <Tooltip
                  title={LABELS.HOLIDAY_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.HOLIDAY_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.TASK_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.TASK_MASTER)}
              >
                <Tooltip title={LABELS.TASK_MASTER} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.TASK_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.GPT_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.GPT_MASTER)}
              >
                <Tooltip title={LABELS.GPT_MASTER} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.GPT_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.PRICE ? "active" : "")
                }
                onClick={() => setPage(LABELS.PRICE)}
              >
                <Tooltip title={LABELS.PRICE} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.PRICE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.SCHEME ? "active" : "")
                }
                onClick={() => setPage(LABELS.SCHEME)}
              >
                <Tooltip title={LABELS.SCHEME} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.SCHEME}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.PROJECT_TYPE_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.PROJECT_TYPE_MASTER)}
              >
                <Tooltip
                  title={LABELS.PROJECT_TYPE_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.PROJECT_TYPE_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.PROJECTS ? "active" : "")
                }
                onClick={() => setPage(LABELS.PROJECTS)}
              >
                <Tooltip title={LABELS.PROJECTS} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.PROJECTS}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.RESPONSIBILITY_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.RESPONSIBILITY_MASTER)}
              >
                <Tooltip
                  title={LABELS.RESPONSIBILITY_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.RESPONSIBILITY_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.WORK_PACKAGES ? "active" : "")
                }
                onClick={() => setPage(LABELS.WORK_PACKAGES)}
              >
                <Tooltip title={LABELS.WORK_PACKAGES} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.WORK_PACKAGES}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.DESIGNATION_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.DESIGNATION_MASTER)}
              >
                <Tooltip
                  title={LABELS.DESIGNATION_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.DESIGNATION_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {masterEntryLevel && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.EMPLOYEE_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.EMPLOYEE_MASTER)}
              >
                <Tooltip
                  title={LABELS.EMPLOYEE_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.EMPLOYEE_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuAdmin && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.USERS ? "active" : "")
                }
                onClick={() => setPage(LABELS.USERS)}
              >
                <Tooltip title={LABELS.USERS} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.USERS}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
          </List>
        </Collapse> */}

        {/* {salesMenu && (
          <ListItemButton
            onClick={() => {
              setShowSalesMenu(!showSalesMenu);
            }}
            className="leftDropDownMenu"
          >
            <ListItemIcon className="leftDropDownMenuIcon">
              <IconSales />
            </ListItemIcon>
            {leftBar && (
              <ListItemText
                className="menuText leftDropDownMenuText"
                primary={LABELS.SALES}
              />
            )}
            {leftBar && (showSalesMenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )}
        <Collapse in={showSalesMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuSalesOrder && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.SALES_ORDER ? "active" : "")
                }
                onClick={() => setPage(TITLES.SALES_ORDER)}
              >
                <Tooltip title={TITLES.SALES_ORDER} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.SALES_ORDER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {menuAddSalesInvoice && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.ADD_SALES_INVOICE ? "active" : "")
                }
                onClick={() => setPage(TITLES.ADD_SALES_INVOICE)}
              >
                <Tooltip
                  title={TITLES.ADD_SALES_INVOICE}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.ADD_SALES_INVOICE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {menuSalesInvoice && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.SALES_INVOICE ? "active" : "")
                }
                onClick={() => setPage(TITLES.SALES_INVOICE)}
              >
                <Tooltip title={TITLES.SALES_INVOICE} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.SALES_INVOICE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {menuSalesReturn && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.SALES_RETURN ? "active" : "")
                }
                onClick={() => setPage(TITLES.SALES_RETURN)}
              >
                <Tooltip title={TITLES.SALES_RETURN} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.SALES_RETURN}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
            {menuEInvoice && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.E_INVOICE ? "active" : "")
                }
                onClick={() => setPage(TITLES.E_INVOICE)}
              >
                <Tooltip title={TITLES.E_INVOICE} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.E_INVOICE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
          </List>
        </Collapse>

        {purchaseMenu && (
          <ListItemButton
            onClick={() => {
              setShowPurchaseMenu(!showPurchaseMenu);
            }}
            className="leftDropDownMenu"
          >
            <ListItemIcon className="leftDropDownMenuIcon">
              <IconPurchase />
            </ListItemIcon>
            {leftBar && (
              <ListItemText
                className="menuText leftDropDownMenuText"
                primary={LABELS.PURCHASE}
              />
            )}
            {leftBar && (showPurchaseMenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )}

        <Collapse in={showPurchaseMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuAddPurchaseBill && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.ADD_PURCHASE_BILL ? "active" : "")
                }
                onClick={() => setPage(TITLES.ADD_PURCHASE_BILL)}
              >
                <Tooltip
                  title={TITLES.ADD_PURCHASE_BILL}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.ADD_PURCHASE_BILL}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuPurchaseBill && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.PURCHASE_BILL ? "active" : "")
                }
                onClick={() => setPage(TITLES.PURCHASE_BILL)}
              >
                <Tooltip title={TITLES.PURCHASE_BILL} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.PURCHASE_BILL}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuPurchaseReturn && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.PURCHASE_RETURN ? "active" : "")
                }
                onClick={() => setPage(TITLES.PURCHASE_RETURN)}
              >
                <Tooltip
                  title={TITLES.PURCHASE_RETURN}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.PURCHASE_RETURN}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
          </List>
        </Collapse> */}

        <MenuItem
          className={
            "leftMenu " + (page === LABELS.PHYSICAL_VOUCHER ? "active" : "")
          }
          onClick={() => setPage(LABELS.PHYSICAL_VOUCHER)}
        >
          <Tooltip title={LABELS.PHYSICAL_VOUCHER} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconPhysicalVoucher fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {LABELS.PHYSICAL_VOUCHER}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        <MenuItem
          className={
            "leftMenu " +
            (page === LABELS.PHYSICAL_VOUCHER_APPROVAL ? "active" : "")
          }
          onClick={() => setPage(LABELS.PHYSICAL_VOUCHER_APPROVAL)}
        >
          <Tooltip
            title={LABELS.PHYSICAL_VOUCHER_APPROVAL}
            placement={"right-start"}
          >
            <div>
              <ListItemIcon>
                <IconPhysicalVoucherApproval fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {LABELS.PHYSICAL_VOUCHER_APPROVAL}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        {/* {crmMenu && (
          <ListItemButton
            onClick={() => {
              setShowCrmMenu(!showCrmMenu);
            }}
            className="leftDropDownMenu"
          >
            <ListItemIcon className="leftDropDownMenuIcon">
              <IconCrm />
            </ListItemIcon>
            {leftBar && (
              <ListItemText
                className="menuText leftDropDownMenuText"
                primary={LABELS.CRM}
              />
            )}
            {leftBar && (showCrmMenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )}
        <Collapse in={showCrmMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuCrmDashboard && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.CRM_DASHBOARD ? "active" : "")
                }
                onClick={() => setPage(TITLES.CRM_DASHBOARD)}
              >
                <Tooltip title={TITLES.CRM_DASHBOARD} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmDashboard />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.CRM_DASHBOARD}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuCrmLeadProfile && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.LEAD_PROFILE ? "active" : "")
                }
                onClick={() => setPage(TITLES.LEAD_PROFILE)}
              >
                <Tooltip title={TITLES.LEAD_PROFILE} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.LEAD_PROFILE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {/* {menuCrmFeedback && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.FEEDBACK ? "active" : "")
                }
                onClick={() => setPage(TITLES.FEEDBACK)}
              >
                <Tooltip title={TITLES.FEEDBACK} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.FEEDBACK}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuCrmSurvey && (
              <MenuItem
                className={
                  "leftMenu subMenu " + (page === TITLES.SURVEY ? "active" : "")
                }
                onClick={() => setPage(TITLES.SURVEY)}
              >
                <Tooltip title={TITLES.SURVEY} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconLeadProfile />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.SURVEY}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuRoi && (
              <MenuItem
                className={
                  "leftMenu subMenu " + (page === TITLES.ROI ? "active" : "")
                }
                onClick={() => setPage(TITLES.ROI)}
              >
                <Tooltip title={TITLES.ROI} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconRoi />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.ROI}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuCrmReports && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.CRM_REPORTS ? "active" : "")
                }
                onClick={() => setPage(TITLES.CRM_REPORTS)}
              >
                <Tooltip title={TITLES.CRM_REPORTS} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconReports fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.CRM_REPORTS}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {/* {menuCrmReports && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.INSTRUCTIONS ? "active" : "")
                }
                onClick={() => setPage(TITLES.INSTRUCTIONS)}
              >
                <Tooltip title={TITLES.INSTRUCTIONS} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconCrmReports fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.INSTRUCTIONS}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {CRMLifecycle && (
              <MenuItem
                className={
                  "leftMenu subMenu " +
                  (page === TITLES.CRM_LIFECYCLE ? "active" : "")
                }
                onClick={() => setPage(TITLES.CRM_LIFECYCLE)}
              >
                <Tooltip title={TITLES.CRM_LIFECYCLE} placement={"right-start"}>
                  <div>
                    <ListItemIcon>
                      <IconReports fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className="menuText">
                      {TITLES.CRM_LIFECYCLE}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
          </List>
        </Collapse>

        {/* {masterEntryLevel && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.TIME_SHEET ? "active" : "")
            }
            onClick={() => setPage(LABELS.TIME_SHEET)}
          >
            <Tooltip title={LABELS.TIME_SHEET} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconTimeSheet fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.TIME_SHEET}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {masterEntryLevel && (
          <MenuItem
            className={
              "leftMenu " +
              (page === LABELS.TIME_SHEET_APPROVAL ? "active" : "")
            }
            onClick={() => setPage(LABELS.TIME_SHEET_APPROVAL)}
          >
            <Tooltip
              title={LABELS.TIME_SHEET_APPROVAL}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconTimeSheet fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.TIME_SHEET_APPROVAL}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {masterEntryLevel && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.RESOURCE_PLANNING ? "active" : "")
            }
            onClick={() => setPage(LABELS.RESOURCE_PLANNING)}
          >
            <Tooltip title={LABELS.RESOURCE_PLANNING} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconResourcePlanning fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.RESOURCE_PLANNING}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {masterEntryLevel && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.LEAVE_PLANNER ? "active" : "")
            }
            onClick={() => setPage(LABELS.LEAVE_PLANNER)}
          >
            <Tooltip title={LABELS.LEAVE_PLANNER} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconUsers fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.LEAVE_PLANNER}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {masterEntryLevel && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.MINUTES_OF_MEETING ? "active" : "")
            }
            onClick={() => setPage(LABELS.MINUTES_OF_MEETING)}
          >
            <Tooltip
              title={LABELS.MINUTES_OF_MEETING}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconMeeting fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.MINUTES_OF_MEETING}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {masterEntryLevel && (
          <MenuItem
            className={
              "leftMenu " +
              (page === LABELS.LEAVE_PLANNER_APPROVE ? "active" : "")
            }
            onClick={() => setPage(LABELS.LEAVE_PLANNER_APPROVE)}
          >
            <Tooltip
              title={LABELS.LEAVE_PLANNER_APPROVE}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconUsers fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.LEAVE_PLANNER_APPROVE}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {masterEntryLevel && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.PAY_SLIP ? "active" : "")}
            onClick={() => setPage(LABELS.PAY_SLIP)}
          >
            <Tooltip title={LABELS.PAY_SLIP} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconReports fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.PAY_SLIP}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )} */}

        {/* {masterEntryLevel && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.INVOICE ? "active" : "")}
            onClick={() => setPage(LABELS.INVOICE)}
          >
            <Tooltip title={LABELS.INVOICE} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconReports fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.INVOICE}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {masterEntryLevel && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.INVOICE_PRINT ? "active" : "")
            }
            onClick={() => setPage(LABELS.INVOICE_PRINT)}
          >
            <Tooltip title={LABELS.INVOICE_PRINT} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconReports fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.INVOICE_PRINT}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )} */}

        <MenuItem
          className={"leftMenu " + (page === LABELS.SETTINGS ? "active" : "")}
          onClick={() => setPage(LABELS.SETTINGS)}
        >
          <Tooltip title={LABELS.SETTINGS} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconSettings fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {LABELS.SETTINGS}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        <MenuItem
          className={"leftMenu " + (page === LABELS.LOGOUT ? "active" : "")}
          onClick={() => setLogoutPopup(!logoutPopup)}
        >
          <Tooltip title={LABELS.LOGOUT} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconLogout fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.LOGOUT}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>
      </MenuList>

      <ConfirmDialog
        open={logoutPopup}
        title={LABELS.CONFIRM}
        msg={CONFIRMS.SURE_TO_LOGOUT}
        size={DEFAULTS.DIALOG_SIZE}
        onClose={() => setLogoutPopup(false)}
        onOk={onUserLogout}
      />
    </div>
  );
};

export default withSnackbar(LeftBar);
