/**
 * Add New Lead Profile
 */

import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import TxtRow from "components/Custom/TxtRow";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";

import { LABELS, SUCCESS, WARNINGS } from "common/lables";
import { COLORS } from "common/colors";
import { CRM_APIs } from "apihandlers/crm";
import { COMMON_JS } from "common/scripts";
import {
  CRM_LEAD_STATUS,
  FORMATS,
  NOTIFY,
  INVESTMENT_AMOUNTS,
} from "common/config";
import { DateFunctions } from "common/datefunctions";
import RadioBtn from "components/Custom/RadioBtn";
import { GEO_APIs } from "apihandlers/geography";
import SkeletonBox from "components/Custom/SkeletonBox";

interface defaultProps {}

const NewLeadProfile = (props: any) => {
  const { setManageNewDialog, selectedRow, areas, onClose, isButtonDisabled } =
    props;

  const companyDetails = selectedRow.companyDetails
    ? JSON.parse(selectedRow.companyDetails)
    : {};
  const educationDetails = selectedRow.educationDetails
    ? JSON.parse(selectedRow.educationDetails)
    : {};
  const experienceDetails = selectedRow.experienceDetails
    ? JSON.parse(selectedRow.experienceDetails)
    : {};
  const partnerDetails = selectedRow.partnerDetails
    ? JSON.parse(selectedRow.partnerDetails)
    : {};
  const personalDetails = selectedRow.personalDetails
    ? JSON.parse(selectedRow.personalDetails)
    : {};
  const storageDetails = selectedRow.storageDetails
    ? JSON.parse(selectedRow.storageDetails)
    : {};
  const logisticsDetails = selectedRow.logisticsDetails
    ? JSON.parse(selectedRow.logisticsDetails)
    : {};
  const capitalDetails = selectedRow.capitalDetails
    ? JSON.parse(selectedRow.capitalDetails)
    : {};
  const selectedRemarks = selectedRow.remarks
    ? JSON.parse(selectedRow.remarks)
    : {};
  const newleadRemarks = selectedRemarks.newLead || {};

  const [loading, setLoading] = useState<boolean>(true);
  const [states, setStates] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [comDistricts, setComDistricts] = useState<any>([]);
  const [partnerDistricts, setPartnerDistricts] = useState<any>([]);

  const [firmType, setFirmType] = useState(companyDetails.firmType || " ");

  const areasRef: any = useRef(null);
  const companyDistrictRef: any = useRef(null);
  const companyStatesRef: any = useRef(null);
  const personalStatesRef: any = useRef(null);
  const personalDistrictRef: any = useRef(null);
  const partnerStatesRef: any = useRef(null);
  const partnerDistrictRef: any = useRef(null);
  const investAmountRef: any = useRef(null);

  useEffect(() => {
    if (loading) {
      getStates();
    }
  }, [loading]);

  const getStates = async () => {
    try {
      const res: any = await GEO_APIs.getStates({ activeStatus: "true" });
      const stateList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.stateName,
        };
      });
      setStates(stateList);
      if (selectedRow.id) {
        // load districts
        if (personalDetails.state) {
          const list = await getDistricts(personalDetails.state);
          setDistricts(list);
        }
        if (companyDetails.compState) {
          const list = await getDistricts(companyDetails.compState);
          setComDistricts(list);
        }
        if (partnerDetails.partnerState) {
          const list = await getDistricts(partnerDetails.partnerState);
          setPartnerDistricts(list);
        }
      }
      setLoading(false);
    } catch (error) {}
  };

  const getDistricts = async (stateId) => {
    let districtList = [];
    try {
      const args = {
        stateId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getDistricts(args);
      districtList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.districtName,
        };
      });
    } catch (error) {}
    return districtList;
  };

  const saveRecord = async () => {
    try {
      // Current Details
      const fName = (
        document.getElementById("fName") as HTMLInputElement
      ).value.trim();
      const midName = (
        document.getElementById("midName") as HTMLInputElement
      ).value.trim();
      const lName = (
        document.getElementById("lName") as HTMLInputElement
      ).value.trim();
      const state = personalStatesRef.current.selectedValue
        ? personalStatesRef.current.selectedValue.id
        : "";
      const district = personalDistrictRef.current.selectedValue
        ? personalDistrictRef.current.selectedValue.id
        : "";
      const taluk = (
        document.getElementById("taluk") as HTMLInputElement
      ).value.trim();
      const appDate = (
        document.getElementById("appDate") as HTMLInputElement
      ).value.trim();
      const dob = (
        document.getElementById("dob") as HTMLInputElement
      ).value.trim();
      const currentAdd = (
        document.getElementById("currentAdd") as HTMLInputElement
      ).value.trim();

      // Personal Details
      const phone = (
        document.getElementById("phone") as HTMLInputElement
      ).value.trim();
      const mob1 = (
        document.getElementById("mob1") as HTMLInputElement
      ).value.trim();
      const mob2 = (
        document.getElementById("mob2") as HTMLInputElement
      ).value.trim();
      const pin = (
        document.getElementById("pin") as HTMLInputElement
      ).value.trim();
      const age = (
        document.getElementById("age") as HTMLInputElement
      ).value.trim();

      // Company Details:
      const compName = (
        document.getElementById("compName") as HTMLInputElement
      ).value.trim();
      const compEmail = (
        document.getElementById("compEmail") as HTMLInputElement
      ).value.trim();
      const compAddress = (
        document.getElementById("compAddress") as HTMLInputElement
      ).value.trim();
      const compState = companyStatesRef.current.selectedValue
        ? companyStatesRef.current.selectedValue.id
        : "";
      const compDist = companyDistrictRef.current.selectedValue
        ? companyDistrictRef.current.selectedValue.id
        : "";
      const compTaluka = (
        document.getElementById("compTaluka") as HTMLInputElement
      ).value.trim();
      const compPin = (
        document.getElementById("compPin") as HTMLInputElement
      ).value.trim();
      const email = (
        document.getElementById("email") as HTMLInputElement
      ).value.trim();
      let type = document.querySelector(
        'input[name="firmType"]:checked'
      ) as HTMLInputElement;
      const firmType = type ? type.value : "";

      // Education Details
      const highestQualification = (
        document.getElementById("highestQualification") as HTMLInputElement
      ).value.trim();

      //Partner Details
      let partnerFName: any = document.getElementById(
        "partnerFName"
      ) as HTMLInputElement;
      if (partnerFName) {
        partnerFName = partnerFName.value.trim();
      } else {
        partnerFName = "";
      }
      let partnerMidName: any = document.getElementById(
        "partnerMidName"
      ) as HTMLInputElement;
      if (partnerMidName) {
        partnerMidName = partnerMidName.value.trim();
      } else {
        partnerMidName = "";
      }
      let partnerLName: any = document.getElementById(
        "partnerLName"
      ) as HTMLInputElement;
      if (partnerLName) {
        partnerLName = partnerLName.value.trim();
      } else {
        partnerLName = "";
      }
      let partnerState = partnerStatesRef.current.selectedValue;
      if (partnerState) {
        partnerState = partnerStatesRef.current.selectedValue.id;
      } else if (partnerState == null) {
        partnerState = "";
      }

      const partnerDist = partnerDistrictRef.current.selectedValue.id
        ? partnerDistrictRef.current.selectedValue.id
        : "";
      let partnerTaluk: any = document.getElementById(
        "partnerTaluk"
      ) as HTMLInputElement;
      if (partnerTaluk) {
        partnerTaluk = partnerTaluk.value.trim();
      } else {
        partnerTaluk = "";
      }
      let partnerDOB: any = document.getElementById(
        "partnerDOB"
      ) as HTMLInputElement;
      if (partnerDOB) {
        partnerDOB = partnerDOB.value.trim();
      } else {
        partnerDOB = "";
      }
      let partnerCurrentAddress: any = document.getElementById(
        "partnerCurrentAddress"
      ) as HTMLInputElement;
      if (partnerCurrentAddress) {
        partnerCurrentAddress = partnerCurrentAddress.value.trim();
      } else {
        partnerCurrentAddress = "";
      }
      let partnerAge: any = document.getElementById(
        "partnerAge"
      ) as HTMLInputElement;
      if (partnerAge) {
        partnerAge = partnerAge.value.trim();
      } else {
        partnerAge = "";
      }
      let partnerPhone: any = document.getElementById(
        "partnerPhone"
      ) as HTMLInputElement;
      if (partnerPhone) {
        partnerPhone = partnerPhone.value.trim();
      } else {
        partnerPhone = "";
      }
      let partnerMob1: any = document.getElementById(
        "partnerMob1"
      ) as HTMLInputElement;
      if (partnerMob1) {
        partnerMob1 = partnerMob1.value.trim();
      } else {
        partnerMob1 = "";
      }
      let partnerMob2: any = document.getElementById(
        "partnerMob2"
      ) as HTMLInputElement;
      if (partnerMob2) {
        partnerMob2 = partnerMob2.value.trim();
      } else {
        partnerMob2 = "";
      }
      let partnerPin: any = document.getElementById(
        "partnerPin"
      ) as HTMLInputElement;
      if (partnerPin) {
        partnerPin = partnerPin.value.trim();
      } else {
        partnerPin = "";
      }

      //Experience Details
      const otherExp = (
        document.getElementById("otherExp") as HTMLInputElement
      ).value.trim();

      //Storage Details:
      const strgFacility = (
        document.getElementById("strgFacility") as HTMLInputElement
      ).value.trim();
      const transportFacility = (
        document.getElementById("transportFacility") as HTMLInputElement
      ).value.trim();
      const godownSize = (
        document.getElementById("godownSize") as HTMLInputElement
      ).value.trim();

      //Logistics Details:
      const vehicalDetails = (
        document.getElementById("vehicalDetails") as HTMLInputElement
      ).value.trim();

      //Capital Details
      const investmentCapacity = investAmountRef.current.selectedValue.id;

      const remarks = (
        document.getElementById("remarks") as HTMLInputElement
      ).value.trim();
      const argsPersonal = {
        fName,
        midName,
        lName,
        state,
        district,
        taluk,
        appDate,
        dob,
        currentAdd,
        phone,
        mob1,
        mob2,
        pin,
        age,
      };
      const argsCompany = {
        compName,
        compEmail,
        compAddress,
        compState,
        compDist,
        compTaluka,
        compPin,
        firmType,
        email,
      };
      const argsEducational = {
        highestQualification,
      };
      const argsPartner = {
        partnerFName,
        partnerMidName,
        partnerLName,
        partnerState,
        partnerDist,
        partnerTaluk,
        partnerDOB,
        partnerCurrentAddress,
        partnerAge,
        partnerPhone,
        partnerMob1,
        partnerMob2,
        partnerPin,
      };

      const argsExperience = {
        otherExp,
      };
      const argsStorage = {
        strgFacility,
        transportFacility,
        godownSize,
      };
      const argsLogistics = {
        vehicleDetails: vehicalDetails,
      };
      const argsCapital = {
        investmentCapacity,
      };

      const selectedRemarks = selectedRow.remarks
        ? JSON.parse(selectedRow.remarks)
        : {};
      const argsRemarks = {
        ...selectedRemarks,
        newLead: remarks,
      };

      let crmLeadName = "";
      if (argsPersonal.fName) {
        crmLeadName += argsPersonal.fName;
      }
      if (argsPersonal.midName) {
        crmLeadName += " " + argsPersonal.midName;
      }
      if (argsPersonal.lName) {
        crmLeadName += " " + argsPersonal.lName;
      }

      const args = {
        crmLeadStatus: CRM_LEAD_STATUS.NEW_LEAD,
        activeStatus: "true",
        crmLeadId: selectedRow.id || -1,
        crmLeadName,
        customerTypeId: 1, // todo: hardcoded here
        capitalDetails: argsCapital,
        logisticsDetails: argsLogistics,
        storageDetails: argsStorage,
        experienceDetails: argsExperience,
        partnerDetails: argsPartner,
        companyDetails: argsCompany,
        educationDetails: argsEducational,
        personalDetails: argsPersonal,
        areaId: areasRef.current.selectedValue.id,
        remarks: argsRemarks,
      };
      // Validations
      if (!argsPersonal.fName || !argsPersonal.lName || !args.areaId) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
        );
        return;
      }

      const res = await CRM_APIs.manageCrmLeadProfile(args);
      console.log("manageCrmLeadProfile:", res);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        onClose();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    } catch (error) {
      console.error("manageCrmLeadProfile: error:", error);
    }
  };

  const onStateChange = async (e, f) => {
    if (f.id) {
      const res = await getDistricts(f.id);
      setDistricts(res);
    }
  };

  const onComStateChange = async (e, f) => {
    if (f.id) {
      const res = await getDistricts(f.id);
      setComDistricts(res);
    }
  };

  const onPartnerStateChange = async (e, f) => {
    if (f.id) {
      const res = await getDistricts(f.id);
      setPartnerDistricts(res);
    }
  };

  let selectedState = null;
  if (personalDetails.state) {
    selectedState = states.find((s) => s.id === personalDetails.state);
    if (!selectedState) {
      selectedState = null;
    }
  }
  let selectedDist = null;
  if (personalDetails.district) {
    selectedDist = districts.find((s) => s.id === personalDetails.district);
    if (!selectedDist) {
      selectedDist = null;
    }
  }

  let selectedComState = null;
  if (companyDetails.compState) {
    selectedComState = states.find((s) => s.id === companyDetails.compState);
    if (!selectedComState) {
      selectedComState = null;
    }
  }
  let selectedComDist = null;
  if (companyDetails.compDist) {
    selectedComDist = comDistricts.find(
      (s) => s.id === companyDetails.compDist
    );
    if (!selectedComDist) {
      selectedComDist = null;
    }
  }

  let selectedPartnerState = null;
  if (partnerDetails.partnerState) {
    selectedPartnerState = states.find(
      (s) => s.id === partnerDetails.partnerState
    );
    if (!selectedPartnerState) {
      selectedPartnerState = null;
    }
  }
  let selectedPartnerDist = null;
  if (partnerDetails.partnerDist) {
    selectedPartnerDist = partnerDistricts.find(
      (s) => s.id === partnerDetails.partnerDist
    );
    if (!selectedPartnerDist) {
      selectedPartnerDist = null;
    }
  }

  console.log(
    "selected row:",
    // personalDetails,
    // companyDetails,
    // partnerDetails,
    selectedState,
    selectedDist,
    selectedComState,
    selectedComDist,
    selectedPartnerState,
    selectedPartnerState,
    selectedPartnerDist
  );
  return loading ? (
    <div>
      <SkeletonBox width={"100%"} height={150} />
    </div>
  ) : (
    <>
      <div
        className="formWrapper"
        style={{
          height: window.innerHeight * 0.7,
          overflow: "auto",
          paddingRight: "1rem",
        }}
      >
        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.PERSONAL_DETAILS}
          </legend>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"fName"}
                type={"text"}
                label={LABELS.FIRST_NAME}
                required={true}
                defaultValue={personalDetails.fName || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"midName"}
                type={"text"}
                label={LABELS.MIDDLE_NAME}
                // required={true}
                defaultValue={personalDetails.midName || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"lName"}
                type={"text"}
                label={LABELS.LAST_NAME}
                required={true}
                defaultValue={personalDetails.lName || ""}
                //disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.STATE}</label>
              <AutoComplete
                list={states}
                childRef={personalStatesRef}
                required={true}
                defaultValue={selectedState}
                onChange={onStateChange}
                //disabled={true}
              />
            </div>

            <div className="formGroup width30 ">
              <label>{LABELS.DISTRICT}</label>
              <AutoComplete
                list={districts}
                childRef={personalDistrictRef}
                required={true}
                defaultValue={selectedDist}
                //disabled={true}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"taluk"}
                type={"text"}
                label={LABELS.TALUKA}
                //required={true}
                defaultValue={personalDetails.taluk || ""}
                //disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"appDate"}
                type={"date"}
                label={LABELS.APPLICATION_DT}
                //required={true}
                defaultValue={
                  selectedRow.id
                    ? DateFunctions.getFormatedDate(
                        personalDetails.appDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : null
                }
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"dob"}
                type={"date"}
                label={LABELS.DOB}
                //required={true}
                defaultValue={personalDetails.dob || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"currentAdd"}
                type={"text"}
                label={LABELS.CURRENT_ADDRESS}
                //required={true}
                defaultValue={personalDetails.currentAdd || ""}
                //disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"phone"}
                type={"text"}
                label={LABELS.PHONE}
                //required={true}
                defaultValue={personalDetails.phone || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"mob1"}
                type={"text"}
                label={LABELS.MOB_1}
                //required={true}
                defaultValue={personalDetails.mob1 || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"mob2"}
                type={"text"}
                label={LABELS.MOB_2}
                //required={true}
                defaultValue={personalDetails.mob2 || ""}
                //disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"pin"}
                type={"number"}
                label={LABELS.PIN}
                //required={true}
                defaultValue={personalDetails.pin || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"age"}
                type={"number"}
                label={LABELS.AGE}
                //required={true}
                defaultValue={personalDetails.age || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 required">
              <label>
                {LABELS.AREA}
                <span>{" *"}</span>
              </label>
              <AutoComplete
                list={areas}
                childRef={areasRef}
                required={true}
                defaultValue={
                  selectedRow.areaId
                    ? {
                        id: selectedRow.areaId,
                        label: selectedRow.areaName,
                      }
                    : null
                }
                //disabled={true}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">{LABELS.COMPANY_DETAIL}</legend>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"compName"}
                type={"text"}
                label={LABELS.COMPANY_NAME}
                // required={true}
                defaultValue={companyDetails.compName || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"compEmail"}
                type={"text"}
                label={LABELS.COMPANY_EMAIL}
                //required={true}
                defaultValue={companyDetails.compEmail || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"compAddress"}
                type={"text"}
                label={LABELS.COMPANY_ADDRESS}
                // required={true}
                defaultValue={companyDetails.compAddress || ""}
                //disabled={isSubmit}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.COMPANY_STATE}</label>
              <AutoComplete
                list={states}
                childRef={companyStatesRef}
                required={true}
                defaultValue={selectedComState}
                onChange={onComStateChange}
                //disabled={true}
              />
            </div>
            <div className="formGroup width30 ">
              <label>{LABELS.COMPANY_DIST}</label>
              <AutoComplete
                list={comDistricts}
                childRef={companyDistrictRef}
                required={true}
                defaultValue={selectedComDist}
                //disabled={true}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"compTaluka"}
                type={"text"}
                label={LABELS.COMPANY_TALUKA}
                //required={true}
                defaultValue={companyDetails.compTaluka || ""}
                //disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"compPin"}
                type={"number"}
                label={LABELS.COMPANY_PIN}
                //required={true}
                defaultValue={companyDetails.compPin || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"email"}
                type={"text"}
                label={LABELS.EMAIL_ID}
                //required={true}
                defaultValue={companyDetails.email || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
          </div>
          <div className="flexRow" style={{ marginLeft: "1.5%" }}>
            <div className="formGroup width70">
              <label>{LABELS.FIRM_TYPE}</label>
              <RadioBtn
                list={[
                  {
                    id: "Proprietor",
                    value: "Proprietor",
                    label: LABELS.PROPRIETOR,
                    checked: selectedRow.companyDetails
                      ? companyDetails.firmType === "Proprietor"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "Partnership",
                    value: "Partnership",
                    label: LABELS.PARTNERSHIP,
                    checked: selectedRow.companyDetails
                      ? companyDetails.firmType === "Partnership"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "Pvt. Ltd",
                    value: "Pvt. Ltd",
                    label: LABELS.PVT_LTD,
                    checked: selectedRow.companyDetails
                      ? companyDetails.firmType === "Pvt. Ltd"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "Ltd",
                    value: "Ltd.",
                    label: LABELS.LTD,
                    checked: selectedRow.companyDetails
                      ? companyDetails.firmType === "Ltd"
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"firmType"}
                onChange={(e) => {
                  setFirmType(e);
                }}
              />
            </div>
            <div className="formGroup width30"></div>
          </div>

          {firmType === "Partnership" && (
            <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              <fieldset className="formSection">
                <legend className="formSectionLegend">
                  {LABELS.PARTNER_DETAIL}
                </legend>
                <div className="flexRowAround">
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerFName"}
                      type={"text"}
                      label={LABELS.PARTNER_FIRST_NAME}
                      // required={true}
                      defaultValue={partnerDetails.partnerFName || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerMidName"}
                      type={"text"}
                      label={LABELS.PARTNER_MIDDLE_NAME}
                      //required={true}
                      defaultValue={partnerDetails.partnerMidName || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerLName"}
                      type={"text"}
                      label={LABELS.PARTNER_LAST_NAME}
                      // required={true}
                      defaultValue={partnerDetails.partnerLName || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                </div>
                <div className="flexRowAround">
                  <div className="formGroup width30">
                    <label>{LABELS.PARTNER_STATE}</label>
                    <AutoComplete
                      list={states}
                      childRef={partnerStatesRef}
                      required={true}
                      defaultValue={selectedPartnerState}
                      onChange={onPartnerStateChange}
                      //disabled={true}
                    />
                  </div>
                  <div className="formGroup width30">
                    <label>{LABELS.PARTNER_DIST}</label>
                    <AutoComplete
                      list={partnerDistricts}
                      childRef={partnerDistrictRef}
                      required={true}
                      defaultValue={selectedPartnerDist}
                      //disabled={true}
                    />
                  </div>
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerTaluk"}
                      type={"text"}
                      label={LABELS.PARTNER_TALUKA}
                      //required={true}
                      defaultValue={partnerDetails.partnerTaluk || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                </div>
                <div className="flexRowAround">
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerDOB"}
                      type={"date"}
                      label={LABELS.PARTNER_DOB}
                      //required={true}
                      defaultValue={partnerDetails.partnerDOB || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerCurrentAddress"}
                      type={"text"}
                      label={LABELS.PARTNER_CURRENT_ADDRESS}
                      //required={true}
                      defaultValue={partnerDetails.partnerCurrentAddress || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerAge"}
                      type={"number"}
                      label={LABELS.PARTNER_AGE}
                      //required={true}
                      defaultValue={partnerDetails.partnerAge || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                </div>
                <div className="flexRowAround">
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerPhone"}
                      type={"text"}
                      label={LABELS.PARTNER_PHONE}
                      //required={true}
                      defaultValue={partnerDetails.partnerPhone || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerMob1"}
                      type={"text"}
                      label={LABELS.PARTNER_MOB_1}
                      //required={true}
                      defaultValue={partnerDetails.partnerMob1 || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerMob2"}
                      type={"text"}
                      label={LABELS.PARTNER_MOB_2}
                      //required={true}
                      defaultValue={partnerDetails.partnerMob2 || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                </div>
                <div className="flexRowAround">
                  <div className="formGroup width30">
                    <TxtRow
                      id={"partnerPin"}
                      type={"number"}
                      label={LABELS.PARTNER_PIN}
                      //required={true}
                      defaultValue={partnerDetails.partnerPin || ""}
                      //disabled={isSubmit}
                    />
                  </div>
                  <div className="formGroup width30"></div>
                  <div className="formGroup width30"></div>
                </div>
              </fieldset>
            </div>
          )}
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.EDUCATIONAL_DETAILS}
          </legend>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"highestQualification"}
                type={"text"}
                label={LABELS.HIGHEST_QUALIFICATION}
                // required={true}
                defaultValue={educationDetails.highestQualification || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">{LABELS.EXP_DETAILS}</legend>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"otherExp"}
                type={"text"}
                label={LABELS.OTHER_EXP}
                // required={true}
                defaultValue={experienceDetails.otherExp || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.STORAGE_DETAILS}
          </legend>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"strgFacility"}
                type={"text"}
                label={LABELS.STORAGE_FACILITY}
                // required={true}
                defaultValue={storageDetails.strgFacility || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"transportFacility"}
                type={"text"}
                label={LABELS.TRANSPORT_FACILITY}
                // required={true}
                defaultValue={storageDetails.transportFacility || ""}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"godownSize"}
                type={"text"}
                label={LABELS.SIZE_OF_GODOWN}
                // required={true}
                defaultValue={storageDetails.godownSize || ""}
                placeholder={"Length ,Width ,Height"}

                //disabled={isSubmit}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.LOGISTIC_DETAILS}
          </legend>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"vehicalDetails"}
                type={"text"}
                label={LABELS.VEHICAL_DETAILS}
                // required={true}
                defaultValue={logisticsDetails.vehicleDetails}
                //disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <fieldset className="formSection">
          <legend className="formSectionLegend">
            {LABELS.CAPITAL_DETAILS}
          </legend>
          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.INVESTMENT_AMOUNT}</label>
              <AutoComplete
                list={INVESTMENT_AMOUNTS}
                childRef={investAmountRef}
                defaultValue={
                  capitalDetails.investmentCapacity
                    ? {
                        id: capitalDetails.investmentCapacity,
                        label: capitalDetails.investmentCapacity,
                      }
                    : null
                }
                //disabled={true}
              />
            </div>

            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </fieldset>

        <div className="flexRow">
          <div className="formGroup width60">
            <TxtRow
              id={"remarks"}
              type={"text"}
              label={LABELS.REMARKS}
              // required={true}
              defaultValue={selectedRemarks.newLead || " "}
              //disabled={isSubmit}
              rows={4}
            />
          </div>
        </div>
      </div>

      {/* BtmMenu */}
      <div className="formGroup submitBtnWrapper">
        <div className=" flexRowAround">
          <Btn
            text={LABELS.SAVE}
            //loading={isSubmit}
            onClick={saveRecord}
            bgColor={COLORS.SAVE_GREEN}
            // disabled={isButtonDisabled}
          />
          {/* <Btn
            text={LABELS.CANCEL}
            bgColor={COLORS.DIM_DARK_GRAY}
            onClick={() => setManageNewDialog(false)}
            //loading={isSubmit}
          /> */}
        </div>
      </div>
    </>
  );
};

export default withSnackbar(NewLeadProfile);
