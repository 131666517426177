import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
// Data Tables
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";

import { NOTIFY } from "common/config";
import { CONFIRMS, LABELS, SUCCESS, ERRORS, WARNINGS } from "common/lables";
import { DateFunctions } from "common/datefunctions";
import { COMMON_JS } from "common/scripts";
import { REPORTS_APIs } from "apihandlers/reports";

const $ = jQuery;

const CategorySaleReport = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [masters, setMasters] = useState<any>([]);

  useEffect(() => {
    if (loading) {
      getMasterData();
    }
  }, [loading]);

  const getMasterData = () => {
    const args = {};
    // ApiMastersHandler.getVehicles(args).then((res: any) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list: any = [{ id: -1, label: "All" }];
    //     let recs: any = response.records;
    //     for (let i = 0; i < recs.length; i++) {
    //       list.push({
    //         id: recs[i].id,
    //         label: recs[i].vehicleNumber,
    //       });
    //     }
    //     setMasters(list);
    //     setLoading(false);
    //   }
    // });
    setLoading(false);
  };

  const generateReportGrid = (res: any) => {
    let title = LABELS.CATEGORY_WISE_SALES;
    if (masterRef.current) {
      title += " - " + masterRef.current.selectedValue.label;
    }
    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          text: "<i class='fa fa-download'></i>",
        },
      ],
      paging: true,
      pageLength: 15,
      columns: res.cols,
      data: res.rows,
      order: [],
    });
  };

  const loadChart = () => {
    // const id = masterRef.current.selectedValue.id;
    // if (!id) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.DATA_IS_EMPTY);
    //   return;
    // }
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const toDt = (document.getElementById("toDt") as HTMLInputElement).value;
    const args = {
      fromDt,
      toDt,
      // id:-1,
      // vehicleId: id,
      // driverId: -1,
    };
    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    if ($("#reportGrid")) {
      $("#reportGrid").empty();
    }
    setChartData(null);
    REPORTS_APIs.getCategorySalesReport(args).then((res) => {
      // console.log("getUserLogReport:", args);
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setChartData(response);
        setTimeout(() => {
          generateReportGrid(response);
        }, 500);
      }
    });
  };

  const masterRef: any = useRef();

  // console.log("elist:", masters);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.CATEGORY_WISE_SALES}</li>
        </ul>
        <h4>{LABELS.CATEGORY_WISE_SALES}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 5,
                marginBottom: 10,
                width: "40%",
              }}
            >
              {/* <label>{"Select Vehicle :"}</label>
              <AutoComplete
                id={"masters"}
                list={masters}
                childRef={masterRef}
                defaultValue={{ id: -1, label: "All" }}
              /> */}
            </div>
            <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 0,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.FROM_DATE}
                </label>
                <TxtBox
                  type={"date"}
                  id="fromDt"
                  defaultValue={DateFunctions.getDate(
                    "today",
                    "from",
                    "YYYY-MM-DD"
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.TO_DATE}
                </label>
                <TxtBox
                  type={"date"}
                  id="toDt"
                  defaultValue={DateFunctions.getDate(
                    "thismonth",
                    "to",
                    "YYYY-MM-DD"
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                />
              </div>
            </div>
          </div>

          {chartData ? (
            <div className="reportGridWrapper">
              <table
                id="reportGrid"
                className="mdl-data-table width100"
                cellSpacing="0"
                cellPadding="3"
              />
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(CategorySaleReport);
