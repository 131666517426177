/*
 * Textbox with label
 */
import React from "react";

import TxtBox from "components/Custom/TxtBox";

const TxtRow = ({
  id,
  label,
  type = "text",
  required = false,
  defaultValue = "",
  disabled = false,
  rows,
  placeholder,
}: any) => {
  return (
    <>
      <label className={required ? "red" : ""}>
        {label}
        {required && <span className="red">{" *"}</span>}
      </label>
      <TxtBox
        id={id}
        type={type}
        placeholder={placeholder ? placeholder : label}
        defaultValue={defaultValue}
        disabled={disabled}
        rows={rows}
        className={rows ? "multiLine" : ""}
      />
    </>
  );
};

export default TxtRow;
