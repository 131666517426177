import React, { useEffect, useRef, useState } from "react";

import { withSnackbar } from "notistack";
import RadioBtn from "../../Custom/RadioBtn";

import TxtRow from "../../Custom/TxtRow";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "../../../common/lables";
import ExcelGrid from "../../Custom/ExcelGrid";
import Loader from "../../Custom/Loader";
import UiDialog from "../../Custom/UiDialog";
import Btn from "../../Custom/Btn";

import { DEFAULTS, FORMATS, NOTIFY } from "../../../common/config";
import { COMMON_JS } from "../../../common/scripts";
import { DateFunctions } from "common/datefunctions";

import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { COLORS } from "../../../common/colors";
import AutoComplete from "../../Custom/AutoComplete";

import { GEO_APIs } from "../../../apihandlers/geography";
import { MASTER_APIs } from "../../../apihandlers/master";
import { USER_APIs } from "../../../apihandlers/user";

const Beat = (props: any) => {
  const { setDashMenu } = props;

  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [cities, setCities] = useState<any>([]);
  const [vehicleNumber, setVehicleNumber] = useState<any>([]);
  const [driverNames, setDriverNames] = useState<any>([]);
  const [executive, setExecutive] = useState<any>([]);

  const daysRef: any = useRef(null);
  const citiesRef: any = useRef(null);
  const vehicleRef: any = useRef(null);
  const driverRef: any = useRef(null);
  const executiveRef: any = useRef(null);

  const [columns, setColumns] = useState<any>([
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      headerAlign: "center",
      searchable: "true",
    },
    {
      key: "stateName",
      name: LABELS.STATE_NAME,
      type: "text",
      width: "35%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: "true",
    },
    {
      key: "countryName",
      name: LABELS.COUNTRY_NAME,
      type: "text",
      width: "35%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: "true",
    },

    {
      key: "activeStatus",
      name: LABELS.ACTIVE_STATUS,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: "true",
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    getAreas();
  }, []);

  const getAreas = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };

    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setCities(list);
        getVehicles();
      }
    });
  };

  const getVehicles = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };

    MASTER_APIs.getVehicles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.vehicleNumber,
          };
        });
        setVehicleNumber(list);

        getDrivers();
      }
    });
  };
  const getDrivers = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };

    MASTER_APIs.getDrivers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.userId,
            label: r.driverName,
          };
        });
        setDriverNames(list);

        getUsers();
      }
    });
  };
  const getUsers = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
      marketingexec: "Executive",
    };

    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setExecutive(list);

        getRows();
      }
    });
  };

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };

    //getAreaBeat

    GEO_APIs.getAreaBeat(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
          //   if (list[i]["loadingOnlyStatus"]) {
          //     list[i]["loadingOnlyStatus"] = "Yes";
          //   } else {
          //     list[i]["loadingOnlyStatus"] = "No";
          //   }
        }
        setRows(list);
        setLoading(false);
        console.log("list rows ", list);
      }
    });
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };
  const reloadGrid = () => {
    setRows(null);
    getRows();
    console.log("selected row", selectedRow);
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log("SELECTED ROW", selectedRow);
  };

  const manageState = () => {
    const beatCode = (
      document.getElementById("beatCode") as HTMLInputElement
    ).value.trim();

    const cityId = citiesRef.current.selectedValue.id;

    const beatPJP = (
      document.getElementById("beatPJP") as HTMLInputElement
    ).value.trim();

    const dayId = daysRef.current.selectedValue.id;

    const date = (
      document.getElementById("date") as HTMLInputElement
    ).value.trim();

    const vehicleId = vehicleRef.current.selectedValue.id;

    const driverId = driverRef.current.selectedValue.id;

    const executiveId = executiveRef.current.selectedValue.id;

    const pincode = (
      document.getElementById("pincode") as HTMLInputElement
    ).value.trim();

    const population = (
      document.getElementById("population") as HTMLInputElement
    ).value.trim();

    const totalShops = (
      document.getElementById("totalShops") as HTMLInputElement
    ).value.trim();

    const averageCallTime = (
      document.getElementById("averageCallTime") as HTMLInputElement
    ).value.trim();

    const sortingOrder = (
      document.getElementById("sortingOrder") as HTMLInputElement
    ).value.trim();

    let ele = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = ele ? ele.value : "";

    const args = {
      id: selectedRow.id || -1,
      // beatCode,
      areaId: cityId,
      // beatPJP,
      weekDay: dayId,
      // date,
      vehicleId,
      driverId,
      executiveId,
      // pincode,
      // population,
      // totalShops,
      averageCallTime,
      sortingOrder,
      activeStatus,
    };
    // if (!stateName) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PLEASE_FILL_MANDATORY_FIELDS);
    //   return;
    // }

    // if (!countryId) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PLEASE_FILL_MANDATORY_FIELDS);
    //   return;
    // }
    setIsSubmit(true);
    GEO_APIs.manageAreaBeat(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
    console.log("args", args);
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.BEAT_PJP}</li>
        </ul>
        <h4>{TITLES.BEAT_PJP}</h4>
        <p>{SUB_TITLES.BEAT_PJP}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.STATE}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + TITLES.BEAT_PJP + ": " + selectedRow.id
              : "Add " + TITLES.BEAT_PJP
          }
          open={true}
          size={"md"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="flexColumn">
            <div className="formGroup ">
              <TxtRow
                id={"beatCode"}
                label={LABELS.BEAT_CODE}
                defaultValue={selectedRow.id ? selectedRow.beatCode : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <label>
                {LABELS.CITY}
                <span className="red">{" *"}</span>
              </label>

              <AutoComplete
                list={cities}
                childRef={citiesRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.countryName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup ">
              <TxtRow
                id={"beatPJP"}
                label={LABELS.BEAT_PJP}
                defaultValue={selectedRow.id ? selectedRow.beatPJP : ""}
                type={"text"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <label>
                {LABELS.DAY}
                <span className="red">{" *"}</span>
              </label>

              <AutoComplete
                list={[
                  {
                    id: "sunday",
                    label: "Sunday",
                  },
                  {
                    id: "monday",
                    label: "Monday",
                  },
                  {
                    id: "tuesday",
                    label: "Tuesday",
                  },
                  {
                    id: "wednesday",
                    label: "Wednesday",
                  },
                  {
                    id: "thursday",
                    label: "Thursday",
                  },
                  {
                    id: "friday",
                    label: "Friday",
                  },
                  {
                    id: "saturday",
                    label: "Saturday",
                  },
                ]}
                childRef={daysRef}
                // defaultValue={
                //   selectedRow.id
                //     ? {
                //         id: selectedRow.id,
                //         label: selectedRow.value,
                //       }
                //     : null
                // }
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup ">
              <TxtRow
                id={"date"}
                label={LABELS.DATE}
                defaultValue={
                  selectedRow.ldDate
                    ? DateFunctions.getFormatedDate(
                        selectedRow.ldDate,

                        FORMATS.SQL_DATE,

                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",

                        "from",

                        FORMATS.SQL_DATE_ONLY
                      )
                }
                type={"date"}
                required={true}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup ">
              <label>
                {LABELS.VEHICLE_NUMBER}
                <span className="red">{" *"}</span>
              </label>

              <AutoComplete
                list={vehicleNumber}
                childRef={vehicleRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.countryName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <label>
                {LABELS.DRIVER_NAME}
                <span className="red">{" *"}</span>
              </label>

              <AutoComplete
                list={driverNames}
                childRef={driverRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.countryName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <label>
                {LABELS.EXECUTIVE}
                <span className="red">{" *"}</span>
              </label>

              <AutoComplete
                list={executive}
                childRef={executiveRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.countryName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup ">
              <TxtRow
                id={"pincode"}
                label={LABELS.PIN_CODE}
                defaultValue={selectedRow.id ? selectedRow.pincode : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup ">
              <TxtRow
                id={"population"}
                label={LABELS.POPULATION}
                defaultValue={selectedRow.id ? selectedRow.population : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <TxtRow
                id={"totalShops"}
                label={LABELS.TOTAL_SHOPS}
                defaultValue={selectedRow.id ? selectedRow.totalShops : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <TxtRow
                id={"averageCallTime"}
                label={LABELS.AVERAGE_CALL_TIME}
                defaultValue={selectedRow.id ? selectedRow.averageCallTime : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup ">
              <TxtRow
                id={"sortingOrder"}
                label={LABELS.SORTING_ORDER}
                defaultValue={selectedRow.id ? selectedRow.sortingOrder : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup">
              <label>{LABELS.STATUS}</label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Yes"
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "No"
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup submitBtnWrapper">
              <div className="width45 flexRowJustify">
                <Btn
                  text={LABELS.SAVE}
                  onClick={manageState}
                  loading={isSubmit}
                />
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.DIM_DARK_GRAY}
                  onClick={() => setManageDialog(false)}
                  loading={isSubmit}
                />
              </div>
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};
export default withSnackbar(Beat);
