import React, { useRef } from "react";

import Loader from "components/Custom/Loader";

import { CONFIG } from "common/config";

import "./styles.css";

let _timer = null;

const OrgChart = (props: any) => {
  const { chartSeries, Highcharts, HighchartsReact, title } = props;

  let chartRef: any = useRef();

  let options: any = {
    chart: {
      inverted: true,
    },
    title: {
      text: title,
    },
    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var nodeName = point.toNode.name,
            nodeId = point.toNode.id,
            nodeDesc =
              nodeName === nodeId ? nodeName : nodeName + ", " + nodeId,
            parentDesc = point.fromNode.id;
          return (
            point.index + ". " + nodeDesc + ", reports to " + parentDesc + "."
          );
        },
      },
    },
    series: [
      {
        type: "organization",
        name: CONFIG.SITE_NAME,
        keys: ["from", "to"],
        data: chartSeries.data ? chartSeries.data : [],
        levels: chartSeries.levels ? chartSeries.levels : [],
        nodes: chartSeries.nodes ? chartSeries.nodes : [],
        colorByPoint: false,
        color: "#007ad0",
        dataLabels: {
          color: "#FFF",
        },
        borderColor: "#FFF",
        nodeWidth: 65,
        nodeHeight: 65,
      },
    ],
    tooltip: {
      // outside: true,
    },
    exporting: {
      allowHTML: true,
      sourceWidth: 800,
      sourceHeight: 600,
    },
    credits: {
      enabled: false,
    },
  };

  // console.log("Projects: ");

  return (
    <React.Fragment>
      {chartSeries.data ? (
        <div
          id="chart"
          style={{
            width: "100%",
            height: "auto",
            overflow: "auto",
            position: "relative",
            marginTop: 10,
          }}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        </div>
      ) : (
        <div className="flexCenter" style={{ height: 400 }}>
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
};

export default OrgChart;
