// * Note: Please collect location info & logtime of each and every API Requests

// import axios from 'axios';

import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7007";

export const TRANS_APIs = {
  getOrderInfo: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/830Q8948A82365YD",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  getDeliveryOrders: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/160B6502E62350OV",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  getStockloadInfo: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/840N2665S24137WI",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  getDriverStockloadInfo: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/570Z9115J43890PG",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // Get Vehicle Route details
  getVehicleRoute: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/288S8142U58947PD",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // Get Next customer details on Map
  getNextCustomerMap: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/623D3935K53996DU",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // Add/Edit Stock Load
  manageStockLoad: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/760M5087V45028AX",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get Stock Load Details
  getStockLoad: async (args = {}) => {
    let apiUrl = "/tr/v1/692M4680C61934XI";
    if (local) {
      apiUrl = "http://192.168.0.115:7007/tr/v1/692M4680C61934XI";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Add/Edit Order details
  manageOrder: async (args = {}) => {
    let apiUrl = "/tr/v1/857Q9550C28747QF";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get Order Details
  getOrders: async (args = {}) => {
    let apiUrl = "/tr/v1/604U8628H37993UK";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Add/Edit Stock Load Challan
  manageChallan: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/tr/v1/187H1122J35909WJ",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  getSalesInvoice: async (args = {}) => {
    let resObj: any = {};
    let apiUrl = "/tr/v1/getSalesInvoice";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    resObj = {
      status: 200,
      response: {
        records: [
          {
            id: "1",
            billDate: "2023-09-29",
            billNumber: "200",
            shopName: "Gopal Snacks Limited",
            routeName: "51",
            address: "Benglore",
            items: "Masala Bites 100 grams",
            amount: "200",
            salesMan: "Ramesh ",
            bill: "KAFGD34",
            return: "No",
            sales: [
              {
                id: "3",
                // type: "subgrid",
                productName: "productName 21",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "2",
            billDate: "2023-09-30",
            billNumber: "199",
            shopName: "Gopal Snacks Limited",
            routeName: "dc",
            address: "Mulki",
            items: "Masala Bites 200 grams",
            amount: "400",
            salesMan: "Suresh",
            bill: "KAFGD36",
            return: "No",
            sales: [
              {
                id: "4",
                // type: "subgrid",
                productName: "productName 21",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "5",
                // type: "subgrid",
                productName: "productName 22",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "6",
                // type: "subgrid",
                productName: "productName 23",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "3",
            billDate: "2023-09-30",
            billNumber: "200",
            shopName: "Shop Name 22",
            routeName: "Manglore",
            address: "Surtkal",
            items: "Masala Bites 150 grams",
            amount: "300",
            salesMan: "Raj",
            bill: "KAFGD37",
            return: "No",
            sales: [
              {
                id: "8",
                // type: "subgrid",
                productName: "productName 21",
                price: "10",
                qty: "10",
                amount: "100",
              },
              {
                id: "9",
                // type: "subgrid",
                productName: "productName 22",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "10",
                // type: "subgrid",
                productName: "productName 23",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "4",
            billDate: "2023-10-01",
            billNumber: "201",
            shopName: "Shop Name 12",
            routeName: "Manglore",
            address: "Udupi",
            items: "Masala Bites 100 grams",
            amount: "200",
            salesMan: "Karthik",
            bill: "KAFGD38",
            return: "No",
            sales: [
              {
                id: "11",
                // type: "subgrid",
                productName: "productName 21",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "12",
                // type: "subgrid",
                productName: "productName 22",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "13",
                // type: "subgrid",
                productName: "productName 23",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "5",
            billDate: "2023-10-01",
            billNumber: "202",
            shopName: "Shop Name 34",
            routeName: "Goa",
            address: "Benglore",
            items: "Masala Bites 2000 grams",
            amount: "400",
            salesMan: "Kiran",
            bill: "KAFGD40",
            return: "No",
            sales: [
              {
                id: "11",
                // type: "subgrid",
                productName: "productName 21",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "12",
                // type: "subgrid",
                productName: "productName 22",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "13",
                // type: "subgrid",
                productName: "productName 23",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
        ],
      },
    };
    return resObj;
    resObj = await COMMON_JS.callApi(
      "/re/v1/279W6469O77183US",
      API_CONFIG.METHODS.GET,
      args
    );
    return resObj;
  },

  getSalesReturn: async (args = {}) => {
    let resObj: any = {};
    let apiUrl = "/tr/v1/getSalesInvoice";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    resObj = {
      status: 200,
      response: {
        records: [
          {
            id: "100",
            salesReturnDate: "2023-09-28",
            salesReturnNumber: "22",
            billNumber: "198",
            shopName: "Gopal Snacks Limited",
            routeName: "51",
            address: "Benglore",
            items: "Masala Bites 100 grams",
            amount: 200,
            salesMan: "Ramesh ",
            bill: "KAFGD34",
            return: "No",
          },
          {
            id: "102",
            salesReturnDate: "2023-09-28",
            salesReturnNumber: "23",
            billNumber: "199",
            shopName: "Gopal Snacks Limited",
            routeName: "dc",
            address: "manglore",
            items: "Masala Bites 200 grams",
            amount: 400,
            salesMan: "santhosh",
            bill: "KAFGD36",
            return: "No",
          },
        ],
      },
    };
    return resObj;
    resObj = await COMMON_JS.callApi(
      "/re/v1/279W6469O77183US",
      API_CONFIG.METHODS.GET,
      args
    );
    return resObj;
  },

  getPurchaseReturn: async (args = {}) => {
    let resObj: any = {};
    let apiUrl = "/tr/v1/getPurchaseReturn";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    resObj = {
      status: 200,
      response: {
        records: [
          {
            id: "21",
            origBillNo: "ABC345",
            origDate: "2023-09-28",
            purchaseReturnNo: "23",
            purchaseReturnDate: "2023-09-28",
            noOfItem: "30",
            itemAmt: "100",
            billAmt: "3000",
            purchaseReturn: [
              {
                id: "1",
                productName: "productName 22",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "2",
                productName: "productName 23",
                price: "100",
                qty: "5",
                amount: "500",
              },
            ],
          },
          {
            id: "22",
            origBillNo: "XYZ123",
            origDate: "2023-09-29",
            purchaseReturnNo: "25",
            purchaseReturnDate: "2023-09-29",
            noOfItem: "10",
            itemAmt: "50",
            billAmt: "500",
            purchaseReturn: [
              {
                id: "3",
                productName: "productName 24",
                price: "20",
                qty: "10",
                amount: "200",
              },
              {
                id: "4",
                productName: "productName 25",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "23",
            origBillNo: "AXB234",
            origDate: "2023-09-30",
            purchaseReturnNo: "26",
            purchaseReturnDate: "2023-09-30",
            noOfItem: "50",
            itemAmt: "100",
            billAmt: "5000",
            purchaseReturn: [
              {
                id: "5",
                productName: "productName 25",
                price: "25",
                qty: "5",
                amount: "125",
              },
              {
                id: "6",
                productName: "productName 27",
                price: "50",
                qty: "5",
                amount: "250",
              },
            ],
          },
        ],
      },
    };
    return resObj;
    resObj = await COMMON_JS.callApi(
      "/re/v1/279W6469O77183US",
      API_CONFIG.METHODS.GET,
      args
    );
    return resObj;
  },

  getPurchaseBill: async (args = {}) => {
    let resObj: any = {};
    let apiUrl = "/tr/v1/getPurchaseBill";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    resObj = {
      status: 200,
      response: {
        records: [
          {
            id: "201",
            approveDate: "2023-09-28",
            billDate: "2023-09-28",
            billNO: "78",
            billNumber: "198",
            shopName: "Gopal Snacks Limited",
            routeName: "51",
            address: "Benglore",
            items: "Masala Bites 100 grams",
            itemAmount: "200",
            billAmount: "210",
            amount: "200",
            salesMan: "Ramesh ",
            bill: "KAFGD34",
            billReturn: "Return",
            billStatus: "Yes",
            purchase: [
              {
                id: "3",
                // type: "subgrid",
                productName: "productName 21",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "202",
            approveDate: "2023-09-29",
            billDate: "2023-09-29",
            billNO: "79",
            billNumber: "199",
            shopName: "Gopal Snacks Limited",
            routeName: "dc",
            address: "manglore",
            items: "Masala Bites 200 grams",
            amount: "400",
            itemAmount: "100",
            billAmount: "105",
            salesMan: "santhosh",
            bill: "KAFGD36",
            billReturn: "Return",
            billStatus: "No",
            purchase: [
              {
                id: "4",
                // type: "subgrid",
                productName: "productName 33",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "5",
                // type: "subgrid",
                productName: "productName 34",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "6",
                // type: "subgrid",
                productName: "productName 35",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "203",
            approveDate: "2023-09-30",
            billDate: "2023-09-30",
            billNO: "82",
            billNumber: "200",
            shopName: "Shop Name 22",
            routeName: "Benglore",
            address: "manglore",
            items: "Masala Bites 1500 grams",
            amount: "300",
            itemAmount: "200",
            billAmount: "105",
            salesMan: "Raj",
            bill: "KAFGD37",
            billReturn: "Return",
            billStatus: "No",
            purchase: [
              {
                id: "5",
                // type: "subgrid",
                productName: "productName 33",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "6",
                // type: "subgrid",
                productName: "productName 34",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "7",
                // type: "subgrid",
                productName: "productName 35",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "204",
            approveDate: "2023-09-30",
            billDate: "2023-09-30",
            billNO: "83",
            billNumber: "202",
            shopName: "Shop Name 22",
            routeName: "Suratkal",
            address: "Udupi",
            items: "Masala Bites 100 grams",
            amount: "200",
            itemAmount: "50",
            billAmount: "105",
            salesMan: "Karthik",
            bill: "KAFGD38",
            billReturn: "Return",
            billStatus: "Yes",
            purchase: [
              {
                id: "8",
                // type: "subgrid",
                productName: "productName 33",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "9",
                // type: "subgrid",
                productName: "productName 34",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "10",
                // type: "subgrid",
                productName: "productName 35",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
          {
            id: "205",
            approveDate: "2023-10-01",
            billDate: "2023-10-01",
            billNO: "84",
            billNumber: "200",
            shopName: "Shop Nmae 12",
            routeName: "Goa",
            address: "Benglore",
            items: "Masala Bites 2000 grams",
            amount: "4000",
            itemAmount: "1000",
            billAmount: "1050",
            salesMan: "Kiran",
            bill: "KAFGD40",
            billReturn: "Return",
            billStatus: "Yes",
            purchase: [
              {
                id: "12",
                // type: "subgrid",
                productName: "productName 33",
                price: "10",
                qty: "5",
                amount: "50",
              },
              {
                id: "13",
                // type: "subgrid",
                productName: "productName 34",
                price: "20",
                qty: "5",
                amount: "100",
              },
              {
                id: "14",
                // type: "subgrid",
                productName: "productName 35",
                price: "10",
                qty: "5",
                amount: "50",
              },
            ],
          },
        ],
      },
    };
    return resObj;
    resObj = await COMMON_JS.callApi(
      "/re/v1/279W6469O77183US",
      API_CONFIG.METHODS.GET,
      args
    );
    return resObj;
  },

  // getVehicleDriver: async (args = {}) => {
  //   const response = await COMMON_JS.callApi(
  //     '/v1/vehicle/driver',
  //     API_CONFIG.METHODS.GET,
  //     args,
  //     accessToken,
  //   );
  //   return response;
  // },

  managePhysicalVoucher: async (args = {}) => {
    let apiUrl = "/tr/v1/O6MNOGU3NRVS9ZM5";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getPhysicalVoucher: async (args = {}) => {
    let apiUrl = "/tr/v1/ZKMZU5R6VOIO88QE";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getPhysicalVoucherItems: async (args = {}) => {
    let apiUrl = "/tr/v1/TGHWFE276CDG99BP";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
};
