import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7008";

export const REPORTS_APIs = {
  getRegionReport: async (args = {}) => {
    let apiUrl = "/re/v1/911OXB04JEHO10FS";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getAreaReport: async (args = {}) => {
    let apiUrl = "/re/v1/HBD7YALYXG972ELC";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getRoutesReport: async (args = {}) => {
    let apiUrl = "/re/v1/TVILKQ0VZI32LURW";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getAttendanceReport: async (args = {}) => {
    let apiUrl = "/re/v1/GUG971E6XH7BWVO9";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getLeaveReport: async (args = {}) => {
    let apiUrl = "/re/v1/OW73GZWPXT4JDGGK";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getCustomerTypeReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/3H5PUTSBPUFG5EL6",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getCustomerReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/E3Z911JD87IJFWOB",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getAreaSalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/FME20ASSCMXPE63J",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getCitySalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/EN0VE48EVTCHE4U3",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getRegionSalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/VCUUEBNAUXH54M07",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getStateSalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/G9YKIX7E1QX4D1WB",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getProductSalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/0M0RVCUPN3SU043U",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getSubCategorySalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/BGWL9P9KFEZII4D6",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getMainCategorySalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/9CCXGMX5PABM4C0L",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getCategorySalesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/XRT1YYY56XYM3C0L",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getSchemeUtilizationReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/WNT5YDZ9M92LC3LW",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getSchemesReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/5CRQWRUHR56NHXMN",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getTargetsReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/B43OH9XOSFZCT1Z6",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getTotalTargetStatusReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/TKVTYL6P1V7DKV5J",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getProductTargetStatusReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/0QBUQCLX2SR5KT08",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getOrderDetailReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/C29UHVCNX81ZP312",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getBeatOrderReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/0NCPSLXKORUV0BPB",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getTeamReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/IIQTKPZDSXTXG4J7",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getCityList: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/PXK2SBMZH8A1WD8F",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getProductsReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/RAKW5XEL3PRKE8R1",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getAllLogsRegister: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/3ZH3C254DU6EE6LD",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getUserDetailedLog: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/883Y5851A13563IH",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getAreaList: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/XGF94VWQN7J94CNP",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getPriceListReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/FVX7SBHH4KBQATB7",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getCrmLeadReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/HCJ4PAPSAWA2T6Y0",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getFeedbackReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/63IL9SW3DTYT9JX8",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getInstructionReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/YWS6V5RDJ51O2PRP",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getSurveyReport: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/1HCTO1NUHV5O5Z6Q",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getCustomerList: async (args = {}, accessToken = "") => {
    const response = await COMMON_JS.callApi(
      "/re/v1/1N93YNBAR5M8QQKJ",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getCustomerOrders: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/re/v1/OWBV161Y2H0A3Q75",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getSalesMonthly: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/re/v1/631I2314N61107YP",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getRouteReport: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/re/v1/XGF94VWQN7J94CNP",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  getGeoDrillDownReport: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/re/v1/getgeodilldown",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  getAllCustomers: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/re/v1/GQSIRMDAXBXRLT2C",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  getLocationUpdate: async (args = {}) => {
    let apiUrl = "/re/v1/9BVLSFZ7J99B4LW1";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // POS Reports
  getPosRetailSale: async (args = {}) => {
    let apiUrl = "/re/v1/CGZWWGP0OHWB0EHY";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosBillItem: async (args = {}) => {
    let apiUrl = "/re/v1/X03D11QWN9QXBNSO";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosBillRegister: async (args = {}) => {
    let apiUrl = "/re/v1/0BLYU4FT9UT4IHYK";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosDaywiseMonthly: async (args = {}) => {
    let apiUrl = "/re/v1/64ZJYJROD8ESZKZS";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosIbtRegister: async (args = {}) => {
    let apiUrl = "/re/v1/V04IRI76D4IHNUI8";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosIbtOutwardRegister: async (args = {}) => {
    let apiUrl = "/re/v1/9B5OFYG6K1N9DL4F";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosStockReport: async (args = {}) => {
    let apiUrl = "/re/v1/P2M2TFR3J1U3I2JD";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosPresentStock: async (args = {}) => {
    let apiUrl = "/re/v1/W2AAVI3U4JIV0BGE";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosStockAdjustment: async (args = {}) => {
    let apiUrl = "/re/v1/PJ1HF2KHNCKN7744";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosPurchase: async (args = {}) => {
    let apiUrl = "/re/v1/D10KEMHTM36G8LC3";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosConsolidatePurchase: async (args = {}) => {
    let apiUrl = "/re/v1/M61IWQ28Q0C6AY1O";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getPosItemsList: async (args = {}) => {
    let apiUrl = "/re/v1/U1NBENYIZZH7KLZ6";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // DMS Report
  getDmsSalesOrder: async (args = {}) => {
    let apiUrl = "/re/v1/C55E0NFM0AZGM4W3";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsDeletedOrder: async (args = {}) => {
    let apiUrl = "/re/v1/GQSIRMDAXBXRLT2C";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsSalesBill: async (args = {}) => {
    let apiUrl = "/re/v1/WJPKYM8NEFH341AY";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsDeletedBill: async (args = {}) => {
    let apiUrl = "/re/v1/Z76NAUMT4AAS345L";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsPartyStatus: async (args = {}) => {
    let apiUrl = "/re/v1/M1GBOSFWQ2XK12ZT";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsSalesBillRegister: async (args = {}) => {
    let apiUrl = "/re/v1/Z1LG4APYOYV41RB4";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsSalesReturn: async (args = {}) => {
    let apiUrl = "/re/v1/9BVLSFZ7J99B4LW1";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsOrderBill: async (args = {}) => {
    let apiUrl = "/re/v1/HFGG6VJ3CR13A0MY";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsBillPayment: async (args = {}) => {
    let apiUrl = "/re/v1/NU9JLVMKVUYKKBK8";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsPurchaseBill: async (args = {}) => {
    let apiUrl = "/re/v1/FLIJRM7U62KACFM3";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsTotalSummary: async (args = {}) => {
    let apiUrl = "/re/v1/O1TJ3YZ556IX8QVI";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsSalesmanSummary: async (args = {}) => {
    let apiUrl = "/re/v1/6FOVHI9TM2DSLTF9";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDmsCurrentStock: async (args = {}) => {
    let apiUrl = "/re/v1/QEWB6WHDH47WH1XR";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getGraphData: async (args = {}) => {
    let apiUrl = "/re/v1/karthik";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getChartData: async (args = {}) => {
    let response = {
      status: 200,
      response: {},
    };
    return response;
  },
};
