import React, { useState, useEffect, useRef } from "react";
import AutoComplete from "components/Custom/AutoComplete";
import { DateFunctions } from "common//datefunctions";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import SkeletonBox from "components/Custom/SkeletonBox";
import HGraph from "components/Custom/HGraph";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { GRAPH_TYPES } from "common/config";
import { REPORTS_APIs } from "apihandlers/reports";
import { MASTER_APIs } from "apihandlers/master";
import { LABELS } from "common/lables";

const GraphDashboard = () => {
  const dealersRef: any = useRef(null);
  const viewRef: any = useRef(null);
  const filterRef: any = useRef(null);

  const [chartData, setChartData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [dealers, setDealers] = useState<any>([]);
  const [view, setView] = useState<any>([
    {
      id: "view1",
      label: "View1",
    },
    {
      id: "view2",
      label: "View2",
    },
    {
      id: "view3",
      label: "View3",
    },
  ]);

  const [filterdropdown, setFilterdropdown] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);

  useEffect(() => {
    getDealers();
  }, []);

  const getDealers = async () => {
    try {
      const args = {};
      const res: any = await MASTER_APIs.getOrgProfiles(args);
      if (res.status === 200) {
        setDealers(
          res.response.records.map((rec: any) => {
            return {
              id: rec.id,
              label: rec.organizationName,
            };
          })
        );
        // setLoading(false);
      }
    } catch (error) {
      console.log("getDealers: ", error);
    }
  };

  const handleChart = () => {
    const dealer = dealersRef.current.selectedValue.id;
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const toDt = (document.getElementById("toDt") as HTMLInputElement).value;
    const view = viewRef.current.selectedValue.id;
    const args = {
      fromDt,
      toDt,
      dealer,
      view,
    };
    console.log(args);
    setChartData(null);
    REPORTS_APIs.getChartData(args);
    setTimeout(() => {
      setChartData({ series: [] });
    }, 2000);
  };

  const onfilterChart = () => {
    const userInput = filterRef.current.selectedValue.id;
    console.log(userInput);
  };

  return (
    <>
      <div className="graphDashboardWrapper flexRow">
        <div className="formGroup width30">
          <label>{LABELS.DEALER}</label>
          <AutoComplete
            list={dealers}
            childRef={dealersRef}
            defaultValue={null}
            placeholder={"Select Dealer"}
            // onChange={() => getCustomers()}
          />
        </div>

        <div
          style={{
            display: "flex",
            marginRight: 15,
            marginLeft: 15,
            justifyContent: "",
            alignItems: "center",
          }}
        >
          <label
            style={{
              fontWeight: "500",
              fontSize: 14,
              marginTop: 6,
              marginRight: 8,
            }}
          >
            {LABELS.FROM_DATE}
          </label>
          <TxtBox
            type={"date"}
            id="fromDt"
            defaultValue={DateFunctions.getDate("today", "from", "YYYY-MM-DD")}
          />
        </div>

        <div
          style={{
            display: "flex",
            marginRight: 15,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            style={{
              fontWeight: "500",
              fontSize: 14,
              marginTop: 6,
              marginRight: 8,
            }}
          >
            {LABELS.TO_DATE}
          </label>
          <TxtBox
            type={"date"}
            id="toDt"
            defaultValue={DateFunctions.getDate(
              "thismonth",
              "to",
              "YYYY-MM-DD"
            )}
          />
        </div>
        <div className="formGroup width30">
          <label>{LABELS.VIEW}</label>
          <AutoComplete
            list={view}
            childRef={viewRef}
            defaultValue={null}
            placeholder={"Select View"}
            // onChange={() => getCustomers()}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "1rem",
            paddingTop: "1.4em",

            height: "50%",
          }}
        >
          <Btn text={LABELS.LOAD} onClick={handleChart} />
        </div>
      </div>

      <div>
        {!chartData ? (
          <div className="formGroup width30">
            <SkeletonBox />
          </div>
        ) : (
          chartData.series && (
            <div>
              <div className="formGroup width30">
                <label>{LABELS.FILTER}</label>
                <AutoComplete
                  list={filterdropdown}
                  childRef={filterRef}
                  defaultValue={null}
                  placeholder={"Select Filter"}
                  // onChange={() => getCustomers()}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "2rem",
                  paddingTop: "1.4em",

                  height: "40%",
                }}
              >
                <Btn text={LABELS.LOAD} onClick={onfilterChart} />
              </div>
              <div className="chartDiv">
                <HGraph
                  width={"50%"}
                  height={"60%"}
                  type={GRAPH_TYPES.COLUMN}
                  float={"right"}
                  // stacked={false}
                  xAxis={[
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ]}
                  yAxis={[20, 25, 32, 40, 65, 68, 55, 43, 0, 0, 0, 0]}
                  // xAxis={chartData.xAxis}
                  // yAxis={chartData.yAxis}
                  title={"Column Graph"}
                  exportTitle={"Column Graph Record"}
                  xAxisTitle=""
                  yAxisTitle="Leads"
                />
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default GraphDashboard;
