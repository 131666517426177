/*
 * Manages Export Things => CSV & PDF
 */

import { cloneElement } from "react";
import type { ReactElement } from "react";
import type { DataGridProps } from "react-data-grid";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import { COMMON_JS } from "common/scripts";
import { COLORS } from "common/colors";
import { LABELS } from "common/lables";

export async function exportToCsv(
  fileName: string,
  gridCols: any,
  sortedRows: any,
  btmRows: any
) {
  const rowsObj: any = getRowsObj(gridCols, sortedRows, btmRows);
  const { head, body, foot } = rowsObj;
  const content = [...head, ...body, ...foot]
    .map((cells: any) => cells.map(serialiseCellValue).join(","))
    .join("\n");
  downloadFile(
    fileName + ".csv",
    new Blob([content], { type: "text/csv;charset=utf-8;" })
  );
}

const getRowsObj = (gridCols: any, sortedRows: any, btmRows: any) => {
  const cols = [];
  const rows = [];
  const sumRows = [];
  const dontPrint = [];
  for (let i = 0; i < gridCols.length; i++) {
    if (gridCols[i].key) {
      if (
        gridCols[i].headerCellClass &&
        gridCols[i].headerCellClass.indexOf("dontprint") === -1
      ) {
        cols.push(gridCols[i].name);
        if (btmRows.length > 0) {
          if (cols.length === 1) {
            sumRows.push("");
          } else if (cols.length === 2) {
            sumRows.push("Total: " + btmRows[0]["totalRows"] + " records");
          } else {
            sumRows.push(btmRows[0][gridCols[i].key]);
          }
        }
      } else {
        dontPrint.push(i);
      }
    } else {
      dontPrint.push(i);
    }
  }
  for (let i = 0; i < sortedRows.length; i++) {
    const sortedRow = sortedRows[i];
    const tmpRow = [];
    let type = sortedRow._type || "";
    if (type !== "subgrid") {
      for (let j = 0; j < gridCols.length; j++) {
        let key = gridCols[j].key;
        if (dontPrint.indexOf(j) === -1) {
          tmpRow.push(sortedRow[key]);
        }
      }
    }
    if (tmpRow.length > 0) {
      rows.push(tmpRow);
    }
  }
  const rowsObj = {
    head: [cols],
    body: rows,
    foot: [sumRows],
  };
  return rowsObj;
};

export async function exportToPdf(
  fileName: string,
  gridCols: any,
  sortedRows: any,
  btmRows: any,
  orientation = "p",
  details = "",
  exportSplitColumn = "",
  exportSplitColumnPrefix = "",
  splitColumnValues = [],
  trimmer = [],
  css = ""
) {
  const rowsObj = getRowsObj(gridCols, sortedRows, btmRows);
  const { head, body, foot } = rowsObj;
  if (exportSplitColumn) {
    let splitColumnIndex = null;
    if (head.length > 0) {
      splitColumnIndex = head[0].findIndex((h) => h === exportSplitColumn);
    }
    let bodyJson: any = {};
    let prevVal = "";
    for (let b = 0; b < body.length; b++) {
      if (body[b][splitColumnIndex]) {
        if (prevVal !== body[b][splitColumnIndex]) {
          prevVal = body[b][splitColumnIndex];
        }
      }
      if (!bodyJson[prevVal]) {
        if (splitColumnValues.length > 0) {
          let tmpPrevVal = [];
          for (let t = 0; t < splitColumnValues.length; t++) {
            console.log("split: ", splitColumnValues[t]);
            let colIndex = head[0].findIndex((h) => h === splitColumnValues[t]);
            if (colIndex > -1) {
              tmpPrevVal.push(body[b][colIndex]);
            } else {
              tmpPrevVal.push(splitColumnValues[t]);
            }
          }
          bodyJson[prevVal] = {
            title: tmpPrevVal.join(""), // Page Sub title
            data: [],
          };
        } else {
          bodyJson[prevVal] = {
            title: prevVal,
            data: [],
          };
        }
      }
      bodyJson[prevVal]["data"].push(body[b]);
    }

    console.log("bodyJson:", bodyJson);
    // Get HTML content to print
    let printHtml = `
    <style>
      .mainBody{
        display:flex;
        flex-direction:column;
        width:100%;
        font-size:0.6rem;
        font-family: 'Mulish' !important;
      }
      .printTable{
        width:100%;
      }
      @page {
        size: a4;
        margin: 5mm;
      }
      section {
        page-break-after: always;
        break-after: page;
      }
      thead{
        background-color:${COLORS.PRIMARY}
      }
      th{
        color:#FFFFFF;
        text-align: left;
      }
      .printHeading {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 0.5rem;
      }
      .printHeading>h4 {
        margin: 0px;
        font-size: 1.5rem;
      }
      .subHeading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      .subTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      tfoot {
        background: ${COLORS.PRIMARY};
        color:#FFFFFF;
      }
      tfoot td {
        color:#FFFFFF;
      }
      td {
        border: 1px solid #ccc;
        font-size: 0.7rem;
      }
      tbody>tr:nth-of-type(odd) {
        background-color: #f5f5f5;
      }
      ${css}
      </style>
      <head>
      <link href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600&display=swap" rel="stylesheet">
      <title>${fileName}</title>
      </head>
      <div class="mainBody">`;
    if (splitColumnIndex) {
      for (let columnName in bodyJson) {
        printHtml += `
          <section>
          <div class="printHeading"><h4>${LABELS.COMPANY_NAME_REPORT}</h4></div>
          <div class="subHeading">${details}</div>
          <div class="subTitle">${
            exportSplitColumnPrefix + ": " + bodyJson[columnName]["title"]
          }</div>
          <div class="printTable">
          <table border="1" cellspacing="0" cellpadding="3" width="100%" style="border: 1px solid #ccc;">
          <thead>
          <tr>`;
        // Show Headings
        const columnsToExclude = trimmer;

        if (head.length > 0) {
          for (let h = 0; h < head[0].length; h++) {
            console.log(`Checking column ${h}`);
            if (!columnsToExclude.includes(h)) {
              console.log(`Including column ${h}`);
              printHtml += `<th>${head[0][h]}</th>`;
            } else {
              console.log(`Excluding column ${h}`);
            }
          }
        }

        printHtml += `
          </tr>
          </thead>
          <tbody>`;
        // Show Rows

        let continuousSerialNumber = 1; // Initial value for the continuous serial number

        for (let r = 0; r < bodyJson[columnName]["data"].length; r++) {
          printHtml += "<tr>";
          let row = bodyJson[columnName]["data"][r];

          // Skip the excluded columns and replace the first column with a local continuous serial number
          for (let k = 0; k < row.length; k++) {
            if (!columnsToExclude.includes(k)) {
              let val = row[k];
              if (val == null) {
                val = "";
              }
              if (k === 0) {
                // Add the local continuous serial number as the first column
                printHtml += "<td>" + continuousSerialNumber++ + "</td>";
              } else {
                printHtml += "<td>" + val + "</td>";
              }
            }
          }

          printHtml += "</tr>";
        }

        printHtml += `</tbody>
          </table>
          </div>
          </section>`;
      }
    }
    printHtml += `</div>`;
    COMMON_JS.printHtml(printHtml);
    return;
  }

  // Get HTML content to print
  let printHtml = `
  <style>
  .mainBody{
    display:flex;
    flex-direction:column;
    width:100%;
    fontSize:0.8rem;
  }
  .printTable{
    width:100%;
  }
  @page {
    size: a4;
    margin: 5mm;
  }
  section {
    page-break-after: always;
    break-after: page;
  }
  thead{
    background-color:${COLORS.PRIMARY}
  }
  th{
    color:#FFFFFF;
    text-align: left;
  }
  .printHeading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  .printHeading>h4 {
    margin: 0px;
    font-size: 1.5rem;
  }
  .subHeading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  tfoot {
    background: ${COLORS.PRIMARY};
    color:#FFFFFF;
  }
  tfoot td {
    color:#FFFFFF;
  }
  td {
    border: 1px solid #ccc;
  }
  </style>
  <head>
  <title>${fileName}</title>
  </head>
  <div class="mainBody">
  <section>
  <div class="printHeading"><h4>${LABELS.COMPANY_NAME_REPORT}</h4></div>
  <div class="subHeading">${details}</div>
  <div class="printTable">
  <table border="1" cellspacing="0" cellpadding="3" width="100%" style="border: 1px solid #ccc;">
  <thead>
  <tr>`;

  // Show Headings
  if (head.length > 0) {
    for (let h = 0; h < head[0].length; h++) {
      printHtml += `<th>${head[0][h]}</th>`;
    }
  }
  printHtml += `
  </tr>
  </thead>
  <tbody>`;

  // Show Rows
  for (let r = 0; r < body.length; r++) {
    printHtml += "<tr>";
    let row = body[r];
    for (let k = 0; k < row.length; k++) {
      printHtml += "<td>" + row[k] + "</td>";
    }
    printHtml += "</tr>";
  }
  printHtml += `</tbody>
  <tfoot>
  <tr>
  `;
  // Show Summary
  if (foot.length > 0) {
    for (let f = 0; f < foot[0].length; f++) {
      if (foot[0][f]) {
        printHtml += `<td>${foot[0][f]}</td>`;
      } else {
        printHtml += `<td></td>`;
      }
    }
  }
  printHtml += `
  </tr>
  </tfoot>
  </table>
  </div>
  </section>
  </div>
  `;
  console.log(printHtml);
  COMMON_JS.printHtml(printHtml);
  return;
}

function serialiseCellValue(value: unknown) {
  if (typeof value === "string") {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(",")
      ? `"${formattedValue}"`
      : formattedValue;
  }
  return value;
}

function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement("a");
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}
