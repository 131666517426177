import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { LABELS, TITLES } from "common/lables";
import { CRM_REPORTS_APIs } from "apihandlers/crm/reports";
import { DateFunctions } from "common/datefunctions";
import { CRM_LEAD_STATUS, FORMATS } from "common/config";
import { selectClasses } from "@mui/material";

const EXCEL_GRID_ID = "LeadVisits";

const LeadVisits = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        fromDate: DateFunctions.getDate("thismonth", "from", FORMATS.SQL_DATE),
        toDate: DateFunctions.getDate("thismonth", "to", FORMATS.SQL_DATE),
        // fromDate: "2023-06-01",
        // toDate: "2023-06-30",
      };
      const res: any = await CRM_REPORTS_APIs.getLeadVisits(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;

          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
          if (list[i]["verifiedStatus"]) {
            list[i]["verifiedStatus"] = "Yes";
          } else {
            list[i]["verifiedStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getLeadVisits:", error);
    }
  };

  const COLUMNS = [
    {
      key: "crmLeadName",
      name: LABELS.LEAD_NAME,
      width: "20%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "leadSource",
      name: LABELS.LEAD_SOURCE,
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "createdOn",
      name: LABELS.CREATED_ON,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "mobileNo",
      name: LABELS.MOBILE_NUMBER,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "fssiNumber",
      name: LABELS.FSSI_NO,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "fssiDate",
      name: LABELS.FSSAI_EXPIRY_DATE,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "ageing",
      name: LABELS.AGEING,
      width: "5%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "agencyName",
      name: LABELS.AGENCY_NAME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "workCondition",
      name: LABELS.WORK_CONDITION,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "investCapacity",
      name: LABELS.INVESTMENT_CAPACITY,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "businessDesignation",
      name: LABELS.BUSINESS_DESIGNATION,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "familyMembers",
      name: LABELS.FAMILY_MEMBERS,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "name",
      name: LABELS.NAME,
      width: "8%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "monthly",
      name: LABELS.MONTHLY_INCOME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "yearly",
      name: LABELS.YEARLY_INCOME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "companyName",
      name: LABELS.COMPANY_NAME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "firmGst",
      name: LABELS.FIRM_GST,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "firmType",
      name: LABELS.FIRM_TYPE,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "firstName",
      name: LABELS.FIRST_NAME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "lastName",
      name: LABELS.LAST_NAME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "firstOderName",
      name: LABELS.FIRST_ORDER_NAME,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "firstOrderDate",
      name: LABELS.FIRST_ORDER_DATE,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "firstOrderDesignation",
      name: LABELS.FIRST_ORDER_DESIGNATION,
      width: "10%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "fssiDate",
      name: LABELS.FSSAI_EXPIRY_DATE,
      width: "8%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "fssiNumber",
      name: LABELS.FSSI_NO,
      width: "8%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "godownSize",
      name: LABELS.GODOWN_SIZE,
      width: "8%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "onlineDate",
      name: LABELS.ONLINE_DATE,
      width: "8%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "packageBody",
      name: LABELS.PACK_BODY,
      width: "8%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "accessReason",
      name: LABELS.ACCESS_RESON,
      width: "25%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },

    {
      key: "workModeStatus",
      name: LABELS.WORK_MODE_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: LABELS.YES,
        },
        {
          id: "no",
          name: LABELS.NO,
        },
      ],
    },
    {
      key: "accessStatus",
      name: LABELS.ACCESS_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: "YES",
        },
        {
          id: "no",
          name: "NO",
        },
      ],
    },
    {
      key: "onlineStatus",
      name: LABELS.ONLINE_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: "YES",
        },
        {
          id: "no",
          name: "NO",
        },
      ],
    },
    {
      key: "businessStatus",
      name: LABELS.BUSINESS_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: "YES",
        },
        {
          id: "no",
          name: "NO",
        },
      ],
    },

    {
      key: "financeStatus",
      name: LABELS.FINANCE_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: "YES",
        },
        {
          id: "no",
          name: "NO",
        },
      ],
    },
    {
      key: "godownStatus",
      name: LABELS.GODOWN_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: "YES",
        },
        {
          id: "no",
          name: "NO",
        },
      ],
    },

    {
      key: "techStatus",
      name: LABELS.TECH_STATUS,
      width: "8%",
      type: "dropdown",

      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: "YES",
        },
        {
          id: "no",
          name: "NO",
        },
      ],
    },

    {
      key: "weeklyStatus",
      name: LABELS.ORDER_SCHEDULE,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: LABELS.WEEKLY,
          name: LABELS.WEEKLY,
        },
        {
          id: LABELS.BI_WEEKLY,
          name: LABELS.BI_WEEKLY,
        },
      ],
    },
    {
      key: "ownerStatus",
      name: LABELS.OWNER_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "own",
          name: LABELS.OWN,
        },
        {
          id: "rented",
          name: LABELS.RENTED,
        },
      ],
    },

    {
      key: "vehicleStatus",
      name: LABELS.VEHICLE_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "yes",
          name: LABELS.YES,
        },
        {
          id: "no",
          name: LABELS.NO,
        },
      ],
    },

    {
      key: "vehicleTypeStatus",
      name: LABELS.VEHICLE_TYPE_STATUS,
      width: "10%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: "old",
          name: LABELS.OLD,
        },
        {
          id: "new",
          name: LABELS.NEW,
        },
      ],
    },
    {
      key: "leadStatus",
      name: LABELS.LEAD_STATUS,
      width: "10%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,
      filters: [
        {
          id: CRM_LEAD_STATUS.NEW_LEAD,
          name: CRM_LEAD_STATUS.NEW_LEAD,
        },
        {
          id: CRM_LEAD_STATUS.VERIFIED,
          name: CRM_LEAD_STATUS.VERIFIED,
        },
        {
          id: CRM_LEAD_STATUS.ASSIGNED,
          name: CRM_LEAD_STATUS.ASSIGNED,
        },
        {
          id: CRM_LEAD_STATUS.FIELD_VISIT,
          name: CRM_LEAD_STATUS.FIELD_VISIT,
        },
        {
          id: CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
          name: CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
        },
        {
          id: CRM_LEAD_STATUS.APPROVALS,
          name: CRM_LEAD_STATUS.APPROVALS,
        },
        {
          id: CRM_LEAD_STATUS.QUALIFIED,
          name: CRM_LEAD_STATUS.QUALIFIED,
        },
        {
          id: CRM_LEAD_STATUS.CONVERTED,
          name: CRM_LEAD_STATUS.CONVERTED,
        },
        {
          id: CRM_LEAD_STATUS.CUSTOMER,
          name: CRM_LEAD_STATUS.CUSTOMER,
        },
      ],
    },
    {
      key: "verifiedStatus",
      name: LABELS.VERIFIED_STATUS,
      width: "8%",
      type: "dropdown",
      sortable: true,
      searchable: true,
      editable: false,

      filters: [
        {
          id: "yes",
          name: "YES",
        },
        {
          id: "no",
          name: "NO",
        },
      ],
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        { id: "yes", name: "Yes" },
        { id: "no", name: "No" },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
  ];

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  console.log("selectedRow", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{TITLES.CRM_REPORTS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.LEAD_VISITS}</li>
        </ul>
        <h4>{TITLES.LEAD_VISITS}</h4>
        {/* <p>{SUB_TITLES.LEAD_VISITS}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={EXCEL_GRID_ID}
            height={window.innerHeight * 0.85}
            cols={COLUMNS}
            rows={rows}
            hideUnhide={true}
            freezeColumn={true}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.LEAD_VISITS}
            onReload={reloadGrid}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(LeadVisits);
