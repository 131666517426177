import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Visibility as IconEyeOn,
  VisibilityOff as IconEyeOff,
} from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";

import "./styles/settings.css";

import { CONFIG, NOTIFY } from "common/config";

import { CONFIRMS, LABELS, SUCCESS, ERRORS, WARNINGS } from "common/lables";
import { AUTH_APIs } from "apihandlers/auth";
import { COMMON_JS } from "common/scripts";
import { COLORS } from "common/colors";
import { PRINT_APIs } from "apihandlers/print";

const Settings = (props: any) => {
  const { setPageMenu } = props;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);

  useEffect(() => {}, [loading]);

  const saveRecord = () => {
    const args = {
      id: 38,
    };
    PRINT_APIs.printDeliveryChallan(args).then((res: any) => {
      console.log("print:", res.response);
      if (res.status === 200) {
        COMMON_JS.printHtml(res.response);
      } else {
        COMMON_JS.showNotify(props, res.error, NOTIFY.ERROR);
      }
    });
    return;
    const cPwd: any = (document.getElementById("cPwd") as HTMLInputElement)
      .value;
    const nPwd: any = (document.getElementById("nPwd") as HTMLInputElement)
      .value;

    if (!cPwd) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.CPWD_EMPTY);
      return;
    }
    if (!nPwd) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.NPWD_EMPTY);
      return;
    }
    if (nPwd.length < 6) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.NPWD_LENGTH_SMALL);
      return;
    }
    if (cPwd === nPwd) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.BOTH_PASSWORDS_SAME);
      return;
    }

    setLoading(true);
    AUTH_APIs.changePassword({ cPassword: cPwd, nPassword: nPwd }).then(
      (res) => {
        if (res.status === 200) {
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.PASSWORD_CHANGED);
          setTimeout(() => {
            window.open("/login", "_self");
          }, 1000);
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
        }
        setLoading(false);
      }
    );
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setPageMenu(LABELS.HOME)}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.SETTINGS}</li>
        </ul>
        <h4>{LABELS.SETTINGS}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15, width: "30%" }}>
        <div className="formGroup">
          <label>{LABELS.CURRENT_PASSWORD}</label>
          <TxtBox id="cPwd" className="cPwd" />
        </div>
        <div className="formGroup">
          <label>{LABELS.NEW_PASSWORD}</label>
          <div style={{ position: "relative" }}>
            <TxtBox
              id="nPwd"
              className="nPwd"
              type={showPassword ? "text" : "password"}
            />
            <IconButton
              className="sViewPassword"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <IconEyeOn /> : <IconEyeOff />}
            </IconButton>
          </div>
        </div>
        <div
          className="formGroup"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 25,
            marginBottom: 30,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Btn
              className={"width100"}
              text={LABELS.SAVE}
              bgColor={COLORS.SAVE_GREEN}
              onClick={saveRecord}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(Settings);
