import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Paper,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Draggable from "react-draggable";

import Btn from "components/Custom/Btn";

import { BTN_VARIENTS } from "common/config";
import { COLORS } from "common/colors";

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const ConfirmDialog = (props: any) => {
  const { title, msg, open, size, draggable, children, onClose, onOk } = props;
  return (
    <Dialog
      className={"uiDialog"}
      open={open || false}
      PaperComponent={PaperComponent}
      fullWidth={true}
      maxWidth={size || "md"}
      onClose={onClose}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onOk && (
          <Btn
            variant={BTN_VARIENTS.TEXT}
            text={"OK"}
            bgColor={COLORS.RELOAD_EXPORT}
            color={COLORS.RELOAD_EXPORT_TEXT}
            onClick={onOk}
          />
        )}
        {onClose && (
          <Btn
            variant={BTN_VARIENTS.TEXT}
            text={"Cancel"}
            onClick={onClose}
            bgColor={COLORS.SAVE_GREEN}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
