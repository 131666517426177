import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import { API_CONFIG, NOTIFY, FORMATS } from "common/config";
import { LABELS, WARNINGS, TITLES, SUB_TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { REPORTS_APIs } from "apihandlers/reports";
import { CUSTOMER_APIs } from "apihandlers/customer";
import AutoComplete from "components/Custom/AutoComplete";
import { GEO_APIs } from "apihandlers/geography";
import { MASTER_APIs } from "apihandlers/master";

const CustomerInspectionReport = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(false);
  const [masters, setMasters] = useState<any>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>({});
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [customerPhoto, setCustomerPhoto] = useState<any>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "regionName",
      name: LABELS.REGION,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "cityName",
      name: LABELS.CITY,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "areaName",
      name: LABELS.AREA,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "driverName",
      name: LABELS.DRIVER_NAME,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "name",
      name: LABELS.CUSTOMER,
      width: "25%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "incharge",
      name: LABELS.INCHARGE,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "mobileNumber",
      name: LABELS.CONTACT_NUMBER,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   key: "address1",
    //   name: LABELS.ADDRESS_1,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      key: "week1",
      name: LABELS.WEEK_1,
      width: "9%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "week2",
      name: LABELS.WEEK_2,
      width: "9%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "week3",
      name: LABELS.WEEK_3,
      width: "9%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "week4",
      name: LABELS.WEEK_4,
      width: "9%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "week5",
      name: LABELS.WEEK_5,
      width: "9%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "week6",
      name: LABELS.REMARK_PRINT,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   key: "areaName",
    //   name: LABELS.AREA_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "cityName",
    //   name: LABELS.CITY_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "location",
    //   name: LABELS.LOCATION,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "longitude",
    //   name: LABELS.LONGITUDE,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "createdUser",
    //   name: LABELS.CREATED_USER,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "signature",
    //   name: LABELS.SIGNATURE,
    //   width: "15%",
    //   type: "html",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "profile",
    //   name: LABELS.PROFILE,
    //   width: "15%",
    //   type: "html",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
  ];

  // useEffect(() => {
  //   getCustomerPhoto();
  // }, []);

  const getCustomerPhoto = () => {
    const args = {
      customerId: 89,
    };
    CUSTOMER_APIs.getCustomerPhotos(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;

        setCustomerPhoto(list);
      }
    });
  };
  const [areas, setAreas] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [drivers, setDrivers] = useState<any>([]);
  const [exportName, setExportName] = useState("");
  const months = [
    { id: "January", label: "January" },
    { id: "February", label: "February" },
    { id: "March", label: "March" },
    { id: "April", label: "April" },
    { id: "May", label: "May" },
    { id: "June", label: "June" },
    { id: "July", label: "July" },
    { id: "August", label: "August" },
    { id: "September", label: "September" },
    { id: "October", label: "October" },
    { id: "November", label: "November" },
    { id: "December", label: "December" },
  ];

  const regionRef: any = useRef(null);
  const cityRef: any = useRef(null);
  const areaRef: any = useRef(null);
  const driverRef: any = useRef(null);
  const monthRef: any = useRef(null);

  useEffect(() => {
    getRegion();
    getDrivers();
  }, []);

  const getDrivers = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
      shortMode: "shortMode",
    };
    MASTER_APIs.getDrivers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setDrivers(list);
      }
    });
  };

  const getRegion = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    GEO_APIs.getRegions(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.regionName,
          };
        });
        setRegions(list);
      }
    });
  };

  const getCity = async (regionId) => {
    let cityList = [];
    try {
      const args = {
        regionId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getCities(args);
      cityList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.cityName,
        };
      });
    } catch (error) {}
    return cityList;
  };

  const getAreas = async (cityId) => {
    let areaList = [];
    try {
      const args = {
        cityId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getAreas(args);
      areaList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.areaName,
        };
      });
    } catch (error) {}
    return areaList;
  };

  const onRegionChange = async (e, f) => {
    if (f.id) {
      const res = await getCity(f.id);
      setCities(res);
    }
  };

  const onCityChange = async (e, f) => {
    if (f.id) {
      const res = await getAreas(f.id);
      setAreas(res);
    }
  };

  const loadChart = () => {
    const areaId = areaRef.current.selectedValue.id;
    const cityId = cityRef.current.selectedValue.id;
    const regionId = regionRef.current.selectedValue.id;

    // const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
    //   .value;
    // const toDt = (document.getElementById("toDt") as HTMLInputElement).value;

    const timestamp = new Date().getTime();

    // Create a new Date object using the timestamp
    const date = new Date(timestamp);

    // Define options for formatting
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    // Use toLocaleDateString to format the date
    const formattedDate: string = date.toLocaleDateString("en-GB", options);

    setExportName(
      "     " + LABELS.COMPANY_NAME_REPORT
      // "\n" +
      // "     " +
      // LABELS.CUSTOMER_INSPECTION_REPORT +
      // "               " +
      // LABELS.PRINT_DATE +
      // ": " +
      // formattedDate +
      // "\n" +
      // "     " +
      // LABELS.MONTH +
      // ": " +
      // monthRef.current.selectedValue.label +
      // "               " +
      // LABELS.DRIVER +
      // ": " +
      // driverRef.current.selectedValue.label +
      // "\n" +
      // "     " +
      // LABELS.REGION +
      // ": " +
      // regionRef.current.selectedValue.label +
      // "               " +
      // LABELS.AREA_LABLE +
      // ": " +
      // cityRef.current.selectedValue.label +
      // "               " +
      // LABELS.ROUTE +
      // ": " +
      // areaRef.current.selectedValue.label +
      // "\n"
      // +
      // LABELS.FROM_DATE +
      // ":" +
      // fromDt +
      // "\n" +
      // LABELS.TO_DATE +
      // ":" +
      // toDt
    );

    const args = {
      areaId,
      cityId,
      regionId,
      // fromDt,
      // toDt,
      reportType: "excel",
      inspectionFilter: "short",
    };
    let errors = [];
    // if (fromDt == "") {
    //   errors.push({
    //     id: "fromDt",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.FROM_DATE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    // if (toDt == "") {
    //   errors.push({
    //     id: "toDt",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.TO_DATE +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    setRows(null);
    setLoading(true);
    REPORTS_APIs.getAllCustomers(args).then((res) => {
      // console.log("getUserLogReport:", args);
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          let fileUrl = API_CONFIG.STATIC_URL + list[i].signaturePath;

          list[i].signature = (
            <div>
              <img
                src={fileUrl}
                alt=""
                width={100}
                height={50}
                onClick={() => {
                  window.open(fileUrl, "_blank");
                }}
              />
            </div>
          );
          list[i].location = (
            <div>
              <a
                className="textShadowBlack"
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  list[i].latitude +
                  "," +
                  list[i].longitude
                }
                target="_new"
              >
                {LABELS.VIEW_LOCATION}
              </a>
            </div>
          );
        }
        setRows(list);
        setLoading(false);
      }
    });

    // const custArgs = {
    //   customerId: 89,
    // };
    // CUSTOMER_APIs.getCustomerPhotos(custArgs).then((res) => {
    //   // console.log("getUserLogReport:", args);
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list2 = response.records;
    //     for (let i = 0; i < list2.length; i++) {
    //       list2[i]["slNo"] = i + 1;
    //       let fileUrl = API_CONFIG.STATIC_URL + list2[i].path;

    //       list2[i].profile = (
    //         <div>
    //           <img
    //             src={fileUrl}
    //             alt=""
    //             width={100}
    //             height={50}
    //             onClick={() => {
    //               window.open(fileUrl, "_blank");
    //             }}
    //           />
    //         </div>
    //       );
    //     }
    //     setCustomerPhoto(list2);
    //   }
    // });
  };

  const reloadGrid = () => {
    setRows(null);
    loadChart();
  };

  const outletRef: any = useRef();

  // console.log("elist:", masters);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {TITLES.REPORTS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.CUSTOMER_INSPECTION_REPORT}</li>
        </ul>
        <h4>{LABELS.CUSTOMER_INSPECTION_REPORT}</h4>
        <p>{LABELS.CUSTOMER_INSPECTION_REPORT}</p>
      </div>

      <div className="hChart">
        <div className=" flexRowJustify width50 ">
          <div className="formGroup" style={{ width: "45%" }}>
            <label>{LABELS.MONTH}</label>
            <AutoComplete
              id={"month"}
              list={months}
              childRef={monthRef}
              onChange={(e, f) => {
                setSelectedMonth(f);
              }}
            />
          </div>
          <div className="formGroup" style={{ width: "45%" }}>
            {/* <label>{LABELS.DRIVER}</label>

            <AutoComplete id={"driver"} list={drivers} childRef={driverRef} /> */}
          </div>
        </div>
        <div className=" flexRowJustify width50 ">
          <div className="formGroup width30 ">
            <label>{LABELS.REGION}</label>

            <AutoComplete
              id={"region"}
              list={regions}
              childRef={regionRef}
              onChange={onRegionChange}
            />
          </div>
          <div className="formGroup width30 ">
            <label>{LABELS.AREA_LABLE}</label>

            <AutoComplete
              id={"city"}
              list={cities}
              childRef={cityRef}
              onChange={onCityChange}
            />
          </div>
          <div className="formGroup width30 ">
            <label>{LABELS.ROUTE}</label>

            <AutoComplete id={"area"} list={areas} childRef={areaRef} />
          </div>
        </div>

        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {/* <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 5,
                marginBottom: 10,
                width: "40%",
              }}
            >
              <label>{LABELS.SELECT_OUTLET + " :"}</label>
              <AutoComplete
                id={"masters"}
                list={masters}
                childRef={outletRef}
                defaultValue={{ id: -1, label: "All" }}
              />
            </div> */}
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 0,
              marginBottom: 20,
            }}
          >
            {/* <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.FROM_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="fromDt"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.TO_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="toDt"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div> */}

            <div
              style={{
                display: "flex",
              }}
            >
              <Btn text={LABELS.LOAD} onClick={loadChart} />
            </div>
          </div>
        </div>
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="grid excelGridWrapper">
          {loading ? (
            <div className="flexRowCenter" style={{ minHeight: 450 }}>
              <Loader />
            </div>
          ) : (
            <ExcelGrid
              id={LABELS.CUSTOMER_INSPECTION_REPORT}
              height={window.innerHeight * 0.8}
              cols={columns}
              rows={rows}
              exportOption={true}
              newRowOption={false}
              exportName={LABELS.CUSTOMER_INSPECTION_REPORT}
              exportDetails={
                `
                <div style="display:flex;flex-direction:row;justify-content:space-between;width:100%;">
                <div>
                <b>${LABELS.CUSTOMER_INSPECTION_REPORT}</b>
                </div>
                <div>
                Month: ${selectedMonth.label || ""}
                </div>
                <div>
                ${
                  LABELS.PRINT_DATE +
                  ": " +
                  DateFunctions.getFormatedDate(
                    new Date(),
                    FORMATS.SQL_DATE,
                    FORMATS.PRINT_DATE
                  )
                }
                </div>
                </div>
                `
                // LABELS.CUSTOMER_INSPECTION_REPORT +
                // ": &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                // "Month: " +
                // (selectedMonth.label || "") +
                // "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                // LABELS.PRINT_DATE +
                // ": " +
                // DateFunctions.getFormatedDate(
                //   new Date(),
                //   FORMATS.SQL_DATE,
                //   FORMATS.PRINT_DATE
                // )
              }
              exportSplitColumn={LABELS.AREA}
              exportSplitColumnPrefix={LABELS.REGION}
              trimmer={[1, 2, 3, 4]}
              splitColumnValues={[
                LABELS.REGION,
                "&emsp;&emsp;",
                "Area: ",
                LABELS.CITY,
                "&emsp;&emsp;",
                "Route: ",
                LABELS.AREA,
                "&emsp;&emsp;",
                "Driver: ",
                LABELS.DRIVER_NAME,
              ]} // Send only If you want to display two or more values
              onReload={reloadGrid}
              title={LABELS.CUSTOMER_INSPECTION_REPORT}
              exportCss={`
              thead>tr>th:nth-child(1) {
                width: 3%;
              }
              thead>tr>th:nth-child(2) {
                width: 30%;
              }
              thead>tr>th:nth-child(3) {
                width: 12%;
              }
              thead>tr>th:nth-child(4) {
                width: 8%;
              }
              thead>tr>th:nth-child(5) {
                width: 5%;
              }
              thead>tr>th:nth-child(6) {
                width: 5%;
              }
              thead>tr>th:nth-child(7) {
                width: 5%;
              }
              thead>tr>th:nth-child(8) {
                width: 5%;
              }
              thead>tr>th:nth-child(9) {
                width: 5%;
              }
              thead>tr>th:nth-child(10) {
                width: 10%;
              }
              `}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(CustomerInspectionReport);
