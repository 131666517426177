import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import "./UsersSkeleton";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { USER_APIs } from "apihandlers/user";
import { MASTER_APIs } from "apihandlers/master";

const Users = (props: any) => {
  const { setDashMenu, privileges, dmsMasters = "" } = props;

  const userTypesRef: any = useRef(null);
  const designationRef: any = useRef(null);
  const deparmentRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [userTypes, setUserTypes] = useState<any>([]);
  const [designations, setDesignation] = useState<any>([]);
  const [department, setDepartment] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getUserTypes();
    }
  }, [loading]);

  const getUserTypes = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
      minResponse: true,
    };

    USER_APIs.getUserTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.label,
          };
        });
        setUserTypes(list);
        getRows();
        getDesignation();
      }
    });
  };

  const getDesignation = () => {
    const args = {
      minResponse: true,
      activeStatus: true,
    };

    MASTER_APIs.getUserDesignations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.designation,
          };
        });
        setDesignation(list);
        getDepartment();
      }
    });
  };

  const getDepartment = () => {
    const args = {
      minResponse: true,
      activeStatus: true,
    };

    MASTER_APIs.getUserDepartments(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.department,
          };
        });
        setDepartment(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await USER_APIs.getUsers(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getRegions:", error);
    }
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "fullName",
      name: LABELS.NAME,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "firstName",
      name: LABELS.FIRST_NAME,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "lastName",
      name: LABELS.LAST_NAME,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "userName",
      name: LABELS.USER_NAME,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "userType",
      name: LABELS.USER_ROLE,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "phoneNumber",
      name: LABELS.PHONE,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "emailId",
      name: LABELS.EMAIL_ID,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "orgName",
      name: LABELS.ORGANIZATION,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "department",
      name: LABELS.DEPARTMENT,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "designation",
      name: LABELS.DESIGNATION,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const dmsColumns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "fullName",
      name: LABELS.NAME,
      width: "25%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "userType",
      name: LABELS.USER_ROLE,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "phoneNumber",
      name: LABELS.PHONE,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "emailId",
      name: LABELS.EMAIL_ID,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "designation",
      name: LABELS.DESIGNATION,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },

    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      width: "5%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageUser = () => {
    const firstName = (
      document.getElementById("firstName") as HTMLInputElement
    ).value.trim();
    const lastName = (
      document.getElementById("lastName") as HTMLInputElement
    ).value.trim();
    const userName = (
      document.getElementById("userName") as HTMLInputElement
    ).value.trim();
    const password = (
      document.getElementById("password") as HTMLInputElement
    ).value.trim();
    const emailId = (
      document.getElementById("emailId") as HTMLInputElement
    ).value.trim();

    const phoneNumber = (
      document.getElementById("mobileNo") as HTMLInputElement
    ).value.trim();

    let employeeCode = "";
    let dateOfJoining = "";
    let departmentId = "";
    let designationId = "";
    let cadre = "";
    let band = "";
    let address = "";
    if (dmsMasters === "") {
      employeeCode = (
        document.getElementById("employeeCode") as HTMLInputElement
      ).value.trim();
      dateOfJoining = (
        document.getElementById("dateOfJoining") as HTMLInputElement
      ).value.trim();
      departmentId = deparmentRef.current.selectedValue.id;
      designationId = designationRef.current.selectedValue.id;
      cadre = (
        document.getElementById("cadre") as HTMLInputElement
      ).value.trim();
      band = (document.getElementById("band") as HTMLInputElement).value.trim();
      address = (
        document.getElementById("address") as HTMLInputElement
      ).value.trim();
    }

    const userTypeId = userTypesRef.current.selectedValue.id;

    const active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = active ? active.value : "";

    let errors = [];
    const regMobile = /^[1-9]\d{9}$/;
    const emailRegex =
      /^([A-Za-z]){1}[A-Za-z0-9]*([_.-][A-Za-z0-9]+)*@[A-Za-z0-9]+(\.[A-Za-z]{2,})$/;

    if (!firstName) {
      errors.push({
        id: "firstName",
        msg:
          "<span class='bold'>" +
          LABELS.FIRST_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!userName) {
      errors.push({
        id: "userName",
        msg:
          "<span class='bold'>" +
          LABELS.USER_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!userTypeId) {
      errors.push({
        id: "userTypeId",
        msg:
          "<span class='bold'>" +
          LABELS.USER_ROLE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (phoneNumber) {
      if (!phoneNumber.match(regMobile)) {
        errors.push({
          id: "phoneNumber",
          msg:
            "Please Enter Valid 10 Digit" +
            "<span class='bold'> Mobile Number</span>",
        });
      }
    }
    if (!emailId.match(emailRegex)) {
      errors.push({
        id: "emailId",
        msg: "Please Enter Valid Email Id (example@email.com)",
      });
    }

    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      userId: selectedRow.id || -1,
      firstName,
      lastName,
      userName,
      password,
      emailId,
      phoneNumber,
      userTypeId,
      employeeCode,
      dateOfJoining,
      departmentId,
      designationId,
      cadre,
      band,
      address,
      activeStatus,
    };

    console.log("args", args);
    setIsSubmit(true);
    USER_APIs.manageUser(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          {dmsMasters === "" ? (
            <>
              <li onClick={() => setDashMenu(LABELS.HOME)}>
                {LABELS.MASTERS}{" "}
              </li>
              <li className="dim">{" / "}</li>
              <li className="active">{TITLES.USERS}</li>
            </>
          ) : (
            <li className="active">{TITLES.SALESMAN}</li>
          )}
        </ul>
        {dmsMasters === "" ? (
          <>
            <h4>{TITLES.USERS}</h4>
            <p>{SUB_TITLES.USERS}</p>
          </>
        ) : (
          <>
            <h4>{TITLES.SALESMAN}</h4>
            <p>{SUB_TITLES.SALESMAN}</p>
          </>
        )}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"users"}
            height={window.innerHeight * 0.8}
            cols={dmsMasters === "" ? COLUMNS : dmsColumns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.USERS}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.USERS + ": " + selectedRow.fullName
              : "Add New " +
                (dmsMasters === "" ? LABELS.USERS : LABELS.SALESMAN)
          }
          open={true}
          size={"md"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexRowJustify">
            <div className="formGroup width30 red ">
              <TxtRow
                id={"firstName"}
                type={"text"}
                label={LABELS.FIRST_NAME}
                defaultValue={selectedRow.firstName}
                disabled={isSubmit}
                required={true}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"lastName"}
                type={"text"}
                label={LABELS.LAST_NAME}
                defaultValue={selectedRow.lastName}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 red ">
              <TxtRow
                id={"userName"}
                type={"text"}
                label={LABELS.USER_NAME}
                defaultValue={selectedRow.userName}
                disabled={isSubmit}
                required={true}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30 red">
              <TxtRow
                id={"emailId"}
                type={"text"}
                label={LABELS.EMAIL_ID}
                defaultValue={selectedRow.emailId}
                disabled={isSubmit}
                required={true}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"password"}
                type={"text"}
                label={LABELS.PASSWORD}
                defaultValue={selectedRow.password}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 required">
              <label className="red">
                {LABELS.USER_ROLE}
                {" *"}
              </label>
              <AutoComplete
                list={userTypes}
                childRef={userTypesRef}
                defaultValue={
                  selectedRow.userTypeId
                    ? {
                        id: selectedRow.userTypeId,
                        label: selectedRow.userType,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"mobileNo"}
                type={"number"}
                label={LABELS.MOBILE_NUMBER}
                defaultValue={selectedRow.phoneNumber}
                disabled={isSubmit}
              />
            </div>
            {dmsMasters === "" && (
              <>
                <div className="formGroup width30">
                  <TxtRow
                    id={"employeeCode"}
                    type={"text"}
                    label={LABELS.EMPLOYEE_CODE}
                    defaultValue={selectedRow.employeeCode}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"dateOfJoining"}
                    type={"date"}
                    label={LABELS.DATE_OF_JOINING}
                    defaultValue={selectedRow.dateOfJoining}
                    disabled={isSubmit}
                  />
                </div>{" "}
              </>
            )}
          </div>
          {dmsMasters === "" && (
            <>
              <div className="flexRowJustify">
                <div className="formGroup width30 ">
                  <label>{LABELS.DEPARTMENT}</label>
                  <AutoComplete
                    list={department}
                    childRef={deparmentRef}
                    defaultValue={
                      selectedRow.departmentId
                        ? {
                            id: selectedRow.departmentId,
                            label: selectedRow.department,
                          }
                        : null
                    }
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30 ">
                  <label>{LABELS.DESIGNATION}</label>
                  <AutoComplete
                    list={designations}
                    childRef={designationRef}
                    defaultValue={
                      selectedRow.designationId
                        ? {
                            id: selectedRow.designationId,
                            label: selectedRow.designation,
                          }
                        : null
                    }
                    disabled={isSubmit}
                  />
                </div>

                <div className="formGroup width30">
                  <TxtRow
                    id={"cadre"}
                    type={"text"}
                    label={LABELS.CADRE}
                    defaultValue={selectedRow.cadre}
                    disabled={isSubmit}
                  />
                </div>
              </div>
            </>
          )}
          <div className="flexRowJustify">
            {dmsMasters === "" && (
              <>
                <div className="formGroup width30">
                  <TxtRow
                    id={"band"}
                    type={"number"}
                    label={LABELS.BAND}
                    defaultValue={selectedRow.band}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"address"}
                    type={"text"}
                    label={LABELS.ADDRESS}
                    defaultValue={selectedRow.address}
                    disabled={isSubmit}
                  />
                </div>
              </>
            )}
            {/* <div className="formGroup width30"></div> */}

            <div className="formGroup required width30">
              <label>{LABELS.ACTIVE_STATUS}</label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Yes"
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "No"
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                disabled={isSubmit}
              />
            </div>
          </div>

          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className=" flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageUser}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Users);
