import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import ExcelGrid from "components/Custom/ExcelGrid";
import RadioBtn from "components/Custom/RadioBtn";
import TxtRow from "components/Custom/TxtRow";
import Loader from "components/Custom/Loader";
import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/lables";
import { COLORS } from "common/colors";
import { DEFAULTS, NOTIFY } from "common/config";
import { COMMON_JS } from "common/scripts";

import { GEO_APIs } from "apihandlers/geography";

const State = (props: any) => {
  const { setDashMenu } = props;

  const countriesRef: any = useRef(null);
  const centralZoneRef: any = useRef(null);

  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [countries, setCountries] = useState<any>([]);
  const [centralZone, setCentralZone] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      align: "center",
      headerAlign: "center",
      editable: "false",
      searchable: "true",
      sortable: "true",
    },
    {
      key: "stateName",
      name: LABELS.STATE_NAME,
      width: "25%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "countryName",
      name: LABELS.COUNTRY_NAME,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "stateCode",
      name: LABELS.STATE_CODE,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "centralZoneName",
      name: LABELS.CENTRAL_ZONE,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "sortOrder",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: "true",
    },
    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      searchable: true,
      type: "dropdown",
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      filters: [
        {
          id: "1",
          name: "Yes",
        },
        {
          id: "2",
          name: "No",
        },
      ],
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      width: "5%",
      renderCell: ({ params }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getCountries();
    }
  }, [loading]);

  const getCountries = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };
    GEO_APIs.getCountries(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.countryName,
          };
        });
        setCountries(list);
        getCentralZones();
        getRows();
      }
    });
  };

  const getCentralZones = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    GEO_APIs.getCentralZones(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.centralZoneName,
          };
        });
        setCentralZone(list);
        getRows();
      }
    });
  };

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    GEO_APIs.getStates(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const manageState = () => {
    const stateName = (
      document.getElementById("name") as HTMLInputElement
    ).value.trim();
    const countryId = countriesRef.current.selectedValue.id;
    const centralZoneId = centralZoneRef.current.selectedValue.id;
    const stateCode = (
      document.getElementById("stateCode") as HTMLInputElement
    ).value.trim();
    const sortOrder = (
      document.getElementById("sortingOrder") as HTMLInputElement
    ).value.trim();
    let ele = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = ele ? ele.value : "";

    //validation
    let errors = [];
    if (stateName === "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.STATE_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!countryId) {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.COUNTRY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      stateName,
      countryId,
      sortOrder: Number(sortOrder).toString(),
      stateCode,
      centralZoneId,
      activeStatus,
    };

    setIsSubmit(true);
    GEO_APIs.manageState(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("Page: State:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.STATE}</li>
        </ul>
        <h4>{TITLES.STATE}</h4>
        <p>{SUB_TITLES.STATE}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"state"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.STATE}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + TITLES.STATE + ": " + selectedRow.stateName
              : "Add " + TITLES.STATE
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexColumn">
            <div className="formGroup red">
              <TxtRow
                id={"name"}
                label={LABELS.STATE}
                defaultValue={selectedRow.id ? selectedRow.stateName : ""}
                type={"text"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup red">
              <label>
                {LABELS.COUNTRY}
                <span className="red">{" *"}</span>
              </label>
              <AutoComplete
                list={countries}
                childRef={countriesRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.countryId,
                        label: selectedRow.countryName,
                      }
                    : null
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <TxtRow
                id={"stateCode"}
                label={LABELS.STATE_CODE}
                defaultValue={selectedRow.id ? selectedRow.stateCode : ""}
                type={"text"}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup ">
              <label>{LABELS.CENTRAL_ZONE}</label>
              <AutoComplete
                list={centralZone}
                childRef={centralZoneRef}
                defaultValue={
                  selectedRow.centralZoneId
                    ? {
                        id: selectedRow.centralZoneId,
                        label: selectedRow.centralZoneName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup">
              <TxtRow
                id={"sortingOrder"}
                type={"number"}
                label={LABELS.SORTING_ORDER}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.sortOrder).toString()
                    : selectedRow.sortOrder
                }
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup required">
              <label>
                {LABELS.ACTIVE_STATUS}
                <span>*</span>
              </label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Yes"
                      : true,
                    disabled: isSubmit,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "No"
                      : false,
                    disabled: isSubmit,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                disabled={isSubmit}
                required={true}
              />
            </div>

            {/* mandatoryResults */}
            {mandatoryResults.length > 0 && (
              <div className="mandatoryBox">
                <ul>
                  {mandatoryResults.map((m) => {
                    return (
                      <li>
                        <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            <div className="formGroup submitBtnWrapper">
              <div className="flexbtnjustify">
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => {
                    setManageDialog(false);
                    setMandatoryResults([]);
                  }}
                  loading={isSubmit}
                />
                <Btn
                  text={LABELS.SAVE}
                  onClick={manageState}
                  loading={isSubmit}
                  bgColor={COLORS.SAVE_GREEN}
                />
              </div>
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};
export default withSnackbar(State);
