import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";

import { FORMATS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import { LABELS, WARNINGS, TITLES, SUCCESS, ERRORS } from "common/lables";
import { COMMON_JS } from "common/scripts";
import { DateFunctions } from "common/datefunctions";
import { GEO_APIs } from "../../../apihandlers/geography";
import { USER_APIs } from "apihandlers/user";

const EXCEL_GRID_ID = "Survey";

const Survey = (props: any) => {
  const { setDashMenu } = props;

  const beatRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [beats, setBeats] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getArea();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await USER_APIs.getSurveys(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getRegions:", error);
    }
  };

  const getArea = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getAreas(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.areaName,
          };
        });
        setBeats(list);
        getRows();
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "areaName",
      name: LABELS.BEAT_PJP,
      width: "35%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "surveyDate",
      name: LABELS.DATE,
      width: "20%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "messages",
      name: LABELS.OBSERVATION_COMMENTS,
      width: "35%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageSurvey = () => {
    const messages = (
      document.getElementById("messages") as HTMLInputElement
    ).value.trim();
    const areaId = beatRef.current.selectedValue.id;
    const surveyDate = (
      document.getElementById("surveyDate") as HTMLInputElement
    ).value.trim();

    // Validations
    let errors = [];
    if (!messages) {
      errors.push({
        id: "messages",
        msg:
          "<span class='bold'>" +
          LABELS.OBSERVATION_COMMENTS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!areaId) {
      errors.push({
        id: "area",
        msg:
          "<span class='bold'>" +
          LABELS.BEAT_PJP +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      messages: messages,
      areaId: areaId,
      surveyDate: surveyDate,
    };
    console.log("args", args);
    setIsSubmit(true);
    USER_APIs.manageSurvey(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.SURVEY}</li>
        </ul>
        <h4>{TITLES.SURVEY}</h4>
        {/* <p>{SUB_TITLES.SURVEY}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            id={EXCEL_GRID_ID}
            hideUnhide={true}
            freezeColumn={true}
            exportName={LABELS.SURVEY}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.SURVEY + ": " + selectedRow.areaName
              : "Add New " + LABELS.SURVEY
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup ">
            <label>
              {LABELS.BEAT_PJP}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={beats}
              childRef={beatRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.areaId,
                      label: selectedRow.areaName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"surveyDate"}
              type={"date"}
              label={LABELS.DATE}
              required={true}
              defaultValue={
                selectedRow.surveyDate
                  ? DateFunctions.getFormatedDate(
                      selectedRow.surveyDate,
                      FORMATS.SQL_DATE,
                      FORMATS.SQL_DATE_ONLY
                    )
                  : DateFunctions.getDate(
                      "today",
                      "from",
                      FORMATS.SQL_DATE_ONLY
                    )
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"messages"}
              rows={4}
              type={"text"}
              label={LABELS.OBSERVATION_COMMENTS}
              defaultValue={selectedRow.id ? selectedRow.messages : ""}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className="width45 flexRowJustify">
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageSurvey}
              />
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Survey);
