import React, { useState } from "react";
import { CheckCircle as IconDone } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import Btn from "components/Custom/Btn";

import { INFO, LABELS, SUCCESS, TITLES, WARNINGS } from "common/lables";
import { COLORS } from "common/colors";
import { CRM_LEAD_STATUS, NOTIFY } from "common/config";
import { COMMON_JS } from "common/scripts";
import { CRM_APIs } from "apihandlers/crm";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import TxtRow from "components/Custom/TxtRow";

const successAnimation = require("./assets/success.gif");
const successVideo = require("./assets/success.mp4");
const successGif = require("./assets/welcome.gif");

const Converted = (props: any) => {
  const { selectedRow, onClose } = props;
  const [status, setStatus] = useState(selectedRow.crmLeadStatus);
  const [isConvert, setIsConvert] = useState<boolean>(true);
  const [isConvertDialog, setIsConvertDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConverted = async () => {
    try {
      const remarks = (
        document.getElementById("remarks") as HTMLInputElement
      ).value.trim();
      const argsRemarks = {
        ...selectedRemarks,
        converted: remarks,
      };
      // const sure = window.confirm(WARNINGS.ARE_YOU_SURE);
      if (isConvert) {
        const args = {
          crmLeadId: selectedRow.id,
          crmLeadName: selectedRow.crmLeadName,
          activeStatus: "true",
          crmLeadStatus: CRM_LEAD_STATUS.CONVERTED,
          remarks: argsRemarks,
        };
        const res = await CRM_APIs.manageCrmLeadProfile(args);
        if (res.status === 200) {
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
          setStatus(CRM_LEAD_STATUS.CONVERTED);
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            onClose();
          }, 3000);
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
        }
      }
    } catch (error) {
      console.error("onConverted: ", error);
    }
  };

  const selectedRemarks = selectedRow.remarks
    ? JSON.parse(selectedRow.remarks)
    : {};
  console.error("COVERTED  SELECTED ROW", selectedRow);
  console.log("COVERTED SELECTED ROW", selectedRow);
  return (
    <>
      {status === CRM_LEAD_STATUS.CONVERTED ? (
        <div
          className="flexColumn"
          style={{ alignItems: "center", marginTop: 60 }}
        >
          <div style={{ width: "30%" }}>
            <img src={successAnimation} style={{ width: "100%" }} />
            {/* <video src={successVideo} style={{ width: "100%" }} autoPlay loop /> */}
            {/* <img src={successGif} style={{ width: "100%" }} /> */}
          </div>
          <div className="flexRow" style={{ alignItems: "center" }}>
            <IconDone
              style={{ color: COLORS.GREEN, marginRight: 10, fontSize: "2rem" }}
            />
            <h4 style={{ color: COLORS.GREEN, fontSize: "1.5rem" }}>
              {INFO.CONGRATULATION_LEAD_CONVERTED}
            </h4>
          </div>

          {!isLoading && (
            <div className="formGroup width45 ">
              <TxtRow
                id={"remarks"}
                type={"text"}
                label={LABELS.REMARKS}
                defaultValue={selectedRemarks.converted || " "}
                disabled={true}
                rows={4}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="formGroup width45 ">
            <TxtRow
              id={"remarks"}
              type={"text"}
              label={LABELS.REMARKS}
              // defaultValue={selectedRemarks.assigned || " "}
              //disabled={isSubmit}
              rows={4}
            />
          </div>
          <div
            className="flexColumn"
            style={{
              alignItems: "center",
              marginTop: "5rem",
            }}
          >
            <div
              className="flexRow"
              style={{ width: "20%", justifyContent: "center" }}
            >
              <Btn
                text={CRM_LEAD_STATUS.CONVERTED}
                style={{ width: "100%" }}
                onClick={() => {
                  setIsConvertDialog(true);
                }}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </>
      )}
      {isConvertDialog && (
        <ConfirmDialog
          title={TITLES.CONFIRM_LEAD_CONVERT}
          open={true}
          onClose={() => {
            setIsConvertDialog(false);
          }}
          msg={LABELS.CONFIRM_LEAD_CONVERT_MSG}
          onOk={() => {
            // setIsConvert(true);
            onConverted();
            setIsConvertDialog(false);
          }}
          size={"md"}
        />
      )}
    </>
  );
};

export default withSnackbar(Converted);
