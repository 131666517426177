import React from "react";
import { TextField } from "@mui/material";
import { COLORS } from "common/colors";

const TxtBox = (props: any) => {
  const {
    id,
    name,
    text,
    variant,
    className,
    disabled,
    type,
    placeholder,
    rows,
    accept,
    autoFocus,
    defaultValue,
    onChange,
    onKeyPress,
  } = props;
  return (
    <TextField
      id={id}
      className={"uiTxt " + (className ? className : "")}
      name={name}
      label={text}
      variant={variant}
      disabled={disabled}
      type={type || "text"}
      placeholder={placeholder}
      rows={rows}
      multiline={rows ? true : false}
      style={{ backgroundColor: disabled ? COLORS.DIM_GRAY : COLORS.WHITE }}
      inputProps={{ accept: accept }}
      autoFocus={autoFocus}
      autoComplete={"off"}
      defaultValue={defaultValue || ""}
      onChange={onChange}
      onKeyUp={onKeyPress}
    />
  );
};

export default TxtBox;
