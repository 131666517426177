import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import RadioBtn from "components/Custom/RadioBtn";
import TxtRow from "components/Custom/TxtRow";
import UiDialog from "components/Custom/UiDialog";
import AutoComplete from "components/Custom/AutoComplete";
import { COMMON_JS } from "common/scripts";
import { DEFAULTS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/lables";

import { CUSTOMER_APIs } from "apihandlers/customer";
import { USER_APIs } from "apihandlers/user";

const LeadApprovals = (props: any) => {
  const { setDashMenu } = props;

  const userTypeRef: any = useRef(null);
  const leadTypeRef: any = useRef(null);

  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [userTypes, setUserType] = useState<any>(null);
  const [leadTypes, setLeadTypes] = useState<any>(null);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      align: "center",
      headerAlign: "center",
      searchable: true,
      editable: false,
      sortable: true,
    },
    {
      key: "approvalLevel",
      name: LABELS.APPROVAL_LEVEL,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "approvalUserType",
      name: LABELS.USER_TYPE,
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "createdUser",
      name: LABELS.USER,
      width: "14%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "crmLeadTypeName",
      name: LABELS.CRM_LEAD_TYPE,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "orgName",
      name: LABELS.ORGANIZATION,
      width: "11%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "isApprovalMandatory",
      name: LABELS.APPROVAL_MANDATORY,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: true,
    },
    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: true,
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getUserType();
    }
  }, [loading]);

  const getUserType = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUserTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.label,
          };
        });
        setUserType(list);
        getCrmLeadType();
      }
    });
  };
  const getCrmLeadType = () => {
    const args = {
      activeStatus: true,
    };
    CUSTOMER_APIs.getCustomerTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setLeadTypes(list);
        getRows();
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await CUSTOMER_APIs.getCrmApprovals(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
          if (list[i]["isApprovalMandatory"]) {
            list[i]["isApprovalMandatory"] = "Yes";
          } else {
            list[i]["isApprovalMandatory"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getOrgProfiles:", error);
    }
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const mangeLeadApproval = () => {
    const approvalLevel = (
      document.getElementById("approvalLevel") as HTMLInputElement
    ).value.trim();
    const approvalUserTypeId = userTypeRef.current.selectedValue.id;
    const crmLeadType = leadTypeRef.current.selectedValue.id;
    let LeadApprovals = document.querySelector(
      'input[name="mandatoryStatus"]:checked'
    ) as HTMLInputElement;
    const isApprovalMandatory = LeadApprovals ? LeadApprovals.value : "";
    let ele = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = ele ? ele.value : "";

    // Validations
    let errors = [];
    if (approvalLevel == "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.APPROVAL_LEVEL +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!approvalUserTypeId) {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.USER_ROLE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!crmLeadType) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.CRM_LEAD_TYPE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }
    const args = {
      id: selectedRow.id || -1,
      approvalUserTypeId,
      isApprovalMandatory,
      approvalLevel,
      crmLeadType,
      activeStatus,
    };

    setIsSubmit(true);
    CUSTOMER_APIs.manageCrmApproval(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  console.log("Page: LeadApprovals:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.LEAD_APPROVALS}</li>
        </ul>
        <h4>{TITLES.LEAD_APPROVALS}</h4>
        <p>{SUB_TITLES.LEAD_APPROVALS}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.LEAD_APPROVALS}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                TITLES.LEAD_APPROVALS +
                ": " +
                selectedRow.approvalLevel
              : "Add " + TITLES.LEAD_APPROVALS
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexColumn">
            <div className="formGroup red">
              <TxtRow
                id={"approvalLevel"}
                label={LABELS.APPROVAL_LEVEL}
                defaultValue={selectedRow.id ? selectedRow.approvalLevel : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup required">
              <label>
                {LABELS.USER_TYPE} {"*"}
              </label>
              <AutoComplete
                list={userTypes}
                childRef={userTypeRef}
                defaultValue={
                  selectedRow.approvalUserTypeId
                    ? {
                        id: selectedRow.approvalUserTypeId,
                        label: selectedRow.approvalUserType,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup required">
              <label>
                {LABELS.CRM_LEAD_TYPE} {"*"}
              </label>
              <AutoComplete
                list={leadTypes}
                childRef={leadTypeRef}
                defaultValue={
                  selectedRow.crmLeadType
                    ? {
                        id: selectedRow.crmLeadType,
                        label: selectedRow.crmLeadTypeName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup">
              <label>{LABELS.APPROVAL_MANDATORY}</label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.YES,
                    checked: selectedRow.id
                      ? selectedRow.isApprovalMandatory === "Yes"
                      : true,
                    disabled: isSubmit,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.NO,
                    checked: selectedRow.id
                      ? selectedRow.isApprovalMandatory === "No"
                      : false,
                    disabled: isSubmit,
                  },
                ]}
                row={true}
                name={"mandatoryStatus"}
                disable={isSubmit}
              />
            </div>
            <div className="formGroup">
              <label>{LABELS.ACTIVE_STATUS}</label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "Yes"
                      : true,
                    disabled: isSubmit,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === "No"
                      : false,
                    disabled: isSubmit,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                disable={isSubmit}
              />
            </div>
            {/* mandatoryResults */}
            {mandatoryResults.length > 0 && (
              <div className="mandatoryBox">
                <ul>
                  {mandatoryResults.map((m) => {
                    return (
                      <li>
                        <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="formGroup submitBtnWrapper">
              <div className=" flexbtnjustify">
                <Btn
                  text={LABELS.CANCEL}
                  bgColor={COLORS.RELOAD_EXPORT}
                  color={COLORS.RELOAD_EXPORT_TEXT}
                  onClick={() => {
                    setManageDialog(false);
                    setMandatoryResults([]);
                  }}
                  loading={isSubmit}
                />
                <Btn
                  text={LABELS.SAVE}
                  onClick={mangeLeadApproval}
                  loading={isSubmit}
                  bgColor={COLORS.SAVE_GREEN}
                />
              </div>
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};
export default withSnackbar(LeadApprovals);
