import React, { useEffect, useState, useRef } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Edit as IconEdit,
  Visibility as IconEyeOn,
  VisibilityOff as IconEyeOff,
  Close as IconClose,
} from "@mui/icons-material";
import "../Driver/styles/users.css";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import Loader from "components/Custom/Loader";
import { NOTIFY, DEFAULTS, FORMATS, CONFIG, API_CONFIG } from "common/config";
import { COLORS } from "common/colors";
import {
  ERRORS,
  LABELS,
  SUB_TITLES,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/lables";

import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";
import { DateFunctions } from "common//datefunctions";
import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";

import TxtBox from "components/Custom/TxtBox";
import SkeletonBox from "components/Custom/SkeletonBox";
import { MASTER_APIs } from "apihandlers/master";
import { AUTH_APIs } from "apihandlers/auth";
import { USER_APIs } from "apihandlers/user";

const thumbnailImg = require("assets/images/pdf.png");

const Drivers = (props: any) => {
  const { setDashMenu, privileges, dmsMasters = "" } = props;

  const userNameRef: any = useRef(null);
  const driverRef: any = useRef();

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [driverFile, setDriverFile] = useState<any>([]);
  const [userNames, setUserNames] = useState<any>(null);

  const [uploading, setUploading] = useState<boolean>(false);

  const gridCols = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      frozen: true,
    },
    {
      key: "userName",
      name: LABELS.DRIVERS_NAME,
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      frozen: true,
    },
    {
      key: "status",
      name: LABELS.CURRENT_STATUS,
      width: "10%",
      editable: true,
      searchable: true,
      sortable: true,
      type: "dropdown",
      filters: [
        {
          id: "enroute",
          label: "Enroute",
        },
        {
          id: "holiday",
          label: "Holiday",
        },
        {
          id: "reported",
          label: "Reported",
        },
        {
          id: "notApplicable",
          label: "Not Applicable",
        },
      ],
      list: [
        {
          id: "all",
          label: "All",
        },
        {
          id: "enroute",
          label: "Enroute",
        },
        {
          id: "holiday",
          label: "Holiday",
        },
        {
          id: "reported",
          label: "Reported",
        },
        {
          id: "notApplicable",
          label: "Not Applicable",
        },
      ],
      align: "center",
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      type: "text",
      width: "5%",
      editable: false,
      sortable: true,
      align: "left",
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      key: "dlNumber",
      name: LABELS.DDL_NUMBER,
      type: "text",
      width: "13%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "dateOfBirth",
      name: LABELS.DATE_OF_BIRTH,
      type: "date",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "expiryDate",
      name: LABELS.DL_VALID_UPTO,
      type: "date",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "mobileNumber",
      name: LABELS.MOBILE_NO,
      type: "number",
      width: "12%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "mobileCug",
      name: LABELS.CUG,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "activeStatus",
      name: LABELS.ACTIVE,
      width: "10%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "issuingAuthority",
      name: LABELS.DL_ISSUING_AUTHORITY,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "dateOfIssue",
      name: LABELS.DL_ISSUED_ON,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "swdOf",
      name: LABELS.SWD_OF,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "vehicleType",
      name: LABELS.VEHICLE_TYPE,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "mobileNumber2",
      name: LABELS.MOBILE_ALTERNATIVE,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "createdOn",
      name: LABELS.CREATED_ON,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "modifiedOn",
      name: LABELS.MODIFIED_ON,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "bankName",
      name: LABELS.BANK_NAME,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "accountNumber",
      name: LABELS.ACCOUNT_NUMBER,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "ifscCode",
      name: LABELS.IFSC_CODE,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "beneficiaryName",
      name: LABELS.BENEFICIARY_NAME,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "aadharNumber",
      name: LABELS.AADHAR_NUMBER,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "panNumber",
      name: LABELS.PAN_NUMBER,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "uanNumber",
      name: LABELS.UAN_NUMBER,
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "trDueDate",
      name: LABELS.TR_DUE_DATE,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "ntDueDate",
      name: LABELS.NT_DUE_DATE,
      type: "date",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "permanentAdd",
      name: LABELS.PERMANENT_ADDRESS,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "tempAdd",
      name: LABELS.TEMPERORY_ADDRESS,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "driverType",
      name: LABELS.DRIVER_TYPE,
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
  ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      // setFuelFiles(null);
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        // setFuelFiles([res]);
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "fuel",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        setDriverFile([...driverFile, fileInfo]);
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      driver: true,
    };
    MASTER_APIs.getDrivers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatus"] = "Yes";
          } else {
            list[i]["activeStatus"] = "No";
          }
          const driver = list.find((r: any) => r.id === list[i].id);
          console.log("driver: ", i, driver, list[i], list);
          list[i].status = driver ? driver.status : null;
        }
        setRows(list);
        setLoading(false);
        getUsers();
      }
    });
  };

  const getUsers = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setUserNames(list);
      }
    });
  };

  const manageRecord = () => {
    // const savingnameRef = (
    //   document.getElementById("driverName") as HTMLInputElement
    // ).value.trim();
    const savingfirstNameRef = (
      document.getElementById("firstName") as HTMLInputElement
    ).value.trim();
    const savinglastNameRef = (
      document.getElementById("lastName") as HTMLInputElement
    ).value.trim();
    const userId = userNameRef.current
      ? userNameRef.current.selectedValue.id
      : "";
    let ele = document.querySelector(
      'input[name="driverType"]:checked'
    ) as HTMLInputElement;
    const driverType = ele ? ele.value : "";
    const savingdlNumberRef = (
      document.getElementById("dlNumber") as HTMLInputElement
    ).value.trim();
    const savingdlIssuerRef = (
      document.getElementById("dlIssusingAuthority") as HTMLInputElement
    ).value.trim();
    const savingswdOfRef = (
      document.getElementById("swdOf") as HTMLInputElement
    ).value.trim();
    const savingvehicleTypeRef = (
      document.getElementById("vehicleTypes") as HTMLInputElement
    ).value.trim();
    const savingmobileNumberRef = (
      document.getElementById("mobileNumber") as HTMLInputElement
    ).value.trim();
    const savingmobileNumber2Ref = (
      document.getElementById("mobileAlt") as HTMLInputElement
    ).value.trim();
    const savingemployeeCodeRef = (
      document.getElementById("employeeCode") as HTMLInputElement
    ).value.trim();
    const savingmobileCugRef = (
      document.getElementById("mobileCug") as HTMLInputElement
    ).value.trim();
    const savingbeneficiaryNameRef = (
      document.getElementById("beneficiaryName") as HTMLInputElement
    ).value.trim();
    const savingaccountNumberRef = (
      document.getElementById("accountNumber") as HTMLInputElement
    ).value.trim();
    const savingbankNameRef = (
      document.getElementById("bankName") as HTMLInputElement
    ).value.trim();
    const savingbankBranchRef = (
      document.getElementById("branch") as HTMLInputElement
    ).value.trim();
    const savingifscCodeRef = (
      document.getElementById("ifscCode") as HTMLInputElement
    ).value.trim();
    const savingaadharNumberRef = (
      document.getElementById("aadharNumber") as HTMLInputElement
    ).value.trim();
    const savingpanNumberRef = (
      document.getElementById("panNumber") as HTMLInputElement
    ).value.trim();
    const savinguanNumberRef = (
      document.getElementById("uan") as HTMLInputElement
    ).value.trim();
    const savingbloodGroupRef = (
      document.getElementById("bloodGroup") as HTMLInputElement
    ).value.trim();
    const savingbloodRelativeRef = (
      document.getElementById("relative") as HTMLInputElement
    ).value.trim();
    const savingbloodRelationRef = (
      document.getElementById("relation") as HTMLInputElement
    ).value.trim();
    const emergencyNumber = (
      document.getElementById("emergencyNumber") as HTMLInputElement
    ).value.trim();
    const savingtempAddRef = (
      document.getElementById("temporaryAddress") as HTMLInputElement
    ).value.trim();
    const savingpermanentAddRef = (
      document.getElementById("permanentAddress") as HTMLInputElement
    ).value.trim();
    const dlIssuedOn = (
      document.getElementById("dlIssuedOn") as HTMLInputElement
    ).value.trim();
    const dlValidUpto = (
      document.getElementById("dlValidUpto") as HTMLInputElement
    ).value.trim();
    const dateOfBirth = (
      document.getElementById("dateOfBirth") as HTMLInputElement
    ).value.trim();
    const joiningDate = (
      document.getElementById("joiningDate") as HTMLInputElement
    ).value.trim();
    const trDueDate = (
      document.getElementById("trDueDate") as HTMLInputElement
    ).value.trim();
    const ntDueDate = (
      document.getElementById("ntDueDate") as HTMLInputElement
    ).value.trim();
    ele = document.querySelector(
      'input[name="driverActiveStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = ele ? ele.value : "";

    //
    const emailId = (
      document.getElementById("emailId") as HTMLInputElement
    ).value.trim();
    const password = (
      document.getElementById("password") as HTMLInputElement
    ).value.trim();
    const redeemedkey = (
      document.getElementById("redeemedkey") as HTMLInputElement
    ).value.trim();
    const balance = (
      document.getElementById("balance") as HTMLInputElement
    ).value.trim();

    const current = document.querySelector(
      'input[name="status"]:checked'
    ) as HTMLInputElement;
    const currentStatus = current ? current.value : "";

    const args = {
      id: selectedRow.id || -1,
      userId,
      // driverName: savingnameRef,
      firstName: savingfirstNameRef,
      lastName: savinglastNameRef,
      dlNumber: savingdlNumberRef,
      issuingAuthority: savingdlIssuerRef,
      swdOf: savingswdOfRef,
      vehicleType: savingvehicleTypeRef,
      mobileNumber: savingmobileNumberRef,
      mobileNumber2: savingmobileNumber2Ref,
      employeeCode: savingemployeeCodeRef,
      mobileCug: savingmobileCugRef,
      bankName: savingbankNameRef,
      accountNumber: savingaccountNumberRef,
      ifscCode: savingifscCodeRef,
      aadharNumber: savingaadharNumberRef,
      panNumber: savingpanNumberRef,
      uanNumber: savinguanNumberRef,
      bloodGroup: savingbloodGroupRef,
      bloodRelative: savingbloodRelativeRef,
      bloodRelation: savingbloodRelationRef,
      permanentAdd: savingpermanentAddRef,
      tempAdd: savingtempAddRef,
      beneficiaryName: savingbeneficiaryNameRef,
      bankBranch: savingbankBranchRef,
      activeStatus: selectBtnValue,
      dateOfIssue: dlIssuedOn,
      expiryDate: dlValidUpto,
      dateOfBirth: dateOfBirth,
      joiningDate: joiningDate,
      trDueDate: trDueDate,
      ntDueDate: ntDueDate,
      password,
      homeContact: emergencyNumber,
      driverType: driverType,
      //
      emailId,
      redeemedkey,
      // driverFile: driverFile.length > 0 ? driverFile[0].id : -1,
      balance: balance,
      status: currentStatus,
    };

    console.log("args", args);
    // Validations
    const fileArgs = {
      driverId: selectedRow.id || -1,
      files: driverFile,
    };
    let errors = [];

    const regMobile = /^[1-9]\d{9}$/;

    const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    const regAdhar = /^[2-9]\d{11}$/;

    // if (!userId) {
    //   errors.push({
    //     id: "driverName",
    //     msg:
    //       "<span class='bold'>" +
    //       LABELS.USER_NAME +
    //       "</span> " +
    //       WARNINGS.IS_A_REQUIRED_FIELD,
    //   });
    // }
    if (savingfirstNameRef.length < 3) {
      errors.push({
        id: "driverName",
        msg:
          "<span class='bold'>" +
          LABELS.FIRST_NAME +
          "</span> " +
          WARNINGS.MIN_CHAR_REQUIRED_FIELD,
      });
    }
    if (savingfirstNameRef === "") {
      errors.push({
        id: "driverName",
        msg:
          "<span class='bold'>" +
          LABELS.FIRST_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (password.length < 4) {
      errors.push({
        id: "password",
        msg:
          "<span class='bold'>" +
          LABELS.PASSWORD +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (savingdlNumberRef === "") {
      errors.push({
        id: "dlNumber",
        msg:
          "<span class='bold'>" +
          LABELS.DL_NUMBER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (savingdlIssuerRef === "") {
      errors.push({
        id: "dlIssusingAuthority",
        msg:
          "<span class='bold'>" +
          LABELS.DL_ISSUING_AUTHORITY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (savingswdOfRef === "") {
      errors.push({
        id: "swdOf",
        msg:
          "<span class='bold'>" +
          LABELS.SWD_OF +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (savingvehicleTypeRef === "") {
      errors.push({
        id: "vehicleTypes",
        msg:
          "<span class='bold'>" +
          LABELS.VEHICLE_TYPES +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (dlIssuedOn === "") {
      errors.push({
        id: "dlIssuedOn",
        msg:
          "<span class='bold'>" +
          LABELS.DL_ISSUED_ON +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (dlValidUpto === "") {
      errors.push({
        id: "dlValidUpto",
        msg:
          "<span class='bold'>" +
          LABELS.DL_VALID_UPTO +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (savingmobileNumberRef === "") {
      errors.push({
        id: "mobileNumber",
        msg:
          "<span class='bold'>" +
          LABELS.MOBILE_NO +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    if (savingmobileNumberRef) {
      if (!savingmobileNumberRef.match(regMobile)) {
        errors.push({
          id: "savingmobileNumberRef",
          msg:
            "Please Enter Valid 10 Digit" +
            "<span class='bold'>  Mobile Number</span>",
        });
      }
    }
    if (savingmobileNumber2Ref) {
      if (!savingmobileNumber2Ref.match(regMobile)) {
        errors.push({
          id: "savingmobileNumber2Ref",
          msg:
            "Please Enter Valid 10 Digit" +
            "<span class='bold'> Mobile(alternative)</span>",
        });
      }
    }

    if (savingaadharNumberRef) {
      if (!savingaadharNumberRef.match(regAdhar)) {
        errors.push({
          id: "savingaadharNumberRef",
          msg:
            "Please Enter Valid 12 Digit" +
            "<span class='bold'> Aadhar-UID Number</span>",
        });
      }
    }

    if (savingpanNumberRef) {
      if (!savingpanNumberRef.match(regpan)) {
        errors.push({
          id: "savingpanNumberRef",
          msg: "Please Enter Valid PAN Number (ABCDE1234F)",
        });
      }
    }

    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    setIsSubmit(true);
    MASTER_APIs.manageDriver(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });

    MASTER_APIs.manageDriverFiles(fileArgs).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        // COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        // COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const onManageDriver = async () => {
    try {
      const rows = driverRef.getRowData();
      const args = { drivers: rows };
      console.log("args:", args);
      const res = await MASTER_APIs.manageDriver(args);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    } catch (error) {
      console.error("manageDriver: ", error);
    }
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    setDriverFile([]);
    //todo: after API changes

    getUploadedFiles(row.id);
    // setDriverFile([
    //   {
    //     id: row.driverFile,
    //     path: row.path,
    //     mimeType: row.mimeType,
    //   },
    // ]);
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
    setDriverFile([]);
  };

  const getUploadedFiles = (driverId) => {
    const args = {
      driverId,
    };
    setUploading(true);
    setDriverFile([]);
    MASTER_APIs.getDriverFileInfo(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        setDriverFile(list);
        setUploading(false);
      }
      console.log("Driver Files2", driverFile);
    });
  };
  // console.log("userInfo", userInfo);
  // let menuProducts = true;

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>

          {dmsMasters !== LABELS.DMS_MASTER && (
            <>
              <li
                onClick={() => {
                  setDashMenu(LABELS.HOME);
                }}
              >
                {LABELS.MASTERS}
              </li>
              <li className="dim">{" / "}</li>
            </>
          )}

          <li className="active">{TITLES.DRIVERS}</li>
        </ul>
        <h4>{TITLES.DRIVERS}</h4>
        <p className="SubTitles">{SUB_TITLES.DRIVERS}</p>
      </div>

      {rows ? (
        <>
          <div className="grid excelGridBtnWrapper">
            <ExcelGrid
              id={LABELS.DRIVERS}
              cols={gridCols}
              rows={rows}
              childRef={driverRef}
              exportOption={true}
              newRowOption={false}
              exportName={LABELS.DRIVERS}
              onReload={reloadGrid}
              onAdd={onAdd}
              height={window.innerHeight * 0.7}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              width: "96.5%",
            }}
          >
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={onManageDriver}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flexRowCenter">
          <Loader />
        </div>
      )}

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + TITLES.DRIVERS + " " + selectedRow.userName
              : "Add " + TITLES.DRIVERS
          }
          open={true}
          size={"lg"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexColumn">
            <fieldset className="formSection">
              <legend className="formSectionLegend">
                {LABELS.DRIVER_DETAILS}
              </legend>
              <div className="flexRowJustify">
                <div className="formGroup width30 red">
                  <TxtRow
                    id={"firstName"}
                    label={LABELS.FIRST_NAME}
                    required={true}
                    defaultValue={selectedRow.firstName}
                    disabled={isSubmit}
                  />
                </div>
                {/* <div className="formGroup width30 red">
                  <TxtRow
                    id={"driverName"}
                    label={LABELS.DRIVERS_NAME}
                    required={true}
                    defaultValue={selectedRow.id ? selectedRow.userName : null}
                    disabled={isSubmit}
                  />
                </div> */}
                <div className="formGroup width30">
                  <TxtRow
                    id={"lastName"}
                    label={LABELS.LAST_NAME}
                    defaultValue={selectedRow.lastName}
                    disabled={isSubmit}
                  />
                </div>
                {selectedRow.id ? (
                  <div className="formGroup width30 red">
                    <label>{LABELS.USER_NAME}</label>
                    <span className="required">{" *"}</span>
                    <AutoComplete
                      list={userNames}
                      childRef={userNameRef}
                      defaultValue={
                        selectedRow.userName
                          ? {
                              id: selectedRow.userId,
                              label: selectedRow.userName,
                            }
                          : null
                      }
                      onChange={(e, v) => {}}
                      disabled={isSubmit}
                    />
                  </div>
                ) : (
                  <div className="formGroup width30"></div>
                )}

                {/* <div className="formGroup width30">
                  <TxtRow
                    id={"firstName"}
                    label={LABELS.FIRST_NAME}
                    defaultValue={selectedRow.firstName}
                    disabled={isSubmit}
                  />
                </div> */}
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"emailId"}
                    label={LABELS.EMAIL_ID}
                    defaultValue={selectedRow.emailId}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30 required">
                  <TxtRow
                    id={"password"}
                    label={LABELS.PASSWORD}
                    defaultValue={selectedRow.password}
                    disabled={isSubmit}
                    type={showPassword ? "text " : "password"}
                    required={true}
                  />
                  <IconButton
                    className="ViewPassword"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <IconEyeOn /> : <IconEyeOff />}
                  </IconButton>
                </div>

                <div className="formGroup width30">
                  <label>{LABELS.DRIVER_TYPE}</label>
                  <RadioBtn
                    list={[
                      {
                        id: "driver",
                        value: "Driver",
                        label: LABELS.DRIVER,
                        checked: selectedRow.id
                          ? selectedRow.driverType === "Driver"
                          : true,
                        disabled: false,
                      },
                      {
                        id: "helper",
                        value: "Helper",
                        label: LABELS.HELPER,
                        checked: selectedRow.id
                          ? selectedRow.driverType === "Helper"
                          : false,
                        disabled: false,
                      },
                      {
                        id: "cleaner",
                        value: "Cleaner",
                        label: LABELS.CLEANER,
                        checked: selectedRow.id
                          ? selectedRow.driverType === "Cleaner"
                          : false,
                        disabled: false,
                      },
                    ]}
                    name={"driverType"}
                    row={true}
                    disabled={isSubmit}
                  />
                </div>
              </div>
            </fieldset>

            <fieldset className="formSection">
              <legend className="formSectionLegend">
                {LABELS.DRIVER_VEHICLE_DETAILS}
              </legend>
              <div className="flexRowJustify">
                <div className="formGroup width30 red">
                  <TxtRow
                    id={"dlNumber"}
                    label={LABELS.DL_NUMBER}
                    defaultValue={selectedRow.dlNumber}
                    required={true}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30 red">
                  <TxtRow
                    id={"dlIssusingAuthority"}
                    label={LABELS.DL_ISSUING_AUTHORITY}
                    defaultValue={selectedRow.issuingAuthority}
                    required={true}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30 red">
                  <TxtRow
                    id={"swdOf"}
                    label={LABELS.SWD_OF}
                    defaultValue={selectedRow.swdOf}
                    required={true}
                    disabled={isSubmit}
                  />
                </div>
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30 red">
                  <TxtRow
                    id={"vehicleTypes"}
                    label={LABELS.VEHICLE_TYPES}
                    defaultValue={selectedRow.vehicleType}
                    required={true}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30 red">
                  <TxtRow
                    id={"dlIssuedOn"}
                    label={LABELS.DL_ISSUED_ON}
                    defaultValue={
                      selectedRow.dateOfIssue
                        ? DateFunctions.getFormatedDate(
                            selectedRow.dateOfIssue,
                            FORMATS.SQL_DATE,
                            FORMATS.SQL_DATE_ONLY
                          )
                        : DateFunctions.getDate(
                            "today",
                            "from",
                            FORMATS.SQL_DATE_ONLY
                          )
                    }
                    type={"date"}
                    required={true}
                    disabled={isSubmit}
                  />
                </div>

                <div className="formGroup width30 red">
                  <TxtRow
                    id={"dlValidUpto"}
                    label={LABELS.DL_VALID_UPTO}
                    defaultValue={
                      selectedRow.expiryDate
                        ? DateFunctions.getFormatedDate(
                            selectedRow.expiryDate,
                            FORMATS.SQL_DATE,
                            FORMATS.SQL_DATE_ONLY
                          )
                        : DateFunctions.getDate(
                            "today",
                            "from",
                            FORMATS.SQL_DATE_ONLY
                          )
                    }
                    type={"date"}
                    required={true}
                    disabled={isSubmit}
                  />
                </div>
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"trDueDate"}
                    label={LABELS.TR_DUE_DATE}
                    defaultValue={
                      selectedRow.trDueDate
                        ? DateFunctions.getFormatedDate(
                            selectedRow.trDueDate,
                            FORMATS.SQL_DATE,
                            FORMATS.SQL_DATE_ONLY
                          )
                        : DateFunctions.getDate(
                            "today",
                            "from",
                            FORMATS.SQL_DATE_ONLY
                          )
                    }
                    type={"date"}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"ntDueDate"}
                    label={LABELS.NT_DUE_DATE}
                    defaultValue={
                      selectedRow.ntDueDate
                        ? DateFunctions.getFormatedDate(
                            selectedRow.ntDueDate,
                            FORMATS.SQL_DATE,
                            FORMATS.SQL_DATE_ONLY
                          )
                        : DateFunctions.getDate(
                            "today",
                            "from",
                            FORMATS.SQL_DATE_ONLY
                          )
                    }
                    type={"date"}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30"></div>
              </div>
            </fieldset>

            <fieldset className="formSection">
              <legend className="formSectionLegend">
                {LABELS.EMPLOYEE_DETAILS}
              </legend>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"employeeCode"}
                    label={LABELS.EMPLOYEE_CODE}
                    defaultValue={selectedRow.employeeCode}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30 red">
                  <TxtRow
                    id={"mobileNumber"}
                    label={LABELS.MOBILE_NO}
                    defaultValue={selectedRow.mobileNumber}
                    required={true}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"mobileAlt"}
                    label={LABELS.MOBILE_ALTERNATIVE}
                    defaultValue={selectedRow.mobileNumber2}
                    disabled={isSubmit}
                  />
                </div>
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"mobileCug"}
                    label={LABELS.MOBILE_CUG}
                    defaultValue={selectedRow.mobileCug}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"aadharNumber"}
                    label={LABELS.AADHAR_NUMBER}
                    defaultValue={selectedRow.aadharNumber}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"panNumber"}
                    label={LABELS.PAN_NUMBER}
                    defaultValue={selectedRow.panNumber}
                    disabled={isSubmit}
                  />
                </div>
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"uan"}
                    label={LABELS.UAN_NUMBER}
                    defaultValue={selectedRow.uanNumber}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"temporaryAddress"}
                    label={LABELS.TEMPERORY_ADDRESS}
                    defaultValue={selectedRow.tempAdd}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"permanentAddress"}
                    label={LABELS.PERMANENT_ADDRESS}
                    defaultValue={selectedRow.permanentAdd}
                    disabled={isSubmit}
                  />
                </div>
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"dateOfBirth"}
                    label={LABELS.DATE_OF_BIRTH}
                    defaultValue={
                      selectedRow.dateOfBirth
                        ? DateFunctions.getFormatedDate(
                            selectedRow.dateOfBirth,
                            FORMATS.SQL_DATE,
                            FORMATS.SQL_DATE_ONLY
                          )
                        : "2005-08-01"
                    }
                    type={"date"}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"joiningDate"}
                    label={LABELS.JOINING_DATE}
                    defaultValue={
                      selectedRow.joiningDate
                        ? DateFunctions.getFormatedDate(
                            selectedRow.joiningDate,
                            FORMATS.SQL_DATE,
                            FORMATS.SQL_DATE_ONLY
                          )
                        : DateFunctions.getDate(
                            "today",
                            "from",
                            FORMATS.SQL_DATE_ONLY
                          )
                    }
                    type={"date"}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30"></div>
              </div>
            </fieldset>
            <fieldset className="formSection">
              <legend className="formSectionLegend">
                {LABELS.OTHER_DETAILS}
              </legend>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"bloodGroup"}
                    label={LABELS.BLOOD_GROUP}
                    defaultValue={selectedRow.bloodGroup}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"relative"}
                    label={LABELS.RELATIVE}
                    defaultValue={selectedRow.bloodRelative}
                    disabled={isSubmit}
                  />
                </div>

                <div className="formGroup width30">
                  <TxtRow
                    id={"relation"}
                    label={LABELS.RELATION}
                    defaultValue={selectedRow.bloodRelation}
                    disabled={isSubmit}
                  />
                </div>
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"emergencyNumber"}
                    label={LABELS.EMERGENCY_NUMBER}
                    defaultValue={selectedRow.homeContact}
                    disabled={isSubmit}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="formSection">
              <legend className="formSectionLegend">
                {LABELS.BANK_DETAILS}
              </legend>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"accountNumber"}
                    label={LABELS.ACCOUNT_NUMBER}
                    defaultValue={selectedRow.accountNumber}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"bankName"}
                    label={LABELS.BANK_NAME}
                    defaultValue={selectedRow.bankName}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"branch"}
                    label={LABELS.BRANCH}
                    defaultValue={selectedRow.bankBranch}
                    disabled={isSubmit}
                  />
                </div>
              </div>
              <div className="flexRowJustify">
                <div className="formGroup width30">
                  <TxtRow
                    id={"ifscCode"}
                    label={LABELS.IFSC_CODE}
                    defaultValue={selectedRow.ifscCode}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"beneficiaryName"}
                    label={LABELS.BENEFICIARY_NAME}
                    defaultValue={selectedRow.beneficiaryName}
                    disabled={isSubmit}
                  />
                </div>
                <div className="formGroup width30"></div>
              </div>
            </fieldset>

            <div className="flexRowJustify">
              <div className="formGroup width30">
                <TxtRow
                  id={"redeemedkey"}
                  label={LABELS.REDEEMED_KEY}
                  defaultValue={selectedRow.redeemedkey}
                  disabled={isSubmit}
                />
              </div>
              <div className="formGroup width30">
                <label>
                  {LABELS.STATUS}
                  {/* {<span className="red">*</span>} */}
                </label>

                <RadioBtn
                  list={[
                    {
                      id: "enroute",
                      value: "Enroute",
                      label: "Enroute",
                      checked: selectedRow.id
                        ? selectedRow.status === "Enroute"
                        : true,
                      disabled: false,
                    },
                    {
                      id: "holiday",
                      value: "Holiday",
                      label: "Holiday",
                      checked: selectedRow.id
                        ? selectedRow.status === "Holiday"
                        : false,
                      disabled: false,
                    },
                    {
                      id: "reported",
                      value: "Reported",
                      label: "Reported",
                      checked: selectedRow.id
                        ? selectedRow.status === "Reported"
                        : false,
                      disabled: false,
                    },
                    {
                      id: "notApplicable",
                      value: "Not Applicable",
                      label: "Not Applicable",
                      checked: selectedRow.id
                        ? selectedRow.status === "Not Applicable"
                        : false,
                      disabled: false,
                    },
                  ]}
                  row={true}
                  name={"status"}
                  disabled={isSubmit}
                />
              </div>
              <div className="formGroup width30">
                <TxtRow
                  id={"balance"}
                  type={"number"}
                  label={LABELS.BALANCE}
                  defaultValue={selectedRow.balance}
                  disabled={isSubmit}
                />
              </div>
            </div>

            <div className="flexRowJustify">
              <div className="formGroup width30 red">
                <label>
                  {LABELS.ACTIVE_STATUS} {<span className="red">*</span>}
                </label>

                <RadioBtn
                  list={[
                    {
                      id: "true",
                      value: "true",
                      label: LABELS.ACTIVE,
                      checked: selectedRow.id
                        ? selectedRow.activeStatus === true
                        : true,
                      disabled: false,
                    },
                    {
                      id: "false",
                      value: "false",
                      label: LABELS.INACTIVE,
                      checked: selectedRow.id
                        ? selectedRow.activeStatus === false
                        : false,
                      disabled: false,
                    },
                  ]}
                  row={true}
                  name={"driverActiveStatus"}
                  disabled={isSubmit}
                />
              </div>
            </div>

            <div className="formGroup">
              <label>
                {LABELS.FILE_UPLOAD}
                <small>
                  {" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}
                </small>
              </label>
              <TxtBox
                type={"file"}
                id="uploadFile"
                // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
                // className="uploadFile"
                accept="image/*,.pdf"
                onChange={onUploadFile}
              />
              <div
                className="formGroup"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {driverFile.map((u, i) => {
                  return (
                    <div className="thumbnailBox width20">
                      <IconButton
                        className="thumbnailBoxIcon"
                        onClick={() => {
                          const list = driverFile.filter((a, j) => j !== i);
                          setDriverFile(list);
                        }}
                      >
                        <IconClose />
                      </IconButton>
                      <img
                        onClick={() =>
                          window.open(API_CONFIG.API_URL + u.path, "_blank")
                        }
                        src={
                          u.mimeType === "pdf"
                            ? thumbnailImg
                            : API_CONFIG.STATIC_URL + u.path
                        }
                        className="responsive cursor"
                      />
                    </div>
                  );
                })}
                {uploading && (
                  <div className="thumbnailBox width20">
                    <SkeletonBox height={300} />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
                // loading={isSubmit}
              />
              <Btn
                text={LABELS.SAVE}
                onClick={manageRecord}
                loading={isSubmit}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Drivers);
