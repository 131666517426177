/**
 * Edit Lead Profile
 */

import React, { useState } from "react";
import { Box, Stepper, Step, StepButton, StepLabel } from "@mui/material";

import NewLeadProfile from "../NewLeadProfile";
import StoreVisit from "../StoreVisit";
import DocVerification from "../DocVerification";
import LeadApprovals from "../LeadApprovals";
import Qualified from "../Qualified";
import Converted from "../Converted";
import Verified from "../Verified";
import AssignedInfo from "../AssignedInfo";
import PreVerification from "../PreVerification";
import LegalDocuments from "../LegalDocuments";
import InterviewProcess from "../InterviewProcess";

import { CRM_LEAD_STATUS } from "common/config";

interface defaultProps {
  selectedRow;
}

const EditLeadProfile = (props: any) => {
  const { selectedRow, areas, onClose } = props;
  let steps = [
    CRM_LEAD_STATUS.NEW_LEAD,
    CRM_LEAD_STATUS.PRE_VERIFICATION,
    CRM_LEAD_STATUS.VERIFIED,
    CRM_LEAD_STATUS.ASSIGNED,
    CRM_LEAD_STATUS.FIELD_VISIT,
    CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
    CRM_LEAD_STATUS.INTERVIEW_PROCESS,
    CRM_LEAD_STATUS.APPROVALS,
    CRM_LEAD_STATUS.QUALIFIED,
    CRM_LEAD_STATUS.LEGAL_DOCUMENTS,
    CRM_LEAD_STATUS.CONVERTED,
  ];

  let nextStep = 0;
  if (selectedRow.crmLeadStatus === CRM_LEAD_STATUS.NEW_LEAD) {
    steps = [CRM_LEAD_STATUS.NEW_LEAD, CRM_LEAD_STATUS.PRE_VERIFICATION];
    nextStep = steps.indexOf(selectedRow.crmLeadStatus) + 1;
  } else {
    nextStep = steps.indexOf(selectedRow.crmLeadStatus) + 1;
    if (nextStep >= steps.length) {
      nextStep = steps.length - 1;
    }
  }

  const [activeStep, setActiveStep] = useState(nextStep);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    // setTabs("TAB " + { step });

    if (step <= steps.indexOf(selectedRow.crmLeadStatus)) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
    setActiveStep(step);
    console.log("index checked clicked ", step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  console.log("areas", areas);
  console.log("edit: ", selectedRow, activeStep);

  const companyDetails = selectedRow.companyDetails
    ? JSON.parse(selectedRow.companyDetails)
    : {};
  const currentDetails = selectedRow.currentDetails
    ? JSON.parse(selectedRow.currentDetails)
    : {};
  const personalDetails = selectedRow.personalDetails
    ? JSON.parse(selectedRow.personalDetails)
    : {};
  const partnerDetails = selectedRow.partnerDetails
    ? JSON.parse(selectedRow.partnerDetails)
    : {};
  const storageDetails = selectedRow.storageDetails
    ? JSON.parse(selectedRow.storageDetails)
    : {};
  const familyData = selectedRow.familyDetails
    ? JSON.parse(selectedRow.familyDetails)
    : {};
  const vehicleDetails = selectedRow.vehicleDetails
    ? JSON.parse(selectedRow.vehicleDetails)
    : {};

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={index <= steps.indexOf(selectedRow.crmLeadStatus)}
            >
              {index <= steps.indexOf(selectedRow.crmLeadStatus) + 1 ? (
                <StepButton
                  color="inherit"
                  onClick={handleStep(index)}
                  className="stepperBtn"
                >
                  {label}
                </StepButton>
              ) : (
                <StepLabel>{label}</StepLabel>
              )}
            </Step>
          ))}
        </Stepper>
        <div style={{ marginTop: 20 }}>
          {activeStep === steps.indexOf(CRM_LEAD_STATUS.NEW_LEAD) && (
            <NewLeadProfile
              selectedRow={selectedRow}
              areas={areas}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.PRE_VERIFICATION) && (
            <PreVerification
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.VERIFIED) && (
            <Verified
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.ASSIGNED) && (
            <AssignedInfo
              selectedRow={selectedRow}
              onClose={onClose}
              areas={areas}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.FIELD_VISIT) && (
            <StoreVisit
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep ===
            steps.indexOf(CRM_LEAD_STATUS.DOCUMENT_VERIFICATION) && (
            <DocVerification
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.INTERVIEW_PROCESS) && (
            <InterviewProcess
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.APPROVALS) && (
            <LeadApprovals
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.QUALIFIED) && (
            <Qualified
              selectedRow={selectedRow}
              areas={areas}
              onClose={onClose}
              companyDetails={companyDetails}
              currentDetails={currentDetails}
              personalDetails={personalDetails}
              partnerDetails={partnerDetails}
              storageDetails={storageDetails}
              isButtonDisabled={isButtonDisabled}
              familyData={familyData}
              vehicleDetails={vehicleDetails}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.LEGAL_DOCUMENTS) && (
            <LegalDocuments
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {activeStep === steps.indexOf(CRM_LEAD_STATUS.CONVERTED) && (
            <Converted
              selectedRow={selectedRow}
              onClose={onClose}
              isButtonDisabled={isButtonDisabled}
            />
          )}
        </div>
      </Box>
    </>
  );
};

export default EditLeadProfile;
