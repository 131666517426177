import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";

import { API_CONFIG, FORMATS, NOTIFY } from "common/config";
import { LABELS, WARNINGS, TITLES, INFO, SUB_TITLES } from "common/lables";

import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { MASTER_APIs } from "apihandlers/master";
import { REPORTS_APIs } from "apihandlers/reports";

const AttendanceReport = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [masters, setMasters] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "name",
      name: LABELS.NAME,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   key: "routeName",
    //   name: LABELS.ROUTE_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "dealerName",
    //   name: LABELS.DEALER_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      //key: "startTime",
      key: "2",
      name: LABELS.START_TIME,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      // key: "startLocation",
      key: "loc",
      name: LABELS.START_LOCATION,
      width: "15%",
      type: "html",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      //key: "startPhoto",
      key: "files",
      name: LABELS.START_PHOTO,
      width: "15%",
      type: "html",
      editable: true,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "endTime",
      name: LABELS.END_TIME,
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "endLocation",
      name: LABELS.END_LOCATION,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "endPhoto",
      name: LABELS.END_PHOTO,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   key: "areaName",
    //   name: LABELS.AREA_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "cityName",
    //   name: LABELS.CITY_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "stateName",
    //   name: LABELS.STATE_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },

    // {
    //   key: "reportingManager",
    //   name: LABELS.REPORTING_MANAGER,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
  ];

  useEffect(() => {
    if (loading) {
      getOrgProfile();
    }
  }, [loading]);

  const getOrgProfile = () => {
    const args = {};
    MASTER_APIs.getOrgProfiles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list: any = [{ id: -1, label: "All" }];
        let recs: any = response.records;
        for (let i = 0; i < recs.length; i++) {
          list.push({
            id: recs[i].id,
            label: recs[i].organizationName,
          });
        }
        setMasters(list);
        setLoading(false);
      }
    });
  };

  const loadAttendenceReport = () => {
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const toDt = (document.getElementById("toDt") as HTMLInputElement).value;
    const args = {
      inTime: fromDt,
      outTime: toDt,
      reportType: "excel",
      isMobile: false,
    };
    let errors = [];
    if (fromDt == "") {
      errors.push({
        id: "fromDt",
        msg:
          "<span class='bold'>" +
          LABELS.FROM_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (toDt == "") {
      errors.push({
        id: "toDt",
        msg:
          "<span class='bold'>" +
          LABELS.TO_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }
    setRows(null);
    REPORTS_APIs.getAttendanceReport(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.rows;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          let fileUrl = API_CONFIG.STATIC_URL + list[i][5];
          list[i].files = (
            <div>
              <img
                src={fileUrl}
                alt=""
                width={100}
                height={50}
                onClick={() => {
                  window.open(fileUrl, "_blank");
                }}
              />
            </div>
          );
          list[i].loc = (
            <div>
              <a
                className="textShadowBlack"
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  list[i][3] +
                  "," +
                  list[i][4]
                }
                target="_new"
              >
                {LABELS.VIEW_START_LOCATION}
              </a>
            </div>
          );
        }
        setRows(response.rows);
      }
    });
  };

  const outletRef: any = useRef();

  console.log("Page: Attendance Report:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {TITLES.REPORTS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.ATTENDANCE_REPORT}</li>
        </ul>
        <h4>{TITLES.ATTENDANCE_REPORT}</h4>
        <p>{SUB_TITLES.ATTENDANCE_REPORT}</p>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 0,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.FROM_DATE}
                </label>
                <TxtBox
                  type={"datetime-local"}
                  id="fromDt"
                  defaultValue={DateFunctions.getDate(
                    "now",
                    "from",
                    FORMATS.SQL_DATE
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.TO_DATE}
                </label>
                <TxtBox
                  type={"datetime-local"}
                  id="toDt"
                  defaultValue={DateFunctions.getDate(
                    "thismonth",
                    "to",
                    FORMATS.SQL_DATE
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn text={LABELS.LOAD} onClick={loadAttendenceReport} />
              </div>
            </div>
          </div>
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {rows && (
            <ExcelGrid
              id={LABELS.ATTENDANCE_REPORT}
              height={window.innerHeight * 0.8}
              cols={columns}
              rows={rows}
              exportOption={true}
              newRowOption={false}
              exportName={LABELS.ATTENDANCE_REPORT}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(AttendanceReport);
