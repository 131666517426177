import React, { useEffect, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import {
  Done as IconVerified,
  ThumbDownAlt as IconReject,
} from "@mui/icons-material";
import { withSnackbar } from "notistack";

import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import TxtRow from "components/Custom/TxtRow";

import {
  API_CONFIG,
  CONFIG,
  CRM_LEAD_STATUS,
  DOC_UPLOAD_STATUS,
  NOTIFY,
} from "common/config";
import { COMMON_JS } from "common/scripts";
import { ERRORS, LABELS, SUCCESS, WARNINGS } from "common/lables";
import { COLORS } from "common/colors";

import { CRM_APIs } from "apihandlers/crm";
import { AUTH_APIs } from "apihandlers/auth";
import RadioBtn from "components/Custom/RadioBtn";

const pdfThumbnailImg = require("assets/images/pdf.png");

interface defaultProps {}

const DocVerification = (props: any) => {
  const { selectedRow, onClose, isButtonDisabled } = props;
  const interviewDetails = selectedRow.interviewDetails
    ? JSON.parse(selectedRow.interviewDetails)
    : {};
  const documentDetails = selectedRow.documentDetails
    ? JSON.parse(selectedRow.documentDetails)
    : {};
  const [loading, setLoading] = useState<boolean>(true);
  const [interviewProcess, setInterviewProcess] = useState(
    interviewDetails.interviewProcess || ""
  );
  const [uploadFiles, setUploadFiles] = useState<any>([]);
  const [leadDocs, setLeadDocs] = useState<any>([]);
  const [column, setColumn] = useState<any>([
    {
      key: "crmDocumentName",
      name: "Document",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
    },
    {
      key: "files",
      name: "Files",
      width: "50%",
      editable: false,
      sortable: true,
      align: "center",
      renderCell: ({ row }: any) => {
        return row.files;
      },
    },
    {
      key: "status",
      name: "Status",
      width: "30%",
      editable: false,
      sortable: false,
      align: "center",
    },
  ]);
  const [rows, setRows] = useState<any>(null);
  const [vehicalDetailsCols, setVehicalDetailsCols] = useState<any[]>([
    {
      key: "no",
      name: "No.",
      type: "number",
      editable: true,
      width: "5%",
    },
    {
      key: "company",
      name: "Company",
      type: "text",
      editable: true,
      width: "25%",
    },
    {
      key: "model",
      name: "Model",
      type: "text",
      editable: true,
      width: "15%",
    },
    {
      key: "plateNo",
      name: "Plate No.",
      type: "text",
      editable: true,
      width: "15%",
    },
    {
      key: "packBody",
      name: "Pack Body",
      type: "text",
      editable: true,
      width: "20%",
    },
    {
      key: "gopalPaint",
      name: "Gopal Paint",
      type: "text",
      editable: true,
      width: "20%",
    },
  ]);
  const [vehicalDetailRows, setVehicalDetailRows] = useState<any[]>(
    selectedRow.vehicleDetails
      ? JSON.parse(selectedRow.vehicleDetails)
      : [{ id: "1.1", slNo: "1" }]
  );

  const vehicleGridRef: any = useRef();

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const onUploadFile = (e: any, row: any) => {
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setUploadFiles(null);
      uploadFile(file, base64, row.id).then((res) => {
        setUploadFiles([res]);
        getRows();
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "", docId = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "crm",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        manageCrmLeadDoc(fileInfo.id, docId);
        console.log("File Info", fileInfo);
      }
    } catch (error) {
      console.error(error);
    }
    return fileInfo;
  };

  const manageCrmLeadDoc = async (
    fileId,
    docId,
    status = DOC_UPLOAD_STATUS.UPLOADED,
    id = null
  ) => {
    const args = {
      id: id || -1,
      crmLeadId: selectedRow.id,
      docId: docId,
      documentStatus: status,
      fileId,
    };
    CRM_APIs.manageCrmLeadDocument(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const getRows = async () => {
    try {
      const args = {
        // id: selectedRow.id,
      };
      setRows(null);
      const res: any = await CRM_APIs.getCrmDocuments(args);
      const { status, response } = res;
      if (status === 200) {
        getCrmLeadDocuments(res.response.records);
      }
    } catch (error) {
      console.error("getCrmDocuments:", error);
    }
  };

  const getCrmLeadDocuments = async (list: any) => {
    const args = { crmLeadId: selectedRow.id };
    const res: any = await CRM_APIs.getCrmLeadDocuments(args);
    if (res.status === 200) {
      const recs = res.response.records;
      setLeadDocs(recs);
      for (let i = 0; i < list.length; i++) {
        if (list[i].isDocMandatory) {
          list[i].crmDocumentName = (
            <span className="red">{list[i].crmDocumentName + " *"}</span>
          );
        }

        const docFiles = recs.filter((r: any) => r.docId === list[i].id);

        const docFile =
          docFiles.length > 0 ? docFiles[docFiles.length - 1] : {};
        let isUpload = false;
        if (selectedRow.crmLeadStatus === CRM_LEAD_STATUS.FIELD_VISIT) {
          if (docFile.documentStatus === undefined) {
            isUpload = true;
          } else if (docFile.documentStatus !== DOC_UPLOAD_STATUS.VERIFIED) {
            isUpload = true;
          }
        }

        list[i].files = (
          <div className="flexRow">
            {isUpload && (
              <div style={{ width: "40%" }}>
                <TxtBox
                  type={"file"}
                  id={"uploadFile-" + i}
                  accept="image/*,.pdf"
                  onChange={(e) => onUploadFile(e, list[i])}
                />
              </div>
            )}

            <div
              style={{
                marginLeft: 10,
                width: "60%",
                overflow: "auto",
                display: "flex",
              }}
            >
              {docFiles.map((d: any) => {
                const fileUrl = API_CONFIG.STATIC_URL + d.path;
                return (
                  <img
                    onClick={() => {
                      window.open(fileUrl, "_blank");
                    }}
                    src={
                      d.mimeType.indexOf("pdf") > -1 ? pdfThumbnailImg : fileUrl
                    }
                    width={"auto"}
                    height={50}
                    style={{ marginRight: 5 }}
                  />
                );
              })}
            </div>
          </div>
        );
        const isVerified =
          docFile.documentStatus &&
          docFile.documentStatus === DOC_UPLOAD_STATUS.UPLOADED;
        const isRejected =
          docFile.documentStatus &&
          docFile.documentStatus === DOC_UPLOAD_STATUS.UPLOADED;
        list[i].status = (
          <div
            className="flexRow"
            style={{ margin: "auto", alignItems: "center" }}
          >
            {docFile.documentStatus && (
              <p
                className={"bold "}
                style={{
                  margin: 0,
                  color:
                    docFile.documentStatus === DOC_UPLOAD_STATUS.UPLOADED
                      ? COLORS.ORANGE
                      : docFile.documentStatus === DOC_UPLOAD_STATUS.VERIFIED
                      ? COLORS.GREEN
                      : COLORS.RED,
                }}
              >
                {docFile.documentStatus}
              </p>
            )}
            {isVerified && (
              <Chip
                label={DOC_UPLOAD_STATUS.VERIFIED}
                disabled={false}
                clickable={true}
                icon={<IconVerified />}
                style={{ marginLeft: "10px" }}
                className={"chipBtn"}
                onClick={() => {
                  manageCrmLeadDoc(
                    docFile.fileId,
                    list[i].id,
                    DOC_UPLOAD_STATUS.VERIFIED,
                    docFile.id
                  );
                }}
              />
            )}
            {isRejected && (
              <Chip
                label={DOC_UPLOAD_STATUS.REJECTED}
                disabled={false}
                clickable={true}
                icon={<IconReject />}
                style={{ marginLeft: "10px" }}
                className={"chipBtn"}
                onClick={() => {
                  manageCrmLeadDoc(
                    docFile.fileId,
                    list[i].id,
                    DOC_UPLOAD_STATUS.REJECTED,
                    docFile.id
                  );
                }}
              />
            )}
          </div>
        );
      }
    }
    setRows(list);
    setLoading(false);
  };

  const onDocVerified = async () => {
    try {
      const accName = (
        document.getElementById("accName") as HTMLInputElement
      ).value.trim();
      const accNo = (
        document.getElementById("accNo") as HTMLInputElement
      ).value.trim();
      const bankName = (
        document.getElementById("bankName") as HTMLInputElement
      ).value.trim();
      const branch = (
        document.getElementById("branch") as HTMLInputElement
      ).value.trim();
      const typeOFAcc = (
        document.getElementById("typeOFAcc") as HTMLInputElement
      ).value.trim();
      const ifscNo = (
        document.getElementById("ifscNo") as HTMLInputElement
      ).value.trim();

      const permanentAddress = (
        document.getElementById("permanentAddress") as HTMLInputElement
      ).value.trim();

      const vehicleData = vehicleGridRef.getRowData();

      const remarks = (
        document.getElementById("remarks") as HTMLInputElement
      ).value.trim();
      const argsRemarks = {
        ...selectedRemarks,
        documentVerification: remarks,
      };
      const args = {
        bankDetails: { accName, accNo, bankName, branch, typeOFAcc, ifscNo },
        documentDetails: {
          ...documentDetails,
          permanentAddress,
        },
        vehicleDetails: vehicleData,
        crmLeadId: selectedRow.id,
        crmLeadName: selectedRow.crmLeadName,
        activeStatus: "true",
        crmLeadStatus: CRM_LEAD_STATUS.DOCUMENT_VERIFICATION,
        remarks: argsRemarks,
      };
      console.log("args: ", args);
      const res = await CRM_APIs.manageCrmLeadProfile(args);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        onClose();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    } catch (error) {
      console.error("onConverted: ", error);
    }
  };

  const getDefaultVehicleNewRow = () => {
    const newRow: any = {
      id: (vehicleGridRef.getRowData().length + 1).toString(),
      no: "",
      company: "",
      model: "",
      plateNo: "",
      packBody: "",
      gopalPaint: "",
    };
    return newRow;
  };

  let mandatoryDocs: any = {};
  if (rows) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].isDocMandatory) {
        mandatoryDocs[rows[i].id] = false;
      }
    }
    for (let i = 0; i < leadDocs.length; i++) {
      const docId = leadDocs[i].docId;
      const docStatus = leadDocs[i].documentStatus;
      console.log("doc :", docId, docStatus);
      if (docStatus === DOC_UPLOAD_STATUS.VERIFIED) {
        if (!mandatoryDocs[docId]) {
          mandatoryDocs[docId] = true;
        }
      }
    }
  }
  mandatoryDocs = Object.values(mandatoryDocs);
  const verifiedDocs = mandatoryDocs.filter((m) => {
    return m;
  });

  const bankDetails = selectedRow.bankDetails
    ? JSON.parse(selectedRow.bankDetails)
    : {};

  const selectedRemarks = selectedRow.remarks
    ? JSON.parse(selectedRow.remarks)
    : {};

  return (
    <React.Fragment>
      {rows ? (
        <>
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={column}
            rows={rows}
            exportOption={false}
            newRowOption={false}
            onReload={getRows}
          />

          <fieldset className="formSection">
            <legend className="formSectionLegend">{LABELS.BANK_DEATIL}</legend>
            <div className="flexColumn">
              <div className="flexRowAround">
                <div className="formGroup width30">
                  <TxtRow
                    id={"accName"}
                    label={LABELS.ACCOUNT_NAME}
                    defaultValue={bankDetails.accName || ""}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"accNo"}
                    label={LABELS.ACCOUNT_NUMBER}
                    defaultValue={bankDetails.accNo || ""}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"bankName"}
                    label={LABELS.BANK_NAME}
                    defaultValue={bankDetails.bankName || ""}
                  />
                </div>
              </div>

              <div className="flexRowAround">
                <div className="formGroup width30">
                  <TxtRow
                    id={"branch"}
                    label={LABELS.BRANCH}
                    defaultValue={bankDetails.branch || ""}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"typeOFAcc"}
                    label={LABELS.TYPE_OF_ACCOUNT}
                    defaultValue={bankDetails.typeOFAcc || ""}
                  />
                </div>
                <div className="formGroup width30">
                  <TxtRow
                    id={"ifscNo"}
                    label={LABELS.IFSC_NO}
                    defaultValue={bankDetails.ifscNo || ""}
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="formSection">
            <legend className="formSectionLegend">
              {LABELS.VEHICAL_DETAILS}
            </legend>
            <div className="flexRowAround">
              <ExcelGrid
                height={600}
                childRef={vehicleGridRef}
                cols={vehicalDetailsCols}
                rows={vehicalDetailRows}
                exportOption={false}
                newRowOption={false}
                //onAdd={onAdd}
                getDefaultNewRow={getDefaultVehicleNewRow}
              />
            </div>
          </fieldset>

          <div className="formGroup width60" style={{ marginBottom: 20 }}>
            <TxtRow
              id={"permanentAddress"}
              type={"text"}
              label={LABELS.PERMANENT_ADDRESS}
              defaultValue={documentDetails.permanentAddress || " "}
              //disabled={isSubmit}
              rows={4}
            />
          </div>

          <div className="formGroup width60">
            <TxtRow
              id={"remarks"}
              type={"text"}
              label={LABELS.REMARKS}
              defaultValue={selectedRemarks.documentVerification || " "}
              //disabled={isSubmit}
              rows={4}
            />
          </div>

          <div
            className="flexColumn"
            style={{
              alignItems: "flex-end",
              marginTop: "2rem",
              marginLeft: "2rem",
            }}
          >
            <div
              className="flexRow"
              style={{
                width: "30%",
                paddingRight: "2rem",
                justifyContent: "right",
                marginBottom: "2rem",
              }}
            >
              <Btn
                text={LABELS.DOCUMENT_VERIFIED}
                onClick={onDocVerified}
                style={{ width: "50%" }}
                bgColor={COLORS.SAVE_GREEN}
                disabled={
                  mandatoryDocs.length !== verifiedDocs.length ||
                  isButtonDisabled
                }
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flexRowCenter">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(DocVerification);
