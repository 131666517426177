import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import {
  Person as UserIcon,
  CalendarMonth as CalenderIcon,
  Place as Location,
  Comment,
} from "@mui/icons-material";

import TxtRow from "components/Custom/TxtRow";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";
import SkeletonBox from "components/Custom/SkeletonBox";

import { LABELS, SUCCESS, WARNINGS } from "common/lables";
import { COLORS } from "common/colors";
import { FORMATS, NOTIFY } from "common/config";
import { COMMON_JS } from "common/scripts";

import { CUSTOMER_APIs } from "apihandlers/customer";
import { USER_APIs } from "apihandlers/user";
import { DateFunctions } from "common/datefunctions";

const AssignedInfo = (props: any) => {
  const { data, areas, onClose, selectedRow } = props;
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [toUsers, setToUsers] = useState([]);
  const [byUsers, setByUsers] = useState([]); // todo: get only marketing users
  const [assignedInfo, setAssignedInfo] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  const toUsersRef: any = useRef(null);
  const byUsersRef: any = useRef(null);
  const areasRef: any = useRef(null);

  useEffect(() => {
    if (loading) {
      loadInitData();
    }
  }, [loading]);

  const loadInitData = async () => {
    try {
      const args = {};
      const res: any = await USER_APIs.getUsers(args);
      if (res.status === 200) {
        const users = res.response.records.map((r) => {
          return {
            id: r.id,
            label: r.fullName,
          };
        });
        setToUsers(users);
        setByUsers(users);
        const res2: any = await CUSTOMER_APIs.getCrmLeadAssigned({
          crmLeadId: selectedRow.id,
        });
        if (res2.status === 200) {
          const recs = res2.response.records;
          if (recs.length > 0) {
            setAssignedInfo(recs[recs.length - 1]);
          } else {
            setAssignedInfo({});
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("loadInitData: error:", error);
    }
  };

  const saveRecord = async () => {
    try {
      const args = {
        id: assignedInfo.id || -1,
        crmLeadId: selectedRow.id,
        assignedToId: toUsersRef.current.selectedValue.id,
        assignedById: byUsersRef.current.selectedValue.id,
        assignedDate: (
          document.getElementById("assignedDate") as HTMLInputElement
        ).value,
        areaId: areasRef.current.selectedValue.id,
        comments: (
          document.getElementById("assignComments") as HTMLInputElement
        ).value,
      };

      let errors = [];
      if (!args.assignedToId) {
        errors.push({
          id: "toUsersRef",
          msg:
            "<span class='bold'>" +
            LABELS.ASSIGNED_TO +
            "</span> " +
            WARNINGS.IS_A_REQUIRED_FIELD,
        });
      }
      if (!args.assignedById) {
        errors.push({
          id: "byUsersRef",
          msg:
            "<span class='bold'>" +
            LABELS.ASSIGNED_BY +
            "</span> " +
            WARNINGS.IS_A_REQUIRED_FIELD,
        });
      }
      if (!args.areaId) {
        errors.push({
          id: "areasRef",
          msg:
            "<span class='bold'>" +
            LABELS.AREA +
            "</span> " +
            WARNINGS.IS_A_REQUIRED_FIELD,
        });
      }
      if (!args.assignedDate) {
        errors.push({
          id: "assignedDate",
          msg:
            "<span class='bold'>" +
            LABELS.ASSIGNED_DATE +
            "</span> " +
            WARNINGS.IS_A_REQUIRED_FIELD,
        });
      }

      if (!args.comments) {
        errors.push({
          id: "assignComments",
          msg:
            "<span class='bold'>" +
            LABELS.COMMENTS +
            "</span> " +
            WARNINGS.IS_A_REQUIRED_FIELD,
        });
      }

      setMandatoryResults(errors);
      if (errors.length > 0) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          WARNINGS.PLEASE_CHECK_FORM_ERRORS
        );
        return;
      }

      // if (
      //   !args.assignedToId ||
      //   !args.assignedById ||
      //   !args.assignedDate ||
      //   !args.areaId ||
      //   !args.comments
      // ) {
      //   COMMON_JS.showNotify(
      //     props,
      //     NOTIFY.WARNING,
      //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
      //   );
      //   return;
      // }
      setIsSubmit(true);
      const res: any = await CUSTOMER_APIs.manageLeadAssigned(args);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        onClose(true);
        // getCrmLeadAssigned();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
      setIsSubmit(false);
      console.log("saveRecord: ", args, res);
    } catch (error) {
      setIsSubmit(false);
      console.log("saveRecord: error:", error);
    }
  };

  return loading ? (
    <div>
      <fieldset className="formSection" style={{ padding: "2rem" }}>
        <SkeletonBox height={45} width={"30%"} style={{ marginBottom: 15 }} />
        <SkeletonBox height={45} width={"30%"} style={{ marginBottom: 15 }} />
        <SkeletonBox height={45} width={"30%"} style={{ marginBottom: 15 }} />
        <SkeletonBox height={45} width={"30%"} style={{ marginBottom: 15 }} />
        <SkeletonBox height={150} width={"30%"} style={{ marginBottom: 15 }} />
        <div className="formGroup submitBtnWrapper width30">
          <div className="width100 flexRowRight">
            <SkeletonBox
              height={40}
              width={"25%"}
              style={{ marginRight: 15 }}
            />
            <SkeletonBox height={40} width={"25%"} />
          </div>
        </div>
      </fieldset>
    </div>
  ) : assignedInfo.id ? (
    <div>
      <fieldset className="formSection" style={{ padding: "2rem" }}>
        <div className="listIcon">
          <UserIcon fontSize="medium" />
          <p>
            <span className="semiBold"> {LABELS.ASSIGNED_TO}</span>
            <span>{":"}</span>
            <span className="pL10">{assignedInfo.assignedToUser || ""}</span>
          </p>
        </div>
        <div className="listIcon">
          <UserIcon fontSize="medium" />
          <p>
            <span className="semiBold"> {LABELS.ASSIGNED_BY}</span>
            <span>{":"}</span>
            <span className="pL10">{assignedInfo.assignedByUser || ""}</span>
          </p>
        </div>
        <div className="listIcon">
          <Location fontSize="medium" />
          <p>
            <span className="semiBold">{LABELS.AREA}</span>
            <span>{":"}</span>
            <span className="pL10">{assignedInfo.areaName || ""}</span>
          </p>
        </div>
        <div className="listIcon">
          <CalenderIcon fontSize="medium" />
          <p>
            <span className="semiBold">{LABELS.ASSIGNED_ON}</span>
            <span>{":"}</span>
            <span className="pL10">{assignedInfo.assignedDate || ""}</span>
          </p>
        </div>
        <div className="listIcon">
          <Comment fontSize="medium" />
          <p>
            <span className="semiBold">{LABELS.COMMENTS}</span>
            <span>
              {":"}
              <span className="pL10">{assignedInfo.comments || ""}</span>
            </span>
          </p>
        </div>
      </fieldset>
    </div>
  ) : (
    <div>
      <fieldset className="formSection" style={{ padding: "2rem" }}>
        <div className="formGroup required width30">
          <label>
            {LABELS.ASSIGNED_TO}
            <span>{" *"}</span>
          </label>
          <AutoComplete
            list={toUsers}
            childRef={toUsersRef}
            required={true}
            disabled={isSubmit}
          />
        </div>
        <div className="formGroup required width30">
          <label>
            {LABELS.ASSIGNED_BY}
            <span>{" *"}</span>
          </label>
          <AutoComplete
            list={byUsers}
            childRef={byUsersRef}
            required={true}
            disabled={isSubmit}
          />
        </div>
        <div className="formGroup required width30">
          <label>
            {LABELS.AREA}
            <span>{" *"}</span>
          </label>
          <AutoComplete
            list={areas}
            childRef={areasRef}
            required={true}
            disabled={isSubmit}
          />
        </div>
        <div className="formGroup required width30">
          <TxtRow
            id={"assignedDate"}
            type={"date"}
            label={LABELS.ASSIGNED_DATE}
            required={true}
            // defaultValue={selectedRow.id && selectedRow.assignedDate}

            defaultValue={
              selectedRow.id
                ? DateFunctions.getFormatedDate(
                    selectedRow.assignedDate,
                    FORMATS.SQL_DATE,
                    FORMATS.SQL_DATE_ONLY
                  )
                : null
            }
            disabled={isSubmit}
          />
        </div>
        <div className="formGroup required width30">
          <TxtRow
            id={"assignComments"}
            label={LABELS.COMMENTS}
            required={true}
            rows={3}
            defaultValue={selectedRow.id && selectedRow.comments}
            disabled={isSubmit}
          />
        </div>

        <div
          className="formGroup flexRowAround"
          style={{
            width: "50%",
            margin: "auto",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          {/* <div className="width30">
            <Btn text={LABELS.SAVE} loading={isSubmit} onClick={saveRecord} />
          </div>
          <div className="width30">
            <Btn
              text={LABELS.CANCEL}
              bgColor={COLORS.DIM_DARK_GRAY}
              disabled={isSubmit}
              onClick={() => onClose(false)}
            />
          </div> */}
        </div>

        <div className="formGroup submitBtnWrapper width30">
          <div className="flexbtnjustify">
            {/* <Btn
              text={LABELS.CANCEL}
              loading={isSubmit}
              bgColor={COLORS.RELOAD_EXPORT}
              onClick={() => {
                setMandatoryResults([]);
                onClose(false);
              }}
            /> */}
            <Btn
              text={LABELS.SAVE}
              loading={isSubmit}
              bgColor={COLORS.SAVE_GREEN}
              onClick={saveRecord}
            />
          </div>
        </div>
      </fieldset>
      {mandatoryResults.length > 0 && (
        <div className="mandatoryBox">
          <ul>
            {mandatoryResults.map((m) => {
              return (
                <li>
                  <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(AssignedInfo);
