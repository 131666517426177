import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Close as IconClose } from "@mui/icons-material";

import TxtRow from "components/Custom/TxtRow";
import RadioBtn from "components/Custom/RadioBtn";
import ExcelGrid from "components/Custom/ExcelGrid";
import Btn from "components/Custom/Btn";
import TxtBox from "components/Custom/TxtBox";
import SkeletonBox from "components/Custom/SkeletonBox";

import { COLORS } from "common/colors";
import { DateFunctions } from "common/datefunctions";
import {
  API_CONFIG,
  CONFIG,
  CRM_LEAD_STATUS,
  FORMATS,
  NOTIFY,
} from "common/config";
import { LABELS, SUCCESS, WARNINGS } from "common/lables";
import { COMMON_JS } from "common/scripts";
import { CRM_APIs } from "apihandlers/crm";
import { AUTH_APIs } from "apihandlers/auth";
import { GEO_APIs } from "apihandlers/geography";

import { withSnackbar } from "notistack";
import AutoComplete from "components/Custom/AutoComplete";

const datajson = require("./grid.json");
const thumbnailImg = require("assets/images/pdf.png");

interface defaultProps {
  selectedRow;
  childRef: any;
}

const Qualified = (props: any) => {
  const {
    selectedRow,
    childRef,
    areas,
    onClose,
    companyDetails,
    currentDetails,
    personalDetails,
    partnerDetails,
    storageDetails,
    isButtonDisabled,
    familyData,
  } = props;

  const depositDetails = selectedRow.depositDetails
    ? JSON.parse(selectedRow.depositDetails)
    : {};
  const bankDetails = selectedRow.bankDetails
    ? JSON.parse(selectedRow.bankDetails)
    : {};
  const referenceDetails = selectedRow.referenceDetails
    ? JSON.parse(selectedRow.referenceDetails)
    : {};
  const areaDetails = selectedRow.areaDetails
    ? JSON.parse(selectedRow.areaDetails)
    : {};
  // const familyData = selectedRow.familyDetails
  //   ? JSON.parse(selectedRow.familyDetails)
  //   : {};
  const selectedRemarks = selectedRow.remarks
    ? JSON.parse(selectedRow.remarks)
    : {};

  const gridRef: any = useRef();
  const partnerGridRef: any = useRef();
  const areasRef: any = useRef(null);
  const nomineeGridRef: any = useRef();
  const familyGridRef: any = useRef();
  const vehicleGridRef: any = useRef();
  const companyStateRef: any = useRef();
  const companyDistrictRef: any = useRef();
  const companyZoneRef: any = useRef();
  const companyCityRef: any = useRef();
  const areaDetailsStateRef: any = useRef();
  const areaDetailsDistrictRef: any = useRef();
  const warehouseStateRef: any = useRef();
  const warehouseDistrictRef: any = useRef();

  const [partnerColumns, setPartnerColumns] = useState<any>([
    // {
    //   key: "partner",
    //   name: LABELS.PARTNER,
    //   type: "text",
    //   width: "6%",
    //   editable: true,
    //   sortable: false,
    //   align: "center",
    // },
    {
      key: "name",
      name: LABELS.NAME,
      type: "text",
      width: "20%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "mobileNo",
      name: LABELS.MOBILE_NUMBER,
      type: "text",
      width: "10%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "homeAdd",
      name: LABELS.HOME_ADDRESS,
      type: "text",
      width: "24%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "birthDate",
      name: LABELS.DATE_OF_BIRTH,
      type: "date",
      width: "10%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "drivingLicense",
      name: LABELS.DRIVING_LICENSE,
      width: "10%",
      editable: true,
      sortable: false,
      align: "center",
      type: "text",
    },
    {
      key: "panNo",
      name: LABELS.PAN_NUMBER,
      type: "text",
      width: "10%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "aadharNo",
      name: LABELS.AADHAR_UID_NUMBER,
      type: "text",
      width: "16%",
      editable: true,
      sortable: false,
      align: "center",
    },
  ]);

  const [partnerRows, setPartnerRows] = useState<any[]>(
    companyDetails.partnerData
      ? companyDetails.partnerData
      : [
          {
            id: "1",
            name: partnerDetails.partnerFName,
            mobileNo: partnerDetails.partnerPhone,
            homeAdd: partnerDetails.partnerCurrentAddress,
            birthDate: partnerDetails.partnerDOB,
          },
        ]
  );

  const [familyDetailsRow, setFamilyDetailsRow] = useState<any[]>(
    selectedRow.familyDetails
      ? familyData.references
      : [
          {
            id: "1.1",
            slNo: 1,
          },
        ]
  );
  const [familydeatilsColumn, setFamilyDetailsColumn] = useState<any>([
    // {
    //   key: "slno",
    //   name: LABELS.SL_NO,
    //   type: "text",
    //   width: "5%",
    //   editable: false,
    //   sortable: false,
    //   align: "center",
    // },
    {
      key: "name",
      name: LABELS.NAME,
      type: "text",
      width: "20%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "contact",
      name: LABELS.CONTACT,
      type: "text",
      width: "15%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "relation",
      name: LABELS.RELATION,
      type: "text",
      width: "15%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "address",
      name: LABELS.ADDRESS,
      type: "text",
      width: "50%",
      editable: true,
      sortable: false,
      align: "center",
    },
  ]);

  const [nomineedeatilsColumn, setNomineeDeatilsColumn] = useState<any>([
    // {
    //   key: "slno",
    //   name: LABELS.SL_NO,
    //   type: "text",
    //   width: "5%",
    //   editable: false,
    //   sortable: false,
    //   align: "center",
    // },
    {
      key: "fullName",
      name: LABELS.FULL_NAME,
      type: "text",
      width: "20%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "contact",
      name: LABELS.CONTACT,
      type: "text",
      width: "15%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "relation",
      name: LABELS.RELATION,
      type: "text",
      width: "15%",
      editable: true,
      sortable: false,
      align: "center",
    },
    {
      key: "address",
      name: LABELS.ADDRESS,
      type: "text",
      width: "50%",
      editable: true,
      sortable: false,
      align: "center",
    },
  ]);
  const [nomineedeatilsRow, setNomineedeatilsRow] = useState<any[]>(
    selectedRow.familyDetails
      ? familyData.nominees
      : [
          {
            id: "1.1",
            slno: 1,
          },
        ]
  );

  const [vehicalDetailsCols, setVehicalDetailsCols] = useState<any[]>([
    {
      key: "no",
      name: "No.",
      type: "number",
      editable: true,
      width: "5%",
    },
    {
      key: "company",
      name: "Company",
      type: "text",
      editable: true,
      width: "25%",
    },
    {
      key: "model",
      name: "Model",
      type: "text",
      editable: true,
      width: "15%",
    },
    {
      key: "plateNo",
      name: "Plate No.",
      type: "text",
      editable: true,
      width: "15%",
    },
    {
      key: "packBody",
      name: "Pack Body",
      type: "text",
      editable: true,
      width: "20%",
    },
    {
      key: "gopalPaint",
      name: "Gopal Paint",
      type: "text",
      editable: true,
      width: "20%",
    },
  ]);
  const [vehicalDetailRows, setVehicalDetailRows] = useState<any[]>(
    selectedRow.vehicleDetails
      ? JSON.parse(selectedRow.vehicleDetails)
      : [{ id: "1.1", slNo: "1" }]
  );
  const [uploading, setUploading] = useState<boolean>(false);

  const [qualifiedFiles, setQualifiedFiles] = useState<any>(
    depositDetails.files || []
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [states, setStates] = useState<any>([]);
  const [companyDistrict, setCompanyDistrict] = useState<any>([]);
  const [companyZone, setCompanyZone] = useState<any>([]);
  const [companyCity, setCompanyCity] = useState<any>([]);
  const [areaDetailsDistrict, setAreaDetailsDistrict] = useState<any>([]);
  const [warehouseDistrict, setWarehouseDistrict] = useState<any>([]);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const res: any = await GEO_APIs.getStates({ activeStatus: "true" });
      const stateList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.stateName,
        };
      });
      setStates(stateList);
      if (selectedRow.id) {
        // load districts
        if (companyDetails.compState) {
          const list = await getDistricts(companyDetails.compState);
          setCompanyDistrict(list);
        }
        if (companyDetails.compDist) {
          const list = await getCity(companyDetails.compDist);
          setCompanyCity(list);
        }
        //zone
        if (companyDetails.compState) {
          const list = await getZone(companyDetails.compState);
          setCompanyZone(list);
        }
        if (areaDetails.stateArea) {
          const list = await getDistricts(areaDetails.stateArea);
          setAreaDetailsDistrict(list);
        }
        if (storageDetails.stateStorage) {
          const list = await getDistricts(storageDetails.stateStorage);
          setWarehouseDistrict(list);
        }
      }
      setLoading(false);
    } catch (error) {}
  };

  const getDistricts = async (stateId) => {
    let districtList = [];
    try {
      const args = {
        stateId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getDistricts(args);
      districtList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.districtName,
        };
      });
    } catch (error) {}
    return districtList;
  };
  const getZone = async (stateId) => {
    let zoneList = [];
    try {
      const args = {
        stateId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getRegionZones(args);
      zoneList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.regionZoneName,
        };
      });
    } catch (error) {}
    return zoneList;
  };
  const getCity = async (districtId) => {
    let cityList = [];
    try {
      const args = {
        districtId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getCities(args);
      cityList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.cityName,
        };
      });
    } catch (error) {}
    return cityList;
  };

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setQualifiedFiles(null);
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        setQualifiedFiles([...qualifiedFiles, res]);
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "storevisit",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        fileInfo["mimeType"] = args.file.mimeType;
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };

  const manageLeadProfile = async () => {
    try {
      const firmName = (
        document.getElementById("firmName") as HTMLInputElement
      ).value.trim();
      const areaId = areasRef.current.selectedValue.id;
      const regAddress = (
        document.getElementById("regAddress") as HTMLInputElement
      ).value.trim();
      const taluka = (
        document.getElementById("taluka") as HTMLInputElement
      ).value.trim();
      const district = companyDistrictRef.current.selectedValue
        ? companyDistrictRef.current.selectedValue.id
        : "";
      const state = companyStateRef.current.selectedValue
        ? companyStateRef.current.selectedValue.id
        : "";
      const city = companyCityRef.current.selectedValue
        ? companyCityRef.current.selectedValue.id
        : "";

      const zone = companyZoneRef.current.selectedValue
        ? companyZoneRef.current.selectedValue.id
        : "";
      let type = document.querySelector(
        'input[name="firmType"]:checked'
      ) as HTMLInputElement;
      const firmType = type ? type.value : "";

      const pinNo = (
        document.getElementById("pinNo") as HTMLInputElement
      ).value.trim();
      const email = (
        document.getElementById("emailId") as HTMLInputElement
      ).value.trim();

      const firmPAN = (
        document.getElementById("firmPAN") as HTMLInputElement
      ).value.trim();

      const religion = (
        document.getElementById("religion") as HTMLInputElement
      ).value.trim();

      const firmGST = (
        document.getElementById("firmGST") as HTMLInputElement
      ).value.trim();
      const fssiNo = (
        document.getElementById("fssiNo") as HTMLInputElement
      ).value.trim();

      const busStartDate = (
        document.getElementById("busStartDate") as HTMLInputElement
      ).value.trim();

      const personName = (
        document.getElementById("personName") as HTMLInputElement
      ).value.trim();
      const mobileNo1 = (
        document.getElementById("mobileNo1") as HTMLInputElement
      ).value.trim();
      const personName2 = (
        document.getElementById("personName2") as HTMLInputElement
      ).value.trim();
      const mobileNo2 = (
        document.getElementById("mobileNo2") as HTMLInputElement
      ).value.trim();

      // area
      const areaVillage = (
        document.getElementById("areaVillage") as HTMLInputElement
      ).value.trim();
      const talukaArea = (
        document.getElementById("talukaArea") as HTMLInputElement
      ).value.trim();
      const districtArea = areaDetailsDistrictRef.current.selectedValue
        ? areaDetailsDistrictRef.current.selectedValue.id
        : "";
      const stateArea = areaDetailsStateRef.current.selectedValue
        ? areaDetailsStateRef.current.selectedValue.id
        : "";

      //PERSONAL details
      const mobileNo = (
        document.getElementById("mobileNo") as HTMLInputElement
      ).value.trim();
      const homeAddress = (
        document.getElementById("homeAddress") as HTMLInputElement
      ).value.trim();
      const birthDate = (
        document.getElementById("birthDate") as HTMLInputElement
      ).value.trim();
      const drivingLicense = (
        document.getElementById("drivingLicense") as HTMLInputElement
      ).value.trim();
      const panNo = (
        document.getElementById("panNo") as HTMLInputElement
      ).value.trim();
      const aadharUIDNo = (
        document.getElementById("aadharUIDNo") as HTMLInputElement
      ).value.trim();

      //PARTNER details

      //STORAGE
      const sqFt = (
        document.getElementById("sqFt") as HTMLInputElement
      ).value.trim();
      const address = (
        document.getElementById("address") as HTMLInputElement
      ).value.trim();
      const talukaStorage = (
        document.getElementById("talukaStorage") as HTMLInputElement
      ).value.trim();
      const districtStorage = warehouseDistrictRef.current.selectedValue
        ? warehouseDistrictRef.current.selectedValue.id
        : "";

      const stateStorage = warehouseStateRef.current.selectedValue
        ? warehouseStateRef.current.selectedValue.id
        : "";

      //BANK
      const accName = (
        document.getElementById("accName") as HTMLInputElement
      ).value.trim();
      const accNo = (
        document.getElementById("accNo") as HTMLInputElement
      ).value.trim();
      const bankName = (
        document.getElementById("bankName") as HTMLInputElement
      ).value.trim();
      const branch = (
        document.getElementById("branch") as HTMLInputElement
      ).value.trim();
      const typeOFAcc = (
        document.getElementById("typeOFAcc") as HTMLInputElement
      ).value.trim();
      const ifscNo = (
        document.getElementById("ifscNo") as HTMLInputElement
      ).value.trim();

      //reference
      const appointedBy = (
        document.getElementById("appointedBy") as HTMLInputElement
      ).value.trim();

      //deposit detail
      const amount = (
        document.getElementById("amount") as HTMLInputElement
      ).value.trim();
      const depositeDate = (
        document.getElementById("depositeDate") as HTMLInputElement
      ).value.trim();

      ///other family
      const familyData = familyGridRef.getRowData();

      ///Nominee details
      const nomineeData = nomineeGridRef.getRowData();

      //partner
      const partnerData = partnerGridRef.getRowData();

      //vehicle
      const vehicleData = vehicleGridRef.getRowData();

      const remarks = (
        document.getElementById("remarks") as HTMLInputElement
      ).value.trim();

      //COMPANY
      const argsCompany = {
        ...JSON.parse(selectedRow.companyDetails),
        compName: firmName,
        compTaluka: taluka,
        compDist: district,
        compState: state,
        partnerData: partnerData,
        regAddress,
        pinNo,
        firmPAN,
        religion,
        firmGST,
        fssiNo,
        busStartDate,
        personName,
        mobileNo1,
        personName2,
        mobileNo2,
        firmType,
        email,
      };

      // area
      const argsArea = {
        areaVillage,
        talukaArea,
        districtArea,
        stateArea,
      };

      // PERSONAL
      const argsPersonal = {
        ...JSON.parse(selectedRow.personalDetails),
        phone: mobileNo,
        drivingLicense,
        panNo,
        aadharUIDNo,
        dob: birthDate,
      };

      //STORAGE
      const argsStorage = {
        ...JSON.parse(selectedRow.storageDetails),
        address,
        talukaStorage,
        districtStorage,
        stateStorage,
        godownSize: sqFt,
      };

      // BANK
      const argsBank = {
        accName,
        accNo,
        bankName,
        branch,
        typeOFAcc,
        ifscNo,
      };

      // reference
      const argsReference = {
        appointedBy,
      };

      // DEPOSIT DETAIL
      const argsDeposit = {
        amount,
        depositeDate,
        files: qualifiedFiles,
      };

      //OTHER FAMILY

      // NOMIEE DETAILS

      // VEHICAL DETAIL
      const argsRemarks = {
        ...selectedRemarks,
        qualified: remarks,
      };
      const args = {
        crmLeadId: selectedRow.id,
        crmLeadName: selectedRow.crmLeadName,
        activeStatus: "true",
        crmLeadStatus: CRM_LEAD_STATUS.QUALIFIED,
        companyDetails: argsCompany,
        currentDetails: {
          ...JSON.parse(selectedRow.currentDetails),
          city,
          zone,
          currentAdd: homeAddress,
        },
        areaDetails: argsArea,
        storageDetails: argsStorage,
        bankDetails: argsBank,
        referenceDetails: argsReference,
        depositDetails: argsDeposit,
        personalDetails: argsPersonal,
        familyDetails: {
          references: familyData,
          nominees: nomineeData,
        },
        vehicleDetails: vehicleData,
        areaId,
        remarks: argsRemarks,
      };
      console.log("Args :", args);

      // Validations
      if (!args.areaId) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
        );
        return;
      }
      if (!argsCompany.email) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
        );
        return;
      }
      if (!argsCompany.fssiNo) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
        );
        return;
      }
      if (!argsCompany.busStartDate) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
        );
        return;
      }
      const res = await CRM_APIs.manageCrmLeadProfile(args);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        onClose();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    } catch (error) {
      console.error("manageCrmLeadProfile: error:", error);
    }
  };

  const getDefaultNomineeNewRow = () => {
    const newRow: any = {
      id: "1." + (nomineeGridRef.getRowData().length + 1).toString(),
      slno: (nomineeGridRef.getRowData().length + 1).toString(),
      fullName: "",
      contact: "",
      Relation: "",
      Address: "",
    };
    return newRow;
  };
  const getDefaultFamilyNewRow = () => {
    const newRow: any = {
      id: "1." + (familyGridRef.getRowData().length + 1).toString(),
      slno: (familyGridRef.getRowData().length + 1).toString(),
      name: "",
      contact: "",
      Relation: "",
      Address: "",
    };
    return newRow;
  };

  const onAdd = () => {};

  const getDefaultNewPartner = () => {
    const newRow: any = {
      id: (partnerGridRef.getRowData().length + 1).toString(),
      name: "",
      mobileNo: "",
      homeAdd: "",
      birthDate: "",
      drivingLicense: "",
      panNo: "",
      aadharNo: "",
    };
    return newRow;
  };

  const getDefaultVehicleNewRow = () => {
    const newRow: any = {
      id: (vehicleGridRef.getRowData().length + 1).toString(),
      no: "",
      company: "",
      model: "",
      plateNo: "",
      packBody: "",
      gopalPaint: "",
    };
    return newRow;
  };

  const onStateChange = async (e, f) => {
    if (f.id) {
      const res = await getDistricts(f.id);
      setCompanyDistrict(res);
      handleZone(f.id);
    }
  };

  const handleZone = async (f) => {
    if (f) {
      const res = await getZone(f);
      setCompanyZone(res);
    }
  };
  const onDistrictChange = async (e, f) => {
    if (f.id) {
      const res = await getCity(f.id);
      setCompanyCity(res);
    }
  };

  const onAreaStateChange = async (e, f) => {
    if (f.id) {
      const res = await getDistricts(f.id);
      setAreaDetailsDistrict(res);
    }
  };

  const onWarehouseStateChange = async (e, f) => {
    if (f.id) {
      const res = await getDistricts(f.id);
      setWarehouseDistrict(res);
    }
  };

  let selectedCompanyState = null;
  if (companyDetails.compState) {
    selectedCompanyState = states.find(
      (s) => s.id === companyDetails.compState
    );
    if (!selectedCompanyState) {
      selectedCompanyState = null;
    }
  }

  let selectedCompanyDistrict = null;
  if (companyDetails.compDist) {
    selectedCompanyDistrict = companyDistrict.find(
      (s) => s.id === companyDetails.compDist
    );
    if (!selectedCompanyDistrict) {
      selectedCompanyDistrict = null;
    }
  }

  let selectedCompanyCity = null;
  if (currentDetails.city) {
    selectedCompanyCity = companyCity.find((s) => s.id === currentDetails.city);
    if (!selectedCompanyCity) {
      selectedCompanyCity = null;
    }
  }
  let selectedCompanyZone = null;
  if (currentDetails.zone) {
    selectedCompanyZone = companyCity.find((s) => s.id === currentDetails.zone);
    if (!selectedCompanyZone) {
      selectedCompanyZone = null;
    }
  }
  let selectedAreaDetailsState = null;
  if (areaDetails.stateArea) {
    selectedAreaDetailsState = states.find(
      (s) => s.id === areaDetails.stateArea
    );
    if (!selectedAreaDetailsState) {
      selectedAreaDetailsState = null;
    }
  }
  let selectedAreaDetailsDistrict = null;
  if (areaDetails.districtArea) {
    selectedAreaDetailsDistrict = states.find(
      (s) => s.id === areaDetails.districtArea
    );
    if (!selectedAreaDetailsDistrict) {
      selectedAreaDetailsDistrict = null;
    }
  }
  let selectedWarehouseState = null;
  if (storageDetails.stateStorage) {
    selectedWarehouseState = states.find(
      (s) => s.id === storageDetails.stateStorage
    );
    if (!selectedWarehouseState) {
      selectedWarehouseState = null;
    }
  }
  let selectedWarehouseDistrict = null;
  if (storageDetails.districtStorage) {
    selectedWarehouseDistrict = states.find(
      (s) => s.id === storageDetails.districtStorage
    );
    if (!selectedWarehouseDistrict) {
      selectedWarehouseDistrict = null;
    }
  }
  console.log("Cureent details:", nomineedeatilsRow);
  console.log("VEHICLE details:", selectedRow.vehicleDetails);
  console.log(
    "selected row:",
    selectedCompanyState,
    selectedCompanyDistrict,
    selectedCompanyCity,
    selectedCompanyZone,
    selectedAreaDetailsState,
    selectedAreaDetailsDistrict,
    selectedWarehouseState,
    selectedWarehouseDistrict
  );

  return (
    <React.Fragment>
      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"1. " + LABELS.COMPANY_DEATAIL}
        </legend>
        <div className="flexColumn">
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"firmName"}
                label={LABELS.FIRM_NAME}
                defaultValue={companyDetails.compName || ""}
              />
            </div>
            <div className="formGroup width30 required">
              <label>
                {LABELS.AREA}
                <span>{" *"}</span>
              </label>
              <AutoComplete
                list={areas}
                childRef={areasRef}
                required={true}
                defaultValue={
                  selectedRow.areaId
                    ? {
                        id: selectedRow.areaId,
                        label: selectedRow.areaName,
                      }
                    : null
                }
              />
            </div>
            {/* <div className="formGroup width30">
              <TxtRow
                id={"area"}
                label={LABELS.AREA}
                defaultValue={selectedRow.area}
              />
            </div> */}
            <div className="formGroup width30">
              <TxtRow
                id={"regAddress"}
                label={LABELS.REG_ADDRESS}
                defaultValue={companyDetails.regAddress || ""}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.CITY}</label>
              <AutoComplete
                list={companyCity}
                childRef={companyCityRef}
                required={true}
                defaultValue={
                  //
                  selectedCompanyCity
                }
                // onChange={onStateChange}
                //disabled={true}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"taluka"}
                label={LABELS.TALUKA}
                defaultValue={companyDetails.compTaluka || ""}
              />
            </div>

            <div className="formGroup width30 ">
              <label>{LABELS.DISTRICT}</label>
              <AutoComplete
                list={companyDistrict}
                childRef={companyDistrictRef}
                required={true}
                defaultValue={selectedCompanyDistrict}
                onChange={onDistrictChange}
                //disabled={true}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.ZONE}</label>
              <AutoComplete
                list={companyZone}
                childRef={companyZoneRef}
                required={true}
                defaultValue={
                  // currentDetails.zoneId
                  //   ? {
                  //       id: currentDetails.zoneId,
                  //       label: currentDetails.zone,
                  //     }
                  //   : null
                  selectedCompanyZone
                }
                onChange={onStateChange}
                //disabled={true}
              />
            </div>

            <div className="formGroup width30 ">
              <label>{LABELS.STATE}</label>
              <AutoComplete
                list={states}
                childRef={companyStateRef}
                required={true}
                defaultValue={selectedCompanyState}
                onChange={onStateChange}
                //disabled={true}
              />
            </div>

            <div className="formGroup width30">
              <TxtRow
                id={"pinNo"}
                label={LABELS.PIN_NO}
                defaultValue={companyDetails.pinNo || ""}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30 required">
              <TxtRow
                id={"emailId"}
                label={LABELS.EMAIL_ID}
                defaultValue={companyDetails.email || ""}
                required={true}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"firmPAN"}
                label={LABELS.FIRM_PAN}
                defaultValue={companyDetails.firmPAN || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"religion"}
                label={LABELS.RELIGION}
                defaultValue={companyDetails.religion || ""}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"firmGST"}
                label={LABELS.FIRM_GST}
                defaultValue={companyDetails.firmGST || ""}
              />
            </div>
            <div className="formGroup width30 required">
              <TxtRow
                id={"fssiNo"}
                label={LABELS.FSSI_NO}
                defaultValue={companyDetails.fssiNo || ""}
                required={true}
              />
            </div>
            <div className="formGroup width30 required">
              <TxtRow
                id={"busStartDate"}
                label={LABELS.BUS_START_DATE}
                type="date"
                defaultValue={
                  companyDetails.busStartDate
                    ? DateFunctions.getFormatedDate(
                        companyDetails.busStartDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : null
                }
                required={true}
              />
            </div>
          </div>
          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.STATUS}</label>
              <RadioBtn
                list={[
                  {
                    id: "Proprietor",
                    value: "Proprietor",
                    label: LABELS.PROPRIETOR,
                    checked: companyDetails
                      ? companyDetails.firmType === "Proprietor"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "Partnership",
                    value: "Partnership",
                    label: LABELS.PARTNERSHIP,
                    checked: companyDetails
                      ? companyDetails.firmType === "Partnership"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "Pvt. Ltd",
                    value: "Pvt. Ltd",
                    label: LABELS.PVT_LTD,
                    checked: companyDetails
                      ? companyDetails.firmType === "Pvt. Ltd"
                      : false,
                    disabled: false,
                  },
                  {
                    id: "Ltd",
                    value: "Ltd.",
                    label: LABELS.LTD,
                    checked: companyDetails
                      ? companyDetails.firmType === "Ltd"
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"firmType"}
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">{"2. " + LABELS.CONTACT}</legend>
        <div className="flexRowAround">
          <div className="formGroup width30">
            <TxtRow
              id={"personName"}
              label={LABELS.CONTACT_PERSON_1_NAME}
              defaultValue={companyDetails.personName || ""}
            />
          </div>
          <div className="formGroup width30">
            <TxtRow
              id={"mobileNo1"}
              label={LABELS.CONTACT_PERSON_1_MOBILE}
              defaultValue={companyDetails.mobileNo1 || ""}
            />
          </div>
          <div className="formGroup width30"></div>
        </div>
        <div className="flexRowAround">
          <div className="formGroup width30">
            <TxtRow
              id={"personName2"}
              label={LABELS.CONTACT_PERSON_2_NAME}
              defaultValue={companyDetails.personName2 || ""}
            />
          </div>
          <div className="formGroup width30">
            <TxtRow
              id={"mobileNo2"}
              label={LABELS.CONTACT_PERSON_2_MOBILE}
              defaultValue={companyDetails.mobileNo2 || ""}
            />
          </div>
          <div className="formGroup width30"></div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"3. " + LABELS.AREA_DEATAIL}
        </legend>
        <div className="flexColumn">
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"areaVillage"}
                label={LABELS.AREA_VILLAGE}
                defaultValue={areaDetails.areaVillage || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"talukaArea"}
                label={LABELS.TALUKA}
                defaultValue={areaDetails.talukaArea || ""}
              />
            </div>

            <div className="formGroup width30 ">
              <label>{LABELS.DISTRICT}</label>
              <AutoComplete
                list={areaDetailsDistrict}
                childRef={areaDetailsDistrictRef}
                required={true}
                defaultValue={
                  // areaDetails.districtAreaId
                  //   ? {
                  //       id: areaDetails.districtAreaId,
                  //       label: areaDetails.districtArea,
                  //     }
                  //   : null
                  selectedAreaDetailsDistrict
                }
                //disabled={true}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.STATE}</label>
              <AutoComplete
                list={states}
                childRef={areaDetailsStateRef}
                required={true}
                defaultValue={
                  // areaDetails.stateAreaId
                  //   ? {
                  //       id: areaDetails.stateAreaId,
                  //       label: areaDetails.stateArea,
                  //     }
                  //   : null
                  selectedAreaDetailsState
                }
                onChange={onAreaStateChange}
                //disabled={true}
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"4. " + LABELS.PERSONAL_DEATIL}
        </legend>
        <div className="flexColumn">
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"name"}
                label={LABELS.NAME}
                disabled={true}
                defaultValue={
                  personalDetails.fName +
                    " " +
                    personalDetails.midName +
                    " " +
                    personalDetails.lName || ""
                }
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"mobileNo"}
                label={LABELS.PHONE}
                defaultValue={personalDetails.phone || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"homeAddress"}
                label={LABELS.HOME_ADDRESS}
                defaultValue={currentDetails.currentAdd || ""}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"birthDate"}
                label={LABELS.DATE_OF_BIRTH}
                defaultValue={personalDetails.dob || ""}
                type="date"
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"drivingLicense"}
                label={LABELS.DRIVING_LICENSE}
                defaultValue={personalDetails.drivingLicense || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"panNo"}
                label={LABELS.PAN_NUMBER}
                defaultValue={personalDetails.panNo || ""}
              />
            </div>
          </div>
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"aadharUIDNo"}
                label={LABELS.AADHAR_UID_NUMBER}
                defaultValue={personalDetails.aadharUIDNo || ""}
              />
            </div>
            <div className="formGroup width30"></div>
            <div className="formGroup width30"></div>
          </div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"5. " + LABELS.PARTNER_DETAIL}
        </legend>
        <div className="flexRowAround">
          <ExcelGrid
            height={600}
            childRef={partnerGridRef}
            cols={partnerColumns}
            rows={partnerRows}
            exportOption={false}
            newRowOption={false}
            getDefaultNewRow={getDefaultNewPartner}
          />
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"6. " + LABELS.WAREHOUSE_DETAIL}
        </legend>
        <div className="flexColumn">
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"sqFt"}
                label={LABELS.SQ_FT}
                defaultValue={storageDetails.godownSize || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"address"}
                label={LABELS.ADDRESS}
                defaultValue={storageDetails.address || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"talukaStorage"}
                label={LABELS.TALUKA}
                defaultValue={storageDetails.talukaStorage || ""}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30 ">
              <label>{LABELS.DISTRICT}</label>
              <AutoComplete
                list={warehouseDistrict}
                childRef={warehouseDistrictRef}
                required={true}
                defaultValue={
                  // storageDetails.districtStorageId
                  //   ? {
                  //       id: storageDetails.districtStorageId,
                  //       label: storageDetails.districtStorage,
                  //     }
                  //   : null
                  selectedWarehouseDistrict
                }

                //disabled={true}
              />
            </div>

            <div className="formGroup width30 ">
              <label>{LABELS.STATE}</label>
              <AutoComplete
                list={states}
                childRef={warehouseStateRef}
                required={true}
                defaultValue={
                  // storageDetails.stateStorageId
                  //   ? {
                  //       id: storageDetails.stateStorageId,
                  //       label: storageDetails.stateStorage,
                  //     }
                  //   : null
                  selectedWarehouseState
                }
                onChange={onWarehouseStateChange}
                //disabled={true}
              />
            </div>

            <div className="formGroup width30"></div>
          </div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"7. " + LABELS.BANK_DEATIL}
        </legend>
        <div className="flexColumn">
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"accName"}
                label={LABELS.ACCOUNT_NAME}
                defaultValue={bankDetails.accName || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"accNo"}
                label={LABELS.ACCOUNT_NUMBER}
                defaultValue={bankDetails.accNo || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"bankName"}
                label={LABELS.BANK_NAME}
                defaultValue={bankDetails.bankName || ""}
              />
            </div>
          </div>

          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"branch"}
                label={LABELS.BRANCH}
                defaultValue={bankDetails.branch || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"typeOFAcc"}
                label={LABELS.TYPE_OF_ACCOUNT}
                defaultValue={bankDetails.typeOFAcc || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"ifscNo"}
                label={LABELS.IFSC_NO}
                defaultValue={bankDetails.ifscNo || ""}
              />
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"8. " + LABELS.REFERENCE}
        </legend>
        <div className="flexRowAround">
          <div className="formGroup width30">
            <TxtRow
              id={"appointedBy"}
              label={LABELS.APPOINTED_BY}
              defaultValue={referenceDetails.appointedBy || ""}
            />
          </div>
          <div className="formGroup width30"></div>
          <div className="formGroup width30"></div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"9. " + LABELS.DEPOSIT_DETAIL}
        </legend>
        <div className="flexColumn">
          <div className="flexRowAround">
            <div className="formGroup width30">
              <TxtRow
                id={"amount"}
                label={LABELS.AMOUNT}
                defaultValue={depositDetails.amount || ""}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"depositeDate"}
                label={LABELS.DEPOSIT_DATE}
                type="date"
                defaultValue={
                  depositDetails.depositeDate
                    ? DateFunctions.getFormatedDate(
                        depositDetails.depositeDate,
                        FORMATS.SQL_DATE,
                        FORMATS.SQL_DATE_ONLY
                      )
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
              />
            </div>
            <div className="formGroup width30">
              <label>
                {LABELS.FILE_UPLOAD}
                <small>
                  {" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}
                </small>
              </label>
              <TxtBox
                type={"file"}
                id="uploadFile"
                // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
                // className="uploadFile"
                accept="image/*,.pdf"
                disabled={isButtonDisabled}
                onChange={onUploadFile}
              />
              <div
                className="formGroup"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {qualifiedFiles &&
                  qualifiedFiles.map((u, i) => {
                    return (
                      <div
                        className="thumbnailBox width20"
                        key={"storefile-" + i}
                      >
                        <IconButton
                          disabled={isButtonDisabled}
                          className="thumbnailBoxIcon"
                          onClick={() => {
                            const list = qualifiedFiles.filter(
                              (a, j) => j !== i
                            );
                            setQualifiedFiles(list);
                          }}
                        >
                          <IconClose />
                        </IconButton>
                        <img
                          onClick={() =>
                            window.open(
                              API_CONFIG.STATIC_URL + u.path,
                              "_blank"
                            )
                          }
                          src={
                            u.mimeType === "pdf"
                              ? thumbnailImg
                              : API_CONFIG.STATIC_URL + u.path
                          }
                          className="responsive cursor"
                        />
                      </div>
                    );
                  })}
                {uploading && (
                  <div className="thumbnailBox width20">
                    <SkeletonBox height={150} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"10. " + LABELS.OTHER_FAMILY}
        </legend>
        <div className="flexRowAround">
          <ExcelGrid
            height={600}
            childRef={familyGridRef}
            cols={familydeatilsColumn}
            rows={familyDetailsRow}
            exportOption={false}
            newRowOption={false}
            getDefaultNewRow={getDefaultFamilyNewRow}
          />
        </div>
      </fieldset>

      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"11. " + LABELS.NOMINEE_DETAILS}
        </legend>
        <div className="flexRowAround">
          <ExcelGrid
            height={600}
            childRef={nomineeGridRef}
            cols={nomineedeatilsColumn}
            rows={nomineedeatilsRow}
            exportOption={false}
            newRowOption={false}
            getDefaultNewRow={getDefaultNomineeNewRow}
          />
        </div>
      </fieldset>

      {/* vehicle details */}
      <fieldset className="formSection">
        <legend className="formSectionLegend">
          {"12. " + LABELS.VEHICAL_DETAILS}
        </legend>
        <div className="flexRowAround">
          <ExcelGrid
            height={600}
            childRef={vehicleGridRef}
            cols={vehicalDetailsCols}
            rows={vehicalDetailRows}
            exportOption={false}
            newRowOption={false}
            //onAdd={onAdd}
            getDefaultNewRow={getDefaultVehicleNewRow}
          />
        </div>
      </fieldset>

      <div className="formGroup width60">
        <TxtRow
          id={"remarks"}
          type={"text"}
          label={LABELS.REMARKS}
          defaultValue={selectedRemarks.qualified || " "}
          //disabled={isSubmit}
          rows={4}
        />
      </div>
      <div className="formGroup submitBtnWrapper">
        <div className=" flexRowAround">
          <Btn
            text={LABELS.SAVE}
            onClick={manageLeadProfile}
            bgColor={COLORS.SAVE_GREEN}
            disabled={isButtonDisabled}
          />
          {/* <Btn text={LABELS.CANCEL} bgColor={COLORS.DIM_DARK_GRAY} /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(Qualified);
