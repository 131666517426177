import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit, Close as IconClose } from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS, CONFIG, API_CONFIG } from "common/config";
import { LABELS, WARNINGS, TITLES, SUCCESS, ERRORS } from "common/lables";
import { COMMON_JS } from "common/scripts";
import TxtRow from "components/Custom/TxtRow";

import { GEO_APIs } from "../../../apihandlers/geography";
import { MASTER_APIs } from "apihandlers/master";
import { AUTH_APIs } from "apihandlers/auth";
import SkeletonBox from "components/Custom/SkeletonBox";
import "./styles/users.css";
import { USER_APIs } from "apihandlers/user";

const thumbnailImg = require("assets/images/pdf.png");
const video = require("assets/images/video.png");

const TrainingAssign = (props: any) => {
  const { setDashMenu } = props;

  const userRef: any = useRef(null);
  const trainingRef: any = useRef(null);

  const [trainings, setTrainings] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [users, setUsers] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [trainingFiles, setTrainingFiles] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getAssigned();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getTrainingAssigns(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getTrainingAssigns:", error);
    }
  };

  const getAssigned = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.userName,
          };
        });
        setUsers(list);
        getTtraining();
      }
    });
  };

  const getTtraining = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      activeStatus: true,
    };
    MASTER_APIs.getTrainings(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.trainingCategory,
          };
        });
        setTrainings(list);
        getRows();
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { key: "assignedToUser", name: "Assign To", width: "25%" },
    { key: "trainingId", name: "Training Id", width: "25%" },
    {
      key: "assignedDate",
      type: "text",
      name: "Assigned On",
      width: "15%",
    },
    {
      key: "completionDate",
      type: "text",
      name: "Completion Date",
      width: "15%",
    },
    { key: "activeStatusText", name: LABELS.ACTIVE, width: "10%" },

    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageTraining = () => {
    const trainingId = trainingRef.current.selectedValue.id;
    const assignedToId = userRef.current.selectedValue;
    const assignedDate = (
      document.getElementById("assignedDate") as HTMLInputElement
    ).value.trim();
    const completionDate = (
      document.getElementById("completionDate") as HTMLInputElement
    ).value.trim();
    const comments = (
      document.getElementById("comments") as HTMLInputElement
    ).value.trim();
    const active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";
    // Validations
    // if (!cityName) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!regionId) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!shopCount) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!totalPopulation) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }
    // if (!selectBtnValue) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.PLEASE_FILL_MANDATORY_FIELDS
    //   );
    //   return;
    // }

    const args = {
      id: selectedRow.id || -1,
      assignedToId: assignedToId[0].id,
      assignedDate,
      completionDate,
      comments,
      trainingId,
      activeStatus: selectBtnValue.toString(),
    };
    console.log("args", args);
    setIsSubmit(true);
    MASTER_APIs.manageTrainingAssign(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>
            {LABELS.TRAINING_ASSIGN}{" "}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TRAINING_ASSIGN}</li>
        </ul>
        <h4>{TITLES.TRAINING_ASSIGN}</h4>
        {/* <p>{SUB_TITLES.TRAINING_ASSIGN}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.TRAINING_ASSIGN}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                LABELS.TRAINING_ASSIGN +
                ": " +
                selectedRow.trainingCategory
              : "Add New " + LABELS.TRAINING_ASSIGN
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label>
              {"Assign To"}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={users}
              childRef={userRef}
              multiple={true}
              defaultValue={
                selectedRow.id
                  ? [
                      {
                        id: selectedRow.assignedToId,
                        label: selectedRow.assignedToUser,
                      },
                    ]
                  : []
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {"Training"}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={trainings}
              childRef={trainingRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.trainingId,
                      label: selectedRow.trainingId,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"assignedDate"}
              type={"date"}
              label={"Assigned Date"}
              required={true}
              defaultValue={selectedRow.assignedDate}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"completionDate"}
              type={"date"}
              label={"Completion Date"}
              required={true}
              defaultValue={selectedRow.completionDate}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"comments"}
              type={"text"}
              label={"comments"}
              required={true}
              defaultValue={selectedRow.comments}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {LABELS.STATUS} {<span className="red">*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>

          <div className="formGroup submitBtnWrapper">
            <div className="width45 flexRowJustify">
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageTraining}
              />
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(TrainingAssign);
