import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import LeftBar from "components/Layouts/LeftBar";

import Reports from "components/Reports";
import Masters from "components/Masters";
import Settings from "components/Settings";
import HomeSkeleton from "./HomeSkeleton";
import Orders from "components/Orders";

// Masters
import Training from "../Training";
import Designations from "components/Masters/Designations";
import Holidays from "components/Masters/Holidays";
import Employees from "components/Masters/Employees";
import Projects from "components/Masters/Projects";
import Users from "components/Masters/Users/Users";
import Price from "components/Masters/Price";
import Scheme from "components/Masters/Scheme";

import LeavePlanner from "components/LeavePlanner";
import LeavePlannerApprove from "components/LeavePlannerApprove";
import OrdersLifecycle from "components/Orders/OrdersLifecycle";
import CRMLifecycle from "components/Crm/CRMLifecycle";
// import Roi from "../Masters/ROI/roi";
import LeadProfile from "components/Crm/LeadProfile";
import Feedback from "components/Crm/Feedback";
import Survey from "components/Crm/Survey";
import CrmDashboard from "components/Crm/CrmDashboard";
import ROI from "components/ROI";
import RoiDashboard from "components/ROI/RoiDashboard";

// import MyMap from "components/MyMap";
import CrmReports from "components/Crm/CrmReports";
import Instructions from "components/Crm/Instructions";
import Customer from "components/Masters/Customer";
import CustomersOnMap from "components/CustomersOnMap";
import SalesOrder from "components/Sales/SalesOrder";
import SalesInvoice from "components/Sales/SalesInvoice";
import AddSalesInvoice from "components/Sales/AddSalesInvoice";
import SalesReturn from "components/Sales/SalesReturn";
import EInvoice from "components/Sales/EInvoice";
import AddPurchaseBill from "components/Purchase/AddPurchaseBill";
import PurchaseBill from "components/Purchase/PurchaseBill";
import PurchaseReturn from "components/Purchase/PurchaseReturn";
import PhysicalVoucher from "components/PhysicalVoucher";
import PhysicalVoucherApproval from "components/PhysicalVoucherApproval";
import POSReport from "components/POSReport";
import DMSReport from "components/DMSReport";
import GraphDashboard from "components/GraphDashboard";

import { CONFIG, LOCAL_STORAGE } from "common/config";
import { LABELS, TITLES } from "common/lables";
import { AUTH_APIs } from "apihandlers/auth";

import "./styles/home.css";

const imgLogo = require("assets/images/logo.png");

const HomePage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<any>({});
  const [page, setPage] = useState(LABELS.HOME);

  const leftBarRef: any = useRef(null);

  useEffect(() => {
    // console.log("Home: userEffect: state: ", loading);
    if (loading) {
      checkLogin();
    }
  }, [loading]);

  const checkLogin = () => {
    const args = {};
    AUTH_APIs.checklogin(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      // console.log("checklogin: ", res);
      if (status === 200) {
        if (response.status) {
          let userInfo = localStorage.getItem(LOCAL_STORAGE.USER_INFO);
          if (userInfo) {
            userInfo = JSON.parse(userInfo);
            setUserInfo(userInfo);
          }
          setLoading(false);
          setPage(LABELS.HOME);
        } else {
          window.open("/login", "_self");
        }
      }
    });
  };

  const reportsRef: any = useRef({});
  const posReportsRef: any = useRef({});
  const dmsReportsRef: any = useRef({});

  const mastersRef: any = useRef({});
  const trainingRef: any = useRef({});
  const ordersRef: any = useRef({});
  const roidashboardref: any = useRef({});
  const graphdashboardref: any = useRef({});
  // const approvalRef: any = useRef({});

  const onSetPage = (val = "") => {
    console.log("onSetPage", val);
    setPage(val);
  };

  console.log("home: userInfo:", userInfo);

  return (
    <div
      className={"homeContainer " + (page === LABELS.HOME ? "bgBanner" : "")}
      style={{
        overflow: page === LABELS.GANTT_CHART ? "none" : "auto",
      }}
    >
      {loading ? (
        <HomeSkeleton />
      ) : (
        <React.Fragment>
          <LeftBar
            setPage={onSetPage}
            page={page}
            userInfo={userInfo}
            childRef={leftBarRef}
          />
          <div className="content">
            {page === LABELS.HOME && (
              <div className="welcomeBox">
                <div
                  style={{ marginRight: 10, width: 150 }}
                  className="flexRowCenter"
                >
                  <img src={imgLogo} height={100} />
                </div>
                <div className="flexColumn">
                  <h4
                    className="shadowTxt fontXL bold"
                    style={{
                      marginTop: 18,
                      textAlign: "left",
                    }}
                  >
                    {CONFIG.SITE_NAME}
                  </h4>
                  <h4
                    className="shadowTxt fontM semiBold"
                    style={{
                      marginTop: 0,
                      textAlign: "left",
                      paddingTop: 6,
                      paddingLeft: 2,
                    }}
                  >
                    Welcome, {userInfo.role && userInfo.role.name + " !"}
                  </h4>
                </div>
              </div>
            )}

            {page === LABELS.REPORTS && (
              <Reports childRef={reportsRef} userInfo={userInfo} />
            )}

            {page === LABELS.MASTERS && (
              <Masters
                childRef={mastersRef}
                userInfo={userInfo}
                setPageMenu={setPage}
              />
            )}

            {page === LABELS.CUSTOMERS && (
              <Customer
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === LABELS.ORDERS && (
              <Orders
                childRef={ordersRef}
                userInfo={userInfo}
                setPageMenu={setPage}
              />
            )}

            {page === TITLES.ORDERS_LIFECYCLE && (
              <OrdersLifecycle
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === LABELS.TRAINING && (
              <Training
                childRef={trainingRef}
                userInfo={userInfo}
                setPageMenu={setPage}
              />
            )}

            {/* {page === LABELS.EXPENSES && (
              <Expenses userInfo={userInfo} setPageMenu={setPage} />
            )}
            
            {page === LABELS.LEAVES && (
              <Leaves userInfo={userInfo} setPageMenu={setPage} />
            )} */}

            {page === LABELS.USERS && <Users />}

            {page === LABELS.SETTINGS && (
              <Settings userInfo={userInfo} setPageMenu={setPage} />
            )}

            {page === LABELS.DESIGNATION_MASTER && (
              <Designations userInfo={userInfo} />
            )}

            {page === LABELS.HOLIDAY_MASTER && <Holidays userInfo={userInfo} />}

            {page === LABELS.EMPLOYEE_MASTER && (
              <Employees userInfo={userInfo} />
            )}

            {page === LABELS.PRICE && <Price userInfo={userInfo} />}

            {page === LABELS.SCHEME && <Scheme userInfo={userInfo} />}

            {page === LABELS.PROJECTS && <Projects userInfo={userInfo} />}

            {page === LABELS.LEAVE_PLANNER && (
              <LeavePlanner userInfo={userInfo} />
            )}

            {page === TITLES.SALES_ORDER && (
              <SalesOrder
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.ADD_SALES_INVOICE && (
              <AddSalesInvoice
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.SALES_INVOICE && (
              <SalesInvoice
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.SALES_RETURN && (
              <SalesReturn
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.E_INVOICE && (
              <EInvoice
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.ADD_PURCHASE_BILL && (
              <AddPurchaseBill
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.PURCHASE_BILL && (
              <PurchaseBill
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.PURCHASE_RETURN && (
              <PurchaseReturn
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.PHYSICAL_VOUCHER && (
              <PhysicalVoucher
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === LABELS.PHYSICAL_VOUCHER_APPROVAL && (
              <PhysicalVoucherApproval
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === LABELS.LEAVE_PLANNER_APPROVE && (
              <LeavePlannerApprove userInfo={userInfo} />
            )}

            {page === LABELS.LEAD_PROFILE && (
              <LeadProfile
                setPageMenu={setPage}
                header={true}
                // setDashMenu={(menu) => {
                //   setPage(menu);
                //   leftBarRef.hideCrmMenu();
                // }}
              />
            )}

            {page === TITLES.FEEDBACK && (
              <Feedback
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === TITLES.SURVEY && (
              <Survey
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === TITLES.CRM_DASHBOARD && (
              <CrmDashboard
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === TITLES.ROI && (
              <ROI
                setPageMenu={setPage}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === TITLES.CRM_REPORTS && (
              <CrmReports
                setPageMenu={setPage}
                userInfo={userInfo}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === TITLES.INSTRUCTIONS && (
              <Instructions
                setPageMenu={setPage}
                userInfo={userInfo}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {page === TITLES.CRM_LIFECYCLE && (
              <CRMLifecycle
                setPageMenu={setPage}
                userInfo={userInfo}
                setDashMenu={(menu) => {
                  setPage(menu);
                  leftBarRef.hideCrmMenu();
                }}
              />
            )}

            {/* {page === LABELS.ROI && <Roi />} */}

            {page === TITLES.CUSTOMERS_ON_MAP && (
              <CustomersOnMap
                setPageMenu={setPage}
                userInfo={userInfo}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}
            {/* {page === TITLES.MY_MAP && (
              <MyMap
                setPageMenu={setPage}
                userInfo={userInfo}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )} */}

            {page === TITLES.POS_REPORT && (
              <POSReport
                childRef={posReportsRef}
                setPageMenu={setPage}
                userInfo={userInfo}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.DMS_REPORT && (
              <DMSReport
                childRef={dmsReportsRef}
                setPageMenu={setPage}
                userInfo={userInfo}
                setDashMenu={(menu) => {
                  setPage(menu);
                }}
              />
            )}

            {page === TITLES.ROI_DASHBOARD && <RoiDashboard />}
            {page === TITLES.GRAPH_DASHBOARD && <GraphDashboard />}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withSnackbar(HomePage);
