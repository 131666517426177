import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7005";

export const PRINT_APIs = {
  printDeliveryChallan: async (args = {}) => {
    let apiUrl = "/pn/v1/6AY6HE7YWD955BQS";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      true,
      local
    );
    return response;
  },

  printLead: async (args = {}) => {
    let apiUrl = "/cu/v1/YQNPODEUFB5314TL";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      true,
      local
    );
    return response;
  },

  printLeadQualify: async (args = {}) => {
    let apiUrl = "/cu/v1/364TNQOZWLJCYU0P";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      true,
      local
    );
    return response;
  },
};
