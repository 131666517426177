import React from "react";
import { withSnackbar } from "notistack";

import { LABELS, TITLES } from "../../../common/lables";

const OrdersLifecycle = (props: any) => {
  const { setDashMenu } = props;

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.ORDERS_LIFECYCLE}</li>
        </ul>
        <h4>{TITLES.ORDERS_LIFECYCLE}</h4>
        {/* <p>{SUB_TITLES.SURVEY}</p> */}
      </div>
      <div className="ordersLifecycle">
        <img
          src="https://kissflow.com/hubfs/Lifecycle-of-a-PO.jpg"
          alt="Orders Lifecycle"
        ></img>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(OrdersLifecycle);
