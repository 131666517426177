import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import { API_CONFIG, NOTIFY, FORMATS } from "common/config";
import { LABELS, WARNINGS, TITLES, SUB_TITLES } from "common/lables";
import { DateFunctions } from "common//datefunctions";
import { COMMON_JS } from "common/scripts";

import { REPORTS_APIs } from "apihandlers/reports";
import { CUSTOMER_APIs } from "apihandlers/customer";
import AutoComplete from "components/Custom/AutoComplete";
import { GEO_APIs } from "apihandlers/geography";

const CustomerList = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(false);
  const [masters, setMasters] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const [customerPhoto, setCustomerPhoto] = useState<any>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "name",
      name: LABELS.NAME,
      width: "30%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "incharge",
      name: LABELS.INCHARGE,
      width: "30%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      key: "mobileNumber",
      name: LABELS.CONTACT_NUMBER,
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   key: "address1",
    //   name: LABELS.ADDRESS_1,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      key: "pan",
      name: LABELS.REMARK,
      width: "20%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   key: "areaName",
    //   name: LABELS.AREA_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "cityName",
    //   name: LABELS.CITY_NAME,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "location",
    //   name: LABELS.LOCATION,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "longitude",
    //   name: LABELS.LONGITUDE,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "createdUser",
    //   name: LABELS.CREATED_USER,
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "signature",
    //   name: LABELS.SIGNATURE,
    //   width: "15%",
    //   type: "html",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   key: "profile",
    //   name: LABELS.PROFILE,
    //   width: "15%",
    //   type: "html",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },
  ];

  // useEffect(() => {
  //   getCustomerPhoto();
  // }, []);

  const getCustomerPhoto = () => {
    const args = {
      customerId: 89,
    };
    CUSTOMER_APIs.getCustomerPhotos(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;

        setCustomerPhoto(list);
      }
    });
  };
  const [areas, setAreas] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [exportName, setExportName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [routeName, setRouteName] = useState("");

  const cityRef: any = useRef(null);
  const areaRef: any = useRef(null);

  useEffect(() => {
    getCity();
  }, []);

  const getCity = () => {
    const args = {
      activeStatus: true,
      minResponse: true,
    };
    GEO_APIs.getCities(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.cityName,
          };
        });
        setCities(list);
      }
    });
  };

  const getAreas = async (cityId) => {
    let areaList = [];
    try {
      const args = {
        cityId,
        activeStatus: "true",
      };
      const res: any = await GEO_APIs.getAreas(args);
      areaList = res.response.records.map((r) => {
        return {
          id: r.id,
          label: r.areaName,
        };
      });
    } catch (error) {}
    return areaList;
  };

  const onCityChange = async (e, f) => {
    if (f.id) {
      const res = await getAreas(f.id);
      setAreas(res);
    }
  };

  const loadChart = () => {
    const areaId = areaRef.current.selectedValue.id;
    const cityId = cityRef.current.selectedValue.id;

    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const toDt = (document.getElementById("toDt") as HTMLInputElement).value;

    setAreaName(
      cityRef.current.selectedValue.label === undefined
        ? "ALL"
        : cityRef.current.selectedValue.label
    );
    setRouteName(
      areaRef.current.selectedValue.label === undefined
        ? "ALL"
        : areaRef.current.selectedValue.label
    );

    setExportName(
      LABELS.CUSTOMER_LIST +
        "\n" +
        LABELS.AREA_LABLE +
        ":" +
        cityRef.current.selectedValue.label +
        "                     " +
        LABELS.ROUTE +
        ":" +
        areaRef.current.selectedValue.label +
        "\n"
      // +
      // LABELS.FROM_DATE +
      // ":" +
      // fromDt +
      // "\n" +
      // LABELS.TO_DATE +
      // ":" +
      // toDt
    );

    const args = {
      areaId,
      cityId,
      fromDt,
      toDt,
      reportType: "excel",
    };
    let errors = [];
    if (fromDt == "") {
      errors.push({
        id: "fromDt",
        msg:
          "<span class='bold'>" +
          LABELS.FROM_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (toDt == "") {
      errors.push({
        id: "toDt",
        msg:
          "<span class='bold'>" +
          LABELS.TO_DATE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    setRows(null);
    setLoading(true);
    REPORTS_APIs.getAllCustomers(args).then((res) => {
      // console.log("getUserLogReport:", args);
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          let fileUrl = API_CONFIG.STATIC_URL + list[i].signaturePath;

          list[i].signature = (
            <div>
              <img
                src={fileUrl}
                alt=""
                width={100}
                height={50}
                onClick={() => {
                  window.open(fileUrl, "_blank");
                }}
              />
            </div>
          );
          list[i].location = (
            <div>
              <a
                className="textShadowBlack"
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  list[i].latitude +
                  "," +
                  list[i].longitude
                }
                target="_new"
              >
                {LABELS.VIEW_LOCATION}
              </a>
            </div>
          );
        }
        setRows(list);
        setLoading(false);
      }
    });

    // const custArgs = {
    //   customerId: 89,
    // };
    // CUSTOMER_APIs.getCustomerPhotos(custArgs).then((res) => {
    //   // console.log("getUserLogReport:", args);
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     let list2 = response.records;
    //     for (let i = 0; i < list2.length; i++) {
    //       list2[i]["slNo"] = i + 1;
    //       let fileUrl = API_CONFIG.STATIC_URL + list2[i].path;

    //       list2[i].profile = (
    //         <div>
    //           <img
    //             src={fileUrl}
    //             alt=""
    //             width={100}
    //             height={50}
    //             onClick={() => {
    //               window.open(fileUrl, "_blank");
    //             }}
    //           />
    //         </div>
    //       );
    //     }
    //     setCustomerPhoto(list2);
    //   }
    // });
  };

  const reloadGrid = () => {
    setRows(null);
    setAreaName(
      cityRef.current.selectedValue.label === undefined
        ? "ALL"
        : cityRef.current.selectedValue.label
    );
    setRouteName(
      areaRef.current.selectedValue.label === undefined
        ? "ALL"
        : areaRef.current.selectedValue.label
    );
    loadChart();
  };

  const outletRef: any = useRef();

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {TITLES.REPORTS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.CUSTOMER_LIST}</li>
        </ul>
        <h4>{TITLES.CUSTOMER_LIST}</h4>
        <p>{SUB_TITLES.CUSTOMER_LIST}</p>
      </div>

      <div className="hChart">
        <div className=" flexRowJustify width50 ">
          <div className="formGroup width45 ">
            <label>{LABELS.AREA_LABLE}</label>

            <AutoComplete
              id={"city"}
              list={cities}
              childRef={cityRef}
              onChange={onCityChange}
            />
          </div>
          <div className="formGroup width45 ">
            <label>{LABELS.ROUTE}</label>

            <AutoComplete id={"area"} list={areas} childRef={areaRef} />
          </div>
        </div>

        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {/* <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 5,
                marginBottom: 10,
                width: "40%",
              }}
            >
              <label>{LABELS.SELECT_OUTLET + " :"}</label>
              <AutoComplete
                id={"masters"}
                list={masters}
                childRef={outletRef}
                defaultValue={{ id: -1, label: "All" }}
              />
            </div> */}
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 0,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.FROM_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="fromDt"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginRight: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 6,
                  marginRight: 8,
                }}
              >
                {LABELS.TO_DATE}
              </label>
              <TxtBox
                type={"date"}
                id="toDt"
                defaultValue={DateFunctions.getDate(
                  "thismonth",
                  "to",
                  "YYYY-MM-DD"
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <Btn text={LABELS.LOAD} onClick={loadChart} />
            </div>
          </div>
        </div>
        {mandatoryResults.length > 0 && (
          <div className="mandatoryBox">
            <ul>
              {mandatoryResults.map((m) => {
                return (
                  <li>
                    <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="grid excelGridWrapper">
          {loading ? (
            <div className="flexRowCenter" style={{ minHeight: 450 }}>
              <Loader />
            </div>
          ) : (
            <ExcelGrid
              id={LABELS.CUSTOMER_LIST}
              height={window.innerHeight * 0.8}
              cols={columns}
              rows={rows}
              exportOption={true}
              newRowOption={false}
              exportName={LABELS.CUSTOMER_LIST}
              exportDetails={""}
              exportSplitColumn={
                LABELS.CUSTOMER_LIST +
                  "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                  LABELS.PRINT_DATE +
                  ": " +
                  DateFunctions.getFormatedDate(
                    new Date(),
                    FORMATS.SQL_DATE,
                    FORMATS.PRINT_DATE
                  ) +
                  "\n" +
                  LABELS.AREA_LABLE +
                  ": " +
                  areaName +
                  "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                  LABELS.ROUTE +
                  ": " +
                  routeName || " "
              }
              exportSplitColumnPrefix={
                LABELS.CUSTOMER_LIST +
                  "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                  LABELS.PRINT_DATE +
                  ": " +
                  DateFunctions.getFormatedDate(
                    new Date(),
                    FORMATS.SQL_DATE,
                    FORMATS.PRINT_DATE
                  ) +
                  "\n" +
                  LABELS.AREA_LABLE +
                  ": " +
                  areaName +
                  "&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;" +
                  LABELS.ROUTE +
                  ": " +
                  routeName || " "
              }
              splitColumnValues={[""]} // Send only If you want to display two or more values
              onReload={reloadGrid}
              title={LABELS.CUSTOMER_LIST}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(CustomerList);
