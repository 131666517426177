import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";


export const CRM_REPORTS_APIs = {
  getAllLeads: async (args = {}) => {
    let apiUrl = "/re/v1/CWOZBRFRWPRD0587";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/CWOZBRFRWPRD0587";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getLeadAssignments: async (args = {}) => {
    let apiUrl = "/re/v1/CLC42AYJY3CQIN21";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/CLC42AYJY3CQIN21";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getLeadVisits: async (args = {}) => {
    let apiUrl = "/re/v1/VES601F64Y87V8NJ";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/VES601F64Y87V8NJ";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getDocumentList: async (args = {}) => {
    let apiUrl = "/re/v1/BZTZDMCWIYX60GY3";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/BZTZDMCWIYX60GY3";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getApprovalList: async (args = {}) => {
    let apiUrl = "/re/v1/37D06E5RFZ30697Q";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/37D06E5RFZ30697Q";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  /**
   * Training
   **/
  getTrainingFiles: async (args = {}) => {
    let apiUrl = "/re/v1/XRMGDFUWEKHQ25TA";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/XRMGDFUWEKHQ25TA";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getTrainingAssignment: async (args = {}) => {
    let apiUrl = "/re/v1/78MC5QSIC9XTMS9E";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/78MC5QSIC9XTMS9E";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getTrainingStatus: async (args = {}) => {
    let apiUrl = "/re/v1/EHM3VXZP9FLLBWHZ";
    if (PRODUCTION === "false") {
      apiUrl = "http://192.168.0.115:7008/re/v1/EHM3VXZP9FLLBWHZ";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
};
