import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  PictureAsPdf as IconPdf,
  Article as IconFile,
  Comment as IconEye,
  AudioFile as IconVoice,
} from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";
import { REPORTS_APIs } from "apihandlers/reports";
import { NOTIFY, DEFAULTS, CONFIG, API_CONFIG } from "common/config";
import { COLORS } from "common/colors";
import { CONFIRMS, LABELS, SUCCESS, ERRORS, WARNINGS } from "common/lables";

import { COMMON_JS } from "common/scripts";

let _timer = null;

const CustomerOrders = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);

  const [fileId, setFileId] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
    };
    REPORTS_APIs.getCustomerOrders(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setRows(response);
        setFilteredRows([]);
        setLoading(false);
      }
    });
  };

  const onEditRow = (val: any) => {
    setSelectedRow(val);
    setManageDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "customerName", headerName: "Customer", width: 300 },
    { field: "orderDate", headerName: "Date", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "total", headerName: "Total", width: 150 },
    {
      field: "gpt",
      headerName: "GPT",
      width: 150,
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.VIEW} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEye />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "photos",
      headerName: "Photos",
      width: 150,
      renderCell: (params: any) => {
        return (
          <div
            className="photos"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {params.row.photos.map((photo: any) => {
              return (
                <div
                  style={{ width: 50, marginRight: 10, cursor: "pointer" }}
                  onClick={() => {
                    window.open(API_CONFIG.API_URL + photo.path, "_blank");
                  }}
                >
                  <img
                    src={API_CONFIG.API_URL + photo.path}
                    style={{ width: "100%" }}
                  />
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "voice",
      headerName: "Voice",
      width: 150,
      renderCell: (params: any) => {
        return (
          <div
            className="photos"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {params.row.voice.map((v: any) => {
              return (
                <div style={{ width: 50, marginRight: 10, cursor: "pointer" }}>
                  <IconButton
                    onClick={() => {
                      window.open(API_CONFIG.API_URL + v, "_blank");
                    }}
                  >
                    <IconVoice />
                  </IconButton>
                </div>
              );
            })}
          </div>
        );
      },
    },
    { field: "count", headerName: "Display Count", width: 150 },
  ];

  const manageRecord = () => {
    const name = (
      document.getElementById("name") as HTMLInputElement
    ).value.trim();
    const activeStatus = (
      document.getElementById("isActive") as HTMLInputElement
    ).checked;

    // Validations
    if (!name) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.DESIGNATION_NAME_EMPTY
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      name,
      activeStatus: activeStatus.toString(),
    };
    // ApiMastersHandler.manageVehicleType(args).then((res) => {
    //   if (res.status === 200) {
    //     COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
    //     setSelectedRow({});
    //     setManageDialog(false);
    //     reloadGrid();
    //   } else {
    //     COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
    //   }
    // });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.name.toUpperCase().indexOf(qry) > -1;
      });
      // console.log("flist: ", rows, flist, qry);
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const orgRef: any = useRef();
  const userRef: any = useRef();
  const frequencyRef: any = useRef();

  console.log("GPT: ", selectedRow);

  const { setDashMenu } = props;

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="" onClick={() => setDashMenu(LABELS.HOME)}>
            {LABELS.REPORTS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.CUSTOMER_ORDERS}</li>
        </ul>
        <h4>{LABELS.CUSTOMER_ORDERS}</h4>
        {/* <p>{SUB_TITLES.USERS}</p> */}
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          {/* <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
            }}
          >
            <IconAdd />
          </IconButton> */}
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={
              !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
            }
            columns={columns}
            rowHeight={50}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={"GPT Chat - " + selectedRow.customerName}
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          {selectedRow.gpt.map((gpt: any) => {
            return (
              <div className="chat">
                <h4>{gpt.question}</h4>
                <p>{gpt.logtime}</p>
              </div>
            );
          })}
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(CustomerOrders);
