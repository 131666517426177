// * Note: Please collect location info & logtime of each and every API Requests

// import axios from 'axios';

import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7005";

export const CUSTOMER_APIs = {
  getAllCustomersOnMap: async (args = {}) => {
    let apiUrl = "/cu/v1/2BOV0OGD1GXFQLVZ";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/2BOV0OGD1GXFQLVZ";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getStockLevels: async (args = {}) => {
    let apiUrl = "/cu/v1/LPA5JWVBGNZ4WD2L";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/LPA5JWVBGNZ4WD2L";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  manageStockLevel: async (args = {}) => {
    let apiUrl = "/cu/v1/1VQYIF0D5MZFM07G";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/1VQYIF0D5MZFM07G";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCustSalesTypes: async (args = {}) => {
    let apiUrl = "/cu/v1/8W8JDX0YOUSXO2MC";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/8W8JDX0YOUSXO2MC";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  manageCustSalesType: async (args = {}) => {
    let apiUrl = "/cu/v1/DNN10S65USCY76RC";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/DNN10S65USCY76RC";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCrmApprovals: async (args = {}) => {
    let apiUrl = "/cu/v1/48GR8BYQUY4SOU02";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/48GR8BYQUY4SOU02";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageCrmApproval: async (args = {}) => {
    let apiUrl = "/cu/v1/Q9VSYVMXTX7UIBJ1";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/Q9VSYVMXTX7UIBJ1";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCrmDocuments: async (args = {}) => {
    let apiUrl = "/cu/v1/R4W8OQMOLYH02ACT";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/R4W8OQMOLYH02ACT";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  manageCrmDocument: async (args = {}) => {
    let apiUrl = "/cu/v1/OH3YJRLEQG4GRNHA";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/OH3YJRLEQG4GRNHA";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getFeedbacks: async (args = {}) => {
    let apiUrl = "/cu/v1/WPESLNMBE8YJILXN";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/WPESLNMBE8YJILXN";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  manageFeedback: async (args = {}) => {
    let apiUrl = "/cu/v1/6SL9U5KUWWZENBMY";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/6SL9U5KUWWZENBMY";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCrmLeadAssigned: async (args = {}) => {
    let apiUrl = "/cu/v1/WGFY2L2O2IOFE382";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/WGFY2L2O2IOFE382";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageLeadAssigned: async (args = {}) => {
    let apiUrl = "/cu/v1/43XNE0WGZ4QUZUVV";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/43XNE0WGZ4QUZUVV";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCrmLeadProfiles: async (args = {}) => {
    let apiUrl = "/cu/v1/054XKXP835731FWM";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getCustomerOnMap: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/438Z5618L74978RF",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  removeCustomerPhoto: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/458S9085E53540GY",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  getCustomerPhotos: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/991M9250J22211EP",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // To create/update customer
  manageCustomerProfile: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/709S5073F13336FB",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // To get all customer details
  getCustomerProfiles: async (args = {}) => {
    let apiUrl = "/cu/v1/668D6510V91105XT";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To get all customer details
  getChallanCustomers: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/792C1465K91718NQ",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // To create/update Branch
  manageBranchProfile: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/v1/customer/branchprofile",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get customer Branches
  getBranchProfile: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/v1/customer/branchprofiles",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  // To create/update customer
  manageCustomerType: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/513C4053D83312PC",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get customer Types
  getCustomerTypes: async (args = {}) => {
    let apiUrl = "/cu/v1/710T6972L66442XF";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/710T6972L66442XF";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // To create/update customer address
  manageCustomerAddresses: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/410Y2778T60362OG",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get customer address
  getCustomerAddresses: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/424W1574I97636OW",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },
  //Get Instructions
  getInstructions: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/PHXPT6KQDQS4Z9AH",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  //To Create /Update Instructions
  manageInstruction: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/cu/v1/PQC7LLT43DAHR17U",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },
  //To Create /Update Customer BusinessType
  manageCustBusinessType: async (args = {}) => {
    let apiUrl = "/cu/v1/XIVEWM78NYAJ1RCW";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/XIVEWM78NYAJ1RCW";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCustBusinessTypes: async (args = {}) => {
    let apiUrl = "/cu/v1/C4OAT72681EC1FHB";
    if (local) {
      apiUrl = "http://192.168.0.115:7005/cu/v1/C4OAT72681EC1FHB";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
};
