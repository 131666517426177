import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { PRODUCT_APIs } from "apihandlers/product";

const Grams = (props: any) => {
  const { setDashMenu, privileges } = props;

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await PRODUCT_APIs.getGramsMaster(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getGramsMaster:", error);
    }
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "name",
      name: LABELS.NAME,
      width: "30%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "aliasName",

      name: LABELS.ALIAS_NAME,
      width: "25%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "weight",
      name: LABELS.WEIGHT,
      width: "25%",
      editable: false,
      sortable: true,
      align: "center",
      type: "text",
      searchable: true,
    },
    {
      key: "qty",
      name: LABELS.QUANTITY,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "number",
      searchable: true,
    },
    {
      key: "boxQty",
      name: LABELS.BOX_QUANTITY,
      width: "20%",
      editable: false,
      sortable: true,
      align: "center",
      type: "number",
      searchable: true,
    },
    {
      key: "sortOrder",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",

      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageGrams = () => {
    const name = (
      document.getElementById("name") as HTMLInputElement
    ).value.trim();
    const aliasName = (
      document.getElementById("alias") as HTMLInputElement
    ).value.trim();
    const weight = (
      document.getElementById("weight") as HTMLInputElement
    ).value.trim();
    const qty = (
      document.getElementById("quantity") as HTMLInputElement
    ).value.trim();
    const boxQty = (
      document.getElementById("boxQty") as HTMLInputElement
    ).value.trim();
    let op = document.querySelector(
      'input[name="isQuantitative"]:checked'
    ) as HTMLInputElement;
    const isQuantity = op ? op.value : "";
    const sortOrder = (
      document.getElementById("sortingOrder") as HTMLInputElement
    ).value.trim();
    op = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = op ? op.value : "";

    // Validations
    let errors = [];
    if (name === "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (aliasName === "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.ALIAS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (weight === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.WEIGHT +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (qty === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.QUANTITY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (boxQty === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.BOX_QUANTITY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (Number(boxQty) <= 0) {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.BOX_QUANTITY +
          "</span> " +
          WARNINGS.SHOULD_BE_GREATER_THAN_ZERO,
      });
    }
    if (sortOrder === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.SORTING_ORDER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      name,
      aliasName,
      qty,
      orgId: 1,
      sessionId: 1,
      boxQty,
      weight,
      sortOrder,
      isQuantity,
      activeStatus,
    };

    setIsSubmit(true);
    PRODUCT_APIs.manageGramsMaster(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.GRAMS}</li>
        </ul>
        <h4>{TITLES.GRAMS}</h4>
        <p>{SUB_TITLES.GRAMS}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"grams"}
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.GRAMS}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.GRAMS + ": " + selectedRow.name
              : "Add New " + LABELS.GRAMS
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup red">
            <TxtRow
              id={"name"}
              type={"text"}
              label={LABELS.NAME}
              required={true}
              defaultValue={selectedRow.name}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"alias"}
              type={"text"}
              label={LABELS.ALIAS}
              required={true}
              defaultValue={selectedRow.aliasName}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"weight"}
              type={"number"}
              label={LABELS.WEIGHT}
              required={true}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.weight).toString()
                  : selectedRow.weight
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"quantity"}
              type={"number"}
              label={LABELS.QUANTITY}
              required={true}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.qty).toString()
                  : selectedRow.qty
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"boxQty"}
              type={"number"}
              label={LABELS.BOX_QUANTITY}
              required={true}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.boxQty).toString()
                  : selectedRow.boxQty
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"sortingOrder"}
              type={"number"}
              label={LABELS.SORTING_ORDER}
              required={true}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.sortOrder).toString()
                  : selectedRow.sortOrder
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.IS_QUANTITATIVE}</label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.YES,
                  checked: selectedRow.id
                    ? selectedRow.isQuantity === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.NO,
                  checked: selectedRow.id
                    ? selectedRow.isQuantity === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"isQuantitative"}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup required">
            <label>{LABELS.ACTIVE_STATUS}</label>
            <span className="red">*</span>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageGrams}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Grams);
