// * Note: Please collect location info & logtime of each and every API Requests

// import axios from 'axios';

import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7003";

export const USER_APIs = {
  getSurveys: async (args = {}) => {
    let apiUrl = "/us/v1/KURZR3Y4SSY4TS0K";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/KURZR3Y4SSY4TS0K";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Add/Edit Survey Details
  manageSurvey: async (args = {}) => {
    let apiUrl = "/us/v1/IX9V47I2EWJ95H7L";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/IX9V47I2EWJ95H7L";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getDriverDetails: async (args = {}) => {
    let apiUrl = "/us/v1/149G2899Z97542CP";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/149G2899Z97542CP";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Add/Edit User Details
  manageUser: async (args = {}) => {
    let apiUrl = "/us/v1/114Z5894T27968ZR";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/114Z5894T27968ZR";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  // Get user details
  getUsers: async (args = {}) => {
    let apiUrl = "/us/v1/912P8505F29771VN";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Get Usertype details
  getUserTypes: async (args = {}) => {
    let apiUrl = "/us/v1/736Z6674M27931IB";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/736Z6674M27931IB";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  // Add/Edit
  manageAttendance: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/us/v1/XUNPM7746C5D5CMT",
      API_CONFIG.METHODS.POST,
      args
    );
    return response;
  },

  // Get
  getAttendance: async (args = {}) => {
    const response = await COMMON_JS.callApi(
      "/us/v1/O6BL092MCRJ36E9C",
      API_CONFIG.METHODS.GET,
      args
    );
    return response;
  },

  manageLeaveApplication: async (args = {}) => {
    let apiUrl = "/us/v1/0GZXN17DMQRC004P";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/0GZXN17DMQRC004P";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },
  getLeaveApplications: async (args = {}) => {
    let apiUrl = "/us/v1/5OAGGGFOAYSCSHKP";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/5OAGGGFOAYSCSHKP";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
  getOrgWiseUsers: async (args = {}) => {
    let apiUrl = "/us/v1/M1GBOSFWQ2XK12ZT";
    if (local) {
      apiUrl = "http://192.168.0.115:7003/us/v1/M1GBOSFWQ2XK12ZT";
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },
};

//
