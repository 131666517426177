import axios from "axios";
import jQuery from "jquery";

import { API_CONFIG, CONFIG, LOCAL_STORAGE, NOTIFY } from "common/config";
import { IApiResponse } from "common/models";

const $ = jQuery;

export const COMMON_JS = {
  getIndianNumber: (num: any) => {
    let numStrArr = num.toString().split(".");
    let numStr = numStrArr[0];
    var lastThree = numStr.substring(numStr.length - 3);
    var otherNumbers = numStr.substring(0, numStr.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    if (numStrArr.length > 1) {
      res += "." + numStrArr[1];
    }
    return res;
  },

  printHtml: (html = "") => {
    console.log(html);
    // let w = window.open();
    // w.document.write(html);
    // w.print();
    // w.close();
    var winPrint = window.open(
      "",
      "_blank",
      "left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0"
    );
    winPrint.document.write(html);
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    winPrint.close();
  },

  // Formula Calculations
  calcString: (val = "", formulaStr = "") => {
    let rsltStr = formulaStr.toUpperCase().replace("X", val).trim();
    rsltStr = rsltStr.replace(/[^-()\d/*+.]/g, "");
    return eval(rsltStr);
  },

  hasPrivilege: (roleId: any) => {
    let rslt = false;
    switch (roleId) {
      case CONFIG.USER_ROLES.Admin:
        rslt = true;
        break;
      default:
        break;
    }
    return rslt;
  },

  getSortedObj: (arr: any, key: string) => {
    return arr.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  },

  showNotify: (
    props: any,
    variant: string,
    text: string,
    autoHideDuration = NOTIFY.AUTO_HIDE_DURATION
  ) => {
    props.enqueueSnackbar(text, {
      variant: variant,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      // action: <button className="snackbar-btn">{LABELS.DISMISS}</button>,
      autoHideDuration: autoHideDuration,
    });
  },

  addAlphaToColor: (color: string, opacity = 1) => {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  },

  // Call API
  callApi: async (
    path = "/",
    method = API_CONFIG.METHODS.GET,
    args = {},
    dump = false,
    local = false
  ): Promise<IApiResponse> => {
    try {
      const bearerToken = localStorage[LOCAL_STORAGE.ACCESS_TOKEN] || "";
      let config = {
        timeout: API_CONFIG.TIMEOUT,
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
        params: {},
      };
      console.log("API: req:", path, args);

      let result: IApiResponse = {
        status: 500,
        response: {},
        error: "",
      };
      let res = {
        data: {
          status: 500,
          response: {},
          error: "",
        },
        status: 500,
      };
      let apiUrl = API_CONFIG.API_URL + path;
      if (local) {
        apiUrl = path;
      }
      switch (method) {
        case API_CONFIG.METHODS.POST:
          res = await axios.post(apiUrl, args, config);
          break;
        case API_CONFIG.METHODS.PUT:
          break;
        case API_CONFIG.METHODS.DELETE:
          break;
        default:
          // GET
          config.params = args;
          res = await axios.get(apiUrl, config);
          break;
      }
      console.log("API: res:", path, res);
      if (dump) {
        return res.data;
      }

      // 403 => Redirect to login
      if (res.status === 200) {
        const { status, response, error } = res.data;
        result = { status, response, error };
        return result;
      }

      // Failed due to some other reason
      return {
        status: 500,
        response: res,
        error: "Request Failed..!",
      };
    } catch (err) {
      console.warn("API: ", err);
      if (err.response.data && err.response.data.status === 403) {
        // Authentication Failed
        if (window.location.pathname != "/login") {
          window.open("/login", "_self");
        }
        return {
          status: 403,
          response: {},
          error: "Authentication Failed: Please try login to continue..!",
        };
      }
      let rslt = {
        status: 500,
        response: {},
        error: err,
      };
      if (err.response) {
        if (err.response.data) {
          rslt = err.response.data;
        }
      }
      return rslt;
    }
  },

  getExportPrintCss: (win) => {
    $(win.document.body).find("h1").remove();
    $(win.document.body).find("table").attr("cellpadding", "3");
    $(win.document.body).find("table").css("font-size", "10px");
    $(win.document.body)
      .find("table")
      .css(
        "cssText",
        "border-bottom: none;border-left: none;border-right: none;"
      );
    $(win.document.body).find("td").css("font-size", "8px");
    $(win.document.body).find("th").css("font-size", "8px");
    $(win.document.body)
      .find("tbody td")
      .css("cssText", "border: 1px solid #000;border-top: 0;border-right: 0;");
    $(win.document.body)
      .find("tbody td:last-child")
      .css("cssText", "border: 1px solid #000;border-top: 0;");
    $(win.document.body)
      .find("table th")
      .css("cssText", "border: 1px solid #000;border-right: 0;");
    $(win.document.body)
      .find("table th:last-child")
      .css("cssText", "border: 1px solid #000;");
  },

  getBase64: (file: any, cb: Function) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  },

  onEditPath: (p) => {
    let latLngs: any = [];
    for (let i in p.layers._layers) {
      latLngs = p.layers._layers[i]._latlngs;
    }
    if (document.querySelector("div#addZone")) {
      if (latLngs.length > 0) {
        latLngs = JSON.stringify(latLngs[0]);
      }
      document.querySelector("div#addZone").setAttribute("vertices", latLngs);
    } else if (document.querySelector("div#addRoute")) {
      latLngs = JSON.stringify(latLngs);
      document.querySelector("div#addRoute").setAttribute("route", latLngs);
    }
  },

  onCreate: (e) => {
    // if (d.layerType === "polygon") {
    //   if (document.querySelector("div#addZone")) {
    //     let vertices = d.layer._latlngs;
    //     if (vertices.length > 0) {
    //       vertices = JSON.stringify(vertices[0]);
    //     }
    //     document
    //       .querySelector("div#addZone")
    //       .setAttribute("vertices", vertices);
    //   }
    // } else if (d.layerType === "polyline") {
    //   if (document.querySelector("div#addRoute")) {
    //     let latLngs = d.layer._latlngs;
    //     if (latLngs.length > 0) {
    //       latLngs = JSON.stringify(latLngs);
    //     }
    //     console.log("latLngs", latLngs);
    //     document.querySelector("div#addRoute").setAttribute("route", latLngs);
    //   }
    // }

    const drawnPolygon = e.layer;
    const latlngs = drawnPolygon.getLatLngs();
    console.log("Drawn Polygon Latlngs:", latlngs);
    //mapDrawControl.current.leafletElement._toolbars.draw._modes.polygon.handler.disable();
  },

  onDeleted: () => {},

  closeDrawZone: function () {
    let cb = document.querySelector(
      'ul.leaflet-draw-actions li>a[title="Cancel drawing"]'
    );
    if (cb) {
      cb.dispatchEvent(new Event("click"));
    }
    cb = document.querySelector(
      'ul.leaflet-draw-actions.leaflet-draw-actions-top>li>a[title="Cancel editing, discards all changes"]'
    );
    if (cb) {
      cb.dispatchEvent(new Event("click"));
    }
    console.log("Zone closeDrawZone", cb);
  },

  clearLayers: function () {
    let cb = document.querySelector(".leaflet-draw-edit-remove");
    if (cb) {
      cb.dispatchEvent(new Event("click"));
    }

    cb = document.querySelector(
      "ul.leaflet-draw-actions.leaflet-draw-actions-bottom li:last-child>a"
    );
    if (cb) {
      cb.dispatchEvent(new Event("click"));
    }
    console.log("Zone clearLayers");
  },

  editPolygon: () => {
    let e = new Event("click", {
      bubbles: true,
      cancelable: true,
    });
    let cb = document.getElementsByClassName("leaflet-draw-edit-edit");
    console.log("Zone editPolygon");
    if (cb && cb[0]) {
      return !cb[0].dispatchEvent(e);
    }
  },

  drawPolygon: () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.getElementsByClassName("leaflet-draw-draw-polygon");
    if (cb) {
      return !cb[0].dispatchEvent(e);
    }
  },

  drawCircle: () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.getElementsByClassName("leaflet-draw-draw-circle");
    if (cb) {
      return !cb[0].dispatchEvent(e);
    }
  },
};
