import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";

import { FORMATS, NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";
import { DateFunctions } from "common/datefunctions";
import { USER_APIs } from "apihandlers/user";
import { MASTER_APIs } from "apihandlers/master";

const FareChart = (props: any) => {
  const { setDashMenu } = props;

  const userTypesRef: any = useRef(null);
  const subCategRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [mainCategs, setMainCategs] = useState<any>(null);
  const [userTypes, setUserTypes] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      getUserType();
    }
  }, [loading]);

  const getUserType = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUserTypes(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.label,
          };
        });
        setUserTypes(list);
        getRows();
      }
    });
  };

  // Get not working

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await MASTER_APIs.getFareCharts(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
        list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
      }
    } catch (error) {
      console.error("getFareChart:", error);
    }
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const COLUMNS = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "fareName",
      name: LABELS.FARE_NAME,
      width: "20%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "userTypeId",
      name: LABELS.USER_ROLE,
      width: "20%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "startDate",
      name: LABELS.FROM_DATE,
      width: "20%",
      type: "text",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "endDate",
      name: LABELS.TO_DATE,
      width: "20%",
      type: "number",
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "10%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      align: "center",
      editable: false,
      searchable: true,
      sortable: true,
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageFareChart = () => {
    const fareName = (
      document.getElementById("fareName") as HTMLInputElement
    ).value.trim();
    const startDate = (
      document.getElementById("startDate") as HTMLInputElement
    ).value.trim();
    const userTypeId = userTypesRef.current.selectedValue.id;
    const endDate = (
      document.getElementById("endDate") as HTMLInputElement
    ).value.trim();
    const expenseType = (
      document.getElementById("expenseType") as HTMLInputElement
    ).value.trim();
    const expenseMode = (
      document.getElementById("expenseMode") as HTMLInputElement
    ).value.trim();
    const expenseUnit = (
      document.getElementById("expenseUnit") as HTMLInputElement
    ).value.trim();
    const unitRate = (
      document.getElementById("unitRate") as HTMLInputElement
    ).value.trim();
    const minimumValue = (
      document.getElementById("minimumValue") as HTMLInputElement
    ).value.trim();
    const maximumValue = (
      document.getElementById("maximumValue") as HTMLInputElement
    ).value.trim();
    const minimumCall = (
      document.getElementById("minimumCall") as HTMLInputElement
    ).value.trim();
    const minimumProductiveCall = (
      document.getElementById("minimumProductiveCall") as HTMLInputElement
    ).value.trim();
    let edit = document.querySelector(
      'input[name="editStatus"]:checked'
    ) as HTMLInputElement;
    const editable = edit ? edit.value : "";
    let unitEdit = document.querySelector(
      'input[name="unitEditStatus"]:checked'
    ) as HTMLInputElement;
    const unitEditable = unitEdit ? unitEdit.value : "";
    let active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";

    // Validations
    let errors = [];
    if (!fareName) {
      errors.push({
        id: "name",
        msg:
          "<span class='bold'>" +
          LABELS.FARE_NAME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!userTypeId) {
      errors.push({
        id: "sortOrder",
        msg:
          "<span class='bold'>" +
          LABELS.USER_ROLE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      fareName: fareName,
      userTypeId: userTypeId,
      startDate: startDate,
      endDate: endDate,
      expenseType: expenseType,
      expenseMode: expenseMode,
      expenseUnit: expenseUnit,
      unitRate: unitRate,
      minimumValue: minimumValue,
      activeStatusFareItem: "true",
      maximumValue: maximumValue,
      isEditable: editable,
      isUnitEditable: unitEditable,
      minimumCall: minimumCall,
      minimumProductiveCall: minimumProductiveCall,
      activeStatusFare: selectBtnValue.toString(),
      items: [],
    };
    console.log("args", args);
    setIsSubmit(true);
    MASTER_APIs.manageFareChart(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);

        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("selectedRow", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME} </li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.FARE_CHART}</li>
        </ul>
        <h4>{TITLES.FARE_CHART}</h4>
        <p>{SUB_TITLES.FARE_CHART}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.8}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.FARE_CHART}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.FARE_CHART + ": " + selectedRow.productName
              : "Add New " + LABELS.FARE_CHART
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <TxtRow
              id={"fareName"}
              type={"text"}
              label={LABELS.FARE_NAME}
              required={true}
              defaultValue={selectedRow.fareName}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {LABELS.USER_ROLE}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={userTypes}
              childRef={userTypesRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.mainCategoryId,
                      label: selectedRow.mainCategory,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"startDate"}
              type={"date"}
              label={LABELS.FROM_DATE}
              required={true}
              defaultValue={
                selectedRow.startDate
                  ? DateFunctions.getFormatedDate(
                      selectedRow.startDate,
                      FORMATS.SQL_DATE,
                      FORMATS.SQL_DATE_ONLY
                    )
                  : DateFunctions.getDate(
                      "today",
                      "from",
                      FORMATS.SQL_DATE_ONLY
                    )
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"endDate"}
              type={"date"}
              label={LABELS.TO_DATE}
              required={true}
              defaultValue={
                selectedRow.endDate
                  ? DateFunctions.getFormatedDate(
                      selectedRow.startDate,
                      FORMATS.SQL_DATE,
                      FORMATS.SQL_DATE_ONLY
                    )
                  : DateFunctions.getDate(
                      "today",
                      "from",
                      FORMATS.SQL_DATE_ONLY
                    )
              }
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"expenseType"}
              type={"text"}
              label={LABELS.EXP_TYPE}
              //required={true}
              defaultValue={selectedRow.expenseType}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"expenseMode"}
              type={"text"}
              label={LABELS.EXP_MODE}
              //required={true}
              defaultValue={selectedRow.expenseMode}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"expenseUnit"}
              type={"text"}
              label={LABELS.EXP_UNIT}
              // required={true}
              defaultValue={selectedRow.expenseUnit}
              disabled={isSubmit}
            />
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"unitRate"}
                type={"number"}
                label={LABELS.UNIT_RATE}
                //required={true}
                defaultValue={selectedRow.unitRate}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"minimumValue"}
                type={"number"}
                label={LABELS.MIN}
                // required={true}
                defaultValue={selectedRow.minimumValue}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"maximumValue"}
                type={"number"}
                label={LABELS.MAX}
                //required={true}
                defaultValue={selectedRow.maximumValue}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <TxtRow
                id={"minimumCall"}
                type={"text"}
                label={LABELS.MIN_CALL}
                //required={true}
                defaultValue={selectedRow.minimumCall}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <TxtRow
                id={"minimumProductiveCall"}
                type={"number"}
                label={LABELS.MIN_PRODUCTIVE_CALL}
                //required={true}
                defaultValue={selectedRow.minimumProductiveCall}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30"></div>
          </div>
          <div className="formGroup">
            <label>
              {LABELS.EDITABLE + "?"} {<span className="red">*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.YES,
                  checked: selectedRow.id
                    ? selectedRow.editStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.NO,
                  checked: selectedRow.id
                    ? selectedRow.editStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"editStatus"}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {LABELS.UNIT_EDITABLE + "?"} {<span className="red">*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.YES,
                  checked: selectedRow.id
                    ? selectedRow.unitEditStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.NO,
                  checked: selectedRow.id
                    ? selectedRow.unitEditStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"unitEditStatus"}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label>
              {LABELS.STATUS} {<span className="red">*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => setManageDialog(false)}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                bgColor={COLORS.SAVE_GREEN}
                onClick={manageFareChart}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(FareChart);
