import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import Btn from "components/Custom/Btn";
import SkeletonBox from "components/Custom/SkeletonBox";

import { CRM_LEAD_STATUS, LOCAL_STORAGE, NOTIFY } from "common/config";
import { COMMON_JS } from "common/scripts";
import { LABELS, SUCCESS, WARNINGS } from "common/lables";
import { CRM_APIs } from "apihandlers/crm";
import { COLORS } from "common/colors";
import TxtRow from "components/Custom/TxtRow";

interface defaultProps {}

const LeadApprovals = (props: any) => {
  const gridApprovalRef: any = useRef(null);
  const { selectedRow, onClose, isButtonDisabled } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const buttonDisbale =
    selectedRow.crmLeadStatus === CRM_LEAD_STATUS.APPROVALS ||
    selectedRow.crmLeadStatus === CRM_LEAD_STATUS.QUALIFIED ||
    selectedRow.crmLeadStatus === CRM_LEAD_STATUS.CONVERTED;
  const [columns, setColumns] = useState<any>([
    {
      key: "approvalLevel",
      name: "Approve Level",
      type: "html",
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
    },
    {
      key: "approvalUserType",
      type: "html",
      name: "User Type",
      width: "30%",
      editable: false,
      sortable: false,
      align: "center",
    },
    {
      key: "comments",
      type: "text",
      name: "Comments",
      width: "60%",
      editable: true,
      sortable: true,
      align: "center",
    },
    // {
    //   key: "options",
    //   name: "Options",
    //   width: "10%",
    //   editable: false,
    //   sortable: true,
    //   align: "center",
    //   renderCell: ({ row }: any) => {
    //     return (
    //       <div className="options">
    //         <Tooltip title={LABELS.SAVE} placement="bottom">
    //           <IconButton className="iconBtn" onClick={() => onRowSave(row)}>
    //             <IconEdit />
    //           </IconButton>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
  ]);
  const [rows, setRows] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {};
      setRows(null);
      const res: any = await CRM_APIs.getCrmApprovals(args);
      const { status, response } = res;
      if (status === 200) {
        const list = res.response.records;
        getCrmLeadApprovals(list);
      }
    } catch (error) {
      console.error("getCrmApprovals:", error);
    }
  };

  const getCrmLeadApprovals = async (list: any) => {
    const args = { crmLeadId: selectedRow.id };
    const res: any = await CRM_APIs.getCrmLeadApprovals(args);
    if (res.status === 200) {
      const recs = res.response.records;
      for (let i = 0; i < list.length; i++) {
        if (list[i].isApprovalMandatory) {
          list[i].approvalUserType = (
            <span className="red">{list[i].approvalUserType}</span>
          );
          list[i].approvalLevel = (
            <span className="red">{list[i].approvalLevel}</span>
          );
        }
        const approvals = recs.find(
          (r: any) => r.approvalUserTypeId === list[i].approvalUserTypeId
        );
        list[i].comments = approvals ? approvals.comments : "";
        list[i].crmApproveId = approvals ? approvals.id : -1;
      }
    }
    setRows(list);
    setLoading(false);
  };

  const onApprove = async () => {
    const pendingApprovals = rows.filter(
      (r) => r.isApprovalMandatory && r.comments.trim() === ""
    );
    // if (pendingApprovals.length > 0) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     WARNINGS.MANDATORY_COMMENTS_REQUIRED
    //   );
    //   return;
    // }
    try {
      const remarks = (
        document.getElementById("remarks") as HTMLInputElement
      ).value.trim();
      const argsRemarks = {
        ...selectedRemarks,
        approvals: remarks,
      };

      const args = {
        crmLeadId: selectedRow.id,
        crmLeadName: selectedRow.crmLeadName,
        activeStatus: selectedRow.activeStatus,
        crmLeadStatus: CRM_LEAD_STATUS.APPROVALS,
        remarks: argsRemarks,
      };
      const res = await CRM_APIs.manageCrmLeadProfile(args);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        onClose();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    } catch (error) {
      console.error("onConverted: ", error);
    }
  };

  const onApproveComment = async () => {
    try {
      setIsSubmit(true);
      for (let i in rows) {
        const commentEle = document.getElementById(
          "comment-" + i + "-" + rows[i].crmApproveId
        ) as HTMLInputElement;
        const comments = commentEle.value.trim();
        console.log("commentEle: ", i, commentEle);
        if (!commentEle.disabled) {
          const args = {
            id: rows[i].crmApproveId || -1,
            crmLeadId: selectedRow.id,
            comments: comments,
          };
          const res = await CRM_APIs.manageCrmLeadApproval(args);
          if (res.status === 200) {
            COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
            setLoading(true);
          }
        }
      }
      setIsSubmit(false);
    } catch (error) {
      console.error("onApproveComment: ", error);
    }
  };

  let userInfo: any = localStorage.getItem(LOCAL_STORAGE.USER_INFO);
  userInfo = userInfo ? JSON.parse(userInfo) : {};
  const roleId = userInfo.role ? userInfo.role.id : null;
  const selectedRemarks = selectedRow.remarks
    ? JSON.parse(selectedRow.remarks)
    : {};

  console.log("ROWS:", rows, roleId);

  return (
    <React.Fragment>
      {rows ? (
        <>
          <div style={{ marginBottom: 10 }}>
            <Btn
              text={LABELS.RELOAD}
              bgColor={COLORS.RELOAD_EXPORT}
              color={COLORS.RELOAD_EXPORT_TEXT}
              onClick={getRows}
            />
          </div>
          <table border={1} width={"98%"} cellSpacing={0} cellPadding={8}>
            <thead>
              <th style={{ width: "15%" }}>{columns[0].name}</th>
              <th style={{ width: "15%" }}>{columns[1].name}</th>
              <th style={{ width: "50%" }}>{columns[2].name}</th>
            </thead>
            <tbody>
              {rows.map((r, i) => {
                return (
                  <tr key={"row-" + i}>
                    <td>{r.approvalLevel}</td>
                    <td>{r.approvalUserType}</td>
                    <td style={{ width: "50%" }}>
                      <input
                        id={"comment-" + i + "-" + r.crmApproveId}
                        type="text"
                        defaultValue={r.comments}
                        disabled={r.approvalUserTypeId !== roleId}
                        style={{
                          width: "99%",
                          height: 30,
                          paddingLeft: 10,
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="formGroup width60" style={{ marginTop: 15 }}>
            <TxtRow
              id={"remarks"}
              type={"text"}
              label={LABELS.REMARKS}
              defaultValue={selectedRemarks.approvals || " "}
              //disabled={isSubmit}
              rows={4}
            />
          </div>

          {/* <ExcelGrid
            childRef={gridApprovalRef}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={false}
            newRowOption={false}
            onReload={getRows}
          /> */}

          {isSubmit ? (
            <div
              className="flexRow"
              style={{
                justifyContent: "flex-end",
                marginTop: 15,
                width: "98%",
              }}
            >
              <SkeletonBox
                height={40}
                width={"20%"}
                style={{ marginRight: 15 }}
              />
              <SkeletonBox height={40} width={"20%"} />
            </div>
          ) : (
            <div
              className="flexColumn"
              style={{
                alignItems: "flex-end",
                marginTop: "2rem",
                marginLeft: "2rem",
              }}
            >
              <div
                className="flexRow"
                style={{
                  width: "30%",
                  paddingRight: "2rem",
                  justifyContent: "right",
                }}
              >
                <Btn
                  text={LABELS.APPROVED}
                  bgColor={COLORS.SAVE_GREEN}
                  // disabled={buttonDisbale}
                  marginRight={15}
                  onClick={onApprove}
                  disabled={isButtonDisabled}
                  style={{ width: "50%" }}
                />
                <Btn
                  text={LABELS.SAVE}
                  bgColor={COLORS.SAVE_GREEN}
                  // disabled={buttonDisbale}
                  onClick={onApproveComment}
                  disabled={isButtonDisabled}
                  style={{ width: "50%" }}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flexRowCenter">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(LeadApprovals);
