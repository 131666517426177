import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { withSnackbar } from "notistack";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { GEO_APIs } from "apihandlers/geography";
import { MASTER_APIs } from "apihandlers/master";
import { USER_APIs } from "apihandlers/user";

const Area = (props: any) => {
  const { setDashMenu } = props;

  const cityRef: any = useRef(null);
  const vehiclesRef: any = useRef(null);
  const driversRef: any = useRef(null);
  const executiveRef: any = useRef(null);
  const daysRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [city, setcity] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [vehicles, setVehicles] = useState<any>({});
  const [drivers, setDrivers] = useState<any>({});
  const [executive, setExecutive] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "areaName",
      name: LABELS.AREA,
      width: "15%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "cityName",
      name: LABELS.CITY,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "day",
      type: "text",
      name: LABELS.DAY_OF_WEEK,
      width: "9%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "pinCode",
      type: "number",
      name: LABELS.PIN_CODE,
      width: "9%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "totalPopulation",
      type: "number",
      name: LABELS.POPULATION,
      width: "9%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "totalShops",
      type: "number",
      name: LABELS.TOTAL_SHOPS,
      width: "9%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "beatCode",
      type: "number",
      name: LABELS.BEAT_CODE,
      width: "9%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "sortingOrder",
      type: "number",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "10%",
      type: "dropdown",
      filters: [
        { id: "yes", name: "Yes" },
        { id: "no", name: "No" },
      ],
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getCities();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await GEO_APIs.getAreas(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getCities:", error);
    }
  };

  const getCities = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getCities(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.cityName,
          };
        });
        setcity(list);
        getVehicles();
      }
    });
  };

  const getVehicles = () => {
    const args = {
      activeStatus: true,
    };
    MASTER_APIs.getVehicles(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.vehicleNumber,
          };
        });
        setVehicles(list);
        getDrivers();
      }
    });
  };
  const getDrivers = () => {
    const args = {};
    MASTER_APIs.getDrivers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.userName,
          };
        });
        setDrivers(list);
        getUsers();
      }
    });
  };

  const getUsers = () => {
    const args = {
      activeStatus: true,
    };
    USER_APIs.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.userName,
          };
        });
        setExecutive(list);
        getRows();
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    console.log(selectedRow);
  };

  const manageAreas = () => {
    const areaName = (
      document.getElementById("areaName") as HTMLInputElement
    ).value.trim();
    const cityId = cityRef.current.selectedValue.id;
    const pinCode = (
      document.getElementById("pinCode") as HTMLInputElement
    ).value.trim();
    const totalPopulation = (
      document.getElementById("totalPopulation") as HTMLInputElement
    ).value.trim();
    const totalShops = (
      document.getElementById("totalShops") as HTMLInputElement
    ).value.trim();
    const weekDay = daysRef.current.selectedValue.id;
    // const vehicleId = vehiclesRef.current.selectedValue.id;
    // const driverId = driversRef.current.selectedValue.id;
    const sortingOrder = (
      document.getElementById("sortingOrder") as HTMLInputElement
    ).value.trim();
    const beatCode = (
      document.getElementById("beatCode") as HTMLInputElement
    ).value.trim();
    const avgCallTime = (
      document.getElementById("avgCallTime") as HTMLInputElement
    ).value.trim();
    const areaType = document.querySelector(
      'input[name="areaType"]:checked'
    ) as HTMLInputElement;
    const areaTypeValue = areaType ? "City" : "Rural";
    // const executiveId = executiveRef.current.selectedValue.id;
    const active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const activeStatus = active ? active.value : "";

    // Validations
    let errors = [];
    if (areaName === "") {
      errors.push({
        id: "areaName",
        msg:
          "<span class='bold'>" +
          LABELS.AREA +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!cityId) {
      errors.push({
        id: "regionId",
        msg:
          "<span class='bold'>" +
          LABELS.CITY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!weekDay) {
      errors.push({
        id: "weekDay",
        msg:
          "<span class='bold'>" +
          LABELS.DAY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (sortingOrder === "") {
      errors.push({
        id: "sortingOrder",
        msg:
          "<span class='bold'>" +
          LABELS.SORTING_ORDER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (pinCode === "") {
      errors.push({
        id: "pinCode",
        msg:
          "<span class='bold'>" +
          LABELS.PIN_CODE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (totalPopulation === "") {
      errors.push({
        id: "totalPopulation",
        msg:
          "<span class='bold'>" +
          LABELS.POPULATION +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (totalShops === "") {
      errors.push({
        id: "totalShops",
        msg:
          "<span class='bold'>" +
          LABELS.TOTAL_SHOPS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (beatCode === "") {
      errors.push({
        id: "beatCode",
        msg:
          "<span class='bold'>" +
          LABELS.BEAT_CODE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (avgCallTime === "") {
      errors.push({
        id: "avgCallTime",
        msg:
          "<span class='bold'>" +
          LABELS.AVERAGE_CALL_TIME +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }

    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      areaName,
      cityId,
      activeStatus,
      weekDay,
      // executiveId,
      // vehicleId,
      // driverId,
      sortingOrder,
      pinCode,
      avgCallTime,
      totalPopulation,
      totalShops,
      beatCode,
      areaType: areaTypeValue,
    };
    setIsSubmit(true);
    GEO_APIs.manageArea(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("Page: Area:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.AREA}</li>
        </ul>
        <h4>{TITLES.AREA}</h4>
        <p>{SUB_TITLES.AREA}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"area"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.AREA}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.AREA + ": " + selectedRow.areaName
              : "Add New " + LABELS.AREA
          }
          open={true}
          size={"md"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="flexRowJustify">
            <div className="formGroup required width30">
              <TxtRow
                id={"areaName"}
                type={"text"}
                label={LABELS.AREA}
                required={true}
                defaultValue={selectedRow.areaName}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 required">
              <label>
                {LABELS.CITY}
                {<span>*</span>}
              </label>
              <AutoComplete
                list={city}
                childRef={cityRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.cityId,
                        label: selectedRow.cityName,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 required">
              <label>
                {LABELS.DAY_OF_WEEK}
                {<span>*</span>}
              </label>
              <AutoComplete
                list={[
                  {
                    id: "Sunday",
                    label: "Sunday",
                  },
                  {
                    id: "Monday",
                    label: "Monday",
                  },
                  {
                    id: "Tuesday",
                    label: "Tuesday",
                  },
                  {
                    id: "Wednesday",
                    label: "Wednesday",
                  },
                  {
                    id: "Thursday",
                    label: "Thursday",
                  },
                  {
                    id: "Friday",
                    label: "Friday",
                  },
                  {
                    id: "Saturday",
                    label: "Saturday",
                  },
                ]}
                childRef={daysRef}
                disabled={isSubmit}
                defaultValue={
                  selectedRow.day
                    ? {
                        id: selectedRow.day,
                        label: selectedRow.day,
                      }
                    : null
                }
              />
            </div>
          </div>
          {/* <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.EXECUTIVE}</label>
              <AutoComplete
                list={executive}
                childRef={executiveRef}
                defaultValue={
                  selectedRow.executive
                    ? {
                        id: selectedRow.executiveId,
                        label: selectedRow.executive,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.VEHICLE_NUMBER}</label>
              <AutoComplete
                list={vehicles}
                childRef={vehiclesRef}
                defaultValue={
                  selectedRow.vehicle
                    ? {
                        id: selectedRow.vehicleId,
                        label: selectedRow.vehicle,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30">
              <label>{LABELS.DRIVER_NAME}</label>
              <AutoComplete
                list={drivers}
                childRef={driversRef}
                defaultValue={
                  selectedRow.driver
                    ? {
                        id: selectedRow.driverId,
                        label: selectedRow.driver,
                      }
                    : null
                }
                onChange={(e, v) => {}}
                disabled={isSubmit}
              />
            </div>
          </div> */}
          <div className="flexRowJustify">
            <div className="formGroup width30 required">
              <TxtRow
                id={"sortingOrder"}
                type={"number"}
                label={LABELS.SORTING_ORDER}
                defaultValue={
                  selectedRow.id
                    ? Number(selectedRow.sortingOrder).toString()
                    : selectedRow.sortingOrder
                }
                disabled={isSubmit}
                required={true}
              />
            </div>
            <div className="formGroup width30 required">
              <TxtRow
                id={"pinCode"}
                label={LABELS.PIN_CODE}
                defaultValue={selectedRow.id ? selectedRow.pinCode : ""}
                type={"number"}
                required={true}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 required">
              <TxtRow
                id={"totalPopulation"}
                type={"number"}
                label={LABELS.POPULATION}
                required={true}
                defaultValue={selectedRow.totalPopulation}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30 required">
              <TxtRow
                id={"totalShops"}
                type={"number"}
                label={LABELS.TOTAL_SHOPS}
                required={true}
                defaultValue={selectedRow.totalShops}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 required">
              <TxtRow
                id={"beatCode"}
                type={"number"}
                label={LABELS.BEAT_CODE}
                required={true}
                defaultValue={selectedRow.beatCode}
                disabled={isSubmit}
              />
            </div>
            <div className="formGroup width30 required">
              <TxtRow
                id={"avgCallTime"}
                type={"number"}
                label={LABELS.AVERAGE_CALL_TIME}
                required={true}
                defaultValue={selectedRow.avgCallTime}
                disabled={isSubmit}
              />
            </div>
          </div>
          <div className="flexRowJustify">
            <div className="formGroup width30">
              <label>{LABELS.AREA_TYPE}</label>
              <RadioBtn
                list={[
                  {
                    id: "city",
                    value: "city",
                    label: LABELS.CITY,
                    checked: selectedRow.id
                      ? selectedRow.areaType === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "rural",
                    value: "rural",
                    label: LABELS.RURAL,
                    checked: selectedRow.id
                      ? selectedRow.areaType === false
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"areaType"}
                disabled={isSubmit}
              />
            </div>

            <div className="formGroup width30">
              <label>{LABELS.ACTIVE_STATUS}</label>
              <RadioBtn
                list={[
                  {
                    id: "true",
                    value: "true",
                    label: LABELS.ACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === true
                      : true,
                    disabled: false,
                  },
                  {
                    id: "false",
                    value: "false",
                    label: LABELS.INACTIVE,
                    checked: selectedRow.id
                      ? selectedRow.activeStatus === false
                      : false,
                    disabled: false,
                  },
                ]}
                row={true}
                name={"activeStatus"}
                disabled={isSubmit}
              />
            </div>
          </div>

          {/* mandatoryResults */}
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageAreas}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Area);
