import { API_CONFIG, PRODUCTION, UAT } from "common/config";
import { COMMON_JS } from "common/scripts";

const local = PRODUCTION === "false" && UAT === "false";
const LOCAL_PORT = "7005";

export const CRM_APIs = {
  manageCrmLeadProfile: async (args = {}) => {
    let apiUrl = "/cu/v1/D66W379BYS9SAL52";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getLeadProfiles: async (args = {}) => {
    let apiUrl = "/ac/v1/149Z7769V62163UT";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCrmDocuments: async (args = {}) => {
    let apiUrl = "/cu/v1/R4W8OQMOLYH02ACT";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageCrmLeadDocument: async (args = {}) => {
    let apiUrl = "/cu/v1/6DX11JJXE7RM0NSB";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },

  getCrmLeadDocuments: async (args = {}) => {
    let apiUrl = "/cu/v1/923G194QZUQQRC9N";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getCrmApprovals: async (args = {}) => {
    let apiUrl = "/cu/v1/48GR8BYQUY4SOU02";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  getCrmLeadApprovals: async (args = {}) => {
    let apiUrl = "/cu/v1/UP9O0S3J9ZSEYHVI";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.GET,
      args,
      false,
      local
    );
    return response;
  },

  manageCrmLeadApproval: async (args = {}) => {
    let apiUrl = "/cu/v1/5SLCS9BCN0BGUG2R";
    if (local) {
      apiUrl = API_CONFIG.API_URL + ":" + LOCAL_PORT + apiUrl;
    }
    const response = await COMMON_JS.callApi(
      apiUrl,
      API_CONFIG.METHODS.POST,
      args,
      false,
      local
    );
    return response;
  },
};
