import React from "react";

import HGraph from "components/Custom/HGraph";

const SolidGauge = (props: any) => {
  const { height } = props;
  const series = [
    {
      name: "Leads",
      data: [38],
      dataLabels: {
        format:
          '<div style="text-align:center">' +
          '<span style="font-size:25px">{y}</span><br/>' +
          '<span style="font-size:12px;opacity:0.4">Leads Pending Action</span>' +
          "</div>",
      },
      tooltip: {
        valueSuffix: "Leads",
      },
    },
    {
      name: "no Leads",
      data: [50],
      dataLabels: {
        format:
          '<div style="text-align:center">' +
          '<span style="font-size:25px">{y}</span><br/>' +
          '<span style="font-size:12px;opacity:0.4">Leads Pending Action</span>' +
          "</div>",
      },
      tooltip: {
        valueSuffix: "Leads",
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="graphs">
        <HGraph
          width={"99.5%"}
          height={height}
          type={"solidgauge"}
          series={series}
          title={" "}
          exportTitle={" "}
        />
      </div>
    </React.Fragment>
  );
};

export default SolidGauge;
