import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";
import TxtRow from "components/Custom/TxtRow";
import { NOTIFY } from "common/config";
import { COLORS } from "common/colors";
import { DEFAULTS } from "common/config";
import {
  LABELS,
  WARNINGS,
  TITLES,
  SUCCESS,
  ERRORS,
  SUB_TITLES,
} from "common/lables";
import { COMMON_JS } from "common/scripts";

import { GEO_APIs } from "apihandlers/geography";

const Currency = (props: any) => {
  const { setDashMenu } = props;

  const countryRef: any = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rows, setRows] = useState<any>(null);
  const [countries, setCounteries] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [mandatoryResults, setMandatoryResults] = useState<any[]>([]);
  const columns = [
    {
      key: "slNo",
      name: LABELS.SL_NO,
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "symbol",
      name: LABELS.CURRENCY_SYMBOL,
      width: "22%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "countryName",
      name: LABELS.COUNTRY,
      width: "14%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "code",
      name: LABELS.CURRENCY_CODE,
      width: "14%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "decimaldelimiter",
      name: LABELS.DECIMAL_DELIMETER,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "thousanddelimiter",
      name: LABELS.THOUSAND_DELIMETER,
      width: "10%",
      type: "number",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "sortOrder",
      name: LABELS.SORTING_ORDER,
      width: "10%",
      type: "text",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "activeStatusText",
      name: LABELS.ACTIVE,
      width: "10%",
      type: "dropdown",
      filters: [
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: LABELS.OPTIONS,
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getCountries();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
      };
      const res: any = await GEO_APIs.getCurrencies(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getCurrencies:", error);
    }
  };

  const getCountries = () => {
    const args = {
      activeStatus: true,
    };
    GEO_APIs.getCountries(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.countryName,
          };
        });
        setCounteries(list);
        getRows();
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const manageCurrency = () => {
    const symbol = (
      document.getElementById("symbol") as HTMLInputElement
    ).value.trim();
    const countryId = countryRef.current.selectedValue.id;
    const code = (
      document.getElementById("code") as HTMLInputElement
    ).value.trim();
    const decimalDelimiter = (
      document.getElementById("decimalDelimiter") as HTMLInputElement
    ).value.trim();
    const thousandDelimiter = (
      document.getElementById("thousandDelimiter") as HTMLInputElement
    ).value.trim();
    const sortOrder = (
      document.getElementById("sortOrder") as HTMLInputElement
    ).value.trim();
    let active = document.querySelector(
      'input[name="activeStatus"]:checked'
    ) as HTMLInputElement;
    const selectBtnValue = active ? active.value : "";

    // Validations
    let errors = [];
    if (symbol === "") {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.CURRENCY_SYMBOL +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!countryId) {
      errors.push({
        id: "symbol",
        msg:
          "<span class='bold'>" +
          LABELS.COUNTRY +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (code === "") {
      errors.push({
        id: "code",
        msg:
          "<span class='bold'>" +
          LABELS.CURRENCY_CODE +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (decimalDelimiter === "") {
      errors.push({
        id: "decimalDelimiter",
        msg:
          "<span class='bold'>" +
          LABELS.DECIMAL_DELIMETER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (thousandDelimiter === "") {
      errors.push({
        id: "thousandDelimiter",
        msg:
          "<span class='bold'>" +
          LABELS.THOUSAND_DELIMETER +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    if (!selectBtnValue) {
      errors.push({
        id: "selectBtnValue",
        msg:
          "<span class='bold'>" +
          LABELS.ACTIVE_STATUS +
          "</span> " +
          WARNINGS.IS_A_REQUIRED_FIELD,
      });
    }
    setMandatoryResults(errors);
    if (errors.length > 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_CHECK_FORM_ERRORS
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      code: code,
      symbol: symbol,
      decimalDelimiter,
      thousandDelimiter,
      countryId: countryId,
      sortOrder: Number(sortOrder).toString(),
      activeStatus: selectBtnValue.toString(),
    };

    setIsSubmit(true);
    GEO_APIs.manageCurrency(args).then((res) => {
      setIsSubmit(false);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setSelectedRow({});
    setManageDialog(true);
  };

  console.log("Page: Currency:");

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => setDashMenu("")}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.MASTERS} </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.CURRENCY}</li>
        </ul>
        <h4>{TITLES.CURRENCY}</h4>
        <p>{SUB_TITLES.CURRENCY}</p>
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            id={"currency"}
            height={window.innerHeight * 0.8}
            cols={columns}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.CURRENCY}
            onReload={reloadGrid}
            onAdd={onAdd}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.CURRENCY + ": " + selectedRow.countryName
              : "Add New " + LABELS.CURRENCY
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
            setMandatoryResults([]);
          }}
        >
          <div className="formGroup red">
            <TxtRow
              id={"symbol"}
              type={"text"}
              label={LABELS.CURRENCY_SYMBOL}
              required={true}
              defaultValue={selectedRow.symbol}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <label>
              {LABELS.COUNTRY}
              {<span className="red">*</span>}
            </label>
            <AutoComplete
              list={countries}
              childRef={countryRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.countryId,
                      label: selectedRow.countryName,
                    }
                  : null
              }
              onChange={(e, v) => {}}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup red">
            <TxtRow
              id={"code"}
              type={"text"}
              label={LABELS.CURRENCY_CODE}
              required={true}
              defaultValue={selectedRow.code}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup required">
            <TxtRow
              id={"decimalDelimiter"}
              type={"number"}
              label={LABELS.DECIMAL_DELIMETER}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.decimaldelimiter).toString()
                  : "2"
              }
              disabled={isSubmit}
              required={true}
            />
          </div>
          <div className="formGroup required">
            <TxtRow
              id={"thousandDelimiter"}
              type={"number"}
              label={LABELS.THOUSAND_DELIMETER}
              defaultValue={
                selectedRow.id
                  ? Number(selectedRow.thousanddelimiter).toString()
                  : "2"
              }
              disabled={isSubmit}
              required={true}
            />
          </div>
          <div className="formGroup">
            <TxtRow
              id={"sortOrder"}
              type={"number"}
              label={LABELS.SORTING_ORDER}
              defaultValue={selectedRow.sortOrder}
              disabled={isSubmit}
            />
          </div>
          <div className="formGroup">
            <label className="red">
              {LABELS.ACTIVE_STATUS} {<span>*</span>}
            </label>
            <RadioBtn
              list={[
                {
                  id: "true",
                  value: "true",
                  label: LABELS.ACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === true
                    : true,
                  disabled: false,
                },
                {
                  id: "false",
                  value: "false",
                  label: LABELS.INACTIVE,
                  checked: selectedRow.id
                    ? selectedRow.activeStatus === false
                    : false,
                  disabled: false,
                },
              ]}
              row={true}
              name={"activeStatus"}
              disabled={isSubmit}
            />
          </div>
          {mandatoryResults.length > 0 && (
            <div className="mandatoryBox">
              <ul>
                {mandatoryResults.map((m) => {
                  return (
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: m.msg }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="formGroup submitBtnWrapper">
            <div className="flexbtnjustify">
              <Btn
                text={LABELS.CANCEL}
                loading={isSubmit}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
                onClick={() => {
                  setManageDialog(false);
                  setMandatoryResults([]);
                }}
              />
              <Btn
                text={LABELS.SAVE}
                loading={isSubmit}
                onClick={manageCurrency}
                bgColor={COLORS.SAVE_GREEN}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Currency);
