import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import axios from "axios";
import { IconButton } from "@mui/material";
import { Close as IconClose } from "@mui/icons-material";

import "./styles/styles.css";

import TxtRow from "components/Custom/TxtRow";
import ChkBox from "components/Custom/ChkBox";
import Btn from "components/Custom/Btn";
import TxtBox from "components/Custom/TxtBox";
import SkeletonBox from "components/Custom/SkeletonBox";

import { COLORS } from "common/colors";
import { COMMON_JS } from "common/scripts";
import { WARNINGS } from "common/lables";
import { API_CONFIG, CONFIG, NOTIFY } from "common/config";
import { AUTH_APIs } from "apihandlers/auth";

const thumbnailImg = require("assets/images/pdf.png");

const Agreement = (props: any) => {
  // const [options,setOptions]=useState
  const [gopalSignatureFile, setGopalSignatureFile] = useState<any>([]);
  const [distributorSignatureFile, setDistributorSignatureFile] = useState<any>(
    []
  );
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setlongitude] = useState<number>();
  const [ipAddress, setIpAddress] = useState<number>();
  const choices = [
    {
      // isChecked: "",
      id: "1",
      // value: "DEFINITIONS AND INTERPRETATION",
    },
    {
      // isChecked: "",
      id: "2",
      // value: "DEPOSIT",
    },
    {
      // isChecked: "",
      id: "3",
      // value: "PAYMENT",
    },
    {
      // isChecked: "",
      id: "4",
      // value: "ORDER BOOKING",
    },
    {
      // isChecked: "",
      id: "5",
      // value: "SERVICE",
    },
    {
      // isChecked: "",
      id: "6",
      // value: "TERM",
    },
    {
      // isChecked: "",
      id: "7",
      // value: "WAREHOUSE",
    },
    {
      // isChecked: "",
      id: "8",
      // value: "GOODS AND SERVICES TAX",
    },
    {
      // isChecked: "",
      id: "9",
      // value: "FOOD LICENSE",
    },
    {
      // isChecked: "",
      id: "10",
      // value: "PERMANENT ACCOUNT NUMBER",
    },
    {
      // isChecked: "",
      id: "11",
      // value: "BANK ACCOUNT",
    },
    {
      // isChecked: "",
      id: "12",
      // value: "ADDRESS PROOF",
    },
    {
      // isChecked: "",
      id: "13",
      // value: "COMPUTER/LAPTOP, PRINTER & CCTV",
    },
    {
      // isChecked: "",
      id: "14",
      // value: "SURVEY REPORT",
    },
    {
      // isChecked: "",
      id: "15",
      // value: "SALESMEN",
    },
    {
      // isChecked: "",
      id: "16",
      // value: "VEHICLES",
    },
    {
      // isChecked: "",
      id: "17",
      // value: "OTHER BUSINESS",
    },
    {
      // isChecked: "",
      id: "18",
      // value: "WORK AREA",
    },
    {
      // isChecked: "",
      id: "19",
      // value: "REPRESENTATIONS, WARRANTIES, AND COVENANTS",
    },
    {
      // isChecked: "",
      id: "20",
      // value: "INTELLECTUAL PROPERTY RIGHTS",
    },
    {
      // isChecked: "",
      id: "21",
      // value: "CONFIDENTIALITY",
    },
    {
      // isChecked: "",
      id: "22",
      // value: "INDEMNITY",
    },
    {
      // isChecked: "",
      id: "23",
      // value: "GOVERNING LAW",
    },
    {
      // isChecked: "",
      id: "24",
      // value: "DISPUTE RESOLUTION",
    },
    {
      // isChecked: "",
      id: "25",
      // value: "GENERAL CLAUSES",
    },
  ];
  const [uploadingGopalFile, setUploadingGopalFile] = useState<boolean>(false);
  const [uploadingDistributorFile, setUploadingDistributorFile] =
    useState<boolean>(false);

  // const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState([]);
  const [list, setList] = useState(choices);

  useEffect(() => {
    getLocation();
  }, []);

  const checkAll = (checked, id) => {
    console.log("Checked all ", checked);
    if (checkedList.length === 25) {
      setCheckedList([]);
    } else {
      setCheckedList(list.map((li) => li.id));
    }
  };

  const onChecked = (checked, id) => {
    if (!checkedList.includes(id)) {
      setCheckedList([...checkedList, id]);
    } else {
      setCheckedList(checkedList.filter((checkedId) => checkedId !== id));
    }
  };

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let ulatitude = position.coords.latitude;
          let ulongitude = position.coords.longitude;
          setLatitude(ulatitude);
          setlongitude(ulongitude);
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      console.log("Geolocation is not supported by your browser.");
    }
    getIpAddress();
  };

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://ipinfo.io/ip");
      const ip = response.data;
      setIpAddress(ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const getBrowserDetails = () => {
    const userAgent = navigator.userAgent;
    const browserName = navigator.appName;
    const browserVersion = navigator.appVersion;
    const platform = navigator.platform;
    const language = navigator.language;
    const cookiesEnabled = navigator.cookieEnabled;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const browserDetails = {
      userAgent,
      browserName,
      browserVersion,
      platform,
      language,
      cookiesEnabled,
      screenWidth,
      screenHeight,
    };
    saveRecord(browserDetails);
  };

  const saveRecord = (browserDetails: any) => {
    const time = new Date().toLocaleTimeString();
    const signedOn = (
      document.getElementById("signedOn") as HTMLInputElement
    ).value.trim();

    const partnerName = (
      document.getElementById("partnerName") as HTMLInputElement
    ).value.trim();

    const buisnessPlace = (
      document.getElementById("buisnessPlace") as HTMLInputElement
    ).value.trim();
    const brand = (
      document.getElementById("brand") as HTMLInputElement
    ).value.trim();
    const state = (
      document.getElementById("state") as HTMLInputElement
    ).value.trim();
    const compName = (
      document.getElementById("compName") as HTMLInputElement
    ).value.trim();
    const compAddress = (
      document.getElementById("compAddress") as HTMLInputElement
    ).value.trim();
    const compEmail = (
      document.getElementById("compEmail") as HTMLInputElement
    ).value.trim();
    const compAttention = (
      document.getElementById("compAttention") as HTMLInputElement
    ).value.trim();
    const witnessName = (
      document.getElementById("witnessName") as HTMLInputElement
    ).value.trim();
    const witnessContactNumber = (
      document.getElementById("witnessContactNumber") as HTMLInputElement
    ).value.trim();
    const witneesAddress = (
      document.getElementById("witneesAddress") as HTMLInputElement
    ).value.trim();
    const distributorName = (
      document.getElementById("distributorName") as HTMLInputElement
    ).value.trim();
    const witnessName2 = (
      document.getElementById("witnessName2") as HTMLInputElement
    ).value.trim();
    const distributorAddress = (
      document.getElementById("distributorAddress") as HTMLInputElement
    ).value.trim();
    const witnessContactNumber2 = (
      document.getElementById("witnessContactNumber2") as HTMLInputElement
    ).value.trim();
    const args = {
      signedOn,
      partnerName,
      buisnessPlace,
      brand,
      state,
      compName,
      compAddress,
      compEmail,
      compAttention,
      witnessName,
      witnessContactNumber,
      witneesAddress,
      distributorSignatureFile,
      gopalSignatureFile,
      time,
      location: {
        latitude: latitude,
        longitude: longitude,
      },
      browserDetails: {
        userAgent: browserDetails.userAgent,
        browserName: browserDetails.browserName,
        browserVersion: browserDetails.browserVersion,
        platform: browserDetails.platform,
        language: browserDetails.language,
        cookiesEnabled: browserDetails.cookiesEnabled,
        screenWidth: browserDetails.screenWidth,
        screenHeight: browserDetails.screenHeight,
      },
      ipAddress: ipAddress,
      distributorName,
      witnessName2,
      distributorAddress,
      witnessContactNumber2,
    };
    if (checkedList.length !== 25) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_ACCEPT_ALL_TERMS
      );
    }
    console.log("Args", args);
  };

  const onUploadFile = (e: any, f: any, field) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      if (field === "gopalFile") {
        setGopalSignatureFile(null);
        setUploadingGopalFile(true);
      } else {
        setDistributorSignatureFile(null);
        setUploadingDistributorFile(true);
      }
      uploadFile(file, base64).then((res) => {
        if (field === "gopalFile") {
          setGopalSignatureFile([res]);
          setUploadingGopalFile(false);
        } else if (field === "distributorFile") {
          setDistributorSignatureFile([res]);
          setUploadingDistributorFile(false);
        }
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "storevisit",
    };
    let fileInfo = null;
    try {
      const res = await AUTH_APIs.fileUpload(args);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        fileInfo["mimeType"] = args.file.mimeType;
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };
  // const handleChange = (e, f) => {
  //   if (f === "acceptAll") {
  //     let tempChoice = choices.map((choice) => {
  //       return { ...choice, isChecked: e };
  //     });
  //     setChoices(tempChoice);
  //   }
  //   let tempChoice = choices.map((choice) =>
  //     choice.id === f ? { ...choice, isChecked: e } : choice
  //   );
  //   setChoices(tempChoice);
  // };

  console.log("checkedList:", checkedList);

  return (
    <React.Fragment>
      <div className="agreementContent">
        <div
          style={{
            textAlign: "center",
            fontWeight: "900",
            fontSize: "1.5rem",
          }}
        >
          DISTRIBUTOR AGREEMENT
          <hr></hr>
        </div>
        <div style={{ fontSize: "1.2rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            This
            <span className="bold">Distributor Agreement (“Agreement”) </span>
            &nbsp; is signed on
            <div className="width10" style={{ marginBottom: ".6rem" }}>
              <TxtRow id="signedOn" type="date" />
            </div>
            <span className="bold">(“Execution Date”)</span>
            &nbsp; by and amongst:
          </div>
        </div>
        <p className="bold">BETWEEN</p>
        <div style={{ fontSize: "1.2rem" }}>
          <span className="bold">GOPAL SNACKS [PRIVATE] LIMITED</span>, a
          company incorporated under the Companies Act, 1956 and having its
          registered office at Plot No. G2322, G2323 & G2324, GIDC Metoda Tal.,
          Lodhika Rajkot Gujarat- 360021, India (hereinafter referred to as the
          “Company” which expression shall be deemed to mean and include its
          successors-in-interest and assigns), of the FIRST PART;
        </div>
        <p className="bold">AND</p>
        <div style={{ fontSize: "1.2rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span className="bold">M/s.</span>
            <div className="width10" style={{ marginBottom: ".6rem" }}>
              <TxtRow id="partnerName" />
            </div>{" "}
            , a proprietorship/partnership concern/company/HUF (as the case may
            be) established under the laws of India and having its principal
            place of business at
            <div className="width10" style={{ marginBottom: ".6rem" }}>
              <TxtRow id="buisnessPlace" />
            </div>
          </div>
          (hereinafter referred to as the
          <span className="bold">“Distributor”</span>, which expression shall be
          deemed to include its heirs, partners, successors, executors,
          representatives and permitted assigns) of the{" "}
          <span className="bold">SECOND PART.</span>
        </div>

        <div style={{ fontSize: "1.2rem", paddingTop: "1.5rem" }}>
          The Company and the Distributor are hereinafter also individually and
          collectively referred to as <span className="bold">"Party"</span> and{" "}
          <span className="bold"> "Parties"</span>, respectively.
        </div>
        <p className="bold">WHEREAS:</p>
        <ol style={{ lineHeight: "1.7", fontSize: "1.2rem" }}>
          <li>
            The Company is inter-alia engaged in the business of Namkeen,
            Wafers, Fryums, Papad, Besan, Bakery products & Spices and snacks
            items or any other products{" "}
            <span className="bold"> (“Products”)</span> marketed,
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              {" "}
              distributed and sold by the Company under the various brands owned
              by the Company including but not limited to{" "}
              <div className="width10" style={{ marginBottom: ".6rem" }}>
                <TxtRow id="brand" />
              </div>
              <span className="bold"> ("Brand”)</span>
            </div>
          </li>

          <li>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              The Distributor is in the hereby in the business of distribution
              and trading of the said Products in the State of
              <div className="width10">
                <TxtRow id="state" />
              </div>
            </div>
          </li>

          <li>
            The Distributor is willing to sell the Products under the Brand of
            the Company and now the Parties are desirous for recoding the terms
            of the said arrangement and are entering into this Agreement.
          </li>
        </ol>
        <div style={{ fontSize: "1.2rem" }}>
          <span className="bold"> NOW, THEREFORE,</span> in consideration of the
          mutual promises, representations, warranties, and covenants set forth
          below, other good and valuable consideration (the receipt and
          sufficiency of which are hereby acknowledged by the parties), and
          intending to be legally bound, the Parties agree as follows:
        </div>
        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
          <ol>
            <div className="chkboxopt">
              <ChkBox
                id={"1"}
                controlled={true}
                checked={checkedList.includes("1")}
                onChange={(e, f) => onChecked(e, f)}
              />
              <li className="bold">DEFINITIONS AND INTERPRETATION</li>
            </div>

            <div className="subpointpadding">
              <span>1.1</span>&nbsp; Except where the context otherwise requires
              or capitalized words set out in relevant section of this
              Agreement, the following words and expressions shall have the
              following meanings:
            </div>
            <ul
              style={{
                listStyleType: "lower-roman",
                paddingTop: ".5rem",
                paddingLeft: "5rem",
                lineHeight: "1.7",
              }}
            >
              <li>
                <span className="bold">“Agreement”</span> shall mean and include
                this Agreement including all the annexures attached to or
                referenced to this Agreement, Company policies and any other
                document pursuant to this Agreement and shall include any
                amendment thereto, from time to time.
              </li>
              <li>
                <span className="bold"> “Applicable Law”</span> means any
                applicable law, rule, regulation, ordinance, order, treaty,
                judgment, notification, decree, bye-law, governmental approval,
                directive, guideline, circular, or governmental resolution,
                restriction, determination, interpretation, policy, or any
                similar form of decision having the force of law and shall
                include any adjudication, order, award, injunction, permit or
                decision of any central, state, local or municipal government,
                authority, agency, court having jurisdiction over the matter in
                question, whether in effect as of the date of this Agreement or
                thereafter, in the applicable jurisdiction;
              </li>
              <li>
                <span className="bold">“Claims”</span> means all liabilities,
                claims, suits, losses, demands, actions, fines, penalties,
                proceedings, damages, costs, and expenses including reasonable
                legal and attorney’s fees;
              </li>
              <li>
                <span className="bold">“Confidential Information”</span> means
                and includes all information relating to the business and
                affairs of the disclosing Party which is supplied, received,
                accessed, viewed, or otherwise learned by the receiving Party
                (including its directors, officers, employees, agents,
                affiliates, service provider, contractors or advisors) during
                provision of the Services under this Agreement. Confidential
                Information shall include but not limited to information related
                to (i) any technical, financial, and/or commercial information,
                (ii) any samples, software, documentation, formulations,
                specifications, data relating to the services and products,
                (iii) advertising and marketing plans or marketing
                information/research, promotional data and/or material thereof,
                (iv) any past, current or proposed development projects or plans
                for future development work, (v) pricing and costs data, (vi)
                all members related information including member data, member
                needs, and requirements, (vii) products and services related
                information including rates and discounts, statistics, (viii)
                historical and projected financial statements, (ix) information
                regarding third-party vendors, business partners, independent
                contractors, and (x) any other information which is marked as
                confidential in nature or any such information which in a
                prudent manner shall be construed as confidential in nature;
              </li>
              <li>
                <span className="bold">“Distributor’s</span> Area” means the
                area as detailed in Annexure 1;
              </li>
              <li>
                <span className="bold"> “Execution Date”</span> means the date
                of execution of this Agreement as mentioned above.
              </li>

              <li>
                <span className="bold"> “Force Majeure Event”</span> means act,
                omission, commission, event or accident beyond reasonable
                control including but not limited to natural disasters, acts of
                God, fire, explosion or accidental damage, terrorism, riot,
                civil commotion, war and hostilities, any labour dispute,
                epidemic/pandemic and lockdowns (to the extent the performance
                of obligations by either Party are not possible through online
                mode) interruption or failure of utility service, actions or
                decrees of governmental bodies, communication line failures,
                etc., which is not caused due to the fault, negligence or wilful
                conduct of either Party;
              </li>
              <li>
                <span className="bold"> “Intellectual Property Rights”</span>{" "}
                means and includes (i) all rights, title and interest under any
                statute or common law, including in any Intellectual Property or
                any similar rights, anywhere in the world, whether registerable
                or not, (ii) any licenses, permissions, and grants in any of the
                foregoing; (iii) applications for registering any of the
                foregoing and the right to apply for them in any part of the
                world; and (iv) all extensions and renewals thereto;
              </li>
              <li>
                <span className="bold">“Working Hours”</span> means the days
                except any non-banking Saturday, any Sunday, or any day which is
                a legal holiday or any day on which banking institutions are
                authorized or required by law or other governmental action to
                close.
              </li>
            </ul>

            <div className="subpointpadding">
              <span>1.2</span>&nbsp;Unless the context of this Agreement
              otherwise requires:
            </div>
            <ul
              style={{
                listStyleType: "lower-roman",
                paddingTop: ".5rem",
                paddingLeft: "5rem",
                lineHeight: "1.7",
              }}
            >
              <li>
                words of any gender are deemed to include those of the other
                gender;
              </li>
              <li>
                words using singular or plural number also include plural or
                singular number;
              </li>
              <li>
                the terms “hereof”, “herein”, “hereby”, “hereto” and derivative
                or similar words refer to this entire Agreement or specified
                clauses of this Agreement, as the case may be;
              </li>
              <li>
                reference to any legislation or law or to any provision thereof
                shall include references to any such law, from time to time, be
                amended, supplemented or re-enacted, and any reference to
                statutory provision shall include any subordinate legislation
                made from time to time under that provision; and
              </li>
              <li>
                reference to the word “include” shall be construed without
                limitation;
              </li>
            </ul>

            <div className="chkboxopt">
              <ChkBox
                id={"2"}
                onChange={(e, f) => onChecked(e, f)}
                checked={checkedList.includes("2")}
                controlled={true}
              />
              <li className="bold">DEPOSIT</li>
            </div>
            <div className="subpointpadding">
              <span>2.1</span>&nbsp;The minimum interest free security deposit
              of the Company is shown in below table.
            </div>
            <div className="subpointpadding">
              <span>2.2</span>&nbsp; Deposit has to be paid from only from the
              current account of the Distributor’s entity / agency.
            </div>
            <div className="subpointpadding">
              <span>2.3</span>&nbsp;In case for a continuous period of 30
              (thirty) days, no order has been furnished by the Distributor,
              then the said Distributor would be declared as an inactive
              Distributor and this Agreement shall stand terminated and the
              deposit &emsp;&nbsp; for the same will be returned subject to the
              Distributor complying with Clause 16.3 of this Agreement.
            </div>

            <div style={{ padding: " 1rem 3rem 1rem 3rem" }}>
              <table
                border={1}
                width={"100%"}
                align="center"
                cellPadding={2}
                cellSpacing={0}
              >
                <tr>
                  <td align="center" className="bold">
                    CATEGORY
                  </td>
                  <td align="center" className="bold">
                    DEPOSIT
                  </td>
                  <td align="center" className="bold">
                    REMARKS
                  </td>
                </tr>
                <tr>
                  <td align={"center"} width={"20%"}>
                    Category A
                  </td>

                  <td align={"center"} width={"20%"} className="bold">
                    ₹ 5,00,000
                  </td>
                  <td align={"center"} width={"40%"}>
                    Distributor has to pay 100% billing amount within a period
                    of 24 (twenty-four) Working Hours from the time of
                    generation of such invoice of dispatching order from their
                    respective delivering plant]
                  </td>
                </tr>
              </table>
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="3"
                checked={checkedList.includes("3")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">PAYMENT</li>
            </div>
            <div className="subpointpadding">
              <span>3.1</span>&nbsp;The Distributor shall make the payment to
              Company by making a credit to the Company’s account through
              RTGS/NEFT or Net banking or any digital mode acceptable to the
              Company. Payment will not be accepted in cash or cheque.
            </div>
            <div className="subpointpadding">
              <span>3.2</span>&nbsp;In the event of any delay in payment, the
              Distributor shall be liable to pay late payment charges at the
              rate of INR 200 for every INR 1,00,000 of the outstanding amount
              on a 24 (twenty-fours) Working Hours basis or part thereof for
              such period during which the amount remains outstanding. All the
              charges including any bank charges with respect to delayed
              payment, if any, will be borne by the Distributor. This is without
              prejudice to the Company’s other rights under this Agreement or
              under Applicable Laws including without limitation the right of
              the Company to adjust the security amount from the amount
              outstanding or make a set-off from any amount paid by the
              Distributor.
            </div>
            <div className="subpointpadding">
              <span>3.3</span>&nbsp;Further, in the event of non-payment as
              mentioned above, the Company may also have the right to repossess
              any and all units / articles of the Products for which payment has
              not been received by the Company and any documentation, data,
              records or information relating thereto.
            </div>
            <div className="subpointpadding">
              <span>3.4</span>&nbsp;Further the Company may reward the
              Distributor by way of payment commission or incentives as may be
              mutually agreed and decided between the Parties in writing. The
              same will be communicated in writing to the Distributor by the
              Company.
            </div>
            <div className="subpointpadding">
              <span>3.5</span>&nbsp;The Distributor shall provide the Company
              with 2 (two) undated duly signed cheques as a security for the
              payment of any outstanding from them. The Company shall be
              entitled to present such cheques in case the Distributor does not
              make the payment of its outstanding within 30 days from the due
              date.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="4"
                checked={checkedList.includes("4")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">ORDER BOOKING</li>
            </div>
            <div className="subpointpadding">
              <span>4.1</span>&nbsp;Distributor and salesmen are required to
              work in the Company’s online order booking software and bill in
              the same software of the Company.
            </div>
            <div className="subpointpadding">
              <span>4.2</span>&nbsp;All the Products manufactured by the Company
              has to be sold by the Distributor. In case, if any Distributor is
              not willing to sell any of the Products, then Distributor has to
              give the same in writing which shall be duly signed along with
              seal of the agency. .
            </div>
            <div className="subpointpadding">
              <span>4.3</span>&nbsp;The Company shall use all reasonable
              endeavors to fulfill the accepted orders for all the Products but
              shall not be liable in any way for any loss of trade or profit or
              any other loss occurring to the Distributor in the event of
              delivery being frustrated or delayed.
            </div>
            <div className="subpointpadding">
              <span>4.4</span>&nbsp;Distributor and salesman are not allowed to
              export of the products to any Country, and it is clarified that
              any lability arising from the breach of this clause shall be borne
              by and indemnified by the Distributor and salesman.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="5"
                checked={checkedList.includes("5")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">SERVICE</li>
            </div>
            <div className="subpointpadding">
              <span>5.1</span>&nbsp;The Company may provide to the Distributor,
              for itself and other key employees designated by the Distributor,
              the training in the form and manner as it may deem necessary,
              covering all the Products, price list and Company policies. The
              Company shall not be liable for any cost incurred by the
              Distributor in connection with the training such as travel,
              accommodation etc.
            </div>
            <div className="subpointpadding">
              <span>5.2</span>&nbsp;It is mandatory to provide service to all
              the shops, twice in a week in the city area and once in a week in
              the rural area. It is mandatory to provide service to all the
              villages, urban area and rural area comprising of both small and
              big shops.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="6"
                checked={checkedList.includes("6")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">TERM</li>
            </div>
            <div className="subpointpadding">
              <span>6.1</span>&nbsp;This Agreement shall be in force from the
              Execution Date unless terminated earlier in accordance with this
              Agreement. The Company may terminate this Agreement without
              assigning any reasons thereof, by giving a prior written notice of
              15 (fifteen) days to the other Party.
            </div>
            <div className="subpointpadding">
              <span>6.2</span>&nbsp;Upon termination of the Agreement, the
              Company shall communicate to the Distributor as to the actions
              that need to be undertaken with regards to the pending Products.
              If the same is not done in accordance with the instructions of the
              Company, it may attract consequences as the Company may see that
              it deems fit.
            </div>
            <div className="subpointpadding">
              <span>6.3</span>&nbsp;No such termination shall result into any
              liability or compensation payable by the Company to the
              Distributor.
            </div>
            <div className="subpointpadding">
              <span>6.4</span>&nbsp;The Company has the full right to make any
              change in the above Agreement and if in case the Distributor is
              not able to follow any of the above-mentioned terms then the
              Company has the full right to terminate. Any types of claims will
              not be valid thereafter and terminate this Agreement.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="7"
                checked={checkedList.includes("7")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">WAREHOUSE</li>
            </div>
            <div className="subpointpadding">
              <span>7.1</span>&nbsp;It is mandatory for the Distributor to have
              at least 1200 (twelve hundred) square feet godown facility and
              there should be a facility to unload the truck / transport vehicle
              on a round the clock basis / 24 hrs basis. The said godown
              facility should be within the Distributor’s Area.
            </div>
            <div className="subpointpadding">
              <span>7.2</span>&nbsp; The Distributor shall at all times maintain
              an adequate, suitable and clean warehouse. It is important to keep
              a warehouse in such a place that the truck does not get in the way
              of any kind of wires, cables and/or any traffic obstruction.
            </div>
            <div className="subpointpadding">
              <span>7.3</span>&nbsp; The Distributor shall be responsible for
              transporting the truck / vehicle from the main road to the godown
              facility and after unloading the contents of the same back to the
              main road.
            </div>
            <div className="subpointpadding">
              <span>7.4</span>&nbsp; The Distributor shall maintain best hygiene
              conditions at its storage locations/ warehouse. For this, the
              Distributor shall endeavor that the said storage locations are
              free from pests, insects, theft, burglary, pilferage and damage
              whatsoever and shall be responsible for ensuring that the Products
              reach the buyers in proper and good conditions.
            </div>
            <div className="subpointpadding">
              <span>7.5</span>&nbsp; The Distributor will not sell any Product
              beyond the shelf life of the Product as declared on the label.
            </div>
            <div className="subpointpadding">
              <span>7.6</span>&nbsp; There shall be no replacement given other
              than the ones cause due to manufacturing faults, which on a cases
              to case basis shall be decided by the Company. Products with
              manufacturing faults have to be returned to the respective
              delivering plant within a period of 30 (thirty) days from the time
              of generation of such invoice by way of taking appropriate
              approvals from respective sales team, along with documentation as
              maybe need in accordance with Applicable Law and by adhering to
              proper sales channel.
            </div>
            <div className="subpointpadding">
              <span>7.7</span>&nbsp; The Distributor at all times has to keep
              stock 3 (three) times of his average daily sales in the state of
              Gujarat and 4 (four) times of his average daily sales in rest of
              India.
            </div>
            <div className="subpointpadding">
              <span>7.8</span>&nbsp; The truck can reach the Distributor’s
              warehouse anytime in 24 hours, it will be the responsibility of
              the Distributor to evacuate it. The time of emptying / unloading
              the Products will be 30 minutes of every Product bearing the value
              of INR 1,00,000 (Indian Rupees One Lakhs).
            </div>
            <div className="subpointpadding">
              <span>7.9</span>&nbsp;The Distributor shall never sell Products by
              charging less or more price other than the ones mentioned on the
              company price list. Sale of Products in wholesale is strictly
              against the policy of the Company and the same is subject to penal
              consequences. The entire bill of sale of Products has to be made
              in online software as provided by the Company.
            </div>
            <div className="subpointpadding">
              <span>7.10</span>&nbsp;All the Products & stock keeping units will
              be delivered in Distributor’s Area as per stock availability at
              respective / designated delivery facility.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="8"
                checked={checkedList.includes("8")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">GOODS AND SERVICES TAX</li>
            </div>
            <div className="subpointpadding">
              <span>8.1</span>&nbsp; It is mandatory to get a regular Good and
              Services Tax <span className="bold">(“GST”)</span> number in the
              name of the firm i.e., the operating agency of the Distributor
              <span className="bold"> (“Firm”)</span>. If the address in the
              certificate is not the delivery address, then the delivery address
              should be included in addition to the place of business in the
              certificate. It is necessary to send a copy of the GST certificate
              to the company. GST means any tax on the supply of goods or
              services which is leviable or assessed under Goods and Services
              Tax Legislation at the central and state level as amended from
              time to time <span className="bold"> (“GST Law”).</span>
            </div>
            <div className="subpointpadding">
              <span>8.2</span>&nbsp; The Distributor also warrants that it shall
              comply with the GST compliances as may be applicable upon it and
              shall render the Company indemnified of all the tax costs,
              including interest and penalty in the event of any default of GST
              Law by the Distributor.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="9"
                checked={checkedList.includes("9")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">FOOD LICENSE</li>
            </div>
            <div className="subpointpadding">
              <span>9.1</span>&nbsp; It mandatory to get food licenses{" "}
              <span className="bold"> (“FSSAI”)</span>
              in the name of the Firm/Distributor. The address as mentioned in
              the license has to be the delivery address. It is necessary to
              have state license and include all the categories of the products
              which are sold by the Company. Regular renewal of the FSSAI
              license is mandatory. A full digital (.pdf) copy of the food
              license has to be sent to the Company. If there is any action
              taken by concerned governmental authority towards any of the
              Distributors for not having any of the categories or license, the
              Company will not interfere in that regard & dispatching of
              Products If there is any action taken by concerned governmental
              authority towards any of the Distributors for not having any of
              the categories or license, the Company will not interfere in that
              regard & dispatching of Products will be blocked with immediate
              effect.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="10"
                checked={checkedList.includes("10")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">PERMANENT ACCOUNT NUMBER</li>
            </div>
            <div className="subpointpadding">
              <span>10.1</span>&nbsp; Copies of Permanent Account Number (“PAN
              Card”) has to be sent to the Company. In case the Distributor’s
              Firm is a partnership firm, then the PAN card of the Firm and the
              PAN cards of all the partners of the Firm has to be sent to the
              Company, and if the Distributor is the sole proprietor, then a
              copy of his/her PAN card.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="11"
                checked={checkedList.includes("11")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">BANK ACCOUNT</li>
            </div>
            <div className="subpointpadding">
              <span>11.1</span>&nbsp;The Distributor must have a current account
              with a bank, it is mandatory to send the details of the
              Distributor’s current account to the Company (including copies of
              cheque book and pass book). If there is change in any of the
              details of current account, distributor has to immediately inform
              the company immediate with renewed copies of cheque book and
              passbook. If there is no information given to company in terms of
              changes made in bank account, Company will not be held responsible
              for the payments made in old bank account.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="12"
                checked={checkedList.includes("12")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">ADDRESS PROOF</li>
            </div>
            <div className="subpointpadding">
              <span>12.1</span>&nbsp;It is mandatory for all partners /
              proprietors to send a copy of their Aadhaar card, driving license,
              election card, PAN card & 3 (three) photographs to the Company.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="13"
                checked={checkedList.includes("13")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">COMPUTER/LAPTOP, PRINTER & CCTV</li>
            </div>
            <div className="subpointpadding">
              <span>13.1</span>&nbsp;It is necessary to have a computer / laptop
              and printer at the godown facility. At least one closed-circuit
              television <span className="bold">(“CCTV”)</span> camera should be
              placed in such a way that unloading of the truck can be clearly
              visible. In case of short supply, distributor has to send his
              video clip to the Company for further process. If he fails to do
              the same, then the clip of loading from the company shall be
              considered as valid proof.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="14"
                checked={checkedList.includes("14")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">SURVEY REPORT</li>
            </div>
            <div className="subpointpadding">
              <span>14.1</span>&nbsp;After the appointment of the Distributor,
              Distributor will have to send the list of all the retail shops
              where the Company’s Products will be placed, contact details of
              retail shops and their routes in the Distributor’s Area to the
              Company within a time period of 15 (fifteen) days. After that the
              Distributor will be allowed to continue further process as per
              Company’s rules
            </div>
            <div className="subpointpadding">
              <span>14.2</span>&nbsp;Once the Distributor agrees to the said
              rules and regulation of the Company from then onwards the
              Distributor is responsible for full range selling and placement in
              the Distributor’s Area.
            </div>
            <div className="subpointpadding">
              <span>14.3</span>&nbsp;If found Products are placed at retail
              shops not mentioned in list or Products not placed in retail shops
              mentioned in list, then penalty per outlet will be charged.
            </div>

            <div className="chkboxopt">
              <ChkBox
                id="15"
                checked={checkedList.includes("15")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">SALESMEN</li>
            </div>
            <div className="subpointpadding">
              <span>15.1</span>&nbsp; It is mandatory to have salesmen (one
              salesman per 150 shops in city area and one salesman per 250 Shops
              in rural area). All salesmen must have android smart phones and a
              two-wheeler.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="16"
                checked={checkedList.includes("16")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">VEHICLES</li>
            </div>
            <div className="subpointpadding">
              <span>16.1</span>&nbsp; It is mandatory to have 1 (one) salesman
              per every three or four-wheeler in the Distributors’ Area.
            </div>
            <div className="subpointpadding">
              <span>16.2</span>&nbsp; The Distributor shall have at least 1
              (one) pack body (box body) vehicle and the same shall require the
              name of the Company along with its logo to be printed/painted on
              it so as it is visible to the public. If any changes are made in
              the Distributor’s vehicle the same shall be informed to the
              Company in writing within a period of 10 (ten) days. All vehicles
              must be owned in the name of the agency/entity of the Distributor.
            </div>
            <div className="subpointpadding">
              <span>16.3</span>&nbsp; Immediately upon the termination of this
              Agreement, the Distributor shall get a whitewash of the Company’s
              Brand name and logo from their its premises, vehicle etc., if any.
            </div>
            <div className="subpointpadding">
              <span>16.4</span>&nbsp; If the Distributor has purchased any new
              vehicles, then it is necessary to send all the copies of relevant
              documents including but not limited to the vehicle RC registration
              book, the bill a copy of the insurance to the Company.
            </div>
            <div className="subpointpadding">
              <span>16.5</span>&nbsp;If the Distributor has purchased any old/
              second hand vehicles, then it is necessary to send all copies
              relating to the old vehicle including but not limited to the
              purchase agreement as entered into for the same vehicle on a duly
              valued stamp paper,the RC book, and copies of the insurance policy
              to the Company along with the ID proof of both the parties.
            </div>
            <div className="subpointpadding">
              <span>16.6</span>&nbsp; On request of the Company to fulfill
              market demand, Distributor shall have to purchase vehicle as and
              when required.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="17"
                checked={checkedList.includes("17")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">OTHER BUSINESS</li>
            </div>
            <div className="subpointpadding">
              <span>17.1</span>&nbsp;Post becoming a Distributor of the Company,
              the Distributor and of his family cannot do or par take in the
              trade of any other brands/ agencies any other business. The
              Distributor can do so subject to obtaining the prior consent of
              the Company which shall be provided on a case-to-case basis.
            </div>
            <div className="subpointpadding">
              <span>17.2</span>&nbsp; If it is found that Distributor is engaged
              into other business without the prior knowledge of the Company,
              then the Company reserves full rights to terminate this Agreement.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="18"
                checked={checkedList.includes("18")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">WORK AREA</li>
            </div>
            <div className="subpointpadding">
              <span>18.1 </span>&nbsp; The company has full rights to increase
              or decrease the limits of the Distributor’s Area. The Distributor
              will not be able to sell any Products outside their specified area
              and/or to any customers from outside the Distributor’s Area if
              they come to buy the said Products.
            </div>
            <div className="subpointpadding">
              <span>18.2 </span>&nbsp; If Distributor is found placing Products
              beyond the Distributor’s Area limits as sanctioned by the Company
              to him then there shall be penal consequences for the same.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="19"
                checked={checkedList.includes("19")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">
                REPRESENTATIONS, WARRANTIES, AND COVENANTS
              </li>
            </div>
            <div className="subpointpadding">
              <span>19.1 </span>&nbsp;Each Party represents and warrants to the
              other Party, as follows:
            </div>
            <ul
              style={{
                listStyleType: "lower-roman",
                paddingTop: ".5rem",
                paddingLeft: "5rem",
                lineHeight: "1.7",
              }}
            >
              <li>
                it is a company duly incorporated, organized, validly existing,
                and in good standing under the Applicable Law and has obtained
                necessary permissions including but not limited to licenses,
                permissions, etc. under the Applicable Law to carry out its
                obligations under this Agreement;
              </li>
              <li>
                execution and performance of this Agreement by such Party and
                the consummation of the transactions contemplated under this
                Agreement do not and shall not contravene the certificate of
                incorporation, memorandum of association, articles of
                association or bye-laws, existing deeds of such Party and do not
                and will not conflict with or result in (i) breach of or default
                under any indenture, agreement, judgment, decree, order or
                ruling to which such Party is a party that would adversely
                affect such Party’s ability to perform its obligations under
                this Agreement; or (ii) breach of any Applicable Law; 
              </li>
              <li>
                it has all necessary corporate power and authority to conduct
                its business as it is currently being conducted, and it is
                competent to enter into this Agreement and to perform its
                obligations under this Agreement; and
              </li>
              <li>
                it shall comply with all Applicable Law in the performance of
                its obligations and the exercise of its rights under this
                Agreement.
              </li>
            </ul>
            <div className="subpointpadding">
              <span>19.2 </span>&nbsp;The Distributor hereby:
            </div>
            <ul
              style={{
                listStyleType: "lower-roman",
                paddingTop: ".5rem",
                paddingLeft: "5rem",
                lineHeight: "1.7",
              }}
            >
              <li>
                agrees to sell the Products on an ‘as is’ and ‘as received’
                basis without tampering with the packaging and with the original
                labels thereon.
              </li>
              <li>
                to intimate the Company of any sample taken / seized / lifted of
                the Company’s Products by any authorities within 12 (twelve)
                hours of such sample being taken/seized/lifted, including but
                not limited to under the Food Safety and Standards Regulations.
                The Company shall in no way be responsible for any claim/loss/
                damage/legal consequence arising out of bad or improper storage
                or due to any act of omission or commission on the part of the
                Distributor and/or for non-compliance of FSSA Act and /or other
                laws applicable to the Distributor
              </li>
              <li>
                to intimate the Company if they become aware of any situation in
                which any other company is trying to sale/manufacture
                counterfeit Products of the Company.
              </li>
              <li>
                they shall not indulge in any financial / business activities
                with any of the Company’s employees. In case of any unavoidable
                situation, Distributors shall take the necessary written
                approval duly signed by head of department of respective
                department of the Company.
              </li>
            </ul>
            <div className="subpointpadding">
              <span>19.3 </span>&nbsp;If the Distributor is unable to follow the
              rules of the Company as mentioned herein or if there are
              complaints about Distributorship for any kind of unqualified move
              or service of Products delivery or any other type of complaint in
              the Distributor’s Area more than once, then the Company has full
              right to take action against the Distributor or to terminate the
              said Distributorship pursuant to this Agreement, in which any type
              of the Distributor’s claim shall not be held valid.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="20"
                checked={checkedList.includes("20")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">INTELLECTUAL PROPERTY RIGHTS</li>
            </div>
            <div className="subpointpadding">
              <span>20.1 </span>&nbsp;The Distributor acknowledges that the
              Company owns and shall continue to own all rights, titles, and
              interests in and to any Intellectual Property that is owned prior
              to this Agreement. The proprietary rights and all Intellectual
              Property Rights, interests, and claims in relation to the
              Intellectual Property of the Company shall vest with the Company
              in perpetuity, for all int
            </div>
            <div className="subpointpadding">
              <span>20.2</span>&nbsp;The Distributor also acknowledges that all
              Intellectual Property including but not limited to copyright;
              trademarks and other rights belonging to the Company may only be
              used by the Distributor with the written consent of the Company
              for the limited purpose of this Agreement and during the validity
              of this Agreement.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="21"
                checked={checkedList.includes("21")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">CONFIDENTIALITY </li>
            </div>
            <div className="subpointpadding">
              <span>21.1</span>&nbsp;Each Party shall treat and shall procure
              that its directors, officers, managers, partners, members,
              employees, agents, legal, financial and professional advisors and
              bankers shall treat and hold as confidential, all Confidential
              Information and shall not disclose, transfer, transmit or use any
              of the Confidential Information, except as authorized by the other
              Party.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="22"
                checked={checkedList.includes("22")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">INDEMNITY </li>
            </div>
            <div className="subpointpadding">
              <span>22.1</span>&nbsp;Distributor{" "}
              <span className="bold">(“Indemnifying Party”) </span>shall
              promptly on demand defend, indemnify and hold harmless the Company
              and its directors, officers, managers, partners, members,
              employees, agents, advisors{" "}
              <span className="bold">(“Indemnified Party”)</span> for and
              against all direct Claims incurred or suffered, or to be incurred
              or suffered, by the Indemnified Party that arise out of or in any
              way relate to, or result from any breach by the Indemnifying Party
              of any of their respective obligations, representations and
              warranties, covenants, violation of the Applicable Laws, under the
              provisions of this Agreement, or negligence, fraud or wilful
              misconduct of the Indemnifying Party, or any claim, suit, demand,
              action or proceeding brought by a third party that involves,
              relates to or concerns a violation or other breach by the
              Indemnifying Party of any of the Intellectual Property Rights or
              the negligence, fraud or wilful misconduct of the Indemnifying
              Party.
            </div>
            <div className="subpointpadding">
              <span>22.2</span>&nbsp;Notwithstanding anything to the contrary
              contained in this Agreement, the Company shall not be liable for
              any deficiency, defect, fraud, claim, suit, demand, action or
              proceedings under this Clause.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="23"
                checked={checkedList.includes("23")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">GOVERNING LAW</li>
            </div>
            <div className="subpointpadding">
              <span>23.1</span>&nbsp;This Agreement shall be governed by the
              laws of India; and subject to clause 24on Dispute Resolution under
              this Agreement. The courts at Rajkot shall have the exclusive
              jurisdiction in respect of any matter or dispute connected with
              this Agreement.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="24"
                checked={checkedList.includes("24")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">DISPUTE RESOLUTION</li>
            </div>
            <div className="subpointpadding">
              <span>24.1</span>&nbsp;Any dispute which arises between the
              Parties shall be attempted to be first resolved through
              discussions and negotiations in good faith between the Parties.
              Where the Parties are unable to resolve such disputes through
              discussions and negotiations within a period of 30 (thirty)
              business days from the date of a written notice by either Party
              notifying the existence of such dispute, either Party shall be
              free to refer the dispute to arbitration. This Agreement and the
              rights and obligations of the Parties shall remain in full force
              and effect pending the award in such arbitration proceeding. 
            </div>
            <div className="subpointpadding">
              <span>24.2</span>&nbsp;The arbitration shall be governed by the
              Arbitration and Conciliation Act, 1996 and the rules thereunder,
              for the time being in force, and/or any statutory modification or
              re-enactment thereof. The venue and seat of arbitration shall be
              Rajkot and the language of the arbitration shall be English. The
              arbitration shall be conducted by a sole arbitrator to be jointly
              appointed by the Parties. The award rendered shall be in writing
              and shall set out the facts of the dispute and the reasons for the
              arbitrator’s decision. The arbitral award shall also award costs
              of the arbitration proceedings.
            </div>
            <div className="chkboxopt">
              <ChkBox
                id="25"
                checked={checkedList.includes("25")}
                onChange={(e, f) => onChecked(e, f)}
                controlled={true}
              />
              <li className="bold">GENERAL CLAUSES</li>
            </div>
            <div className="subpointpadding">
              <span>25.1</span>&nbsp;
              <span className="bold">
                <u>Force Majeure</u>
              </span>
              : If either Party is prevented or delayed in the performance of
              its obligations under this Agreement, arising from or attributable
              to any Force Majeure Event, then the Party whose performance has
              been so affected shall not be liable for such delay, default, or
              inability in performance of its obligations under this Agreement,
              provided that such Party, immediately and no later than 24 (twenty
              four) hours of the Force Majeure Event gives written notice to the
              other Party of the facts which constitute the Force Majeure Event.
              The affected Party shall do everything reasonably possible to
              resume performance. For the avoidance of doubt, a Force Majeure
              Event shall exclude any event that a Party could reasonably have
              prevented by testing, work-around, or other exercise of diligence.
              If the period of non-performance exceeds 15 (fifteen) days from
              the receipt of written notice of the Force Majeure Event, either
              Party may, by giving written notice to the other Party, terminate
              this Agreement.
            </div>
            <div className="subpointpadding">
              <span>25.2</span>&nbsp;
              <span className="bold">
                <u>No Assignment</u>
              </span>
              : Neither Party shall have the right to assign any of its rights
              or obligations under this Agreement to its affiliates without
              prior written approval of the other Party. .
            </div>
            <div className="subpointpadding">
              <span>25.3</span>&nbsp;
              <span className="bold">
                <u>Notices</u>
              </span>
              : Any notice, request, consent, waiver, or other communication
              required or permitted under this Agreement shall be effective only
              if it is in writing in English language and shall be deemed
              received by the Party to which it is sent by any of the following
              modes (i) upon delivery when delivered by hand, (ii) 3 (three)
              business days after being sent if sent with all sending expenses
              prepaid, by an express courier with a reliable system for tracking
              delivery, (iii) when transmitted if sent by facsimile/e-mail and
              no delivery failure report being received, or (iv) 3
              (three)Neither Party shall have the right to assign any of its
              rights or obligations under this Agreement to its affiliates
              without prior written approval of the other Party. . business days
              after the date sent, if sent by speed or registered post, postage
              prepaid, return receipt requested, addressed as follows:
            </div>
            <div className="subpointpadding">
              <table border={1} cellSpacing={0} width={"100%"}>
                <tr>
                  <td className="bold">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      If to{" "}
                      <div
                        className="width45"
                        style={{ marginBottom: ".6rem" }}
                      >
                        <TxtRow id="compName" type="text" />
                      </div>
                    </div>
                  </td>
                  <td className="bold" align="center">
                    If to Gopal Snacks [Private] Limited:
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: "4rem",
                        width: "60%",
                        justifyContent: "space-between",
                      }}
                    >
                      Address:{" "}
                      <div
                        // className="width45"
                        style={{ marginBottom: ".6rem", padding: ".5rem" }}
                      >
                        <TxtRow id="compAddress" type="text" />
                      </div>
                    </div>
                  </td>
                  <td style={{ paddingLeft: "4rem" }}>
                    Address: Plot No. G2322, G2323 & G2324, GIDC Metoda Tal.
                    Lodhika, Rajkot, Gujarat – 360021, India
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: "4rem",
                        width: "60%",
                        justifyContent: "space-between",
                      }}
                    >
                      E-mail:{" "}
                      <div
                        // className="width45"
                        style={{ marginBottom: ".6rem", padding: ".5rem" }}
                      >
                        <TxtRow id="compEmail" type="text" />
                      </div>
                    </div>
                  </td>
                  <td style={{ paddingLeft: "4rem" }}>
                    E-mail: info@gopalsnacks.com
                  </td>
                </tr>

                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: "4rem",
                        justifyContent: "space-between",
                        width: "60%",
                      }}
                    >
                      Attention:{" "}
                      <div
                        // className="width45"
                        style={{ marginBottom: ".6rem", padding: ".5rem" }}
                      >
                        <TxtRow id="compAttention" type="text" />
                      </div>
                    </div>
                  </td>
                  <td style={{ paddingLeft: "4rem" }}>
                    Attention: Regional Sales Manager
                  </td>
                </tr>
              </table>
            </div>
            <div className="subpointpadding">
              <span>25.4</span>&nbsp;
              <span className="bold">
                <u>Waiver:</u>
              </span>{" "}
              Except as expressly provided in this Agreement, no waiver of any
              provision of this Agreement shall be effective unless set forth in
              a written instrument signed by the Party waiving such provision.
              No failure or delay by a Party in exercising any right, power,
              privilege, or remedy under this Agreement shall operate as a
              waiver thereof, nor shall any single or partial exercise of the
              same preclude any further exercise thereof or the exercise of any
              other right, power or remedy. Without limiting the foregoing, no
              waiver by a Party of any breach by the other Party of any
              provision hereof shall be deemed to be a waiver of any preceding
              or subsequent breach of that or any other provision hereof
            </div>

            <div className="subpointpadding">
              <span>25.5</span>&nbsp; The Parties intend that in case any one or
              more of the provisions contained in this Agreement shall be held
              to be invalid or unenforceable in any respect, such provision
              shall be modified to the extent necessary to render it, as
              modified, valid and enforceable under Applicable Laws and such
              invalidity or unenforceability shall not affect the other
              provisions of this Agreement.
            </div>

            <div className="subpointpadding">
              <span>25.6</span>&nbsp; Each Party shall co-operate with the other
              Party and execute and deliver to the other Party such instruments
              and documents and take such other actions as may be reasonably
              requested from time to time in order to carry out, evidence and
              confirm their rights hereunder and the intended purpose of this
              Agreement and to ensure the complete and prompt fulfilment,
              observance and performance of the provisions of this Agreement and
              generally that full effect is given to the provisions of this
              Agreement.
            </div>
            <div className="subpointpadding">
              <span>25.7</span>&nbsp; The persons signing this Agreement on
              behalf of the Parties represent and covenant that they have the
              authority to so sign and execute this Agreement on behalf of the
              Parties for whom they are signing.
            </div>
            <div className="subpointpadding">
              <span>25.8</span>&nbsp; The Company has the full right to make any
              change in the above Agreement and if in case the Distributor is
              not able to follow any of the above-mentioned terms then the
              Company has the full right to terminate. Any types of claims will
              not be valid thereafter and terminate this Agreement.
            </div>

            <div className="subpointpadding">
              <span>25.9</span>&nbsp;
              <span className="bold">
                <u>Counterpart:</u>
              </span>{" "}
              This Agreement may be executed in any number of originals or
              counterparts, each in the like form and all of which when taken
              together shall constitute one and the same document, and any Party
              may execute this Agreement by signing any one or more of such
              originals or counterparts. This Agreement may be executed by
              delivery of the signature page hereof by facsimile transmission or
              e-mail in “portable document format” (.pdf), which shall be as
              effective as signing and delivering the counterpart in person.
            </div>

            <div className="subpointpadding">
              <span>25.10</span>&nbsp; In case the company enters into any
              sponsorship agreements, any marketing material in relation the
              same has to be destroyed by the Distributor on termination of such
              sponsorship agreement. The Company shall communicate termination
              of such sponsorship to the Distributor 1 (one) month in advance.
            </div>

            <div className="subpointpadding">
              <span>25.11</span>&nbsp; On a case-to-case basis, the Company
              shall provide the Distributor with certain merchandising products
              which the Company shall communicate the purposes for which it
              should be used. In case it is found that the same is not being
              used for the said purposes then the Distributor shall be penalized
              by the Company on its discretion and the said merchandising
              products shall be returned to the Company within a period of 5
              (five) days.
            </div>
          </ol>
        </div>

        <div>
          <p style={{ textAlign: "center" }} className="bold">
            DISTRIBUTORS AREA
          </p>
          <div>
            <p>
              <span className="bold"> IN WITNESS WHEREOF</span> this Agreement
              has been executed by each of the Parties and delivered as a deed
              on the Execution Date.
            </p>
            <table border={1} cellSpacing={0} width={"50%"}>
              <tr>
                <td align="center">Gopal Snacks [Private] Limited</td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Name:{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtRow id="witnessName" type="text" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Address:{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtRow id="witneesAddress" type="text" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Contact Number:{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtRow id="witnessContactNumber" type="text" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Signature{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtBox
                        type={"file"}
                        id="uploadFile"
                        // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
                        // className="uploadFile"
                        accept="image/*,.pdf"
                        onChange={(e, f) => {
                          onUploadFile(e, f, "gopalFile");
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="agreementFlexCenter signatureBox">
              <div className="agreementFlexCenter">
                {gopalSignatureFile &&
                  gopalSignatureFile.map((u, i) => {
                    return (
                      <div
                        className="thumbnailBox width30"
                        key={"storefile-" + i}
                      >
                        <IconButton
                          className="thumbnailBoxIcon"
                          onClick={() => {
                            const list = gopalSignatureFile.filter(
                              (a, j) => j !== i
                            );
                            setGopalSignatureFile(list);
                          }}
                        >
                          <IconClose />
                        </IconButton>
                        <img
                          onClick={() =>
                            window.open(
                              API_CONFIG.STATIC_URL + u.path,
                              "_blank"
                            )
                          }
                          src={
                            u.mimeType === "pdf"
                              ? thumbnailImg
                              : API_CONFIG.STATIC_URL + u.path
                          }
                          height={"100%"}
                          className="responsive cursor"
                        />
                      </div>
                    );
                  })}
                {uploadingGopalFile && (
                  <div className="thumbnailBox width20">
                    <SkeletonBox height={150} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <p>
              <span className="bold"> IN WITNESS WHEREOF</span> this Agreement
              has been executed by each of the Parties and delivered as a deed
              on the Execution Date.
            </p>
            <table border={1} cellSpacing={0} width={"60%"}>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "45%",
                    }}
                  >
                    [Name of the Distributor]:
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtRow id="distributorName" type="text" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Name:{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtRow id="witnessName2" type="text" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Address:{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtRow id="distributorAddress" type="text" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Contact Number:{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtRow id="witnessContactNumber2" type="text" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    Signature{" "}
                    <div
                      className="width45"
                      style={{ marginBottom: ".6rem", padding: ".5rem" }}
                    >
                      <TxtBox
                        type={"file"}
                        id="uploadFile"
                        // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
                        // className="uploadFile"
                        accept="image/*,.pdf"
                        onChange={(e, f) => {
                          onUploadFile(e, f, "distributorFile");
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="agreementFlexCenter signatureBox">
              <div className="agreementFlexCenter">
                {distributorSignatureFile &&
                  distributorSignatureFile.map((u, i) => {
                    return (
                      <div
                        className="thumbnailBox   "
                        key={"storefile-" + i}
                        style={{ height: "10rem" }}
                      >
                        <IconButton
                          className="thumbnailBoxIcon"
                          onClick={() => {
                            const list = distributorSignatureFile.filter(
                              (a, j) => j !== i
                            );
                            setDistributorSignatureFile(list);
                          }}
                        >
                          <IconClose />
                        </IconButton>
                        <img
                          onClick={() =>
                            window.open(
                              API_CONFIG.STATIC_URL + u.path,
                              "_blank"
                            )
                          }
                          src={
                            u.mimeType === "pdf"
                              ? thumbnailImg
                              : API_CONFIG.STATIC_URL + u.path
                          }
                          height={"100%"}
                          className="responsive cursor"
                        />
                      </div>
                    );
                  })}
                {uploadingDistributorFile && (
                  <div className="thumbnailBox width20">
                    <SkeletonBox height={150} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agreementFooter">
        <div
          style={{
            fontSize: "300",
            padding: "1rem 0rem 0rem 6rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          className="bold "
        >
          <ChkBox
            id="acceptAll"
            onChange={(e, f) => {
              checkAll(e, f);
            }}
            checked={checkedList.length == 25}
            controlled={true}
          />
          <span>Accept All Terms</span>
        </div>
        <div className="agreementFlexCenter">
          <Btn
            text="I Accept the Agreement Terms"
            size={"large"}
            bgColor={COLORS.SAVE_GREEN}
            onClick={getBrowserDetails}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withSnackbar(Agreement);
