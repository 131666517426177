import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

import Loader from "components/Custom/Loader";
import ExcelGrid from "components/Custom/ExcelGrid";

import { LABELS, TITLES } from "common/lables";
import { CRM_REPORTS_APIs } from "apihandlers/crm/reports";
import { API_CONFIG, FORMATS } from "common/config";
import { DateFunctions } from "common/datefunctions";

const TrainingStatus = (props: any) => {
  const { setDashMenu } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = async () => {
    try {
      const args = {
        fromDate: DateFunctions.getDate("thismonth", "from", FORMATS.SQL_DATE),
        toDate: DateFunctions.getDate("thismonth", "to", FORMATS.SQL_DATE),
        // fromDate: "2023-06-01",
        // toDate: "2023-06-30",
      };
      const res: any = await CRM_REPORTS_APIs.getTrainingStatus(args);
      const { status, response } = res;
      if (status === 200) {
        let list = response.records;
        setRows(list);
        setLoading(false);
      }
    } catch (error) {
      console.error("getTrainingStatus:", error);
    }
  };

  const COLUMNS = [
    {
      key: "trainingCategory",
      name: "Training Category",
      width: "25%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "startDate",
      name: "Start Date",
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "endDate",
      name: "End Date",
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "startPercentage",
      name: LABELS.START_PERCENTAGE,
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "endPercentage",
      name: "End Percentage",
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "viewedDevice",
      name: "Viewed Device",
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
    {
      key: "isTrainingCompleted",
      name: "Is Training Completed",
      width: "15%",
      type: "text",
      sortable: true,
      searchable: true,
      editable: false,
    },
  ];

  const reloadGrid = () => {
    setRows(null);
    getRows();
  };

  console.log("selectedRow", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>
            {TITLES.TRAINING_REPORTS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.TRAINING_STATUS}</li>
        </ul>
        <h4>{TITLES.TRAINING_STATUS}</h4>
        {/* <p>{SUB_TITLES.TRAINING_STATUS}</p> */}
      </div>

      <div className="grid excelGridWrapper">
        {rows ? (
          <ExcelGrid
            height={window.innerHeight * 0.85}
            cols={COLUMNS}
            rows={rows}
            exportOption={true}
            newRowOption={false}
            exportName={TITLES.TRAINING_STATUS}
            onReload={reloadGrid}
          />
        ) : (
          <div className="flexRowCenter">
            <Loader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(TrainingStatus);
